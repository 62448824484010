import ApiRequest from 'store/shared/api/apiRequest';
import { auctionItemConnectionSchema } from './searchSchemas';

/**
 * Search Vin
 *
 * @param {string} keyword
 * @param {boolean} isAuctionStaff
 */
export const search = (keyword: string, isAuctionStaff: boolean = false) =>
  ApiRequest({
    data: {
      query: `
        query($keyword: String) {
          sold: auctionItemConnection(first: 4, status: [SOLD], transactionType: SELLING, sort: [{id: "DATE_SOLD", ascending: false}] keyword: $keyword) {
            ${auctionItemConnectionSchema}
          }
          
          ${
            !isAuctionStaff
              ? `purchased: auctionItemConnection(first: 4, status: [SOLD], transactionType: BUYING, sort: [{id: "DATE_SOLD", ascending: false}] keyword: $keyword) {
             ${auctionItemConnectionSchema}
           }`
              : ''
          }
          
          cancelled: auctionItemConnection(first: 4, status: [SALE_CANCELLED], sort: [{id: "UPDATED", ascending: false}] keyword: $keyword) {
            ${auctionItemConnectionSchema}
          }
        }
      `,
      variables: {
        keyword,
      },
    },
  });
