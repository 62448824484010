/**
 * Wrapper around requestAnimationFrame to allow the setting of a timeout
 * to prevent late animations. When requestAnimationFrame is called in a
 * browser's background tab, the callback will not fire until the tab is
 * in the foreground again. If the interval between when requestAnimationFrame
 * is called and when the tab is foregrounded is greater than the timeout,
 * then the callback will not be fired.
 *
 * @param {Function} callback
 * @param {Number} timeout in milliseconds
 */
const raf = (callback, timeout = Number.MAX_SAFE_INTEGER) => {
  const start = performance.now();

  requestAnimationFrame((timeStamp) => {
    if (timeStamp - start < timeout) {
      callback();
    }
  });
};

export default raf;
