import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionService,
  CompanyAuctionRelationship,
  MutationauctionItemAuctionServiceCreateArgs,
  MutationauctionServiceCreateArgs,
  MutationauctionServiceDefaultSetArgs,
  MutationauctionServiceRemoveArgs,
} from 'store/shared/api/graph/interfaces/types';

export const auctionServiceCreate = ({
  auctionId,
  auctionServiceMetadataId,
  buyerShield,
  companyId,
  companySubTypes,
  feeFixedMode,
  feeFixedValue,
  feeTiers,
  feeType,
  format,
  paidBy,
  verified,
}: MutationauctionServiceCreateArgs) =>
  ApiRequest<{ auctionServiceCreate: AuctionService }>({
    data: {
      operationName: 'auctionServiceCreate',
      query: `
        mutation (
          $auctionId: String!,
          $auctionServiceMetadataId: String!,
          $buyerShield: AuctionServiceTriState,
          $companyId: String,
          $companySubTypes: [CompanySubType],
          $feeFixedMode: AuctionFeeMode,
          $feeFixedValue: BigDecimal,
          $feeTiers: [AuctionServiceTierInput],
          $feeType: AuctionServiceType!,
          $format: AuctionItemFormat!,
          $paidBy: AuctionServiceMetadataParty,
          $verified: AuctionServiceTriState
        ) {
          auctionServiceCreate (
            auctionId: $auctionId,
            auctionServiceMetadataId: $auctionServiceMetadataId,
            buyerShield: $buyerShield,
            companyId: $companyId,
            companySubTypes: $companySubTypes,
            feeFixedMode: $feeFixedMode,
            feeFixedValue: $feeFixedValue,
            feeTiers: $feeTiers,
            feeType: $feeType,
            format: $format,
            paidBy: $paidBy,
            verified: $verified,
          ) {
            id          
          }
        }
    `,
      variables: {
        auctionId,
        auctionServiceMetadataId,
        buyerShield,
        companyId,
        companySubTypes,
        feeFixedMode,
        feeFixedValue,
        feeTiers,
        feeType,
        format,
        paidBy,
        verified,
      },
    },
  });

export const auctionItemAuctionServiceCreate = ({
  auctionItemId,
  auctionServiceMetadataId,
  feeFixedMode,
  feeFixedValue,
  feeTiers,
  feeType,
}: MutationauctionItemAuctionServiceCreateArgs) =>
  ApiRequest<{ auctionItemAuctionServiceCreate: AuctionService }>({
    data: {
      operationName: 'auctionItemAuctionServiceCreate',
      query: `
        mutation (
          $auctionItemId: String!,
          $auctionServiceMetadataId: String!,
          $feeFixedMode: AuctionFeeMode,
          $feeFixedValue: BigDecimal,
          $feeTiers: [AuctionServiceTierInput],
          $feeType: AuctionServiceType!
        ) {
          auctionItemAuctionServiceCreate (
            auctionItemId: $auctionItemId,
            auctionServiceMetadataId: $auctionServiceMetadataId,
            feeFixedMode: $feeFixedMode,
            feeFixedValue: $feeFixedValue,
            feeTiers: $feeTiers,
            feeType: $feeType
          ) {
            id          
          }
        }
    `,
      variables: {
        auctionItemId,
        auctionServiceMetadataId,
        feeFixedMode,
        feeFixedValue,
        feeTiers,
        feeType,
      },
    },
  });

export const auctionServiceDefaultSet = ({
  companyAuctionRelationshipId,
  defaultType,
  input,
}: MutationauctionServiceDefaultSetArgs) =>
  ApiRequest<{ auctionServiceDefaultSet: CompanyAuctionRelationship }>({
    data: {
      operationName: 'auctionServiceDefaultSet',
      query: `
        mutation (
          $companyAuctionRelationshipId: ID!
          $defaultType: AuctionServiceMetadataDefaultType!
          $input: [AuctionServiceDefaultSetInput]!
        ) {
          auctionServiceDefaultSet(
            companyAuctionRelationshipId: $companyAuctionRelationshipId
            defaultType: $defaultType
            input: $input
          ) {
            id
            auctionServiceDefaults {
              id
              auctionServiceMetadata {
                id
                defaultSelected
                feeName
                optional
                serviceName
                transportService
              }
              defaultSelected
            }
          }
        }
      `,
      variables: {
        companyAuctionRelationshipId,
        defaultType,
        input,
      },
    },
  });

export const removeAuctionFee = ({ auctionServiceId }: MutationauctionServiceRemoveArgs) =>
  ApiRequest<{ auctionServiceRemove: boolean }>({
    data: {
      operationName: 'removeAuctionFee',
      query: `
        mutation ($auctionServiceId: String!) {
          auctionServiceRemove (auctionServiceId: $auctionServiceId)
        }
    `,
      variables: {
        auctionServiceId,
      },
    },
  });
