import { createAction } from 'redux-actions';

import { getBroadcastsList } from './broadcastsApi';
import { parseQueryParams } from 'utils/apiUtils';

export const broadcastsListIsLoading = createAction('ADMIN_BROADCASTS_LOADING');
export const broadcastsListLoaded = createAction('ADMIN_BROADCASTS_LOADED');
export const broadcastsListCleared = createAction('ADMIN_BROADCASTS_CLEARED');
export const broadcastsListUpdateItem = createAction('ADMIN_BROADCASTS_LIST_UPDATE_ITEM');

export const processGetBroadcastsList = (options, dispatch) => {
  dispatch(broadcastsListIsLoading());

  return getBroadcastsList(parseQueryParams(options)).then((response) => {
    const formattedResponse = response?.data.data?.broadcastsConnection;
    dispatch(broadcastsListLoaded(formattedResponse));
    return formattedResponse;
  });
};
