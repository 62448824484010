import ApiRequest from 'store/shared/api/apiRequest';
import { PaymentType, QuerypaymentTypesArgs } from 'store/shared/api/graph/interfaces/types';

export const getPaymentTypes = ({ auctionId, auctionItemId, companyId, paymentSubType }: QuerypaymentTypesArgs) =>
  ApiRequest<{ paymentTypes: PaymentType[] }>({
    data: {
      operationName: 'getPaymentTypes',
      query: `
        query($auctionId: String!, $auctionItemId: String, $companyId: String, $paymentSubType: PaymentSubType) {
          paymentTypes(auctionId: $auctionId, auctionItemId: $auctionItemId, companyId: $companyId, paymentSubType: $paymentSubType) {
            id
            active
            defaultType
            logicalName
            name
            subType
          }
        }
      `,
      variables: {
        auctionId,
        auctionItemId,
        companyId,
        paymentSubType,
      },
    },
  });
