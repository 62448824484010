import OTPInput from 'react-otp-input';
import classnames from 'classnames';
import { useCallback, useState } from 'react';

import style from './oneTimeCodeInput.scss';

// TODO: Add tests

interface Props {
  /** CSS style to override default style. */
  className?: string;
  /** CSS style to override default style. */
  inputClassName?: string;
  /**
   * The number of input characters.
   * @default 4
   */
  inputLength?: number;
  /** Callback function when the input has changed */
  onChange?: (value: string) => void;
  /** Callback function when the input length matches the max length */
  onSubmit?: (value: string) => void;
}

const OneTimeCodeInput = ({ className, inputClassName, inputLength = 4, onChange, onSubmit }: Props) => {
  const [code, setCode] = useState<string>('');

  /**
   * onChange
   */
  const onInputChange = useCallback(
    (value: string) => {
      setCode(value);
      onChange?.(value);

      if (value?.length === inputLength) {
        onSubmit?.(value);
      }
    },
    [inputLength, onChange, onSubmit]
  );

  return (
    <OTPInput
      containerStyle={className}
      inputStyle={classnames(style.input, inputClassName)}
      numInputs={inputLength}
      onChange={onInputChange}
      renderInput={(props) => <input {...props} />}
      value={code}
    />
  );
};

export default OneTimeCodeInput;
