import { SelectOption } from 'utils/interfaces/SelectOption';
import { t } from 'utils/intlUtils';

/**
 * Finds empty strings in an object and replaces their values with `null`
 *
 * @param obj
 * @returns {obj}
 */
export const stripEmptyStrings = (obj) => {
  const formattedObj = { ...obj };
  Object.keys(formattedObj).forEach((key) => {
    const entry = formattedObj[key];
    if (typeof entry === 'string' && !entry.length) {
      formattedObj[key] = null;
    }
  });
  return formattedObj;
};

/**
 * Creates an immutable array of checked checkboxes,
 * that adds or removes the last toggled item depending on it's checked state.
 *
 * @param {MouseEvent} e
 * @param {string} value
 * @param {array} array
 */
export const getCheckboxArray = (e, value, array) => {
  return e.target.checked ? [...array, value] : array.filter((val) => val !== value);
};

/** Enum value */
export type EnumValue<T extends Record<keyof T, string>> = T[keyof T];

/** Translation map type. */
export type EnumTranslationMap<T> = Record<keyof T, string>;

/**
 * Generate an array of select options that are compatible with select dropdowns for the enum
 *
 * @param enumObject The enum object to generate the select options for.
 * @param enumTranslationMap A map where key is enum's key and value is the corresponding translation key.
 * @returns {SelectOption[]}
 */
export const getSelectOptionsForEnum = <T extends Record<keyof T, EnumValue<T>>>(
  enumObject: T | null | undefined,
  enumTranslationMap: EnumTranslationMap<T> | null | undefined
): SelectOption<EnumValue<T>>[] => {
  if (enumObject && enumTranslationMap) {
    return Object.keys(enumObject).map((key) => {
      const translation = t(enumTranslationMap[key]);
      return {
        label: translation,
        value: enumObject[key],
      };
    });
  }
  return [];
};
