import classnames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Button from 'components/ui/shared/button';
import { Key } from 'constants/enums/key';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './dialog.scss';

interface Props {
  /** The rendered child elements. */
  children: ReactNode;

  /** CSS styling to overwrite default dialog style. */
  className?: string;

  /** CSS styling to overwrite default dialog content style. */
  contentClassName?: string;

  /** Dialog content id used for testing. */
  contentDataTestId?: string;

  /** CSS styling to overwrite default dialog inner content style. */
  contentInnerClassName?: string;

  /** Dialog id used for testing. */
  dataTestId?: string;

  /** True when dialog has fixed, full screen positioning. */
  isFixed?: boolean;

  /** True when dialog should be rendered. */
  isOpen: boolean;

  /** True when clicking on dialog should preventDefault on event. */
  isPreventingDefaultOnClick?: boolean;

  /** Function invoked on dialog close. */
  requestClose?: () => void;

  /** Function to trigger form submission via ENTER key */
  requestSubmit?: () => void;

  /** True when the overlay button should be rendered. */
  showOverlay?: boolean;
}

const Dialog = ({
  children,
  className,
  contentClassName,
  contentDataTestId,
  contentInnerClassName,
  dataTestId,
  isFixed = true,
  isOpen,
  isPreventingDefaultOnClick,
  requestClose = () => {},
  requestSubmit = () => {},
  showOverlay = true,
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(document.getElementById('modal-root'));
  const dialogContainerRef = useRef<HTMLDivElement>(document.createElement('div'));

  /**
   * Remove dialog from dom
   */
  const removeDialog = useCallback(() => {
    // Removes dialog container from DOM
    try {
      dialogContainerRef.current?.remove();
    } catch (e) {
      console.warn('Failed to remove dialog');
    }
  }, [dialogContainerRef]);

  /**
   * Handle key down event
   */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // ESC
      if (isOpen && e.key === (Key.Escape as string)) {
        requestClose?.();
      }

      // ENTER
      if (isOpen && e.key === (Key.Enter as string)) {
        requestSubmit?.();
      }
    },
    [isOpen, requestClose, requestSubmit]
  );

  /**
   * On mount, create modal-root if not exist
   */
  useMountEffect(() => {
    let root = document.getElementById('modal-root');

    if (!root) {
      root = document.createElement('div');
      root.id = 'modal-root';
      document.body.appendChild(root);
    }
    setModalRoot(root);
    return () => {
      // Remove dialog on unmount
      removeDialog();
    };
  });

  /**
   * When open and visible state changed, determine whether to show the dialog or not
   */
  useEffect(() => {
    if (isOpen && !isVisible) {
      modalRoot?.appendChild(dialogContainerRef.current);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsVisible(true);
        });
      });
    } else if (!isOpen && isVisible) {
      removeDialog();
      setIsVisible(false);
    }
  }, [dialogContainerRef, handleKeyDown, isOpen, isVisible, modalRoot, removeDialog]);

  /**
   * Listen for keydown events
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  /**
   * Dialog content
   */
  const dialog = useMemo(() => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={classnames(style.dialog, isFixed && style.isFixed, isVisible && style.isOpen, className)}
        data-testid={dataTestId || 'dialog-container'}
        onClick={(e) => {
          if (isPreventingDefaultOnClick) {
            e.preventDefault();
          }
        }}
      >
        {!!showOverlay && (
          <Button className={style.overlay} dataTestId="dialog-close-button" onClick={requestClose} theme="none">
            &nbsp;
          </Button>
        )}
        <div
          className={classnames(style.content, contentClassName)}
          data-testid={contentDataTestId || 'dialog-content'}
        >
          <div className={classnames(style.contentInner, contentInnerClassName)}>{children}</div>
        </div>
      </div>
    );
  }, [
    children,
    className,
    contentClassName,
    contentDataTestId,
    contentInnerClassName,
    dataTestId,
    isFixed,
    isPreventingDefaultOnClick,
    isVisible,
    requestClose,
    showOverlay,
  ]);

  if (!isOpen) {
    return null;
  }

  return isFixed ? createPortal(dialog, dialogContainerRef.current) : dialog;
};

export default Dialog;
