import { List, Record as ImmutableRecord } from 'immutable';

import { NullableRecordProps } from 'store/shared/models/record';
import { RecordFactory, RecordOf } from './interfaces';

export type ServerRecordOf<TProps extends object> = RecordOf<ServerRecordState<TProps>>;

export type ServerRecordState<TProps extends object = any> = {
  /** Whether the server record is loading or not. */
  isLoading: boolean;

  /** Whether the server record is updating or not. */
  isUpdating: boolean;

  /** The loaded date for the server record. */
  loadedDate: number | null;

  /** Error occurred during load or update. */
  _error: any;

  /** Set loading state */
  setLoading: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /** Unset loading state */
  unsetLoading: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /** Set updating state */
  setUpdating: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /** Unset updating state */
  unsetUpdating: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /** Set loaded state */
  setLoaded: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /**
   * Set error state
   * @param error Error message
   */
  setError: (error: Error | string | List<Error>) => ReturnType<RecordFactory<ServerRecordState<TProps>>>;

  /** Clear error state */
  clearError: () => ReturnType<RecordFactory<ServerRecordState<TProps>>>;
} & TProps;

/**
 * This factory will return an Immutable Record factory
 * function that will have the props you specify as well
 * as the loading props.
 *
 * Use this to make models that contain data from the server.
 */
const ServerRecord = <TProps extends object>(
  props: NullableRecordProps<TProps>
): RecordFactory<ServerRecordState<TProps>> => {
  class ImmutableServerRecord extends ImmutableRecord<ServerRecordState>({
    isLoading: false,
    isUpdating: false,
    loadedDate: null,
    _error: null,
    ...props,
  }) {
    setLoading() {
      return this.merge({
        isLoading: true,
        isUpdating: false,
        loadedDate: null,
        _error: null,
      });
    }

    unsetLoading() {
      return this.merge({
        isLoading: false,
        isUpdating: false,
        loadedDate: null,
        _error: null,
      });
    }

    setUpdating() {
      return this.merge({
        isUpdating: true,
        _error: null,
      });
    }

    unsetUpdating() {
      return this.merge({
        isUpdating: false,
        _error: null,
      });
    }

    setLoaded() {
      return this.merge({
        isLoading: false,
        isUpdating: false,
        loadedDate: new Date().getTime(),
        _error: null,
      });
    }

    setError(errorValue) {
      return this.merge({
        isLoading: false,
        isUpdating: false,
        _error: errorValue,
      });
    }

    clearError() {
      return this.merge({
        isLoading: false,
        isUpdating: false,
        _error: null,
      });
    }
  }
  return ImmutableServerRecord as unknown as RecordFactory<ServerRecordState<TProps>>;
};

export default ServerRecord;
