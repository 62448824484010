import classnames from 'classnames';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';

import calendarGlyph from 'glyphs/calendar.svg';
import circleXGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import Sprite from 'components/ui/shared/sprite';

import style from './dateRangePicker.scss';

type WrappedDateRangePickerProps = DateRangePickerShape & {
  className?: string;
  inputIconClassName?: string;
};

const WrappedDateRangePicker = ({ className, inputIconClassName, ...props }: WrappedDateRangePickerProps) => (
  <div className={classnames(style.datePicker, className)}>
    <DateRangePicker
      customCloseIcon={<Sprite className={style.clearFilterSprite} glyph={circleXGlyph} />}
      customInputIcon={
        props.startDate || !!props.endDate ? null : (
          <Sprite className={classnames(style.calendarIcon, inputIconClassName)} glyph={calendarGlyph} />
        )
      }
      endDateAriaLabel="End Date"
      showClearDates={!!props.startDate || !!props.endDate}
      startDateAriaLabel="Start Date"
      {...props}
    />
  </div>
);

export default WrappedDateRangePicker;
