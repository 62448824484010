import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import UserType from 'constants/user';
import { RecordOf } from 'store/shared/models/interfaces';
import { UserCompanyRelationship as UserCompanyRelationshipType } from 'store/shared/api/graph/interfaces/types';

interface UserDetailsModel {
  /** User's information. */
  details: RecordOf<UserType>;
  /** Whether is loading user company relationships or not. */
  isLoadingUserRelationships: boolean;
  /** User company relationships. */
  userRelationships: List<RecordOf<UserCompanyRelationshipType>>;
}

export type UserDetailsProps = ServerRecordOf<UserDetailsModel>;
export type UserDetailsPropsJs = ReturnType<UserDetailsProps['toJS']>;

export const InitialState = ServerRecord<UserDetailsModel>({
  details: null,
  isLoadingUserRelationships: false,
  userRelationships: null,
});

export const User = Record<UserType>({
  auctionAccessId: null,
  auctionAccessIssueLogs: [],
  auctionAccessLastVerified: null,
  auctionAccessStatus: null,
  cellPhone: null,
  channels: [],
  companyRelationships: null,
  created: null,
  distanceUnit: null,
  email: null,
  emailVerified: false,
  employee: false,
  firstName: null,
  id: null,
  internal: false,
  lastLoggedIn: null,
  lastName: null,
  locale: null,
  locked: false,
  mainPhotoMain: null,
  mainPhotoThumb: null,
  notes: [],
  notificationDevices: [],
  omvicRegExpiryDate: null,
  omvicRegNumber: null,
  phoneVerified: false,
  photoUrl: null,
  preferredLocation: null,
  savedFilters: [],
  secondaryPhone: null,
  sessionActingAsOtherUser: null,
  source: null,
  status: null,
  timeZone: null,
  title: null,
  updated: null,
});

export const UserCompanyRelationship = Record<UserCompanyRelationshipType>({
  company: null,
  created: null,
  department: null,
  permissions: null,
  roles: [],
  status: null,
  user: null,
});
