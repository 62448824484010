import classnames from 'classnames';

import buyerShieldGlyph from 'glyphs/buyerShield.svg';

import BooleanText from 'components/ui/shared/booleanText';
import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import ScoreText from 'components/ui/shared/scores/scoreText';
import Sprite from 'components/ui/shared/sprite';
import { isAutogradeEnabled } from 'utils/inventoryItemUtils';
import { isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';
import { rangeCA, rangeUS } from 'components/ui/shared/scores/scoreRanges';
import { t } from 'utils/intlUtils';

import style from './scoresRow.scss';

interface ScoresRowProps {
  inventoryItem: InventoryItem;
  onClick: (type, inventoryItem) => void;
}

/**
 * Shows a row of the BuyerShield values for a particular inventory item
 */
const BuyerShieldValues = ({ inventoryItem, onClick }: ScoresRowProps) => {
  return (
    <div className={style.crRowCellInner}>
      <div className={style.crRowTitle}>{t('buyer_shield')}</div>
      <Button
        className={classnames(style.crRowContent, style.crScore)}
        onClick={() => onClick('SCORE', inventoryItem)}
        theme="none"
      >
        <ul className={style.crRowList}>
          <li data-testid="buyershield-engine-score">
            <strong>
              <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />
              {`${t('engine')}: `}
              <BooleanText isColorized value={!!inventoryItem?.engineBuyerShield} />
            </strong>
          </li>
          <li data-testid="buyershield-transmission-score">
            <strong>
              <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />
              {`${t('transmission')}: `}
              <BooleanText isColorized value={!!inventoryItem?.transmissionBuyerShield} />
            </strong>
          </li>
          <li data-testid="buyershield-drivetrain-score">
            <strong>
              <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />
              {`${t('drivetrain')}: `}
              <BooleanText isColorized value={!!inventoryItem?.drivetrainBuyerShield} />
            </strong>
          </li>
        </ul>
      </Button>
    </div>
  );
};

/**
 * Shows our legacy score values; used for backwards compatibility.
 * Displayed when tire, drivetrain, transmission, and engine scores are not set.
 */
const ExtensiveVehicleScoreLegacy = ({ inventoryItem, onClick }: ScoresRowProps) => {
  const range = inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;

  return (
    <div className={style.crRow} data-testid="extensive-vehicle-score-legacy">
      <div className={style.crRowTitle}>{t('scores')}</div>
      <Button
        className={classnames(style.crRowContent, style.crScore)}
        onClick={() => onClick('SCORE', inventoryItem)}
        theme="none"
      >
        <ul className={style.crRowList}>
          <li data-testid="engine-rating">
            {inventoryItem?.engineBuyerShield && <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />}
            <strong>
              {t('engine')}:{' '}
              {inventoryItem?.mechanicalEngineRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.mechanicalEngineRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
          <li data-testid="transmission-rating">
            {inventoryItem?.transmissionBuyerShield && (
              <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />
            )}
            <strong>
              {t('transmission')}:{' '}
              {inventoryItem?.mechanicalTransmissionRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.mechanicalTransmissionRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
          <li data-testid="drivetrain-rating">
            {inventoryItem?.drivetrainBuyerShield && (
              <Sprite className={style.buyerShieldScore} glyph={buyerShieldGlyph} />
            )}
            <strong>
              {t('drivetrain')}:{' '}
              {inventoryItem?.mechanicalDrivetrainRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.mechanicalDrivetrainRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
          <li data-testid="exterior-rating">
            <strong>
              {t('exterior')}:{' '}
              {inventoryItem?.exteriorRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.exteriorRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
          <li data-testid="interior-rating">
            <strong>
              {t('interior')}:{' '}
              {inventoryItem?.interiorRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.interiorRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
          <li data-testid="tires-rating">
            <strong>
              {t('tires')}:{' '}
              {inventoryItem?.tiresRating !== null ? (
                <ScoreText range={range} score={inventoryItem?.tiresRating} />
              ) : (
                '-'
              )}
            </strong>
          </li>
        </ul>
      </Button>
    </div>
  );
};

const ExtensiveVehicleScore = ({ inventoryItem, onClick }: ScoresRowProps) => {
  const range = inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;

  return (
    <div className={style.crRow} data-testid="extensive-vehicle-score">
      <div className={style.crRowCell}>
        <div className={style.crRowCellInner}>
          <div className={style.crRowTitle}>{t('score')}</div>
          <Button
            className={classnames(style.crRowContent, style.crScore)}
            onClick={() => onClick('SCORE', inventoryItem)}
            theme="none"
          >
            <ul className={style.crRowList}>
              <li data-testid="mechanical-rating">
                <strong>
                  {t('mechanical')}: <ScoreText range={range} score={inventoryItem?.mechanicalRating} />
                </strong>
              </li>
              <li data-testid="interior-rating">
                <strong>
                  {t('interior')}: <ScoreText range={range} score={inventoryItem?.interiorRating} />
                </strong>
              </li>
              <li data-testid="exterior-rating">
                <strong>
                  {t('exterior')}: <ScoreText range={range} score={inventoryItem?.exteriorRating} />
                </strong>
              </li>
            </ul>
          </Button>
        </div>

        {inventoryItem?.vehicleBuyerShield && <BuyerShieldValues inventoryItem={inventoryItem} onClick={onClick} />}
      </div>
    </div>
  );
};

const AutoGradeVehicleScore = ({ inventoryItem, onClick }: ScoresRowProps) => {
  return (
    <div className={style.crRow} data-testid="auto-grade-vehicle-score">
      <div className={style.crRowCell}>
        <BuyerShieldValues inventoryItem={inventoryItem} onClick={() => {}} />
      </div>
    </div>
  );
};

const ScoresRow = (props: ScoresRowProps) => {
  const { captureType, mechanicalDrivetrainRating, mechanicalEngineRating, mechanicalTransmissionRating, tiresRating } =
    props?.inventoryItem;

  if (isAutogradeEnabled(props?.inventoryItem) && props?.inventoryItem?.vehicleBuyerShield) {
    return <AutoGradeVehicleScore {...props} />;
  }

  const verifiedExtensiveVehicleCondition = isVerifiedExtensiveVehicleCondition(captureType);
  if (verifiedExtensiveVehicleCondition) {
    if (!mechanicalDrivetrainRating && !mechanicalEngineRating && !mechanicalTransmissionRating && !tiresRating) {
      // Display new score set when tire, drivetrain, transmission, and engine scores are not set
      return <ExtensiveVehicleScore {...props} />;
    }
    // Otherwise; show our legacy score set
    return <ExtensiveVehicleScoreLegacy {...props} />;
  }
  return null;
};

export default ScoresRow;
