import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Location } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export type LocationsType = {
  /** Destination locations. */
  destinationLocations: Location[];
  /** Pickup locations. */
  pickupLocations: Location[];
};

export interface AddJobModel {
  /** Pickup and destination locations information. */
  locations: RecordOf<LocationsType>;
  /** Pickup consigner id. */
  pickupConsignerId: string;
  /** Destination consigner id. */
  destinationConsignerId: string;
}

export type AddJobProps = ServerRecordOf<AddJobModel>;
export type AddJobPropsJs = ReturnType<ServerRecordOf<AddJobModel>['toJS']>;

export const Locations = Record<LocationsType>({
  destinationLocations: [],
  pickupLocations: [],
});

export const InitialState = ServerRecord<AddJobModel>({
  locations: new Locations(),
  pickupConsignerId: null,
  destinationConsignerId: null,
});
