import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { InventoryItem, PageInfo, FacetGroup, Facet, InventoryItemsResults } from './inventoryItemsListModels';
import { inventoryItemAddNote } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import {
  inventoryItemsListIsLoading,
  inventoryItemsListLoaded,
  inventoryItemsListClear,
  inventoryItemsListUpdate,
} from './inventoryItemsListActions';
import { isLatestRequestSequence } from 'utils/apiUtils';

export const inventoryItemsListReducer = handleActions(
  {
    [inventoryItemsListIsLoading().type]: (state) => state.set('isLoading', true),

    [inventoryItemsListLoaded().type]: (state, action) => {
      if (!isLatestRequestSequence(action.payload?.requestSequence)) {
        return state;
      }

      const inventoryItemConnectionResponse = action.payload?.inventoryItemConnection;

      return state.setLoaded().merge({
        resultList: List(
          inventoryItemConnectionResponse?.edges?.map((result) => {
            return new InventoryItem({
              ...result.node,
            });
          })
        ),
        pageInfo: new PageInfo(inventoryItemConnectionResponse?.pageInfo),
        facetGroups: List(
          inventoryItemConnectionResponse?.facetGroups.map((result) => {
            return new FacetGroup({
              ...result,
              facets: List(
                result.facets.map((facet) => {
                  return new Facet({
                    ...facet,
                  });
                })
              ),
            });
          })
        ),
      });
    },

    [inventoryItemsListClear().type]: (state) => {
      return state.unsetLoading().merge(new InventoryItemsResults());
    },

    [inventoryItemsListUpdate().type]: (state) => state.setUpdating(),

    [inventoryItemAddNote().type]: (state, action) => {
      const { inventoryItemId, note } = action.payload;
      const { resultList } = state;

      const index = resultList.findIndex((item) => item?.id === inventoryItemId);
      if (index !== -1) {
        const inventoryItem = resultList.get(index)!.toJS();
        inventoryItem?.notes?.push(note);

        return state.merge({ resultList: resultList.set(index, new InventoryItem(inventoryItem)) });
      }

      return state;
    },
  },
  new InventoryItemsResults()
);
