import classnames from 'classnames';

import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';
import { VehicleConditionReport, VehiclePaintCondition } from 'store/shared/api/graph/interfaces/types';

import style from './paint.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    conditionReport: VehicleConditionReport;
    paintCondition: VehiclePaintCondition;
  };
}

const InventoryItemDetailsPaint = ({ inventoryDetailsData }: Props) => {
  const paintCondition = inventoryDetailsData.paintCondition;
  const panelsRepainted = inventoryDetailsData.conditionReport.repaintOnHowManyPanels;
  const panelsRepaintedStrings = {
    ONE_TO_TWO_PANELS: 'repaint_one_to_two_panels',
    THREE_TO_FOUR_PANELS: 'repaint_three_to_four_panels',
    FIVE_TO_ENTIRE_VEHICLE: 'repaint_five_to_entire_vehicle',
  };

  return (
    <div>
      <div className={style.summarySection}>
        <ul>
          <li className={classnames(style.listItem, style.accentuated, !!panelsRepainted && style.red)}>
            <div>{t('vehicle_repainted')}</div>
            <div>{t(panelsRepainted ? 'yes' : 'no')}</div>
          </li>
          {!!panelsRepainted && (
            <li className={classnames(style.listItem, style.accentuated, style.repaintedPanels)}>
              <div>{t('how_many_repainted_panels')}</div>
              <div>{t(panelsRepaintedStrings[panelsRepainted])}</div>
            </li>
          )}
        </ul>
      </div>
      <ul>
        <li className={style.listItem}>
          <div>{t('hood')}</div>
          <div>{paintCondition?.hoodDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('driver_fender')}</div>
          <div>{paintCondition?.driverFenderDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('driver_front_door')}</div>
          <div>{paintCondition?.driverFrontDoorDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('driver_rear_door')}</div>
          <div>{paintCondition?.driverRearDoorDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('driver_quarter_panel')}</div>
          <div>{paintCondition?.driverQuarterPanelDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('roof')}</div>
          <div>{paintCondition?.roofDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('passenger_fender')}</div>
          <div>{paintCondition?.passengerFenderDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('passenger_front_door')}</div>
          <div>{paintCondition?.passengerFrontDoorDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('passenger_rear_door')}</div>
          <div>{paintCondition?.passengerRearDoorDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('passenger_quarter_panel')}</div>
          <div>{paintCondition?.passengerQuarterPanelDepth}</div>
        </li>
        <li className={style.listItem}>
          <div>{t('trunk')}</div>
          <div>{paintCondition?.trunkDepth}</div>
        </li>
      </ul>
    </div>
  );
};

export default InventoryItemDetailsPaint;
