import classnames from 'classnames';

import userGlyph from 'glyphs/user_menu.svg';

import ImageOrSprite from './imageOrSprite';

import style from './userImage.scss';

interface Props {
  /** CSS styling to overwrite image container style. */
  className?: string;

  /** CSS styling to overwrite default container style. */
  containerClassName?: string;

  /** The image source URL. */
  imageUrl: string | undefined;
}

const UserImage = ({ containerClassName, className, imageUrl }: Props) => (
  <ImageOrSprite
    alt="User image"
    className={classnames(style.img, className)}
    containerClassName={classnames(style.container, containerClassName)}
    glyph={userGlyph}
    imageUrl={imageUrl}
  />
);

export default UserImage;
