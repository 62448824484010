import classnames from 'classnames';
import { MouseEvent, MouseEventHandler, useCallback } from 'react';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import { Chevron } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './conditionReport.scss';

interface Props {
  /** Callback function to handle feature click event. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: InventoryItem) => void;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** Callback function to check if the field is required or not. */
  isRequiredField: (field: string) => boolean;
  /** Function invoked on paint button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PaintButton = ({ handleFeatureClicked, inventoryItemDetails, isRequiredField, onClick }: Props) => {
  /**
   * Click handler for paint button
   */
  const onButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      handleFeatureClicked('PAINT', inventoryItemDetails);
      onClick?.(event);
    },
    [handleFeatureClicked, inventoryItemDetails, onClick]
  );

  return (
    <Button
      className={classnames(style.crButton, style.crButtonSm, isRequiredField('paintCondition') && style.isRequired)}
      dataTestId="paint-condition-button"
      onClick={onButtonClick}
      theme="none"
    >
      <span className={style.crButtonSubText}>{t('paint')}</span>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default PaintButton;
