import { handleActions } from 'redux-actions';

import { InitialState } from './auctionDetailsModels';
import {
  auctionDetailsCleared,
  auctionDetailsIsLoading,
  auctionDetailsLoaded,
  auctionDetailsUpdated,
  auctionSettingsUpdated,
  preloadAuctionDetails,
} from 'store/admin/auctions/details/auctionDetailsActions';

export const auctionDetailsReducer = handleActions(
  {
    [auctionDetailsCleared().type]: () => new InitialState(),

    [auctionDetailsIsLoading().type]: (state) => state.set('isLoading', true),

    [preloadAuctionDetails().type]: (state, action) => {
      if (action?.payload?.id) {
        return state.setLoaded().set('details', action?.payload);
      }
      return state;
    },

    [auctionDetailsLoaded().type]: (state, action) => {
      return state?.setLoaded()?.set('details', action?.payload);
    },

    [auctionDetailsUpdated().type]: (state, action) => {
      return state.set('details', {
        ...state.get('details'),
        ...action.payload,
      });
    },

    [auctionSettingsUpdated().type]: (state, action) => {
      return state.set('details', {
        ...state.get('details'),
        ...action.payload,
      });
    },
  },
  new InitialState()
);
