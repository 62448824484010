import { ComponentType } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from 'store/configureStore';
import { Route } from 'store/routing/routes';
import { RouterProps } from 'constants/reactRouter';
import { hasSystemPermission, isAuctionStaff } from 'utils/userUtils';
import { useMountEffect } from 'hooks/useMountEffect';

interface Props {
  /** The rendered component, if user is authorized. */
  component: ComponentType<any>;
  /** Authorized system permissions needed to view component. User needs one of the provided permissions. */
  authorizedSystemPermissions?: string[];
  /** True when a route shouldn't be visible to staff users */
  isNonStaffRoute?: boolean;
  /** True when a route should be restricted to staff users */
  isStaffUser?: boolean;
}

/**
 A higher order component which prevents the wrapped component
 from rendering if the user does not meet the `authorizedRoles`
 or `authorizedSystemPermissions` specified.

 @example
 restrictedRoute({
		component: ExampleComponent,

		// Limits access to users with listed system permissions:
		authorizedSystemPermissions: ['SYSTEM_VIEW_ALL_USERS'],

   // Limits to staff users
   isStaffUser: true,
	})
 */
const restrictedRoute = ({
  component: Component,
  authorizedSystemPermissions,
  isNonStaffRoute = false,
  isStaffUser = false,
}: Props) => {
  const stateConnect = (state: AppState) => ({
    /** Current logged in user. */
    user: state.app.user,
  });

  const connector = connect(stateConnect);
  type RestrictedRouteProps = RouterProps & ConnectedProps<typeof connector>;

  const RestrictedRoute = (props: RestrictedRouteProps) => {
    const { router, user } = props;

    const hasAuthorizedSystemPermissions = hasSystemPermission(user, authorizedSystemPermissions);
    const isAuthorizedStaffUser = isStaffUser && isAuctionStaff(user);
    const isAuthorizedNonStaffUser = isNonStaffRoute && !isAuctionStaff(user);
    const isUserAuthorized =
      (isAuthorizedStaffUser && !isAuthorizedNonStaffUser) ||
      hasAuthorizedSystemPermissions ||
      isAuthorizedNonStaffUser;

    useMountEffect(() => {
      if (!isUserAuthorized) {
        // User not authorized to view this route; redirect to home dashboard
        router?.replace(Route.HOME);
      }
    });

    return isUserAuthorized ? <Component {...props} /> : null;
  };

  return connector(RestrictedRoute);
};

export default restrictedRoute;
