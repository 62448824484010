import { useState } from 'react';
import classnames from 'classnames';
import ImageLightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import BooleanText from 'components/ui/shared/booleanText';
import Button from 'components/ui/shared/button';
import Image from 'components/ui/shared/image';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { TireGeneralCondition, VehicleTireCondition } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './tires.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: VehicleTireCondition & { isVerified: boolean };
}

const InventoryItemDetailsTires = ({ inventoryDetailsData }: Props) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState<boolean>();

  /**
   * Render verified tire info
   */
  const renderVerifiedTireInfo = () => {
    return (
      <div>
        <div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('tpms')}</div>
                <div>
                  <BooleanText value={inventoryDetailsData?.tirePressureMonitoringSystem} />
                </div>
              </li>
              {!!inventoryDetailsData?.tirePressureMonitoringSystemPhotoUrl && (
                <li className={style.photoContainer}>
                  <Button className={style.photoButton} onClick={() => setIsLightBoxOpen(true)} theme="none">
                    <Image
                      alt={t('tpms')}
                      className={style.photo}
                      src={inventoryDetailsData?.tirePressureMonitoringSystemPhotoUrl}
                    />
                  </Button>
                </li>
              )}
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('aftermarket_wheels')}</div>
                <div>
                  <BooleanText value={inventoryDetailsData?.afterMarketTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('winter_tires')}</div>
                <div>
                  <BooleanText value={inventoryDetailsData?.winterTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('studded_winter_tires')}</div>
                <div>
                  <BooleanText value={inventoryDetailsData?.studdedWinterTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('second_set_of_tires')}</div>
                <div className={style.secondSetsOfTires}>
                  <div>
                    <BooleanText value={inventoryDetailsData?.secondSetOfTires} />
                  </div>
                  {!!inventoryDetailsData?.secondSetOfTires && (
                    <>
                      <div className={style.separator}></div>
                      <div className={style.tireRims}>
                        {t(inventoryDetailsData?.secondSetOfTiresHasRims ? 'with_rims' : 'without_rims')}
                      </div>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('four_matching_tires')}</div>
                <div>
                  <BooleanText value={inventoryDetailsData?.fourMatchingTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('driver_front_tire')}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('brand')}</div>
                <div>{inventoryDetailsData?.driverFrontBrand}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tire_size')}</div>
                <div>{inventoryDetailsData?.driverFrontSize}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tread')}</div>
                <div>{inventoryDetailsData?.driverFrontTread?.formatted}</div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('passenger_front_tire')}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('brand')}</div>
                <div>{inventoryDetailsData?.passengerFrontBrand}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tire_size')}</div>
                <div>{inventoryDetailsData?.passengerFrontSize}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tread')}</div>
                <div>{inventoryDetailsData?.passengerFrontTread?.formatted}</div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('driver_rear_tire')}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('brand')}</div>
                <div>{inventoryDetailsData?.driverRearBrand}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tire_size')}</div>
                <div>{inventoryDetailsData?.driverRearSize}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tread')}</div>
                <div>{inventoryDetailsData?.driverRearTread?.formatted}</div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('passenger_rear_tire')}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('brand')}</div>
                <div>{inventoryDetailsData?.passengerRearBrand}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tire_size')}</div>
                <div>{inventoryDetailsData?.passengerRearSize}</div>
              </li>
              <li className={style.listItem}>
                <div>{t('tread')}</div>
                <div>{inventoryDetailsData?.passengerRearTread?.formatted}</div>
              </li>
            </ul>
          </div>
        </div>

        {isLightBoxOpen && inventoryDetailsData?.tirePressureMonitoringSystemPhotoUrl && (
          <ImageLightBox
            mainSrc={inventoryDetailsData?.tirePressureMonitoringSystemPhotoUrl}
            onCloseRequest={() => setIsLightBoxOpen(false)}
          />
        )}
      </div>
    );
  };

  /**
   * Render non-verified tire info
   */
  const renderNonVerifiedTireInfo = () => {
    const {
      afterMarketTires,
      fourMatchingTires,
      generalCondition,
      secondSetOfTires,
      secondSetOfTiresHasRims,
      studdedWinterTires,
      tirePressureMonitoringSystem,
      tirePressureMonitoringSystemPhotoUrl,
      winterTires,
    } = inventoryDetailsData;

    return (
      <div>
        <div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('tpms')}</div>
                <div>
                  <BooleanText value={tirePressureMonitoringSystem} />
                </div>
              </li>
              {!!tirePressureMonitoringSystemPhotoUrl && (
                <li className={style.photoContainer}>
                  <Button className={style.photoButton} onClick={() => setIsLightBoxOpen(true)} theme="none">
                    <Image alt={t('tpms')} className={style.photo} src={tirePressureMonitoringSystemPhotoUrl} />
                  </Button>
                </li>
              )}
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('aftermarket_wheels')}</div>
                <div>
                  <BooleanText value={afterMarketTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('winter_tires')}</div>
                <div>
                  <BooleanText value={winterTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('studded_winter_tires')}</div>
                <div>
                  <BooleanText value={studdedWinterTires} />
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('second_set_of_tires')}</div>
                <div className={style.secondSetsOfTires}>
                  <div>
                    <BooleanText value={secondSetOfTires} />
                  </div>
                  {!!secondSetOfTires && (
                    <>
                      <div className={style.separator}></div>
                      <div>{t(secondSetOfTiresHasRims ? 'with_rims' : 'without_rims')}</div>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>{t('four_matching_tires')}</div>
                <div>
                  <BooleanText value={fourMatchingTires} />
                </div>
              </li>
            </ul>
          </div>
          {!inventoryDetailsData.isVerified && (
            <div className={style.summarySection}>
              <ul>
                <li className={style.listItem}>
                  <div>{t('tire_condition')}</div>
                  <div
                    className={classnames(
                      generalCondition === TireGeneralCondition.POOR && style.red,
                      generalCondition === TireGeneralCondition.AVERAGE && style.yellow,
                      generalCondition === TireGeneralCondition.GOOD && style.green
                    )}
                  >
                    {generalCondition || '-'}
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>

        {isLightBoxOpen && tirePressureMonitoringSystemPhotoUrl && (
          <ImageLightBox
            mainSrc={tirePressureMonitoringSystemPhotoUrl}
            onCloseRequest={() => setIsLightBoxOpen(false)}
          />
        )}
      </div>
    );
  };

  return inventoryDetailsData?.isVerified ? renderVerifiedTireInfo() : renderNonVerifiedTireInfo();
};

export default InventoryItemDetailsTires;
