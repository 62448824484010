import classnames from 'classnames';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import BiddingAsSelector from 'components/sections/admin/selectConsigner/biddingAsSelector';
import Button from 'components/ui/shared/button';
import IncrementButtons from 'components/ui/shared/buttons/incrementButtons';
import { AuctionItem } from 'store/shared/api/graph/interfaces/types';
import { Spinner } from 'components/ui/loading/loading';
import { simpleAnimation } from 'utils/animationUtils';
import { t } from 'utils/intlUtils';
import { usePrevious } from 'hooks/usePrevious';

import style from './overlay.scss';

interface BidOverlayProps {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** The bid amount. */
  bidAmount: number | undefined;
  /** CSS styling to override the default style. */
  className?: string;
  /** The confirm button text */
  confirmButtonText?: string;
  /** True renders loading spinner. */
  isBidding: boolean;
  /** True when custom bid increment options should be hidden/disabled */
  isCustomBiddingDisabled?: boolean;
  /** True when current user has multiple user company relationships. */
  isGroupManager: boolean;
  /** True when current user is auction staff. */
  isStaffUser: boolean;
  /** Function invoked on cancel click. */
  onCancel?: (event?: MouseEvent) => void;
  /** Function invoked on confirm click. */
  onConfirm: (bidAmount?: number) => void;
}

const BidOverlay = ({
  auctionItem,
  bidAmount,
  className,
  confirmButtonText = t('confirm_bid'),
  isBidding,
  isCustomBiddingDisabled = false,
  isGroupManager,
  isStaffUser,
  onCancel,
  onConfirm,
}: BidOverlayProps) => {
  const bidAmountPrev = usePrevious(bidAmount);
  const bidAmountRef = useRef<HTMLLabelElement>(null);

  // Custom bid amount
  const [nextBidAmount, setNextBidAmount] = useState<number>(bidAmount ?? 0);

  // Animate bid amount to indicate to the user that incoming bids have increased the price of the unit.
  useEffect(() => {
    if (bidAmountPrev && bidAmountPrev !== bidAmount) {
      simpleAnimation(bidAmountRef.current, style.bulge);

      if ((bidAmount ?? 0) < bidAmountPrev) {
        // Reset bidAmount in state when value drops below it's previous value.
        // This may happen when the clerk decreases the starting bid.
        setNextBidAmount(bidAmount!);
      }
    }
  }, [bidAmount, bidAmountPrev]);

  return (
    <div
      className={classnames(style.bidOverlay, isBidding && style.isBidding, className)}
      data-testid="submit-bid-overlay"
    >
      {isBidding && <Spinner className={style.bidSpinner} />}
      <IncrementButtons
        disabled={isCustomBiddingDisabled}
        furtherBidIncrement={auctionItem?.furtherBidIncrement?.amount ?? 100}
        onChange={(nextValue: number) => setNextBidAmount(nextValue)}
        value={nextBidAmount}
      />
      <BiddingAsSelector
        auctionId={auctionItem?.auction?.id}
        isSelectEnabled={isStaffUser || isGroupManager}
        onCancel={onCancel}
      />
      <Button className={style.button} onClick={() => onConfirm(nextBidAmount)} theme="green">
        {confirmButtonText}
      </Button>
      <Button className={classnames(style.cancel, style.button)} onClick={onCancel} theme="gray-outline">
        {t('cancel')}
      </Button>
    </div>
  );
};

export default BidOverlay;
