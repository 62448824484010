/**
 * Use this if you want to have coordinated periodic callback functions. This
 * will consolidate interval callbacks into a single setInterval call. It will
 * also clean up after itself.
 */

const runLoops = {};

class RunLoop {
  fns: (<T>(instance: T) => void | undefined)[];
  interval: number;
  last: number;
  missed: boolean;
  pid: number;

  constructor(interval) {
    this.interval = interval;
    this.fns = [];
    this.pid = 0;
    this.last = 0;
    this.missed = false;
  }

  start() {
    if (!this.pid) {
      this.run();
      this.pid = window.setInterval(this.run.bind(this), this.interval);
    }
  }

  run() {
    const now = Date.now();
    const timeSinceLast = now - this.last;

    this.missed = this.last > 0 && timeSinceLast > 1.5 * this.interval;

    this.fns.forEach((fn) => {
      try {
        fn(this);
      } catch (ex) {
        console.warn(`Error in RunLoop: ${ex}`);
      }
    });

    this.last = now;
  }

  stop() {
    if (this.pid) {
      clearInterval(this.pid);
      this.pid = 0;
    }
  }

  add(fn) {
    const fnIdx = this.fns.indexOf(fn);
    if (fnIdx === -1) {
      this.fns.push(fn);
    }
  }

  remove(fn) {
    const fnIdx = this.fns.indexOf(fn);
    if (fnIdx !== -1) {
      this.fns.splice(fnIdx, 1);
    }
  }
}

/**
 * Run the supplied function every {interval} millisecons.
 *
 * @param interval {number}
 * @param fn {function}
 */
export const runEvery = (interval, fn) => {
  let runLoop = runLoops[interval];
  if (runLoop) {
    runLoop.add(fn);
  } else {
    runLoop = new RunLoop(interval);
    runLoops[interval] = runLoop;
    runLoop.add(fn);
    runLoop.start();
  }
};

/**
 * Stop running the supplied function every {interval} milliseconds.
 *
 * @param interval {number}
 * @param fn {function}
 */
export const stopEvery = (interval, fn) => {
  const runLoop = runLoops[interval];
  if (runLoop) {
    runLoop.remove(fn);
    if (runLoop.fns.length === 0) {
      runLoop.stop();
      delete runLoops[interval];
    }
  }
};

/**
 * Run the supplied function every second.
 *
 * @param fn {function}
 */
export const runEverySecond = (fn) => {
  runEvery(1000, fn);
};

/**
 * Stop running the supplied function every second.
 *
 * @param fn {function}
 */
export const stopEverySecond = (fn) => {
  stopEvery(1000, fn);
};
