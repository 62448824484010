import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import InputSwitch from 'forms/shared/inputSwitch';
import UserImage from 'components/ui/shared/images/userImage';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { TertiaryTitle } from 'layouts/list/listItemLayout';
import { getFilteredUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './showMyUnitsSwitch.scss';

interface Props extends RouterProps {
  /** CSS style to override default style. */
  className?: string;

  /** The URL parameter name. */
  parameterName?: string;

  /** The URL parameter value. */
  parameterValue?: boolean | string;

  /** CSS style to override default title style. */
  titleClassName?: string;
}

const ShowMyUnitsSwitch = ({
  className,
  location,
  parameterName = 'showMyUnits',
  parameterValue = 'true',
  router,
  titleClassName,
}: Props) => {
  const [isShowingMyUnits, setIsShowingMyUnits] = useState<boolean>(
    location?.query?.[parameterName] === parameterValue
  );

  useEffect(() => {
    setIsShowingMyUnits(location?.query?.[parameterName] === parameterValue);
  }, [location?.query, parameterName, parameterValue]);

  return (
    <div className={classnames(style.container, className)} data-testid="showUnits">
      <TertiaryTitle className={classnames(style.title, titleClassName)}>
        {isShowingMyUnits ? t('showing_your_units') : t('showing_all_units')}
      </TertiaryTitle>
      <InputSwitch
        className={style.inputSwitch}
        icon={<UserImage containerClassName={style.inputSwitchUserIcon} imageUrl={undefined} />}
        onChange={useCallback(
          (e) => {
            const showMyUnits = e?.currentTarget?.checked;
            const params = getFilteredUrlParams([parameterName]);
            const paramsNext = paramsToQueryString({
              ...params,
              ...(showMyUnits && { [parameterName]: parameterValue }),
            });

            router?.push(`${location?.pathname}?${paramsNext}`);
            setIsShowingMyUnits(e?.currentTarget?.checked);
          },
          [location?.pathname, parameterName, parameterValue, router]
        )}
        value={isShowingMyUnits}
      />
    </div>
  );
};

export default withRouter(ShowMyUnitsSwitch);
