import { MouseEvent, MouseEventHandler, useCallback } from 'react';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import { Chevron } from 'components/ui/shared/chevrons';
import { DetailsSection } from 'layouts/list/listLayout';
import { FeatureFlag } from 'constants/featureFlags';
import { LooseObject } from 'constants/objects';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { t } from 'utils/intlUtils';

import style from './seller.scss';

interface Props {
  /** Function invoked when inventory option clicked. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
  /** The id of the details section. */
  id?: string;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** Function invoked on button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AuctionItemSellerDetails = ({ handleFeatureClicked, id, inventoryItemDetails, onClick }: Props) => {
  const { company } = inventoryItemDetails;

  /**
   * Click handler for seller button
   */
  const onButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      handleFeatureClicked('SELLER');
      onClick?.(event);
    },
    [handleFeatureClicked, onClick]
  );

  if (!company) {
    return null;
  }

  return (
    <DetailsSection id={id} title={t('sold_by')}>
      <div className={style.details}>
        <Button className={style.detailsButton} dataTestId="seller-details-button" onClick={onButtonClick} theme="none">
          <div>
            <div className={style.companyName}>{company.name}</div>
            {hasFeatureFlag(FeatureFlag.NUMERIC_COMPANY_ID) && <div>{company.numericId}</div>}
          </div>
          <Chevron className={style.chevron} />
        </Button>
      </div>
    </DetailsSection>
  );
};

export default AuctionItemSellerDetails;
