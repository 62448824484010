import classnames from 'classnames';

import animationGavel from 'static/json/animation/gavel-black.json';
import Button from 'components/ui/shared/button';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import SvgAnimation from 'components/ui/animation/svgAnimation';
import User from 'constants/user';
import { AuctionItemFormat, TopOffer } from 'store/shared/api/graph/interfaces/types';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { formatCurrency } from 'utils/stringUtils';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './ended.scss';

interface Props {
  /** The currency code to format to */
  currencyCode?: string;
  /** The auction item's current format */
  format: AuctionItemFormat;
  /** Whether the item is in the list view */
  isInAuctionItemsList?: boolean;
  /** Whether the item belongs to the logged-in user */
  isMyItem: boolean;
  /** Callback for onClose event */
  onClose: () => void;
  /** The total bids applied to the current unit */
  totalBids: number;
  /** The unique bidders for to the current unit */
  uniqueBidders: string[];
  /** The logged-in user */
  user: User;
  /** The top offer */
  topOffer?: TopOffer;
}

const Ended = ({
  currencyCode,
  format,
  isInAuctionItemsList,
  isMyItem,
  onClose,
  totalBids,
  uniqueBidders,
  user,
  topOffer,
}: Props) => {
  const isWinning = !!topOffer?.company?.id && !!getEnabledCompanyIds(user)?.includes(topOffer?.company?.id);
  const isLosing = !isWinning && uniqueBidders.some((id) => getEnabledCompanyIds(user)?.includes(id));
  const highestBid = topOffer?.amount?.amount ?? 0;
  const highestBidder = topOffer?.company?.name ?? '';

  return (
    <div className={style.ended}>
      <div className={style.dialog} data-testid="ended-dialog">
        <div className={style.body}>
          <div className={style.gavelContainer}>
            <SvgAnimation
              className={classnames(style.gavel)}
              options={{
                loop: false,
                autoplay: true,
                prerender: true,
                animationData: animationGavel,
              }}
            />
          </div>
          <Switch>
            <Case if={!!isInAuctionItemsList && isAuctionStaff(user)}>
              {highestBid && (
                <label className={style.status}>
                  {t('finished_for')}{' '}
                  <span className={classnames(style.highBid, style.isLosing)}>
                    {formatCurrency(highestBid, currencyCode)}
                  </span>
                </label>
              )}
              {!highestBid && <label className={style.status}>{t('finished')}</label>}
              {highestBidder && (
                <p className={style.description}>
                  <InterpolatedTranslation args={[`<em>${highestBidder}</em>`]} namespace="x_is_the_highest_bidder" />
                </p>
              )}
            </Case>
            <Case if={isMyItem}>
              <Case if={totalBids === 0}>
                <label className={style.status}>{t('finished')}</label>
                <p className={style.description}>{t('your_vehicle_received_no_bids')}</p>
              </Case>
              <Case>
                <label className={style.status}>
                  {t('finished_for')}{' '}
                  <span className={classnames(style.highBid, style.isWinning)}>
                    {formatCurrency(highestBid, currencyCode)}
                  </span>
                </label>
                <p className={style.description}>
                  <InterpolatedTranslation args={[`<em>${highestBidder}</em>`]} namespace="x_is_the_highest_bidder" />
                </p>
              </Case>
            </Case>
            <Case>
              <Case if={isAuctionFormat(format)}>
                <Case if={isWinning}>
                  <label className={style.status}>
                    {t('finished_for')}{' '}
                    <span className={classnames(style.highBid, style.isWinning)}>
                      {formatCurrency(highestBid, currencyCode)}
                    </span>
                  </label>
                  <p className={style.description}>{t('you_are_the_high_bidder')}</p>
                </Case>
                <Case if={isLosing}>
                  <label className={style.status}>
                    {t('finished_for')}{' '}
                    <span className={classnames(style.highBid, style.isLosing)}>
                      {formatCurrency(highestBid, currencyCode)}
                    </span>
                  </label>
                  <p className={style.description}>
                    <InterpolatedTranslation args={[`<em>${highestBidder}</em>`]} namespace="x_is_the_highest_bidder" />
                  </p>
                </Case>
                <Case if={totalBids > 0}>
                  <label className={style.status}>
                    {t('finished_for')}{' '}
                    <span className={style.highBid}>{formatCurrency(highestBid, currencyCode)}</span>
                  </label>
                  <p className={style.description}>
                    <InterpolatedTranslation args={[`<em>${highestBidder}</em>`]} namespace="x_is_the_highest_bidder" />
                  </p>
                </Case>
                <Case>
                  <label className={style.status}>{t('finished')}</label>
                </Case>
              </Case>
              <Case>
                <Case if={isWinning}>
                  <label className={style.status}>
                    {t('finished_for')}{' '}
                    <span className={classnames(style.highBid, style.isWinning)}>
                      {formatCurrency(highestBid, currencyCode)}
                    </span>
                  </label>
                  <p className={style.description}>{t('you_are_the_high_bidder')}</p>
                </Case>
                <Case if={isLosing}>
                  <label className={style.status}>
                    {t('finished_for')}{' '}
                    <span className={classnames(style.highBid, style.isLosing)}>
                      {formatCurrency(highestBid, currencyCode)}
                    </span>
                  </label>
                  <p className={style.description}>{t('you_have_been_outbid')}</p>
                </Case>
                <Case>
                  <label className={style.status}>{t('finished')}</label>
                </Case>
              </Case>
            </Case>
          </Switch>
        </div>
        {(isAuctionStaff(user) || isMyItem || isWinning) && (
          <Button className={style.button} onClick={onClose} theme="none">
            {t('view_item')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Ended;
