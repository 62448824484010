import classnames from 'classnames';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

import style from './slider.scss';

interface WrappedSliderProps extends SliderProps {
  /** The className to overwrite default styles */
  className?: string;
  /** Callback function to handle input value change event. */
  onChange?: (value: number) => void;
  /** The theme for the slider. */
  theme?: 'red' | 'orange' | 'green';
  /** The value of the slider. */
  value?: number;
}

const WrappedSlider = ({ theme, className, ...props }: WrappedSliderProps) => (
  <div className={style.container}>
    <Slider className={classnames('customSlider', className, theme)} {...props} />
    <div className={classnames(style.sliderBlockShim, theme && style[theme])} />
  </div>
);

export default WrappedSlider;
