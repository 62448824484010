import { rangeCA } from 'components/ui/shared/scores/scoreRanges';
import { ScoreRangeValueProps, useScoreRangeValue } from 'components/ui/shared/scores/useScoreRangeValue';

import styleVariables from 'styles/variables.scss';

const ScoreText = ({ range = rangeCA, score }: ScoreRangeValueProps) => {
  const scoreRangeValue = useScoreRangeValue({ range, score });

  return (
    <span style={{ color: scoreRangeValue?.rangeItem?.color || styleVariables?.colorText }}>
      {score ? `${score}/${scoreRangeValue?.maxScore}` : '-'}
    </span>
  );
};

export default ScoreText;
