import classnames from 'classnames';

import style from './hr.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
}

const HR = ({ className }: Props) => <hr className={classnames(style.hr, className)} />;

export default HR;
