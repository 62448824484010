import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';

import ClockGlyph from 'glyphs/clock.svg';

import Countdown from 'components/ui/countdowns/countdown';
import Sprite from 'components/ui/shared/sprite';
import TimerBar from 'components/ui/progressBars/timerBar';
import {
  ExtendedIfBidStatus,
  OfferStatus,
} from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerStatusLabel/offerStatusLabel';
import { IfBidStatus } from 'store/shared/api/graph/interfaces/types';
import { TimerFormats } from 'constants/enums/dateAndTime';
import { t } from 'utils/intlUtils';

import style from './offerExpiryTimer.scss';

interface Props {
  /** Current timed offer status */
  offerStatus: OfferStatus;
  /** The end time of the timer bar and countdown. */
  timeEnd: Moment;
}

const OfferExpiryTimer = ({ offerStatus = IfBidStatus.ACTIVE, timeEnd }: Props) => {
  const timeStart = timeEnd ? timeEnd.clone().add(-1, 'minute') : null;
  const [isTimeExpired, setTimeExpired] = useState<boolean>(timeEnd.isBefore(moment(Date.now())));
  const timeExpiresInLabel =
    offerStatus === ExtendedIfBidStatus.COUNTERED ? t('counter_offer_expires_in') : t('offer_expires_in');

  /**
   * Updates timer label when timer ends.
   */
  const onTimerEnd = useCallback(() => {
    setTimeExpired(true);
  }, []);

  return (
    <div className={style.offerExpiryTimer} data-testid="offer-expiry-timer">
      <div className={style.timerBarContainer}>
        {!!timeStart && !!timeEnd && (
          <TimerBar className={style.timerBar} end={timeEnd} onEnd={onTimerEnd} start={timeStart} />
        )}
      </div>
      <div className={style.timerText}>
        <Sprite className={style.clockGlyph} glyph={ClockGlyph} />
        <div>
          <div className={style.label}>{isTimeExpired ? t('times_up') : timeExpiresInLabel}</div>
          {!isTimeExpired && <Countdown end={timeEnd} timeFormat={TimerFormats} />}
        </div>
      </div>
    </div>
  );
};

export default OfferExpiryTimer;
