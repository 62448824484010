import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';

import Range from 'forms/shared/range';
import { RangeFacetGroupConfigItem } from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getFilteredUrlParams, getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { haveSpecificParamsChange } from 'utils/listUtils';
import { usePrevious } from 'hooks/usePrevious';

import style from './rangeFacetGroup.scss';

export interface RangeFacetGroupProp {
  /** A function to render the label of the range component. */
  valueLabel: (value: number[]) => string;

  /** The upper and lower limits of the range component. */
  values: number[];

  /** The name of the facet group. */
  name: string;
}

interface Props extends RouterProps {
  /** The configuration information associated to the component. */
  configItem: RangeFacetGroupConfigItem;
  /** The range facet group. */
  facetGroup: RangeFacetGroupProp;
  /** The label of the component. */
  label: string | undefined;
  /** The selected facetGroup lower bound (<nameGTE>) value. */
  selectedLowerBound: number | undefined;
  /** The selected facetGroup upper bound (<nameLTE>) value. */
  selectedUpperBound: number | undefined;
}

const RangeFacetGroup = ({
  configItem,
  facetGroup,
  label,
  router,
  location,
  selectedLowerBound,
  selectedUpperBound,
}: Props) => {
  /** Gets the value of the param from the URL. */
  const getUrlParam = useCallback((paramName: string) => {
    const params = getUrlParams();
    return parseFloat(params[paramName]);
  }, []);

  const lowerBoundName = `${facetGroup.name}GTE`;
  const upperBoundName = `${facetGroup.name}LTE`;
  const lowerDefaultValue = facetGroup.values[0];
  const upperDefaultValue = facetGroup.values[1];

  const prevLocation = usePrevious(location);
  const prevSelectedLowerBound = usePrevious(selectedLowerBound);
  const prevSelectedUpperBound = usePrevious(selectedUpperBound);

  const [lowerBound, setLowerBound] = useState<number>(
    () => selectedLowerBound || getUrlParam(lowerBoundName) || lowerDefaultValue
  );
  const [upperBound, setUpperBound] = useState<number>(
    () => selectedUpperBound || getUrlParam(upperBoundName) || upperDefaultValue
  );

  /** Update range bounds on when url changes. */
  useEffect(() => {
    if (prevLocation && haveSpecificParamsChange(location, prevLocation, [lowerBoundName, upperBoundName])) {
      setLowerBound(getUrlParam(lowerBoundName) || lowerDefaultValue);
      setUpperBound(getUrlParam(upperBoundName) || upperDefaultValue);
    }
  }, [prevLocation, location, getUrlParam, lowerBoundName, lowerDefaultValue, upperBoundName, upperDefaultValue]);

  /** Update range bounds if facet group selected values have changed */
  useEffect(() => {
    if (!isEqual(prevSelectedLowerBound, selectedLowerBound) || !isEqual(prevSelectedUpperBound, selectedUpperBound)) {
      setLowerBound(selectedLowerBound || getUrlParam(lowerBoundName) || lowerDefaultValue);
      setUpperBound(selectedUpperBound || getUrlParam(upperBoundName) || upperDefaultValue);
    }
  }, [
    getUrlParam,
    lowerBoundName,
    lowerDefaultValue,
    upperBoundName,
    upperDefaultValue,
    selectedLowerBound,
    selectedUpperBound,
    prevSelectedLowerBound,
    prevSelectedUpperBound,
  ]);

  /** Updates the URL with selected range limits. */
  const onChangeComplete = useCallback(
    ([lowerBoundValue, upperBoundValue]): void => {
      const { push } = router;
      const { canSave = true } = configItem;

      const params = getFilteredUrlParams(['after', 'before']);

      params[lowerBoundName] = lowerBoundValue;
      params[upperBoundName] = upperBoundValue;

      // Delete url param if min range.
      if (lowerBoundValue === lowerDefaultValue) {
        delete params[lowerBoundName];
      }

      // Delete url param if max range.
      if (upperBoundValue === upperDefaultValue) {
        delete params[upperBoundName];
      }

      if (canSave) {
        delete params.savedSearchId;
      }

      push(`${location.pathname}?${paramsToQueryString(params)}`);
    },
    [router, configItem, location.pathname, lowerBoundName, lowerDefaultValue, upperBoundName, upperDefaultValue]
  );

  return (
    !!facetGroup && (
      <div className={style.rangeFacetGroup} data-testid={`${facetGroup.name}-range-facet-group`}>
        <div className={style.heading}>
          {label || facetGroup.name}
          <span className={style.valueLabel}>{facetGroup.valueLabel([lowerBound, upperBound])}</span>
        </div>
        <Range
          key="range-slider"
          allowCross={false}
          disabled={configItem?.disabled}
          max={upperDefaultValue}
          min={lowerDefaultValue}
          onChange={(val) => {
            setLowerBound(val[0]);
            setUpperBound(val[1]);
          }}
          onChangeComplete={onChangeComplete}
          step={configItem?.step}
          tipFormatter={null}
          value={[lowerBound, upperBound]}
        />
      </div>
    )
  );
};

export default withRouter(RangeFacetGroup);
