import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import CheckoutFormContainer from 'containers/checkout/checkoutFormContainer';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { t } from 'utils/intlUtils';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

interface State {
  /** Dialog open state. */
  isDialogOpen: boolean;
}

class Checkout extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isDialogOpen: false };
  }

  render() {
    const { auctionItem } = this.props;
    const { isDialogOpen } = this.state;

    return (
      <>
        <OperationButton
          dataTestId="checkout-button"
          glyph={submitGlyph}
          onClick={() => this.setState({ isDialogOpen: true })}
        >
          {t('checkout')}
        </OperationButton>
        <CheckoutFormContainer
          auctionItem={auctionItem}
          isOpen={isDialogOpen}
          isSingleForm
          onClose={() => this.setState({ isDialogOpen: false })}
        />
      </>
    );
  }
}

export default Checkout;
