import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LoginMFAScenario1 from 'components/sections/auth/loginMFA/loginMFAScenario1';
import LoginMFAScenario2 from 'components/sections/auth/loginMFA/loginMFAScenario2';
import LoginMFAScenario3 from 'components/sections/auth/loginMFA/loginMFAScenario3';
import { AppState } from 'store/configureStore';

export const ONE_TIME_CODE_LENGTH = 6;

const LoginMFA = () => {
  const user = useSelector((state: AppState) => state.app.user);

  // Determines which mfa scenario should be rendered based on the user's verification state
  const loginMFAScenario = useMemo(() => {
    const { emailVerified, phoneVerified } = user;

    // Scenario 1: Email AND phone are verified
    if (emailVerified && phoneVerified) {
      return <LoginMFAScenario1 user={user} />;
    }

    // Scenario 2: Email OR phone are verified
    if (emailVerified || phoneVerified) {
      return <LoginMFAScenario2 user={user} />;
    }

    // Scenario 3: Neither email OR phone are verified
    return <LoginMFAScenario3 user={user} />;
  }, [user]);

  return loginMFAScenario;
};

export default LoginMFA;
