import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import {
  carriersListLoaded,
  carriersListError,
  facetGroupsLoaded,
  pageInfoLoaded,
  carriersListIsLoading,
  carriersListCleared,
  carriersListUpdateItem,
} from './carriersActions';
import { InitialState, Carrier, FacetGroup, PageInfo } from './carriersModels';

export const carriersReducer = handleActions(
  {
    [carriersListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [carriersListLoaded().type]: (state, action) => {
      const {
        carrierConnection: { edges, pageInfo, facetGroups },
      } = action.payload;
      return state.setLoaded().merge({
        resultList: List(edges.map((carrierEdge) => new Carrier(carrierEdge.node))),
        facetGroups: List(facetGroups.map((facetGroupData) => new FacetGroup(facetGroupData))),
        pageInfo: new PageInfo(pageInfo),
      });
    },

    [carriersListCleared().type]: () => new InitialState(),

    [pageInfoLoaded().type]: (state, action) => state.set('pageInfo', action.payload),

    [facetGroupsLoaded().type]: (state, action) =>
      state.set('facetGroups', List(action.payload.map((facetGroupData) => new FacetGroup(facetGroupData)))),

    [carriersListError().type]: (state, action) => state.setError(action.payload),

    [carriersListUpdateItem().type]: (state, action) => {
      const carrierNext = action.payload;
      const carrierIndex = state.resultList.findIndex((item) => item?.id === carrierNext.id);

      if (carrierIndex === -1) {
        return state.merge({ resultList: state.resultList.insert(0, new Carrier(carrierNext)) });
      }

      const existingItem = state.resultList.get(carrierIndex)!.toJS();
      const updatedItem = new Carrier(merge({}, existingItem, carrierNext));
      return state.merge({ resultList: state.resultList.set(carrierIndex, updatedItem) });
    },
  },
  new InitialState()
);
