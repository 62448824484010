import { useCallback } from 'react';

import SimulcastChatManager from 'io/twilio/simulcastChatManager';
import User from 'constants/user';
import {
  Conversation,
  ConversationMessage,
  ConversationMessageType,
  ConversationType,
} from 'store/shared/api/graph/interfaces/types';

interface Props {
  /** Auction Item Id that is under auction currently */
  auctionItemId: string;
  /** Chat manager broker for twillio events */
  chatManager: SimulcastChatManager;
  /** Conversation Id that the message belongs to */
  conversationId: string;
  /** The numeric value of the current bid amount from the live item */
  currentBidAmount?: number;
  /** Set error state */
  setError: (err: Error) => void;
  /** The logged-in user */
  user: User;
}

/**
 * This callback is called when the logged-in user sends a chat message from the chat component.
 * It will create a message object and send it to the Redux dispatcher which will tell the backend
 * that the message has been created.  The redux state is updated and message displayed when the
 * API response is received.
 */
export const useSendMessage = ({
  auctionItemId,
  chatManager,
  conversationId,
  currentBidAmount,
  setError,
  user,
}: Props) => {
  return useCallback(
    async (conversation: Conversation, message: string) => {
      const isBroadCastMessage: boolean = conversation?.type === ConversationType.LIVE_LANE_BROADCAST;

      const formattedMessage: ConversationMessage = {
        message,
        created: new Date().toISOString(),
        createdById: user.id,
        id: '', // Placeholder until message is created on server
        type: isBroadCastMessage ? ConversationMessageType.LIVE_LANE_BROADCAST : ConversationMessageType.LIVE_LANE,
      };

      // Send message to Twillio
      try {
        await chatManager?.sendMessage(
          conversation?.id,
          {
            auctionItemId,
            conversationId,
            currentBidAmount: currentBidAmount ?? undefined,
            sessionId: user?.sessionId || undefined,
          },
          formattedMessage
        );
      } catch (err) {
        setError(err);
      }
    },
    [auctionItemId, chatManager, conversationId, currentBidAmount, setError, user?.id, user?.sessionId]
  );
};
