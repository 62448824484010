import classnames from 'classnames';
import { MouseEventHandler } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './conditionReport.scss';

interface Props {
  /** Inventory item details */
  inventoryItem: InventoryItem;
  /** True when editable */
  isUpdatable?: boolean;
  /** Function invoked on button click */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const ScoreButton = ({ inventoryItem, isUpdatable, onClick }: Props) => {
  const conditionReport = inventoryItem?.conditionReport;
  const score = conditionReport?.overallConditionRating || 0;

  return (
    <Button
      className={classnames(
        style.crButton,
        isUpdatable && style.crButtonUpdatable,
        inventoryItem?.incompleteFieldMessages?.find((ifm) => ifm?.field === 'conditionReport') && style.isRequired,
        score <= 40 && style.crButtonRed
      )}
      dataTestId="score-button"
      onClick={onClick}
      theme="none"
    >
      <div>
        <span
          className={classnames(
            style.crButtonText,
            score <= 40 && style.red,
            score >= 71 && style.green,
            score >= 41 && score < 71 && style.yellow
          )}
        >
          {score}/100
        </span>
        <span className={style.crButtonSubText}>{t('vehicle_score')}</span>
        {isUpdatable && <Sprite className={style.crEditGlyph} glyph={editGlyph} />}
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default ScoreButton;
