import { useCallback, useEffect, useMemo, useState } from 'react';

import ImagesGlyph from 'glyphs/images.svg';
import LiveLanesGlyph from 'glyphs/live-lanes.svg';
import VehicleGlyph from 'glyphs/default-vehicle.svg';

import AuctionBuyerSellerChat from 'components/sections/auctionChat/auctionBuyerSellerChat';
import AuctionItem from 'constants/auctionItem';
import AuctionItemBadges from 'components/sections/auctionItem/details/auctionItemBadges';
import AuctionVideoStream from 'components/sections/liveLanes/auctionVideoStream/auctionVideoStream';
import Button from 'components/ui/shared/button';
import Events from 'components/sections/auctionItem/details/events/events';
import EventsStatsHeader from 'components/sections/auctionItem/details/events/eventsStatsHeader';
import HeaderActionButtons from 'components/sections/inventoryItem/details/header/headerActionButtons';
import InventoryItemNotes from 'components/sections/inventoryItem/details/inventoryItemNotes';
import SaleLightsButton from 'components/ui/shared/saleLights/saleLightsButton';
import SaleLightsSlideOut from 'components/ui/shared/saleLights/saleLightsSlideOut';
import Sprite from 'components/ui/shared/sprite';
import ToggleVerticalPanelSize from 'components/ui/panels/toggleVerticalPanelSize';
import User from 'constants/user';
import { AuctionItemFormat, LiveAuctionItem } from 'store/shared/api/graph/interfaces/types';
import { LooseObject } from 'constants/objects';
import { PrimaryTitle } from 'layouts/list/listItemLayout';
import { RunNumberBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { UserAction } from 'logging/analytics/events/userActions';
import { VideoControlPosition } from 'components/ui/media/video/videoStream';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { useLiveLanesVideoStream } from 'contexts/liveLanesVideoStreamContext';

import style from './physicalDetailsHeader.scss';

interface Props {
  /** The current auction item displayed. */
  auctionItem: AuctionItem;
  /** Callback function to handle feature clicked event. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
  /** The live auction item that is updated from events that is currently under auction in the lane. */
  liveItem?: LiveAuctionItem;
  /** An url of the vehicle image. */
  photoUrl?: string;
  /** The subtitle to be displayed. */
  subtitle: string;
  /** The title to be displayed. */
  title: string;
  /** The title prefix to be displayed. */
  titlePrefix?: string;
  /** The current user. */
  user: User;
}

const PhysicalDetailsHeader = ({
  auctionItem,
  handleFeatureClicked,
  liveItem,
  photoUrl,
  subtitle,
  title,
  titlePrefix,
  user,
}: Props) => {
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isNotesVisible, setNotesVisible] = useState<boolean>(false);
  const [isSaleLightSlideOutOpen, setSaleLightSlideOutOpen] = useState<boolean>(false);
  const isSeller = getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company?.id);
  const isStaffUser = isAuctionStaff(user, auctionItem?.auction?.id);
  const videoStreamState = useLiveLanesVideoStream();

  /**
   * When image onLoad sets image loaded property.
   */
  useEffect(() => {
    setImageLoaded(false);
    if (photoUrl) {
      const img = new Image();
      img.src = photoUrl;
      img.onload = () => setImageLoaded(true);
    }
  }, [photoUrl]);

  /**
   * Memoized photo collection for photo slide out
   */
  const photoCollection = useMemo(() => {
    if (auctionItem?.inventoryItem) {
      const {
        damagePhotos = [],
        interiorPhotos = [],
        photos: exteriorPhotos = [],
        undercarriagePhotos = [],
      } = auctionItem.inventoryItem;
      const formattedDamagePhotos = damagePhotos.map((photo) => ({ ...photo, isDamagePhoto: true }));
      return [...formattedDamagePhotos, ...exteriorPhotos, ...undercarriagePhotos, ...interiorPhotos];
    }
    return [];
  }, [auctionItem?.inventoryItem]);

  /**
   * Open the photo slide out
   */
  const openPhotoSlideOut = useCallback(() => {
    const activePhotoIndex = photoCollection.findIndex(
      (photo) => photoUrl === photo.main || photoUrl === photo.expanded
    );

    handleFeatureClicked('PHOTOS', {
      photos: photoCollection,
      scrollIndex: activePhotoIndex,
    });
  }, [handleFeatureClicked, photoCollection, photoUrl]);

  /**
   * Handles the sale lights edit click event.
   */
  const onEditSaleLightsClick = useCallback(() => {
    setSaleLightSlideOutOpen(true);
  }, []);

  /**
   * Handles the sale lights slide close event.
   */
  const onCloseSaleLightSlideOut = useCallback(() => {
    setSaleLightSlideOutOpen(false);
  }, []);

  /**
   * Toggles the notes visibility.
   */
  const toggleNotesVisibility = useCallback(() => {
    setNotesVisible(!isNotesVisible);
    if (!isNotesVisible) {
      trackUserActionWithAuctionItemAttributes(UserAction.INTERNAL_NOTES_CLICK, auctionItem);
    }
  }, [isNotesVisible, auctionItem]);

  const renderLeftPanel = () => {
    return (
      <div className={style.vehicleDetails}>
        <div className={style.details}>
          <div className={style.actions}>
            {!!auctionItem?.displayRunNumber && (
              <RunNumberBadge className={style.runNumberBadge} text={auctionItem.displayRunNumber} />
            )}
            <SaleLightsButton
              activeSaleLights={auctionItem?.saleLights || []}
              className={style.saleLightsButton}
              onClick={
                isStaffUser && auctionItem.format === AuctionItemFormat.AUCTION_PHYSICAL
                  ? onEditSaleLightsClick
                  : undefined
              }
            />
            <HeaderActionButtons
              auctionItem={auctionItem}
              isStaffUser={isStaffUser}
              toggleNotes={toggleNotesVisibility}
            />
          </div>
          <div className={style.info}>
            <PrimaryTitle className={style.title} title={[titlePrefix, title].join('')}>
              {titlePrefix}
              {title}
            </PrimaryTitle>
            <h2 title={subtitle}>{subtitle}</h2>
            <div className={style.mileage}>{auctionItem?.inventoryItem?.mileage?.formattedAmount}</div>
            <div className={style.badgesContainer}>
              <AuctionItemBadges auctionItem={auctionItem} user={user} />
            </div>
          </div>
        </div>
        <div className={style.photoContainer}>
          <Sprite className={style.defaultVehicleIcon} glyph={VehicleGlyph} />
          {!!isImageLoaded && <div className={style.photo} style={{ backgroundImage: `url(${photoUrl})` }} />}
          <AuctionVideoStream
            auctionTimeSlotId={auctionItem?.auctionTimeSlot?.id ?? ''}
            auctionTimeSlotLaneId={auctionItem?.auctionTimeSlotLane?.id ?? ''}
            className={style.streamVideo}
            controlPosition={VideoControlPosition.BOTTOM}
            onToggleFullScreenOverride={videoStreamState?.toggleFullScreenOverride}
            shouldUpdateFocusedLane
            videoStreamConfig={auctionItem?.auction?.videoStreamConfig}
          />
          {photoCollection.length && (
            <Button className={style.photosButton} dataTestId="photos-button" onClick={openPhotoSlideOut} theme="none">
              <Sprite className={style.sprite} glyph={ImagesGlyph} />+{photoCollection.length}
            </Button>
          )}
          {auctionItem?.inventoryItem?.notes && isNotesVisible && (
            <InventoryItemNotes
              inventoryItemId={auctionItem.inventoryItem.id}
              isAuctionItem
              notes={auctionItem.inventoryItem.notes}
              onClose={() => setNotesVisible(false)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <ToggleVerticalPanelSize>
        <div className={style.events}>
          <EventsStatsHeader
            isSeller={isAuctionStaff(user) || !!isSeller}
            reserve={auctionItem?.reserve || undefined}
            reserveMet={auctionItem?.reserveMet}
            topOffer={auctionItem?.topOffer || undefined}
          />
          <Events
            auctionItemId={auctionItem.id}
            auctionTimeSlotLaneId={auctionItem.auctionTimeSlotLane?.id}
            shouldDisplayAllEvents
            user={user}
          />
        </div>
        <AuctionBuyerSellerChat
          auctionItem={auctionItem}
          auctionTimeSlotLaneId={auctionItem?.auctionTimeSlotLane?.id || undefined}
          currentBidAmount={liveItem?.atAmount || undefined}
        />
      </ToggleVerticalPanelSize>
    );
  };

  return (
    <>
      <div className={style.headerBar}>
        <Sprite className={style.liveLanesLogo} glyph={LiveLanesGlyph} />
      </div>
      <div className={style.headerContainer} data-testid="physical-header">
        {renderLeftPanel()}
        {renderRightPanel()}
      </div>

      <SaleLightsSlideOut
        activeSaleLights={auctionItem?.saleLights?.filter(Boolean) || []}
        asIs={!!auctionItem?.asIs}
        auctionItemId={auctionItem?.id}
        inventoryItemId={auctionItem?.inventoryItem?.id}
        isOpen={isSaleLightSlideOutOpen}
        onClose={onCloseSaleLightSlideOut}
      />
    </>
  );
};

export default PhysicalDetailsHeader;
