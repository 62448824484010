import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemRequestArbitrationArgs } from 'store/shared/api/graph/interfaces/types';
import { isAuctionStaff } from 'utils/userUtils';
import { processRequestArbitration } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

const stateConnect = (state: AppState) => ({
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
  /** Current logged in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to request arbitration. */
  requestArbitration: (options: MutationauctionItemRequestArbitrationArgs) =>
    processRequestArbitration({ ...options, shouldReviewsLoad: true }, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item. */
  auctionItem: AuctionItem;
  /** Callback function to trigger on done. */
  onDone?: (options: boolean) => void;
}

interface State {
  /** Error messages. */
  errorMessages?: ErrorMessages;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Whether is progressing or not. */
  progress: boolean;
}

class Arbitrate extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, progress: false };
  }

  onSubmit = (shouldSubmit: boolean = false, options: Partial<MutationauctionItemRequestArbitrationArgs> = {}) => {
    const { auctionItem, onDone, requestArbitration, user } = this.props;

    if (shouldSubmit) {
      this.setState({ progress: true });
      requestArbitration?.({ auctionItemId: auctionItem.id, userId: user?.id, ...options })
        ?.then(() => {
          onDone?.(true);
        })
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ progress: false }));
    } else {
      this.setState({ errorMessages: undefined, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    const { auctionItem, isStaffUser } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <OperationButton dataTestId="arbitrate-button" onClick={() => this.setState({ isOpen: true })} theme="red">
          {t('arbitrate')}
        </OperationButton>
        {isOpen && (
          <Dialog auctionItem={auctionItem} isStaffUser={isStaffUser} onSubmit={this.onSubmit} {...this.state} />
        )}
      </>
    );
  }
}

export default connector(Arbitrate);
