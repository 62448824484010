import style from './placeholder.scss';

interface Props {
  /** The width of the placeholder. */
  width?: string;
}

const Placeholder = ({ width = '50%' }: Props) => (
  <div className={style.container}>
    <div className={style.placeholder} style={{ width }} />
  </div>
);

export default Placeholder;
