import { LayerInfo } from '@millicast/sdk';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import MillicastViewerManager, { ActiveTrackInfo } from 'io/millicast/millicastViewerManager';
import VideoStream, { VideoStreamRef } from 'components/ui/media/video/videoStream';
import { VideoStreamConfig } from 'store/shared/api/graph/interfaces/types';

export interface StreamState extends ActiveTrackInfo {
  /** Active qualities. */
  activeQualities: LayerInfo[];
  /** Whether the stream is live or not. */
  isLive: boolean;
  /** Media stream. */
  stream: MediaStream | undefined;
}

export interface LiveLanesVideoStreamContextState {
  /** Callback function to clean up video stream state. */
  cleanupVideoStreamState: () => void;
  /** Callback function to connect to the stream by auction time slot lane id. */
  connectToStream: (auctionTimeSlotLaneId: string) => void;
  /** Currently focused auction time slot id. */
  focusedAuctionTimeSlotId?: string;
  /** Currently focused auction lane id. */
  focusedAuctionTimeSlotLaneId?: string;
  /** Callback function to get stream state by auction lane id. */
  getStreamState: (auctionTimeSlotLaneId: string) => StreamState;
  /** Callback function to get viewer manager by auction lane id. */
  getViewerManager: (
    auctionTimeSlotLaneId: string,
    videoStreamConfig: VideoStreamConfig | undefined
  ) => MillicastViewerManager | undefined;
  /** Whether audio is muted or not. */
  isMuted: boolean;
  /** Callback function to remove a viewer manager. */
  removeViewerManager: (auctionTimeSlotLaneId: string) => void;
  /** Callback function to set is muted or not. */
  setIsMuted: (isMuted: boolean) => void;
  /** Callback function to toggle fullscreen override. */
  toggleFullScreenOverride: () => void;
  /** Callback function to update currently focused lane. */
  updateFocusedLane: (auctionTimeSlotId: string, auctionTimeSlotLaneId: string, stream?: MediaStream) => void;
}

type ViewerManagerMap = Record<string, MillicastViewerManager>;
type ViewerStreamStateMap = Record<string, StreamState>;

/**
 * The live lanes video streaming context.
 * Provides the `LiveLanesVideoStreamContextState` to all consumers of `useLiveLanesVideoStream()`.
 */
export const LiveLanesVideoStreamContext = createContext<LiveLanesVideoStreamContextState | undefined>(undefined);

export const LiveLanesVideoStreamProvider = ({ children }: PropsWithChildren) => {
  const [focusedAuctionTimeSlotId, setFocusedAuctionTimeSlotId] = useState<string>();
  const [focusedAuctionTimeSlotLaneId, setFocusedAuctionTimeSlotLaneId] = useState<string>();
  const [focusedStream, setFocusedStream] = useState<MediaStream>();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [viewerManagerMap, setViewerManagerMap] = useState<ViewerManagerMap>({});
  const [viewerStreamStateMap, setViewerStreamStateMap] = useState<ViewerStreamStateMap>({});
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<VideoStreamRef>(null);

  /**
   * Play the audio
   */
  const playAudio = useCallback(() => {
    const audio = audioRef?.current;
    if (audio?.paused) {
      audio.play()?.catch(() => {
        if (audio) {
          // Retry
          audio.play()?.catch(() => {
            // Mute the audio since user has not interacted with the page yet
            setIsMuted(true);
          });
        } else {
          setIsMuted(true);
        }
      });
    }
  }, []);

  /**
   * On mute change, and audio is paused, play the audio
   */
  useEffect(() => {
    if (!isMuted && audioRef?.current?.paused) {
      playAudio();
    }
  }, [isMuted, playAudio]);

  /**
   * Set media stream for video on change
   */
  useEffect(() => {
    const audio = audioRef?.current;
    if (audio && focusedStream) {
      audio.id = focusedStream?.id;
      audio.srcObject = focusedStream || null;
      playAudio();
    }
  }, [focusedStream, playAudio]);

  /**
   * Add viewer manager to map
   */
  const addViewerManager = useCallback((auctionTimeSlotLaneId: string, viewerManager: MillicastViewerManager) => {
    setViewerManagerMap((prev) => {
      if (prev[auctionTimeSlotLaneId]) {
        // Clean up the old one first
        prev[auctionTimeSlotLaneId].stopStream();
      }
      return { ...prev, [auctionTimeSlotLaneId]: viewerManager };
    });
  }, []);

  /**
   * Clean up video stream state
   */
  const cleanupVideoStreamState = useCallback(() => {
    setFocusedAuctionTimeSlotId(undefined);
    setFocusedAuctionTimeSlotLaneId(undefined);
    setFocusedStream(undefined);
    setIsMuted(true);
    Object.values(viewerManagerMap).forEach((viewerManager) => {
      viewerManager.stopStream();
    });
    setViewerManagerMap({});
    setViewerStreamStateMap({});
  }, [viewerManagerMap]);

  /**
   * Trigger connect to stream by auction time slot lane id.
   */
  const connectToStream: LiveLanesVideoStreamContextState['connectToStream'] = useCallback(
    (auctionTimeSlotLaneId: string) => {
      const viewerManager = viewerManagerMap[auctionTimeSlotLaneId];

      viewerManager?.connectStream();
    },
    [viewerManagerMap]
  );

  /**
   * Set focused stream on stream change
   */
  const onActiveQualitiesChange = useCallback((auctionTimeSlotLaneId: string, activeQualities: LayerInfo[] = []) => {
    setViewerStreamStateMap((prev) => {
      return { ...prev, [auctionTimeSlotLaneId]: { ...prev[auctionTimeSlotLaneId], activeQualities } };
    });
  }, []);

  /**
   * Set focused stream on stream change
   */
  const onActiveTracksChange = useCallback((auctionTimeSlotLaneId: string, activeTrackInfo: ActiveTrackInfo) => {
    setViewerStreamStateMap((prev) => {
      return {
        ...prev,
        [auctionTimeSlotLaneId]: {
          ...prev[auctionTimeSlotLaneId],
          ...activeTrackInfo,
        },
      };
    });
  }, []);

  /**
   * Set focused stream on stream change
   */
  const onLiveStateChange = useCallback((auctionTimeSlotLaneId: string, isLive: boolean) => {
    setViewerStreamStateMap((prev) => {
      return { ...prev, [auctionTimeSlotLaneId]: { ...prev[auctionTimeSlotLaneId], isLive } };
    });
  }, []);

  /**
   * Set focused stream on stream change
   */
  const onStreamChange = useCallback(
    (auctionTimeSlotLaneId: string, stream: MediaStream | undefined) => {
      setViewerStreamStateMap((prev) => {
        return { ...prev, [auctionTimeSlotLaneId]: { ...prev[auctionTimeSlotLaneId], stream } };
      });
      if (focusedAuctionTimeSlotLaneId === auctionTimeSlotLaneId) {
        setFocusedStream(stream);
      }
    },
    [focusedAuctionTimeSlotLaneId]
  );

  /**
   * Get stream state by lane id
   */
  const getStreamState = useCallback(
    (auctionTimeSlotLaneId: string) => {
      return viewerStreamStateMap[auctionTimeSlotLaneId];
    },
    [viewerStreamStateMap]
  );

  /**
   * Get viewer manager by lane id
   * If not exists before, initiate a new one
   */
  const getViewerManager: LiveLanesVideoStreamContextState['getViewerManager'] = useCallback(
    (auctionTimeSlotLaneId, videoStreamConfig) => {
      if (!auctionTimeSlotLaneId || !videoStreamConfig?.accountId) {
        return undefined;
      }

      const viewerManager = viewerManagerMap[auctionTimeSlotLaneId];
      if (viewerManager) {
        return viewerManager;
      }

      // Init a new viewer manager
      const newViewerManager = new MillicastViewerManager({
        accountId: videoStreamConfig.accountId,
        onActiveQualitiesChange: (activeQualities) => onActiveQualitiesChange(auctionTimeSlotLaneId, activeQualities),
        onActiveTracksChange: (activeTrack) => onActiveTracksChange(auctionTimeSlotLaneId, activeTrack),
        onLiveStateChange: (isLive) => onLiveStateChange(auctionTimeSlotLaneId, isLive),
        onStreamChange: (stream) => onStreamChange(auctionTimeSlotLaneId, stream),
        streamName: auctionTimeSlotLaneId,
        subscriberToken: videoStreamConfig.subscriptionToken || undefined,
      });
      newViewerManager.init();
      newViewerManager.connectStream();
      // Add to map
      addViewerManager(auctionTimeSlotLaneId, newViewerManager);
      return newViewerManager;
    },
    [
      addViewerManager,
      onActiveQualitiesChange,
      onActiveTracksChange,
      onLiveStateChange,
      onStreamChange,
      viewerManagerMap,
    ]
  );

  /**
   * Remove viewer manager by lane id
   */
  const removeViewerManager = useCallback((auctionTimeSlotLaneId: string) => {
    setViewerManagerMap((prev) => {
      const { [auctionTimeSlotLaneId]: removed, ...remainingViewerManagers } = prev;

      if (removed) {
        // Clean up the removed one
        removed.stopStream();
      }
      return remainingViewerManagers;
    });

    // Also remove the stream state
    setViewerStreamStateMap((prev) => {
      const { [auctionTimeSlotLaneId]: removed, ...remainingStreamState } = prev;
      return remainingStreamState;
    });
  }, []);

  /**
   * Update focused lane
   */
  const updateFocusedLane = useCallback(
    (auctionTimeSlotId: string, auctionTimeSlotLaneId: string, stream?: MediaStream) => {
      // Unmute if focused lane id changed or stream started
      if (focusedAuctionTimeSlotLaneId !== auctionTimeSlotLaneId || (!focusedStream?.id && !!stream?.id)) {
        setIsMuted(false);
      }
      setFocusedAuctionTimeSlotId(auctionTimeSlotId);
      setFocusedAuctionTimeSlotLaneId(auctionTimeSlotLaneId);
      setFocusedStream(stream);
    },
    [focusedAuctionTimeSlotLaneId, focusedStream?.id]
  );

  /**
   * Toggles fullscreen on/off
   */
  const toggleFullScreenOverride = useCallback(() => {
    videoRef.current?.toggleFullScreen?.();
  }, []);

  /**
   * Memoize the full context value
   */
  const contextValue: LiveLanesVideoStreamContextState = useMemo(
    () => ({
      addViewerManager,
      cleanupVideoStreamState,
      connectToStream,
      focusedAuctionTimeSlotId,
      focusedAuctionTimeSlotLaneId,
      getStreamState,
      getViewerManager,
      isMuted,
      removeViewerManager,
      setIsMuted,
      toggleFullScreenOverride,
      updateFocusedLane,
    }),
    [
      addViewerManager,
      cleanupVideoStreamState,
      connectToStream,
      focusedAuctionTimeSlotId,
      focusedAuctionTimeSlotLaneId,
      getStreamState,
      getViewerManager,
      isMuted,
      removeViewerManager,
      toggleFullScreenOverride,
      updateFocusedLane,
    ]
  );

  /**
   * On full screen change
   */
  const onFullScreenChange = useCallback(() => {
    setIsFullscreen(!!document.fullscreenElement);
  }, []);

  /**
   * Add event listener for full screen change
   */
  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, [onFullScreenChange]);

  return (
    <LiveLanesVideoStreamContext.Provider value={contextValue}>
      {children}
      <div style={{ height: 0, width: 0, display: isFullscreen ? 'initial' : 'none' }}>
        {/** Video stream that displayed as full screen and override the default full screen */}
        <VideoStream
          ref={videoRef}
          isMuted={isMuted}
          mediaStream={isFullscreen ? focusedStream : undefined} // Only display the focused stream in full screen
          overrideAudio
          setIsMuted={setIsMuted}
          showFullScreenButton
        />
        <audio ref={audioRef} hidden muted={isMuted} style={{ display: 'none' }} />
      </div>
    </LiveLanesVideoStreamContext.Provider>
  );
};

/**
 * A hook for getting a reference to the `LiveLanesVideoStreamContextState` data.
 */
export const useLiveLanesVideoStream = (): LiveLanesVideoStreamContextState | undefined => {
  const context = useContext(LiveLanesVideoStreamContext);
  if (context === undefined) {
    throw new Error('"useLiveLanesVideoStream()" must be used within the "LiveLanesVideoStreamProvider"');
  }
  return context;
};
