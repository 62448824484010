import classnames from 'classnames';
import { MouseEventHandler } from 'react';

import addGlyph from 'glyphs/add.svg';
import removeGlyph from 'glyphs/remove.svg';

import Sprite from 'components/ui/shared/sprite';
import Button from 'components/ui/shared/button';

import style from './addRemoveButton.scss';

interface Props {
  /** Component CSS styling. */
  className?: string;
  /** True when the button performs add functionality. */
  isAdding: boolean;
  /** Function invoked on add. */
  onAdd?: MouseEventHandler<HTMLButtonElement>;
  /** Function invoked on remove. */
  onRemove?: MouseEventHandler<HTMLButtonElement>;
}

const AddRemoveButton = ({ className, isAdding, onAdd, onRemove }: Props) => (
  <Button
    className={classnames(style.addRemoveButton, className)}
    dataTestId="add-remove-button"
    onClick={(event) => {
      if (isAdding) {
        onAdd?.(event);
      } else {
        onRemove?.(event);
      }
    }}
    theme="none"
  >
    <Sprite glyph={isAdding ? addGlyph : removeGlyph} />
  </Button>
);

export default AddRemoveButton;
