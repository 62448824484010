import { useMemo } from 'react';

import GroupedValuesItem from 'components/sections/inventoryItem/details/feesAndValues/groupedValuesItem';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { getMMRValues, mmrHeaderTranslationMap } from 'utils/formatting/mmrFormatUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** The mmr values of the inventory item. */
  values: InventoryItemValue[] | undefined;
}

const MMRValuesTooltipText = () => (
  <>
    <strong>{t('adjusted_mmr')}</strong>
    <br />
    {t('adjusted_mmr_info')}
  </>
);

const MMRValuesItem = ({ values }: Props) => {
  /** Memoized formatted and sorted mmr values. */
  const formattedValues = useMemo(() => {
    const mmrValues = getMMRValues(values) || [];
    const formatted = mmrValues.map((value) => ({
      // If there are multiple mmr values, use the notes as the label, otherwise default to translated type id
      label: (mmrValues.length > 1 && value.notes) || t(mmrHeaderTranslationMap[value.type.id]),
      value: value.value.formattedAmount,
    }));

    // If there are multiple mmr values, add a label for the adjusted mmr
    if (mmrValues.length > 1) {
      return [{ label: t('adjusted_mmr') }, ...formatted];
    }
    return formatted;
  }, [values]);

  return (
    <GroupedValuesItem
      defaultDisplayCount={3}
      title={t('manheim_market_report')}
      tooltipText={<MMRValuesTooltipText />}
      values={formattedValues}
    />
  );
};

export default MMRValuesItem;
