import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import { Note, NoteEntityType } from 'store/shared/api/graph/interfaces/types';
import { noteEntities } from 'constants/notes';
import { postNote, getNotes } from './notesApi';
import { storageManager } from 'utils/storageUtils';

export const currentNotesCompany = storageManager.createOrFetchStorage('currentNotesCompany');
export const currentNotesCompanies = storageManager.createOrFetchStorage('currentNotesCompanies');

export const notesCleared = createAction('NOTES_CLEARED');
export const notesUpdating = createAction('NOTES_UPDATING');
export const notesLoaded = createAction<{ entityType: NoteEntityType; data?: Note[] | null }>('NOTES_LOADED');
export const notesSetCurrentCompanies = createAction('NOTES_SET_CURRENT_COMPANY');

export const processGetNotes = async (options, dispatch) => {
  const key = noteEntities[options.entityType];

  dispatch(notesUpdating());
  return getNotes(options).then((response) =>
    dispatch(notesLoaded({ ...options, data: response?.data.data?.[key].notes }))
  );
};

export const processSetNotes = async (
  options: { entityType: NoteEntityType; data?: Note[] | null },
  dispatch: AppDispatch
) => {
  dispatch(notesUpdating());
  return dispatch(notesLoaded(options));
};

export const processPostNote = async (options, dispatch) => {
  dispatch(notesUpdating());
  return postNote(options).then(() => {
    if (options?.companies?.length) {
      const companies = options?.companies;

      dispatch(notesSetCurrentCompanies(companies));
      currentNotesCompanies.set(JSON.stringify(companies));
    }

    return processGetNotes(options, dispatch);
  });
};
