import classnames from 'classnames';

import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './labelBadge.scss';

interface Props {
  /** CSS style to override default style. */
  className?: string;

  /** The icon of the badge. */
  glyph?: Glyph;

  /** Label of the badge. */
  label: string;

  /** CSS style to override sprite style. */
  spriteClassName?: string;

  /**
   * @default `'green'`
   * Theme of the label badge.
   */
  theme?: 'blue' | 'green' | 'red' | 'yellow';

  /**
   * Position of the label badge relative to the parent component.
   */
  position?: 'topLeft' | 'topCenter' | 'topRight';
}

const LabelBadge = ({ className, glyph, label, position, spriteClassName, theme = 'green' }: Props) => {
  return (
    <div
      className={classnames(style.badge, className, style[theme], position && style[position], glyph && style.hasGlyph)}
    >
      {glyph && <Sprite className={classnames(style.sprite, spriteClassName)} glyph={glyph} />}
      {label}
    </div>
  );
};

export default LabelBadge;
