import User from 'constants/user';
import { LooseObject } from 'constants/objects';

class GTMLogger {
  /**
   * Set logged-in user for improved context
   *
   * @param user
   */
  static setUser(user: User) {
    // eslint-disable-next-line camelcase
    GTMLogger.trackEvent({ user_id: user?.id });
  }

  /**
   * Clear the active user
   */
  static clearUser() {
    // eslint-disable-next-line camelcase
    GTMLogger.trackEvent({ user_id: null });
  }

  /**
   * Log custom events with Google Tag Manager

   * @example trackEvent({ event: 'BUILDER_OPENED', builderType: 'inventory' });
   * @param {object} options
   */
  static trackEvent(options: LooseObject) {
    (window as any)?.dataLayer?.push(options);
  }

  /**
   * Track a user action
   *
   * @param actionName
   * @param attributes
   */
  static trackUserAction(actionName: string, attributes?: LooseObject) {
    GTMLogger.trackEvent({ event: actionName, ...attributes });
  }
}

export default GTMLogger;
