import classnames from 'classnames';
import { useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import ClearActingUserFormContainer from 'containers/admin/users/forms/clearActingUserFormContainer';
import User from 'constants/user';
import { formatUserName } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './clearActingUserButton.scss';

interface Props {
  /** CSS Styling to overwrite default button style. */
  className?: string;
  /** Whether the button is visible. */
  isVisible: boolean;
  /** The user to impersonate. */
  user: User;
}

const ClearActingUserButton = ({ className, isVisible, user }: Props) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const onCloseForm = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Button
        className={classnames(style.button, className)}
        dataTestId="clear-acting-user-button"
        onClick={() => setIsFormOpen(true)}
        theme="none"
      >
        {t('acting_as_x', [formatUserName(user)])}
      </Button>
      <ClearActingUserFormContainer isOpen={isFormOpen} onClose={onCloseForm} />
    </>
  );
};

export default ClearActingUserButton;
