import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import { MutationauctionItemTransferNoSaleArgs } from 'store/shared/api/graph/interfaces/types';

export const changeInventoryItemConsigner = ({ inventoryItemId = null, consignerId = null }) =>
  ApiRequest<{ inventoryItemTransfer: Pick<InventoryItem, 'id'> }>({
    data: {
      operationName: 'changeInventoryItemConsigner',
      query: `
      mutation($inventoryItemId: String!, $consignerId: String) {
        inventoryItemTransfer(inventoryItemId: $inventoryItemId, consignerId: $consignerId) {
          id
        }
      }
    `,
      variables: {
        inventoryItemId,
        consignerId,
      },
    },
  });

export const changeAuctionItemConsigner = ({
  auctionItemId,
  consignerId = null,
  locationId = null,
  pickupLocationId = null,
}: MutationauctionItemTransferNoSaleArgs) =>
  ApiRequest<{ auctionItemTransferNoSale: Pick<InventoryItem, 'id'> }>({
    data: {
      operationName: 'changeAuctionItemConsigner',
      query: `
      mutation($auctionItemId: String!, $consignerId: String, $locationId: String, $pickupLocationId: String) {
        auctionItemTransferNoSale(auctionItemId: $auctionItemId, consignerId: $consignerId, locationId: $locationId, pickupLocationId: $pickupLocationId) {
          id
        }
      }
    `,
      variables: {
        auctionItemId,
        consignerId,
        locationId,
        pickupLocationId,
      },
    },
  });
