import ServerRecord from 'store/shared/models/serverRecord';
import { Conversation, ConversationMessage } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

export interface ConversationState extends Omit<Conversation, 'staff'> {
  /** Determine if this conversation is considered inactive or dormant (no listener should be added) */
  inactive?: boolean;
  /** Determines if this conversation has a listener to Twilio currently */
  listening?: boolean;
  /** Determines whether the conversation is stale relative to it's stored set of messages */
  messagesLoaded?: boolean;
  /** Staff  */
  staff: Conversation['staff'] | null;
}

/**
 * Extension of ConversationMessage type to allow storage of the auctionItemId which is associated with each message
 */
export interface ConversationMessageState extends Omit<ConversationMessage, 'createdById' | 'message'> {
  /** The auction ID of the item currently under auction for this message */
  auctionItemId?: string;
  /** Created by Id */
  createdById: ConversationMessage['createdById'] | null;
  /** The numeric value of the current bid amount from the live item */
  currentBidAmount?: number;
  /** Conversation message */
  message: ConversationMessage['message'] | null;
}

/**
 * Map of conversations to allow quick look up of a particular conversation based on id
 */
export interface ConversationMap {
  [key: string]: ConversationState;
}

/**
 * Map of usernames retrieved from conversations with user ID as a key.  This is needed for quickly
 * looking up a particular username for a given message that is displayed as an annotation on the message bubble.
 */
export interface UserNamesMap {
  [key: string]: string | undefined;
}

/**
 * Map of whether a user is online or not, keyed by the userid.
 * User must be registered in a conversation and subscribed to user online status events to appear here.
 */
export interface UserOnlineStatusMap {
  [key: string]: boolean;
}

/**
 * Enum describing the current error state of the chat window
 */
export enum ErrorStatus {
  /** Moot state, no error */
  NONE,

  /** Error that can be recoverable on a subsequent action - send message */
  RECOVERABLE_ERROR,

  /** Error that isn't immediately recoverable - either a browser refresh or fundamental change of attributes might recover */
  FATAL_ERROR,
}

/**
 * Interface describing the error state of the chat window.  Whether it's attempting to connect, a non-recoverable error,
 * or an error with message send.
 */
export interface ChatErrorState {
  status: ErrorStatus;
  errors: Error[] | null;
}

export const getDefaultFatalErrorState = (): ChatErrorState => {
  return {
    status: ErrorStatus.FATAL_ERROR,
    errors: [new Error(t('chat_unable_to_connect'))],
  };
};

export const getDefaultRecoverableErrorState = (): ChatErrorState => {
  return {
    status: ErrorStatus.RECOVERABLE_ERROR,
    errors: [new Error(t('chat_functionality_unavailable'))],
  };
};

export const DEFAULT_CONNECTED: ChatErrorState = {
  status: ErrorStatus.NONE,
  errors: null,
};

interface ChatModel {
  /** Array of conversations that is visible */
  conversations: ConversationMap;
  /** Current conversation id */
  currentConversationId: string;
  /** Current conversation messages loaded into the chat window */
  currentConversationMessages: ConversationMessage[];
  /** Errors from either sending a message or starting a conversation */
  errorState: ChatErrorState;
  /** True if stale unread messages */
  unreadMessagesStale: boolean;
  /** Map of usernames that are contributors to the set of conversations */
  userNamesMap: UserNamesMap;
  /** User's online statuses */
  userOnlineStatusMap: UserOnlineStatusMap;
}

// Separate so it can be reused by mock
export const defaultChatState: ChatModel = {
  conversations: {},
  currentConversationId: '',
  currentConversationMessages: [],
  errorState: { status: ErrorStatus.NONE, errors: null },
  unreadMessagesStale: true,
  userNamesMap: {},
  userOnlineStatusMap: {},
};

// Default conversation ID for the virtual auctioneer conversation that is container for all clerk conversations
export const AUCTIONEER_CONVERSATION_ID: string = 'AUCTIONEER_CONVERSATION_ID';

export const InitialState = ServerRecord<ChatModel>(defaultChatState);
