import { SetOptional } from 'type-fest';

import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionItemOrder,
  CheckoutResponse,
  MutationauctionItemCompleteCheckoutArgs,
  MutationauctionItemOrderServiceAddArgs,
  MutationauctionItemOrderServiceRemoveArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionItemOrderFragment } from 'store/shared/api/graph/fragments/auctionItemOrder';

export const auctionItemOrderUpdate = (options) =>
  ApiRequest<{ auctionItemOrderUpdate: AuctionItemOrder }>({
    data: {
      operationName: 'auctionItemOrderUpdate',
      query: `
        mutation ($input: AuctionItemOrderUpdateInput!) {
          auctionItemOrderUpdate(input: $input) {
            ...auctionItemOrder
          }
        }
        ${auctionItemOrderFragment}
      `,
      variables: {
        input: options,
      },
    },
  });

export const auctionItemOrderServiceAdd = ({
  auctionItemOrderId,
  auctionServiceId,
}: SetOptional<MutationauctionItemOrderServiceAddArgs, 'auctionItemOrderId' | 'auctionServiceId'>) =>
  ApiRequest<{ auctionItemOrderServiceAdd: AuctionItemOrder }>({
    data: {
      operationName: 'auctionItemOrderServiceAdd',
      query: `
        mutation ($auctionItemOrderId: ID!, $auctionServiceId: ID!) {
          auctionItemOrderServiceAdd(auctionItemOrderId: $auctionItemOrderId, auctionServiceId: $auctionServiceId) {
            ...auctionItemOrder
          }
        }
        ${auctionItemOrderFragment}
      `,
      variables: {
        auctionItemOrderId,
        auctionServiceId,
      },
    },
  });

export const auctionItemOrderServiceRemove = ({
  auctionItemOrderId,
  auctionServiceId,
}: SetOptional<MutationauctionItemOrderServiceRemoveArgs, 'auctionItemOrderId' | 'auctionServiceId'>) =>
  ApiRequest<{ auctionItemOrderServiceRemove: AuctionItemOrder }>({
    data: {
      operationName: 'auctionItemOrderServiceRemove',
      query: `
        mutation ($auctionItemOrderId: ID!, $auctionServiceId: ID!) {
          auctionItemOrderServiceRemove(auctionItemOrderId: $auctionItemOrderId, auctionServiceId: $auctionServiceId) {
            ...auctionItemOrder
          }
        }
        ${auctionItemOrderFragment}
      `,
      variables: {
        auctionItemOrderId,
        auctionServiceId,
      },
    },
  });

export const auctionItemCompleteCheckout = ({ auctionItemIds }: MutationauctionItemCompleteCheckoutArgs) =>
  ApiRequest<{ auctionItemCompleteCheckout: CheckoutResponse }>({
    data: {
      operationName: 'auctionItemCompleteCheckout',
      query: `
        mutation ($auctionItemIds: [ID]!) {
          auctionItemCompleteCheckout(auctionItemIds: $auctionItemIds) {
            excludedFromCheckout
            expired
            successfullyCheckedOut
          }
        }
      `,
      variables: {
        auctionItemIds,
      },
    },
  });
