import { ComponentType, useState } from 'react';

import SlideOut from 'components/ui/slideOuts/slideOut';
import { LooseObject } from 'constants/objects';

import style from './slideOut.scss';

/** Slide out component properties. */

export interface SlideOutComponentProps {
  /** Inventory details data to be displayed. */
  inventoryDetailsData?: LooseObject;
  /** Callback function to set click outside enabled/disabled. */
  setIsClickOutsideDisabled?: (isEnabled: boolean) => unknown;
}

/** Slide out properties. */

interface Props {
  /** The slide out component. */
  component?: ComponentType<SlideOutComponentProps>;
  /** The data used to render the slide out component. */
  componentData?: SlideOutComponentProps['inventoryDetailsData'];
  /** Callback function triggered when slide out closes. */
  onClose: () => void;
  /** The title of the slide out. */
  title: string | undefined;
}

const InventoryItemDetailsSlideOut = ({ onClose, title, component: Component, componentData }: Props) => {
  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState<boolean>();

  return (
    <SlideOut
      contentInnerClassName={style.content}
      isClickOutsideDisabled={isClickOutsideDisabled}
      isOpen={!!Component}
      onClose={onClose}
      title={title}
    >
      {Component && (
        <Component
          inventoryDetailsData={componentData}
          setIsClickOutsideDisabled={(isEnabled) => setIsClickOutsideDisabled(isEnabled)}
        />
      )}
    </SlideOut>
  );
};

export default InventoryItemDetailsSlideOut;
