import { ReactNode } from 'react';
import classnames from 'classnames';

import infoGlyph from 'glyphs/info.svg';

import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './notificationText.scss';

interface Props {
  /** Elements rendered as the children. */
  children: ReactNode;

  /** CSS styling to overwrite the default style. */
  className?: string;

  /** CSS styling to overwrite the default content style. */
  contentClassName?: string;

  /** The glyph rendered with a Sprite */
  glyph?: Glyph;

  /** CSS styling to overwrite the default glyph style. */
  glyphClassName?: string;
}

const NotificationText = ({ children, className, contentClassName, glyph = infoGlyph, glyphClassName }: Props) => {
  return (
    <div className={classnames(style.notificationText, className)} data-testid="notification-text">
      <Sprite className={classnames(style.glyph, glyphClassName)} glyph={glyph} />
      <p className={classnames(style.content, contentClassName)}>{children}</p>
    </div>
  );
};

export default NotificationText;
