import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { AuctionSubmissionInitialState, QueryAuctionSubmissionList } from './auctionSubmissionModels';
import {
  auctionItemLoaded,
  auctionSubmissionListLoaded,
  auctionTimeSlotsLoaded,
  clearAuctionSubmission,
  isLoading,
} from './auctionSubmissionActions';

export const auctionSubmissionReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [clearAuctionSubmission().type]: (state) => new AuctionSubmissionInitialState(),

    [auctionSubmissionListLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        auctionItem: null,
        list: action?.payload,
      });
    },

    [auctionTimeSlotsLoaded().type]: (state, action) => {
      const auction = action?.payload;
      const prevState = state.toJS();

      const listNext = (prevState?.list?.map((auctionEdge) =>
        // Append upcoming time slots to selected auction
        auctionEdge?.node?.id === auction?.id
          ? { ...auctionEdge, node: { ...auctionEdge.node, ...auction } }
          : auctionEdge
      ) || null) as List<QueryAuctionSubmissionList['edges'][number]> | null;

      return state.setLoaded().set('list', listNext);
    },

    [auctionItemLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        auctionItem: action?.payload,
      });
    },
  },
  new AuctionSubmissionInitialState()
);
