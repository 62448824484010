import { MouseEventHandler, useCallback, useState } from 'react';
import classnames from 'classnames';

import autoCheckImage from 'static/images/autocheck.png';
import AutoCheckExceptionsSlideOut from './autoCheckExceptionsSlideOut';
import Button from 'components/ui/shared/button';
import { AutocheckException } from 'store/shared/api/graph/interfaces/types';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { Chevron } from 'components/ui/shared/chevrons';
import { t, tPlural } from 'utils/intlUtils';

import style from './autoCheckButton.scss';

interface Props {
  /** AutoCheck exceptions to be displayed upon button clicked. */
  autoCheckExceptions?: AutocheckException[];
  /** Whether auto check exceptions can be retrieved or not. */
  autoCheckExceptionsReceived?: boolean;
  /** Function invoked on autocheck button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AutoCheckButton = ({ autoCheckExceptions, autoCheckExceptionsReceived, onClick }: Props) => {
  const [isShowingSlideOut, setIsShowingSlideOut] = useState<boolean>(false);

  const onButtonClick = useCallback(
    (event) => {
      if (autoCheckExceptions?.length) {
        setIsShowingSlideOut(true);
      }
      onClick?.(event);
    },
    [autoCheckExceptions?.length, onClick]
  );

  return (
    <>
      <Button
        className={classnames(
          style.crButton,
          (!autoCheckExceptionsReceived || !autoCheckExceptions?.length) && style.static
        )}
        dataTestId="auto-check-button"
        onClick={onButtonClick}
        theme="none"
      >
        <div>
          <img alt="AutoCheck" className={style.autoCheckImg} src={autoCheckImage} />
          <div className={style.exceptionStatus}>
            <Switch>
              <Case if={!autoCheckExceptionsReceived}>
                <div className={style.notReceived}>{t('unable_to_retrieve_exceptions')}</div>
              </Case>
              <Case if={!!autoCheckExceptions?.length}>
                <div className={style.hasException}>
                  {tPlural('x_exceptions_found', autoCheckExceptions?.length ?? 0, [autoCheckExceptions?.length ?? 0])}
                </div>
              </Case>
              <Case if={!autoCheckExceptions?.length}>
                <div className={style.noException}>{t('no_exception_found')}</div>
              </Case>
            </Switch>
          </div>
        </div>
        <Chevron className={style.chevron} />
      </Button>
      <AutoCheckExceptionsSlideOut
        autoCheckExceptions={autoCheckExceptions ?? []}
        isOpen={isShowingSlideOut}
        onClose={() => setIsShowingSlideOut(false)}
      />
    </>
  );
};

export default AutoCheckButton;
