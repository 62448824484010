import classnames from 'classnames';
import { PureComponent, ReactNode } from 'react';

import Dialog from 'components/ui/shared/dialogs/dialog';
import { DialogHeader } from 'layouts/dialogLayouts/dialogLayouts';

import style from './formDialog.scss';

interface Props {
  /** The rendered child elements. */
  children: ReactNode;

  /** CSS styling to overwrite default dialog style. */
  className?: string;

  /** CSS styling to overwrite default dialog content style. */
  contentClassName?: string;

  /** CSS styling to overwrite default dialog inner content style. */
  contentInnerClassName?: string;

  /** An id used for testing. */
  dataTestId?: string;

  /** CSS styling to overwrite default dialog header style. */
  headerClassName?: string;

  /** True when dialog has fixed, full screen positioning. */
  isFixed?: boolean;

  /** True when dialog should be rendered. */
  isOpen: boolean;

  /** Function invoked on dialog close. */
  onClose?: () => void;

  /** Function invoked when form is submitted. */
  onSubmit?: (shouldSubmit?: boolean, shouldValidate?: boolean, options?: Record<string, any>) => void;

  /** The title of the dialog. */
  title: string;
}

class FormDialog extends PureComponent<Props> {
  static defaultProps = {
    onClose: () => {},
    onSubmit: () => {},
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit?.();
  };

  render() {
    const {
      children,
      className,
      contentClassName,
      contentInnerClassName,
      dataTestId,
      headerClassName,
      isFixed,
      isOpen,
      onClose,
      title,
    } = this.props;

    return (
      <Dialog
        className={classnames(style.dialog, className)}
        contentClassName={classnames(style.formContainer, contentClassName)}
        contentInnerClassName={classnames(style.formContainerInner, contentInnerClassName)}
        dataTestId={dataTestId}
        isFixed={isFixed}
        isOpen={isOpen}
        requestClose={onClose}
      >
        <DialogHeader className={headerClassName} onClose={onClose} title={title} />
        <form className={style.formContent} noValidate onSubmit={this.onSubmit}>
          {children}
        </form>
      </Dialog>
    );
  }
}

export default FormDialog;
