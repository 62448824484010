export const repUserFragment = `
  fragment repUser on User {
    id
    cellPhone
    created
    email
    firstName
    lastName
    photoUrl(width:250, height:250)
    secondaryPhone
    title
    locale
  }
`;

export const repsFragment = `
  fragment reps on AuctionRepresentative {
    auction {
      id
      title
    }
    representativeType {
      id
      name
      description
    }
    user {
      ...repUser
    }
  }
  ${repUserFragment}
`;
