import classnames from 'classnames';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { isNil } from 'lodash-es';

import Checkbox from 'forms/shared/checkbox';
import InputGroup from 'forms/shared/inputGroup';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import Select from 'forms/shared/select';
import { AccountSection, AccountSectionMultiLine } from 'layouts/accountLayouts/accountLayouts';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessages } from 'constants/errors';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { TireCondition } from 'components/sections/inventoryItem/addModify/vehicleFormPanes/segments/tireCondition';
import { TireGeneralCondition, tireGeneralConditionTranslationMap } from 'constants/enums/addModifyInventoryItem';
import { VehicleTireConditionInput } from 'store/shared/api/graph/interfaces/types';
import { getCheckboxArray } from 'utils/formUtils';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './tires.scss';

interface Props {
  /** Callback function to clear error. */
  clearError: () => void;
  /** Error messages. */
  errorMessages?: ErrorMessages;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs['results'];
  /** Name to the section reference. */
  sectionRef?: string;
  /** Callback function to set vehicle information. */
  setVehicle: (options: AddModifyVehicleProps) => void;
}

type SecondSetOfTiresOption = SelectOption<
  keyof Pick<VehicleTireConditionInput, 'secondSetOfTires' | 'secondSetOfTiresHasRims'> | false
>;

export const Tires = ({ clearError, errorMessages = [], inventoryItem, sectionRef, setVehicle }: Props) => {
  const hasError = !!errorMessages.length;
  const vehicle = inventoryItem?.vehicle;
  const conditionReport = vehicle?.conditionReport;
  const declarations = conditionReport?.declarations;
  const tireCondition = vehicle?.tireCondition;
  const verified = isVerified(vehicle?.captureType);

  const hasNoTitle = !!declarations?.includes('transferableTitleNotOnHand');
  const isNonRunner = !!declarations?.includes('nonRunnerRequiresTow');

  /**
   * Default to poor if not set
   */
  useEffect(() => {
    if (isNil(tireCondition?.generalCondition)) {
      setVehicle({ tireCondition: { ...(tireCondition || {}), generalCondition: TireGeneralCondition.POOR } });
    }
  }, [setVehicle, tireCondition]);

  /**
   * Memoized tire condition options.
   */
  const tireConditionOptions: SelectOption<boolean>[] = useMemo(
    () => [
      { label: t('yes'), value: true },
      { label: t('no'), value: false },
    ],
    []
  );

  /**
   * Memoized second set of tires options.
   */
  const secondSetOfTiresOptions: SecondSetOfTiresOption[] = useMemo(
    () => [
      { label: `${t('yes')} (${t('with_rims')})`, value: 'secondSetOfTiresHasRims' },
      { label: `${t('yes')} (${t('without_rims')})`, value: 'secondSetOfTires' },
      { label: t('no'), value: false },
    ],
    []
  );

  /**
   * Memoized tire options.
   */
  const tireOptions: { id: keyof VehicleTireConditionInput; label: string }[] = useMemo(
    () => [
      { id: 'tirePressureMonitoringSystem', label: t('tpms') },
      { id: 'afterMarketTires', label: t('aftermarket_wheels') },
      { id: 'winterTires', label: t('winter_tires') },
      { id: 'studdedWinterTires', label: t('studded_winter_tires') },
      { id: 'fourMatchingTires', label: t('four_matching_tires') },
    ],
    []
  );

  /**
   *  Memoized tire general condition options.
   */
  const tireGeneralConditionOptions = useMemo(
    () =>
      Object.entries(TireGeneralCondition).map(([key, value]) => ({
        label: t(tireGeneralConditionTranslationMap[key]),
        value,
      })),
    []
  );

  /**
   * Get the tire condition selection by field.
   */
  const getTireConditionSelection = useCallback(
    (field: keyof VehicleTireConditionInput) => {
      return tireConditionOptions.find((selectOption) => selectOption.value === tireCondition?.[field]);
    },
    [tireCondition, tireConditionOptions]
  );

  /**
   * Get the tire condition selection by field.
   */
  const getTireGeneralConditionSelection = useCallback(() => {
    return tireGeneralConditionOptions.find(
      (selectOption) => selectOption.value === (tireCondition?.generalCondition ?? TireGeneralCondition.POOR)
    );
  }, [tireCondition, tireGeneralConditionOptions]);

  /**
   * Memoized second set of tires selection.
   */
  const secondSetOfTiresSelection = useMemo(() => {
    if (isNil(tireCondition?.secondSetOfTires)) {
      return null;
    }
    if (tireCondition?.secondSetOfTiresHasRims === true && tireCondition?.secondSetOfTires === true) {
      return secondSetOfTiresOptions.find((option) => option.value === 'secondSetOfTiresHasRims');
    }
    if (tireCondition?.secondSetOfTires === true) {
      return secondSetOfTiresOptions.find((option) => option.value === 'secondSetOfTires');
    }
    return secondSetOfTiresOptions.find((option) => option.value === false);
  }, [secondSetOfTiresOptions, tireCondition?.secondSetOfTires, tireCondition?.secondSetOfTiresHasRims]);

  /**
   * Get the segment classnames by reference name.
   */
  const getSegmentClasses = useCallback(
    (refName: string) => {
      // TODO: Move to Add/Modify helper
      // TODO: Allow for custom styles argument
      return classnames(style.segment, sectionRef && sectionRef === refName && style.highlighted);
    },
    [sectionRef]
  );

  /**
   * Update the vehicle on declarations change.
   */
  const onDeclarationsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const declarationsNext = getCheckboxArray(e, e.target.id, declarations || []);
      setVehicle({ conditionReport: { ...(conditionReport || {}), declarations: declarationsNext } });
      if (hasError) {
        clearError();
      }
    },
    [clearError, conditionReport, declarations, hasError, setVehicle]
  );

  /**
   * Update vehicle on tire condition change.
   */
  const onTireConditionChange = useCallback(
    (field: keyof VehicleTireConditionInput) => (value: SelectOption) => {
      setVehicle({ tireCondition: { ...(tireCondition || {}), [field]: value.value } });
      if (hasError) {
        clearError();
      }
    },
    [clearError, hasError, setVehicle, tireCondition]
  );

  /**
   * Update tire condition on second set of tires change.
   */
  const onSecondSetOfTiresChange = useCallback(
    (option: SecondSetOfTiresOption) => {
      const hasSecondSetOfTires = option.value !== false;
      const hasRims = option.value === 'secondSetOfTiresHasRims';
      setVehicle({
        tireCondition: {
          ...(tireCondition || {}),
          secondSetOfTires: hasSecondSetOfTires,
          secondSetOfTiresHasRims: hasRims,
        },
      });
      if (hasError) {
        clearError();
      }
    },
    [clearError, hasError, setVehicle, tireCondition]
  );

  return (
    <div className={style.container} data-testid="add-vehicle-tires">
      <div className={getSegmentClasses('vehicleState')}>
        {!!errorMessages.length && (
          <ul className={style.errorMessageContainer}>
            {errorMessages.map((errorItem, index) => {
              return <li key={`error-${index}`}>{errorItem.message}</li>;
            })}
          </ul>
        )}
        <div className={classnames(style.segmentLayout, style.segmentLayoutCustom)}>
          <AccountSection className={style.section} title={t('vehicle_state')} titleClass={style.title}>
            {/* TODO: Get text from declarations metadata */}
            <Checkbox
              checked={hasNoTitle}
              id="transferableTitleNotOnHand"
              isButtonTheme
              onChange={onDeclarationsChange}
              text={t('transferable_title_not_on_hand')}
              theme="red"
            />
            <Checkbox
              checked={isNonRunner}
              id="nonRunnerRequiresTow"
              isButtonTheme
              onChange={onDeclarationsChange}
              text={t('vehicle_non_runner_requires_tow')}
              theme="red"
            />
          </AccountSection>
        </div>
      </div>
      <div className={getSegmentClasses('tires')}>
        <div className={classnames(style.segmentLayout, style.segmentLayoutCustom)}>
          <AccountSectionMultiLine className={style.section} title={t('tires')} titleClass={style.title}>
            {tireOptions.map((option) => (
              <InputGroup
                key={option.id}
                className={style.inputClass}
                groupType="select"
                hasMargin={false}
                label={option.label}
                name={option.id}
                onChange={onTireConditionChange(option.id)}
                options={tireConditionOptions}
                outerClassName={style.column}
                placeholder=""
                value={getTireConditionSelection(option.id)}
              />
            ))}
            <InputGroup
              className={style.inputClass}
              groupType="select"
              hasMargin={false}
              label={t('second_set_of_tires')}
              name="secondSetOfTires"
              onChange={onSecondSetOfTiresChange}
              options={secondSetOfTiresOptions}
              outerClassName={style.column}
              placeholder=""
              value={secondSetOfTiresSelection}
            />
          </AccountSectionMultiLine>
        </div>
      </div>

      {!verified && (
        <div className={getSegmentClasses('generalCondition')} data-testid="general-tire-condition">
          <div className={classnames(style.segmentLayout, style.segmentLayoutCustom)}>
            <AccountSection
              className={style.column}
              title={t('tire_condition')}
              titleClass={style.title}
              titleTooltip={<InterpolatedTranslation namespace="general_tire_condition_message" />}
            >
              <Select
                className={style.inputClass}
                defaultValue={getTireGeneralConditionSelection()}
                id="tireGeneralCondition"
                onChange={onTireConditionChange('generalCondition')}
                options={tireGeneralConditionOptions}
              />
            </AccountSection>
          </div>
        </div>
      )}
      {verified && (
        <div
          className={classnames(getSegmentClasses('tireCondition'), style.tireConditionSegment)}
          data-testid="tire-condition"
        >
          <TireCondition
            clearError={clearError}
            hasError={hasError}
            setVehicle={setVehicle}
            tireCondition={tireCondition}
          />
        </div>
      )}
    </div>
  );
};

export default Tires;
