import { InventoryItemValue, InventoryItemValueTypeEnum } from 'store/shared/api/graph/interfaces/types';

export const mmrValueTypes = [InventoryItemValueTypeEnum.MMR_ADJUSTED];

export const mmrHeaderTranslationMap = {
  [InventoryItemValueTypeEnum.MMR_ADJUSTED]: 'adjusted_mmr',
};

/**
 * Returns sorted array of `InventoryItemValue` objects that match the `mmrValueTypes`.
 * @param values
 */
export const getMMRValues = (values: InventoryItemValue[] | undefined) => {
  const mmrValuesFilteredAndSorted = values
    // Filter based on `mmrValueTypes`
    ?.filter(({ type }) => mmrValueTypes.includes(type.id))
    // Match ordering to `mmrValueTypes`
    .sort((a, b) => mmrValueTypes.indexOf(a.type.id) - mmrValueTypes.indexOf(b.type.id));

  return !mmrValuesFilteredAndSorted?.length ? undefined : mmrValuesFilteredAndSorted;
};
