import { splitOnNewLine } from 'utils/componentUtils';
import { t } from 'utils/intlUtils';

import style from 'forms/account/accountVerification/accountVerification.scss';

const MobileVerificationSuccess = () => {
  return (
    <>
      <p className={style.title}>{t('successfully_verified_mobile_phone')}</p>
      <p>{splitOnNewLine(t('successfully_verified_mobile_phone_message'))}</p>
    </>
  );
};

export default MobileVerificationSuccess;
