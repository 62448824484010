import { ReactNode } from 'react';

import { GlobalDialogProvider } from 'contexts/globalDialogContext';
import { LiveLanesVideoStreamProvider } from 'contexts/liveLanesVideoStreamContext';
import { MyBlockPrintContentProvider } from 'contexts/myBlockPrintContentContext';
import { composeComponents } from 'utils/componentUtils';

interface Props {
  children: ReactNode;
}

const ComposedProvider = composeComponents([
  GlobalDialogProvider,
  LiveLanesVideoStreamProvider,
  MyBlockPrintContentProvider,
]);

// All EBlock specific providers that require a global context should live here
const EBlockProvider = ({ children }: Props) => <ComposedProvider>{children}</ComposedProvider>;

export default EBlockProvider;
