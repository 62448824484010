import classnames from 'classnames';

import style from './statusInfo.scss';

interface Props {
  /** CSS styling to override default style. */
  className?: string;
  /** The displayed info box text. */
  infoText: string;
  /** The subtitle displayed after the info box. */
  subtitle: string | undefined;
  /** The title displayed before the info box. */
  title: string;
}

const StatusInfo = ({ className, infoText, subtitle, title }: Props) => {
  return (
    <div className={classnames(style.container, className)}>
      <div className={style.title}>{title}</div>
      <div className={style.infoBox}>{infoText}</div>
      <div className={style.subtitle}>{subtitle}</div>
    </div>
  );
};

export default StatusInfo;
