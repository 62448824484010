import ApiRequest from 'store/shared/api/apiRequest';
import {
  LocationInventoryService,
  MutationlocationInventoryServiceCreateArgs,
  MutationlocationInventoryServiceRemoveArgs,
} from 'store/shared/api/graph/interfaces/types';

export const locationInventoryServiceCreate = ({
  amount,
  locationId,
  serviceMetadataId,
}: MutationlocationInventoryServiceCreateArgs) =>
  ApiRequest<{ locationInventoryServiceCreate: LocationInventoryService }>({
    data: {
      operationName: 'locationInventoryServiceCreate',
      query: `
        mutation ($amount: Float!, $locationId: String!, $serviceMetadataId: String!) {
          locationInventoryServiceCreate(
            amount: $amount
            locationId: $locationId
            serviceMetadataId: $serviceMetadataId
          ) {
            id
          }
        }
      `,
      variables: {
        amount,
        locationId,
        serviceMetadataId,
      },
    },
  });

export const locationInventoryServiceRemove = ({
  locationInventoryServiceId,
}: MutationlocationInventoryServiceRemoveArgs) =>
  ApiRequest<{ locationInventoryServiceRemove: boolean }>({
    data: {
      operationName: 'locationInventoryServiceRemove',
      query: `
        mutation ($locationInventoryServiceId: String!) {
          locationInventoryServiceRemove(locationInventoryServiceId: $locationInventoryServiceId)
        }
      `,
      variables: { locationInventoryServiceId },
    },
  });
