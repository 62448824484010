import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import AwaitingCheckout from './awaitingCheckout';
import BaseClass from 'components/ui/shared/base';
import Cancelled from './cancelled';
import IfBid from './ifBid';
import LiveAppraisal from './liveAppraisal';
import LiveAuction from './liveAuction';
import LiveGrounded from 'components/sections/auctionItem/details/status/liveGrounded';
import LiveTimedOffer from './liveTimedOffer';
import NoSale from './noSale';
import PendingDelivery from './pendingDelivery';
import Sold from './sold';
import UpcomingAuction from './upcomingAuction';
import { AppState } from 'store/configureStore';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { LooseObject } from 'constants/objects';
import { TransactionType } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import styleVariables from 'styles/variables.scss';

const stateConnect = (state: AppState) => ({
  /** Current logged in user. */
  user: state.app.user,
});

const connector = connect(stateConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item details. */
  auctionItemDetails: AuctionItemDetailsProps;
  /** Function invoked when inventory option clicked. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
}

class AuctionItemStatus extends BaseClass<Props> {
  showAutobids = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('AUTO_BIDS');
  };

  showIfBids = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('IF_BIDS');
  };

  showIfBidWith = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { transactionType } = this.props.auctionItemDetails;
    this.props.handleFeatureClicked(transactionType === TransactionType.BUYING ? 'SELLER' : 'BUYER');
  };

  showBids = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('BIDS');
  };

  showHighestBidder = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { transactionType } = this.props.auctionItemDetails;
    this.props.handleFeatureClicked(transactionType === TransactionType.BUYING ? 'SELLER' : 'BUYER');
  };

  showRanBefore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('VEHICLE_HISTORY');
  };

  showBuyer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('BUYER');
  };

  showSeller = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleFeatureClicked('SELLER');
  };

  showTransportEstimate = (auctionItem: AuctionItem) => {
    this.props.handleFeatureClicked('TRANSPORT_ESTIMATE', { auctionItem });
  };

  showAuditLog = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { auctionItemDetails } = this.props;
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_AUDIT_LOG_CLICK, auctionItemDetails);
    this.props.handleFeatureClicked('AUDIT_LOG');
  };

  render() {
    const { user, auctionItemDetails } = this.props;

    let statusElement;

    switch (`${auctionItemDetails.status}_${auctionItemDetails.format}`) {
      case 'UPCOMING_AUCTION':
      case 'UPCOMING_AUCTION_PHYSICAL':
        statusElement = (
          <UpcomingAuction
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
            user={user}
          />
        );
        break;

      case 'INIT_AUCTION':
      case 'INIT_AUCTION_PHYSICAL':
      case 'LIVE_AUCTION':
      case 'LIVE_AUCTION_PHYSICAL':
      case 'PAUSED_AUCTION':
      case 'PAUSED_AUCTION_PHYSICAL':
        statusElement = (
          <LiveAuction
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
            user={user}
          />
        );
        break;

      case 'LIVE_GROUNDED':
        statusElement = (
          <LiveGrounded
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
            user={user}
          />
        );
        break;

      case 'LIVE_APPRAISAL':
        statusElement = (
          <LiveAppraisal
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
            user={user}
          />
        );
        break;

      case 'LIVE_TIMED_OFFER':
        statusElement = (
          <LiveTimedOffer
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
          />
        );
        break;

      case 'IN_IF_BID_GROUNDED':
      case 'IN_IF_BID_AUCTION':
      case 'IN_IF_BID_AUCTION_PHYSICAL':
      case 'IN_IF_BID_APPRAISAL':
        statusElement = (
          <IfBid
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowIfBids={this.showIfBids}
            onShowIfBidWith={this.showIfBidWith}
            onShowRanBefore={this.showRanBefore}
            onShowTransportEstimate={this.showTransportEstimate}
            user={user}
          />
        );
        break;

      case 'PENDING_DELIVERY_APPRAISAL':
        statusElement = (
          <PendingDelivery
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowBuyer={this.showBuyer}
            onShowIfBids={this.showIfBids}
            onShowRanBefore={this.showRanBefore}
            onShowSeller={this.showSeller}
            user={user}
          />
        );
        break;

      case 'AWAITING_CHECKOUT_AUCTION':
      case 'AWAITING_CHECKOUT_AUCTION_PHYSICAL':
      case 'AWAITING_CHECKOUT_GROUNDED':
      case 'AWAITING_CHECKOUT_APPRAISAL':
      case 'AWAITING_CHECKOUT_TIMED_OFFER':
        statusElement = (
          <AwaitingCheckout
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowBuyer={this.showBuyer}
            onShowIfBids={this.showIfBids}
            onShowRanBefore={this.showRanBefore}
            onShowSeller={this.showSeller}
            user={user}
          />
        );
        break;

      case 'NO_SALE_GROUNDED':
      case 'NO_SALE_AUCTION':
      case 'NO_SALE_AUCTION_PHYSICAL':
      case 'NO_SALE_APPRAISAL':
      case 'NO_SALE_TIMED_OFFER':
        statusElement = (
          <NoSale
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowHighestBidder={this.showHighestBidder}
            onShowIfBids={this.showIfBids}
            onShowRanBefore={this.showRanBefore}
            user={user}
          />
        );
        break;

      case 'SOLD_APPRAISAL':
      case 'SOLD_AUCTION':
      case 'SOLD_AUCTION_PHYSICAL':
      case 'SOLD_GROUNDED':
      case 'SOLD_TIMED_OFFER':
        statusElement = (
          <Sold
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowBuyer={this.showBuyer}
            onShowIfBids={this.showIfBids}
            onShowRanBefore={this.showRanBefore}
            onShowSeller={this.showSeller}
            user={user}
          />
        );
        break;

      case 'SALE_CANCELLED_APPRAISAL':
      case 'SALE_CANCELLED_AUCTION':
      case 'SALE_CANCELLED_AUCTION_PHYSICAL':
      case 'SALE_CANCELLED_GROUNDED':
      case 'SALE_CANCELLED_TIMED_OFFER':
        statusElement = (
          <Cancelled
            key="status"
            details={auctionItemDetails}
            onShowAuditLog={this.showAuditLog}
            onShowAutobids={this.showAutobids}
            onShowBids={this.showBids}
            onShowBuyer={this.showBuyer}
            onShowIfBids={this.showIfBids}
            onShowRanBefore={this.showRanBefore}
            onShowSeller={this.showSeller}
            user={user}
          />
        );
        break;

      default:
        console.info(`No handler for status=${auctionItemDetails.status}, format=${auctionItemDetails.format}`);
        statusElement = (
          <div
            key="error"
            style={{ color: styleVariables.colorWhite, backgroundColor: styleVariables.colorRed, padding: '20px' }}
          >
            No handler for {auctionItemDetails.status}_{auctionItemDetails.format}
          </div>
        );
    }

    return statusElement;
  }
}

export default connector(AuctionItemStatus);
