import { createAction } from 'redux-actions';

import { getSearchResults } from './homeDashboardSearchApi';

export const isLoading = createAction('HOME_DASHBOARD_SEARCH_SET_IS_LOADING');
export const isNotLoading = createAction('HOME_DASHBOARD_SEARCH_UNSET_IS_LOADING');
export const isUpdating = createAction('HOME_DASHBOARD_SEARCH_SET_IS_UPDATING');
export const homeDashboardSearchLoaded = createAction('HOME_DASHBOARD_SEARCH_LOADED');
export const homeDashboardSearchClear = createAction('HOME_DASHBOARD_SEARCH_CLEAR');

export const processGetSearchList = (options, dispatch) => {
  dispatch(isLoading());
  return getSearchResults(options).then((response) => dispatch(homeDashboardSearchLoaded(response?.data?.data)));
};

export const processClearSearchList = (dispatch) => {
  dispatch(homeDashboardSearchClear());
};

export const processUpdateSearchList = (options, dispatch) => {
  dispatch(isUpdating());
  return getSearchResults(options).then((response) => dispatch(homeDashboardSearchLoaded(response?.data?.data)));
};
