import AuctionItem from 'constants/auctionItem';
import AcceptCounterOffer from 'components/sections/myBlock/list/status/operations/acceptCounterOffer';
import StatusColumn from 'components/sections/myBlock/list/status/statusColumn';
import StatusRow from 'components/sections/myBlock/list/status/statusRow';
import StatusRowCost from 'components/sections/myBlock/list/status/statusRowCost';
import StatusRowReserve from 'components/sections/myBlock/list/status/statusRowReserve';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { t } from 'utils/intlUtils';

import style from './statusInfo.scss';

interface Props {
  /** The auction item details*/
  auctionItem: AuctionItem;
  /** The variation of my block view. */
  variant: MyBlockVariant;
}

const StatusInIfBid = ({ auctionItem, variant }: Props) => {
  return (
    <div className={style.container} data-testid="status-in-if-bid">
      <StatusColumn>
        <StatusRow subTitle={auctionItem?.topOffer?.amount?.formattedAmount} title={t('current_offer')} />
        <StatusRow
          subTitle={auctionItem?.ifBidTimeline?.amountApart?.formattedAmount}
          title={t('if_bid_amount_apart')}
        />
        {variant === MyBlockVariant.SELLER && <StatusRowReserve auctionItem={auctionItem} dataTestId="reserve-row" />}
        {variant === MyBlockVariant.SELLER && <StatusRowCost auctionItem={auctionItem} dataTestId="cost-row" />}
      </StatusColumn>
      <div className={style.operations}>
        <AcceptCounterOffer auctionItem={auctionItem} />
      </div>
    </div>
  );
};

export default StatusInIfBid;
