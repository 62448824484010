import { connect, ConnectedProps } from 'react-redux';

import modifyGlyph from 'glyphs/operationIcons/modify.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { AuctionItemUpdateInput } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrorMessages } from 'utils/apiUtils';
import { processAuctionItemUpdate } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to update auction item. */
  itemUpdate: (options: AuctionItemUpdateInput) => processAuctionItemUpdate(options, dispatch),
});

const connector = connect(null, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details.*/
  auctionItem: AuctionItem;
  /** Function invoked when auctionItemUpdate dispatch has completed. */
  onDone?: (value?: boolean) => void;
}

interface State {
  /** Validation Errors. */
  errorMessages?: ErrorMessages;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True when form is being submitted. */
  isSubmitting: boolean;
}

class ModifyPricing extends BaseClass<Props, State> {
  static defaultProps = {
    onDone: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit: boolean, options: AuctionItemUpdateInput) => {
    const { auctionItem, itemUpdate, onDone } = this.props;

    if (shouldSubmit) {
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_MODIFY_PRICING_CONFIRM_CLICK, auctionItem);
      this.setState({ isSubmitting: true });
      itemUpdate(options)
        .then(() => {
          this.setState({ errorMessages: null, isOpen: false, isSubmitting: false });
          onDone?.(true);
        })
        .catch((error) => {
          const errorMessages = getErrorMessages(error);
          this.setState({ errorMessages, isSubmitting: false });
        });
    } else {
      this.setState({ errorMessages: null, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    const { auctionItem } = this.props;
    const { errorMessages, isOpen, isSubmitting } = this.state;

    return (
      <>
        <OperationButton
          dataTestId="modifyPricingButton"
          glyph={modifyGlyph}
          onClick={() => {
            trackUserActionWithAuctionItemAttributes(UserAction.VDP_MODIFY_PRICING_CLICK, auctionItem);
            this.setState({ isOpen: true });
          }}
          theme="green"
        >
          {t('modify_pricing')}
        </OperationButton>
        {isOpen && (
          <Dialog
            auctionItem={auctionItem}
            errorMessages={errorMessages}
            isOpen
            isSubmitting={isSubmitting}
            onSubmit={this.onSubmit}
          />
        )}
      </>
    );
  }
}

export default connector(ModifyPricing);
