import { HTMLAttributes, ReactNode, useCallback, useRef, useState } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The component's child elements to be rendered. */
  children?: ReactNode;

  /** Function invoked on a regular click. */
  onClick?: (event) => void;

  /** Function invoked on a long click */
  onLongClick: () => void;

  /** The timeout interval used to determine the length of a long click. */
  timeoutInterval?: number;
}

/**
 * Detect long click events on a wrapped component
 */
const LongClick = ({ children, onClick, onLongClick, timeoutInterval = 1000, ...props }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [startTime, setStartTime] = useState<number>();
  const [timerId, setTimerId] = useState<number>();

  /**
   * ClearTimer
   *
   * Clear the window timeout attached to timerId.
   */
  const clearTimer = useCallback(() => {
    if (timerId) {
      window.clearTimeout(timerId);
      setTimerId(undefined);
    }
  }, [timerId]);

  /**
   * onMouseUp
   *
   * If the timeout interval has NOT passed then invoke onClick(),
   * Otherwise, onMouseDown will have invoked onLongClick().
   * */
  const onMouseUp = useCallback(
    (event) => {
      clearTimer();
      const endTime = new Date().getTime();
      if (startTime && endTime - startTime < timeoutInterval) {
        onClick?.(event);
      }
      setStartTime(undefined);
    },
    [clearTimer, onClick, startTime, timeoutInterval]
  );

  /**
   * onMouseDown
   *
   * Set start time and invoke onLongClick() when timeoutInterval has been reached.
   */
  const onMouseDown = useCallback(() => {
    clearTimer();
    setStartTime(new Date().getTime());

    // Invokes onLongClick() when timeoutInterval reached.
    const id = window.setTimeout(onLongClick, timeoutInterval);
    setTimerId(id);
  }, [clearTimer, onLongClick, timeoutInterval]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      ref={containerRef}
      data-testid="longClick"
      onClick={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      {...props}
    >
      {children}
    </div>
  );
};

export default LongClick;
