import ApiRequest from 'store/shared/api/apiRequest';
import { BroadcastConnection } from 'store/shared/api/graph/interfaces/types';
import { createGraphQLArgs } from 'store/shared/api/graph/createGraphQLArgs';
import { facetGroups, pageInfo } from 'store/shared/api/graph/schema/connection';

const _queryArgs = {
  after: 'String',
  auctionIds: '[ID!]',
  before: 'String',
  first: 'Int',
  keyword: 'String',
  last: 'Int',
  status: '[BroadcastStatus]',
  type: '[BroadcastType]',
};

export const getBroadcastsList = ({ status = ['ACTIVE', 'UPCOMING'], ...options }) => {
  const { queryArgs, fieldsArgs, variables } = createGraphQLArgs(_queryArgs, { status, ...options });

  return ApiRequest<{ broadcastsConnection: BroadcastConnection }>({
    data: {
      operationName: 'getBroadcastsList',
      query: `
        query(${queryArgs}) {
          broadcastsConnection(${fieldsArgs}) {
            edges {
              node {
                id
                auction {
                  id
                  title
                }
                end
                message
                regionCodes
                start
                status
                type
              }
            }
            ${facetGroups}
            ${pageInfo}
          }
        }
      `,
      variables,
    },
  });
};
