import classnames from 'classnames';

import style from './label.scss';

interface LabelProps {
  children?: string;
  className?: string;
  name?: string;
}

const Label = ({ className, children, name }: LabelProps) => (
  <label className={classnames(style.label, className)} htmlFor={name}>
    {children}
  </label>
);

export default Label;
