import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import {
  arbitrationsListLoaded,
  arbitrationsListError,
  facetGroupsLoaded,
  pageInfoLoaded,
  arbitrationsListIsLoading,
  arbitrationsListCleared,
  arbitrationsListUpdateItem,
} from './arbitrationsActions';
import { InitialState, Arbitrations } from './arbitrationsModels';
import { FacetGroup, PageInfo } from 'store/shared/models/graph/connections';

export const arbitrationsReducer = handleActions(
  {
    [arbitrationsListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [arbitrationsListLoaded().type]: (state, action) => {
      const { edges, pageInfo, facetGroups } = action.payload;

      return state.setLoaded().merge({
        resultList: List(edges.map((edge) => new Arbitrations(edge.node))),
        facetGroups: List(facetGroups.map((facetGroupData) => new FacetGroup(facetGroupData))),
        pageInfo: new PageInfo(pageInfo),
      });
    },

    [arbitrationsListCleared().type]: (state) =>
      state.unsetLoading().merge({
        resultList: List([]),
        facetGroups: List([]),
        pageInfo: null,
      }),

    [pageInfoLoaded().type]: (state, action) => state.set('pageInfo', action.payload),

    [facetGroupsLoaded().type]: (state, action) =>
      state.set('facetGroups', List(action.payload.map((facetGroupData) => new FacetGroup(facetGroupData)))),

    [arbitrationsListError().type]: (state, action) => state.setError(action.payload),

    [arbitrationsListUpdateItem().type]: (state, action) => {
      const arbitrationNext = action.payload;
      const jobIndex = state.resultList.findIndex((item) => item?.id === arbitrationNext.id);

      if (jobIndex === -1) {
        return state.merge({ resultList: state.resultList.insert(0, new Arbitrations(arbitrationNext)) });
      }

      const existingItem = state.resultList.get(jobIndex)!.toJS();
      const updatedItem = new Arbitrations(merge({}, existingItem, arbitrationNext));
      return state.merge({ resultList: state.resultList.set(jobIndex, updatedItem) });
    },
  },
  new InitialState()
);
