import transferGlyph from 'glyphs/operationIcons/transfer.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import InventoryItem from 'constants/inventoryItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { ErrorMessages } from 'constants/errors';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import {
  processChangeAuctionItemConsigner,
  processChangeInventoryItemConsigner,
} from 'store/admin/consigners/list/consignersActions';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

interface Props extends RouterProps {
  /** The id of the auction item. */
  auctionItem?: AuctionItem;
  /** The id of the inventory item.*/
  inventoryItem?: InventoryItem;
  /** Function invoked when changeItem dispatch has completed. */
  onDone?: (value?: boolean) => void;
}

interface State {
  /** Validation Errors. */
  errorMessages: ErrorMessages | undefined;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True when form is being submitted. */
  isSubmitting: boolean;
}

class ChangeSeller extends BaseClass<Props, State> {
  static defaultProps = {
    onDone: () => {},
  };

  constructor(props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit: boolean, options) => {
    let onSubmit;
    let onSubmitOptions;

    const { inventoryItem, auctionItem, onDone, router } = this.props;

    if (inventoryItem) {
      onSubmit = processChangeInventoryItemConsigner;
      onSubmitOptions = { inventoryItemId: inventoryItem.id, ...options };
      trackUserActionWithInventoryItemAttributes(UserAction.VDP_CHANGE_SELLER_CONFIRM_CLICK, inventoryItem);
    } else {
      onSubmit = processChangeAuctionItemConsigner;
      onSubmitOptions = { auctionItemId: auctionItem?.id, ...options };
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_CHANGE_SELLER_CONFIRM_CLICK, auctionItem);
    }

    if (shouldSubmit) {
      this.setState({ isSubmitting: true });
      onSubmit(onSubmitOptions)
        .then((id) => {
          onDone?.(true);
          router.push(`/sell/parked?id=${id}`);
          this.setState({ errorMessages: null, isOpen: false, isSubmitting: false });
        })
        .catch(this.onApiError)
        .finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
      onDone?.(false);
    }
  };

  onClick = () => {
    const { inventoryItem, auctionItem } = this.props;
    this.setState({ isOpen: true });

    if (inventoryItem) {
      trackUserActionWithInventoryItemAttributes(UserAction.VDP_CHANGE_SELLER_CLICK, inventoryItem);
    } else {
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_CHANGE_SELLER_CLICK, auctionItem);
    }
  };

  render() {
    const { auctionItem } = this.props;

    return (
      <>
        <OperationButton glyph={transferGlyph} onClick={this.onClick} theme="green">
          {t('change_seller')}
        </OperationButton>
        <Dialog isAuctionItem={!!auctionItem} onSubmit={this.onSubmit} {...this.state} />
      </>
    );
  }
}

export default withRouter(ChangeSeller);
