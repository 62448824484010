/**
 * This class is meant to help derive a latency compensation value based on
 * sporadic latency measurements.
 *
 * The latency compensation is calculated based on a rolling (FIFO) collection
 * of the most recently reported latencies -- the default size is 100 measurements.
 * The result is the average of the collected latencies within some number (default 1) of
 * standard deviations from the mean. The result is then rounded to the nearest multiple
 * of the supplied width (default 25).
 */
export class Latency {
  i: number;
  latencies: number[];
  size: number;
  stdDevs: number;
  width: number;

  /**
   * Creates a Latency instance.
   *
   * @param {number} size number of recent measurements to consider
   * @param {number} width derived value will be rounded to the nearest multiple of width
   * @param {number} stdDevs max number of standard deviations for measurement to matter
   */
  constructor(size: number = 100, width: number = 25, stdDevs: number = 1) {
    this.size = size;
    this.width = width;
    this.stdDevs = stdDevs;
    this.latencies = [];
    this.i = 0;
  }

  /**
   * Report a latency measurement and return the latency compensation based on the
   * values passed into the constructor.
   *
   * @param {number} latency observed in ms
   * @returns {number} average latency
   */
  report(latency: number): number {
    this.latencies[this.i % this.size] = latency;
    this.i += 1;

    const mean = this.latencies.reduce((sum, ms) => sum + ms, 0) / this.latencies.length;
    const stdDev =
      Math.sqrt(this.latencies.reduce((sum, ms) => sum + (ms - mean) ** 2, 0) / this.latencies.length) || 1;

    let num = 0;
    let sum = 0;
    this.latencies.forEach((ms) => {
      if (Math.abs(ms - mean) < this.stdDevs * stdDev) {
        sum += ms;
        num += 1;
      }
    });

    return num === 0 ? 0 : Math.round(sum / num / this.width) * this.width;
  }
}
