import { AuctionLaneType, AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';

export enum AuctionFormatType {
  TIMED = 'TIMED',
  LIVE = 'LIVE',
  TIMED_OFFER = 'TIMED_OFFER',
}

// For `Appraisal/Buy Now` auction type
export interface TimedAuctionDates {
  /** A list of available auction dates for appraisal. */
  appraisal: AuctionDateType[];
  /** A list of available auction dates for grounded. */
  grounded: AuctionDateType[];
}

export type LanesByType = Partial<Record<AuctionLaneType, AuctionTimeSlotLane[]>>;

// For `Auction` auction type
export interface AuctionDateType {
  /** The subtitle to display. */
  subtitle?: string;
  /** The title to display. */
  title: string;
  /** The id of the live auction date. */
  value: string;
}

export type AuctionDates = TimedAuctionDates | AuctionDateType[];
