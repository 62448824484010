import { Record } from 'immutable';

import ServerRecord from 'store/shared/models/serverRecord';
import { currentNotesCompanies } from 'store/notes/notesActions';

export const InitialState = ServerRecord({
  AUCTION_ITEM: null,
  AUCTION_TIME_SLOT: null,
  CARRIER: null,
  CONSIGNER: null,
  INVENTORY_ITEM: null,
  REQUESTED_CAPTURE: null,
  REQUESTED_INVITE: null,
  TRANSPORT_JOB: null,
  TRANSPORT_REQUEST: null,
  USER: null,
  currentNotesCompanies: JSON.parse(currentNotesCompanies?.get() || 'null'),
});

export const Note = Record({
  id: null,
  name: null,
  content: null,
  created: null,
  createdBy: null,
});
