import { ReactNode } from 'react';
import classnames from 'classnames';

import exclamationGlyph from 'glyphs/warning.svg';
import infoGlyph from 'glyphs/info.svg';

import Sprite from 'components/ui/shared/sprite';
import {
  AssuredBadge,
  BuyerShieldBadge,
  VerifiedBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';

import style from './notificationBanner.scss';

interface Props {
  /** Child elements to be rendered. */
  children: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;

  /** The type of glyph to render. */
  primaryGlyphType?: 'assured' | 'buyerShield' | 'info' | 'verified' | 'warning';

  /** The url of the optional second glyph. */
  secondaryGlyphURL?: string;

  /** True when second glyph is displayed. */
  showSecondaryGlyph?: boolean;

  /** The theme of the notification banner. */
  theme?: 'black' | 'blue' | 'gold' | 'green' | 'green-solid' | 'red';
}

const NotificationBanner = ({
  children,
  className,
  primaryGlyphType = 'warning',
  secondaryGlyphURL,
  showSecondaryGlyph = false,
  theme = 'red',
}: Props) => {
  const classname = classnames(style.banner, style[`theme-${theme}`], className);

  return (
    <div className={classname} data-testid="notification-banner">
      {primaryGlyphType === 'assured' && <AssuredBadge />}
      {primaryGlyphType === 'buyerShield' && <BuyerShieldBadge />}
      {primaryGlyphType === 'info' && <Sprite className={style.iconInfo} glyph={infoGlyph} />}
      {primaryGlyphType === 'verified' && <VerifiedBadge />}
      {primaryGlyphType === 'warning' && <Sprite className={style.iconWarning} glyph={exclamationGlyph} />}
      <span className={style.content}>{children}</span>
      {showSecondaryGlyph || secondaryGlyphURL ? (
        <>
          {secondaryGlyphURL ? (
            <a className={style.secondaryIconLink} href={secondaryGlyphURL} rel="noopener noreferrer" target="_blank">
              <Sprite className={style.secondaryIcon} glyph={infoGlyph} />
            </a>
          ) : (
            <Sprite className={style.secondaryIcon} glyph={infoGlyph} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default NotificationBanner;
