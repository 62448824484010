import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionService, InventoryService, QueryfeesArgs } from 'store/shared/api/graph/interfaces/types';

export const getFees = ({ active, auctionId, auctionItemId, companyId, format, party }: QueryfeesArgs) =>
  ApiRequest<{ fees: (AuctionService & InventoryService)[] }>({
    data: {
      operationName: 'getFees',
      query: `
        query (
          $active: Boolean,
          $auctionId: String!,
          $auctionItemId: String,
          $companyId: String,
          $format: AuctionItemFormat!,
          $party: AuctionServiceMetadataParty!
        ) {
          fees (
            active: $active,
            auctionId: $auctionId,
            auctionItemId: $auctionItemId,
            companyId: $companyId,
            format: $format,
            party: $party
          ) {
            id
            serviceMetadata {
              id
              feeName
              party
            }
            ...on AuctionService {
              buyerShield
              companySubTypes
              created
              format
              verified
              feeType
              feeFixedMode
              feeFixedValue
              feeTiers {
                id
                mode
                value
                startPrice
                endPrice
              }
              paidBy
              updated
            }
            ...on InventoryService {
              amount
              created
              inventoryItem {
                id
              }
              invoiced
              updated
            }
          }
        }
    `,
      variables: {
        active,
        auctionId,
        auctionItemId,
        companyId,
        format,
        party,
      },
    },
  });
