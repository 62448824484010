import { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputGroup from 'forms/shared/inputGroup';
import MileageInput, { MileageType } from 'forms/shared/mileageInput';
import { AddModifyVehicleProps } from 'store/inventoryItem/addModify/addModifyModels';
import { t } from 'utils/intlUtils';

import style from './lastKnownMileageDialog.scss';

export const lastKnownMileageDeclarationTriggers = [
  'fiveDigitOdometerOver100000Kms',
  'odometerReplaced',
  'odometerBrokenOrFaulty',
  'odometerRolledBack',
  'odometerUnreadable',
  'trueMileageUnknown',
];

export interface MileageValues {
  /** The  mileage amount. */
  mileageAmount: number;
  /** The mileage date. */
  mileageDate: Moment;
  /** The mileage unit. */
  mileageUnit: MileageType;
}

interface Props {
  /** True when dialog is open. */
  isOpen: boolean;
  /** The last known mileage. */
  lastKnownMileage?: AddModifyVehicleProps['lastKnownMileage'];
  /** The last known mileage date. */
  lastKnownMileageDate: Moment | null;
  /** Function invoked on confirm clicked. */
  onConfirm: (shouldSubmit: boolean, options) => void;
}

const LastKnownMileageDialog = ({ isOpen, lastKnownMileage, lastKnownMileageDate: mileageDate, onConfirm }: Props) => {
  const [lastKnownMileageDateFocused, setLastKnownMileageDateFocused] = useState<boolean>(false);
  const [lastKnownMileageAmount, setLastKnownMileageAmount] = useState<number | null>(lastKnownMileage?.amount || null);
  const [lastKnownMileageUnit, setLastKnownMileageUnit] = useState<MileageType>(
    (lastKnownMileage?.unit || 'km') as MileageType
  );
  const [lastKnownMileageDate, setLastKnownMileageDate] = useState<Moment | null>(
    mileageDate ? moment(mileageDate) : null
  );

  /**
   * OnConfirmDialog - Invoked on dialog confirm.
   */
  const onConfirmDialog = useCallback(
    (shouldSubmit: boolean) => {
      const options = {
        mileageAmount: lastKnownMileageAmount,
        mileageUnit: lastKnownMileageUnit,
        mileageDate: lastKnownMileageDate,
      };
      onConfirm(shouldSubmit, options);
    },
    [lastKnownMileageAmount, lastKnownMileageUnit, lastKnownMileageDate, onConfirm]
  );

  return (
    <ConfirmDialog
      containerClassName={style.lastKnownMileageDialog}
      isOpen={isOpen}
      onConfirm={onConfirmDialog}
      theme="blue"
      title={t('last_known_mileage')}
    >
      <p>{t('last_known_mileage_message')}</p>

      <div className={style.formInputs}>
        <InputGroup
          date={lastKnownMileageDate}
          daySize={25}
          displayFormat="YYYY/MM/DD"
          focused={lastKnownMileageDateFocused}
          groupType="calendar"
          hasMargin={false}
          hideKeyboardShortcutsPanel
          id="lastKnownMileageDate"
          isOutsideRange={() => {}}
          name="lastKnownMileageDate"
          numberOfMonths={1}
          onDateChange={(value) => setLastKnownMileageDate(value)}
          onFocusChange={({ focused }) => setLastKnownMileageDateFocused(focused)}
          outerClassName={style.lastKnownMileageDialogInner}
          placeholder={t('last_known_mileage_date')}
        />
        <MileageInput
          className={style.mileageInput}
          onChange={(value) => setLastKnownMileageAmount(value)}
          onUnitChange={(value) => setLastKnownMileageUnit(value?.toLowerCase())}
          unit={lastKnownMileageUnit}
          value={lastKnownMileageAmount || undefined}
        />
      </div>
    </ConfirmDialog>
  );
};

export default LastKnownMileageDialog;
