import classnames from 'classnames';
import { useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import DialogContent from 'layouts/inventoryItemLayouts/addModify/dialogContentLayouts';
import RadioButtons from 'forms/shared/radioButtons';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessages } from 'constants/errors';
import { Page } from 'constants/enums/addModifyInventoryItem';
import { Spinner } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './vinOverlay.scss';

interface Props {
  /** Callback function to change page. */
  changePage: (option: Page) => void;
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Callback function to create inventory item updates. */
  handleSubmitCreateInventoryItem: (obj?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs;
  /** Whether is loading or not. */
  isLoading: boolean;
  /** Callback function to set vehicle information. */
  setVehicle: (option: AddModifyVehicleProps) => void;
  /** Callback function to submit vin overlay. */
  submitVinOverlay: (option: AddModifyVehicleProps) => void;
  /** Current vehicle. */
  vehicle: AddModifyVehicleProps;
}

const VinSelectTrimOverlay = ({
  changePage,
  errorMessages,
  handleSubmitCreateInventoryItem,
  inventoryItem,
  isLoading,
  setVehicle,
  submitVinOverlay,
  vehicle,
}: Props) => {
  const [trimId, setTrimId] = useState<string>();
  const [validateVin, setValidateVin] = useState<boolean>(false);

  const hasError = !!errorMessages.length;
  const isNextButtonLoading = validateVin && isLoading;
  const trims =
    inventoryItem?.results?.trim?.filter(Boolean).map((trim) => ({ title: trim?.value, value: trim?.id })) || [];

  /**
   * Submit form
   */
  const handleSubmitForm = useCallback(
    (validate) => {
      const obj = { ...vehicle, validate, decodeVin: validate, switchPage: true };

      setValidateVin(validate);
      if (validate && !inventoryItem?.hasContinuedWithCapture) {
        submitVinOverlay(obj);
      } else {
        handleSubmitCreateInventoryItem(obj, false);
      }
    },
    [handleSubmitCreateInventoryItem, inventoryItem?.hasContinuedWithCapture, submitVinOverlay, vehicle]
  );

  return (
    <>
      <DialogContent className={classnames(style.modalContainer, style.modalCustom)}>
        {hasError && (
          <ul className={style.errorMessageContainer}>
            {errorMessages.map((errorItem) => (
              <li key={errorItem.name}>{errorItem.message}</li>
            ))}
          </ul>
        )}
        <div className={classnames(style.vinOverlayForm, hasError && style.marginCustom)}>
          <p className={style.instruction}>{t('partial_vin_decode_message_web')}</p>
          <p className={style.instruction}>{t('select_correct_trim_message')}</p>
          <div className={style.line} />
          <div className={style.inputContainer}>
            <div>
              <label className={style.label}>{t('select_trim')}</label>
              <RadioButtons
                className={style.radioButtons}
                labels={[...trims, { title: t('not_sure_trim'), value: '' }]}
                onChange={(e) => {
                  const trimIdNext = e?.target?.value;
                  setVehicle({ trimId: trimIdNext });
                  setTrimId(trimIdNext);
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <div className={style.footer}>
        <Button
          className={style.link}
          onClick={() => {
            setVehicle({ trimId: null, locationId: null, pickupLocationId: null });
            changePage(Page.VIN_OVERLAY);
          }}
          theme="none"
        >
          {t('back')}
        </Button>
        <Button
          className={style.button}
          disabled={trimId === undefined || isNextButtonLoading}
          onClick={() => handleSubmitForm(true)}
          theme="green"
        >
          {isNextButtonLoading ? <Spinner spinnerStyleClassName={style.spinnerStyle} /> : t('next')}
        </Button>
      </div>
    </>
  );
};

export default VinSelectTrimOverlay;
