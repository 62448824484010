import classnames from 'classnames';
import moment, { Moment } from 'moment';

import BaseClass from 'components/ui/shared/base';
import { runEvery, stopEvery } from 'utils/runLoopUtils';

import style from './timerBar.scss';

const UPDATE_FREQUENCY = 100;

const getStyle = (left, width) =>
  Object({
    WebkitAnimationDuration: `${left}s`,
    animationDuration: `${left}s`,
    width: `${width}%`,
  });

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;

  /** End time of the timer. */
  end?: Moment | null;

  /** Function invoked when timer ends. */
  onEnd?: () => void;

  /** Start time of the timer. */
  start?: Moment | null;

  /** The width style of the rendered timer bar. */
  width?: number;
}

class TimerBar extends BaseClass<Props> {
  private animationBar: HTMLDivElement | null;
  private animationDuration: number;
  private deferredRenderPid: ReturnType<typeof setTimeout> | null;
  private isAnimating: boolean;

  static defaultProps = {
    onEnd: () => {},
  };

  componentDidMount() {
    super.componentDidMount();

    runEvery(UPDATE_FREQUENCY, this.checkSync);
  }

  shouldComponentUpdate(nextProps) {
    const { start, end, className } = nextProps;
    const { start: startPrev, end: endPrev, className: classNamePrev } = this.props;

    let shouldUpdate = true;

    if (start && startPrev && end && endPrev) {
      shouldUpdate = !start.isSame(startPrev) || !end.isSame(endPrev);
    }
    shouldUpdate = shouldUpdate || className !== classNamePrev;

    return shouldUpdate;
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    if (this.deferredRenderPid) {
      clearTimeout(this.deferredRenderPid);
    }
    stopEvery(UPDATE_FREQUENCY, this.checkSync);
  }

  checkSync = () => {
    const { start, end } = this.props;

    if (this.animationBar) {
      const now = moment();
      const secondsLeft = Math.max(0, end?.diff(now, 'seconds') ?? 0);
      const secondsTotal = Math.max(0, end?.diff(start, 'seconds') ?? 0);

      const expectedWidth = Math.ceil(
        Number(this.animationBar.parentElement?.offsetWidth) * (secondsLeft / secondsTotal)
      );
      const maxDifference = Math.max(Number(this.animationBar.parentElement?.offsetWidth) / this.animationDuration, 10); // Max sync difference allowed in pixels, based on the pixels-per-second calculated from the current animation duration and parent timebar

      if (secondsLeft <= 0) {
        this.props?.onEnd?.();
        stopEvery(UPDATE_FREQUENCY, this.checkSync);
      }

      if (Math.abs(this.animationBar.getBoundingClientRect().width - expectedWidth) > maxDifference) {
        requestAnimationFrame(() => {
          this.forceUpdate();
        });
      }
    }
  };

  renderStatic(width) {
    const { className } = this.props;

    this.isAnimating = false;
    return (
      <div
        className={classnames('timer-bar', style.timerBar, className)}
        data-testid="timer-bar-static"
        style={{ width: `${width}%` }}
      />
    );
  }

  renderAnimated(secondsLeft, startWidth) {
    const { className } = this.props;

    /**
     * If the animation is already happening then we want to restart it. The
     * only way to do this is to remove the animation class, let the styles
     * recompute and the re-add the animation class.
     *
     * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Tips
     */
    if (this.isAnimating) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (this._isMounted) {
            this.forceUpdate();
          }
        });
      });
      return this.renderStatic(startWidth);
    }

    this.isAnimating = true;
    this.animationDuration = secondsLeft;

    return (
      <div
        ref={(div) => {
          this.animationBar = div;
        }}
        className={classnames('timer-bar', style.timerBar, style.animatedTimerBar, className)}
        data-testid="timer-bar-animated"
        style={getStyle(secondsLeft, startWidth)}
      />
    );
  }

  render() {
    const { width, start, end } = this.props;

    if (width) {
      return this.renderStatic(width);
    }

    const now = moment();
    const secondsLeft = Math.max(0, end?.diff(now, 'seconds') ?? 0);
    const secondsTotal = Math.max(0, end?.diff(start, 'seconds') ?? 0);

    /**
     * If there's more time left than the total time, then just render the
     * static bar. Set a timer for when the animation should start and do a
     * forceUpdate() the begin the animation.
     */
    if (secondsLeft > secondsTotal) {
      this.deferredRenderPid = setTimeout(
        () => {
          this.deferredRenderPid = null;
          if (this._isMounted) {
            this.forceUpdate();
          }
        },
        Math.ceil((secondsLeft - secondsTotal) * 1000)
      );

      return this.renderStatic(100);
    }
    if (secondsLeft === 0) {
      return this.renderStatic(0);
    }

    const startWidth = Math.ceil(100 * (secondsLeft / secondsTotal));

    return this.renderAnimated(secondsLeft, startWidth);
  }
}

export const momentPropType = (props, propName, componentName) => {
  if (props[propName] && !moment.isMoment(props[propName])) {
    return new Error(`${propName} in ${componentName} requires an instance of Moment.`);
  }
  return undefined;
};

export default TimerBar;
