import ApiRequest from 'store/shared/api/apiRequest';
import {
  InventoryItemValue,
  MutationinventoryItemValuesFetchBlackBookValuesArgs,
} from 'store/shared/api/graph/interfaces/types';

export const inventoryItemValuesFetchBlackBookValues = ({
  auctionId = undefined,
  inventoryItemId,
}: MutationinventoryItemValuesFetchBlackBookValuesArgs['input']) =>
  ApiRequest<{ inventoryItemValuesFetchBlackBookValues: InventoryItemValue[] }>({
    data: {
      operationName: 'inventoryItemValuesFetchBlackBookValues',
      query: `
        mutation ($input: BlackBookValueUpdateInput!) {
          inventoryItemValuesFetchBlackBookValues(input: $input) {
            id
            type {
              id
              name
            }
            value {
              currencyCode
              amount
              formattedAmount
            }
          }
        }
        
    `,
      variables: {
        input: {
          auctionId,
          inventoryItemId,
        },
      },
    },
  });
