import classnames from 'classnames';

import { DateFormat } from 'constants/enums/dateAndTime';
import { formatDate } from 'utils/dateUtils';

import style from './messageBubble.scss';

interface Props {
  /** Context info - contextual to the chat, i.e. Bid Amount */
  contextInfo: string | null;

  /** Timestamp when the message was sent. */
  created: string;

  /** Whether the message has been broadcast to all users */
  isBroadcastMessage: boolean;

  /** Whether the message was sent by current user or not. */
  isMyMessage: boolean;

  /** Message content. */
  message: string | null;

  /** Message id **/
  messageId: string;

  /** The name of the person sending the message - excluded when my message. */
  senderName: string;
}

const MessageBubble = ({
  contextInfo,
  created,
  isBroadcastMessage,
  isMyMessage,
  message,
  senderName,
  messageId,
}: Props) => {
  return (
    <div className={style.messageBubbleWrapper}>
      <div
        className={classnames(style.container, { [style.myMessage]: isMyMessage })}
        data-testid="chat-message-bubble"
      >
        <div className={classnames(style.message, { [style.broadcastMessage]: isBroadcastMessage })}>{message}</div>
      </div>
      <div
        className={classnames('chromatic-ignore', style.timestamp, {
          [style.timestampMine]: isMyMessage,
          [style.timestampOther]: !isMyMessage,
        })}
        data-testid={`chat-message-timestamp-${messageId}`}
      >
        {formatDate(created, DateFormat.TIME_FORMAT, false)}
        {senderName ? ` / ${senderName}` : ''}
        {contextInfo ? ` / ${contextInfo}` : ''}
      </div>
    </div>
  );
};

export default MessageBubble;
