import { connect, ConnectedProps } from 'react-redux';

import removeGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import {
  AuctionItem,
  AuctionItemStatus,
  MutationauctionItemCancelPendingDeliveryArgs,
  MutationauctionItemCancelSaleArgs,
  MutationauctionItemIfBidCancelArgs,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { processCancelAuctionItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Cancel auction item. */
  cancelItem: (options: CancelOptions) => processCancelAuctionItem(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

export type CancelOptions = (
  | MutationauctionItemCancelPendingDeliveryArgs
  | MutationauctionItemCancelSaleArgs
  | MutationauctionItemIfBidCancelArgs
) & { status: AuctionItemStatus };

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

interface State {
  /** Validation Errors. */
  errorMessages: ErrorMessages | undefined;

  /** True when the dialog is open. */
  isOpen: boolean;

  /** True when form is being submitted. */
  isSubmitting: boolean;
}

class Cancel extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit, options) => {
    if (shouldSubmit) {
      this.setState({ isSubmitting: true });
      this.props
        .cancelItem?.(options)
        .then(() => this.setState({ errorMessages: null, isOpen: false }))
        .catch(this.onApiError)
        .finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
    }
  };

  render() {
    const { auctionItem } = this.props;
    const { errorMessages, isOpen, isSubmitting } = this.state;

    return (
      <>
        <OperationButton
          dataTestId="cancelButton"
          glyph={removeGlyph}
          onClick={() => this.setState({ isOpen: true })}
          theme="red"
        >
          {t('cancel')}
        </OperationButton>
        <Dialog
          auctionItem={auctionItem}
          errorMessages={errorMessages}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onSubmit={this.onSubmit}
        />
      </>
    );
  }
}

export default connector(Cancel);
