import { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import transferGlyph from 'glyphs/operationIcons/transfer.svg';

import AuctionItem from 'constants/auctionItem';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemChangeBuyerArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { onApiError } from 'utils/apiUtils';
import { processChangeBuyer } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to change buyer. */
  changeBuyer: (options: Partial<MutationauctionItemChangeBuyerArgs>) => processChangeBuyer(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item to change buyer. */
  auctionItem: AuctionItem;
}

const ChangeBuyer = ({ auctionItem, changeBuyer }: Props) => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = useCallback(
    (shouldSubmit: boolean = false, options: Partial<MutationauctionItemChangeBuyerArgs> = {}) => {
      if (shouldSubmit) {
        setIsSubmitting(true);
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_CHANGE_BUYER_CONFIRM_CLICK, auctionItem);

        changeBuyer({ ...options })
          .then(() => setIsOpen(false))
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages([]);
        setIsOpen(false);
      }
    },
    [auctionItem, changeBuyer]
  );

  return (
    <>
      <OperationButton
        dataTestId="changeBuyer-button"
        glyph={transferGlyph}
        onClick={() => {
          setIsOpen(true);
          trackUserActionWithAuctionItemAttributes(UserAction.VDP_CHANGE_BUYER_CLICK, auctionItem);
        }}
        theme="green"
      >
        {t('change_buyer')}
      </OperationButton>
      {isOpen && (
        <Dialog
          auctionItem={auctionItem}
          errorMessages={errorMessages}
          isOpen
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default connector(ChangeBuyer);
