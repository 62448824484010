import { Dispatch, SetStateAction, useCallback } from 'react';

import { AccountVerificationFormStep } from 'forms/account/accountVerification/steps/accountVerificationHelpers';

interface Props {
  /** The current form step */
  currentStep: number;
  /** Callback function to set `currentStep` */
  setCurrentStep: Dispatch<SetStateAction<number>>;
  /** A form's step config */
  steps: AccountVerificationFormStep[];
}

export const useNavigation = ({ currentStep, setCurrentStep, steps }: Props) =>
  useCallback(
    (isForward: boolean) => {
      // Find index of next navigation point
      const nextStepIndex = (
        isForward ? steps.slice(currentStep + 1) : steps.slice(0, currentStep).reverse()
      ).findIndex((step) => step.isNavigationPoint);

      // Define next step based on the above index and which direction we're going, and set it
      const nextStep = currentStep + (isForward ? 1 : -1) * (nextStepIndex + 1);
      setCurrentStep(nextStep);
    },
    [currentStep, setCurrentStep, steps]
  );
