import { useCallback, useState, useMemo, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import SelectUser, { SelectUserProps } from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { MonetaryAmount, Company } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { UserProps } from 'store/user/userModels';
import { isAuctionStaff } from 'utils/userUtils';
import { onApiError } from 'utils/apiUtils';
import { processAcceptOffer } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Company that is accepting an offer */
  acceptOfferCompany: Pick<Company, 'id' | 'name'>;
  /** True if it's a seller */
  isSeller: boolean;
  /** Company that is offering */
  offeringCompany: Pick<Company, 'id' | 'name'>;
  /** Offered amount */
  offeredAmount: Pick<MonetaryAmount, 'formattedAmountRounded'>;
  /** Offer Id */
  offerId: string;
  /** Function used to close dialog */
  onClose: () => void;
}

const Dialog = ({ acceptOfferCompany, isSeller, offeringCompany, offeredAmount, offerId, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const auctionId = useSelector<AppState, string>((state) => state.app.auctionItemDetails.details.auction.id);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionId), [auctionId, user]);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [actionableUser, setActionableUser] = useState<SelectOption | undefined>();
  const [comment, setComment] = useState<string>('');

  const isActionable = useMemo(() => {
    if (isStaffUser && !actionableUser) {
      return false;
    }

    return true;
  }, [isStaffUser, actionableUser]);

  const instructions = useMemo(() => {
    if (isStaffUser) {
      const sellerBuyerLabel = isSeller ? t('seller') : t('buyer');

      return (
        <InterpolatedTranslation
          args={[
            actionableUser ? actionableUser.label : sellerBuyerLabel,
            offeredAmount.formattedAmountRounded,
            offeringCompany.name,
          ]}
          namespace="accept_offer_message_admin"
        />
      );
    }

    return (
      <InterpolatedTranslation
        args={[offeredAmount.formattedAmountRounded, offeringCompany.name]}
        namespace="accept_offer_message_all"
      />
    );
  }, [isStaffUser, offeredAmount.formattedAmountRounded, offeringCompany.name, actionableUser, isSeller]);

  /**
   * Handles accept offer button
   */
  const handleOnConfirm = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        setIsSubmitting(true);

        processAcceptOffer(
          {
            companyId: acceptOfferCompany.id,
            comment,
            offerId,
            userId: actionableUser?.value,
          },
          dispatch
        )
          .then(() => {
            setErrorMessages([]);
            onClose();
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages([]);
        onClose();
      }
    },
    [acceptOfferCompany.id, comment, offerId, actionableUser?.value, dispatch, onClose]
  );

  /**
   * Handles user change events
   */
  const handleUserOnChange = useCallback<NonNullable<SelectUserProps['onChange']>>((option) => {
    if (option) {
      setActionableUser(option);
    }
  }, []);

  /**
   * Handles text are message change
   */
  const handleMessageChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((e) => {
    setComment(e.currentTarget.value);
  }, []);

  return (
    <ConfirmDialog
      actionable={isActionable}
      actionLabel={t('accept').toUpperCase()}
      actionProgress={isSubmitting}
      isOpen
      isPreventingDefaultOnClick
      onClose={onClose}
      onConfirm={handleOnConfirm}
      theme="green"
      title={t('accept_offer')}
    >
      <FormErrors errorMessages={errorMessages} isSmallDialog />
      <div className={style.amount}>{offeredAmount.formattedAmountRounded}</div>
      <p className={style.instructions}>{instructions}</p>
      {isStaffUser && <SelectUser companyId={acceptOfferCompany.id} onChange={handleUserOnChange} />}
      <TextArea className={style.message} onChange={handleMessageChange} placeholder={t('attach_optional_message')} />
    </ConfirmDialog>
  );
};

export default Dialog;
