import raf from 'utils/raf';

/**
 * Removes loading animation from the dom.
 * This element is added to the index-html file on build.
 *
 * @see template-index.ejs
 */
export const removeLoaderFromIndexHTML = () => {
  const shellLoader = document.querySelector('.spinner');

  if (shellLoader) {
    shellLoader?.parentNode?.removeChild?.(shellLoader);
  }
};

/**
 * Applies a simple, single animation to an HTML element.
 * Goes right down to the DOM since bids can increment in rapid succession.
 */
export const simpleAnimation = (element: HTMLElement | null, animationClassName) => {
  const elementNext = element;

  raf(() => {
    if (elementNext) {
      let classNames = elementNext?.className?.split(/\s+/)?.filter((className) => className !== animationClassName);
      elementNext.className = classNames.join(' ');

      raf(() => {
        if (elementNext) {
          classNames = elementNext?.className?.split(/\s+/);
          classNames.push(animationClassName);
          elementNext.className = classNames.join(' ');
        }
      });
    }
  }, 1000);
};
