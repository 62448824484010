import { useMemo } from 'react';

import User from 'constants/user';
import { Auction } from 'store/shared/api/graph/interfaces/types';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { hasAuctionPermission, hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

interface AuditLogRowProps {
  /** The id of the auction. */
  auctionId?: string;
  /** The available auction list. */
  availableAuctions?: Auction[];
  /** Function invoked on button click. */
  onClick: (event) => void;
  /** The current user. */
  user: User;
}

const AuditLogRow = ({ auctionId, availableAuctions = [], onClick, user }: AuditLogRowProps) => {
  // Checks against auction permission if an auction id is available.
  // If auction id is not available, check against available auctions
  const hasAuctionViewPermission = useMemo(
    () =>
      auctionId
        ? hasAuctionPermission(user, auctionId, ['AUCTION_VIEW_AUDIT_LOG_INVENTORY'])
        : availableAuctions.some((auction) =>
            hasAuctionPermission(user, auction.id, ['AUCTION_VIEW_AUDIT_LOG_INVENTORY'])
          ),
    [auctionId, availableAuctions, user]
  );

  // Check against system permission
  const hasSystemViewPermission = hasSystemPermission(user, ['SYSTEM_VIEW_AUDIT_LOG_INVENTORY']);

  if (hasAuctionViewPermission || hasSystemViewPermission) {
    return <Row dataTestId="status-row-audit-log" name={t('audit_log')} onClick={onClick} />;
  }
  return null;
};

export default AuditLogRow;
