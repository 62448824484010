import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import SelectUser from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemIfBidCounterArgs } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getIfBidCompany } from 'utils/formatting/auctionItemFormatUtils';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Auction item information. */
  auctionItem: AuctionItem;

  /** Error messages. */
  errorMessages: ErrorMessages | null;

  /** Whether the dialog is open or not. */
  isOpen: boolean;

  /** Whether user is staff user or not. */
  isStaffUser: boolean;

  /** Whether is submitting or not. */
  isSubmitting: boolean;

  /** Callback function to handle on submit event. */
  onSubmit: (shouldSubmit: boolean, options: Partial<MutationauctionItemIfBidCounterArgs>) => void;
}

interface State {
  /** Counter offer amount. */
  amount: number;

  /** Counter offer message. */
  message: string | null;

  /** User id. */
  userId?: string;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { amount: Number.NaN, message: null, userId: undefined };
  }

  onOfferChange = (value) => {
    this.setState({ amount: parseInt(value, 10) });
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  counterOffer = (shouldSubmit: boolean) => {
    const { amount, message, userId } = this.state;
    const { auctionItem, onSubmit } = this.props;
    const company = getIfBidCompany(auctionItem);
    const options = {
      amount,
      comment: message,
      consignerId: company?.id,
      userId,
    };
    onSubmit(shouldSubmit, options);
  };

  render() {
    const { amount, userId } = this.state;
    const { auctionItem, errorMessages, isOpen, isStaffUser, isSubmitting } = this.props;

    const autoBidAmount = auctionItem?.autoBids?.myAutoBid?.maxBid?.formattedAmountRounded;
    const currentOfferAmount = auctionItem?.ifBidTimeline?.list?.[0]?.amount?.formattedAmountRounded;
    const hasError = (name) => errorMessages && errorMessages.some((err) => err.name === name);
    const ifBidTimeline = auctionItem?.ifBidTimeline?.list;
    const buyer = ifBidTimeline?.[ifBidTimeline?.length - 1]?.company;
    const latestOffer = ifBidTimeline?.[0];
    const seller = auctionItem?.inventoryItem?.company;
    const companyId = latestOffer?.company?.id === seller?.id ? buyer?.id : seller?.id;
    let actionable = !Number.isNaN(amount);

    if (isStaffUser && !userId) {
      actionable = false;
    }

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('counter')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.counterOffer}
        theme="green"
        title={t('counter_offer')}
      >
        <h3 className={style.currentOfferCounter}>{currentOfferAmount}</h3>
        <p className={style.instructions}>{t('counter_if_bid_message')}</p>
        {autoBidAmount && (
          <p className={style.yourAutobid} data-testid="your-autobid">
            {t('your_autobid_amount_was')?.toUpperCase()}
            <span className={style.autoBidAmount}>{autoBidAmount}</span>
          </p>
        )}
        <InputText
          className={style.amount}
          onChange={this.onOfferChange}
          placeholder={t('your_offer')}
          theme={hasError('amount') ? 'error' : undefined}
          type="currency"
        />
        {isStaffUser && (
          <div className={style.selectContainer}>
            <SelectUser
              className={style.select}
              companyId={companyId}
              hasError={!!hasError('userId')}
              id="selectUser"
              onChange={(user: SelectOption) => {
                this.setState({ userId: user?.value });
              }}
            />
          </div>
        )}
        <TextArea
          className={style.message}
          onChange={this.onMessageChange}
          placeholder={t('attach_optional_message')}
          theme={hasError('comment') ? 'error' : undefined}
        />
        {errorMessages &&
          errorMessages.map(({ message }, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
