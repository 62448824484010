import { useCallback } from 'react';
import classnames from 'classnames';

import Checkbox from 'forms/shared/checkbox';
import { FacetLabelGroup } from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';

import style from './checkboxFacetGroup.scss';

interface Props extends RouterProps {
  /** The facet group details. */
  facetGroup: FacetLabelGroup | undefined;

  /** The label of the facet checkbox grouping. */
  label: string;
}

const CheckboxFacetGroup = ({ facetGroup, label, location, router: { push } }: Props) => {
  const handleFacetCheckboxClicked = useCallback(
    (facetName) => {
      const params = getUrlParams();
      const isSelectedFacet = !!facetGroup?.facets?.find(({ name }) => name === facetName)?.selected;

      if (facetGroup) {
        if (isSelectedFacet) {
          delete params[facetGroup.name];
        } else {
          params[facetGroup.name] = facetName.toLowerCase() === 'true';
        }
      }

      delete params.before;
      delete params.after;

      push(`${location.pathname}?${paramsToQueryString(params)}`);
    },
    [facetGroup, location.pathname, push]
  );

  return (
    <div className={style.container}>
      <div className={style.facetGroupContainer}>
        <div className={classnames(style.facetGroupName, !facetGroup?.facets?.length && style.disabled)}>{label}</div>
        <div className={style.facets}>
          {facetGroup?.facets?.map((facet, index) => (
            <Checkbox
              key={facet.name || index}
              className={style.checkbox}
              disabled={!facet.name}
              id={`${facetGroup.name}-${facet.name || index}`}
              onChange={() => handleFacetCheckboxClicked(facet.name)}
              setChecked={facet.selected}
              theme="check"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CheckboxFacetGroup);
