import moment, { Moment } from 'moment/moment';
import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import DateInputGroup from 'forms/shared/dateInputGroup';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import InventoryItem from 'constants/inventoryItem';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormDialogBody, FormDialogFooter, FormErrors, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { Spinner } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { auctionItemUpdateInventoryItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { inventoryItemUpdate } from 'store/shared/api/graph/mutations/inventoryItemUpdate';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';
import { updateInventoryItem as updateInventoryItemAction } from 'store/inventoryItemDetails/inventoryItemDetailsActions';

const stateConnect = (state: AppState) => ({
  /** AuctionItem details */
  auctionItemDetails: state.app.auctionItemDetails?.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to update the auction item's inventory item */
  updateAuctionItemInventoryItem: (inventoryItem: InventoryItem) =>
    dispatch(auctionItemUpdateInventoryItem(inventoryItem)),
  /** Callback function to update inventory item */
  updateInventoryItem: (inventoryItem: Partial<InventoryItem>) => dispatch(updateInventoryItemAction(inventoryItem)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The inventory item */
  inventoryItem: InventoryItem | null;
  /** Function invoked when dialog is closed */
  onClose: () => void;
}

const SellerRequestedValuesDialog = ({
  auctionItemDetails,
  inventoryItem,
  onClose,
  updateAuctionItemInventoryItem,
  updateInventoryItem,
}: Props) => {
  const isAuctionItem = !!auctionItemDetails?.details;
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [legalSaleDate, setLegalSaleDate] = useState<Moment | null>(
    inventoryItem?.legalSaleDate ? moment(inventoryItem.legalSaleDate) : null
  );

  /**
   * Track user action
   */
  const trackUserAction = useCallback(
    (userAction: UserAction) => {
      if (isAuctionItem) {
        trackUserActionWithAuctionItemAttributes(userAction, auctionItemDetails?.details);
      } else {
        trackUserActionWithInventoryItemAttributes(userAction, inventoryItem);
      }
    },
    [auctionItemDetails?.details, isAuctionItem, inventoryItem]
  );

  /**
   * Handles date change on date picker
   */
  const onDateChange = useCallback((value) => {
    setLegalSaleDate(value);
  }, []);

  /**
   * Handles onSubmit form click.
   */
  const onSubmit = useCallback(() => {
    const options = {
      inventoryItemId: inventoryItem?.id,
      vehicle: {
        legalSaleDate,
        persist: true,
      },
    };
    trackUserAction(UserAction.VDP_LEGAL_SALE_DATE_EDIT_CONFIRM_CLICK);

    setIsSubmitting(true);
    inventoryItemUpdate(options)
      .then((response) => {
        const saleDate = response?.data?.data?.inventoryItemUpdate?.vehicle?.legalSaleDate;
        if (isAuctionItem && inventoryItem) {
          updateAuctionItemInventoryItem({ ...inventoryItem, legalSaleDate: saleDate });
        } else {
          updateInventoryItem({ legalSaleDate: saleDate });
        }
        onClose();
      })
      .catch((error) => onApiError(error, setErrorMessages))
      .finally(() => setIsSubmitting(false));
  }, [
    inventoryItem,
    isAuctionItem,
    legalSaleDate,
    onClose,
    trackUserAction,
    updateAuctionItemInventoryItem,
    updateInventoryItem,
  ]);

  return (
    <FormDialog isOpen onClose={onClose} title={t('seller_requested_values')}>
      <FormDialogBody>
        <FormErrors errorMessages={errorMessages} />
        <FormSection>
          <DateInputGroup
            label={t('legal_sale_date')}
            name="legalSaleDate"
            onChange={onDateChange}
            showClearDate={false}
          />
        </FormSection>
      </FormDialogBody>
      <FormDialogFooter>
        <Button disabled={isSubmitting} onClick={onSubmit} theme="blue">
          {isSubmitting ? <Spinner /> : t('save')}
        </Button>
      </FormDialogFooter>
    </FormDialog>
  );
};

export default connector(SellerRequestedValuesDialog);
