import classnames from 'classnames';
import { MouseEventHandler } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import ScoreText from 'components/ui/shared/scores/scoreText';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { rangeCA, rangeUS } from 'components/ui/shared/scores/scoreRanges';
import { t } from 'utils/intlUtils';

import style from './conditionReport.scss';

interface Props {
  /** Inventory item details */
  inventoryItem: InventoryItem;
  /** True when editable */
  isUpdatable?: boolean;
  /** Function invoked on button click */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const ScoreExtensiveButton = ({ inventoryItem, isUpdatable = false, onClick }: Props) => {
  const score = inventoryItem?.extensiveVehicleConditionScore;
  const range = inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;

  return (
    <Button
      className={classnames(
        style.crButton,
        isUpdatable && style.crButtonUpdatable,
        inventoryItem?.incompleteFieldMessages?.find((ifm) => ifm?.field === 'conditionReport') && style.isRequired,
        score !== null && Number(score) <= 2 && style.crButtonRed
      )}
      dataTestId="score-extensive-button"
      onClick={onClick}
      theme="none"
    >
      <div>
        <span
          className={classnames(
            style.crButtonText,
            score !== null && Number(score) <= 2 && style.red,
            Number(score) >= 5 && style.green,
            Number(score) >= 2 && Number(score) < 5 && style.yellow
          )}
        >
          <ScoreText range={range} score={score} />
        </span>
        <span className={style.crButtonSubText}>{t('overall_score')}</span>
        {isUpdatable && <Sprite className={style.crEditGlyph} glyph={editGlyph} />}
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default ScoreExtensiveButton;
