import classnames from 'classnames';

import Button, { ButtonProps } from 'components/ui/shared/button';
import { Chevron } from 'components/ui/shared/chevrons';

import style from './rowButtonWithArrow.scss';

interface Props extends Omit<ButtonProps, 'theme'> {
  /** CSS styling to overwrite default style. */
  className?: string;

  /** The label of the button. */
  label: string;
}

const RowButtonWithArrow = ({ className, label, ...props }: Props) => (
  <Button className={classnames(style.auctionItem, className)} theme="none" {...props}>
    <div>{label}</div>
    <Chevron />
  </Button>
);

export default RowButtonWithArrow;
