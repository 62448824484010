import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';

import MyBlock, { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { AppDispatch, AppState } from 'store/configureStore';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  MyBlockStatisticsInput,
  QueryauctionItemConnectionArgs,
  QuerymyBlockSellerStatisticsArgs,
  TransactionType,
} from 'store/shared/api/graph/interfaces/types';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { haveParamsChanged } from 'utils/listUtils';
import { myBlockListCleared, processGetMyBlockSellerList } from 'store/myBlock/list/myBlockActions';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';

// A list of enabled statuses that should be visible on the My Block seller page
export const MY_BLOCK_SELLER_ENABLED_STATUSES = [
  AuctionItemStatus.AWAITING_CHECKOUT,
  AuctionItemStatus.IN_IF_BID,
  AuctionItemStatus.NO_SALE,
  AuctionItemStatus.SOLD,
  AuctionItemStatus.UPCOMING,
];

const stateConnect = (state: AppState) => ({
  /** My block list information. */
  myBlockList: state.app.myBlock,
  /** Current logged in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to clear my block list. */
  clearMyBlockList: () => dispatch(myBlockListCleared()),
  /** Callback function to get my block seller list. */
  getMyBlockSellerList: (listOptions: QueryauctionItemConnectionArgs, statsOptions: QuerymyBlockSellerStatisticsArgs) =>
    processGetMyBlockSellerList(listOptions, statsOptions, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

export interface LocationQuery {
  /** The ID param used for the location query */
  id: string;
  /** The showDetails param used for the location query */
  showDetails: `${boolean}`;
}

type Props = RouterProps<LocationQuery> & ConnectedProps<typeof connector>;

const MyBlockSellerContainer = ({
  clearMyBlockList,
  getMyBlockSellerList,
  location,
  myBlockList,
  router,
  user,
}: Props) => {
  const isFirstLoad = useRef<boolean>(true);
  const locationPrev = usePrevious(location);

  /**
   * onRefresh
   */
  const onRefresh = useCallback(() => {
    const urlParams = getUrlParams();
    const options: QueryauctionItemConnectionArgs = {
      sort: [{ id: 'AUCTION_START_TIME', ascending: false }],
      status: MY_BLOCK_SELLER_ENABLED_STATUSES,
      transactionType: TransactionType.SELLING,
      ...urlParams,
    };
    const statOptions: MyBlockStatisticsInput = {
      auctionLocationName: urlParams.auctionLocationName,
      auctionTitle: urlParams.auctionTitle,
      consigner: urlParams.consigner,
      dateRanGTE: urlParams.dateRanGTE,
      dateRanLTE: urlParams.dateRanLTE,
      formats: urlParams.formats,
      region: urlParams.region,
      totalIncludeIfBid: !!urlParams.totalIncludeIfBid,
    };

    if (isFirstLoad.current && !urlParams.dateRanGTE) {
      isFirstLoad.current = false;

      // On first load, if the dateRan filter isn't set, supply the default values
      const dateRanGTE = moment()?.startOf('day')?.subtract(7, 'days')?.toISOString();
      const paramsNext = paramsToQueryString({ ...urlParams, dateRanGTE, formats: AuctionItemFormat.AUCTION });
      router?.push(`${location?.pathname}?${paramsNext}`);
    } else {
      getMyBlockSellerList(options, { input: statOptions });
    }
  }, [getMyBlockSellerList, location, router]);

  /**
   * onHardRefresh
   */
  const onHardRefresh = useCallback(() => {
    clearMyBlockList();
    onRefresh();
  }, [clearMyBlockList, onRefresh]);

  /**
   * onMount
   */
  useMountEffect(() => {
    // Load list
    onRefresh();

    return () => {
      // Unmount; clear store data
      clearMyBlockList();
    };
  });

  /**
   * Refreshes the list when the location has changed.
   */
  useEffect(() => {
    if (locationPrev && haveParamsChanged(locationPrev, location, ['id', 'showDetails'])) {
      onRefresh();
    }
  }, [location, locationPrev, onRefresh]);

  return (
    <MyBlock
      list={myBlockList}
      location={location}
      onRefresh={onHardRefresh}
      router={router}
      user={user}
      variant={MyBlockVariant.SELLER}
    />
  );
};

export default withRouter(connector(MyBlockSellerContainer));
