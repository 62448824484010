import { MouseEventHandler, useMemo } from 'react';

import Button from 'components/ui/shared/button';
import {
  BlueDriverScanData,
  BlueDriverScanStatus,
  BlueDriverSmogScanStatus,
} from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './blueDriverButtons.scss';

interface Props {
  /** The inventory item details. */
  blueDriverScanData: BlueDriverScanData;
  /** Function invoked when button clicked. */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const SmogButton = ({ blueDriverScanData, onClick }: Props) => {
  const smogResult = blueDriverScanData?.smogScan?.status ?? BlueDriverSmogScanStatus.Fail;
  const smogScanStatus = blueDriverScanData?.smogScanStatus;

  const smogResultColour = useMemo(() => {
    switch (smogResult) {
      case BlueDriverSmogScanStatus.Fail:
        return style.red;
      case BlueDriverSmogScanStatus.Pass:
        return style.green;
      case BlueDriverSmogScanStatus.Warning:
        return style.yellow;
      default:
        return undefined;
    }
  }, [smogResult]);

  const buttonLabel = useMemo(() => {
    if (smogScanStatus === BlueDriverScanStatus.PENDING) {
      return <div>{t('pending')}</div>;
    }
    return <div className={smogResultColour}>{smogResult}</div>;
  }, [smogScanStatus, smogResult, smogResultColour]);

  return (
    <Button className={style.button} onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('smog_readiness')}</div>
      <div className={style.crRowContent}>
        {buttonLabel}
        <Chevron />
      </div>
    </Button>
  );
};

export const OBDIIButton = ({ blueDriverScanData, onClick }: Props) => {
  const codeCount = blueDriverScanData?.codeCount ?? 0;
  const codeScanStatus = blueDriverScanData?.codeScanStatus;

  const buttonLabel = useMemo(() => {
    if (codeScanStatus === BlueDriverScanStatus.PENDING) {
      return t('pending');
    }
    return codeCount === 0 ? t('no_codes_found') : t('codes_found');
  }, [codeCount, codeScanStatus]);

  return (
    <Button className={style.button} onClick={onClick} theme="none">
      <div className={style.crRowTitle}>{t('obdii')}</div>
      <div className={style.crRowContent}>
        {buttonLabel}
        <Chevron />
      </div>
    </Button>
  );
};
