import classnames from 'classnames';
import { Link } from 'react-router';
import { useMemo } from 'react';

import Button from 'components/ui/shared/button';
import { FacetLabelGroup } from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { Location } from 'constants/reactRouter';
import { facetLink } from 'components/ui/lists/filters/facetGroups/facetGroups';
import { t } from 'utils/intlUtils';

import style from './multiFacetGroupEdit.scss';

interface Props {
  /** The facet group. */
  facetGroup: FacetLabelGroup;
  /** The label of the facet group. */
  label: string | undefined;
  /** The current url information. */
  location: Location;
  /** Invoked on done button clicked. */
  onClose: (name: string) => void;
  /**
   * If true, render the first "Any x" filter.
   * @default true
   */
  showAny?: boolean;
}

const MultiFacetGroupEdit = ({ facetGroup, label = facetGroup.name, location, onClose, showAny = true }: Props) => {
  /**
   * The total of all facet counts in the group, ignoring '-1'.
   */
  const totalCount = useMemo(() => {
    return facetGroup.facets?.reduce((sum, facet) => (facet.count !== -1 ? sum + facet.count : sum), 0);
  }, [facetGroup.facets]);

  return (
    <div className={style.editFacetGroup} data-testid="editFacetGroup">
      <div className={style.title}>{t('choose_x', [label])}</div>
      <ul className={style.facets}>
        {showAny && (
          <Link
            className={classnames(style.facet, {
              [style.selected]: facetGroup.facets?.reduce((selected, facet) => selected && !facet.selected, true),
            })}
            data-testid="any-facet"
            to={facetLink(facetGroup.name, location)}
          >
            <span className={style.name}>{t('any_x', [label])}</span>
            {!!totalCount && (
              <span className={style.count} data-testid="count">
                {totalCount}
              </span>
            )}
          </Link>
        )}
        {facetGroup.facets?.map(({ name, facetLabel, count, selected }) => (
          <Link
            key={name}
            className={classnames(style.facet, { [style.selected]: selected })}
            data-testid={`facet-${name}`}
            to={facetLink(facetGroup.name, location, name, facetGroup.allowMultiple)}
          >
            <span className={style.name}>{facetLabel}</span>
            {count !== -1 && (
              <span className={classnames(style.count, style[`count${count}`])} data-testid="count">
                {count}
              </span>
            )}
          </Link>
        ))}
      </ul>
      <Button className={style.done} onClick={onClose} theme="green">
        {t('done')}
      </Button>
    </div>
  );
};

export default MultiFacetGroupEdit;
