import AuctionItem from 'constants/auctionItem';
import SubmitToAuctionOperation from 'components/sections/auctionItem/operations/submitToAuction/submitToAuction';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { t } from 'utils/intlUtils';

import style from './submitToAuction.scss';

interface Props extends RouterProps {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

const SubmitToAuction = ({ auctionItem, location }: Props) => (
  <SubmitToAuctionOperation
    auctionItem={auctionItem}
    buttonText={t('run_again')}
    className={style.runAgainButton}
    hasIcon={false}
    inventoryItemDetails={auctionItem.inventoryItem}
    location={location}
  />
);

export default withRouter(SubmitToAuction);
