import { NoteEntityType } from 'store/shared/api/graph/interfaces/types';

export type NoteEntity =
  | 'auctionItem'
  | 'carrier'
  | 'company'
  | 'inventoryItem'
  | 'transportJob'
  | 'transportRequest'
  | 'user';

// TODO: Add keys for other calls when implementing
export const noteEntities: Partial<Record<NoteEntityType, NoteEntity>> = {
  AUCTION_ITEM: 'auctionItem',
  // AUCTION_TIME_SLOT: '',
  CARRIER: 'carrier',
  CONSIGNER: 'company',
  // TODO: uncomment when company entityType is available on the backend
  // COMPANY: 'company',
  INVENTORY_ITEM: 'inventoryItem',
  // REQUESTED_CAPTURE: '',
  // REQUESTED_INVITE: '',
  TRANSPORT_JOB: 'transportJob',
  TRANSPORT_REQUEST: 'transportRequest',
  USER: 'user',
};
