import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import ValuesInfo from 'components/sections/inventoryItem/details/feesAndValues/valuesInfo';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import { AutoBidOptions } from 'components/sections/auctionItem/operations/setAutobid/setAutobid';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { CompanyAuctionRelationshipStatus, InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getBiddingSelectionByAuctionId } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** Validation errors. */
  errorMessages?: ErrorMessages;
  /** The inventory item values. */
  inventoryItemValues?: InventoryItemValue[];
  /** True when current user has group manager role. */
  isGroupManager: boolean;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True to prevent default on click. */
  isPreventingDefaultOnClick?: boolean;
  /** True if the logged-in user represents auction staff. */
  isStaffUser: boolean;
  /** True when for is submitting. */
  isSubmitting: boolean;
  /** Function used to set autobid. */
  onSubmit: (shouldSubmit: boolean, options: AutoBidOptions) => void;
}

interface State {
  /** The currently selected company. */
  company: BidSelection['company'] | undefined;
  /** The black book values of the inventory item. */
  blackBookValues?: InventoryItemValue[];
  /** The set autobid amount. */
  maxBidPrice: number;
  /** The MMR values of the inventory item. */
  mmrValues?: InventoryItemValue[];
  /** The currently selected user. */
  user: BidSelection['user'] | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    const { auctionItem } = props;
    const biddingAs = getBiddingSelectionByAuctionId(auctionItem.auction?.id);

    this.state = {
      company: biddingAs?.company,
      maxBidPrice: Number.NaN,
      user: biddingAs?.user,
    };
  }

  onAutobidChange = (value) => {
    this.setState({ maxBidPrice: parseFloat(value) });
  };

  onCompanyChange = (company: SelectCompanyOption) => {
    if (company?.value !== this.state.company?.id) {
      // Format selected option back to company object (id/name)
      const formattedCompany = { id: company?.value, name: company?.label };

      this.setState({ company: formattedCompany, user: null });
    }
  };

  onUserChange = (user: SelectOption) => {
    // Format selected option back to user object (id/name)
    const formattedUser = user && { id: user.value, name: user.label };

    this.setState({ user: formattedUser });
  };

  setAutobid = (shouldSubmit) => {
    const { company, user, maxBidPrice } = this.state;
    const { onSubmit } = this.props;

    const options = { company, user, maxBidPrice };

    onSubmit(shouldSubmit, options);
  };

  createChildren = () => {
    const { auctionItem, errorMessages, inventoryItemValues, isGroupManager, isStaffUser } = this.props;
    const { company, user } = this.state;
    const canActOnOthersBehalf = isStaffUser || isGroupManager;
    const inventoryItem = auctionItem?.inventoryItem;
    const hasError = Number(errorMessages?.length) > 0;

    return (
      <>
        <ValuesInfo
          className={style.valuesInfo}
          countryCode={inventoryItem?.location?.countryCode}
          headerClassName={style.valuesInfoHeader}
          inventoryItemValues={inventoryItemValues}
          make={inventoryItem?.make}
          model={inventoryItem?.model}
          subModel={inventoryItem?.subModel}
          year={inventoryItem?.year}
        />
        {hasError && <FormErrors errorMessages={errorMessages} isSmallDialog />}
        <div className={style.title}>
          {`${inventoryItem?.year} ${inventoryItem?.make}`}
          <span className={style.subTitle}>{` • ${inventoryItem?.model} ${inventoryItem?.trim}`}</span>
        </div>
        <div className={style.mileage}> {inventoryItem?.mileage?.formattedAmount}</div>
        <p className={style.instructions}>
          {t(canActOnOthersBehalf ? 'set_autobid_dialog_admin_message' : 'set_autobid_dialog_message')}
        </p>
        {canActOnOthersBehalf && (
          <SelectCompany
            className={style.consigners}
            connectionVariables={{
              auctionId: auctionItem?.auction?.id,
              auctionRelationshipPermission: [AuctionRelationshipPermission.CAN_BUY],
              auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
            }}
            id="setAutobidSelectCompany"
            onChange={this.onCompanyChange}
            placeholder={t('select_company')}
            value={
              company && {
                label: company.name,
                value: company.id,
              }
            }
          />
        )}
        {isStaffUser && (
          <SelectUser
            className={style.users}
            companyId={company?.id}
            id="setAutobidSelectUser"
            isDisabled={!company?.id}
            onChange={this.onUserChange}
            placeholder={t('select_user')}
            value={user && { label: user.name, value: user.id }}
          />
        )}
        <InputText
          className={style.amount}
          min={1}
          onChange={this.onAutobidChange}
          placeholder={t('your_autobid')}
          theme={hasError ? 'error' : undefined}
          type="currency"
        />
      </>
    );
  };

  render() {
    const { maxBidPrice, user } = this.state;
    const { isStaffUser, isOpen, isPreventingDefaultOnClick, isSubmitting } = this.props;

    const actionable = !Number.isNaN(maxBidPrice) && (!isStaffUser || !!user?.id);

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('set')}
        actionProgress={isSubmitting}
        className={style.dialog}
        isOpen={isOpen}
        isPreventingDefaultOnClick={isPreventingDefaultOnClick}
        onConfirm={this.setAutobid}
        theme="blue"
        title={t('set_autobid')}
      >
        {isOpen && this.createChildren()}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
