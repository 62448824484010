import { createAction } from 'redux-actions';

import { getLocations, GetLocationsOptions, locationCreate, saveTransportRequest } from './addJobApi';
import { transportJobsListUpdateItem, processUpdateFacets } from '../list/transportJobsActions';
import { getUrlParams } from 'utils/urlUtils';

export const isLoading = createAction('TRANSPORT_JOB_LOCATIONS_SET_IS_LOADING');
export const isUpdating = createAction('TRANSPORT_JOB_LOCATIONS_SET_IS_UPDATING');
export const setSavingState = createAction('TRANSPORT_JOB_DETAILS_SET_SAVING_STATE');
export const isNotUpdating = createAction('TRANSPORT_JOB_LOCATIONS_SET_IS_NOT_UPDATING');
export const locationsLoaded = createAction('TRANSPORT_JOB_LOCATIONS_LOADED');
export const updateLocations = createAction('TRANSPORT_JOB_LOCATIONS_UPDATE_LOCATIONS');

export const processGetLocations = async (options: GetLocationsOptions, dispatch) => {
  dispatch(isLoading());
  const locations = (await getLocations(options))?.data.data;
  return dispatch(locationsLoaded({ ...options, locations })).payload;
};

export const processCreateLocation = (options, dispatch) => {
  dispatch(isUpdating());
  return locationCreate(options)
    .then((response) => {
      dispatch(
        updateLocations({
          location: response?.data?.data?.locationCreate,
          locationsListName: options.locationsListName,
          consignerId: options.id,
        })
      );
      return response?.data?.data?.locationCreate?.id;
    })
    .finally(() => dispatch(isNotUpdating()));
};

export const processSaveTransportRequest = (options, dispatch) => {
  dispatch(isUpdating());
  return saveTransportRequest(options)
    .then((response) => {
      const formattedResponse = response?.data?.data?.transportRequestCreate;
      dispatch(transportJobsListUpdateItem(formattedResponse));
      processUpdateFacets(getUrlParams(), dispatch);
      return formattedResponse;
    })
    .finally(() => dispatch(isNotUpdating()));
};
