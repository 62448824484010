import { ReactNode, useCallback, useState } from 'react';
import classnames from 'classnames';

import Logo from 'components/ui/logo/logo';
import LongClick from 'components/ui/shared/directives/longClick';
import SelectServerDialogContainer from 'components/core/config/selectServerDialogContainer';
import { Location } from 'constants/reactRouter';
import { isProductionEnvironment } from 'utils/deviceInfoUtils';

import style from './authLayouts.scss';

interface Props {
  /** The component's child elements to be rendered. */
  children?: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** True when the dialog for select server is enabled. */
  isSelectServerDialogEnabled?: boolean;

  /** The current url information. */
  location: Location;
}

const AuthLayout = ({ children, className, isSelectServerDialogEnabled = true, location }: Props) => {
  const [isShowingSelectServerDialog, setShowingSelectServerDialog] = useState<boolean>(false);

  const onLongClickLogo = useCallback(() => {
    if (!isProductionEnvironment() && isSelectServerDialogEnabled) {
      setShowingSelectServerDialog(true);
    }
  }, [isSelectServerDialogEnabled]);

  // Temporary route check; if true, don't render the default logo
  const ignoreDefaultLogo = ['/auth/login-mag'].some((path) => location.pathname === path);

  return (
    <>
      <div className={classnames(style.authLayout, className)} data-testid="auth-layout">
        <div className={style.block}>
          {!ignoreDefaultLogo && (
            <div className={style.logoContainer}>
              <LongClick onLongClick={onLongClickLogo}>
                <Logo nonClickable spriteClassName={style.logo} theme="auth" />
              </LongClick>
            </div>
          )}
          {children}
        </div>
      </div>

      {isShowingSelectServerDialog && (
        <SelectServerDialogContainer onClose={() => setShowingSelectServerDialog(false)} />
      )}
    </>
  );
};

export default AuthLayout;

interface AuthContentProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;
}
export const AuthContent = ({ children }: AuthContentProps) => {
  return <div className={style.authContent}>{children}</div>;
};
