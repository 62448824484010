import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import { FacetGroup, PageInfo } from 'store/shared/models/graph/connections';
import { InitialState } from './broadcastsModels';
import {
  broadcastsListIsLoading,
  broadcastsListLoaded,
  broadcastsListCleared,
  broadcastsListUpdateItem,
} from './broadcastsActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const broadcastsReducer = handleActions(
  {
    [broadcastsListIsLoading().type]: (state) => state.set('isLoading', true),

    [broadcastsListLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        resultList: List(parseQueryConnectionResponse(action?.payload) || []),
        facetGroups: List(action?.payload?.facetGroups?.map((facetGroup) => new FacetGroup(facetGroup))),
        pageInfo: new PageInfo(action?.payload?.pageInfo),
      });
    },

    [broadcastsListCleared().type]: () => new InitialState(),

    [broadcastsListUpdateItem().type]: (state, action) => {
      const listItemNext = action.payload;
      const listItemIndex = state.resultList.findIndex((item) => item?.id === listItemNext?.id);

      if (listItemIndex === -1) {
        return state.merge({ resultList: state.resultList.insert(0, listItemNext) });
      }

      const existingItem = state.resultList.get(listItemIndex);
      const updatedItem = merge({}, existingItem, listItemNext);
      return state.merge({ resultList: state.resultList.set(listItemIndex, updatedItem) });
    },
  },
  new InitialState()
);
