import {
  InventoryItemValue,
  InventoryItemValueTypeEnum,
  MarketGuideMetadata,
} from 'store/shared/api/graph/interfaces/types';

export const marketGuideValueTypes = [
  InventoryItemValueTypeEnum.MARKET_GUIDE_LOWEST_SOLD_PRICE,
  InventoryItemValueTypeEnum.MARKET_GUIDE_AVERAGE_SOLD_PRICE,
  InventoryItemValueTypeEnum.MARKET_GUIDE_HIGHEST_SOLD_PRICE,
];

export const marketGuideValueTranslationMap = {
  [InventoryItemValueTypeEnum.MARKET_GUIDE_LOWEST_SOLD_PRICE]: 'low',
  [InventoryItemValueTypeEnum.MARKET_GUIDE_AVERAGE_SOLD_PRICE]: 'average',
  [InventoryItemValueTypeEnum.MARKET_GUIDE_HIGHEST_SOLD_PRICE]: 'high',
};

export const getMarketGuideValues = (values: InventoryItemValue[] | undefined) => {
  const marketGuideValuesFilteredAndSorted = values
    ?.filter(({ type }) => marketGuideValueTypes.includes(type?.id))
    .sort((a, b) => marketGuideValueTypes.indexOf(a?.type?.id) - marketGuideValueTypes.indexOf(b?.type?.id));

  return !marketGuideValuesFilteredAndSorted?.length ? undefined : marketGuideValuesFilteredAndSorted;
};

export const mapMarketGuideMetadataToInventoryItemValues = (
  marketGuideMetadata: Omit<MarketGuideMetadata, '__typename'> | undefined
): InventoryItemValue[] => {
  const metadata = Object.entries(marketGuideMetadata ?? {});

  // If all values are 0, return an empty array
  if (metadata.every(([_, value]) => value.amount === 0)) {
    return [];
  }

  return metadata.map<InventoryItemValue>(([key, amount]) => {
    switch (key) {
      case 'averagePrice': {
        return {
          id: key,
          created: new Date().toISOString(),
          type: {
            id: InventoryItemValueTypeEnum.MARKET_GUIDE_AVERAGE_SOLD_PRICE,
            canUpdate: false,
            canAdd: false,
            canDelete: false,
            name: marketGuideValueTranslationMap[InventoryItemValueTypeEnum.MARKET_GUIDE_AVERAGE_SOLD_PRICE],
          },
          value: amount,
        };
      }

      case 'lowestPrice': {
        return {
          id: key,
          created: new Date().toISOString(),
          type: {
            id: InventoryItemValueTypeEnum.MARKET_GUIDE_LOWEST_SOLD_PRICE,
            canUpdate: false,
            canAdd: false,
            canDelete: false,
            name: marketGuideValueTranslationMap[InventoryItemValueTypeEnum.MARKET_GUIDE_LOWEST_SOLD_PRICE],
          },
          value: amount,
        };
      }

      case 'highestPrice':
      default: {
        return {
          id: key,
          created: new Date().toISOString(),
          type: {
            id: InventoryItemValueTypeEnum.MARKET_GUIDE_HIGHEST_SOLD_PRICE,
            canUpdate: false,
            canAdd: false,
            canDelete: false,
            name: marketGuideValueTranslationMap[InventoryItemValueTypeEnum.MARKET_GUIDE_HIGHEST_SOLD_PRICE],
          },
          value: amount,
        };
      }
    }
  });
};
