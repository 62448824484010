import { storageManager } from 'utils/storageUtils';

export const USER_SESSION_EXPIRY_STORAGE_NAME = 'userSessionExpiry';

export interface UserSessionExpiryInfo {
  /** If the expiry time was dismissed by the user. */
  isDismissed?: boolean;
  /** The expiry time of the user session. */
  expiryTime: string;
}

const userSessionStorageManager = storageManager.createOrFetchStorage<UserSessionExpiryInfo>(
  USER_SESSION_EXPIRY_STORAGE_NAME
);

export const setUserSessionExpiryTime = (time: string | null, isDismissed?: boolean) => {
  const sessionExpiryInfo = userSessionStorageManager.get();

  if (time === null) {
    userSessionStorageManager.remove();
    return;
  }

  if (sessionExpiryInfo?.isDismissed) {
    // If an expiry time was previously dismissed,
    // update the expiryTime only if the value changes.
    const prevTime = sessionExpiryInfo.expiryTime;
    if (prevTime !== time) {
      userSessionStorageManager.set({ expiryTime: time, isDismissed: false });
    }
    return;
  }

  userSessionStorageManager.set({ expiryTime: time, isDismissed });
};
