import { SelectOption } from 'utils/interfaces/SelectOption';

export enum CountryCode {
  CA = 'CA',
  US = 'US',
}

/**
 * Mapped country code options for Select component.
 *
 * @example <Select options={formattedCountryCodeSelectOptions} />
 * @returns <SelectOption<CountryCode>>[]
 */
export const formattedCountryCodeSelectOptions = Object.entries(CountryCode).map<SelectOption<CountryCode>>(
  ([key, value]) => ({
    id: key,
    label: key,
    name: key,
    value,
  })
);
