export const inventoryItemSchema = `
  ... on Vehicle {
    id
    vin
    make
    model
    subModel
    trim
    company {
      id
      name
    }
    location {
      id
    }
    photos (first: 1) {
      thumb: url (width: 110, height: 110)
    }
    transportRequests {
      id
      status
      pickupLocation {
        id
        name
      }
      destinationLocation {
        name
      }
      distance
      created
    }
  }
`;

export const auctionItemConnectionSchema = `
  edges {
    node {
      id
      created
      status
      buyer {
        company {
          id
          name
        }
      }
      transactionDetails {
        created
      }
      inventoryItem {
        pickupLocation {
          id
        }
        ${inventoryItemSchema}
      }
    }
  }
`;
