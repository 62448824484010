import classnames from 'classnames';

import SelectConsigner from 'components/sections/admin/selectConsigner/selectConsigner';
import { t } from 'utils/intlUtils';

import style from './biddingAsSelector.scss';

interface Props {
  /** The id of the current auction */
  auctionId: string;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** True if the user has multiple company auction relationships */
  isSelectEnabled?: boolean;
  /** Cancel callback function */
  onCancel?: (args) => void;
  /** The theme of the SelectConsigner dialog */
  theme?: 'blue' | 'green' | 'red' | 'yellow';
}

const BiddingAsSelector = ({ auctionId, className, isSelectEnabled = false, onCancel, theme }: Props) => {
  return (
    <div className={classnames(style.container, className)}>
      {isSelectEnabled && <div className={style.text}>{t('bidding_as')}</div>}
      {isSelectEnabled && <SelectConsigner auctionId={auctionId} onCancel={onCancel} theme={theme} />}
    </div>
  );
};

export default BiddingAsSelector;
