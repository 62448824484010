import { List } from 'immutable';

import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionItemPublic } from 'constants/auctionItem';
import {
  FacetedPageInfo,
  FacetGroup,
  MarketGuideMetadata as MarketGuideMetadataType,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface MarketGuideModel {
  /** AuctionItemPublic facet groups. */
  facetGroups: List<RecordOf<FacetGroup>>;
  /** AuctionItemPublic metadata */
  metadata: MarketGuideMetadataType | null;
  /** Information about pagination. */
  pageInfo: RecordOf<FacetedPageInfo> | null;
  /** List of AuctionItemPublic. */
  resultList: List<AuctionItemPublic>;
}

export type MarketGuideProps = ServerRecordOf<MarketGuideModel>;

export const InitialState = ServerRecord<MarketGuideModel>({
  facetGroups: List([]),
  pageInfo: null,
  resultList: List([]),
  metadata: null,
});
