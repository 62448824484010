import { useMemo } from 'react';

import { rangeCA, ScoreRange, ScoreRangeItem } from 'components/ui/shared/scores/scoreRanges';

export interface ScoreRangeValueProps {
  className?: string;
  /** If set, then this score originates from autograde and will be rendered differently */
  isAutoGrade?: boolean;
  /** Score range to return the proper badge color */
  range: ScoreRange;
  /** The condition score */
  score: number | null | undefined;
  /** True to show tooltip */
  showTooltip?: boolean;
}

export type ScoreRangeValue = {
  rangeItem: ScoreRangeItem | undefined;
  maxScore: number;
};

export const useScoreRangeValue = ({ range = rangeCA, score }: ScoreRangeValueProps): ScoreRangeValue => {
  const maxScore = useMemo(() => range?.[0]?.range?.[1] || range?.[0]?.range?.[0], [range]);
  const rangeItem = useMemo(() => {
    if (!score) {
      return undefined;
    }

    return range?.find(
      // Check if score is equal to the min or max ranges values, or in between
      (valueRange) =>
        score === valueRange?.range?.[0] ||
        score === valueRange?.range?.[1] ||
        (score > valueRange?.range?.[0] && score < valueRange?.range?.[1])
    );
  }, [range, score]);

  return {
    maxScore,
    rangeItem,
  };
};
