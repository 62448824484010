import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import LinkButton from 'components/ui/shared/buttons/linkButton';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import OneTimeCodeInput from 'forms/shared/oneTimeCodeInput';
import {
  AccountVerificationStepHandleRef,
  AccountVerificationStepProps,
} from 'forms/account/accountVerification/steps/accountVerificationHelpers';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  MutationuserAccountSendVerificationCodeArgs,
  MutationuserAccountVerifyArgs,
  UserAccountVerificationType,
} from 'store/shared/api/graph/interfaces/types';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';
import {
  userAccountSendVerificationCode,
  userAccountVerify,
} from 'store/shared/api/graph/mutations/userAccountSendVerificationCode';

import style from 'forms/account/accountVerification/accountVerification.scss';

const oneTimeCodeLength = 6;

const MobileVerificationCodeInput = forwardRef<AccountVerificationStepHandleRef, AccountVerificationStepProps>(
  ({ formData, isNavigationHidden, onChange, onNavigate }, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
    const [showResendBanner, setShowResendBanner] = useState<boolean>(false);

    /**
     * Update internal state when any inputs have changed, and notify
     * the form controller if the submit button is eligible for submission.
     */
    const onInputChange = useCallback(
      (value) => {
        setInputValue(value);
        onChange(value?.length === oneTimeCodeLength);
      },
      [onChange]
    );

    /**
     * onResendCode
     */
    const onResendCode = useCallback(() => {
      const options = formData as MutationuserAccountSendVerificationCodeArgs;
      userAccountSendVerificationCode(options)
        ?.then(() => setShowResendBanner(true))
        ?.catch((error) => onApiError(error, setErrorMessages));
    }, [formData]);

    /**
     * onResendCode - debounced
     */
    const onResendCodeDebounced = useMemo(() => debounce(onResendCode, 250), [onResendCode]);

    // Exposes `validate` function to parent form controller
    useImperativeHandle(ref, () => ({
      validate: () => {
        const options: MutationuserAccountVerifyArgs = {
          code: inputValue,
          verificationType: UserAccountVerificationType.SMS,
          verifiedField: formData?.verifiedField,
        };

        // Send verification code; show error state when necessary
        return new Promise((resolve) => {
          return userAccountVerify(options)
            ?.then(() => resolve(undefined))
            ?.catch((error) => onApiError(error, setErrorMessages));
        });
      },
    }));

    // On mount, notify the form controller that the submit button should be disabled
    useMountEffect(() => onChange(false));

    return (
      <>
        <p className={style.title}>{t('enter_verification_code')}</p>
        <p>
          <InterpolatedTranslation
            args={[`<br/><strong>${formData?.verifiedField}</strong>`]}
            namespace="verification_code_sent_to_mobile_phone"
          />
        </p>
        <FormErrors className={style.formErrors} errorMessages={errorMessages} isSmallDialog />
        {showResendBanner && (
          <NotificationBanner className={style.notificationBanner} primaryGlyphType="info" theme="blue">
            {t('verification_code_sent')}
          </NotificationBanner>
        )}
        <OneTimeCodeInput className={style.codeInput} inputLength={oneTimeCodeLength} onChange={onInputChange} />
        <div className={style.actionButtons}>
          <LinkButton onClick={onResendCodeDebounced}>{t('resend_code')}</LinkButton>
          {!isNavigationHidden && <LinkButton onClick={() => onNavigate(false)}>{t('back')}</LinkButton>}
        </div>
      </>
    );
  }
);

export default MobileVerificationCodeInput;
