import { FormEvent, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';

import poweredByEBlockGlyph from 'glyphs/powered-by-eblock.svg';

import AuthBanner from 'components/sections/auth/authBanner';
import FooterSupportText from 'components/sections/auth/footerSupportText';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch } from 'store/configureStore';
import { AuthContent } from 'layouts/authLayouts/authLayouts';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { MutationuserResetPasswordArgs } from 'store/shared/api/graph/interfaces/types';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { Spinner } from 'components/ui/loading/loading';
import { SubmitButton } from 'components/sections/auth/auth';
import { cacheAuth } from 'utils/authUtils';
import { getErrors } from 'utils/apiUtils';
import { getUrlParamsToQueryString } from 'utils/urlUtils';
import { processResetPassword, processTryLogin } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';

import style from './resetPassword.scss';

// Search param used to indicate reset-password flow is being used to reset a user's expired password
export const EXPIRED_PASSWORD_PARAM = 'expired-password';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function to reset password. */
  resetPassword: (options: MutationuserResetPasswordArgs) => processResetPassword(options, dispatch),
  /** Function to try login. */
  tryLogin: () => processTryLogin(dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends RouterProps, ConnectedProps<typeof connector> {}

const ResetPassword = ({ location, params, resetPassword, tryLogin, router }: Props) => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const isExpiredPasswordFlow = location?.query?.[EXPIRED_PASSWORD_PARAM] === null;
  const isSubmissionEnabled = password1?.length && password2?.length;

  /**
   * onSubmit
   */
  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      const { token } = params;
      e.preventDefault();

      setIsLoading(true);
      setErrorMessages(undefined);

      if (password1 !== password2) {
        setIsLoading(false);
        setErrorMessages([{ message: t('passwords_dont_match') }]);

        return false;
      }

      resetPassword?.({ token, password: password1 })
        ?.then((response) => {
          cacheAuth(response?.data?.data?.result?.token);
          tryLogin()?.then(() => router?.replace('/'));
        })
        ?.catch((err) => {
          const errors = getErrors(err);
          setIsLoading(false);
          if (errors) {
            setErrorMessages(errors);
          } else {
            setErrorMessages([{ message: t('unexpected_error_occurred') }]);
          }
        });

      return false;
    },
    [params, password1, password2, resetPassword, router, tryLogin]
  );

  /**
   * Displayed banner messages.
   */
  const bannerMessages = useMemo(() => {
    return (
      <div data-testid="password-criteria">
        {isExpiredPasswordFlow && <strong className={style.bannerMessage}>{t('your_password_has_expired')}</strong>}
        <InterpolatedTranslation namespace="new_password_criteria_short" />
      </div>
    );
  }, [isExpiredPasswordFlow]);

  return (
    <>
      <FormErrors className={style.errors} errorMessages={errorMessages} isInteractiveText />
      <AuthBanner>{bannerMessages}</AuthBanner>
      <AuthContent>
        <div className={style.authForm}>
          <form onSubmit={onSubmit}>
            <h2 className={style.header}>{t(isExpiredPasswordFlow ? 'update_password' : 'reset_password')}</h2>
            <div className={style.inputSection}>
              <div className={style.inputGroup}>
                <input
                  className={style.input}
                  onChange={(e) => setPassword1(e.target.value)}
                  placeholder={t('password')}
                  type="password"
                />
              </div>
              <div className={style.inputGroup}>
                <input
                  className={style.input}
                  onChange={(e) => setPassword2(e.target.value)}
                  placeholder={t('reenter_password')}
                  type="password"
                />
              </div>
            </div>
            <SubmitButton disabled={!isSubmissionEnabled || isLoading}>
              {isLoading ? (
                <Spinner className={style.loadingSpinner} />
              ) : (
                t(isExpiredPasswordFlow ? 'update_password' : 'send_password')
              )}
            </SubmitButton>
          </form>
          <p className={style.altLinks}>
            <Link
              className={style.altLink}
              to={`${Route.AUTH_LOGIN}?${getUrlParamsToQueryString([EXPIRED_PASSWORD_PARAM])}`}
            >
              {t('back_to_login')}
            </Link>
          </p>
          <FooterSupportText hasTopMargin isShortForm />

          {process.env.PRIVATE_LABEL && <Sprite className={style.poweredBy} glyph={poweredByEBlockGlyph} />}
        </div>
      </AuthContent>
    </>
  );
};

export default withRouter(connector(ResetPassword));
