import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { clamp } from 'lodash-es';

import {
  AccountVerificationFormProps,
  AccountVerificationFormStep,
  AccountVerificationStepHandleRef,
  AccountVerificationStepProps,
} from 'forms/account/accountVerification/steps/accountVerificationHelpers';

interface Props {
  /** The current form step */
  currentStep: number;
  /** The form ref */
  formRef: MutableRefObject<AccountVerificationStepHandleRef | undefined>;
  /** Callback to close the form */
  onClose: AccountVerificationFormProps['onClose'];
  /** Callback function to set `currentStep` */
  setCurrentStep: Dispatch<SetStateAction<number>>;
  /** Callback to set form data */
  setFormData: Dispatch<SetStateAction<AccountVerificationStepProps['formData']>>;
  /** A form's step config */
  steps: AccountVerificationFormStep[];
}

/**
 * Confirmation callback hook which determines if we should move
 * on to the next step, or if we're finished.
 */
export const useOnSubmit = ({ currentStep, formRef, onClose, setCurrentStep, setFormData, steps }: Props) =>
  useCallback(
    async (shouldSubmit: boolean) => {
      if (!shouldSubmit) {
        return;
      }

      if (currentStep === steps.length - 1) {
        // We're done; close
        onClose();
        return;
      }

      if (steps[currentStep].isFormWithValidationRequirements) {
        // Has validation requirements; defer to child component and await response
        const formDataToPersist = await formRef?.current?.validate();

        // Some future steps require previously entered information; persist it in state if returned.
        if (formDataToPersist) {
          setFormData(formDataToPersist);
        }
      }

      // Jump to next step
      setCurrentStep(clamp(currentStep + 1, 0, steps.length - 1));
    },
    [currentStep, formRef, onClose, setCurrentStep, setFormData, steps]
  );
