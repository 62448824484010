import InputGroup from 'forms/shared/inputGroup';
import Label from 'forms/shared/label';
import SelectCompany, { SelectCompanyProps } from 'forms/shared/selectCompany';
import { t } from 'utils/intlUtils';

interface SelectCompanyInputGroupProps<IsMulti extends boolean = false> extends SelectCompanyProps<IsMulti> {
  /** An id used for testing.*/
  dataTestId?: string;
  /** Label of the dropdown. */
  label?: string;
}

const SelectCompanyInputGroup = <IsMulti extends boolean = false>({
  label = t('select_company'),
  onChange = () => {},
  onInputChange = () => {},
  ...props
}: SelectCompanyInputGroupProps<IsMulti>) => (
  <InputGroup dataTestId={props.dataTestId} groupType="dropdownCustom" name="selectCompany">
    <Label>{label}</Label>
    <SelectCompany hasBottomMargin={false} onChange={onChange} onInputChange={onInputChange} {...props} />
  </InputGroup>
);

export default SelectCompanyInputGroup;
