import classnames from 'classnames';
import { ChangeEvent, ReactNode } from 'react';
import { SortEnd } from 'react-sortable-hoc';
import { fill, isEqual } from 'lodash-es';

import closeGlyph from 'glyphs/close.svg';
import trashGlyph from 'glyphs/trash.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Photo from 'constants/photo';
import PhotoDetailsSection from 'components/sections/inventoryItem/addModify/vehicleFormPanes/photoDetailsSection';
import Select from 'forms/shared/select';
import Sprite from 'components/ui/shared/sprite';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessage, ErrorMessages } from 'constants/errors';
import {
  LegacyInventoryPhotoOptions,
  UploadInventoryPhotoOption,
  UploadInventoryPhotoOptions,
} from 'store/inventoryItem/addModify/addModifyActions';
import { PhotoType, VehicleCaptureType } from 'store/shared/api/graph/interfaces/types';
import { Spinner } from 'components/ui/loading/loading';
import { correctPhotosShotCodeOrder, getPhotosByType, mapShotCodes } from 'utils/photoUtils';
import { isVerifiedAutoGrade } from 'utils/auctionItemUtils';
import { move } from 'utils/arrayUtils';
import { t } from 'utils/intlUtils';

import style from './photoDetails.scss';

const MAX_SIZE_MB = 5;

interface SectionDetails {
  /** The id of the section */
  id: string;
  /** The section ref name */
  sectionRef: string;
  /** The photos for the section */
  photoSection: PhotoSectionItem[];
  /** The photo type of the section */
  photoType: PhotoType;
}

interface PhotoSectionItem {
  /** Delete button to be rendered. */
  deleteButton?: ReactNode;
  /** Whether click is disabled or not. */
  disableClick?: boolean;
  /** Whether is disabled or not. */
  disabled?: boolean;
  /** Dropdown to be rendered. */
  dropdown?: ReactNode;
  /** Node to be rendered. */
  node: ReactNode;
}

interface Props {
  /** Callback function to clear error. */
  clearError: () => void;
  /** Error messages. */
  errorMessages: (ErrorMessage & { bullets?: boolean })[];
  /** Callback function to disable the modal. */
  handleDisableModal: (isDisabled: boolean) => void;
  /** Callback function to submit inventory item updates. */
  handleSubmitUpdateInventoryItem: (value: AddModifyVehicleProps, persist?: boolean) => void;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs['results'];
  /** Callback function to scroll modal by offset value. */
  scrollModalByValue: (scrollOffset: number) => void;
  /** Name to the section reference. */
  sectionRef?: string;
  /** Callback function to set error messages. */
  setError: (errorMessages: ErrorMessages) => void;
  /** Callback function to set vehicle information. */
  setVehicle: (options: AddModifyVehicleProps) => void;
  /** Callback function to upload inventory photos. */
  uploadInventoryPhoto: (option: LegacyInventoryPhotoOptions | UploadInventoryPhotoOptions) => void;
}

interface PhotoSections {
  /** Damage photos section items. */
  damagePhotosSection: PhotoSectionItem[];
  /** Exterior photos section items. */
  exteriorPhotosSection: PhotoSectionItem[];
  /** Interior photos section items. */
  interiorPhotosSection: PhotoSectionItem[];
  /** Undercarriage photos section items. */
  undercarriagePhotosSection: PhotoSectionItem[];
}

interface State extends PhotoSections {
  /** List of corrected shot code order photos. */
  photos: Photo[] | undefined;
}

class PhotoDetails extends BaseClass<Props, State> {
  // Variables
  private damageDropdownId: string | null = null;
  private damagePhotos: HTMLDivElement | null = null;
  private exteriorPhotos: HTMLDivElement | null = null;
  private interiorPhotos: HTMLDivElement | null = null;
  private undercarriagePhotos: HTMLDivElement | null = null;

  // Elements
  private container: HTMLDivElement | null = null;

  static defaultProps = {
    sectionRef: null,
  };

  constructor(props: Props) {
    super(props);
    const {
      inventoryItem: {
        vehicle: { photos },
      },
    } = props;

    this.state = {
      damagePhotosSection: this.getPhotosSection(photos, PhotoType.DAMAGE),
      exteriorPhotosSection: this.getPhotosSection(photos, PhotoType.EXTERIOR),
      interiorPhotosSection: this.getPhotosSection(photos, PhotoType.INTERIOR),
      undercarriagePhotosSection: this.getPhotosSection(photos, PhotoType.UNDERCARRIAGE),
      photos,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const { sectionRef, scrollModalByValue } = this.props;

    if (sectionRef && this[sectionRef]) {
      const offsetValue = this[sectionRef].getBoundingClientRect().top;
      scrollModalByValue(offsetValue);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      errorMessages: errorMessagesNext,
      inventoryItem: {
        vehicle: { uploadPhotoType, photos: inventoryItemPhotosNext, remainingUploads },
      },
    } = this.props;
    const {
      errorMessages,
      handleDisableModal,
      inventoryItem: {
        vehicle: { photos: inventoryItemPhotos },
      },
      setVehicle,
    } = prevProps;
    const hasPhotosChanged = !isEqual(inventoryItemPhotos, inventoryItemPhotosNext) && inventoryItemPhotosNext;
    const hasDamagePhotoAdded =
      uploadPhotoType === PhotoType.DAMAGE &&
      Number(inventoryItemPhotosNext?.length) > Number(inventoryItemPhotos?.length);
    const hasErrorChanged = !isEqual(errorMessages, errorMessagesNext) && !!errorMessagesNext.length;

    if (hasPhotosChanged || hasErrorChanged) {
      const correctedPhotos = correctPhotosShotCodeOrder(inventoryItemPhotosNext || []);

      if (hasDamagePhotoAdded && !this.damageDropdownId) {
        const damagePhotos = getPhotosByType(correctedPhotos, PhotoType.DAMAGE);
        this.damageDropdownId = damagePhotos[damagePhotos.length - 1].id ?? null;
      }

      const damagePhotosSection = this.getPhotosSection(correctedPhotos, PhotoType.DAMAGE, this.damageDropdownId);
      const exteriorPhotosSection = this.getPhotosSection(correctedPhotos, PhotoType.EXTERIOR);
      const interiorPhotosSection = this.getPhotosSection(correctedPhotos, PhotoType.INTERIOR);
      const undercarriagePhotosSection = this.getPhotosSection(correctedPhotos, PhotoType.UNDERCARRIAGE);

      if (remainingUploads && uploadPhotoType) {
        const photosSections: PhotoSections = {
          damagePhotosSection,
          exteriorPhotosSection,
          interiorPhotosSection,
          undercarriagePhotosSection,
        };
        const placeholderSection = this.getPlaceholderSection(photosSections, uploadPhotoType);

        this.handlePlaceholders(remainingUploads, uploadPhotoType, placeholderSection);
      } else {
        this.setState({
          damagePhotosSection,
          exteriorPhotosSection,
          interiorPhotosSection,
          undercarriagePhotosSection,
          photos: correctedPhotos,
        });
        if (!remainingUploads && uploadPhotoType) {
          handleDisableModal(false);

          if (uploadPhotoType === PhotoType.DAMAGE) {
            document.getElementById('damage-photos')?.scrollIntoView(false);
          }
          setVehicle({ ignoreHasStateChanged: true, uploadPhotoType: undefined });
        }
      }
    }
  }

  getFormattedPhotos = (photos: Photo[] | undefined) =>
    photos?.map((photo) => ({
      damageLocation: photo.location,
      id: photo.id,
      shotCode: photo.shotCode,
      type: photo.type,
    }));

  getPhotosSection = (photos: Photo[] | undefined, photoType: PhotoType, damageDropdownId: string | null = null) => {
    const addButton: PhotoSectionItem[] = this.renderAddButton(photoType);
    const photosArray = getPhotosByType(photos || [], photoType).map((photo) => ({
      deleteButton: this.renderDeleteButton(photo),
      dropdown: photoType === PhotoType.DAMAGE ? this.renderDropdown(photo, damageDropdownId) : null,
      node: this.renderPhoto(photo),
    }));

    return addButton.concat(photosArray);
  };

  getPlaceholderSection(photoSections: PhotoSections, uploadPhotoType: PhotoType) {
    const { damagePhotosSection, exteriorPhotosSection, interiorPhotosSection, undercarriagePhotosSection } =
      photoSections;

    if (uploadPhotoType === PhotoType.EXTERIOR) {
      return exteriorPhotosSection;
    }
    if (uploadPhotoType === PhotoType.UNDERCARRIAGE) {
      return undercarriagePhotosSection;
    }
    if (uploadPhotoType === PhotoType.INTERIOR) {
      return interiorPhotosSection;
    }

    return damagePhotosSection;
  }

  getUploadPhotoOptions = (files: File[], type: PhotoType): UploadInventoryPhotoOptions => {
    const fileUploadOptionList: UploadInventoryPhotoOption[] = [];
    const { inventoryItem } = this.props;
    const photos = inventoryItem?.vehicle?.photos ?? [];
    let shotCode = photos.filter((photo) => photo.type === type).length;

    for (const file of files) {
      shotCode += 1;
      const fileUploadOption = {
        contentType: file.type,
        damageLocation: type === PhotoType.DAMAGE ? 'Other' : undefined,
        inventoryItemId: inventoryItem?.vehicle?.id ?? '',
        photoFile: file,
        photoType: type,
        shotCode,
      };
      fileUploadOptionList.push(fileUploadOption);
    }
    return fileUploadOptionList;
  };

  isValidateComplete = (photos: Photo[] | undefined) => {
    const {
      inventoryItem: {
        vehicle: { incompleteFieldMessages, validateComplete },
      },
    } = this.props;
    const hasExteriorPhoto = !!photos?.filter((obj) => obj.type === PhotoType.EXTERIOR).length;
    const hasRequiredFields = incompleteFieldMessages && incompleteFieldMessages.length;

    return validateComplete && !hasRequiredFields && hasExteriorPhoto;
  };

  reorderShotCode(photoType: PhotoType, oldIndex: number, newIndex: number) {
    const { photos } = this.state;
    const newPhotoIndex = newIndex - 1;
    const oldPhotoIndex = oldIndex - 1;
    let damagePhotos = getPhotosByType(photos || [], PhotoType.DAMAGE);
    let exteriorPhotos = getPhotosByType(photos || [], PhotoType.EXTERIOR);
    let interiorPhotos = getPhotosByType(photos || [], PhotoType.INTERIOR);
    let undercarriagePhotos = getPhotosByType(photos || [], PhotoType.UNDERCARRIAGE);

    switch (photoType) {
      case PhotoType.EXTERIOR:
        exteriorPhotos = mapShotCodes(move(exteriorPhotos, oldPhotoIndex, newPhotoIndex));
        break;
      case PhotoType.UNDERCARRIAGE:
        undercarriagePhotos = mapShotCodes(move(undercarriagePhotos, oldPhotoIndex, newPhotoIndex));
        break;
      case PhotoType.INTERIOR:
        interiorPhotos = mapShotCodes(move(interiorPhotos, oldPhotoIndex, newPhotoIndex));
        break;
      case PhotoType.DAMAGE:
        damagePhotos = mapShotCodes(move(damagePhotos, oldPhotoIndex, newPhotoIndex));
        break;
    }
    const photosArray = exteriorPhotos.concat(undercarriagePhotos).concat(interiorPhotos).concat(damagePhotos);

    return this.getFormattedPhotos(photosArray);
  }

  updatePhotosArray(photoId: string | undefined, value: string | undefined): Photo[] {
    const { photos } = this.state;

    return (
      this.getFormattedPhotos(photos)?.map((item) => ({
        ...item,
        damageLocation: item.id === photoId ? value : item.damageLocation,
      })) || []
    );
  }

  deleteFromPhotosArray(photo: Photo) {
    const { photos } = this.state;

    return this.getFormattedPhotos(photos)
      ?.filter((item) => item.id !== photo.id)
      .map((item) => {
        let shotCode = Number(item.shotCode);

        if (item.type === photo.type && shotCode > Number(photo.shotCode)) {
          shotCode -= 1;
        }
        return { ...item, shotCode };
      });
  }

  filterFilesByMaxSize(files: File[]) {
    const maxSizeKB = MAX_SIZE_MB * 1024; // Size in KB
    const maxSizeBytes = maxSizeKB * 1024; // File size is returned in Bytes
    const validFiles: File[] = [];
    const overSizedFiles: File[] = [];

    files.forEach((file) => (file.size <= maxSizeBytes ? validFiles.push(file) : overSizedFiles.push(file)));

    return { validFiles, overSizedFiles };
  }

  displayErrorMessages = (files: File[]) => {
    const { setError } = this.props;
    const errorMessages = [
      {
        message: t('file_upload_size_error_message', [MAX_SIZE_MB]),
        name: 'uploadSize',
      },
    ];

    errorMessages.push(...files.map((item) => ({ bullets: true, message: item.name, name: item.name })));
    setError(errorMessages);
  };

  handleSubmitUpdate = (photos: Photo[] | undefined) => {
    const obj = { photos, validateComplete: this.isValidateComplete(photos) };
    this.props.handleSubmitUpdateInventoryItem(obj);
  };

  handleUpdatePhoto = (photoId: string | undefined, value: string | undefined) => {
    this.props.clearError();
    this.handleSubmitUpdate(this.updatePhotosArray(photoId, value));
  };

  handleDeletePhoto = (photo: Photo) => {
    this.props.clearError();
    this.handleSubmitUpdate(this.deleteFromPhotosArray(photo));
  };

  handleFileReader = (files: File[], type: PhotoType) => {
    const { handleDisableModal, uploadInventoryPhoto } = this.props;

    const uploadOptions = this.getUploadPhotoOptions(files, type);

    uploadInventoryPhoto(uploadOptions);
    handleDisableModal(true);
    this.handlePlaceholders(files.length, type);
  };

  handleAddPhoto = (e: ChangeEvent<HTMLInputElement>, type: PhotoType) => {
    e.preventDefault();
    const filesArray = e.target.files ? Array.from(e.target.files) : [];
    const { overSizedFiles, validFiles } = this.filterFilesByMaxSize(filesArray);

    this.props.clearError();
    if (overSizedFiles.length > 0) {
      this.displayErrorMessages(overSizedFiles);
    }
    if (validFiles.length) {
      this.handleFileReader(validFiles, type);
    }
  };

  handlePlaceholders = (num: number, photoType: PhotoType, placeHolderSection?: PhotoSectionItem[]) => {
    const placeholders = this.renderPlaceholders(num);
    const getSection = (type: PhotoType, prevSection: PhotoSectionItem[]) =>
      photoType === type && placeHolderSection ? placeHolderSection : prevSection;
    const modifySection = (type: PhotoType, section: PhotoSectionItem[]) => {
      const newSection = photoType === type ? section.concat(placeholders) : section;
      return newSection.map((item, index) => ({ ...item, disabled: true, disableClick: index === 0 }));
    };

    this.setState(
      ({
        damagePhotosSection,
        exteriorPhotosSection,
        interiorPhotosSection,
        undercarriagePhotosSection,
      }: PhotoSections) => ({
        damagePhotosSection: modifySection(PhotoType.DAMAGE, getSection(PhotoType.DAMAGE, damagePhotosSection)),
        exteriorPhotosSection: modifySection(PhotoType.EXTERIOR, getSection(PhotoType.EXTERIOR, exteriorPhotosSection)),
        interiorPhotosSection: modifySection(PhotoType.INTERIOR, getSection(PhotoType.INTERIOR, interiorPhotosSection)),
        undercarriagePhotosSection: modifySection(
          PhotoType.UNDERCARRIAGE,
          getSection(PhotoType.UNDERCARRIAGE, undercarriagePhotosSection)
        ),
      })
    );
  };

  handleOnSortEnd = ({ newIndex, oldIndex }: SortEnd, photoSection: PhotoSectionItem[], photoType: PhotoType) => {
    const newPhotoSection = move(photoSection, oldIndex, newIndex);
    const newPhotosArray = this.reorderShotCode(photoType, oldIndex, newIndex);
    const photoSectionName = `${photoType.toLowerCase()}PhotosSection`;

    if (oldIndex !== newIndex) {
      this.props.clearError();
      this.setState({ [photoSectionName]: newPhotoSection } as Pick<State, keyof State>);
      this.handleSubmitUpdate(newPhotosArray);
    }
  };

  renderAddButton(photoType: PhotoType) {
    const { clearError } = this.props;
    const fileType = `${photoType.toLowerCase()}FileInput`;
    return [
      {
        node: (
          <div>
            <input
              key="input"
              ref={(ref) => {
                this[fileType] = ref;
              }}
              accept="image/x-png,image/jpeg"
              className={style.fileInput}
              data-testid={`addPhoto-input-${fileType}`}
              id={fileType}
              multiple
              name="myimage"
              onChange={(e) => this.handleAddPhoto(e, photoType)}
              type="file"
            />
            <Button
              className={style.addPhotoContainer}
              dataTestId="addPhoto-button"
              onClick={() => {
                clearError();
                this[fileType].value = null;
                this[fileType].click();
              }}
              theme="gray-outline"
            >
              <span className={style.plusSymbol}>+</span>
              <span className={style.addPhototext}>{t('add_photo')}</span>
            </Button>
          </div>
        ),
        disabled: true,
      },
    ];
  }

  renderDeleteButton(photo: Photo) {
    return (
      <Button
        className={style.trashButton}
        dataTestId="trashButton"
        onClick={() => this.handleDeletePhoto(photo)}
        theme="none"
      >
        <Sprite className={style.sprite} glyph={trashGlyph} />
      </Button>
    );
  }

  renderPlaceholders(num: number): PhotoSectionItem[] {
    const placeholder = {
      node: (
        <div className={style.placeholder}>
          <Spinner className={style.imgSpinner} theme="gray" />
        </div>
      ),
      disabled: true,
    };
    return fill(Array(num), placeholder);
  }

  renderDropdown = (photo: Photo, damageDropdownId: string | null) => {
    const photoDamageLocations = this.props.inventoryItem?.metadata?.photoDamageLocations;
    const damageLocations = photoDamageLocations
      ?.filter(Boolean)
      ?.map((location) => ({ value: location.id, label: location.value }));

    return (
      <div className={style.dropdownContainer}>
        <Select
          defaultMenuIsOpen={damageDropdownId === photo.id}
          id="select-location"
          isSearchable={false}
          onChange={(e) => {
            if (damageDropdownId) {
              this.damageDropdownId = null;
            }
            this.handleUpdatePhoto(photo?.id, e?.value || undefined);
          }}
          options={damageLocations}
          placeholder={t('select_location')}
          styleName="dark"
          value={{ value: photo?.locationKey, label: photo?.location }}
        />
      </div>
    );
  };

  renderPhoto = (photo: Photo) => {
    const { clearError } = this.props;

    return (
      <div className={style.imgContainer} onClick={clearError} onKeyPress={clearError} role="link" tabIndex={0}>
        <div className={style.imgWrapper}>
          <div className={style.imgWrapperContainer} style={{ backgroundImage: `url(${photo.url})` }} />
        </div>
      </div>
    );
  };

  getSectionTypes = () => {
    const { inventoryItem } = this.props;
    const { damagePhotosSection, exteriorPhotosSection, interiorPhotosSection, undercarriagePhotosSection } =
      this.state;

    const displayUnderCarriagePhotos = [
      VehicleCaptureType.VERIFIED_AUTOGRADE_CAPTURE,
      VehicleCaptureType.VERIFIED_CAPTURE,
      VehicleCaptureType.VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE,
      VehicleCaptureType.VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE,
    ].includes(inventoryItem?.vehicle?.captureType);
    const displayDamagePhotos = !isVerifiedAutoGrade(inventoryItem?.vehicle?.captureType);

    const sections: SectionDetails[] = [];
    sections.push({
      id: 'exterior-photos',
      photoSection: exteriorPhotosSection,
      photoType: PhotoType.EXTERIOR,
      sectionRef: 'exteriorPhotos',
    });
    if (displayUnderCarriagePhotos) {
      sections.push({
        id: 'undercarriage-photos',
        photoSection: undercarriagePhotosSection,
        photoType: PhotoType.UNDERCARRIAGE,
        sectionRef: 'undercarriagePhotos',
      });
    }
    sections.push({
      id: 'interior-photos',
      photoSection: interiorPhotosSection,
      photoType: PhotoType.INTERIOR,
      sectionRef: 'interiorPhotos',
    });
    if (displayDamagePhotos) {
      sections.push({
        id: 'damage-photos',
        photoSection: damagePhotosSection,
        photoType: PhotoType.DAMAGE,
        sectionRef: 'damagePhotos',
      });
    }

    return sections;
  };

  render() {
    const { clearError, errorMessages } = this.props;
    const sectionTypes = this.getSectionTypes();

    return (
      <div
        ref={(div) => {
          this.container = div;
        }}
        className={classnames(style.photoDetails)}
        data-testid="add-vehicle-photos"
      >
        {!!errorMessages.length && (
          <div className={classnames(style.segment)}>
            <ul className={style.errorMessageContainer}>
              {errorMessages.map((errorItem, index) => (
                <li key={`error-${index}`} className={errorItem.bullets ? style.bullets : undefined}>
                  {errorItem.message}
                </li>
              ))}
              <Button className={style.closeErrors} onClick={clearError} theme="none">
                <Sprite className={style.sprite} glyph={closeGlyph} />
              </Button>
            </ul>
          </div>
        )}
        {sectionTypes.map((sectionType, index) => {
          return (
            <PhotoDetailsSection
              key={`${sectionType.photoType}-section`}
              handleOnSortEnd={this.handleOnSortEnd}
              highlighted={sectionType.sectionRef === this.props.sectionRef}
              id={sectionType.id}
              photoSection={sectionType.photoSection}
              photoType={sectionType.photoType}
              sectionIndex={index + 1}
              sectionRef={(ref) => {
                this[sectionType.sectionRef] = ref;
              }}
              totalSections={sectionTypes.length}
            />
          );
        })}
      </div>
    );
  }
}

export default PhotoDetails;
