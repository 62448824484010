import { ReactNode } from 'react';
import classnames from 'classnames';

import noResultsGlyph from 'glyphs/no-results.svg';

import Sprite, { Glyph } from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './noResults.scss';

interface Props {
  children?: ReactNode | ReactNode[];
  className?: string;
  glyph?: Glyph;
  isTransparentBackground?: boolean;
  spriteClassName?: string;
  title?: string;
}

const NoResults = ({
  children,
  className,
  glyph = noResultsGlyph,
  isTransparentBackground,
  spriteClassName,
  title = t('no_results_found'),
}: Props) => {
  return (
    <div
      className={classnames(style.noResults, isTransparentBackground && style.transparentBg, className)}
      data-testid="no-results"
    >
      <Sprite className={classnames(style.noResultsGlyph, spriteClassName)} glyph={glyph} />
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default NoResults;
