import { MouseEventHandler, useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import Dialog from 'components/sections/auctionItem/details/status/statusRows/offerRow/acceptOffer/dialog';
import { Company, MonetaryAmount } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

interface Props {
  /** Company that is accepting an offer */
  acceptOfferCompany: Pick<Company, 'id' | 'name'>;
  /** True if it's a seller */
  isSeller: boolean;
  /** Offer Id */
  offerId: string;
  /** Offered amount */
  offeredAmount: Pick<MonetaryAmount, 'formattedAmountRounded'>;
  /** Company that is offering */
  offeringCompany: Pick<Company, 'id' | 'name'>;
  /** Called each time the dialog is opened or closed */
  onIsOpenChange: (isOpen: boolean) => void;
}

const AcceptOffer = ({ offeredAmount, onIsOpenChange, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Handles accept offer action - Opens Dialog for confirmation
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault();

      setIsOpen(true);
      onIsOpenChange(true);
    },
    [onIsOpenChange]
  );

  /**
   * Handles cancel action - Closes Dialog
   */
  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    onIsOpenChange(false);
  }, [onIsOpenChange]);

  return (
    <>
      <Button onClick={handleOnClick} theme="green">
        {t('accept_x', [offeredAmount.formattedAmountRounded])}
      </Button>
      {isOpen && <Dialog {...props} offeredAmount={offeredAmount} onClose={handleOnClose} />}
    </>
  );
};

export default AcceptOffer;
