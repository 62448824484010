import { MouseEventHandler, useCallback, useMemo } from 'react';

import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { AuctionItemFormat } from 'store/shared/api/graph/interfaces/types';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { Row, Rows, Name } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarDate, formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './ifBid.scss';

interface Props extends RouterProps {
  /** The auction item  */
  details: AuctionItemDetailsProps;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids. */
  onShowIfBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids with. */
  onShowIfBidWith: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler on transport estimate row click. */
  onShowTransportEstimate: (auctionItem: AuctionItemDetailsProps) => void;
  /** The current user. */
  user: User;
}

const IfBid = ({
  details,
  details: {
    auction,
    autoBids,
    deliveryDate,
    format,
    guaranteedAuctionPrice,
    ifBidTimeline,
    inventoryItem,
    isMyItem,
    ranOn,
  },
  onShowAuditLog,
  onShowAutobids,
  onShowBids,
  onShowIfBidWith,
  onShowIfBids,
  onShowRanBefore,
  onShowTransportEstimate,
  router,
  user,
}: Props) => {
  const seller = inventoryItem.company;
  const buyer = details?.buyer?.company;
  const auctionName = getAuctionNameByDetails(details);
  const amountApart = ifBidTimeline?.amountApart?.formattedAmountRounded;
  const estimatedDelivery = deliveryDate && formatCalendarDate(deliveryDate, false);
  const dateRan = formatCalendarTime(ranOn, 'MMMM DD, YYYY [at] h:mm A');
  const isAuction = isAuctionFormat(format);
  const autoBidAmount = autoBids?.myAutoBid?.maxBid?.formattedAmountRounded;
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  /**
   * Handles company redirect onClick event
   */
  const handleCompanyRedirectOnClick = useCallback(
    (id: string) => () => router.push(`/admin/companies?id=${id}`),
    [router]
  );

  /**
   * Handles Transport Estimate onClick event
   */
  const handleTransportEstimateOnClick = useCallback(
    () => onShowTransportEstimate(details),
    [details, onShowTransportEstimate]
  );

  if (!ifBidTimeline) {
    return null;
  }

  const topOffer = ifBidTimeline.list?.filter(Boolean)[0];

  return (
    <div key="ifBid" className={style.ifBid} data-testid="if-bid-rows">
      <Rows>
        {isStaffUser && (
          <>
            {seller && (
              <Row dataTestId="status-row-seller" name={t('seller')} onClick={handleCompanyRedirectOnClick(seller.id)}>
                {seller.name}
              </Row>
            )}
            {buyer && (
              <Row dataTestId="status-row-buyer" name={t('buyer')} onClick={handleCompanyRedirectOnClick(buyer.id)}>
                {buyer.name}
              </Row>
            )}
          </>
        )}
        {!isStaffUser && ifBidTimeline?.inIfBidWithCompany && (
          <Row name={t('in_if_bid_with')} onClick={onShowIfBidWith}>
            {ifBidTimeline.inIfBidWithCompany.name}
          </Row>
        )}
        {amountApart && <Row name={t('if_bid_amount_apart')}>{amountApart}</Row>}
        {ifBidTimeline.awaitingMyResponse ? (
          <Row className={style.waitingForMe} description={topOffer?.comment || undefined}>
            <Name>
              {t('current_offer')} - <em className={style.waitingForMe}>{t('awaiting_your_response')}</em>
            </Name>
            {topOffer?.amount?.formattedAmountRounded}
          </Row>
        ) : (
          <Row className={style.waitingForThem} description={topOffer?.comment || undefined}>
            <Name>
              {t('current_offer')} - <em className={style.waitingForThem}>{t('awaiting_their_response')}</em>
            </Name>
            {topOffer?.amount?.formattedAmountRounded}
          </Row>
        )}
        <ReservePriceRow auctionItem={details} user={user} />
        {autoBidAmount && (
          <Row className={style.autobidAmount} name={t('my_autobid')}>
            {autoBidAmount}
          </Row>
        )}
        <Row dataTestId="status-row-if-bids" name={t('if_bids')} onClick={onShowIfBids}>
          {ifBidTimeline.list?.length || 0}
        </Row>
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row name={t('transport_estimate')} onClick={handleTransportEstimateOnClick} />
        {(isStaffUser || isMyItem) && (
          <BidsRow details={details} isStaffUser={isStaffUser} onClick={isAuction ? onShowBids : undefined} />
        )}
        <Row name={t('auction')}>{auctionName}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('date_ran')}>{dateRan}</Row>
        {format === AuctionItemFormat.APPRAISAL && <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>}
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction?.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default withRouter(IfBid);
