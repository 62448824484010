import { Director, View, ViewConnectOptions } from '@millicast/sdk';

import { MillicastViewerBroadcastEvent } from 'io/millicast/millicastViewerManager';

export const handleInitViewConnection = (streamAccountId: string, streamName: string, subscriberToken?: string) => {
  if (!streamAccountId || !streamName) {
    throw new Error('Stream account or stream name not provided.');
  }

  const tokenGenerator = () => Director.getSubscriber({ streamAccountId, streamName, subscriberToken });
  return new View(streamName, tokenGenerator);
};

export const handleConnectToStream = async (millicastView: View | null, options?: ViewConnectOptions) => {
  if (millicastView?.isActive()) {
    return;
  }
  try {
    await millicastView?.connect({
      events: [
        MillicastViewerBroadcastEvent.ACTIVE,
        MillicastViewerBroadcastEvent.INACTIVE,
        MillicastViewerBroadcastEvent.LAYERS,
        MillicastViewerBroadcastEvent.VIEWER_COUNT,
      ],
      absCaptureTime: true,
      ...(options ?? {}),
    });
  } catch (e) {
    millicastView?.reconnect();
  }
};
