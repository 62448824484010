import { memo } from 'react';

import BlackBookValuesItem from 'components/sections/inventoryItem/details/feesAndValues/blackBookValues/blackBookValuesItem';
import MMRValuesItem from 'components/sections/inventoryItem/details/feesAndValues/mmrValues/mmrValuesItem';
import MarketGuideValuesItem, {
  Props as MarketGuideValuesItemProps,
} from 'components/sections/inventoryItem/details/feesAndValues/marketGuideValues/marketGuideValuesItem';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { blackBookValueTypes } from 'utils/formatting/blackBookFormatUtils';
import { isMarketGuideEnabled } from 'utils/featureFlagUtils';
import { mmrValueTypes } from 'utils/formatting/mmrFormatUtils';
import { marketGuideValueTypes } from 'utils/formatting/marketGuideFormatUtils';

export interface Props extends Omit<MarketGuideValuesItemProps, 'values'> {
  /** The inventory item values. */
  inventoryItemValues: InventoryItemValue[] | undefined;
}

/**
 * Show market guide values for Canada and for any inventory item that has market guide values
 */
const isMarketGuideValuesVisible = (
  countryCode: string | undefined,
  inventoryItemValues: InventoryItemValue[] | undefined
) => {
  return (
    isMarketGuideEnabled() &&
    (countryCode === 'CA' || inventoryItemValues?.some((value) => marketGuideValueTypes.includes(value.type.id)))
  );
};

export const isValuesInfoVisible = (
  inventoryItemValues: InventoryItemValue[] | undefined,
  countryCode: string | undefined
): boolean => {
  const hasBlackBookValues = inventoryItemValues?.some((value) => blackBookValueTypes.includes(value.type.id));
  const hasMmrValues = inventoryItemValues?.some((value) => mmrValueTypes.includes(value.type.id));

  return isMarketGuideValuesVisible(countryCode, inventoryItemValues) || !!hasMmrValues || !!hasBlackBookValues;
};

const ValuesInfoItems = ({ inventoryItemValues, ...props }: Props) => (
  <>
    {isMarketGuideValuesVisible(props.countryCode, inventoryItemValues) ? (
      <MarketGuideValuesItem {...props} values={inventoryItemValues} />
    ) : (
      <MMRValuesItem values={inventoryItemValues} />
    )}
    <BlackBookValuesItem values={inventoryItemValues} />
  </>
);

export default memo(ValuesInfoItems);
