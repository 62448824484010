import AuthService from 'store/shared/services/authService';
import { CountryCode } from 'constants/enums/countryCode';
import { FeatureFlag as FeatureFlagEnum } from 'constants/featureFlags';
import { FeatureFlag as FeatureFlagType } from 'store/shared/api/graph/interfaces/types';
import { getEnabledCompanyRelationships, hasCompanyWithOperatingRegions } from 'utils/userUtils';

/**
 * Allows us to override the `featureKey` value of each FeatureFlag with a
 * reference to our internal `FeatureFlagEnum` keys.
 */
export interface FeatureFlag extends Omit<FeatureFlagType, 'featureKey'> {
  featureKey: FeatureFlagEnum;
}

/**
 * An option to override the user's feature flags when showcasing Storybook entries
 *
 * @see setStorybookFeatureFlags
 */
let storybookFeatureFlags: FeatureFlag[];

/**
 * A helper method to set feature flags for Storybook components that require them
 *
 * @see hasFeatureFlag
 * @example
 * // someComponent.stories.tsx
 * setStorybookFeatureFlags([{ featureKey: 'buyNow', defaultValue: true }]);
 * const Template = () => (
 *   <StorybookTemplate>
 *     <Component />
 *   </StorybookTemplate>
 * );
 */
export const setStorybookFeatureFlags = (featureFlags: Partial<FeatureFlag>[]) => {
  storybookFeatureFlags = featureFlags as FeatureFlag[];
};

/**
 * Returns a list of all the available FeatureFlags
 */
export const getFlags = (): FeatureFlag[] => {
  // This is done, so we can utilize our FeatureFlag enums on both services
  return storybookFeatureFlags || (AuthService.user?.featureFlags as FeatureFlag[]);
};

/**
 * Checks if a feature flag is enabled
 *
 * @example: hasFeatureFlag(FeatureFlag.BUY_NOW); // Validates flag at global level
 * @example: hasFeatureFlag(FeatureFlag.BUY_NOW, auction?.auctionFeatureFlagKeys); // Validates flag at global and auction level
 */
export const hasFeatureFlag = (featureFlag: FeatureFlagEnum, auctionFeatureFlagKeys?: string[]): boolean => {
  const flag = getFlags()?.find((ff) => ff.featureKey === featureFlag);

  return Boolean(flag?.defaultValue || (!flag?.defaultValue && auctionFeatureFlagKeys?.includes(featureFlag)));
};

/**
 * Checks if the logged-in user has a feature flag enabled on any of their enabled companyAuctionRelationships.
 * If no auction level flag is detected, refer to default global value.
 *
 * @example: hasAuctionFeatureFlag(FeatureFlag.TIMED_OFFER);
 */
export const hasAuctionFeatureFlag = (featureFlag: FeatureFlagEnum): boolean => {
  const flag = getFlags()?.find((ff) => ff.featureKey === featureFlag);
  return (
    !!getEnabledCompanyRelationships(AuthService.user)?.some(
      (userCompanyRelationship) =>
        !!userCompanyRelationship.company.auctionRelationships?.list.find((companyAuctionRelationship) =>
          companyAuctionRelationship?.auction?.featureFlagKeys?.includes(featureFlag)
        )
    ) || !!flag?.defaultValue
  );
};

// ===============================================================================
// GETTERS =======================================================================

export const isMarketGuideEnabled = () => hasCompanyWithOperatingRegions(AuthService.user, CountryCode.CA);
