import AuctionItem from 'constants/auctionItem';
import User from 'constants/user';
import { AuctionItemFormat } from 'store/shared/api/graph/interfaces/types';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { isAuctionStaff, representsCompany } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** The auction item */
  auctionItem: AuctionItem;
  /** The current user */
  user: User;
}

const BuyNowPriceRow = ({ auctionItem, user }: Props) => {
  const isSeller = representsCompany(user, auctionItem?.inventoryItem?.company?.id);
  const isAuthorized = isSeller || isAuctionStaff(user, auctionItem?.auction?.id);

  if (isAuthorized && auctionItem.format === AuctionItemFormat.TIMED_OFFER) {
    return (
      <Row dataTestId="status-row-buy-now-price" name={t('buy_now_price')}>
        {auctionItem?.buyNowPrice?.formattedAmountRounded ?? '-'}
      </Row>
    );
  }
  return null;
};

export default BuyNowPriceRow;
