import Button from 'components/ui/shared/button';
import { PrimaryTitle, SecondaryTitleLight } from 'layouts/list/listItemLayout';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './429.scss';

const Error429 = () => {
  return (
    <div className={style.container}>
      <div className={style.containerInner}>
        <SpinnerCentered className={style.spinner} />
        <PrimaryTitle className={style.textPrimary}>{t('uh_oh')}</PrimaryTitle>
        <SecondaryTitleLight className={style.textSecondary}>{t('too_many_requests_message')}</SecondaryTitleLight>
        <Button onClick={() => window.location.reload()} theme="blue">
          {t('refresh')}
        </Button>
      </div>
    </div>
  );
};

export default Error429;
