import classnames from 'classnames';

import { AuctionEventTimelineEntry, AuctionEventTimelineEntryStyle } from 'store/shared/api/graph/interfaces/types';

import style from './eventMessage.scss';

interface Props {
  /** The event message. */
  auctionEvent: AuctionEventTimelineEntry;
}

const EventMessage = ({ auctionEvent }: Props) => {
  return (
    <div
      className={classnames(style.row, {
        [style.bold]: auctionEvent?.style === AuctionEventTimelineEntryStyle.STRONG,
      })}
      data-testid="event-message"
    >
      {auctionEvent?.message}
    </div>
  );
};

export default EventMessage;
