import MyBlockStat from 'components/sections/myBlock/stats/myBlockStat';
import { MyBlockSellerResponse } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './myBlockSellerStats.scss';

interface Props {
  /** Seller stats amounts. */
  stats: MyBlockSellerResponse;
}

const MyBlockSellerStats = ({ stats }: Props) => (
  <>
    <div className={style.statGroup}>
      <MyBlockStat color="gray-darker" label={t('ran')} value={stats?.ran} />
      <MyBlockStat color="green-dark" label={t('auction_item_status_sold')} value={stats?.sold} />
      <MyBlockStat color="yellow-dark" label={t('auction_item_status_in_if_bid')} value={stats?.inIfBid} />
    </div>
    <div className={style.statGroup}>
      <MyBlockStat
        className={style.statTotal}
        color="gray-lighter"
        label={t('total_sold_amount')}
        value={stats?.totalSoldAmount?.formattedAmountRounded}
      />
      <MyBlockStat
        className={style.statTotal}
        color="gray-lighter"
        label={t('total_sold_net')}
        tooltip={{
          title: t('total_sold_net_amount'),
          description: t('missing_cost_banner_message'),
        }}
        value={stats?.totalSoldNet?.formattedAmountRounded}
      />
    </div>
  </>
);

export default MyBlockSellerStats;
