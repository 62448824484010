import { List } from 'immutable';

import ServerRecord from 'store/shared/models/serverRecord';
import { FacetGroup as FacetGroupType, FacetedPageInfo, Broadcast } from 'store/shared/api/graph/interfaces/types';

interface BroadcastsModel {
  /** Broadcast facet groups. */
  facetGroups: List<FacetGroupType>;
  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;
  /** List of broadcast. */
  resultList: List<Broadcast>;
}

export const InitialState = ServerRecord<BroadcastsModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});
