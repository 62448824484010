import classNames from 'classnames';
import { useMemo } from 'react';

import { BlueDriverCode, BlueDriverCodeScanEventType } from 'store/shared/api/graph/interfaces/types';
import { Circle } from 'components/ui/shared/shapes';
import { t } from 'utils/intlUtils';

import style from './obdiiScanListItem.scss';

interface Props {
  /** The blue driver code. */
  blueDriverCode: BlueDriverCode;
}

const ObdiiScanListItem = ({ blueDriverCode }: Props) => {
  const codeTypeStyle = useMemo(
    () => `eventType-${blueDriverCode.type && BlueDriverCodeScanEventType[blueDriverCode.type]?.toLowerCase()}`,
    [blueDriverCode.type]
  );

  return (
    <div data-testid="obdii-list-item">
      <div className={style.codeRow}>
        <div>{blueDriverCode.code}</div>
        <div className={classNames(style.eventType, style[codeTypeStyle])}>{blueDriverCode.type}</div>
      </div>
      <div className={style.systemRow}>
        <div>{t('system')}</div>
        <Circle className={style.dot} height="4px" width="4px" />
        <div>{blueDriverCode.system}</div>
      </div>
      <div className={style.description}>{blueDriverCode.description}</div>
    </div>
  );
};

export default ObdiiScanListItem;
