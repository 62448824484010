import classnames from 'classnames';
import { forwardRef, MouseEvent, ReactNode, useCallback } from 'react';

import style from './linkButton.scss';

export type LinkButtonTheme = 'blue' | 'red' | 'white';

export interface LinkButtonProps {
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite the link button style. */
  className?: string;
  /** Set the html `data-testid` attribute. */
  dataTestId?: string;
  /** True when the link button is disabled. */
  disabled?: boolean;
  /** The id of the link button. */
  id?: string;
  /** Callback function to handle the `onClick` event. */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * The link button theme.
   * @default 'blue'
   */
  theme?: LinkButtonTheme;
}

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ children, className, dataTestId, disabled, id, onClick, theme = 'blue', ...props }, ref) => {
    /**
     * On button click event handler.
     */
    const onButtonClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          onClick(event);
        }
      },
      [onClick]
    );

    return (
      <button
        ref={ref}
        className={classnames(style.linkButton, theme && style[`theme-${theme}`], className)}
        data-testid={dataTestId}
        disabled={disabled}
        id={id}
        onClick={onButtonClick}
        type="button"
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default LinkButton;
