import { useCallback } from 'react';

import editGlyph from 'glyphs/edit.svg';

import AuctionItem from 'constants/auctionItem';
import Button from 'components/ui/shared/button';
import CopyToClipboardButton from 'components/ui/shared/buttons/copyToClipboardButton';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import VINBarcode from 'components/ui/shared/vinBarcode';
import { UserAction } from 'logging/analytics/events/userActions';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

import style from './vinDetails.scss';

interface Props {
  /** Auction item details */
  auctionItem?: AuctionItem;
  /** Inventory item details */
  inventoryItem?: InventoryItem;
  /** When true shows edit button */
  isUpdatable?: boolean;
  /** Update button event handler */
  onUpdateButtonClick?: () => void;
  /** VIN number */
  vin: string;
}

const VinDetails = ({ auctionItem, inventoryItem, isUpdatable, onUpdateButtonClick, vin: vinNumber }: Props) => {
  /**
   * Tracks user action.
   */
  const trackUserAction = useCallback(
    (action: UserAction) => {
      if (auctionItem) {
        trackUserActionWithAuctionItemAttributes(action, auctionItem);
      } else {
        trackUserActionWithInventoryItemAttributes(action, inventoryItem);
      }
    },
    [auctionItem, inventoryItem]
  );

  /**
   * Handles copy click.
   */
  const onCopyClick = useCallback(() => {
    trackUserAction(UserAction.VIN_COPY_CLICK);
  }, [trackUserAction]);

  /**
   * Handles edit button click.
   */
  const onEditButtonClick = useCallback(() => {
    trackUserAction(UserAction.VDP_VIN_EDIT_CLICK);
    onUpdateButtonClick?.();
  }, [onUpdateButtonClick, trackUserAction]);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <p className={style.title}>{t('vin')}</p>
          <div className={style.vinNumberContainer}>
            <div className={style.vinNumber}>{vinNumber}</div>
            <CopyToClipboardButton onClick={onCopyClick} textToCopy={vinNumber} />
          </div>
        </div>
        <VINBarcode vin={vinNumber} />
        {isUpdatable && onUpdateButtonClick && (
          <Button dataTestId="vin-update-button" onClick={onEditButtonClick} theme="none">
            <span className={style.editButton} title={t('edit_x', [t('vin')])}>
              <Sprite glyph={editGlyph} />
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default VinDetails;
