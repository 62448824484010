import axios from 'axios';

import ApiRequest, { ApiResponse, getDefaultHeaders } from 'store/shared/api/apiRequest';
import {
  AuthVerificationResponse,
  MutationuserAccountSendVerificationCodeArgs,
  MutationuserAccountVerifyArgs,
  MutationuserVerifyAuthenticationArgs,
} from 'store/shared/api/graph/interfaces/types';
import { getAuthToken } from 'utils/authUtils';
import { getGraphBaseURL } from 'utils/apiUtils';

export const userAccountSendVerificationCode = (options: MutationuserAccountSendVerificationCodeArgs) =>
  ApiRequest<{ userAccountSendVerificationCode: boolean }>({
    data: {
      query: `
        mutation ($userId: String, $verifiedField: String!, $verificationType: UserAccountVerificationType!) {
          userAccountSendVerificationCode(userId: $userId, verifiedField: $verifiedField, verificationType: $verificationType)
        }
      `,
      variables: options,
    },
  });

export const userAccountVerify = (options: MutationuserAccountVerifyArgs) =>
  ApiRequest<{ userAccountVerify: boolean }>({
    data: {
      query: `
        mutation ($code: String!, $verifiedField: String!, $verificationType: UserAccountVerificationType!) {
          userAccountVerify(code: $code, verifiedField: $verifiedField, verificationType: $verificationType) {
            id
          }
        }
      `,
      variables: options,
    },
  });

export const userVerifyAuthentication = (
  options: MutationuserVerifyAuthenticationArgs
): ApiResponse<{ userVerifyAuthentication: AuthVerificationResponse }> => {
  const isLoggedIn = !!getAuthToken();
  const payload = {
    query: `
      mutation (
        $userId: String!,
        $verifiedField: String!,
        $verificationType: UserAccountVerificationType!,
        $verificationCode: String!,
      ) {
        userVerifyAuthentication(
          userId: $userId,
          verifiedField: $verifiedField,
          verificationType: $verificationType,
          verificationCode: $verificationCode,
        ) {
          token
          user {
            id
          }
        }
      }
    `,
    variables: options,
  };

  // If we're logged in, let's use ApiRequest
  if (isLoggedIn) {
    return ApiRequest<{ userVerifyAuthentication: AuthVerificationResponse }>({ data: payload });
  }

  // Otherwise, let's use a self-contained axios instance that doesn't apply our 401 redirect rules when errors occur (e.g. MFA)
  // TODO: Potentially extract this into a reusable unauthenticated api request util
  return axios.post(`${getGraphBaseURL()}/graphql`, payload, { headers: getDefaultHeaders() });
};
