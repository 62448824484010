import classnames from 'classnames';
import { MouseEventHandler, useCallback, useMemo } from 'react';

import topOfferGlyph from 'glyphs/top-offer.svg';

import Sprite from 'components/ui/shared/sprite';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { TransactionType } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { t, tPlural } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './bidsRow.scss';

interface BidsRowProps {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The auction item details. */
  details: AuctionItemDetailsProps;
  /** True when the current user has staff role. */
  isStaffUser?: boolean;
  /** Function invoked on button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const BidsRow = ({ className, details, isStaffUser, onClick }: BidsRowProps) => {
  /**
   * Handles row on click event
   */
  const handleRowOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (onClick) {
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_VIEW_BIDS_CLICK, details);
        onClick(e);
      }
    },
    [details, onClick]
  );

  /**
   * Memoized handler, return null if there is no onClick event defined
   */
  const memoizedHandleRowOnClick = useMemo(() => (onClick ? handleRowOnClick : undefined), [handleRowOnClick, onClick]);

  const isSellingOrBuying = [TransactionType.BUYING, TransactionType.SELLING, TransactionType.ALL].includes(
    details.transactionType
  );

  const theme = !details.reserve || details.reserveMet ? 'green' : 'red';
  if ((isStaffUser || isSellingOrBuying) && Number(details?.bidTimeline?.count) > 0) {
    return (
      <Row
        className={classnames(style.bids, style.bidsRow, style[theme], className)}
        dataTestId="status-row-bids"
        name={t('bids')}
        onClick={memoizedHandleRowOnClick}
      >
        {tPlural('x_bids', details?.bidTimeline?.count, [details?.bidTimeline?.count])}
        <Sprite className={style.topOfferIcon} glyph={topOfferGlyph} />
        <div className={style.topOfferAmount}>{details.topOffer?.amount?.formattedAmountRounded}</div>
      </Row>
    );
  }
  return <Row name={t('bids')}>{t('none')}</Row>;
};

export default BidsRow;
