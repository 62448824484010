import { useCallback, useState } from 'react';
import classnames from 'classnames';

import Button from 'components/ui/shared/button';
import Checkbox from 'forms/shared/checkbox';
import InputText from 'forms/shared/inputText';
import { ErrorMessages } from 'constants/errors';
import { LooseObject } from 'constants/objects';
import { getUrlParams } from 'utils/urlUtils';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';

import style from './saveSearch.scss';

interface Props {
  // TODO: Properly type config.
  /** The configuration information associated to the component. */
  config: LooseObject;

  /** Function that calls an Api action to save a search */
  onSaveSearch: (name: string, isDefault: boolean, params: LooseObject) => void;
}

const SaveSearch = ({ config, onSaveSearch }: Props): JSX.Element => {
  const [searchName, setSearchName] = useState<string>('');
  const [isDefault, setDefault] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);

  const onSaveSearchHandler = useCallback(
    (event) => {
      event.preventDefault();

      const urlParams = getUrlParams();
      const searchParams = Object.keys(urlParams).reduce((params, key) => {
        if (
          config.find((item) =>
            ['dateRange', 'range', 'reserveRange', 'startingBidRange']?.includes(item?.type)
              ? `${item.name}GTE` === key || `${item.name}LTE` === key
              : item.name === key
          )
        ) {
          return { ...params, [key]: urlParams[key] };
        }
        return params;
      }, {});

      if (onSaveSearch) {
        Promise.resolve(onSaveSearch(searchName, isDefault, searchParams))
          .then(() => setSearchName(''))
          .catch((error) => onApiError(error, setErrorMessages));
      }
    },
    [config, isDefault, onSaveSearch, searchName]
  );

  return (
    <form className={style.saveSearchForm} onSubmit={onSaveSearchHandler}>
      <div className={classnames(style.heading, style.saveSearchHeading)}>{t('save_search')}</div>
      {errorMessages &&
        errorMessages.map((error) => (
          <p key={error.message} className={style.errorMessage} data-testid={error.name}>
            {error.message}
          </p>
        ))}
      <InputText
        className={style.searchName}
        dataTestId="save-search-input"
        onChange={(value) => {
          setSearchName(value);
          setErrorMessages([]);
        }}
        placeholder={t('enter_search_name')}
        textClassName={style.searchNameText}
        theme={errorMessages && errorMessages.length ? 'error' : undefined}
      />
      <Checkbox
        checked={isDefault}
        className={style.defaultSearch}
        id="defaultSearch"
        name="defaultSearch"
        onChange={(event) => setDefault(event.target.checked)}
        text={t('make_default_search')}
        theme="blue"
      />
      <Button
        className={style.saveSearch}
        dataTestId="save-search-submit"
        disabled={!searchName.trim()}
        theme="blue"
        type="submit"
      >
        {t('save_search')}
      </Button>
    </form>
  );
};

export default SaveSearch;
