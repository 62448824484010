export const auctionItemOrderFragment = `
  fragment auctionItemOrder on AuctionItemOrder {
    id
    autoCheckedOut
    buyerPickupLocation {
      id
      name
    }
    checkedOut
    comment
    lineItems(includeNotSelected: true) {
      id
      amount {
        amount
        formattedAmount
      }
      auctionService {
        id
        serviceMetadata {
          defaultSelected
          description
          feeName
          logicalName
          serviceName
          transportService
        }
      }
      inventoryService {
        id
        serviceMetadata {
          defaultSelected
          description
          feeName
          logicalName
          serviceName
          transportService
        }
      }
      optional
      selected
      taxable
    }
    paymentType {
      id
      defaultType
      logicalName
      name
    }
    subtotal {
      amount
      formattedAmount
    }
    taxes {
      amount
      formattedAmount
    }
    total {
      amount
      formattedAmount
    }
    totalBuyCredit {
      amount
      formattedAmount
    }
  }
`;
