import { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';
import { isEmpty } from 'lodash-es';

import logoBlackGlyph from 'glyphs/logo-black.svg';
import logoWhiteGlyph from 'glyphs/logo-white.svg';
import EBlockLogo from 'glyphs/logo-icon.svg';

import Sprite from 'components/ui/shared/sprite';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './logo.scss';

const logoGlyphLoader = (theme) => {
  if (process.env.PRIVATE_LABEL) {
    const logoFileNameAuth = process.env.REACT_APP_PRIVATE_LABEL_LOGO_FILE_NAME_AUTH;
    const logoFileNameNav = process.env.REACT_APP_PRIVATE_LABEL_LOGO_FILE_NAME_NAV;
    const fileName = theme === 'auth' && !isEmpty(logoFileNameAuth) ? logoFileNameAuth : logoFileNameNav;

    return import(/* WebpackMode: "eager" */ `../../../../privateLabels/${process.env.PRIVATE_LABEL}/${fileName}`);
  }

  let glyph = theme === 'default' ? logoWhiteGlyph : logoBlackGlyph;
  if (theme === 'auth') {
    glyph = EBlockLogo;
  }

  return Promise.resolve({ default: glyph });
};

const logoStyleLoader = async () => {
  if (process.env.PRIVATE_LABEL) {
    const privateLabelStyle = await import(
      /* WebpackMode: "eager" */ `../../../../privateLabels/${process.env.PRIVATE_LABEL}/logo.scss`
    );
    return Promise.resolve(privateLabelStyle?.default);
  }
  return Promise.resolve(style);
};

interface Props {
  className?: string;
  isLarge?: boolean; // TODO: Let's remove this, and instead rely on passing classnames
  nonClickable?: boolean; // TODO: Change name...
  spriteClassName?: string;
  theme?: 'default' | 'auth';
}

const Logo = ({ className, isLarge, nonClickable, spriteClassName, theme = 'default' }: Props) => {
  const [logoGlyph, setLogoGlyph] = useState<any>({ id: '' });
  const [spriteClass, setSpriteClass] = useState<string>();

  useMountEffect(() => {
    const init = async () => {
      const loadedGlyph = await logoGlyphLoader(theme);
      const loadedSpriteStyle = await logoStyleLoader();
      const loadedSpriteClass = loadedSpriteStyle?.[isLarge ? 'spriteLarge' : 'sprite'];

      setSpriteClass(loadedSpriteClass);
      setLogoGlyph(loadedGlyph);
    };

    // Fetch logo glyph and styles (if applicable)
    init();
  });

  if (nonClickable) {
    return (
      <div className={className}>
        <Sprite className={classnames(spriteClassName, spriteClass)} glyph={logoGlyph?.default} />
      </div>
    );
  }

  return (
    <Link className={className} data-testid="navigation-logo" to="/">
      <Sprite className={classnames(spriteClassName, spriteClass)} glyph={logoGlyph?.default} />
    </Link>
  );
};

export default Logo;
