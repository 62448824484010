import invariant from 'invariant';
import { combineReducers } from 'redux';

import { addModifyInventoryItemReducer } from './inventoryItem/addModify/addModifyReducer';
import { adminReducer } from './admin/adminReducer';
import { auctionItemDetailsReducer } from './auctionItemDetails/auctionItemDetailsReducer';
import { auctionItemsReducer } from './auctionItemsList/auctionItemsReducer';
import { auctionSubmissionReducer } from './auctionSubmission/auctionSubmissionReducer';
import { authReducer } from './auth/authReducer';
import { chatReducer } from 'store/chat/chatReducer';
import { checkoutReducer } from 'store/checkout/checkoutReducer';
import { homeDashboardReducer } from './homeDashboard/homeDashboardReducer';
import { homeDashboardSearchReducer } from './homeDashboard/search/homeDashboardSearchReducer';
import { inventoryItemDetailsReducer } from './inventoryItemDetails/inventoryItemDetailsReducer';
import { inventoryItemsListReducer } from './inventoryItemsList/inventoryItemsListReducer';
import { liveLanesReducer } from './liveLanes/liveLanesReducer';
import { marketGuideReducer } from 'store/marketGuide/marketGuideReducer';
import { myBlockReducer } from 'store/myBlock/list/myBlockReducer';
import { notesReducer } from './notes/notesReducer';
import { pageTitleReducer } from './pageTitle/pageTitleReducer';
import { reportsReducer } from './reports/reportsReducer';
import { routingReducer } from './routing/routingReducer';
import { searchReducer } from './search/searchReducer';
import { systemReducer } from './system/systemReducer';
import { userReducer } from './user/userReducer';

invariant(addModifyInventoryItemReducer, 'addModifyInventoryItem Reducer is missing!');
invariant(adminReducer, 'Admin Reducer is missing!');
invariant(auctionItemDetailsReducer, 'AuctionItemDetails Reducer is missing!');
invariant(auctionItemsReducer, 'AuctionItems Reducer is missing!');
invariant(auctionSubmissionReducer, 'auctionSubmission Reducer is missing!');
invariant(authReducer, 'Auth Reducer is missing!');
invariant(chatReducer, 'Chat Reducer is missing!');
invariant(checkoutReducer, 'Checkout Reducer is missing!');
invariant(homeDashboardReducer, 'homeDashboard Reducer is missing!');
invariant(homeDashboardSearchReducer, 'homeDashboardSearch Reducer is missing!');
invariant(inventoryItemDetailsReducer, 'InventoryItemDetails Reducer is missing!');
invariant(inventoryItemsListReducer, 'InventoryItemsList Reducer is missing!');
invariant(liveLanesReducer, 'LiveLanes Reducer is missing!');
invariant(pageTitleReducer, 'PageTitle Reducer is missing!');
invariant(reportsReducer, 'Reports reducer is missing!');
invariant(routingReducer, 'Routing Reducer is missing!');
invariant(searchReducer, 'Search Reducer is missing!');
invariant(systemReducer, 'System Reducer is missing!');
invariant(userReducer, 'User Reducer is missing!');

// Add any new reducer you create to this map!
const reducerMap = {
  admin: adminReducer,
  auctionItemDetails: auctionItemDetailsReducer,
  auctionItems: auctionItemsReducer,
  auctionSubmission: auctionSubmissionReducer,
  auth: authReducer,
  chat: chatReducer,
  checkout: checkoutReducer,
  homeDashboard: homeDashboardReducer,
  inventoryItem: addModifyInventoryItemReducer,
  inventoryItemDetails: inventoryItemDetailsReducer,
  inventoryItems: inventoryItemsListReducer, // Add/Modify
  liveLanes: liveLanesReducer,
  marketGuide: marketGuideReducer,
  myBlock: myBlockReducer,
  notes: notesReducer,
  pageTitle: pageTitleReducer,
  reports: reportsReducer,
  routes: routingReducer,
  search: searchReducer,
  searchItems: homeDashboardSearchReducer,
  system: systemReducer,
  user: userReducer,
};

const rootReducer = combineReducers(reducerMap);

export default rootReducer;
