import { Link } from 'react-router';

import checkThinGlyph from 'glyphs/check-thin.svg';

import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { FormDialogBody, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { Location } from 'constants/reactRouter';
import { t } from 'utils/intlUtils';

import style from './successfulSubmission.scss';

interface Props {
  /** The current url information. */
  location: Location;
  /** The view vehicle link url. */
  urlPath: string;
}

const SuccessfulSubmission = ({ urlPath = '', location: { pathname, query } }: Props) => {
  const getNameByLocation = () => {
    switch (pathname) {
      case '/sell/parked':
        return t('parked');
      case '/finished/no-sale':
        return t('auction_item_status_no_sale');
      case '/finished/cancelled':
        return t('auction_item_status_sale_cancelled');
    }
    return '';
  };

  return (
    <FormDialogBody>
      <FormSection flexDirection="column">
        <div className={style.successContainer} data-testid="success-message">
          <div className={style.spriteCircle}>
            <Sprite className={style.sprite} glyph={checkThinGlyph} />
          </div>
          <h4>{t('your_vehicle_was_submitted_successfully')}</h4>
        </div>
        <Link className={style.links} to={urlPath}>
          <div data-testid="viewVehicleLink">{t('view_vehicle')}</div>
          <Chevron />
        </Link>
        <Link className={style.links} to={{ pathname: `${pathname}`, query: { ...query, id: null } }}>
          <div>{t('view_x_vehicles', [getNameByLocation()])}</div>
          <Chevron />
        </Link>
      </FormSection>
    </FormDialogBody>
  );
};

export default SuccessfulSubmission;
