import { useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';

import trashGlyph from 'glyphs/trash.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { AuctionItemSavedFilter } from 'store/shared/api/graph/interfaces/types';
import { Location } from 'constants/reactRouter';
import { LooseObject } from 'constants/objects';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './savedSearches.scss';

interface Props {
  /** The current url without any search params */
  clearUrl: string;

  // TODO: Properly type config.
  /** The configuration information associated to the component. */
  config: LooseObject;

  /** The user's saved searches **/
  savedSearches: AuctionItemSavedFilter[];

  /** The id of the currently applied saved search */
  currentSavedSearchId: string | undefined;

  /** Click handler that is invoked on the deletion of a saved search */
  onSavedSearchDelete: (id: string) => void;

  /** The current url information. */
  location: Location;
}

export const getSavedSearchUrl = (
  savedSearch: AuctionItemSavedFilter,
  config: LooseObject,
  location: Location<any>
) => {
  const params = { ...getUrlParams(), savedSearchId: savedSearch.id };
  const rangeTypes = ['dateRange', 'range', 'reserveRange', 'startingBidRange'];

  config.forEach((configItem) => {
    const names = rangeTypes.includes(configItem?.type)
      ? [`${configItem.name}GTE`, `${configItem.name}LTE`]
      : [configItem.name];

    names.forEach((name) => {
      delete params[name];

      if (savedSearch[name]) {
        params[name] = savedSearch[name];
      }
    });
  });

  const after = 'after';
  delete params[after];
  return `${location.pathname}?${paramsToQueryString(params)}`;
};

const SavedSearches = ({
  clearUrl,
  config,
  savedSearches,
  currentSavedSearchId,
  onSavedSearchDelete,
  location,
}: Props): JSX.Element => {
  const toLink = useCallback(
    (savedSearch: AuctionItemSavedFilter) => {
      return currentSavedSearchId === savedSearch.id ? clearUrl : getSavedSearchUrl(savedSearch, config, location);
    },
    [currentSavedSearchId, clearUrl, location, config]
  );

  return (
    <div className={style.savedSearches} data-testid="saved-searches">
      <div className={style.heading}>{t('saved_searches')}</div>
      <ul>
        {savedSearches.map((savedSearch) => (
          <li key={savedSearch.id} className={style.savedSearch}>
            <Link className={style.name} data-testid={`${savedSearch.id}-link`} to={toLink(savedSearch)}>
              <div
                className={classnames(style.radio, currentSavedSearchId === savedSearch.id && style.selected)}
                data-testid={`${savedSearch.id}-radio`}
              />
              {savedSearch.name}
            </Link>
            <Button
              className={style.trashButton}
              dataTestId={`${savedSearch.id}-delete-button`}
              onClick={() => onSavedSearchDelete?.(savedSearch.id)}
              theme="none"
            >
              <Sprite className={style.trash} glyph={trashGlyph} />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SavedSearches;
