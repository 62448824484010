import {
  configure as configureWS,
  start as startWS,
  stop as stopWS,
  onMessage as onMessageWS,
} from 'io/pubsub/websockets';

export interface PubSubSubscription {
  /** Cancel an active subscription */
  cancel: () => void;
}

export const Types = {
  string: (val) => typeof val === 'string',
};

/**
 * Determines whether val contains q. For val to contain q, the intersection
 * of val and q must be equal to q.
 */
export const contains = (val, q, payload = val) => {
  let match = true;

  if (q instanceof Array && val instanceof Array) {
    if (q.length <= val.length) {
      for (let i = 0; match && i < q.length; i += 1) {
        match = match && contains(val[i], q[i], payload);
      }
    } else {
      match = false;
    }
  } else if ((q instanceof Array && typeof val === 'object') || (typeof q === 'object' && typeof val === 'object')) {
    if (q?.length) {
      // Allows for multi event query types
      // Check if one of the queries contains the event payload
      match = q?.some?.((_q) => contains(val, _q));
    } else {
      Object.keys(q).forEach((k) => {
        match = match && contains(val[k], q[k], payload);
      });
    }
  } else if (typeof q === 'function') {
    match = q(val, payload);
  } else {
    match = q === val;
  }

  return match;
};

/**
 * Configure all PubSub services
 */
export const configure = (channels: string[]) => {
  configureWS(channels);
};

/**
 * Start all PubSub services
 */
export const start = () => {
  startWS();
};

/**
 * Stop all PubSub services
 */
export const stop = (cb?: (...args: any) => void) => {
  stopWS(cb);
};

/**
 * Subscribe to incoming messages on all PubSub services.
 * The `onMessage` format is structured around the original PubNub configuration.
 * In the websocket sense, messages cover all incoming payload types.
 */
export const onMessage = (query: Record<string, any>, fn: (...args: any) => void): PubSubSubscription => {
  const { cancel: cancelWS } = onMessageWS(query, fn);

  return {
    cancel() {
      cancelWS();
    },
  };
};
