import EditButton from 'components/ui/shared/buttons/editButton';
import InventoryItem from 'constants/inventoryItem';
import { Row, Rows, RowsContainer } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatDate } from 'utils/dateUtils';
import { t } from 'utils/intlUtils';

import style from './lastKnownMileageRow.scss';

interface Props {
  /** The className to overwrite default styles */
  className?: string;
  /** The inventoryItem currently under auction */
  inventoryItem?: InventoryItem;
  /** Callback function to edit the last known mileage. */
  onEdit?: () => void;
}

const LastKnownMileageRow = ({ className, inventoryItem, onEdit }: Props) => {
  const lastKnownMileage = inventoryItem?.lastKnownMileage;
  const lastKnownMileageDate = inventoryItem?.lastKnownMileageDate;

  if (!lastKnownMileage) {
    return null;
  }

  return (
    <RowsContainer className={className}>
      <Rows>
        <Row name={t('last_known_mileage')}>
          <div className={style.content}>
            {lastKnownMileage?.formattedAmount || '-'}
            {inventoryItem?.isUpdatable && (
              <EditButton className={style.editButton} onClick={onEdit} theme="blue-pencil" />
            )}
          </div>
        </Row>
        <Row name={t('last_known_mileage_date')}>
          <div className={style.content}>
            {formatDate(lastKnownMileageDate, undefined, false) || '-'}
            {inventoryItem?.isUpdatable && (
              <EditButton className={style.editButton} onClick={onEdit} theme="blue-pencil" />
            )}
          </div>
        </Row>
      </Rows>
    </RowsContainer>
  );
};

export default LastKnownMileageRow;
