import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { onMessage, Types } from 'io/pubsub/pubsub';

export const onLiveAuctionItemCreated = (fn) => {
  return onMessage(
    {
      type: 'AuctionItemCreatedEvent',
      status: 'LIVE',
    },
    fn
  );
};

export const onAuctionItemUpdated = (status: AuctionItemStatus | undefined, fn) => {
  return onMessage(
    {
      type: 'AuctionItemUpdatedEvent',
      status: status || Types.string,
    },
    fn
  );
};

export const onLiveAuctionItemEnded = (fn) => {
  return onMessage(
    [
      // We can assume the item as ended when either of the following are fired:
      {
        type: 'AuctionItemEndedEvent',
        status: 'LIVE',
      },
      {
        type: 'AuctionItemBuyNowEndedEvent',
      },
    ],
    fn
  );
};

export const onLiveAuctionItemTimelineEvent = (fn) => {
  return onMessage(
    {
      type: 'AuctionItemTimelineEvent',
    },
    fn
  );
};
