import moment from 'moment';
import { createAction } from 'redux-actions';

import { getCompanyReportGroups, getCompanyReport, getCompany } from './reportsApi';
import { downloadAttachment, getErrors } from 'utils/apiUtils';
import { mimeTypes } from 'constants/mimeTypes';
import { ReportOptions } from 'constants/enums/reports';

export const consignerReportGroupsUpdating = createAction('REPORTS_UPDATING');
export const consignerReportGroupsLoaded = createAction('REPORTS_LOADED');
export const consignerReportGroupsCleared = createAction('REPORTS_CLEARED');
export const consignerReportLoading = createAction('REPORTS_REPORT_LOADING');
export const consignerReportUpdating = createAction('REPORTS_REPORT_UPDATING');
export const consignerReportLoaded = createAction('REPORTS_REPORT_LOADED');
export const consignerReportError = createAction('REPORTS_REPORT_ERROR');
export const consignerReportCleared = createAction('REPORTS_REPORT_CLEARED');
export const consignerLoaded = createAction('REPORTS_CONSIGNER_LOADED');

export const processGetConsigner = async (options, dispatch) => {
  const company = (await getCompany(options))?.data?.data?.company;
  dispatch(consignerLoaded(company));
};

export const processGetConsignerReportGroups = async (options, dispatch) => {
  dispatch(consignerReportGroupsUpdating());
  return getCompanyReportGroups(options)
    .then((response) => dispatch(consignerReportGroupsLoaded(response?.data?.data?.company?.reportGroups)))
    .catch(() => dispatch(consignerReportGroupsLoaded(null)));
};

export const processGetConsignerReport = async (options, dispatch) => {
  try {
    dispatch(consignerReportLoading());
    const report = await getCompanyReport(options);
    dispatch(consignerReportLoaded(report));
  } catch (err) {
    dispatch(consignerReportError(getErrors(err)));
  }
};

export const processUpdateConsignerReport = async (options, dispatch) => {
  try {
    dispatch(consignerReportUpdating());
    const report = await getCompanyReport(options);
    dispatch(consignerReportLoaded(report));
  } catch (err) {
    dispatch(consignerReportError(getErrors(err)));
  }
};

export const processDownloadConsignerReport = async ({
  [ReportOptions.DOWNLOAD_ATTACHMENT_NAME]: name,
  ...options
}) => {
  let report: Blob | string | undefined = await getCompanyReport(options as any);

  if (options.fileType === 'json') {
    report = JSON.stringify(report);
  }

  const reportName = `EBlockReport-${name}_${moment().format('YYYY_MM_DD')}.${options.fileType}`;
  const reportMimeType = mimeTypes[options.fileType];
  return downloadAttachment(report, reportName, reportMimeType);
};
