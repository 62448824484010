import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { InitialState, Results } from './searchModels';
import { clearSearch, searchIsUpdating, searchLoaded } from './searchActions';

export const searchReducer = handleActions(
  {
    [searchIsUpdating().type]: (state, action) => state.set('isUpdating', action.payload),

    [searchLoaded().type]: (state, action) => {
      const results = action.payload;
      const resultsNext = {};

      Object.keys(results).forEach((type) => {
        resultsNext[type] = List(results[type].edges.map((edge) => edge.node));
      });

      return state.setLoaded().set('results', Results(resultsNext));
    },

    [clearSearch().type]: () => new InitialState(),
  },
  new InitialState()
);
