import { connect, ConnectedProps } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { useCallback } from 'react';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { AppDispatch, AppState } from 'store/configureStore';
import { Route } from 'store/routing/routes';
import { setInteractionTimeout as setInteractionIdleTimeout } from 'store/system/systemActions';
import { t } from 'utils/intlUtils';
import { useLocation } from 'constants/reactRouter';

import style from './systemTimeout.scss';

const stateConnect = (state: AppState) => ({
  /** Whether PubSub is active or not */
  isPubSubActive: state.app.system.isPubSubActive,
  /** Whether application has timed out due to lack of interactivity */
  isTimedOut: state.app.system.isTimedOut,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function to set the interaction timeout state */
  setInteractionTimeout: () => dispatch(setInteractionIdleTimeout()),
});

const connector = connect(stateConnect, dispatchConnect);

type Props = ConnectedProps<typeof connector>;

const SystemTimeout = ({ isPubSubActive, isTimedOut, setInteractionTimeout }: Props) => {
  const { pathname } = useLocation();

  /**
   * Disables PubSub activity (if active) and displays a timeout dialog requesting
   * the user to refresh the page in-order to re-subscribe to incoming activity.
   */
  const onSystemTimeout = useCallback(() => {
    // Prevent timeout within Live Auction, Auctioneer, Marquee screens
    const isExcludedTimeoutRoute = [
      Route.BUY_LIVE_AUCTION,
      Route.ADMIN_LIVE_LANES_AUCTIONEER,
      Route.ADMIN_LIVE_LANES_MARQUEE,
    ].includes(pathname as any);

    if (isPubSubActive && !isExcludedTimeoutRoute) {
      setInteractionTimeout();
    }
  }, [isPubSubActive, pathname, setInteractionTimeout]);

  // Subscribe to idling presence (triggered after 30 mins)
  useIdleTimer({ timeout: 1000 * 60 * 30, onIdle: onSystemTimeout });

  if (!isTimedOut || process.env.NODE_ENV === 'development') {
    return null;
  }

  return (
    <ConfirmDialog
      bodyClassName={style.message}
      isOpen
      onClose={() => window.location.reload()}
      onConfirm={() => window.location.reload()}
      theme="blue"
      title={t('session_timeout')}
    >
      <p>{t('session_timeout_message')}</p>
    </ConfirmDialog>
  );
};

export default connector(SystemTimeout);
