import classnames from 'classnames';

import Button, { ButtonProps as AuthButtonProps } from 'components/ui/shared/button';

import style from './auth.scss';

export const PrimaryAuthButton = ({ children, className, ...props }: AuthButtonProps) => (
  <Button className={classnames(style.primaryAuthButton, className)} theme="black" {...props}>
    {children}
  </Button>
);

export const SecondaryAuthButton = ({ children, className, ...props }: AuthButtonProps) => (
  <Button className={classnames(style.secondaryAuthButton, className)} theme="white" {...props}>
    {children}
  </Button>
);

export const SubmitButton = ({ children, ...props }: AuthButtonProps) => (
  <PrimaryAuthButton type="submit" {...props}>
    {children}
  </PrimaryAuthButton>
);
