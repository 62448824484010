import classnames from 'classnames';
import { MouseEventHandler, useCallback } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { TireGeneralCondition, VehicleTireCondition } from 'store/shared/api/graph/interfaces/types';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { convertEnumToString } from 'utils/stringUtils';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './conditionReport.scss';

interface Props {
  /** Vehicle capture type. */
  captureType: InventoryItem['captureType'];
  /** Callback function to handle feature click event. */
  handleFeatureClicked: (
    featureType: string,
    inventoryItemDetails?: VehicleTireCondition & { isVerified: boolean }
  ) => void;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal: (feature: VehicleFeature) => void;
  /** True when inventory item details are editable. */
  isUpdatable: boolean;
  /** Function invoked on tire button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** The tire condition. */
  tireCondition: InventoryItem['tireCondition'];
}

const NonVerifiedTireButton = ({
  captureType,
  handleFeatureClicked,
  handleOpenAddModifyModal,
  isUpdatable,
  onClick,
  tireCondition,
}: Props) => {
  const generalCondition = tireCondition?.generalCondition;
  const { generalCondition: generalTireCondition } = vehicleFeatures;

  /**
   * Click handler for non verified tire button
   **/
  const onButtonClick = useCallback(
    (event) => {
      isUpdatable
        ? handleOpenAddModifyModal(generalTireCondition)
        : handleFeatureClicked('TIRES', { ...tireCondition, isVerified: isVerified(captureType) });
      onClick?.(event);
    },
    [
      captureType,
      generalTireCondition,
      handleFeatureClicked,
      handleOpenAddModifyModal,
      isUpdatable,
      onClick,
      tireCondition,
    ]
  );

  return (
    <Button
      className={classnames(style.crButton, isUpdatable && style.crButtonUpdatable)}
      dataTestId="non-verified-tire-condition-button"
      onClick={onButtonClick}
      theme="none"
    >
      <div>
        <span
          className={classnames(
            style.crButtonText,
            style.tireCondition,
            generalCondition === TireGeneralCondition.POOR && style.red,
            generalCondition === TireGeneralCondition.AVERAGE && style.yellow,
            generalCondition === TireGeneralCondition.GOOD && style.green,
            !generalCondition && style.black
          )}
          data-testid="tire-condition"
        >
          {convertEnumToString(generalCondition) || '-'}
        </span>
        <span className={style.crButtonSubText}>{t('tires')}</span>
        {isUpdatable && <Sprite className={style.crEditGlyph} glyph={editGlyph} />}
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default NonVerifiedTireButton;
