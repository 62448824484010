import { isNil } from 'lodash-es';

import { t } from 'utils/intlUtils';

import styleVariables from 'styles/variables.scss';

export enum BooleanTextType {
  ON_OFF = 'ON_OFF',
  YES_NO = 'YES_NO',
}

const BooleanTextTypeValues = {
  [BooleanTextType.ON_OFF]: ['on', 'off'],
  [BooleanTextType.YES_NO]: ['yes', 'no'],
};

const BooleanTextTypeColors = {
  [BooleanTextType.ON_OFF]: [styleVariables.colorGreen, styleVariables.colorRed],
  [BooleanTextType.YES_NO]: [styleVariables.colorGreen, styleVariables.colorRed],
};

interface BooleanTextProps {
  isColorized?: boolean;
  type?: BooleanTextType;
  value?: boolean | null;
}

const BooleanText = ({ isColorized = false, type = BooleanTextType.YES_NO, value }: BooleanTextProps) => {
  const text = isNil(value) ? t('unknown') : t(BooleanTextTypeValues[type][value ? 0 : 1]);
  const color = t(BooleanTextTypeColors[type][value ? 0 : 1]);

  if (isColorized) {
    return <span style={{ color }}>{text}</span>;
  }
  return <span>{text}</span>;
};

export default BooleanText;
