import { ReactNode } from 'react';

import Button from 'components/ui/shared/button';
import DialogContent from 'layouts/inventoryItemLayouts/addModify/dialogContentLayouts';
import { t } from 'utils/intlUtils';

import style from './confirmDialog.scss';

interface Props {
  /** Children component to be rendered. */
  children: ReactNode;
  /**
   * True renders ok and cancel buttons.
   * False renders go back, close without saving, and save and close buttons.
   */
  isConfirmButtons?: boolean;
  /** Callback function to close without saving. */
  onCloseWithoutSaving?: () => void;
  /** Callback function to confirm. */
  onConfirm: (isConfirm: boolean) => void;
  /** Title to be rendered. */
  title: string;
}

const ConfirmDialog = ({
  children,
  isConfirmButtons = false,
  onCloseWithoutSaving = () => {},
  onConfirm,
  title,
}: Props) => {
  if (isConfirmButtons) {
    return (
      <DialogContent className={style.confirmOverlay}>
        <h4>{title}</h4>
        {children}
        <div className={style.buttonContainer}>
          <Button className={style.button} onClick={() => onConfirm(false)} theme="gray-outline">
            {t('cancel')}
          </Button>
          <Button className={style.button} onClick={() => onConfirm(true)} theme="green">
            {t('ok')}
          </Button>
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent className={style.confirmOverlay}>
      <h4>{title}</h4>
      {children}
      <div className={style.buttonContainer}>
        <Button className={style.button} onClick={() => onConfirm(false)} theme="gray-outline">
          {t('go_back')}
        </Button>
        <Button className={style.button} onClick={onCloseWithoutSaving} theme="gray-outline">
          {t('close_without_saving')}
        </Button>
        <Button onClick={() => onConfirm(true)} theme="green">
          {t('save_and_close')}
        </Button>
      </div>
    </DialogContent>
  );
};

export default ConfirmDialog;
