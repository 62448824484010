import { t } from 'utils/intlUtils';

/**
 * Convert given data URL to binary blob
 * @param dataURL data url
 * @param fileType file type
 * @returns binary {Blob}
 */
export const convertDataUrlToBinary = (dataURL: string, fileType: string): Blob => {
  const binary = window.atob(dataURL.split(',')[1]);
  const array: number[] = [];
  for (const char of binary) {
    array.push(char.charCodeAt(0));
  }
  return new Blob([new Uint8Array(array)], { type: fileType });
};

/**
 * Read file as data URL
 * @param file file to be read
 * @returns dataURL {Promise<string>}
 */
export const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result && typeof e.target.result === 'string') {
        // Result of `readAsDataURL` should be a string
        resolve(e.target.result);
      } else {
        // Unexpected result loaded.
        reject(t('unable_to_load_file_x', [file.name]));
      }
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
};
