import { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';

import DatePicker from 'forms/shared/datePicker';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './dateRangeFacetGroup.scss';

export interface Props extends RouterProps {
  /** The name of the component. */
  name: string;

  /** The label of the component. */
  label: string;

  /** True if the given date should be in UTC. */
  isUTC: boolean;

  /** The format of the date url param. */
  format: string | undefined;

  /** A function to further restrict which days can be selected on the calendar. */
  isOutsideRange?: (date: Moment) => boolean;
}

const DateRangeFacetGroup = ({ name, label, router, location, isUTC = true, format, isOutsideRange }: Props) => {
  const [isDateGTEFocused, setDateGTEFocused] = useState<boolean>(false);
  const [isDateLTEFocused, setDateLTEFocused] = useState<boolean>(false);
  const params = getUrlParams();
  const labelGTE = `${name}GTE`;
  const labelLTE = `${name}LTE`;
  const dateGTE = params[labelGTE] ? moment(params[labelGTE]) : null;
  const dateLTE = params[labelLTE] ? moment(params[labelLTE]) : null;

  /**
   * Updates the URL with date range values.
   * If format is not provided, then date will be formatted to ISO standard.
   */
  const onDateChange = useCallback(
    (id: string, date: Moment | null) => {
      const urlParams = { ...getUrlParams() };
      if (date) {
        // Convert to start or end of day.
        let startOrEndOfDay = id?.includes('GTE') ? date.startOf('day') : date.endOf('day');

        if (isUTC) {
          startOrEndOfDay = moment.utc(startOrEndOfDay);
        }

        urlParams[id] = format ? startOrEndOfDay.format(format) : startOrEndOfDay.toISOString();
      } else {
        delete urlParams[id];
      }
      router.push(`${location.pathname}?${paramsToQueryString(urlParams)}`);
    },
    [isUTC, format, location.pathname, router]
  );

  return (
    <div className={style.dateRangeFacetGroup} data-testid="dateRangeFacetGroup">
      <div className={style.heading}>{label}</div>
      <div className={style.dateRanges}>
        <DatePicker
          date={dateGTE}
          daySize={25}
          displayFormat="MM/DD"
          focused={isDateGTEFocused}
          hideKeyboardShortcutsPanel
          id={labelGTE}
          isOutsideRange={isOutsideRange || ((day) => day.isAfter(moment()) || day.isAfter(dateLTE))}
          numberOfMonths={1}
          onDateChange={(_dateGTE) => onDateChange(labelGTE, _dateGTE)}
          onFocusChange={({ focused }) => setDateGTEFocused(focused)}
          placeholder={t('any')}
        />
        <span>{t('to')}</span>
        <DatePicker
          date={dateLTE}
          daySize={25}
          displayFormat="MM/DD"
          focused={isDateLTEFocused}
          hideKeyboardShortcutsPanel
          id={labelLTE}
          isOutsideRange={isOutsideRange || ((day) => day.isAfter(moment()) || day.isBefore(dateGTE))}
          numberOfMonths={1}
          onDateChange={(_dateLTE) => onDateChange(labelLTE, _dateLTE)}
          onFocusChange={({ focused }) => setDateLTEFocused(focused)}
          placeholder={t('any')}
        />
      </div>
    </div>
  );
};

export default withRouter(DateRangeFacetGroup);
