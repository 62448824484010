import classnames from 'classnames';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Spinner } from 'components/ui/loading/loading';

import style from './image.scss';

interface Props {
  /** Function invoked after image has loaded.  */
  afterOnLoad?: () => void;

  /** Alternative text for the image. */
  alt: string;

  /** Elements rendered as children.  */
  children?: ReactNode;

  /** CSS styling to overwrite default image style. */
  className?: string;

  /** CSS styling to overwrite default container style. */
  containerClassName?: string;

  /** Test id used for testing component. */
  dataTestId?: string;

  /** True when the spinner is disabled. */
  disableSpinner?: boolean;

  /** Component rendered when image has errored. */
  placeholder?: ReactNode;

  /** CSS styling to overwrite default spinner style. */
  spinnerClassName?: string;

  /** Location path of image source. */
  src: string;
}

/**
 * An image wrapper to help avoid content-jumping when loading multiple images simultaneously.
 * While loading, the child element or the spinner component is displayed.
 */
const Image = ({
  alt,
  children,
  className,
  containerClassName,
  dataTestId,
  disableSpinner,
  afterOnLoad,
  placeholder,
  spinnerClassName,
  src,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(false);

    if (src) {
      const image = new window.Image(); // Let's not reference this class
      image.onload = () => setIsLoaded(true);
      image.onerror = () => setHasError(true);
      image.src = src;

      if (afterOnLoad) {
        afterOnLoad();
      }
    }
  }, [afterOnLoad, src]);

  if (hasError) {
    return placeholder as ReactElement;
  }

  return (
    <div className={classnames(style.container, containerClassName)} data-testid={dataTestId || 'image'}>
      {!isLoaded && children}
      {!disableSpinner && !isLoaded && !children && <Spinner className={classnames(style.spinner, spinnerClassName)} />}
      {isLoaded && <img alt={alt} className={classnames(style.img, className)} src={src} />}
    </div>
  );
};

export default Image;
