import classnames from 'classnames';

import AccountVerificationIntro from './steps/accountVerificationIntro';
import AccountVerificationSuccess from './steps/accountVerifcationSuccess';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import EmailVerificationCodeInput from './steps/emailVerificationCodeInput';
import EmailVerificationEmailInput from './steps/emailVerificationEmailInput';
import LinkButton from 'components/ui/shared/buttons/linkButton';
import MobileVerificationCodeInput from './steps/mobileVerificationCodeInput';
import MobileVerificationPhoneInput from './steps/mobileVerificationPhoneInput';
import MobileVerificationSuccess from './steps/mobileVerificationSuccess';
import StepsProgress from 'components/ui/progressBars/stepsProgress';
import {
  AccountVerificationFormProps,
  AccountVerificationFormStep,
} from 'forms/account/accountVerification/steps/accountVerificationHelpers';
import { t } from 'utils/intlUtils';
import { useFormContent } from 'forms/account/accountVerification/steps/hooks/useFormContent';
import { useFormState } from 'forms/account/accountVerification/steps/hooks/useFormState';
import { useIsFormSubmittable } from 'forms/account/accountVerification/steps/hooks/useIsFormSubmittable';
import { useNavigation } from 'forms/account/accountVerification/steps/hooks/useNavigation';
import { useOnRequestClose } from 'forms/account/accountVerification/steps/hooks/useOnRequestClose';
import { useOnSubmit } from 'forms/account/accountVerification/steps/hooks/useOnSubmit';

import style from './accountVerification.scss';

const steps: AccountVerificationFormStep[] = [
  {
    actionLabel: 'continuee',
    component: AccountVerificationIntro,
  },
  {
    actionLabel: 'send_verification_code',
    component: MobileVerificationPhoneInput,
    isFormWithValidationRequirements: true,
    isNavigationPoint: true,
    progress: 1,
  },
  {
    actionLabel: 'confirm_verification_code',
    component: MobileVerificationCodeInput,
    isFormWithValidationRequirements: true,
    progress: 2,
  },
  {
    actionLabel: 'continuee',
    component: MobileVerificationSuccess,
    progress: 2,
  },
  {
    actionLabel: 'send_verification_code',
    component: EmailVerificationEmailInput,
    isFormWithValidationRequirements: true,
    isNavigationPoint: true,
    progress: 3,
  },
  {
    actionLabel: 'confirm_verification_code',
    component: EmailVerificationCodeInput,
    isFormWithValidationRequirements: true,
    progress: 4,
  },
  {
    actionLabel: 'done',
    component: AccountVerificationSuccess,
    progress: 4,
  },
];

const VerifyPhoneAndEmailForm = ({ isOpen, onClose }: AccountVerificationFormProps) => {
  const {
    currentStep,
    formData,
    formRef,
    isActionable,
    isConfirmCloseDialogOpen,
    isConfirmUnverifiedDialogOpen,
    setCurrentStep,
    setFormData,
    setIsActionable,
    setIsConfirmCloseDialogOpen,
    setIsConfirmUnverifiedDialogOpen,
  } = useFormState();

  /**
   * Navigate to one of the designated step root points.
   */
  const onNavigateToSection = useNavigation({ currentStep, setCurrentStep, steps });

  /**
   * A callback for child form elements to notify whether the
   * submit button is eligible for submission.
   */
  const onFormInputChange = useIsFormSubmittable({ currentStep, setIsActionable, steps });

  /**
   * A callback to determine which confirm close dialog should be displayed.
   */
  const onRequestClose = useOnRequestClose({ formData, setIsConfirmCloseDialogOpen, setIsConfirmUnverifiedDialogOpen });

  /**
   * Confirmation callback which determines if we should move
   * on to the next step, or if we're finished.
   */
  const onConfirm = useOnSubmit({ currentStep, formRef, onClose, setCurrentStep, setFormData, steps });

  // Build form content depending on which step is active
  const formContent = useFormContent({ currentStep, formData, formRef, onFormInputChange, onNavigateToSection, steps });

  return (
    <>
      <ConfirmDialog
        actionable={isActionable}
        actionLabel={t(steps[currentStep].actionLabel)}
        bodyClassName={style.dialogBody}
        className={style.container}
        isOpen={isOpen}
        onClose={onRequestClose}
        onConfirm={onConfirm}
        title=""
      >
        <StepsProgress
          className={style.stepsProgress}
          currentStep={steps[currentStep].progress}
          steps={4}
          theme="blue"
        />
        {formContent}
      </ConfirmDialog>

      {/* Confirm close dialog */}
      <ConfirmDialog
        actionLabel={t('continue_verifying_account')}
        bringToFront
        className={style.container}
        isOpen={isConfirmCloseDialogOpen}
        onConfirm={() => setIsConfirmCloseDialogOpen(false)}
        title=""
      >
        <div className={style.title}>{t('exit_account_verification_primary_message')}</div>
        <div>{t('exit_account_verification_secondary_message')}</div>
        <div className={classnames(style.actionButtons, style.exitAccountButtons)}>
          <LinkButton onClick={onClose}>{t('close')}</LinkButton>
        </div>
      </ConfirmDialog>

      {/* Confirm close with unverified information */}
      <ConfirmDialog
        actionLabel={t('continue_verifying_account')}
        bringToFront
        className={style.container}
        isOpen={isConfirmUnverifiedDialogOpen}
        onConfirm={() => setIsConfirmUnverifiedDialogOpen(false)}
        title=""
      >
        <div className={style.title}>{t('unverified_information')}</div>
        <div>{t('unverified_information_message')}</div>
        <div className={classnames(style.actionButtons, style.exitAccountButtons)}>
          <LinkButton onClick={onClose}>{t('close_anyway')}</LinkButton>
        </div>
      </ConfirmDialog>
    </>
  );
};

export default VerifyPhoneAndEmailForm;
