import classnames from 'classnames';
import { ReactNode } from 'react';

import style from 'components/ui/tag/tag.scss';

interface Props {
  /** The children to be rendered as highlighted. */
  children: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /**
   * The color theme of the tag.
   * @default 'default'
   */
  theme?: 'default' | 'gray' | 'green' | 'red' | 'yellow';
  /** The HTML tag to be used for the component. */
  tagName?: 'div' | 'li';
}

const Tag = ({ children, className, theme = 'default', tagName: TagName = 'div' }: Props) => {
  return <TagName className={classnames(style.tag, style[theme], className)}>{children}</TagName>;
};

export default Tag;
