import classnames from 'classnames';
import { MouseEvent } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Badge from 'components/ui/badges/badge';
import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './editButton.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Function invoked on badge click. */
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** The button label. */
  text?: string;
  /** The CSS theme for the badge theme. */
  theme?: 'blue-pencil' | 'default';
  /** Set the html `title` attribute. */
  title?: string;
}

const EditButton = ({ className, onClick, theme = 'default', text, title = t('edit') }: Props) => {
  if (!text) {
    return (
      <Badge
        className={classnames(style.edit, style[`theme-${theme}`], className)}
        content={editGlyph}
        dataTestId="edit-button"
        onClick={onClick}
        theme="none"
        title={title}
      />
    );
  }

  return (
    <Button className={classnames(style.edit, className)} dataTestId="edit-button" onClick={onClick} theme="none">
      <div className={classnames(style[`theme-${theme}`], style.editWithText)}>
        <Sprite glyph={editGlyph} />
      </div>
      <span className={style.text}>{text}</span>
    </Button>
  );
};

export default EditButton;
