import { Component } from 'react';

import { ErrorMessages } from 'constants/errors';
import { getErrors } from 'utils/apiUtils';

class BaseClass<Props = Record<string, any>, State = Record<string, any>> extends Component<Props, State> {
  _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // ========================================================================================
  // Note:
  // The following public members are regularly used in classes which extend `BaseClass`
  // ========================================================================================

  // eslint-disable-next-line react/no-unused-class-component-methods
  onApiError = (error: Error) => {
    const errorMessages: ErrorMessages = getErrors(error).map(({ name, message }) => ({ name, message }));

    // This state is meant to be used in inheriting classes
    this.setState((prevState) => ({ ...prevState, errorMessages }));
  };

  setState = (state: any, cb?: () => void) => {
    if (this._isMounted) {
      super.setState(state, cb);
    }
  };
}

export default BaseClass;
