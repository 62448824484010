import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import chevronGlyph from 'glyphs/chevron.svg';

import Button from 'components/ui/shared/button';
import ChatChannelButton, { ChatChannelButtonProps, ScrollIntoViewType } from 'components/ui/chat/chatChannelButton';
import Sprite from 'components/ui/shared/sprite';
import { ConversationType } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './chatSidePanel.scss';

export interface ChatChannel {
  /** Chat channel id. */
  channelId: string;
  /** The conversation type of the channel */
  conversationType: ConversationType;
  /** Chat channel name. */
  name: string;
  /** Number of unread messages. */
  unreadMessageCount?: number;
}

export interface ChatSection {
  /** Array of channels that are available within the particular chat section */
  channels: ChatChannel[];
  /** Section title visible to the user */
  name: string;
}

enum Direction {
  ABOVE,
  BELOW,
}

interface UnreadMessagesButtonProps {
  /** Direction of the unread messages */
  direction: Direction;

  /** Should the button be displayed or not */
  isVisible?: boolean;

  /** Click to scroll to channel with unread messages */
  onClick: (event) => void;
}

interface ScrollInViewDescriptor {
  /** Chat channel id. */
  channelId: string | undefined;

  /** Determines how the item will be scrolled into view - from top, bottom or none */
  scrollIntoViewType: ScrollIntoViewType;
}

interface Props {
  /** Chat Sections */
  chatSections: ChatSection[];

  /** Callback function to handle on chat channel click event. */
  onChannelClick?: ChatChannelButtonProps['onChannelClick'];

  /** Currently selected chat channel id. */
  selectedChannelId?: ChatChannelButtonProps['selectedChannelId'];
}

const UnreadMessagesButton = ({ direction, isVisible, onClick }: UnreadMessagesButtonProps) =>
  isVisible ? (
    <div className={classnames(style.unreadMessagesButtonContainer, direction === Direction.BELOW && style.down)}>
      <Button
        className={style.unreadMessagesButton}
        dataTestId={`chat-unread-messages-button-${direction}`}
        onClick={onClick}
        theme="yellow"
      >
        <Sprite
          className={classnames(style.chevronGlyph, direction === Direction.BELOW && style.down)}
          glyph={chevronGlyph}
        />
        {t('chat_unread')}
      </Button>
    </div>
  ) : null;

const ChatSidePanel = ({ chatSections = [], onChannelClick, selectedChannelId }: Props) => {
  const [scrollToChannelDescriptor, setScrollToChannelDescriptor] = useState<ScrollInViewDescriptor | null>(null);
  const [visibilityMap, setVisibilityMap] = useState<Map<string, boolean>>(new Map<string, boolean>());

  /** Sort each channel section by channels with unread messages, followed by all other channels. */
  const sortedSections = useMemo(
    () =>
      chatSections?.map((section: ChatSection) => ({
        ...section,
        channels: [
          ...section.channels
            .filter((channel) => Number(channel.unreadMessageCount) > 0)
            .sort((a, b) => a.name.localeCompare(b.name)),
          ...section.channels
            .filter((channel) => !channel.unreadMessageCount)
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
      })),
    [chatSections]
  );

  /**
   * Create a flat array of the channels without sections so that we can have a pure index array of channels
   *  in the order they appear in the chat side panel.
   */
  const flatChannelArray: ChatChannel[] = useMemo(() => {
    return sortedSections.flatMap((section) => section.channels);
  }, [sortedSections]);

  /**
   * When the visibility of a particular channel changes, this callback will be called.  We will update
   * a map that keeps track of each channels' visibility.
   */
  const onVisibilityChange = useCallback(
    (channelId: string, isVisible: boolean) => {
      if (isVisible && scrollToChannelDescriptor?.channelId === channelId) {
        setScrollToChannelDescriptor(null); // Reset for next time
      }
      if (visibilityMap.get(channelId) !== isVisible) {
        setVisibilityMap(new Map<string, boolean>(visibilityMap.set(channelId, isVisible)));
      }
    },
    [scrollToChannelDescriptor, setScrollToChannelDescriptor, setVisibilityMap, visibilityMap]
  );

  /**
   * Find the first none visible channel that has unread message in the ordered channel array.
   * We will parse the array either from the top or bottom depending on which direction we want to search.
   */
  const findFirstNonVisibleChannelWithUnReadMessages = useCallback(
    (bottom: boolean): ChatChannel | null => {
      // If map hasn't been populated yet, return null
      if (visibilityMap.size === 0) {
        return null;
      }

      let channelWithUnreadMessages: ChatChannel | null = null;

      /** Iterate from the start or reverse from the end of the array if the bottom variable is true */
      const topIndex: number = flatChannelArray.length - 1;
      const startIndex: number = bottom ? topIndex : 0;
      for (let i = startIndex; bottom ? i >= 0 : i <= topIndex; bottom ? (i -= 1) : (i += 1)) {
        const channel: ChatChannel = flatChannelArray[i];
        if (visibilityMap.get(channel.channelId)) {
          break; // Exit out of the loop - no channel with unread messages found
        }

        if (Number(channel.unreadMessageCount) > 0) {
          channelWithUnreadMessages = channel;
          break; // Found one channel that isn't visible, we don't need to continue the search
        }
      }

      return channelWithUnreadMessages;
    },
    [flatChannelArray, visibilityMap]
  );

  /** Search for a channel with unread messages above the scroll area */
  const unreadMessagesChannelNotVisibleAbove = useMemo(() => {
    return findFirstNonVisibleChannelWithUnReadMessages(false);
  }, [findFirstNonVisibleChannelWithUnReadMessages]);

  /** Search for a channel with unread messages below the scroll area */
  const unreadMessagesChannelNotVisibleBelow = useMemo(() => {
    return findFirstNonVisibleChannelWithUnReadMessages(true);
  }, [findFirstNonVisibleChannelWithUnReadMessages]);

  /** Click callback for top unread message button */
  const onHandleUnreadMessagesButtonClickAbove = useCallback(() => {
    setScrollToChannelDescriptor({
      channelId: unreadMessagesChannelNotVisibleAbove?.channelId,
      scrollIntoViewType: unreadMessagesChannelNotVisibleAbove ? ScrollIntoViewType.TOP : ScrollIntoViewType.NONE,
    });
  }, [unreadMessagesChannelNotVisibleAbove]);

  /** Click callback for bottom unread message button */
  const onHandleUnreadMessagesButtonClickBelow = useCallback(() => {
    setScrollToChannelDescriptor({
      channelId: unreadMessagesChannelNotVisibleBelow?.channelId,
      scrollIntoViewType: unreadMessagesChannelNotVisibleBelow ? ScrollIntoViewType.BOTTOM : ScrollIntoViewType.NONE,
    });
  }, [unreadMessagesChannelNotVisibleBelow]);

  return (
    <div
      className={classnames(
        style.sidePanel,
        !unreadMessagesChannelNotVisibleBelow && !unreadMessagesChannelNotVisibleAbove && style.noButtons
      )}
    >
      <UnreadMessagesButton
        direction={Direction.ABOVE}
        isVisible={!!unreadMessagesChannelNotVisibleAbove}
        onClick={onHandleUnreadMessagesButtonClickAbove}
      />
      <div
        className={classnames(style.container, [
          !!unreadMessagesChannelNotVisibleAbove && style.unreadMessagesNotVisibleTop,
          !!unreadMessagesChannelNotVisibleBelow && style.unreadMessagesNotVisibleBottom,
        ])}
        data-testid="chat-side-panel"
      >
        {sortedSections.map((chatSection): JSX.Element => {
          return (
            <div key={chatSection.name} className={style.section}>
              <div className={style.sectionTitle}>{chatSection.name}</div>
              <div>
                {chatSection.channels?.map(({ conversationType, ...channel }, idx) => (
                  <ChatChannelButton
                    key={channel.channelId}
                    conversationType={conversationType}
                    index={idx}
                    onChannelClick={onChannelClick}
                    onVisibilityChange={(isVisible: boolean) => {
                      onVisibilityChange(channel.channelId, isVisible);
                    }}
                    scrollIntoViewType={
                      scrollToChannelDescriptor?.channelId === channel.channelId &&
                      scrollToChannelDescriptor?.scrollIntoViewType
                    }
                    sectionName={chatSection.name}
                    selectedChannelId={selectedChannelId}
                    {...channel}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <UnreadMessagesButton
        direction={Direction.BELOW}
        isVisible={!!unreadMessagesChannelNotVisibleBelow}
        onClick={onHandleUnreadMessagesButtonClickBelow}
      />
    </div>
  );
};

export default ChatSidePanel;
