import { useState, useCallback, ReactNode, useEffect } from 'react';
import classnames from 'classnames';

import style from 'forms/shared/inputSwitch.scss';

interface Props {
  /** Additional styling */
  className?: string;
  /** Test ID for identifying input */
  dataTestId?: string;
  /** Default toggle value */
  defaultValue?: boolean;
  /** Icon to display on the toggle */
  icon?: ReactNode;
  /** ID value used to tied the label to the input */
  id?: string;
  /** Callback when switch value changes */
  onChange?: (e) => void;
  /** Click handler when switch control is clicked */
  onInputClick?: (e) => void;
  /** Value of the toggle */
  value?: boolean;
}

const InputSwitch = ({
  className,
  dataTestId,
  defaultValue,
  icon,
  id = 'switch',
  onChange = () => {},
  onInputClick = (e) => {
    e?.stopPropagation?.();
  },
  value,
}: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!value || !!defaultValue);

  // Force update checked value if `value` prop updates
  useEffect(() => {
    if (value !== undefined) {
      setIsChecked(value);
    }
  }, [value]);

  return (
    <div className={className} data-testid="checkBox">
      <input
        checked={isChecked}
        className={style.input}
        data-testid={dataTestId}
        id={id}
        onChange={useCallback(
          (e) => {
            onChange(e);
            setIsChecked(e?.currentTarget?.checked);
          },
          [onChange]
        )}
        onClick={onInputClick}
        type="checkbox"
      />
      <label className={classnames(style.label, !!isChecked && style.checked)} htmlFor={id}>
        <span className={classnames(style.switchDot, !!isChecked && style.checked)}>{icon || null}</span>
      </label>
    </div>
  );
};

export default InputSwitch;
