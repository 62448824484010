import Record from 'store/shared/models/record';

interface CheckoutModel {
  /** True to show notification */
  showNotification: boolean;
}

export const Checkout = Record<CheckoutModel>({
  showNotification: false,
});
