import axios from 'axios';
import semver from 'semver';

const MANIFEST_URL = '/manifest.json';

export const checkApplicationVersion = async () => {
  const manifestURL = `${MANIFEST_URL}?${new Date().getTime()}`;
  let manifest;
  try {
    manifest = (await axios(manifestURL))?.data;
  } catch (e) {
    console.warn('Issue with retrieving manifest:', e);
  }

  const isAppVersionValid = semver.valid(process.env.REACT_APP_BUILD_VERSION);
  const isManifestVersionValid = semver.valid(manifest?.version);

  if (isAppVersionValid && isManifestVersionValid) {
    const isBuildTimeOutdated = manifest?.buildTime !== process.env.REACT_APP_BUILD_TIME;
    const isVersionOutdated = semver.gt(manifest?.version, process.env.REACT_APP_BUILD_VERSION!);

    if (isBuildTimeOutdated || isVersionOutdated) {
      // User's application is out of date; force reload
      window.location.reload();
    }
  }
};
