import { handleActions } from 'redux-actions';

import { InitialState, Locations } from './addJobModels';
import { Location } from 'store/shared/api/graph/interfaces/types';
import { isLoading, isUpdating, isNotUpdating, updateLocations, locationsLoaded } from './addJobActions';

export const addJobReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [locationsLoaded().type]: (state, action) => {
      // TODO: Rename all references of `consigner` to `company`.
      const { pickupCompany, destinationCompany, defaultLocationId, locations } = action.payload;
      const { defaultLocation, ...otherLocations } = locations;
      const pickupConsignerId = pickupCompany?.id;
      const destinationConsignerId = destinationCompany?.id || pickupConsignerId;

      Object.keys(otherLocations).forEach((key) => {
        otherLocations[key] = otherLocations[key]?.edges?.map((item) => item.node);
      });

      const hasDefaultLocation =
        !defaultLocationId ||
        !!Object.values(otherLocations as Location[])
          .reduce((acc: any, val: any) => [...acc, ...val], [])
          .find((location) => location.id === defaultLocationId);

      if (!hasDefaultLocation) {
        otherLocations.pickupLocations.push(defaultLocation);
      }

      return state.setLoaded().merge({
        pickupConsignerId,
        destinationConsignerId,
        locations: new Locations({
          ...otherLocations,
          destinationLocations: otherLocations.destinationLocations || otherLocations.pickupLocations || [],
        }),
      });
    },

    [updateLocations().type]: (state, action) => {
      const { location, locationsListName } = action.payload;
      const { pickupConsignerId, destinationConsignerId } = state;
      const locations = state.locations.toJS();

      Object.keys(locations).forEach((key) => {
        if (pickupConsignerId === destinationConsignerId || key === locationsListName) {
          locations[key].push(location);
        }
      });

      return state.merge({
        locations: new Locations(locations),
      });
    },
  },
  new InitialState()
);
