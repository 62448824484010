import { useRef } from 'react';

import { useMountEffect } from 'hooks/useMountEffect';

/**
 * Returns true on component's first render, otherwise false.
 */
export const useIsFirstRender = () => {
  const isFirstRender = useRef(true);
  useMountEffect(() => {
    isFirstRender.current = false;
  });
  return isFirstRender.current;
};
