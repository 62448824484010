import linkGlyph from 'glyphs/link.svg';

import Sprite from 'components/ui/shared/sprite';
import autoGradeImage from 'static/images/AutoGrade.png';
import { t } from 'utils/intlUtils';

import style from './autoGradeLogo.scss';

const AutoGradeLogo = () => {
  return (
    <div className={style.autoGradeContent} data-testid="autograde-logo-link">
      <img alt={t('about_autograde')} className={style.autoGradeImage} src={autoGradeImage} />
      <div style={{ verticalAlign: 'top' }}>
        <div className={style.about}>{t('about_autograde_message')}</div>
        <a
          className={style.link}
          href="https://www.mymanheim.com/app/uploads/2022/04/All-About-AutoGrade_v2.0.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div>
            {t('about_autograde')}
            <Sprite className={style.linkGlyph} glyph={linkGlyph} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default AutoGradeLogo;
