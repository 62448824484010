import { CSSProperties } from 'react';
import { GroupBase, StylesConfig } from 'react-select';

import { joinStrings } from 'utils/stringUtils';

import styleVariables from 'styles/variables.scss';

export const MAX_MULTI_SELECT_CHARS = 50;

const ellipsisStyles: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

/** Returns the default color for text based on the disabled state */
const getDefaultColor = (isDisabled: boolean): string => {
  return isDisabled ? styleVariables.colorGrayLight : styleVariables.colorBlack;
};

/**
 * @function getDefaultSelectStyles (based on options, gets default select styles)
 * @param {boolean} [isLarge] - boolean to display a large select UI, defaults false
 * @param {object} [styleOverrides] - manual overrides of each style property, see react-select style docs
 * @returns {object} style object
 */
export const getDefaultSelectStyles = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  isLarge: boolean = false,
  styleOverrides: { [key: string]: any } = {}
): StylesConfig<Option, IsMulti, Group> => {
  const chevronPadding = isLarge ? 20 : 9;

  return {
    option: (base, state) => ({
      ...base,
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: isLarge ? 15 : base.padding,
      ...styleOverrides.option?.(base, state),
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: state.theme.backgroundColor,
      borderColor: state.theme.borderColor,
      borderRadius: state.menuIsOpen
        ? `${state.theme.borderRadius}px ${state.theme.borderRadius}px 0 0`
        : state.theme.borderRadius,
      borderWidth: state.theme.controlBorderWidth,
      boxShadow: 0,
      color: getDefaultColor(state.isDisabled),
      cursor: 'pointer',
      height: state.theme.height === '0' && state.theme.height,
      minHeight: isLarge ? 48 : state.theme.height,
      overflow: 'hidden',
      zIndex: state.theme.boxShadow !== 'none' ? styleVariables.zIndex2 : 'unset',
      '&:hover': {
        borderColor: state.theme.borderColor,
      },
      ...styleOverrides.control?.(base, state),
    }),
    placeholder: (base, state) => ({
      ...base,
      color: getDefaultColor(state.isDisabled),
      ...styleOverrides.placeholder?.(base, state),
    }),
    valueContainer: (base, state) => ({
      ...base,
      flexWrap: 'nowrap',
      maxWidth: '90%',
      padding: isLarge ? '0 10px 0 18px' : '0 10px',
      ...styleOverrides.valueContainer?.(base, state),
    }),
    singleValue: (base, state) => ({
      ...base,
      color: getDefaultColor(state.isDisabled),
      ...styleOverrides.singleValue?.(base, state),
    }),
    indicatorSeparator: () => ({}),
    clearIndicator: (base) => ({
      ...base,
      width: '24px',
      padding: '6px 0 6px 8px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: state.selectProps.menuIsOpen ? `6px 6px 3px ${chevronPadding}px` : `3px ${chevronPadding}px 6px 6px`,
      color: state.isDisabled ? styleVariables.colorGrayLight : styleVariables.colorGrayDark,
      width: isLarge ? 38 : 27,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
      '&:hover': {
        color: state.isDisabled ? styleVariables.colorGrayLight : styleVariables.colorGrayDark,
      },
    }),
    menu: (base, state) => {
      const { theme, menuPlacement } = state;
      let top = isLarge ? 48 : Number(theme.height);
      let borderRadius = `0 0 ${theme.borderRadius}px ${theme.borderRadius}px`;
      if (theme.boxShadow !== 'none') {
        top += 10;
        borderRadius = `${theme.borderRadius}px`;
      }
      if (menuPlacement === 'top') {
        top = -top;
      }
      return {
        ...base,
        zIndex: styleVariables.zIndex2,
        margin: 0,
        top,
        borderRadius,
        borderWidth: theme.menuBorderWidth,
        borderStyle: 'solid',
        borderColor: theme.borderColor,
        boxShadow: theme.boxShadow,
        padding: 0,
        maxHeight: theme.maxMenuHeight || base.maxHeight,
        ...styleOverrides.menu?.(base, state),
      };
    },
    menuList: (base, state) => ({
      ...base,
      padding: 0,
      marginTop: 0,
      borderRadius:
        state.theme.boxShadow === 'none'
          ? `0 0 ${state.theme.borderRadius}px ${state.theme.borderRadius}px`
          : `${state.theme.borderRadius}px`,
      scrollbarWidth: 'thin',
      maxHeight: state.theme.maxMenuHeight || base.maxHeight,
      ...styleOverrides.menuList?.(base, state),
    }),
  };
};

/**
 * @function getErrorSelectStyles - Returns styles for a select menu in a state of error
 * @param {boolean} [isLarge] - boolean to display a large select UI, defaults false
 * @returns {object} style object
 */
export const getErrorSelectStyles = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  isLarge = false
): StylesConfig<Option, IsMulti, Group> => {
  const defaultStyles = getDefaultSelectStyles<Option, IsMulti, Group>(isLarge);
  const chevronPadding = isLarge ? 20 : 9;

  return {
    ...defaultStyles,
    control: (base, { theme, menuIsOpen, isDisabled }) => ({
      ...base,
      minHeight: isLarge ? 48 : theme.height,
      backgroundColor: styleVariables.colorRedLightest,
      borderRadius: menuIsOpen ? `${theme.borderRadius}px ${theme.borderRadius}px 0 0` : theme.borderRadius,
      borderColor: styleVariables.colorRed,
      boxShadow: '0',
      color: styleVariables.colorRed,
      cursor: 'pointer',
      borderWidth: 1,
      opacity: isDisabled ? '0.4' : '1',
      zIndex: theme.boxShadow !== 'none' ? styleVariables.zIndex2 : 'unset',
      '&:hover': {
        borderColor: styleVariables.colorRed,
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: styleVariables.colorRed,
    }),
    singleValue: (base) => ({
      ...base,
      color: styleVariables.colorRed,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: state.selectProps.menuIsOpen ? `6px 6px 3px ${chevronPadding}px` : `3px ${chevronPadding}px 6px 6px`,
      color: styleVariables.colorRed,
      width: isLarge ? 38 : 27,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
      '&:hover': {
        color: styleVariables.colorRed,
      },
    }),
  };
};

export const getTransparentSelectStyles = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> => {
  const defaultStyles = getDefaultSelectStyles<Option, IsMulti, Group>();
  return {
    ...defaultStyles,
    control: (base) => ({
      ...base,
      ...defaultStyles.control,
      backgroundColor: 'transparent',
      borderWidth: 0,
      minHeight: '32px',
      height: '32px',
      zIndex: styleVariables.zIndex2,
      '&:hover': {
        borderWidth: 0,
      },
    }),
  };
};

export const defaultThemeStyles = () => ({
  backgroundColor: styleVariables.colorWhite,
  controlBorderWidth: '1px',
  menuBorderWidth: '0 1px 1px',
  boxShadow: 'none',
  height: '34px',
  borderColor: styleVariables.colorGrayLighter,
  borderRadius: 3,
});

export const greenSelectTheme = (theme) => ({
  ...theme,
  ...defaultThemeStyles(),
  colors: {
    ...theme.colors,
    primary: styleVariables.colorGreen,
    primary75: styleVariables.colorGreenLightest,
    primary50: styleVariables.colorGreenLightest,
    primary25: styleVariables.colorGreenLightest,
  },
});

export const blueSelectTheme = (theme) => ({
  ...theme,
  ...defaultThemeStyles(),
  colors: {
    ...theme.colors,
    primary: styleVariables.colorBlue,
    primary75: styleVariables.colorBlueLighter,
    primary50: styleVariables.colorBlueLighter,
    primary25: styleVariables.colorBlueLighter,
  },
});

export const minimalSelectTheme = (theme) => ({
  ...theme,
  controlBorderWidth: '0',
  menuBorderWidth: '0',
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.06)',
  height: '0',
  borderColor: styleVariables.colorGrayLightest,
  borderRadius: 5,
  colors: {
    ...theme.colors,
    primary: styleVariables.colorBlue,
    primary75: styleVariables.colorBlueLighter,
    primary50: styleVariables.colorBlueLighter,
    primary25: styleVariables.colorBlueLighter,
  },
});

export const transparentSelectTheme = (theme) => ({
  ...theme,
  ...minimalSelectTheme(theme),
  height: '32px',
});

export const darkSelectStyle = {
  control: (base, { menuIsOpen }) => ({
    background: styleVariables.colorBlackTransparent,
    borderRadius: menuIsOpen ? '0' : '0 0 3px 3px',
    border: '0',
  }),
  menuList: () => ({
    color: styleVariables.colorWhite,
    maxHeight: '117px',
  }),
  menu: () => ({
    border: 'none',
    borderRadius: '0',
  }),
  option: (base, state) => ({
    backgroundColor: state.isFocused ? styleVariables.colorBlueLighter : styleVariables.colorGrayDarkest,
    background: state.isFocused ? styleVariables.colorBlueLighter : styleVariables.colorGrayDarkest,
    borderTop: `1px solid ${styleVariables.colorGrayDark}`,
    color: state.isFocused ? styleVariables.colorBlack : styleVariables.colorWhite,
  }),
  placeholder: () => ({ color: styleVariables.colorWhite }),
  singleValue: () => ({ color: styleVariables.colorWhite }),
};

/**
 * Extended search filtering for React Select.
 * Breaks words apart and allows for a more granular search, as opposed to the default `includes x string` approach.
 *
 * @param {object} option
 * @param {string} inputValue
 * @returns {boolean}
 */
export const customOptionFiltering = (option, inputValue) => {
  const searchFields = option.label?.split(' ')?.map((s) => (s || '').toString().toLowerCase());
  const searchQueries = inputValue?.toLowerCase()?.split(' ');
  return searchQueries.every((q) => searchFields?.find((sf) => sf.includes(q)));
};

/**
 * Provides a group of props to generate a multi-select dropdown, with custom text formatting for the selected values.
 *
 * @param {string} maxChars - Cap the max string output length
 * @returns {{isMulti: boolean, components: {MultiValueContainer: (function({selectProps: *, data: *}): string)}, hideSelectedOptions: boolean, closeMenuOnSelect: boolean}}
 */
export const getMultiSelectProps = (maxChars: number = MAX_MULTI_SELECT_CHARS) => ({
  isMulti: true,
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  components: {
    MultiValueContainer: ({ selectProps, data }) => {
      if (!Array.isArray(selectProps.value)) {
        return null;
      }

      const index = selectProps.value?.findIndex(({ label, name }) => (label || name) === (data.label || data?.name));

      // Join the selected values and cap the max string output to length of `MAX_CHARS`.
      // This fixes a bug with React-Select when there are many items selected, and the
      // user deselects the first item, which shifts the x axis leftward until the
      // `white-space` style value is reset.
      return (
        <div style={ellipsisStyles}>
          {index === 0
            ? joinStrings(selectProps.value?.map((option) => option?.label || option?.name))?.slice(0, maxChars)
            : ''}
        </div>
      );
    },
  },
});
