import { handleActions } from 'redux-actions';

import { InitialState } from './broadcastDetailsModels';
import {
  broadcastDetailsCleared,
  broadcastDetailsIsLoading,
  broadcastDetailsLoaded,
  preloadBroadcastDetails,
} from './broadcastDetailsActions';

export const broadcastDetailsReducer = handleActions(
  {
    [broadcastDetailsCleared().type]: () => new InitialState(),

    [broadcastDetailsIsLoading().type]: (state) => state.set('isLoading', true),

    [preloadBroadcastDetails().type]: (state, action) => {
      if (action?.payload?.id) {
        return state.setLoaded().set('details', action?.payload);
      }
      return state;
    },

    [broadcastDetailsLoaded().type]: (state, action) => {
      return state?.setLoaded()?.set('details', action?.payload);
    },
  },
  new InitialState()
);
