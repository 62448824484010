import ApiRequest from 'store/shared/api/apiRequest';
import { MutationuserNotificationSettingsUpdateArgs, User } from 'store/shared/api/graph/interfaces/types';
import { userNotificationSettingsSchema } from 'store/shared/api/graph/queries/notifications';

export const userNotificationSettingsUpdate = (options: MutationuserNotificationSettingsUpdateArgs) =>
  ApiRequest<{ userNotificationSettingsUpdate: User }>({
    data: {
      operationName: 'userNotificationSettingsUpdate',
      query: `
        mutation (
          $appraisal: Boolean,
          $auction: Boolean,
          $distanceFromPreferredLocation: Int,
          $email: Boolean,
          $grounded: Boolean,
          $makeModelFilters: [MakeModelFilter],
          $notifications: [NotificationInput],
          $push: Boolean,
          $sms: Boolean,
          $timedOffer: Boolean,
          $userId: String
        ) {
          userNotificationSettingsUpdate(
            appraisal: $appraisal,
            auction: $auction,
            distanceFromPreferredLocation: $distanceFromPreferredLocation,
            email: $email,
            grounded: $grounded,
            makeModelFilters: $makeModelFilters,
            notifications: $notifications,
            push: $push,
            sms: $sms,
            timedOffer: $timedOffer,
            userId: $userId,
          ) {
            ${userNotificationSettingsSchema}
          }
        }
      `,
      variables: options,
    },
  });
