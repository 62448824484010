import { connect, ConnectedProps } from 'react-redux';

import Loading from 'components/ui/loading/loading';
import BaseClass from 'components/ui/shared/base';
import CheckoutAllForm from 'forms/checkout/checkoutAllForm';
import CheckoutSingleForm from 'forms/checkout/checkoutSingleForm';
import CheckoutSuccessForm from 'forms/checkout/checkoutSuccessForm';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import { AppDispatch } from 'store/configureStore';
import {
  AuctionItem,
  AuctionItemOrder,
  AuctionItemOrdersResponse,
  CheckoutResponse,
} from 'store/shared/api/graph/interfaces/types';
import { auctionItemCompleteCheckout } from 'store/shared/api/graph/mutations/checkout';
import { formatYMMT } from 'utils/stringUtils';
import { getAuctionItemOrders } from 'store/shared/api/graph/queries/checkout';
import { showCheckoutNotification } from 'store/checkout/checkoutActions';
import { t } from 'utils/intlUtils';
import { updateAuctionItemOrder } from 'store/auctionItemDetails/auctionItemDetailsActions';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to show checkout navigation notification */
  showCheckoutNavNotification: (isVisible: boolean) => dispatch(showCheckoutNotification(isVisible)),
  /** Callback function to update auction item order */
  updateAuctionItemOrder: (auctionItemOrder: AuctionItemOrder | undefined) =>
    dispatch(updateAuctionItemOrder(auctionItemOrder)),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem?: AuctionItem;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True if single form */
  isSingleForm?: boolean;
  /** Callback function when dialog is closed */
  onClose: () => void;
}

interface State {
  auctionItemOrders: AuctionItemOrdersResponse | undefined;
  isFetchingOrders: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  successResponse: CheckoutResponse | undefined;
  selectedAuctionItem: AuctionItem | undefined;
}

const initialState = {
  auctionItemOrders: undefined,
  isFetchingOrders: false,
  isSubmitted: false,
  isSubmitting: false,
  successResponse: undefined,
  selectedAuctionItem: undefined,
};

class CheckoutFormContainer extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!prevProps?.isOpen && this.props?.isOpen) {
      this.setState({ selectedAuctionItem: this.props?.auctionItem }, () => {
        if (!this.props?.isSingleForm) {
          this.getAuctionItemOrders();
        }
      });
    } else if (prevProps?.isOpen && !this.props?.isOpen) {
      this.setState(initialState);
    }
  }

  /**
   * Fetches and sets the available AuctionItemOrders
   */
  getAuctionItemOrders = () => {
    this.setState({ isFetchingOrders: true });
    return getAuctionItemOrders()
      .then((response) => this.setState({ auctionItemOrders: response?.data?.data?.auctionItemOrders }))
      .catch(this.onApiError)
      .finally(() => this.setState({ isFetchingOrders: false }));
  };

  /**
   * Returns the form dialog's title depending on the state of the form.
   */
  getFormTitle = () => {
    const { isSingleForm } = this.props;
    const { auctionItemOrders, isSubmitted, selectedAuctionItem } = this.state;
    const bulkOrderItemCount = auctionItemOrders?.auctionItems?.length;

    if (isSubmitted) {
      return t('checkout');
    }
    if (isSingleForm || !!selectedAuctionItem) {
      return `${t('checkout')} - ${formatYMMT(selectedAuctionItem?.inventoryItem)}`;
    }
    return `${t('checkout_all')} ${bulkOrderItemCount ? `(${bulkOrderItemCount})` : ''}`;
  };

  onSubmit = (auctionItemIds: string[]) => {
    this.setState({ isSubmitting: true });
    auctionItemCompleteCheckout({ auctionItemIds })
      .then((response) => {
        this.setState({ isSubmitted: true, successResponse: response?.data?.data?.auctionItemCompleteCheckout });
      })
      .catch(this.onApiError)
      .finally(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { isOpen, isSingleForm, onClose, showCheckoutNavNotification } = this.props;
    const { auctionItemOrders, isFetchingOrders, isSubmitted, isSubmitting, selectedAuctionItem, successResponse } =
      this.state;
    const title = this.getFormTitle();

    return (
      <FormDialog isOpen={isOpen} onClose={onClose} title={title}>
        <>
          {/* Show loader while fetching checkout orders */}
          {isFetchingOrders && <Loading hasFullWidth isLoading isModalTransparent />}

          {/* Show SuccessForm upon successfully checking out */}
          {isSubmitted && (
            <CheckoutSuccessForm
              auctionItem={selectedAuctionItem}
              checkoutResponse={successResponse}
              isSingleForm={isSingleForm}
              onClose={() => {
                if (!isSingleForm) {
                  showCheckoutNavNotification(false);
                }
                onClose();
              }}
            />
          )}

          {!isSubmitted && (
            <>
              {isSingleForm || !!selectedAuctionItem ? (
                // Show single order form
                <CheckoutSingleForm
                  auctionItem={selectedAuctionItem}
                  isSubmitting={isSubmitting}
                  onGoBack={
                    isSingleForm
                      ? undefined
                      : async (shouldRefresh = false) => {
                          if (shouldRefresh) {
                            await this.getAuctionItemOrders();
                          }
                          this.setState({ selectedAuctionItem: undefined });
                        }
                  }
                  onSubmit={
                    !isSingleForm
                      ? undefined
                      : () => this.onSubmit(selectedAuctionItem?.id ? [selectedAuctionItem?.id] : [])
                  }
                  onUpdateAuctionItemOrder={(auctionItemOrder) => {
                    this.setState({
                      selectedAuctionItem: {
                        ...(selectedAuctionItem || ({} as AuctionItem)),
                        order: auctionItemOrder,
                      },
                    });
                    if (this.props?.auctionItem?.id) {
                      this.props?.updateAuctionItemOrder(auctionItemOrder);
                    }
                  }}
                />
              ) : (
                // Show bulk order form
                <CheckoutAllForm
                  auctionItemOrders={auctionItemOrders}
                  isSubmitting={isSubmitting}
                  onEdit={(auctionItemId) =>
                    this.setState({
                      selectedAuctionItem:
                        auctionItemOrders?.auctionItems?.find((auctionItem) => auctionItem?.id === auctionItemId) ||
                        undefined,
                    })
                  }
                  onSubmit={() =>
                    this.onSubmit(auctionItemOrders?.auctionItems?.filter(Boolean).map(({ id }) => id) || [])
                  }
                />
              )}
            </>
          )}
        </>
      </FormDialog>
    );
  }
}

export default connector(CheckoutFormContainer);
