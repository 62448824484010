import { useEffect, useRef } from 'react';

import MessageBubble from 'components/ui/chat/messageBubble';
import { ConversationMessageState, ConversationState } from 'store/chat/chatModels';
import { ConversationMessageType } from 'store/shared/api/graph/interfaces/types';
import { formatCurrency } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './chatMessages.scss';

interface Props {
  /** Conversation that is being displayed */
  conversation: ConversationState | null;

  /** Callback for retrieving a user name from an id */
  getUserName: (userId: string | null) => string | null;

  /** Determines if extra context info should be displayed with each message */
  isContextInfoDisplayed?: boolean;

  /** Current user id. */
  userId: string;
}

const ChatMessages = ({ conversation, getUserName, isContextInfoDisplayed, userId }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  /**
   * On messages change, scroll to the bottom
   */
  useEffect(() => {
    if (containerRef?.current) {
      const element = containerRef.current;
      element.scroll?.({
        left: 0,
        top: element.scrollHeight,
      });
    }
  }, [conversation?.messages.length]);

  return (
    <div ref={containerRef} className={style.container} data-testid="chat-messages">
      <div className={style.innerContainer}>
        {((conversation?.messages as ConversationMessageState[]) ?? [])
          ?.filter(Boolean)
          .map(({ id, createdById, type, ...message }) => {
            const userName: string | null = getUserName(createdById);
            const isMyMessage = createdById === userId;
            return (
              <MessageBubble
                key={id}
                contextInfo={
                  isContextInfoDisplayed && message.currentBidAmount && message.currentBidAmount > 0
                    ? t('chat_bid_amount', [formatCurrency(message.currentBidAmount)])
                    : null
                }
                isBroadcastMessage={type === ConversationMessageType.LIVE_LANE_BROADCAST}
                isMyMessage={isMyMessage}
                messageId={id}
                senderName={!isMyMessage || isContextInfoDisplayed ? (userName ?? t('unknown')) : ''}
                {...message}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ChatMessages;
