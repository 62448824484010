import { useMemo } from 'react';
import classnames from 'classnames';

import style from './odometer.scss';

// The reverse ordering is done to inverse the animation direction
const ACCEPTABLE_DIGITS = Array.from('0123456789').reverse();

interface Props {
  /** The odometer reading */
  children: string;

  /** The className to overwrite default styles */
  className?: string;
}

const Odometer = ({ children, className }: Props) => {
  // Map the digit indexes; we use these to vertically position/animate the correct digit
  const digitIndexes = Array.from(children || []).map((c) => ACCEPTABLE_DIGITS.indexOf(c));

  // Map the various digit types
  const digits = useMemo(
    () =>
      Array.from(children || [])?.reduce((characters, character, i) => {
        if ([' ', ' '].includes(character)) {
          // Spaces
          // TODO: Remove 'NBSP' character once backend stops returning it...
          return [...characters, <div key={i} className={style.emptyChar} />];
        }

        if (ACCEPTABLE_DIGITS.indexOf(character) > -1) {
          // Acceptable digits
          return [
            ...characters,
            <div key={i} className={style.digit} style={{ transform: `translateY(${digitIndexes[i] * -23}px)` }}>
              {ACCEPTABLE_DIGITS.map((c) => (
                <div key={c} className={style.char}>
                  {c}
                </div>
              ))}
            </div>,
          ];
        }

        // Everything else...
        return [...characters, <div key={i}>{character}</div>];
      }, []),
    [children, digitIndexes]
  );

  return (
    <div className={classnames(style.odometer, className)} data-testid="odometer">
      <div className={style.digits}>{digits}</div>
    </div>
  );
};

export default Odometer;
