import { List } from 'immutable';

import InventoryItemType from 'constants/inventoryItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Location, LogEntry as LogEntryType, Note, SellHoldStatus } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export interface LogEntries {
  /** The title for the collection of log entries. */
  title: string;
  /** A collection of logs. */
  logs: LogEntryType[];
}

interface LogEntriesModel {
  /** List of log entries. */
  results: List<LogEntries> | null;
}

interface InventoryItemModel {
  /** List of consigner locations. */
  consignerLocations: List<Location>;
  /** Inventory item details. */
  details: InventoryItemDetailsProps | null;
  /** List of log entries. */
  logEntries: ServerRecordOf<LogEntriesModel>;
}

const LogEntriesInitialState = ServerRecord<LogEntriesModel>({
  results: null,
});

export type InventoryItemProps = ServerRecordOf<InventoryItemModel>;

export const InventoryItem = ServerRecord<InventoryItemModel>({
  details: null,
  logEntries: new LogEntriesInitialState(),
  consignerLocations: List(),
});

export type InventoryItemDetailsProps = RecordOf<InventoryItemType>;

export const InventoryItemDetails = Record<InventoryItemType>({
  archived: false,
  asIsCause: null,
  auctionLocation: null,
  auctionTimeSlotLane: null,
  autocheckExceptions: null,
  autocheckExceptionsReceived: null,
  autoGradeScore: null,
  autoGradeScoreData: {
    autoGradeVehicleDamageCount: 0,
  },
  availableAuctions: null,
  blueDriverScanData: null,
  bodyType: null,
  canBeListed: false,
  captured: null,
  capturedBy: null,
  captureType: null,
  chargingCable: null,
  company: null,
  comparables: null,
  comparableValuations: [],
  complete: null,
  completionPercentage: 0,
  compoundHistory: [],
  conditionReport: {
    activeDeclarations: [],
    carfaxCanadaClaimAmount: null,
    carfaxCanadaDisclosuresExist: false,
    carfaxCanadaReportStatus: null,
    inactiveDeclarations: [],
    overallConditionRating: null,
    repaintOnHowManyPanels: null,
  },
  created: null,
  createdBy: null,
  cylinders: null,
  damagePhotos: [],
  description: null,
  displacement: null,
  driveTrain: null,
  drivetrainBuyerShield: null,
  engineBuyerShield: null,
  extensiveVehicleConditionScore: null,
  exteriorColor: null,
  exteriorNote: null,
  exteriorRating: null,
  fuelType: null,
  id: null,
  incompleteFieldMessages: null,
  interiorColor: null,
  interiorNote: null,
  interiorPhotos: [],
  interiorRating: null,
  inventoryItemFee: null,
  isUpdatable: false,
  lastKnownMileage: null,
  lastKnownMileageDate: null,
  legalSaleDate: null,
  location: {
    address1: null,
    address2: null,
    city: null,
    country: null,
    countryCode: null,
    created: null,
    formattedTaxRateRegionCode: null,
    id: null,
    latitude: null,
    longitude: null,
    name: null,
    public: null,
    region: null,
    regionCode: null,
    taxRateForRegionCode: null,
    updated: null,
    zipCode: null,
  },
  make: null,
  mechanicalDrivetrainNote: null,
  mechanicalDrivetrainRating: null,
  mechanicalEngineNote: null,
  mechanicalEngineRating: null,
  mechanicalNote: null,
  mechanicalRating: null,
  mechanicalTransmissionNote: null,
  mechanicalTransmissionRating: null,
  mileage: null,
  minimalCR: null,
  model: null,
  notes: [],
  numberOfDoors: null,
  numberOfPassengers: null,
  operable: true,
  paintCondition: null,
  photos: [],
  pickupLocation: {
    address1: null,
    address2: null,
    city: null,
    country: null,
    countryCode: null,
    created: null,
    formattedTaxRateRegionCode: null,
    id: null,
    latitude: null,
    longitude: null,
    name: null,
    public: null,
    region: null,
    regionCode: null,
    taxRateForRegionCode: null,
    updated: null,
    zipCode: null,
  },
  printableUrl: null,
  redirectPath: null,
  releaseStatus: null,
  runNumber: null,
  selectedOptions: [],
  sellHoldStatus: SellHoldStatus.NO_HOLD,
  sellerNotes: null,
  sellerReps: [],
  source: null,
  status: null,
  stickerUrl: null,
  stockNumber: null,
  subModel: null,
  tasks: [],
  timelineActions: [],
  tireCondition: null,
  tiresNote: null,
  tiresRating: null,
  transmission: null,
  transmissionBuyerShield: null,
  transportDetails: null,
  trim: null,
  undercarriagePhotos: [],
  uniqueStockNumber: 0,
  updated: null,
  values: null,
  vehicleBuyerShield: null,
  verifiedBy: null,
  vin: null,
  year: null,
});

export const LogEntry = Record({
  color: null,
  type: null,
  message: null,
  note: null,
  created: null,
});

export const InventoryItemDetailsNotes = Record<Note>({
  id: null,
  content: null,
  created: null,
  createdBy: null,
});
