export const carrier = `
  id
  name
  archived
  primaryLocation {
    ...location
  }
  locations {
    name,
    region
  }
  deliveryIsLocalOnly
  deliveryNotes
  deliveryWhitelistedRegions {
    region
    regionCode
  }
  sdCarrierGuid
  shipmentModes
  notes {
    id
    content
    created
    createdBy {
      firstName
      lastName
      photoUrl(width:150, height:150)
      lastLoggedIn
      id
    }
  }
  contacts {
    id
    name
    roles
    email
    phone
    locale
    primary
    note
    archived
  }
`;
