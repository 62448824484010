import classnames from 'classnames';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import style from './section.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The content of the component. */
  children: ReactNode;
  /** The variant to use */
  variant?: 'transparent' | 'solid';
}

const Section = forwardRef<HTMLDivElement, Props>(
  ({ className, children, variant = 'transparent', ...props }: Props, ref) => (
    <div
      {...props}
      ref={ref}
      className={classnames(style.container, { [style.solid]: variant === 'solid' }, className)}
    >
      {children}
    </div>
  )
);

export default Section;
