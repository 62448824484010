import classnames from 'classnames';
import { MouseEvent, PureComponent, ReactNode } from 'react';

import addGlyph from 'glyphs/plus-skinny.svg';
import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import LinkRow from 'components/ui/lists/details/linkRow';
import Sprite from 'components/ui/shared/sprite';
import { Spinner } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './listLayout.scss';

interface ListContainerProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;
}

export class ListContainer extends PureComponent<ListContainerProps> {
  render() {
    return <div className={style.listContainer}>{this.props.children}</div>;
  }
}

interface DetailsProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** A ref to a html element. */
  reference?: (element) => void;
}

export class Details extends PureComponent<DetailsProps> {
  render() {
    return (
      <div
        ref={this.props.reference}
        className={classnames(style.detailsContainer, this.props.className)}
        data-testid="detailsContainer"
      >
        {this.props.children}
      </div>
    );
  }
}

export interface DetailsSectionProps {
  /** The component's child elements to be rendered. */
  children?: ReactNode;
  /** Optional CSS styling. */
  className?: string;
  /** Optional CSS styling for container. */
  containerClassName?: string;
  /** Optional CSS styling for content. */
  contentClassName?: string;
  /** Optional ID for the details section component. */
  dataTestId?: string;
  /** True when content should have CSS padding. */
  hasContentPadding?: boolean;
  /** Optional CSS styling for header. */
  headerClassName?: string;
  /** Html content added to the end of the header. */
  headerEndContent?: ReactNode;
  /** Optional ID for the details section component. */
  id?: string;
  /** Optional CSS styling for the inner container. */
  innerContainerClassName?: string;
  /** True when children should be rendered. */
  isChildrenRenderedWhenEmpty?: boolean;
  /** True when there are no details to display. */
  isEmpty?: boolean;
  /** Function invoked when details sub-button clicked. */
  onClickSubButton?: (() => void) | null;
  /** Test id for detail sub button. */
  subButtonDataTestId?: string;
  /** The type of the sub button. */
  subButtonType?: 'add' | 'edit';
  /** The title of the details section. */
  title?: string;
  /** Optional CSS styling for the title. */
  titleClassName?: string;
}

export class DetailsSection extends PureComponent<DetailsSectionProps> {
  static defaultProps = {
    hasContentPadding: true,
    subButtonDataTestId: 'details-section-sub-button',
    subButtonType: 'add',
  };

  render() {
    const {
      children,
      className,
      containerClassName,
      contentClassName,
      dataTestId,
      hasContentPadding,
      headerClassName,
      headerEndContent,
      id,
      innerContainerClassName,
      isChildrenRenderedWhenEmpty,
      isEmpty,
      onClickSubButton,
      subButtonDataTestId,
      subButtonType,
      title,
      titleClassName,
    } = this.props;

    return (
      <section className={classnames(style.detailsSection, className)} id={id}>
        <div className={classnames(style.detailsSectionContainer, containerClassName)}>
          <div
            className={classnames(style.detailsSectionInnerContainer, innerContainerClassName)}
            data-testid={dataTestId || 'details-section'}
          >
            <div data-testid="admin-details-section">
              {title && (
                <div className={classnames(style.detailsSectionHeader, headerClassName)}>
                  <h4
                    className={classnames(
                      style.detailsSectionTitle,
                      isEmpty ? style.detailsSectionEmpty : titleClassName
                    )}
                  >
                    {title}
                  </h4>
                  {onClickSubButton && (
                    <Button dataTestId={subButtonDataTestId} onClick={onClickSubButton} theme="none">
                      <span
                        className={style.detailsSectionSubButton}
                        title={t(subButtonType === 'add' ? 'add' : 'edit')}
                      >
                        <Sprite className={style.sprite} glyph={subButtonType === 'add' ? addGlyph : editGlyph} />
                      </span>
                    </Button>
                  )}
                  {headerEndContent}
                </div>
              )}
              {(!isEmpty || isChildrenRenderedWhenEmpty) && children && (
                <div
                  className={classnames(
                    style.detailsSectionContent,
                    hasContentPadding && style.hasContentPadding,
                    contentClassName
                  )}
                >
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

interface DetailsSectionAuxButtonProps {
  /** The label of the button */
  label: string;
  /** The callback when clicking the auxiliary section title button */
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
}

export const DetailsSectionAuxButton = ({ label, onClick }: DetailsSectionAuxButtonProps) => {
  return <LinkRow className={style.detailsSectionAuxButton} label={label} onClick={onClick} />;
};

/**
 * DetailsSectionUpdateSpinner
 *
 */
export const DetailsSectionUpdating = () => (
  <DetailsSection>
    <Spinner className={style.detailsSectionUpdatingSpinner} />
  </DetailsSection>
);
