import { Dispatch } from 'react';

import ApiRequest, { ApiResponse, ApiRequestConfig } from 'store/shared/api/apiRequest';
import { ErrorMessages } from 'constants/errors';
import { Pagination } from 'constants/enums/pagination';
import { Route } from 'store/routing/routes';
import { Sort } from 'store/shared/api/graph/interfaces/types';
import { getPermittedAuctionIds } from 'utils/privateLabelUtils';
import { miToKm } from 'utils/numberUtils';
import { storageManager } from 'utils/storageUtils';
import { userDistanceUnit } from 'store/user/userActions';

export const graphBaseUrlStorage = storageManager.createOrFetchStorage('graphBaseUrl');

/**
 * Triggers file download as browser does not prompt by default
 *
 * @param {object} data
 * @param {string} filename
 * @param {string} type
 */
export const downloadAttachment = (data, filename, type = '') => {
  const blob = new Blob([data], { type });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * Downloads a PDF from a specified URL and saves it to the user's computer.
 *
 * @param fileUrl The url of the file to download
 * @param fileName The name to use when saving the file
 */
export const downloadPDF = (fileUrl: string, fileName: string) => {
  getRequestData<Blob>({ url: fileUrl, responseType: 'blob' })?.then((data) => downloadAttachment(data, fileName));
};

/**
 * Send GET request to the specified url and returns the data of the response
 *
 * @param options Axios request options
 */
export const getRequestData = <TData>(options: ApiRequestConfig<false, string>): Promise<TData | undefined> =>
  ApiRequest<TData, false, string>({
    method: 'GET',
    ...options,
  }).then((response) => response?.data);

/**
 * Determines if the response sequence index is the latest.
 *
 * @see utils/apiRequest
 * @param {object} sequenceStats
 * @returns {boolean}
 */
export const isLatestRequestSequence = (sequenceStats) => {
  if (sequenceStats) {
    const { current, latest } = sequenceStats;
    return current === latest;
  }
  return true;
};

/**
 * Returns the base URL for graphQL from local storage or process.env
 *
 * @returns {string}
 */
export const getGraphBaseURL = (): string => graphBaseUrlStorage.get() || (process.env.REACT_APP_GRAPH_BASE as string);

/**
 * Sets the base URL for graphQL in localStorage
 *
 * @param {string} url
 */
export const setGraphBaseURL = (url) => {
  if (url === undefined) {
    console.warn('Failed to change graph base, no url specified');
    return;
  }

  if (url === null) {
    graphBaseUrlStorage.remove();
  } else {
    graphBaseUrlStorage.set(url);
  }

  window.location.reload();
};

/**
 * ParseQueryConnectionResponse
 *
 * @example apiRequest().then(response => parseQueryConnectionResponse(response?.data?.data?.auctionItemConnection))
 * @param {TConnectionResponse} response
 * @returns {Array<TConnectionNode> | undefined}
 */
export const parseQueryConnectionResponse = <
  TConnectionResponse extends { edges: Array<{ node: any }> },
  TConnectionNode = TConnectionResponse['edges'][number]['node'],
>(
  response: TConnectionResponse | undefined
) => response?.edges?.map<TConnectionNode>((edge) => edge.node);

/**
 * Set a components `errorMessages` state using the error response from an api request.
 *
 * @param error - The request's error response
 * @param setErrorStateCb - The `setErrorMessages` hook function
 */
export const onApiError = (error: any, setErrorStateCb: Dispatch<any>) =>
  setErrorStateCb?.(getErrors(error).map(({ name, message }) => ({ name, message })));

/**
 * Gets an API network request's error response.
 *
 * Looks for errors in the following priority:
 * - ApiRequest (Axios): "error?.response?.data?.errors"
 * - ApolloClient (from server): "error?.networkError?.result?.errors"
 * - ApolloClient (from ApolloClient): "error?.networkError"
 * - ApiRequest (Axios): "error"
 * - Simple Error: "{ name: 'Unknown Error', message: error }"
 */
export const getErrors = (error) => {
  if (error?.response?.data?.errors) {
    // ApiRequest (Axios)
    return error?.response?.data?.errors;
  }

  if (error?.networkError?.result?.errors) {
    // ApolloClient
    return error?.networkError?.result?.errors;
  }

  // Other, less used alternatives
  return error?.networkError
    ? [error?.networkError]
    : [error?.message ? error : { name: 'Unknown Error', message: error }];
};

/**
 * Gets an API network request's error response as `ErrorMessages`.
 *
 * @see ErrorMessages
 */
export const getErrorMessages = (error): ErrorMessages => {
  return getErrors(error)?.map(({ name, message }) => ({ name, message }));
};

/**
 * Formats options to include the requestSequence info.
 * This helps us determine if the response is from the latest request and if it should be ignored.
 *
 * @see utils/apiRequest
 * @see utils/apiUtils.isLatestRequestSequence
 */
export const getOptionsWithRequestSequence = <
  TData extends Record<string, unknown> | unknown = Record<string, unknown>,
  IsGraphQL extends boolean = true,
  TOptions = any,
>(
  response: Awaited<ApiResponse<TData, IsGraphQL>>,
  options: TOptions
) => {
  const requestSequence = response?.stats?.requestSequence;
  return { ...options, requestSequence };
};

export type QueryParams<T extends Record<string, unknown>> = T & {
  /** Specifies the cursor for pagination after a certain point. */
  after?: string | null;
  /** Specifies the cursor for pagination before a certain point. */
  before?: string | null;
  /** Limits the number of items to retrieve in the first page of results. */
  first?: number | null;
  /** Format to filter the query by. */
  format?: string | null;
  /** An array of formats to filter the query by. */
  formats?: T['formats'] | string[] | null;
  /** Limits the number of items to retrieve in the last page of results. */
  last?: number | null;
  /** Specifies the sorting order for the query results. */
  sort?: (Sort | null)[] | null;
};

/*
 * We've had lingering issues with invalid params throwing errors.
 * This parses out unwanted key/values.
 *
 * https://github.com/carmigo/eblock-web/issues/1150
 **/
const cleanQueryParams = <T extends Record<string, unknown>>(params: QueryParams<T>): QueryParams<T> => {
  const qParams = { ...params };

  if (qParams.sort) {
    // Check for invalid `sort` param
    if (typeof qParams.sort === 'string') {
      try {
        qParams.sort = JSON.parse(qParams.sort);
      } catch (err) {
        delete qParams.sort;
      }
    }
  }

  if (qParams.format) {
    // Convert `format` to `formats`
    if (!qParams.formats) {
      qParams.formats = [qParams.format];
    }
    delete qParams.format;
  }
  return qParams;
};

export const formatMileageValues = <T extends Record<string, unknown>>(params: QueryParams<T>): QueryParams<T> => {
  if (userDistanceUnit?.get() !== 'MI') {
    return params;
  }

  let paramsNext = { ...params };
  const valuesToFormat = [
    'distance',
    'distanceFromPreferredLocation',
    'maxMileage',
    'mileageGTE',
    'mileageLTE',
    'minMileage',
  ];

  Object.keys(paramsNext).forEach((key) => {
    if (valuesToFormat.includes(key)) {
      paramsNext = Object.assign(paramsNext, { [key]: miToKm(Number(params[key])) });
    }
  });
  return paramsNext;
};

export const parsePrivateLabelQueryParams = <T extends Record<string, unknown>>(
  query: QueryParams<T>
): QueryParams<T> => {
  const isAuctionView = [Route.BUY_TIMED_AUCTION, Route.BUY_RUN_LIST].includes(window.location.pathname as Route);
  if (isAuctionView && process.env.PRIVATE_LABEL) {
    const auctionIds = getPermittedAuctionIds();
    return { ...query, auctionIds };
  }
  return query;
};

export const parseQueryParams = <T extends Record<string, unknown>>(
  params: QueryParams<T>,
  resultsPerPage: number = Pagination.LIST_LENGTH
): QueryParams<T> => {
  let query = cleanQueryParams(params);
  query = formatMileageValues(query);
  query = parsePrivateLabelQueryParams(query);

  const { filterBy } = query;
  if (filterBy === 'Outbid' || filterBy === 'Winning') {
    return { ...query, first: 499, before: null, after: null };
  }

  const queryParam = !query.before ? 'first' : 'last';
  const queryValue = Number(query[queryParam]);
  return {
    ...query,
    [queryParam]: !isNaN(queryValue) ? queryValue : resultsPerPage,
  };
};
