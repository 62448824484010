import classnames from 'classnames';
import { debounce } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Select from 'forms/shared/select';
import { Auction } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getAuctions } from 'store/shared/api/graph/queries/auctions';
import { getField } from 'utils/objectUtils';
import { t } from 'utils/intlUtils';

import style from './select.scss';

export type SelectAuctionProps = {
  /** CSS style to override default style. */
  className?: string;
  /** Whether to include the default bottom margin. */
  hasBottomMargin?: boolean;
  /** Whether the selection is clearable or not. */
  isClearable?: boolean;
  /** Whether the selection is disabled or not. */
  isDisabled?: boolean;
  /** Whether is multi selection or not. */
  isMulti?: boolean;
  /** On select value change. */
  onChange?(value: string): void;
  /** Overwrite the default placeholder of selector. */
  placeholder?: string;
  /** Selected auction id. */
  value?: string | SelectOption[];
};

interface SelectAuctionState {
  /** List of available auctions. */
  auctions?: Auction[];
  /** Selected auction id. */
  value?: string | SelectOption[];
}

class SelectAuction extends BaseClass<SelectAuctionProps, SelectAuctionState> {
  constructor(props: SelectAuctionProps) {
    super(props);

    // Initialize state
    this.state = {
      auctions: [],
      value: getField(props, 'value'),
    };

    // Fetch auctions
    this.getAuctions();
  }

  static defaultProps = {
    className: undefined,
    hasBottomMargin: true,
    isMulti: false,
    onChange: () => {},
    placeholder: undefined,
    value: undefined,
  };

  onChange = (selectedOptions) => {
    // If multi-select is enabled return an array of selected items; otherwise just pass a string of the auctionId.
    const value = this.props.isMulti ? selectedOptions : getField(selectedOptions, 'value', '');

    this.setState({ value });
    this.props.onChange?.(value);
  };

  onInputChange = debounce((keyword, inputActionMeta) => {
    if (this._isMounted && inputActionMeta.action === 'input-change') {
      this.getAuctions(keyword);
    }
  }, 250);

  getAuctions = (keyword: string = '') => {
    getAuctions({ keyword })
      .then((response) => {
        const auctions = response?.data?.data?.auctionConnection?.edges?.map((auctionEdge) => auctionEdge?.node);
        this.setState({ auctions });
      })
      .catch(() => {
        // Query error
      });
  };

  render() {
    const { className, hasBottomMargin, placeholder } = this.props;
    const { auctions, value: selectedValue } = this.state;

    const options: SelectOption[] | undefined = auctions?.map(({ id, title }) => ({ value: id, label: title }));

    // Find the selected option if value is not an array
    const selectedOption: SelectOption | SelectOption[] | null = Array.isArray(selectedValue)
      ? selectedValue
      : options?.find(({ value }) => value === selectedValue) || null;

    return (
      <Select
        {...this.props}
        className={classnames(style.selectInput, { [style.hasBottomMargin]: hasBottomMargin }, className)}
        id="selectAuction"
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        options={options}
        placeholder={placeholder ?? t('choose_auction')}
        theme="green"
        value={selectedOption}
      />
    );
  }
}

export default SelectAuction;
