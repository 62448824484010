import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import { VehicleBuilder } from 'store/shared/api/graph/interfaces/types';

interface MutationInventoryItemUpdate extends Omit<VehicleBuilder, 'vehicle'> {
  vehicle?: InventoryItem | null;
}

/**
 * A central utility to modify inventoryItems using the builder. Add additional param/query fields as necessary.
 *
 * @param options
 */
export const inventoryItemUpdate = (options) =>
  ApiRequest<{ inventoryItemUpdate: MutationInventoryItemUpdate }>({
    data: {
      operationName: 'inventoryItemUpdate',
      query: `
        mutation ($inventoryItemId: String!, $vehicle: VehicleInput){
          inventoryItemUpdate(inventoryItemId: $inventoryItemId, vehicle: $vehicle) {
            ...on VehicleBuilder {
              vehicle {
                id
                legalSaleDate
                transportDetails {
                  id
                  value
                }
              }
            }
          }
        }
      `,
      variables: options,
    },
  });
