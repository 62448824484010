import { Conversation } from '@twilio/conversations';

/**
 * Map for quick lookup of conversations registered with Twilio in this chat session
 */
export interface RegisteredConversations {
  [key: string]: Conversation;
}

/**
 * Extra data sent in the message attributes to Twilio
 */
export interface MessageMetaData {
  /** The id of the auction item currently under auction */
  auctionItemId: string;
  /** Conversation Id that the message belongs to */
  conversationId: string;
  /** The current bid amount at the time the message is sent.  Can be null if no bid has been made */
  currentBidAmount?: number;
  /** The session id of the current user who is logged in */
  sessionId?: string;
}

/**
 * Enum that describes the current connection state of the chat window with Twilio
 */
export enum ConnectionState {
  CONNECTED,
  DISCONNECTED,
  ERROR,
  UNAUTHORIZED,
}
