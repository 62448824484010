import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  notesCleared,
  notesUpdating,
  notesLoaded,
  notesSetCurrentCompanies,
  currentNotesCompanies,
} from './notesActions';
import { InitialState, Note } from './notesModels';

export const notesReducer = handleActions(
  {
    [LOCATION_CHANGE]: (state) => new InitialState({ currentNotesCompanies: state?.currentNotesCompanies }),

    [notesCleared().type]: (state) => {
      currentNotesCompanies?.remove();
      return state?.set('currentNotesCompanies', null);
    },

    [notesUpdating().type]: (state) => state.setUpdating(),

    [notesLoaded().type]: (state, action) => {
      const { data, entityType } = action.payload;
      return state.setLoaded().set(entityType, data && data.map((note) => new Note(note)));
    },

    [notesSetCurrentCompanies().type]: (state, action) => state?.set('currentNotesCompanies', action?.payload),
  },
  new InitialState()
);
