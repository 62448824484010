import { ReactNode } from 'react';
import classnames from 'classnames';

import style from './titleBanner.scss';

export type TitleBannerTheme = 'black' | 'blue' | 'green' | 'red' | 'yellow';

interface Props {
  /** Child elements to be rendered. */
  children: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The colour theme of the banner. */
  theme: TitleBannerTheme;
  /** The title displayed in the banner. */
  title: string;
}

const TitleBanner = ({ children, className, theme, title }: Props) => {
  return (
    <div className={classnames(style.banner, style[`theme-${theme}`], className)}>
      <div className={style.title}>{title}</div>
      <span>{children}</span>
    </div>
  );
};

export default TitleBanner;
