import { ReactNode } from 'react';
import classnames from 'classnames';

import Button from 'components/ui/shared/button';

import style from './multiSelectCard.scss';

const MultiSelectCard = ({
  checkbox,
  children,
  className,
  dataTestId = 'result-button',
  isDisabled,
  isSelected,
  onSelect = () => {},
  subTitle,
  title,
}: {
  checkbox?: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  dataTestId?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onSelect: () => void;
  subTitle?: string;
  title?: ReactNode | ReactNode[];
}) => (
  <Button
    className={classnames(
      style.container,
      isDisabled && style.containerDisabled,
      isSelected && style.containerSelected,
      className
    )}
    dataTestId={dataTestId}
    disabled={isDisabled}
    onClick={onSelect}
    theme="none"
  >
    <div className={style.content}>
      {children || (
        <>
          {title}
          {subTitle && <div className={style.description}>{subTitle}</div>}
        </>
      )}
    </div>

    {!isDisabled && (
      <>
        {!checkbox ? (
          <div className={classnames(style.dot, isSelected && style.dotSelected)} />
        ) : (
          <div className={classnames(style.checkbox, isSelected && style.checkboxSelected)} />
        )}
      </>
    )}
  </Button>
);

export default MultiSelectCard;
