import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { concat } from 'lodash-es';

import SimulcastChatManager from 'io/twilio/simulcastChatManager';
import { ConversationMessage } from 'store/shared/api/graph/interfaces/types';
import { processSetCurrentConversationMessages } from 'store/chat/chatActions';
import { ConversationState } from 'store/chat/chatModels';

interface Props {
  /** Chat manager broker for twillio events */
  chatManager: SimulcastChatManager;
  /** The list of conversations to load messages for */
  conversations?: ConversationState[];
  /** Set error state */
  setError: (err: Error) => void;
}

/**
 * This effect will be invoked after initial conversation load and when a conversation is loaded in the main pane.
 * It will retrieve the messages from Twillio and load them into the conversation.
 */
export const useLoadMessages = ({ chatManager, conversations, setError }: Props) => {
  const dispatch = useDispatch();

  /**
   * When a currentConversation is set, we can proceed to get the messages from Twillio
   */
  useEffect(() => {
    const conversationsReady: ConversationState[] | undefined = conversations?.filter(
      (conversation) => conversation.listening && !conversation.messagesLoaded
    );

    if (!conversationsReady || conversationsReady?.length === 0) {
      return;
    }

    Promise.all(
      conversationsReady?.map(async (conversation) => {
        return chatManager
          ?.loadAllMessages(conversation?.id)
          ?.then((messages: ConversationMessage[]) => {
            return messages;
          })
          ?.catch((err) => {
            return err;
          });
      })
    )
      .then((allMessages: ConversationMessage[][]) => {
        let newMessages: ConversationMessage[];
        if (allMessages?.length === 1) {
          newMessages = allMessages[0];
        } else {
          newMessages = concat(...allMessages);
          newMessages.sort(
            (a: ConversationMessage, b: ConversationMessage) =>
              new Date(a.created).getTime() - new Date(b.created).getTime()
          );
        }

        processSetCurrentConversationMessages(
          {
            conversationIds: conversationsReady.map((conversation) => conversation.id),
            messages: newMessages,
          },
          dispatch
        );
      })
      .catch((err) => {
        setError(err);
      });
  }, [chatManager, conversations, dispatch, setError]);
};
