import { List } from 'immutable';

import AuctionItemType from 'constants/auctionItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  AuctionItemSavedFilter,
  Facet as FacetType,
  FacetGroup as FacetGroupType,
  FacetedPageInfo,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export interface AuctionItemModel extends AuctionItemType {
  /** Animation class name. */
  _animateClass?: string;
  /** Whether the auction item has ended. */
  _ended?: boolean;
}

export interface AuctionItemsResultModel {
  /** List of auction item end times. */
  auctionItemEndTimes: List<FacetType>;
  /** Auction items facet groups. */
  facetGroups: List<RecordOf<FacetGroupModel>>;
  /** Auction items filters. */
  filters: RecordOf<FiltersModel>;
  /** Whether is adding auction item or not. */
  isAdding: boolean;
  /** Count of new auction items. */
  newItemsCount: number;
  /** Information about pagination. */
  pageInfo: RecordOf<FacetedPageInfo>;
  /** List of auction items. */
  resultList: List<RecordOf<AuctionItemModel>>;
  /** Saved auction item filters. */
  savedFilters: AuctionItemSavedFilter[] | null;
  /** Sort information. */
  sort: RecordOf<SortModel>;
  /** List of submitting auction item ids. */
  submittingItemIds: string[];
}

export type AuctionItemsResultsProps = ServerRecordOf<AuctionItemsResultModel>;
export type AuctionItemsResultsPropsJs = ReturnType<AuctionItemsResultsProps['toJS']>;

const sliderInitialValues = Object.freeze({
  mileage: [0, 300000],
  vehicleScore: [0, 5],
  title: undefined,
  year: [1900, 2017],
});

export type AuctionItemProps = RecordOf<AuctionItemModel>;
export type AuctionItemPropsJs = ReturnType<AuctionItemProps['toJS']>;

/** By using a class constructor for Record we are able to prototype functionality on to the items allowing us to keep
 * our code more dry as well as allowing better testability. a side benefit of this is when inspecting code the object
 * will show in the inspector as a type of this class as apposed to a generic Record.
 *
 * constructor is optional and not needed, super must be called if using a constructor. however without a constructor
 * we get an error when instantiating the class in intelaSense
 **/
export const AuctionItem = Record<AuctionItemModel>({
  _animateClass: null,
  _ended: false,
  archived: false,
  asIs: false,
  auction: null,
  auctionServices: [],
  auctionTimeSlot: null,
  auctionTimeSlotLane: null,
  autoBids: null,
  bidExtension: 0,
  bidTimeline: null,
  buyNowPrice: null,
  buyerReps: [],
  canReverseInvoicesOnCancel: false,
  checkoutExpiry: null,
  colorScheme: null,
  created: null,
  createdBy: null,
  current: null,
  deliveryDate: null,
  displayRunNumber: null,
  format: null,
  furtherBidIncrement: null,
  hideSellerNameEnabled: false,
  history: null,
  holdbackActive: false,
  id: null,
  ifBidClaimedByUserId: null,
  ifBidClaimedByUserName: null,
  ifBidTimeline: null,
  inventoryItem: null,
  invoices: [],
  isAssured: false,
  isMyItem: null,
  listPrice: null,
  nextBidAmount: null,
  notes: [],
  ranOn: null,
  redirectPath: '',
  releaseBlockers: [],
  releaseStatus: null,
  reserveMet: false,
  reserveType: null,
  saleLights: [],
  sellerReps: [],
  sellerTier: null,
  startingBid: null,
  status: null,
  tasks: [],
  timedOfferTimeline: null,
  timelineActions: [],
  timerEnd: null,
  timerText: null,
  topOffer: null,
  transactionDetails: null,
  transactionType: null,
  updated: null,
  warnings: [],
  watchers: null,
  workflowProcesses: [],
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});

export interface FacetGroupModel extends Omit<FacetGroupType, 'facets'> {
  /** List of facets. */
  facets: List<RecordOf<FacetType>>;
}

export const FacetGroup = Record<FacetGroupModel>({
  name: null,
  allowMultiple: false,
  facets: null,
});

export const Facet = Record<FacetType>({
  name: null,
  localizedName: null,
  count: null,
  selected: false,
});

interface FiltersModel {
  /** Mileage */
  mileage: number[];
  /** Mileage title */
  mileageTitle: string | undefined;
  /** Vehicle score */
  vehicleScore: number[];
  /** Vehicle score title */
  vehicleScoreTitle: string | undefined;
  /** Year */
  year: number[];
  /** Year title */
  yearTitle: string | undefined;
}

export const Filters = Record<FiltersModel>({
  mileage: sliderInitialValues.mileage,
  mileageTitle: sliderInitialValues.title,
  vehicleScore: sliderInitialValues.vehicleScore,
  vehicleScoreTitle: sliderInitialValues.title,
  year: sliderInitialValues.year,
  yearTitle: sliderInitialValues.title,
});

interface SortModel {
  /** True, sort by ascending else descending */
  ascending: boolean;
  /** Sort Id */
  sortId: string;
}

export const Sort = Record<SortModel>({
  sortId: null,
  ascending: false,
});

export const AuctionItemsResults = ServerRecord<AuctionItemsResultModel>({
  auctionItemEndTimes: List([]),
  facetGroups: List([]),
  filters: new Filters({}),
  isAdding: false,
  newItemsCount: 0,
  pageInfo: null,
  resultList: List([]),
  savedFilters: null,
  sort: new Sort(),
  submittingItemIds: [],
});
