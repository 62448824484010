import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionTimeSlotConnection, QueryauctionTimeSlotConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { facetGroups, pageInfo } from 'store/shared/api/graph/schema/connection';

export const getNextAuctions = (options: QueryauctionTimeSlotConnectionArgs) =>
  ApiRequest<{ auctionTimeSlotConnection: AuctionTimeSlotConnection }>({
    data: {
      operationName: 'getNextAuctions',
      query: `
        query(
          $after: String,
          $auctionIds: [String],
          $auctionTitles: [String],
          $before: String,
          $cutOffTimeGTE: DateTime,
          $cutOffTimeLTE: DateTime,
          $events: String,
          $first: Int,
          $formats: [AuctionTimeSlotFormat!],
          $last: Int,
          $keyword: String,
          $sort: [Sort],
          $status: [AuctionTimeSlotStatus],
          $startTimeGTE: DateTime,
          $startTimeLTE: DateTime,
        ) {
          auctionTimeSlotConnection(
            after: $after,
            auctionIds: $auctionIds,
            auctionTitles: $auctionTitles,
            before: $before,
            cutOffTimeGTE: $cutOffTimeGTE,
            cutOffTimeLTE: $cutOffTimeLTE,
            events: $events,
            first: $first,
            formats: $formats,
            keyword: $keyword,
            last: $last,
            sort: $sort,
            status: $status,
            startTimeGTE: $startTimeGTE,
            startTimeLTE: $startTimeLTE,
          ) {
            edges {
              node {
                id
                auction {
                  title
                }
                auctionTimeSlotLanes {
                  id
                  name
                }
                startTime
                itemCount
              }
            }
            ${facetGroups}
            ${pageInfo}
          }
        }
      `,
      variables: options,
    },
  });
