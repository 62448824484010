import classnames from 'classnames';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import SoundIndicator from 'components/ui/media/video/soundIndicator';
import VideoControl from 'components/ui/media/video/videoControl';

import style from './videoStream.scss';

export enum VideoControlPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
}

export interface VideoStreamRef {
  /** Toggle fullscreen. */
  toggleFullScreen: () => void;
}

interface HTMLVideoElementWithCaptureStream extends HTMLVideoElement {
  /**
   * Returns a MediaStream object which is streaming a real-time capture of the content being rendered
   * in the media element.
   * Note: As of Dec 2022, Safari does not support capture stream from video.
   */
  captureStream?(frameRequestRate?: number): MediaStream;
}

export interface VideoStreamProps {
  /** Whether the video should autoplay or not. */
  autoPlay?: boolean;
  /** CSS style to override default style. */
  className?: string;
  /**
   * Control button position.
   * @default 'bottom'
   */
  controlPosition?: VideoControlPosition;
  /** Whether the player is hidden or not. */
  isHidden?: boolean;
  /**
   * Whether the video is muted not.
   * @default true
   */
  isMuted?: boolean;
  /** Media to be streamed. */
  loop?: boolean;
  /** The address or URL of the media resource. */
  mediaStream?: MediaStream;
  /** Callback function to close the media stream from the src media resource. */
  onSrcMediaStreamClose?: () => void;
  /** Callback function to notify when the src media resource stream is ready. */
  onSrcMediaStreamReady?: (value: MediaStream | undefined) => void;
  /**
   * Callback function to override the fullscreen toggle.
   * If not provided, the default fullscreen toggle will be used.
   */
  onToggleFullScreenOverride?: () => void;
  /**
   * Whether audio is overridden somewhere else.
   * True - mute and stop the audio from playing since it's been played elsewhere
   */
  overrideAudio?: boolean;
  /**
   * Whether the audio playback is set to a different destination.
   * True - the audio still plays, but not sending to the current speaker
   */
  overrideAudioDestination?: boolean;
  /** Set if the video is muted or not. */
  setIsMuted?: (isMuted: boolean) => void;
  /**
   * Whether to show the controls or not.
   * @default true
   */
  showControls?: boolean;
  /** Whether to show full screen button or not. */
  showFullScreenButton?: boolean;
  /** Whether to show mute button or not. */
  showMuteButton?: boolean;
  /**
   * Whether to show the video or not.
   * @default true
   */
  showVideo?: boolean;
  /**
   * Whether to show the sound indicator or not.
   * @default false
   */
  showSoundIndicator?: boolean;
  /** CSS style to override default sound indicator style. */
  soundIndicatorClassName?: string;
  /** The address or URL of the media resource. */
  src?: string;
}

const VideoStream = forwardRef<VideoStreamRef, VideoStreamProps>(
  (
    {
      autoPlay = true,
      className,
      controlPosition = VideoControlPosition.BOTTOM,
      isHidden,
      isMuted = true,
      loop,
      mediaStream,
      onSrcMediaStreamClose,
      onSrcMediaStreamReady,
      onToggleFullScreenOverride,
      overrideAudio,
      overrideAudioDestination,
      setIsMuted,
      showControls = true,
      showFullScreenButton,
      showMuteButton,
      showSoundIndicator = false,
      showVideo = true,
      soundIndicatorClassName,
      src,
      ...props
    },
    ref
  ) => {
    const [audioContext, setAudioContext] = useState<AudioContext>();
    const [audioDestination, setAudioDestination] = useState<MediaStreamAudioDestinationNode>();
    const [audioSource, setAudioSource] = useState<MediaElementAudioSourceNode>();
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isVideoMuted, setIsVideoMuted] = useState<boolean>(isMuted);
    const [srcStream, setSrcStream] = useState<MediaStream>();
    const playerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElementWithCaptureStream>(null);

    /**
     * Override audio destination if required
     */
    useEffect(() => {
      const video = videoRef.current;

      // Only create the audio context and override the destination when the video is playing and the sound is not muted.
      // This is to prevent failing to access the audio context when the user has not interacted with the dom yet.
      if (video && !audioSource && isPlaying && !isVideoMuted && overrideAudioDestination) {
        const context = new AudioContext();
        const destination = context.createMediaStreamDestination();
        const source = context.createMediaElementSource(video);
        source.connect(destination);
        setAudioDestination(destination);
        setAudioContext(context);
        setAudioSource(source);
      }

      // Handel override audio destination change
      if (audioSource && audioContext) {
        try {
          if (overrideAudioDestination) {
            // Disconnect to audio context destination if overrideAudioDestination is set to true
            audioSource.disconnect(audioContext.destination);
          } else {
            // Connect to audio context destination if overrideAudioDestination is set to false so that
            // the audio is sent to the speaker
            audioSource.connect(audioContext.destination);
          }
        } catch {
          // The destination was not connected, do nothing.
        }
      }

      return () => {
        if (!video) {
          audioDestination?.disconnect();
          audioSource?.disconnect();
          audioContext?.close();
          setAudioContext(undefined);
          setAudioDestination(undefined);
          setAudioSource(undefined);
        }
      };
    }, [audioContext, audioDestination, audioSource, isPlaying, isVideoMuted, overrideAudioDestination]);

    /**
     * Set media stream for video on change
     */
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        if (src) {
          video.loop = !!loop;
          video.srcObject = null;
          video.src = src;
        } else {
          video.src = '';
          video.srcObject = mediaStream || null;
        }
      }
    }, [loop, mediaStream, src]);

    /**
     * Autoplay the video
     */
    useEffect(() => {
      const video = videoRef.current;
      if (video?.paused && autoPlay && (mediaStream || src)) {
        // Autoplay the stream video
        video.play()?.catch((e) => {
          if (video) {
            if (String(e).includes('interact')) {
              // Unable to autoplay the video due to user has not interacted with the page, set video to muted and retry
              setIsVideoMuted(true);
              setIsMuted?.(true);
              video.muted = true;
            }
            video.play()?.catch(() => {
              // Do nothing.
            });
          }
        });
      } else if (!autoPlay) {
        video?.pause?.();
      }
    }, [autoPlay, mediaStream, setIsMuted, src]);

    /**
     * Update local state if is muted changed
     */
    useEffect(() => {
      setIsVideoMuted(isMuted);
    }, [isMuted]);

    /**
     * Capture video stream for src media source on can play
     */
    const onCanPlay = useCallback(() => {
      if (src) {
        const stream = videoRef.current?.captureStream?.();
        setSrcStream(stream);
        onSrcMediaStreamReady?.(stream);
      }
    }, [onSrcMediaStreamReady, src]);

    /**
     * On pause, set is playing to false
     */
    const onPause = useCallback(() => {
      setIsPlaying(false);
    }, []);

    /**
     * On play, set is playing to true
     */
    const onPlay = useCallback(() => {
      setIsPlaying(true);
    }, []);

    /**
     * Toggles fullscreen on/off
     */
    const toggleFullScreen = useCallback(() => {
      if (onToggleFullScreenOverride) {
        onToggleFullScreenOverride();
        return;
      }

      if (document.fullscreenEnabled) {
        if (document.fullscreenElement) {
          document.exitFullscreen?.();
        } else {
          playerRef.current?.requestFullscreen?.();
        }
      }
    }, [onToggleFullScreenOverride]);

    /**
     * Expose toggle fullscreen function to parent component
     */
    useImperativeHandle(
      ref,
      () => ({
        toggleFullScreen,
      }),
      [toggleFullScreen]
    );

    /**
     * Toggles video's mute setting on/off
     */
    const toggleMute = useCallback(() => {
      setIsVideoMuted(!isVideoMuted);
      setIsMuted?.(!isVideoMuted);
    }, [isVideoMuted, setIsMuted]);

    return (
      <div ref={playerRef} className={classnames(style.playerContainer, className)} hidden={isHidden} {...props}>
        {showVideo && (
          <video
            ref={videoRef}
            className={style.videoStream}
            controls={false}
            data-testid="video"
            muted={overrideAudio || isVideoMuted}
            onCanPlay={onCanPlay}
            onPause={onPause}
            onPlay={onPlay}
            playsInline
          />
        )}
        {showSoundIndicator && (
          <SoundIndicator
            audioTracks={(mediaStream ?? srcStream ?? audioDestination?.stream)?.getAudioTracks()}
            className={classnames(style.soundIndicator, soundIndicatorClassName)}
          />
        )}
        {showControls && (
          <VideoControl
            className={classnames(style.videoControl, style[controlPosition])}
            isMuted={isVideoMuted}
            showFullScreenButton={showFullScreenButton}
            showMuteButton={showMuteButton}
            toggleFullScreen={toggleFullScreen}
            toggleMute={toggleMute}
          />
        )}
      </div>
    );
  }
);

export default VideoStream;
