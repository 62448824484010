import { connect, ConnectedProps } from 'react-redux';

import removeGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { MutationauctionItemSetAutoBidArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { processSetAuctionItemAutobid } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function used to update the autobid. */
  setAutobid: (options: MutationauctionItemSetAutoBidArgs) => processSetAuctionItemAutobid(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** True when autobid button is in a list. */
  isListView?: boolean;
  /** Function invoked after autobid has been removed. */
  onDone?: (value?: boolean) => void;
}

class RemoveAutobid extends BaseClass<Props> {
  static defaultProps = {
    onDone: () => {},
  };

  onSubmit = (e) => {
    const { auctionItem, isListView, setAutobid, onDone } = this.props;

    e.preventDefault();
    setAutobid({ auctionItemId: auctionItem?.id, maxBidPrice: 0 })
      .then(() => {
        const action = isListView ? UserAction.RUN_LIST_AUTOBID_REMOVE_CLICK : UserAction.VDP_AUTOBID_REMOVE_CLICK;
        trackUserActionWithAuctionItemAttributes(action, auctionItem);
        onDone?.(true);
      })
      .catch((error) => {
        console.info('Error removing autobid: ', error);
      });
  };

  render() {
    const { className } = this.props;

    return (
      <OperationButton
        className={className}
        dataTestId="remove-autobid-button"
        glyph={removeGlyph}
        onClick={(e) => this.onSubmit(e)}
        theme="red"
      >
        {t('remove_autobid')}
      </OperationButton>
    );
  }
}

export default connector(RemoveAutobid);
