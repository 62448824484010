import classnames from 'classnames';
import { debounce } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Select, { SelectProps } from 'forms/shared/select';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { User } from 'store/shared/api/graph/interfaces/types';
import { customOptionFiltering } from 'utils/selectUtils';
import { formatUserName } from 'utils/stringUtils';
import { getRepsFromCompany } from 'store/shared/api/graph/queries/companyUsers';
import { parseQueryParams } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';

import style from './select.scss';

interface SelectCompanyUserResponse {
  id: string;
  cellPhone: string;
  created: string;
  email: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  secondaryPhone: string;
  title: string;
  locale: string;
}

export type SelectCompanyUserOption = SelectOption & SelectCompanyUserResponse;

interface Props extends SelectProps<SelectOption, false> {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The id of the company the users are filtered by */
  companyId?: string;
  /** Add default variable values (IE: type:HOLDING - which queries only holding companies) */
  connectionVariables?: object;
  /**
   *  Adds css margin to bottom of select component.
   *  @default true
   */
  hasBottomMargin?: boolean;
  /**
   * Query function to fetch users.
   * @default getRepsFromCompany() - uses companyUserConnection
   */
  getUsers?(args: any): Promise<any>;
  /** Function invoked on selection change. */
  onChange?: (user: SelectCompanyUserOption | null) => void;
  /** Function invoked on input value change. */
  onInputChange?(args: any): void;
  /** Overwrite the default placeholder of selector */
  placeholder?: string;
}

interface State {
  /** The user options. */
  options: SelectCompanyUserOption[];
  /** The selected user option. */
  selectedOption: SelectCompanyUserOption | null;
}

class SelectCompanyUser extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      options: [],
      selectedOption: props?.value,
    };

    // Fetch users
    this.getUsers();
  }

  static defaultProps = {
    className: undefined,
    companyId: undefined,
    connectionVariables: {},
    getUsers: getRepsFromCompany,
    hasBottomMargin: true,
    onChange: () => {},
    onInputChange: () => {},
    onUserChange: () => {},
    placeholder: undefined,
    value: undefined,
  };

  componentDidUpdate(prevProps) {
    const { companyId } = prevProps;
    const { companyId: companyIdNext } = this.props;

    if (companyId !== companyIdNext) {
      this.onChange(null);
      this.getUsers();
    }
  }

  onChange = (selectedOption: SelectCompanyUserOption | null) => {
    this.setState({ selectedOption });
    this.props.onChange?.(selectedOption);
  };

  onInputChange = debounce((keyword, inputActionMeta) => {
    if (this._isMounted && inputActionMeta.action === 'input-change') {
      this.getUsers(keyword);
    }
  }, 250);

  getUsers = (keyword = '') => {
    const { companyId, connectionVariables } = this.props;
    const options = { keyword, ...(companyId && { companyIds: [companyId] }) };

    this.props?.getUsers?.(parseQueryParams({ ...connectionVariables, ...options })).then((response) => {
      const users: User[] =
        response?.data?.data?.companyUserConnection?.edges?.map((userEdge) => userEdge?.node?.user).filter(Boolean) ||
        [];

      const selectOptions: SelectCompanyUserOption[] = users.map((_user) => ({
        ...(_user as SelectCompanyUserResponse),
        value: _user?.id,
        label: formatUserName(_user)!,
      }));

      this.setState({ options: selectOptions });
    });
  };

  render() {
    const { className, hasBottomMargin, placeholder } = this.props;
    const { options, selectedOption } = this.state;

    return (
      <Select
        theme="green"
        {...this.props}
        className={classnames(style.selectInput, { [style.hasBottomMargin]: hasBottomMargin }, className)}
        filterOption={customOptionFiltering}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        options={options}
        placeholder={placeholder ?? t('choose_user')}
        value={selectedOption}
      />
    );
  }
}

export default SelectCompanyUser;
