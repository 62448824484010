import { ReactNode } from 'react';
import classnames from 'classnames';

import EditButton from 'components/ui/shared/buttons/editButton';
import InputTextEditable from 'forms/shared/inputTextEditable';
import { SecondaryTitle, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { FlexRowSpaceBetween } from 'layouts/shared/flexbox';
import { InputTextType } from 'forms/shared/inputText';

import style from './statusRow.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** ID used for testing. */
  dataTestId?: string;
  /** The type of input. */
  inputType?: InputTextType;
  /** True when edit button is displayed. */
  isEditing?: boolean;
  /** True when edit is being submitted, */
  isSubmitting?: boolean;
  /** Function invoked on edit button click. */
  onEdit?: () => void;
  /** Function invoked on cancel button click. */
  onCancel?: () => void;
  /** Function invoked on submit button click. */
  onSubmit?: (value?: string) => void;
  /** The subtitle of the row. */
  subTitle?: string | ReactNode;
  /** CSS styling to overwrite subtitle style. */
  subTitleClassName?: string;
  /** The title of the row. */
  title: string | ReactNode;
  /** CSS styling to overwrite title style. */
  titleClassName?: string;
}

const StatusRow = ({
  className,
  dataTestId,
  inputType,
  isEditing,
  isSubmitting,
  onEdit,
  onCancel,
  onSubmit,
  subTitle,
  subTitleClassName,
  title,
  titleClassName,
}: Props) => (
  <FlexRowSpaceBetween className={classnames(style.row, className)} data-testid={dataTestId}>
    <TertiaryTitleLight className={classnames(style.title, titleClassName)}>
      {title}
      {!!onEdit && !isEditing && <EditButton className={style.editButton} onClick={onEdit} />}
    </TertiaryTitleLight>
    <SecondaryTitle className={classnames(style.subtitle, subTitleClassName)}>
      {isEditing ? (
        <div className={style.inputContainer}>
          <InputTextEditable
            className={style.input}
            dataTestId={`${dataTestId}-text`}
            defaultValue={subTitle as string}
            isSubmitting={isSubmitting}
            onCancel={onCancel}
            onSubmit={onSubmit}
            type={inputType}
          />
        </div>
      ) : (
        subTitle || '-'
      )}
    </SecondaryTitle>
  </FlexRowSpaceBetween>
);

export default StatusRow;
