import { InventoryItemValue, InventoryItemValueTypeEnum } from 'store/shared/api/graph/interfaces/types';

export const blackBookValueTypes = [
  InventoryItemValueTypeEnum.BLACK_BOOK_ROUGH_COMBINED,
  InventoryItemValueTypeEnum.BLACK_BOOK_AVG_COMBINED,
  InventoryItemValueTypeEnum.BLACK_BOOK_CLEAN_COMBINED,
];

export const blackBookHeaderTranslationMap = {
  [InventoryItemValueTypeEnum.BLACK_BOOK_CLEAN_COMBINED]: 'black_book_clean',
  [InventoryItemValueTypeEnum.BLACK_BOOK_AVG_COMBINED]: 'black_book_average',
  [InventoryItemValueTypeEnum.BLACK_BOOK_ROUGH_COMBINED]: 'black_book_rough',
};

export const getBlackBookValues = (values: InventoryItemValue[] | undefined) => {
  const blackBookValuesFilteredAndSorted = values
    // Filter based on `blackBookValueTypes`
    ?.filter(({ type }) => blackBookValueTypes.includes(type?.id))
    // Match BlackBook ordering to `blackBookValueTypes`
    .sort((a, b) => blackBookValueTypes.indexOf(a?.type?.id) - blackBookValueTypes.indexOf(b?.type?.id));

  return !blackBookValuesFilteredAndSorted?.length ? undefined : blackBookValuesFilteredAndSorted;
};
