import classnames from 'classnames';

import Button, { ButtonTheme } from 'components/ui/shared/button';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLightFormButton.scss';

type Props = {
  /** True when the button is active. */
  isActive: boolean;
  /** True when the button is disabled. */
  isDisabled?: boolean;
  /** Function invoked on button click. */
  onClick?: (saleLight: SaleLight) => void;
  /** The sale light the button represents. */
  saleLight: SaleLight;
};

const SaleLightFormButton = ({ isActive, isDisabled, onClick, saleLight }: Props) => {
  // If active, use the sale light color as the theme; Use `green`
  // (most valid color themes work to utilize the proper disabled theme)
  // when disabled, otherwise omit the value.
  // eslint-disable-next-line no-nested-ternary
  const theme = isActive ? (saleLight.toLowerCase() as ButtonTheme) : isDisabled ? 'green' : 'gray-outline';

  return (
    <span
      className={classnames(isDisabled && style.disabled)}
      data-testid={`sale-light-${saleLight.toLowerCase()}-container`}
    >
      <Button
        className={classnames(style.button, !isActive && !isDisabled && style.deselected)}
        dataTestId={`sale-light-${saleLight.toLowerCase()}`}
        disabled={!isActive && isDisabled}
        onClick={onClick ? () => onClick(saleLight) : undefined}
        theme={theme}
      >
        {t(`color_${saleLight.toLowerCase()}`)}
      </Button>
    </span>
  );
};

export default SaleLightFormButton;
