import { createAction } from 'redux-actions';

import { QueryauctionItemConnectionArgs, QueryauctionTimeSlotArgs } from 'store/shared/api/graph/interfaces/types';
import { getLane, getTimeSlot } from 'store/admin/liveLanes/laneManager/laneManagerApi';
import { parseQueryParams } from 'utils/apiUtils';

export const clearLaneManager = createAction('ADMIN_LANE_MANAGER_CLEAR');
export const isLoading = createAction('ADMIN_LANE_MANAGER_SET_IS_LOADING');
export const isUpdating = createAction('ADMIN_LANE_MANAGER_SET_IS_UPDATING');
export const laneDestinationLoaded = createAction('ADMIN_LANE_MANAGER_LANE_DESTINATION_LOADED');
export const laneSourceLoaded = createAction('ADMIN_LANE_MANAGER_LANE_SOURCE_LOADED');
export const timeSlotLoaded = createAction('ADMIN_LANE_MANAGER_TIME_SLOT_LOADED');

export const processGetTimeSlot = (options: QueryauctionTimeSlotArgs, dispatch) => {
  return getTimeSlot(options)?.then((response) => {
    dispatch(timeSlotLoaded(response?.data?.data?.auctionTimeSlot));
    return response;
  });
};

export const processGetLaneSource = (options: QueryauctionItemConnectionArgs, dispatch) => {
  dispatch(isUpdating());

  return getLane(parseQueryParams(options))?.then((response) => {
    dispatch(laneSourceLoaded(response?.data?.data));
    return response;
  });
};

export const processGetLaneDestination = (options: QueryauctionItemConnectionArgs, dispatch) => {
  dispatch(isUpdating());

  return getLane(parseQueryParams(options))?.then((response) => {
    dispatch(laneDestinationLoaded(response?.data?.data));
    return response;
  });
};
