import { MouseEventHandler, ReactNode } from 'react';

import BaseClass from 'components/ui/shared/base';

interface Props {
  /* Rendered components which trigger the OAuth dialog when clicked */
  children: ReactNode;
  /* Prevents onClick actions from triggering on the OAuth container div;
     should be used in conjunction with `shouldForceTrigger` */
  disableClickTrigger?: boolean;
  /* The height of the OAuth dialog window */
  height: number;
  /* The callback function whenever the window is closed */
  onClose?: () => void;
  /* The callback function once authorization is verified */
  onCode: (code, windowRef) => void;
  /* Used in conjunction with `disableClickTrigger`, where this prop triggers the dialog to open when `true` */
  shouldForceTrigger?: boolean;
  /* The title of the OAuth dialog window */
  title?: string;
  /* The url of the OAuth dialog window */
  url?: string;
  /* The width of the OAuth dialog window */
  width: number;
}

class OAuthDialog extends BaseClass<Props> {
  static defaultProps = {
    height: 500,
    onClose: () => {},
    title: '',
    width: 500,
  };

  /* A `interval` reference; Used to clear the timeout during cleanup. */
  private intervalRef?: number = undefined;

  /* A `window` reference; Used to read the current state of the popup. */
  private windowRef?: Window | null = undefined;

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props?.disableClickTrigger) {
      if (!prevProps?.shouldForceTrigger && this.props?.shouldForceTrigger) {
        this.onTriggerClick(true);
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.windowRef = null;
    this.clearTimer();
  }

  clearTimer = () => {
    window?.clearInterval(this.intervalRef);
  };

  onInterval = () => {
    try {
      const currentUrl = this.windowRef?.location?.href;
      const params = currentUrl ? new URL(currentUrl)?.searchParams : undefined;
      const code = params?.get?.('code');

      if (!code) {
        return;
      }

      this.clearTimer();
      this.props?.onCode(code, this.windowRef);
      this.windowRef?.close?.();
    } catch (error) {
      // Catch error
    } finally {
      if (!this.windowRef || this.windowRef?.closed) {
        this.clearTimer();
        this.windowRef = null;
        this.props?.onClose?.();
      }
    }
  };

  onTriggerClick = (forceTrigger: MouseEventHandler<HTMLDivElement> | boolean = false) => {
    const { disableClickTrigger, height, title, url, width } = this.props;

    if (disableClickTrigger && forceTrigger !== true) {
      return;
    }

    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    this.windowRef = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    this.intervalRef = window.setInterval(this.onInterval, 500);
  };

  render() {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    return <div onClick={this.onTriggerClick as any}>{this.props?.children}</div>;
  }
}

export default OAuthDialog;
