import classnames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

import style from './sectionHeader.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The content of the component. */
  children: ReactNode;
}

const SectionHeader = ({ className, children, ...props }: Props) => (
  <div {...props} aria-level={4} className={classnames(style.header, className)} role="heading">
    <h4 className={style.title}>{children}</h4>
  </div>
);

export default SectionHeader;
