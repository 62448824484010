import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionConnection, QueryauctionConnectionArgs } from 'store/shared/api/graph/interfaces/types';

export const getAuctions = ({ first = 50, keyword = undefined }: QueryauctionConnectionArgs) =>
  ApiRequest<{ auctionConnection: AuctionConnection }>({
    data: {
      operationName: 'getAuctions',
      query: `
        query($first: Int, $keyword: String) {
          auctionConnection(first: $first, keyword: $keyword) {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      `,
      variables: {
        first,
        keyword,
      },
    },
  });
