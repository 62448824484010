import classnames from 'classnames';

import topOfferGlyph from 'glyphs/top-offer.svg';

import AuctionItem from 'constants/auctionItem';
import Sprite from 'components/ui/shared/sprite';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { t, tPlural } from 'utils/intlUtils';

import style from './topOffer.scss';

interface IfBidsRowProps {
  /** The auction item details. */
  details: AuctionItem;
  /** Function invoked on button click. */
  onClick?: (event) => void;
  /** The theme color of the row. */
  theme?: 'green' | 'red';
}

const IfBidsRow = ({ details, theme = 'green', onClick }: IfBidsRowProps) => {
  if (details.ifBidTimeline && details.ifBidTimeline.count) {
    return (
      <Row
        className={classnames(style.ifBids, style[theme])}
        dataTestId="status-row-if-bids"
        name={t('if_bids')}
        onClick={onClick}
      >
        {tPlural('x_if_bids', details.ifBidTimeline.list?.length, [details.ifBidTimeline.list?.length])}
        <Sprite className={style.topOfferIcon} glyph={topOfferGlyph} />
        <div className={style.topOfferAmount}>{details.ifBidTimeline.list?.[0]?.amount?.formattedAmountRounded}</div>
      </Row>
    );
  }
  return <Row name={t('if_bids')}>{t('none')}</Row>;
};

export default IfBidsRow;
