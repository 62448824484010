import AuctionItem from 'constants/auctionItem';
import StatusColumn from 'components/sections/myBlock/list/status/statusColumn';
import StatusRowCost from 'components/sections/myBlock/list/status/statusRowCost';
import StatusRowReserve from 'components/sections/myBlock/list/status/statusRowReserve';

import style from './statusInfo.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

const StatusInfoRunList = ({ auctionItem }: Props) => {
  return (
    <div className={style.container} data-testid="status-info-run-list">
      <StatusColumn>
        <StatusRowReserve auctionItem={auctionItem} dataTestId="reserve-row" />
        <StatusRowCost auctionItem={auctionItem} dataTestId="cost-row" />
      </StatusColumn>
    </div>
  );
};

export default StatusInfoRunList;
