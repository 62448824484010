import { Dispatch, SetStateAction, useCallback } from 'react';

import { AccountVerificationFormStep } from 'forms/account/accountVerification/steps/accountVerificationHelpers';

interface Props {
  /** The current form step */
  currentStep: number;
  /** Callback function to set `currentStep` */
  setIsActionable: Dispatch<SetStateAction<boolean>>;
  /** A form's step config */
  steps: AccountVerificationFormStep[];
}

/**
 * A callback for child form elements to notify whether the
 * submit button is eligible for submission.
 */
export const useIsFormSubmittable = ({ currentStep, setIsActionable, steps }: Props) =>
  useCallback(
    (value: boolean) => {
      if (steps[currentStep].isFormWithValidationRequirements) {
        setIsActionable(value);
      }
    },
    [currentStep, setIsActionable, steps]
  );
