import { ActionPayload, handleActions } from 'redux-actions';

import { AuctionTimeSlot, AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';
import { InitialState } from 'store/admin/events/details/eventDetailsModels';
import {
  eventDetailsCleared,
  eventDetailsIsLoading,
  eventDetailsLoaded,
  eventDetailsUpdated,
  eventLaneAssignedToClerk,
  eventLaneUpdated,
  preloadEventDetails,
} from 'store/admin/events/details/eventDetailsActions';

export const eventDetailsReducer = handleActions(
  {
    [eventDetailsCleared().type]: () => new InitialState(),

    [eventDetailsIsLoading().type]: (state) => state.set('isLoading', true),

    [preloadEventDetails().type]: (state, action) => {
      if (action?.payload?.id) {
        return state.setLoaded().set('details', action?.payload);
      }
      return state;
    },

    [eventDetailsLoaded().type]: (state, action) => {
      return state?.setLoaded()?.set('details', action?.payload);
    },

    [eventDetailsUpdated().type]: (state, action) => {
      const event = { ...state?.details, ...action?.payload };
      return state?.setLoaded()?.set('details', event);
    },

    [eventLaneAssignedToClerk().type]: (state, action: ActionPayload<AuctionTimeSlotLane | undefined>) => {
      const auctionTimeSlotLanes = state?.details?.auctionTimeSlotLanes?.filter(Boolean);
      const laneIndex = auctionTimeSlotLanes?.findIndex((lane) => lane?.id === action?.payload?.id) ?? -1;

      if (auctionTimeSlotLanes && laneIndex > -1) {
        // Update lane if it exists
        auctionTimeSlotLanes[laneIndex] = {
          ...auctionTimeSlotLanes[laneIndex],
          auctionClerk: action?.payload?.auctionClerk,
        };
      }

      const event = { ...state?.details, auctionTimeSlotLanes } as AuctionTimeSlot;
      return state?.set('details', event);
    },

    [eventLaneUpdated().type]: (state, action) => {
      const auctionTimeSlotLanes = state?.details?.auctionTimeSlotLanes?.filter(Boolean);
      const laneIndex = auctionTimeSlotLanes?.findIndex((lane) => lane?.id === action?.payload?.id) ?? -1;

      if (auctionTimeSlotLanes && laneIndex > -1) {
        // Update lane if it exists
        auctionTimeSlotLanes[laneIndex] = {
          ...auctionTimeSlotLanes[laneIndex],
          ...action?.payload,
        };
      }

      const event = { ...state?.details, auctionTimeSlotLanes } as AuctionTimeSlot;
      return state?.set('details', event);
    },
  },
  new InitialState()
);
