import ApiRequest from 'store/shared/api/apiRequest';
import { QueryasIsQualifiedArgs } from 'store/shared/api/graph/interfaces/types';

/**
 * Determines if an inventoryItem can be listed AS IS.
 *
 * @param {string} inventoryItemId
 * @param {string} auctionId
 * @param {string} auctionFormat
 * @param {int} price
 */
export const asIsQualified = ({ inventoryItemId, auctionId, auctionFormat, price }: QueryasIsQualifiedArgs) =>
  ApiRequest<{ asIsQualified: boolean }>({
    data: {
      operationName: 'asIsQualified',
      query: `
        query($inventoryItemId: String!, $auctionId: String!, $auctionFormat: AuctionItemFormat!, $price: Float!) {
          asIsQualified(inventoryItemId: $inventoryItemId, auctionId: $auctionId, auctionFormat: $auctionFormat, price: $price)
        }
      `,
      variables: {
        inventoryItemId,
        auctionId,
        auctionFormat,
        price,
      },
    },
  });

/**
 * A helper function for `asIsQualified`.
 * Queries if the price exists and is a valid number.
 * Otherwise returns a 'false' mock response.
 *
 * @param {object} options
 * @returns {object}
 */
export const isQualifiedForAsIs = (options) => {
  if (options?.price && !Number.isNaN(options?.price)) {
    return asIsQualified(options);
  }
  return Promise.resolve({ data: { data: { asIsQualified: false } } });
};
