import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionTimeSlotLane, MutationauctionTimeSlotLaneUpdateArgs } from 'store/shared/api/graph/interfaces/types';

export const auctionTimeSlotLaneUpdate = (options: MutationauctionTimeSlotLaneUpdateArgs) =>
  ApiRequest<{ auctionTimeSlotLaneUpdate: AuctionTimeSlotLane }>({
    data: {
      query: `
        mutation($auctionTimeSlotLaneId: String!, $active: Boolean, $description: String, $startRunNumber: Int) {
          auctionTimeSlotLaneUpdate(auctionTimeSlotLaneId: $auctionTimeSlotLaneId, active: $active, description: $description, startRunNumber: $startRunNumber) {
            id
            active
            description
            startRunNumber
          }
        }
      `,
      variables: {
        ...options,
      },
    },
  });
