import { HTMLAttributes } from 'react';
import classnames from 'classnames';

import style from './shapes.scss';

type BaseShapeProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  color?: string;
  height?: string;
  width?: string;
};

/**
 * Circle
 *
 * @example <Circle color="red" height="5px" width="5px" />
 */
export const Circle = ({ className, color, height, width, ...props }: BaseShapeProps) => (
  <div
    {...props}
    className={classnames(style.circle, className)}
    style={{ backgroundColor: color, height, width, ...props?.style }}
  />
);

/**
 * RadioCircle
 *
 * Similar to `Circle`, but to mimic our RadioInput styles - for example - within a button.
 * @example <RadioCircle />
 */
export const RadioCircle = ({
  className,
  isSelected,
  ...props
}: Omit<BaseShapeProps, 'color'> & { isSelected: boolean }) => (
  <div {...props} className={classnames(style.dot, isSelected && style.dotSelected, className)} />
);

/**
 * Rectangle
 *
 * @example <Rectangle color="red" height="5px" width="5px" />
 */
export const Rectangle = ({ className, color, height, width, ...props }: BaseShapeProps) => (
  <div
    {...props}
    className={classnames(style.rectangle, className)}
    style={{ backgroundColor: color, height, width, ...props?.style }}
  />
);
