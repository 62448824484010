import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useState } from 'react';

import AcceptOffer from 'components/sections/auctionItem/operations/acceptOffer/acceptOffer';
import AuctionItem from 'constants/auctionItem';
import Button from 'components/ui/shared/button';
import CounterDialog from 'components/sections/auctionItem/operations/counterOffer/dialog';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemIfBidCounterArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { isAuctionStaff } from 'utils/userUtils';
import { myBlockUpdateAuctionItem } from 'store/myBlock/list/myBlockActions';
import { onApiError } from 'utils/apiUtils';
import { processAuctionItemIfBidCounter } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './acceptCounterOffer.scss';

const stateConnect = (state: AppState) => ({
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  ifBidCounter: (options) => processAuctionItemIfBidCounter(options, dispatch),
  updateListItem: (auctionItem) => dispatch(myBlockUpdateAuctionItem(auctionItem)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  auctionItem: AuctionItem;
}

const AcceptCounterOffer = ({ auctionItem, ifBidCounter, isStaffUser, updateListItem }: Props) => {
  const [isCounterDialogOpen, setIsCounterDialogOpen] = useState<boolean>(false);
  const [isCounterDialogSubmitting, setIsCounterDialogSubmitting] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>();

  /**
   * OnSubmit
   */
  const onSubmit = useCallback(
    (shouldSubmit: boolean = false, options: Partial<MutationauctionItemIfBidCounterArgs> = {}) => {
      if (shouldSubmit) {
        setErrorMessages(undefined);
        setIsCounterDialogSubmitting(true);

        trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_COUNTER_CONFIRM_CLICK, auctionItem);

        ifBidCounter({ auctionItemId: auctionItem?.id, ...options })
          ?.then((response) => {
            updateListItem(response);
            setIsCounterDialogOpen(false);
          })
          ?.catch((error) => onApiError(error, setErrorMessages))
          ?.finally(() => setIsCounterDialogSubmitting(false));
        return;
      }

      setIsCounterDialogOpen(false);
    },
    [auctionItem, ifBidCounter, updateListItem]
  );

  return (
    <>
      {auctionItem?.ifBidTimeline?.awaitingMyResponse ? (
        <>
          <div className={style.statusMessage} data-testid="waiting-on-you">
            {t('waiting_on_you')}
          </div>
          <AcceptOffer auctionItem={auctionItem} className={style.accept} onDone={updateListItem} />
          <Button
            className={style.counter}
            onClick={() => {
              setIsCounterDialogOpen(true);
              trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_COUNTER_CLICK, auctionItem);
            }}
            theme="green-outline"
          >
            {t('counter')}
          </Button>
        </>
      ) : (
        <div className={style.statusMessage} data-testid="awaiting-their-response">
          {t('awaiting_their_response')}
        </div>
      )}

      <CounterDialog
        auctionItem={auctionItem}
        errorMessages={errorMessages || null}
        isOpen={isCounterDialogOpen}
        isStaffUser={isStaffUser}
        isSubmitting={isCounterDialogSubmitting}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default connector(AcceptCounterOffer);
