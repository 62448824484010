import classnames from 'classnames';
import { ComponentProps } from 'react';

import Chip from 'components/ui/chip/chip';
import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';

import style from './myBlockStat.scss';

interface Props {
  /** The className to overwrite default styles */
  className?: string;
  /** Stats color */
  color?: 'gray-darker' | 'gray-lighter' | 'green-dark' | 'yellow-dark';
  /** The label */
  label: string;
  /** Tooltip props */
  tooltip?: ComponentProps<typeof InfoTooltip>;
  /** The value to be displayed */
  value: number | string;
}

const MyBlockStat = ({ className, color = 'gray-lighter', label, tooltip, value }: Props) => (
  <div className={classnames(style.container, className)}>
    <div className={classnames(style.value, style[color])}>{value}</div>
    <div className={style.chipContainer}>
      <Chip color={color} label={label} />
      {tooltip && (
        <InfoTooltip
          {...tooltip}
          className={classnames(style.tooltip, tooltip.className)}
          glyphClassName={classnames(style.tooltipGlyph, tooltip.glyphClassName)}
        />
      )}
    </div>
  </div>
);

export default MyBlockStat;
