import { ReactNode } from 'react';
import classnames from 'classnames';

import style from './progressBar.scss';

interface Props {
  /** Rendered child elements. */
  children?: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;

  /** CSS styling to overwrite progress bar style. */
  innerClassName?: string;

  /** Number between 0 and 100. */
  percentage: number;
}

/**
 * A percent progress bar display based on percentage value between 0 - 100
 */
const ProgressBar = ({ percentage, className, innerClassName, children }: Props) => {
  return (
    <div className={classnames(className, style.progressBarContainer)} data-testid="progress-bar">
      <div className={classnames(innerClassName, style.progressBar)} style={{ width: `${percentage}%` }}>
        {children}
      </div>
    </div>
  );
};

export default ProgressBar;
