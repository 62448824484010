import { onMessage } from 'io/pubsub/pubsub';

export const onInventoryItemUpdatedEvent = (fn) => {
  return onMessage(
    {
      type: 'InventoryItemUpdateEvent',
    },
    fn
  );
};
