import classnames from 'classnames';
import { forwardRef, memo, useMemo } from 'react';

import ValuesInfoItems, {
  Props as ValuesInfoItemsProps,
  isValuesInfoVisible,
} from 'components/sections/inventoryItem/details/feesAndValues/valuesInfoItems';
import { Scrollable } from 'components/ui/shared/scrollable';
import { t } from 'utils/intlUtils';

import style from './valuesInfo.scss';

interface Props extends ValuesInfoItemsProps {
  /** CSS style to override default style. */
  className?: string;
  /** True would make values scrollable */
  isScrollable?: boolean;
  /** CSS style to override default style of header. */
  headerClassName?: string;
  /**
   * True when header should be displayed.
   * @default true
   */
  showHeader?: boolean;
}

const ValuesInfo = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      countryCode,
      headerClassName,
      inventoryItemValues,
      isScrollable = false,
      make,
      model,
      showHeader = true,
      subModel,
      year,
    }: Props,
    ref
  ) => {
    const renderValuesInfo = useMemo(() => {
      return (
        <div ref={ref} className={classnames(style.container, className)} data-testid="values-info">
          {showHeader && <div className={classnames(style.header, headerClassName)}>{t('values')}</div>}
          <div className={style.values}>
            <ValuesInfoItems
              countryCode={countryCode}
              inventoryItemValues={inventoryItemValues}
              make={make}
              model={model}
              subModel={subModel}
              year={year}
            />
          </div>
        </div>
      );
    }, [className, countryCode, headerClassName, inventoryItemValues, make, model, ref, showHeader, subModel, year]);

    if (!isValuesInfoVisible(inventoryItemValues, countryCode)) {
      return null;
    }

    if (isScrollable) {
      return <Scrollable scrollableClassName={style.scrollable}>{renderValuesInfo}</Scrollable>;
    }

    return renderValuesInfo;
  }
);

export default memo(ValuesInfo);
