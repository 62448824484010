import classnames from 'classnames';
import { MouseEventHandler, useCallback } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { VehicleTireCondition } from 'store/shared/api/graph/interfaces/types';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './conditionReport.scss';

interface Props {
  /** Vehicle capture type. */
  captureType: InventoryItem['captureType'];
  /** Callback function to handle feature click event. */
  handleFeatureClicked: (
    featureType: string,
    inventoryItemDetails?: VehicleTireCondition & { isVerified: boolean }
  ) => void;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal: (feature: VehicleFeature) => void;
  /** Whether autograde is enabled or not. */
  isAutogradeEnabled: boolean;
  /** Whether the field is required or not. */
  isRequiredField: (field: string) => boolean;
  /** True when inventory item details are editable. */
  isUpdatable: boolean;
  /** Function invoked on tire button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Tire condition. */
  tireCondition: InventoryItem['tireCondition'];
}

const VerifiedTireButton = ({
  captureType,
  handleFeatureClicked,
  handleOpenAddModifyModal,
  isAutogradeEnabled,
  isRequiredField,
  isUpdatable,
  onClick,
  tireCondition,
}: Props) => {
  /**
   * Click handler for verified tire button
   **/
  const onButtonClick = useCallback(
    (event) => {
      isUpdatable
        ? handleOpenAddModifyModal(vehicleFeatures.tireCondition)
        : handleFeatureClicked('TIRES', { ...tireCondition, isVerified: isVerified(captureType) });
      onClick?.(event);
    },
    [captureType, handleFeatureClicked, handleOpenAddModifyModal, isUpdatable, onClick, tireCondition]
  );

  return (
    <Button
      className={classnames(
        style.crButton,
        !isAutogradeEnabled && style.crButtonSm,
        isRequiredField('tireCondition') && style.isRequired,
        isUpdatable && style.crButtonUpdatable
      )}
      dataTestId="verified-tire-condition-button"
      onClick={onButtonClick}
      theme="none"
    >
      <div>
        <span className={style.crButtonSubText}>{t('tires')}</span>
        {isUpdatable && (
          <Sprite
            className={classnames(style.crEditGlyph, { [style.crButtonSm]: !isAutogradeEnabled })}
            glyph={editGlyph}
          />
        )}
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default VerifiedTireButton;
