import { List } from 'immutable';

import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlot, FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface EventModel {
  /** Facet groups used to filter search results. */
  facetGroups: List<RecordOf<FacetGroup>>;

  /** Information about pagination. */
  pageInfo: FacetedPageInfo;

  /** Result list of auction time slot */
  resultList: List<AuctionTimeSlot>;
}

export type EventProps = ServerRecordOf<EventModel>;

export const InitialState = ServerRecord<EventModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});
