export const auctionSettingsFragment = `
  fragment auctionSettings on AuctionSettings {
    assuranceEnabled
    billOfSaleTemplate
    blackBookEnabled
    checkoutEnabled
    dealerArbitrationEnabled
    internalSaleEnabled
    remitRvTaxEnabled
    appraisalAuctionSettings {
      schedules
      numberOfFutureEvents
      auctionCutOff
      checkoutExpiration
      ifBidExpiration
    }
    groundedAuctionSettings {
      schedules
      numberOfFutureEvents
      auctionCutOff
      checkoutExpiration
      ifBidExpiration
    }
    liveAuctionSettings {
      schedules
      numberOfFutureEvents
      auctionCutOff
      digitalCheckoutExpiration
      digitalIfBidExpiration
      physicalCheckoutExpiration
      physicalIfBidExpiration
    }
    timedOfferAuctionSettings {
      auctionCutOff
      checkoutExpiration
      minimumRunTime
      numberOfFutureEvents
      offerExpiration
      schedules
    }
    mmrEnabled
  }
`;
