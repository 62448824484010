import { ComponentProps, MouseEvent } from 'react';

import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { CompanyAuctionRelationshipStatus } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** The id of the auction. */
  auctionId: string;
  /** A collection of the user's bidding selections. */
  currentBiddingSelection: BidSelection | undefined;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True if the current user has admin role. */
  isStaffUser: boolean;
  /** Function invoked on dialog close. */
  onClose: () => void;
  /** Function invoked on company change. */
  onConsignerChange: () => void;
  /** Function invoked on confirm button click. */
  onSubmit: (shouldSubmit: boolean, options: BidSelection) => void;
  /** Function invoked on to prevent default on click event. */
  preventDefault: (event: MouseEvent<HTMLDivElement>) => void;
  /** The theme style to be applied. */
  theme?: ComponentProps<typeof ConfirmDialog>['theme'];
}

interface State {
  /** The selected company for the bid. */
  company: BidSelection['company'] | undefined;
  /** The selected user for the bid. */
  user: BidSelection['user'] | null;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      company: props?.currentBiddingSelection?.company,
      user: props?.currentBiddingSelection?.user || null,
    };
  }

  onConsignerChange = (company: SelectCompanyOption) => {
    if (company?.value !== this.state.company?.id) {
      // Format selected option back to company object (id/name)
      const formattedCompany = { id: company?.value, name: company?.label };

      this.setState({ company: formattedCompany, user: null });
    }
  };

  onUserChange = (user: SelectOption) => {
    if (user?.value !== this.state.user?.id) {
      // Format selected option to user object (id/name)
      const formattedUser = { id: user?.value, name: user?.label };

      this.setState({ user: formattedUser });
    }
  };

  onSubmit = (shouldSubmit: boolean) => {
    const { onSubmit } = this.props;
    const { company, user } = this.state;

    if (company) {
      onSubmit(shouldSubmit, { company, user });
    }
  };

  renderAdminSelection() {
    const { company, user } = this.state;
    const { auctionId, isOpen, isStaffUser, onClose, theme = 'blue' } = this.props;

    return (
      <ConfirmDialog
        actionable={isStaffUser ? !!user : !!company}
        actionLabel={t('confirm')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={(shouldSubmit) => this.onSubmit(shouldSubmit)}
        theme={theme}
        title={t('select_company')}
      >
        <SelectCompany
          className={style.consigners}
          connectionVariables={{
            auctionId,
            auctionRelationshipPermission: [AuctionRelationshipPermission.CAN_BUY],
            auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
          }}
          onChange={this.onConsignerChange}
          placeholder={t('select_company')}
          theme="blue"
          value={
            company && {
              label: company.name,
              value: company.id,
            }
          }
        />
        {isStaffUser && (
          <SelectUser
            className={style.users}
            companyId={company?.id}
            isDisabled={!company?.id}
            onChange={this.onUserChange}
            placeholder={t('select_user')}
            theme="blue"
            value={user && { label: user.name, value: user.id }}
          />
        )}
      </ConfirmDialog>
    );
  }

  render() {
    const { preventDefault } = this.props;

    return (
      <div onClick={preventDefault} role="presentation">
        {this.renderAdminSelection()}
      </div>
    );
  }
}

export default Dialog;
