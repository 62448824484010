import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { omit } from 'lodash-es';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import generatedIntrospection from './possibleTypes.json';
import { getAuthToken } from 'utils/authUtils';
import { getGraphBaseURL } from 'utils/apiUtils';
import { getDefaultHeaders } from 'store/shared/api/apiRequest';

export enum ApolloFetchPolicy {
  NETWORK_ONLY = 'network-only',
  CACHE_AND_NETWORK = 'cache-and-network',
  CACHE_FIRST = 'cache-first',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
}

export enum ApolloContextHeaders {
  NO_AUTH_REQUIRED = 'noAuthRequired',
}

const httpLink = new HttpLink({
  uri: `${getGraphBaseURL()}/graphql`,
});

const errorLink = onError((errorResponse) => {
  if (!errorResponse.networkError) {
    return;
  }

  const isLoggedIn = !!getAuthToken();
  const error = errorResponse.networkError as ServerError;
  if (isLoggedIn && error?.statusCode === 401) {
    // TODO: AuthManager.logout()
  }
});

const authLink = () =>
  setContext(async (_, { headers }) => {
    const defaultHeaders = getDefaultHeaders();

    if (headers === ApolloContextHeaders.NO_AUTH_REQUIRED) {
      // Omit authorization header; generally reserved for mutations that don't require auth
      // See: ForgotPassword@onFormSubmit()
      return { headers: omit(defaultHeaders, 'Authorization') };
    }

    return { headers: { ...defaultHeaders, ...headers } };
  });

const formatResponseLink = new ApolloLink((operation, forward) => {
  // Map shape to legacy Apollo XHR response (`{ data: { data: { ... } } }`)
  return forward(operation).map((response) => ({
    ...response,
    data: { ...response?.data, data: response?.data },
  }));
});

export const client = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes }),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: from([authLink(), errorLink, formatResponseLink, httpLink].filter(Boolean)),
});
