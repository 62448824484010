import { useCallback, useState } from 'react';
import moment from 'moment';

import InputGroup, { InputGroupProps } from 'forms/shared/inputGroup';

type Props = InputGroupProps & {
  defaultDate?: moment.Moment;
  label?: string;
  name: string;
  onChange?: (date) => void;
};

const DateInputGroup = ({ defaultDate, name, label, onChange, ...props }: Props) => {
  const [date, setDate] = useState<moment.Moment | undefined>(defaultDate);
  const [isDateFocused, setIsDateFocused] = useState<boolean>(false);

  return (
    <InputGroup
      date={date}
      daySize={25}
      displayFormat="YYYY/MM/DD"
      focused={isDateFocused}
      groupType="calendar"
      hasMargin
      hideKeyboardShortcutsPanel
      id={name}
      isOutsideRange={() => {}}
      label={label}
      name={name}
      numberOfMonths={1}
      onDateChange={useCallback(
        (value) => {
          setDate(value);
          onChange?.(value);
        },
        [onChange]
      )}
      onFocusChange={({ focused }) => setIsDateFocused(focused)}
      placeholder="YYYY/MM/DD"
      {...props}
    />
  );
};

export default DateInputGroup;
