import { SetOptional, Spread } from 'type-fest';

import InventoryItem from 'constants/inventoryItem';
import { Location } from 'store/shared/api/graph/interfaces/types';
import { isAutogradeEnabled } from 'utils/inventoryItemUtils';
import { isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';

import styleVariables from 'styles/variables.scss';

export type ScoreRangeItem = {
  range: number[]; // Example: [min, max]; Example: [0, 10]
  color: string;
};

export type ScoreRange = ScoreRangeItem[];

// Legacy Canadian Scoring: 0-100
export const rangeCALegacy: ScoreRange = [
  { range: [70.001, 100], color: styleVariables?.colorGreen },
  { range: [40.001, 70], color: styleVariables?.colorYellow },
  { range: [0, 40], color: styleVariables?.colorRed },
];

// Extensive Canadian Scoring: 0-5
export const rangeCA: ScoreRange = [
  { range: [5], color: styleVariables?.colorGreen },
  { range: [2, 4.999], color: styleVariables?.colorYellow },
  { range: [0, 1.999], color: styleVariables?.colorRed },
];

// Extensive US Scoring: 0-5
export const rangeUS: ScoreRange = [
  { range: [3.5, 5], color: styleVariables?.colorGreen },
  { range: [2.001, 3.499], color: styleVariables?.colorYellow },
  { range: [0, 2], color: styleVariables?.colorRed },
];

// Auto Grade Scoring: 0-5
export const rangeAutoGrade: ScoreRange = [{ range: [0, 5], color: styleVariables?.colorBlack }];

/**
 * Determines the score range type by: `captureType` and the unit's location.
 * Defaults to the Legacy Canadian Scoring range.
 */
export const getScoreRangeByInventoryItem = (
  inventoryItem: Spread<
    SetOptional<Pick<InventoryItem, 'autoGradeScore' | 'captureType'>, 'captureType'>,
    { location?: Pick<Location, 'countryCode'> | null }
  >
): ScoreRange => {
  const isAutoGrade = isAutogradeEnabled(inventoryItem);

  if (isAutoGrade) {
    return rangeAutoGrade;
  }
  if (isVerifiedExtensiveVehicleCondition(inventoryItem.captureType)) {
    return inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;
  }
  return rangeCALegacy;
};
