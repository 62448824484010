import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  MutationauctionTimeSlotChangeCutoffArgs,
  MutationauctionTimeSlotLaneUpdateArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionPhysicalLaneAssignClerk, getAuctionTimeSlot } from 'store/admin/events/details/eventDetailsApi';
import { auctionTimeSlotChangeCutoff } from 'store/shared/api/graph/mutations/auctionTimeSlotChangeCutoff';
import { auctionTimeSlotLaneUpdate } from 'store/shared/api/graph/mutations/auctionTimeSlotLaneUpdate';

export const eventDetailsCleared = createAction('ADMIN_EVENT_DETAILS_CLEARED');
export const eventDetailsIsLoading = createAction('ADMIN_EVENT_DETAILS_LOADING');
export const eventDetailsLoaded = createAction('ADMIN_EVENT_DETAILS_LOADED');
export const eventDetailsUpdated = createAction('ADMIN_EVENT_DETAILS_UPDATED');
export const eventLaneAssignedToClerk = createAction('ADMIN_EVENT_DETAILS_LANE_ASSIGNED_TO_CLERK');
export const eventLaneUpdated = createAction('ADMIN_EVENT_DETAILS_LANE_UPDATED');
export const preloadEventDetails = createAction('ADMIN_EVENT_DETAILS_PRELOADED');

export const processGetLiveLaneDetails = (options, dispatch) => {
  dispatch(eventDetailsIsLoading());
  return getAuctionTimeSlot(options).then((response) =>
    dispatch(eventDetailsLoaded(response?.data?.data?.auctionTimeSlot))
  );
};

export const processAuctionPhysicalLaneAssignClerk = (options, dispatch) => {
  return auctionPhysicalLaneAssignClerk(options)?.then((response) => {
    dispatch(eventLaneAssignedToClerk(response?.data?.data?.auctionPhysicalLaneAssignClerk));
  });
};

export const processAuctionTimeSlotLaneUpdate = (
  options: MutationauctionTimeSlotLaneUpdateArgs,
  dispatch: AppDispatch
) => {
  return auctionTimeSlotLaneUpdate(options)?.then((response) => {
    dispatch(eventLaneUpdated(response?.data?.data?.auctionTimeSlotLaneUpdate));
  });
};

export const processAuctionTimeSlotCutoffTime = async (
  options: MutationauctionTimeSlotChangeCutoffArgs,
  dispatch: AppDispatch
) => {
  return auctionTimeSlotChangeCutoff(options)?.then((response) => {
    dispatch(eventDetailsUpdated(response?.data?.data?.auctionTimeSlotChangeCutoff));
  });
};
