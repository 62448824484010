import classnames from 'classnames';
import { memo } from 'react';

import blackBookLogo from 'glyphs/logo-black-book.svg';
import infoGlyph from 'glyphs/info.svg';

import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';
import Sprite from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './blackBookValuesTooltip.scss';

interface Props {
  /** CSS styling to overwrite default logo style. */
  logoClassName?: string;
  /** True when logo is displayed. */
  showLogo?: boolean;
  /** CSS styling to overwrite default tooltip icon style.*/
  tooltipDescriptionClassName?: string;
  /** CSS styling to overwrite default tooltip icon style.*/
  tooltipIconClassName?: string;
}

export const BlackBookValuesTooltipText = () => (
  <>
    <strong>{t('clean_wholesale_mileage_adjusted')}</strong>
    <br />
    {t('clean_wholesale_mileage_adjusted_info')}
    <br />
    <br />

    <strong>{t('average_wholesale_mileage_adjusted')}</strong>
    <br />
    {t('average_wholesale_mileage_adjusted_info')}
    <br />
    <br />

    <strong>{t('rough_wholesale_mileage_adjusted')}</strong>
    <br />
    {t('rough_wholesale_mileage_adjusted_info')}
  </>
);

const BlackBookValuesTooltip = ({
  logoClassName,
  showLogo,
  tooltipDescriptionClassName,
  tooltipIconClassName,
}: Props) => (
  <div className={style.container}>
    {!!showLogo && <Sprite className={classnames(style.blackBookLogo, logoClassName)} glyph={blackBookLogo} />}
    <div className={tooltipDescriptionClassName}>{t('black_book_value_info')}</div>
    <InfoTooltip
      className={style.tooltip}
      description={<BlackBookValuesTooltipText />}
      glyph={infoGlyph}
      glyphClassName={classnames(style.tooltipGlyph, tooltipIconClassName)}
      title={t('black_book')}
    />
  </div>
);

export default memo(BlackBookValuesTooltip);
