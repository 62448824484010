import ApiRequest from 'store/shared/api/apiRequest';
import { Conversation, LiveLaneConversationGroup } from 'store/shared/api/graph/interfaces/types';

export const clerkLiveLaneConversation = (options) => {
  return ApiRequest<{ clerkLiveLaneConversation: Conversation }>({
    data: {
      query: `
        query ($conversationId: String!, $isAuctioneer: Boolean = false) {
          clerkLiveLaneConversation(conversationId: $conversationId, isAuctioneer: $isAuctioneer) {
            id
            staff {
              id
              firstName
              lastName
            }
            customer {
              id
              firstName
              lastName
              companyRelationships {
                list {
                  status
                  company {
                    id
                    name
                    auctionRelationships {
                      list {
                        id
                        status
                        auction {
                          id
                        }
                        permissions {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            type
          }
        }
      `,
      variables: {
        conversationId: options.conversationId,
        isAuctioneer: options.isAuctioneer,
      },
    },
  });
};

export const clerkLiveLaneConversations = (options) => {
  return ApiRequest<{ clerkLiveLaneConversations: Conversation[] }>({
    data: {
      query: `
        query ($auctionTimeSlotLaneId: String!, $isAuctioneer: Boolean = false) {
          clerkLiveLaneConversations(auctionTimeSlotLaneId: $auctionTimeSlotLaneId, isAuctioneer: $isAuctioneer) {
            id
            staff {
              id
              firstName
              lastName
            }
            customer {
              id
              firstName
              lastName
              companyRelationships {
                list {
                  status
                  company {
                    id
                    name
                    auctionRelationships {
                      list {
                        id
                        status
                        auction {
                          id
                        }
                        permissions {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            type
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId: options.auctionTimeSlotLaneId,
        isAuctioneer: options.isAuctioneer,
      },
    },
  });
};

export const liveLaneConversationRegister = (options) => {
  return ApiRequest<{ liveLaneConversationRegister: LiveLaneConversationGroup }>({
    data: {
      query: `
        mutation ($auctionTimeSlotLaneId: String!, $forceCreate: Boolean = false) {
          liveLaneConversationRegister(auctionTimeSlotLaneId: $auctionTimeSlotLaneId, forceCreate: $forceCreate) {
            liveLaneConversation {
              id
              staff {
                id
                firstName
                lastName
              }
              customer {
                id
                firstName
                lastName
              }
              type
            }
            liveLaneBroadcastConversation {
                id
                staff {
                  id
                  firstName
                  lastName
                }
                customer {
                  id
                  firstName
                  lastName
                }
                type
            }
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId: options.auctionTimeSlotLaneId,
        forceCreate: options.forceCreate,
      },
    },
  });
};
