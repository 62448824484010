import { useCallback, useState } from 'react';

import EditButton from 'components/ui/shared/buttons/editButton';
import InventoryItem from 'constants/inventoryItem';
import SellerRequestedValuesDialog from 'components/sections/inventoryItem/details/sellerRequestedValues/sellerRequestedValuesDialog';
import { DetailsSection } from 'layouts/list/listLayout';
import { formatDate } from 'utils/dateUtils';
import { t } from 'utils/intlUtils';

import style from './sellerRequestedValues.scss';

interface Props {
  /** The id of the details section. */
  id: string;
  /** Inventory item details */
  inventoryItem: InventoryItem;
}

const SellerRequestedValues = ({ id, inventoryItem }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  /**
   * Handles edit button click.
   */
  const onEditClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  /**
   * Handles onClose dialog click.
   */
  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      <DetailsSection id={id} title={t('seller_requested_values')}>
        <div className={style.legalSaleDate} data-testid="legal-sale-date">
          <div>{t('legal_sale_date')}</div>
          <div className={style.value}>
            <div>{formatDate(inventoryItem?.legalSaleDate) ?? '-'}</div>
            {inventoryItem.source === 'EBLOCK' && <EditButton onClick={onEditClick} theme="blue-pencil" />}
          </div>
        </div>
      </DetailsSection>

      {isDialogOpen && <SellerRequestedValuesDialog inventoryItem={inventoryItem} onClose={onCloseDialog} />}
    </>
  );
};

export default SellerRequestedValues;
