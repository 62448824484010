import { getStore } from 'store/configureStore';
import {
  getAccessibleActionIds,
  getEnabledCompaniesByType,
  getEnabledCompanyIds,
  getEnabledCompanyRelationship,
  getEnabledCompanyRelationships,
  getOwnedAuctionIds,
  getOwnedAuctions,
  hasAuctionPermission,
  hasCompanyPermission,
  hasSystemPermission,
  isAuctionStaff,
  isGroupManagerRole,
  representsAuction,
} from 'utils/userUtils';

/**
 * A static utility to reference Auth/User utils without having to plumb state into your context/components
 */
class AuthService {
  static get user() {
    return getStore()?.getState()?.app?.user;
  }

  static isGroupManager() {
    return isGroupManagerRole(this.user);
  }

  static isStaffUser(auctionId?: string) {
    return isAuctionStaff(this.user, auctionId);
  }

  static getEnabledCompanyRelationships() {
    return getEnabledCompanyRelationships(this.user);
  }

  static getEnabledCompanyRelationship(companyId: string) {
    return getEnabledCompanyRelationship(this.user, companyId);
  }

  static getEnabledCompanyIds() {
    return getEnabledCompanyIds(this.user);
  }

  static getEnabledCompaniesByType(companyType: string) {
    return getEnabledCompaniesByType(this.user, companyType);
  }

  static getOwnedAuctions() {
    return getOwnedAuctions(this.user);
  }

  static hasOwnedAuctions() {
    return Number(getOwnedAuctions(this.user)?.length) > 0;
  }

  static getOwnedAuctionIds() {
    return getOwnedAuctionIds(this.user);
  }

  static getAccessibleActionIds() {
    return getAccessibleActionIds(this.user);
  }

  static hasAuctionPermission(auctionId: string, permissions: string[]) {
    return hasAuctionPermission(this.user, auctionId, permissions);
  }

  static hasCompanyPermission(companyId: string, permissions: string[]) {
    return hasCompanyPermission(this.user, companyId, permissions);
  }

  static hasSystemPermission(permissions: string[]) {
    return hasSystemPermission(this.user, permissions);
  }

  static representsAuction(auctionId: string) {
    return representsAuction(this.user, auctionId);
  }
}

export default AuthService;
