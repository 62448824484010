import { datadogRum } from '@datadog/browser-rum';

import User from 'constants/user';
import { LooseObject } from 'constants/objects';
import { PageView } from 'logging/analytics/events/pageViews';

class DatadogLogger {
  /**
   * Initialize logger
   *
   * @example: DatadogLogger.init();
   */
  static init() {
    if (process.env.REACT_APP_FEATURE_DATADOG_RUM === 'true' && process.env.NODE_ENV !== 'development') {
      datadogRum.init({
        allowedTracingUrls: [process.env.REACT_APP_FEATURE_DATADOG_RUM_BASE_URI!, /https:\/\/.*\.eblock\.com/],
        applicationId: process.env.REACT_APP_FEATURE_DATADOG_RUM_APPLICATION_ID!,
        clientToken: process.env.REACT_APP_FEATURE_DATADOG_RUM_CLIENT_TOKEN!,
        env: process.env.REACT_APP_FEATURE_DATADOG_RUM_ENV,
        service: 'eblock-web',
        sessionReplaySampleRate: 0,
        sessionSampleRate: 100,
        site: 'datadoghq.com',
        trackUserInteractions: true,
        trackViewsManually: true,
        version: process.env.REACT_APP_BUILD_VERSION,
      });

      // Potentially enable session replay recording
      // datadogRum.startSessionReplayRecording();
    }
  }

  /**
   * Set logged-in user for improved context
   */
  static setUser(user: User) {
    datadogRum.setUser({ id: user?.id });
  }

  /**
   * Clear the active user
   */
  static clearUser() {
    datadogRum.clearUser();
  }

  /**
   * Track a page view
   *
   * @example: DatadogLogger.trackPageView(location?.pathName);
   */
  static trackPageView(pageName: string) {
    const formattedPageViewName = PageView[pageName];

    if (formattedPageViewName) {
      datadogRum.startView(formattedPageViewName);
    }
  }

  /**
   * Track a user action
   *
   * @example: DatadogLogger.trackUserAction(UserAction.VIEW_VDP, { is_auction_staff: true });
   */
  static trackUserAction(actionName: string, attributes?: LooseObject) {
    datadogRum.addAction(actionName, attributes);
  }
}

export default DatadogLogger;
