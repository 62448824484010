import { FormEvent, memo } from 'react';

import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { CompanyAuctionRelationshipStatus, MonetaryAmount } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getBiddingSelectionByAuctionId } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface DialogProps {
  /** The id of the auction. */
  auctionId: string | undefined;
  /** A list of error messages */
  errorMessages?: ErrorMessages;
  /** If the form is currently submitting a bid */
  isBidding: boolean;
  /** If the user has the `isGroupManager` role */
  isGroupManager: boolean;
  /** If the dialog is open */
  isOpen: boolean;
  /** True if the logged-in user represents auction staff */
  isStaffUser: boolean;
  /** The minimum accepted offer for the next bid */
  minimumOffer?: MonetaryAmount;
  /** Function to call when submitting a bid */
  onSubmit(shouldSubmit: boolean, customAmount?: number, userId?: string, companyId?: string): void;
}

interface DialogState {
  amount?: number;
  company: BidSelection['company'] | undefined;
  user: BidSelection['user'] | undefined;
}

class Dialog extends BaseClass<DialogProps, DialogState> {
  static defaultProps = {
    currentBiddingCompany: undefined,
  };

  constructor(props) {
    super(props);

    const biddingAs = getBiddingSelectionByAuctionId(props?.auctionItem?.auction?.id);

    // State
    this.state = {
      amount: Number.NaN,
      company: biddingAs?.company,
      user: biddingAs?.user,
    };
  }

  onCompanyChange = (company: SelectCompanyOption) => {
    // Format selected option back to company object (id/name)
    const formattedCompany = { id: company?.value, name: company?.label };

    this.setState({ company: formattedCompany, user: null });
  };

  onUserChange = (user: SelectOption) => {
    // Format selected option back to user object (id/name)
    const formattedUser = user && { id: user?.value, name: user?.label };

    this.setState({ user: formattedUser });
  };

  onOfferChange = (value) => {
    this.setState({ amount: parseFloat(value) });
  };

  /**
   * Determines if the form is actionable and if so then activates the confirm button.
   */
  isActionable = () => {
    const { amount, company, user } = this.state;
    const { isGroupManager, isStaffUser, minimumOffer } = this.props;
    const currentAmount = minimumOffer ? minimumOffer.amount : 0;
    const amountValid = !Number.isNaN(amount) && Number(amount) >= currentAmount;

    if (isStaffUser) {
      return !!company && !!user && amountValid;
    }
    if (isGroupManager) {
      return !!company && amountValid;
    }
    return amountValid;
  };

  /**
   * Triggers bid upon form submission, only when actionable.
   */
  onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.isActionable()) {
      this.bid(true);
    }
  };

  bid = (shouldSubmit) => {
    const { amount, company, user } = this.state;
    const { onSubmit } = this.props;

    onSubmit(shouldSubmit, amount, user?.id, company?.id);
  };

  render() {
    const { company, user } = this.state;
    const { auctionId, errorMessages, isBidding, isGroupManager, isOpen, isStaffUser, minimumOffer } = this.props;

    const placeholder = minimumOffer ? t('enter_x_or_more', [minimumOffer.formattedAmountRounded]) : t('amount');
    const hasError = errorMessages && errorMessages.length > 0;

    return (
      <ConfirmDialog
        actionable={this.isActionable()}
        actionProgress={isBidding}
        isOpen={isOpen}
        onConfirm={this.bid}
        theme="green"
        title={t('set_custom_bid')}
      >
        <form noValidate onSubmit={this.onFormSubmit}>
          <p className={style.instructions}>{t('set_custom_bid_message')}</p>
          {(isStaffUser || isGroupManager) && (
            <SelectCompany
              className={style.consigners}
              connectionVariables={{
                auctionId,
                auctionRelationshipPermission: [AuctionRelationshipPermission.CAN_BUY],
                auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
              }}
              onChange={this.onCompanyChange}
              placeholder={t('select_company')}
              value={
                company && {
                  label: company.name,
                  value: company.id,
                }
              }
            />
          )}
          {isStaffUser && (
            <SelectUser
              className={style.users}
              companyId={company?.id}
              isDisabled={!company?.id}
              onChange={this.onUserChange}
              placeholder={t('select_user')}
              value={user && { label: user.name, value: user.id }}
            />
          )}
          <InputText
            autoFocus
            className={style.amount}
            onChange={this.onOfferChange}
            placeholder={placeholder}
            theme={hasError ? 'error' : undefined}
            type="currency"
          />
        </form>
      </ConfirmDialog>
    );
  }
}

export default memo(Dialog);
