import { useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import Range from 'forms/shared/range';
import Select from 'forms/shared/select';
import { FormDialogFooter } from 'layouts/formLayouts/formDialogLayouts';
import { MakeModelFilter } from 'store/shared/api/graph/interfaces/types';
import { Notifications } from 'components/sections/notifications/userNotificationsSlideOut';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { UserAction } from 'logging/analytics/events/userActions';
import { formatMileageValues } from 'utils/apiUtils';
import { getMakes, getModels, getYears } from 'store/shared/api/graph/queries/ymm';
import { getMultiSelectProps } from 'utils/selectUtils';
import { mileageRange, mileageValueLabel } from 'components/ui/lists/filters/facetGroups/facetGroupHelpers';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './addNewMakeModeFilter.scss';

interface Props {
  mileageUnit: string;
  notifications: Notifications | null;
  onClose: () => void;
  saveNotifications: (notifications: Notifications, shouldUpdateFromResponse: boolean) => Promise<void>;
}

const AddNewMakeModeFilter = ({ mileageUnit, notifications, onClose, saveNotifications }: Props) => {
  const [fromYearOption, setFromYearOption] = useState<SelectOption | null>();
  const [toYearOption, setToYearOption] = useState<SelectOption | null>();
  const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
  const [makeOption, setMakeOption] = useState<SelectOption | null>();
  const [makeOptions, setMakeOptions] = useState<SelectOption[]>([]);
  const [modelOption, setModelOption] = useState<SelectOption[] | null>();
  const [modelOptions, setModelOptions] = useState<SelectOption[]>([]);
  const [mileage, setMileage] = useState<{ min: number | undefined; max: number | undefined }>({
    min: undefined,
    max: undefined,
  });

  /**
   * Fetches available years and updates the select options.
   */
  const getYearOptions = useCallback(async () => {
    const years = (await getYears())?.data?.data?.inventoryItemMetaData?.vehicleMetaData?.year ?? [];
    const options = years.map<SelectOption>((year) => ({ label: String(year), value: String(year) }));
    setYearOptions(options);
  }, []);

  /**
   * Fetches available makes and updates the select options.
   */
  const getMakeOptions = useCallback(async () => {
    const makes = (await getMakes())?.data?.data?.inventoryItemMetaData?.vehicleMetaData?.mmt ?? [];
    const options = makes.map<SelectOption>((opt) => ({ label: String(opt?.name), value: String(opt?.id) }));
    setMakeOptions(options);
  }, []);

  /**
   * Fetches available models and updates the select options.
   */
  const getModelOptions = useCallback(async (makeId: string) => {
    const models =
      (await getModels({ makeId }))?.data?.data?.inventoryItemMetaData?.vehicleMetaData?.mmt?.[0]?.models ?? [];
    const options = models.map<SelectOption>((opt) => ({ label: String(opt?.name), value: String(opt?.id) }));
    setModelOptions(options);
  }, []);

  /**
   * Callback for when the `fromYear` selection has changed.
   */
  const onFromYearInputChange = useCallback(
    (value: SelectOption) => {
      trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_FROM_YEAR_SET);
      setFromYearOption(value);
      setMakeOption(null);
      setModelOption(null);
      getMakeOptions();
    },
    [getMakeOptions]
  );

  /**
   * Callback for when the `toYear` selection has changed.
   */
  const onToYearInputChange = useCallback(
    (value: SelectOption) => {
      trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_TO_YEAR_SET);
      setToYearOption(value);
      setMakeOption(null);
      setModelOption(null);
      getMakeOptions();
    },
    [getMakeOptions]
  );

  /**
   * Callback for when the `make` selection has changed.
   */
  const onMakeInputChange = useCallback(
    (value: SelectOption) => {
      trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_MAKE_SET);
      setMakeOption(value);
      setModelOption(null);
      getModelOptions(value.value);
    },
    [getModelOptions]
  );

  /**
   * Callback for when the `model` selection has changed.
   */
  const onModelInputChange = useCallback((values: SelectOption[]) => {
    trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_MODEL_SET);
    setModelOption(values);
  }, []);

  /**
   * Closes overlay and tracks the event
   */
  const onRequestClose = useCallback(() => {
    trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_CANCEL_CLICK);
    onClose();
  }, [onClose]);

  /**
   * onSubmit
   */
  const onSubmit = useCallback(() => {
    // Map over model options if model selected, otherwise return single entry using selected make
    const newFilters = modelOption
      ? (modelOption?.map((option) =>
          formatMileageValues({
            makeId: makeOption!.value,
            modelId: option?.value,
            yearFrom: fromYearOption?.value ? Number(fromYearOption?.value) : undefined,
            yearTo: toYearOption?.value ? Number(toYearOption?.value) : undefined,
            maxMileage: mileage?.max,
            minMileage: mileage?.min,
          })
        ) as MakeModelFilter[])
      : [
          formatMileageValues({
            makeId: makeOption!.value,
            yearFrom: fromYearOption?.value ? Number(fromYearOption?.value) : undefined,
            yearTo: toYearOption?.value ? Number(toYearOption?.value) : undefined,
            maxMileage: mileage?.max,
            minMileage: mileage?.min,
          }),
        ];

    const notificationsNext = {
      ...notifications,
      makeModelFilters: [...(notifications?.makeModelFilters ?? []), ...newFilters],
    } as Notifications;

    trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_SAVE_CLICK);
    saveNotifications(notificationsNext, true)
      ?.then(onRequestClose)
      ?.catch(() => {
        /* Do nothin' */
      });
  }, [
    makeOption,
    modelOption,
    notifications,
    fromYearOption,
    onRequestClose,
    saveNotifications,
    toYearOption,
    mileage,
  ]);

  /**
   * OnMount: Fetch year and make options
   */
  useMountEffect(() => {
    getYearOptions();
    getMakeOptions();
  });

  return (
    <div>
      <div>
        <div className={style.section}>
          <div className={style.sectionTitle}>{t('add_vehicle_listing_details')}</div>
          <div className={style.sectionSubtitle}>{t('add_vehicle_listing_details_msg')}</div>

          <div className={style.selects}>
            <Select
              className={style.select}
              onChange={onFromYearInputChange}
              options={yearOptions}
              placeholder={t('year_from')}
              themeOverrides={{ height: '40px' }}
              value={fromYearOption}
            />
            <Select
              className={style.select}
              onChange={onToYearInputChange}
              options={yearOptions}
              placeholder={t('year_to')}
              themeOverrides={{ height: '40px' }}
              value={toYearOption}
            />
            <Select
              className={style.select}
              onChange={onMakeInputChange}
              options={makeOptions}
              placeholder={t('choose_make')}
              themeOverrides={{ height: '40px' }}
              value={makeOption}
            />
            <Select
              className={style.select}
              isDisabled={!makeOption}
              {...getMultiSelectProps()}
              onChange={onModelInputChange}
              options={modelOptions}
              placeholder={t('choose_model')}
              themeOverrides={{ height: '40px' }}
              value={modelOption}
            />
          </div>

          <div className={style.rangeContainer}>
            <div className={style.rangeTitles}>
              <div className={style.sectionTitle}>{t('mileage_in_km')}</div>
              <div className={style.sectionSubtitle}>
                {mileageValueLabel([mileage.min ?? mileageRange[0], mileage.max ?? mileageRange[1]])}
              </div>
            </div>
            <Range
              key="range-slider"
              allowCross={false}
              max={mileageRange[1]}
              min={mileageRange[0]}
              onChange={(val) => {
                setMileage({
                  min: val[0] === mileageRange[0] ? undefined : val[0],
                  max: val[1] === mileageRange[1] ? undefined : val[1],
                });
              }}
              onChangeComplete={() => trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_MILEAGE_SET)}
              tipFormatter={null}
              value={[mileage.min ?? mileageRange[0], mileage.max ?? mileageRange[1]]}
            />
          </div>
        </div>
      </div>

      <FormDialogFooter>
        <Button className={style.addButton} onClick={onRequestClose} theme="gray-outline">
          {t('cancel')}
        </Button>
        <Button className={style.addButton} disabled={!makeOption?.value} onClick={onSubmit} theme="blue">
          {t('save')}
        </Button>
      </FormDialogFooter>
    </div>
  );
};

export default AddNewMakeModeFilter;
