import { ContextType } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ComboButton from './comboButton';
import Dialog from './dialog';
import Overlay from './overlay';
import raf from 'utils/raf';
import { AppState } from 'store/configureStore';
import { LiveAuctionItemState } from 'store/shared/api/graph/interfaces/types';
import { ButtonTheme } from 'components/ui/shared/button';
import { GlobalDialogContext } from 'contexts/globalDialogContext';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import {
  getBiddingSelectionByAuctionId,
  getEnabledCompanyIds,
  isAuctionStaff,
  isGroupManagerRole,
} from 'utils/userUtils';
import { isLiveAuction as isLiveAuctionItem } from 'utils/auctionItemUtils';
import { processSubmitBid } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './submitButton.scss';

const stateConnect = (state: AppState) => ({
  /** The active lanes of an ongoing auction */
  activeLaneIds: state.app.liveLanes.activeLaneIds,
  /** True if the logged-in user has multiple company-auction relationships  */
  isGroupManager: isGroupManagerRole(state.app.user),
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
  /** All the current live lanes */
  lanes: state.app.liveLanes.resultList,
  /** Current logged in user. */
  user: state.app.user,
});

const connector = connect(stateConnect);

interface SubmitBidProps extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  details: AuctionItem;
  /** True when a condensed version of the button is rendered. */
  isCondensed?: boolean;
  /** True when the combo style is used. */
  isCombo?: boolean;
  /** Function invoked when submitted bid is confirmed. */
  onDone?(...args: unknown[]): unknown;
  /** CSS styling to override default overlay style. */
  overlayClassName?: string;
}

interface SubmitBidState {
  bidAmount: number;
  bidsChanged: boolean;
  isBidding: boolean;
  showDialog: boolean;
  showOverlay: boolean;
}

class SubmitBid extends BaseClass<SubmitBidProps, SubmitBidState> {
  static contextType = GlobalDialogContext;
  context: ContextType<typeof GlobalDialogContext>;

  static defaultProps = {
    onDone: () => {},
  };

  state = { showOverlay: false, isBidding: false, showDialog: false, bidsChanged: false, bidAmount: 0 };

  componentDidUpdate(prevProps: SubmitBidProps) {
    const { showOverlay, showDialog, isBidding, bidAmount } = this.state;
    const {
      details: { bidTimeline, topOffer },
      user,
    } = this.props;
    const {
      details: { bidTimeline: bidTimelinePrev, topOffer: topOfferPrev },
    } = prevProps;

    const numBidsPrev = bidTimelinePrev ? bidTimelinePrev.count : 0;
    const numBids = bidTimeline ? bidTimeline.count : 0;

    if (numBids > numBidsPrev || topOffer?.amount?.amount !== topOfferPrev?.amount?.amount) {
      this.animateBidChange();
      if (isBidding) {
        this.setState({ isBidding: false });
      }
    }
    if (showOverlay || showDialog) {
      const bidsPrev = bidTimelinePrev?.list ? bidTimelinePrev.list.filter(Boolean) : [];
      const bids = bidTimeline?.list ? bidTimeline.list.filter(Boolean) : [];
      const hasMyLatestBid = bidAmount !== 0 && !!bids.find((bid) => bid.amount.amount >= bidAmount);
      const newBids = bids.slice(0, bids.length - bidsPrev.length);
      const myNewBids = newBids.filter(
        (bid) => bid?.company?.id && getEnabledCompanyIds(user)?.includes(bid?.company?.id)
      );

      if (myNewBids.length > 0 || hasMyLatestBid) {
        this.setState({ showOverlay: false, isBidding: false, showDialog: false, bidAmount: 0 });
      }
    }
  }

  onConfirm = (shouldSubmit: boolean, customAmount?: number, userId?: string, companyId = null) => {
    const {
      details,
      details: { id, auction, nextBidAmount },
      onDone,
    } = this.props;
    const bidAmount = customAmount || nextBidAmount?.amount;
    const biddingAs = getBiddingSelectionByAuctionId(auction?.id);
    const options = {
      auctionItemId: id,
      amount: bidAmount,
      consignerId: companyId || biddingAs?.company?.id,
      userId: userId || biddingAs?.user?.id,
    };

    this.setState({ isBidding: true, bidAmount });

    if (shouldSubmit) {
      processSubmitBid(options)
        .then(() => {
          const userAction = customAmount ? UserAction.VDP_CUSTOM_BID_CONFIRM_CLICK : UserAction.VDP_BID_CONFIRM_CLICK;
          trackUserActionWithAuctionItemAttributes(userAction, details);

          onDone?.(true);
        })
        .catch((error) => {
          this.setState({ showOverlay: false, isBidding: false, showDialog: false, bidAmount: 0 });
          this.context?.setConfig({ errorsOverride: getErrors(error) });
        });
    } else {
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_BID_CANCEL_CLICK, details);
      this.setState({ showOverlay: false, isBidding: false, showDialog: false, bidAmount: 0 });
      onDone?.(false);
    }
  };

  animateBidChange() {
    raf(() => {
      this.setState({ bidsChanged: false });
      raf(() => {
        this.setState({ bidsChanged: true });
      });
    }, 1000);
  }

  isDisabled = () => {
    const { activeLaneIds, details, lanes } = this.props;

    if (isLiveAuctionItem(details)) {
      // Find the matching LiveAuctionItem, and get its current state
      const liveItem = lanes
        ?.filter((liveLane) => activeLaneIds?.includes(liveLane?.id))
        ?.find((liveLane) => liveLane?.liveItem?.id === details?.id)?.liveItem;

      // Enabled only when state is `BID`
      return liveItem?.state !== LiveAuctionItemState.BID;
    }

    // Otherwise, assume APPRAISAL or GROUNDED auction
    return false;
  };

  render() {
    const {
      details,
      details: { auction, bidTimeline, nextBidAmount, topOffer },
      isCombo,
      isCondensed,
      isGroupManager,
      isStaffUser,
      overlayClassName,
    } = this.props;
    const { showOverlay, isBidding, showDialog, bidsChanged } = this.state;

    const isWinning = !isStaffUser && bidTimeline ? bidTimeline.winning : false;
    const isLosing = !isStaffUser && bidTimeline ? bidTimeline.outbid : false;
    const isDisabled = this.isDisabled();

    let buttonTheme: ButtonTheme = 'green';
    let buttonLabel = isCondensed ? t('bid') : `${t('bid')} ${nextBidAmount?.formattedAmountRounded}`;

    if (isWinning) {
      buttonLabel = isCondensed ? t('on') : `${t('on')} ${topOffer?.amount.formattedAmountRounded}`;
      buttonTheme = 'green';
    } else if (isLosing) {
      buttonTheme = 'red';
    }

    return (
      <>
        {showOverlay && (
          <Overlay
            auctionItem={details}
            bidAmount={nextBidAmount?.amount}
            className={overlayClassName}
            isBidding={isBidding}
            isGroupManager={isGroupManager}
            isStaffUser={isStaffUser}
            onCancel={() => this.onConfirm(false)}
            onConfirm={(bidAmount: number) => this.onConfirm(true, bidAmount)}
          />
        )}
        <ComboButton
          bulge={bidsChanged}
          className={style.button}
          dataTestId="submit-bid-button"
          isCombo={isCombo ?? !isWinning}
          isDisabled={isDisabled}
          isWinning={isWinning}
          label={buttonLabel}
          onCustomBidClick={() => {
            if (!isDisabled) {
              trackUserActionWithAuctionItemAttributes(UserAction.VDP_CUSTOM_BID_CLICK, details);
              this.setState({ showDialog: true });
            }
          }}
          onQuickBidClick={() => {
            if (!isDisabled) {
              trackUserActionWithAuctionItemAttributes(UserAction.VDP_BID_CLICK, details);
              this.setState({ showOverlay: !isWinning });
            }
          }}
          theme={buttonTheme}
        />
        <Dialog
          auctionId={auction.id}
          isBidding={isBidding}
          isGroupManager={isGroupManager}
          isOpen={showDialog}
          isStaffUser={isStaffUser}
          minimumOffer={nextBidAmount}
          onSubmit={this.onConfirm}
        />
      </>
    );
  }
}

export default connector(SubmitBid);
