import classnames from 'classnames';
import moment from 'moment';

import ClockGlyph from 'glyphs/clock.svg';

import Countdown from 'components/ui/countdowns/countdown';
import Sprite from 'components/ui/shared/sprite';
import TimerBar from 'components/ui/progressBars/timerBar';
import User from 'constants/user';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { LiveAuctionItemState, LiveLane } from 'store/shared/api/graph/interfaces/types';
import { TimerFormats } from 'constants/enums/dateAndTime';
import { getEnabledCompanyIds, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { t, tPlural } from 'utils/intlUtils';

import style from './heroForehead.scss';

export interface HeroForeheadProps {
  /**
   * Theme of the hero forehead.
   * @default `'green'`
   */
  theme?: 'green' | 'red' | 'yellow';
  /** Timer text. */
  timerText?: string;
}

export const HeroForehead = ({ timerText, theme = 'green' }: HeroForeheadProps) => {
  if (!timerText) {
    return null;
  }

  return (
    <div className={classnames(style.status, { [style[theme]]: true })} data-testid="hero-forehead">
      <span className={style.timerText}>{timerText}</span>
    </div>
  );
};

interface HeroForeheadLiveAuctionProps {
  /** Live lane information. */
  liveLane?: LiveLane;
  /** Time offset. */
  timeOffset: number;
  /** Current user. */
  user: User;
}

export const HeroForeheadLiveAuction = ({ liveLane, timeOffset, user }: HeroForeheadLiveAuctionProps) => {
  const { timerStart, timerEnd, liveItem } = liveLane || {};
  const startTime = moment(timerStart).add(timeOffset, 'ms');
  const endTime = moment(timerEnd).add(timeOffset, 'ms');
  const isStaffUser = isAuctionStaff(user);
  const isWinning =
    liveItem?.winningBid?.consignerId &&
    !isStaffUser &&
    getEnabledCompanyIds(user)?.includes(liveItem?.winningBid?.consignerId);
  const isLosing =
    !isWinning &&
    !isStaffUser &&
    getEnabledCompanyIds(user)?.some((consignerId) => liveItem?.uniqueBidders?.includes(consignerId));
  const numBids = Number(liveItem?.totalBids);

  return (
    <div
      className={classnames(style.status, liveItem?.state && style[`liveItem${liveItem.state}`])}
      data-testid="hero-forehead-live-auction"
    >
      <div className={classnames(style.timerBarContainer, isLosing && style.isLosing)}>
        {startTime?.isValid() && endTime?.isValid() && (
          <TimerBar
            className={classnames(style.timerBar, isLosing && style.isLosing)}
            end={endTime}
            start={startTime}
          />
        )}
      </div>
      <div className={style.bid}>
        <Countdown className={style.countdown} end={endTime} timeFormat={TimerFormats} />
        {liveItem?.state === LiveAuctionItemState.BID && numBids > 0 && (
          <span className={style.preDot}>
            {numBids} {numBids === 1 ? 'bid' : 'bids'}
            {isWinning && (
              <span className={style.preDot} data-testid="auction-winning-bid-name">
                {isGroupManagerRole(user) ? liveItem?.winningBid?.consigner : t('you_are_winning')}
              </span>
            )}
            {!isWinning && (
              <span className={style.preDot} data-testid="auction-not-winning-bid-name">
                {liveItem.winningBid?.consigner}
              </span>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

interface HeroForeheadLiveAppraisalProps {
  /** Auction item details information. */
  details: AuctionItemDetailsProps;
  /** Time offset. */
  timeOffset: number;
  /** Current user. */
  user: User;
}

export const HeroForeheadLiveAppraisal = ({
  details: { timerEnd, bidTimeline, topOffer },
  timeOffset,
  user,
}: HeroForeheadLiveAppraisalProps) => {
  const endTime = moment(timerEnd).add(timeOffset, 'ms');
  const startTime = endTime ? endTime.clone().add(-1, 'minute') : null;
  const isLosing = bidTimeline ? bidTimeline.outbid : false;
  const isWinning = bidTimeline ? bidTimeline.winning : false;
  const numBids = bidTimeline ? bidTimeline.count : 0;
  const winningCompany = topOffer?.company;

  return (
    <div className={style.status} data-testid="hero-forehead-live-appraisal-buy-now">
      <div className={classnames(style.timerBarContainer, isLosing && style.isLosing)}>
        {startTime?.isValid() && endTime?.isValid() && (
          <TimerBar
            className={classnames(style.timerBar, isLosing && style.isLosing)}
            end={endTime}
            start={startTime}
          />
        )}
      </div>
      <div className={style.bid}>
        <Sprite className={style.clockGlyph} glyph={ClockGlyph} />
        <Countdown className={style.countdown} end={endTime} timeFormat={TimerFormats} />
        {numBids > 0 && (
          <span className={style.preDot}>
            {tPlural('x_bids', numBids, [numBids])}
            {isWinning && (
              <span className={style.preDot} data-testid="appraisal-winning-bid-name">
                {isGroupManagerRole(user) ? winningCompany?.name : t('you_are_winning')}
              </span>
            )}
            {isLosing && <span className={style.preDot}>{t('you_are_losing')}</span>}
          </span>
        )}
        {isAuctionStaff(user) && winningCompany?.name && (
          <span className={style.preDot}>{t('x_is_winning', [winningCompany.name])}</span>
        )}
      </div>
    </div>
  );
};

export const HeroForeheadLiveBuyNow = HeroForeheadLiveAppraisal;

interface HeroForeheadInIfBidProps {
  /** Auction item details information. */
  details: AuctionItemDetailsProps;
  /** Time offset. */
  timeOffset: number;
}

export const HeroForeheadInIfBid = ({
  details: { ifBidTimeline, timerText },
  timeOffset,
}: HeroForeheadInIfBidProps) => {
  const endTime = moment(ifBidTimeline?.ending).add(timeOffset, 'ms');
  const startTime = endTime ? endTime.clone().add(-1, 'minute') : null;

  const awaitingMyResponse = ifBidTimeline?.awaitingMyResponse;

  return (
    <div className={style.status} data-testid="hero-forehead-in-if-bid">
      <div className={classnames(style.timerBarContainer, awaitingMyResponse && style.awaitingMyResponse)}>
        {startTime?.isValid() && endTime?.isValid() && (
          <TimerBar
            className={classnames(style.timerBar, awaitingMyResponse && style.awaitingMyResponse)}
            end={endTime}
            start={startTime}
          />
        )}
      </div>
      <div className={style.bid}>
        <Sprite className={style.clockGlyph} glyph={ClockGlyph} />
        <Countdown className={style.countdown} end={endTime} timeFormat={TimerFormats} />
        <span className={style.preDot}>{timerText}</span>
      </div>
    </div>
  );
};

interface HeroForeheadAwaitingCheckoutProps {
  /** Auction item details information. */
  details: AuctionItemDetailsProps;
  /** Time offset. */
  timeOffset: number;
}

export const HeroForeheadAwaitingCheckout = ({ details, timeOffset }: HeroForeheadAwaitingCheckoutProps) => {
  const endTime = moment(details?.checkoutExpiry).add(timeOffset, 'ms');
  const startTime = endTime ? endTime.clone().add(-1, 'minute') : null;

  return (
    <div className={style.status} data-testid="hero-forehead-awaiting-checkout">
      <div className={classnames(style.timerBarContainer, style.awaitingCheckout)}>
        {startTime?.isValid() && endTime?.isValid() && (
          <TimerBar className={classnames(style.timerBar, style.awaitingCheckout)} end={endTime} start={startTime} />
        )}
      </div>
      <div className={style.bid}>
        <Sprite className={style.clockGlyph} glyph={ClockGlyph} />
        <Countdown className={style.countdown} end={endTime} timeFormat={TimerFormats} />
        <span>&nbsp; • {t('time_left_to_complete_checkout')}</span>
      </div>
    </div>
  );
};
