import ApiRequest from 'store/shared/api/apiRequest';
import { Pagination } from 'constants/enums/pagination';
import { LocationConnection, QuerylocationConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

export interface QueryLocationConnectionArgs extends Omit<QuerylocationConnectionArgs, 'public'> {
  isPublic?: QuerylocationConnectionArgs['public'];
}

export const getLocations = ({
  after = undefined,
  archived = undefined,
  auctionId = undefined,
  before = undefined,
  carrierId = undefined,
  city = undefined,
  consignerId = undefined,
  country = undefined,
  first = Pagination.LIST_LENGTH,
  isPublic = undefined,
  keyword = undefined,
  last = undefined,
  mode = undefined,
  name = undefined,
  region = undefined,
  regionCode = undefined,
  sort = undefined,
}: QueryLocationConnectionArgs) =>
  ApiRequest<{ locationConnection: LocationConnection }>({
    data: {
      operationName: 'getLocations',
      query: `
      query(
        $after: String,
        $archived: Boolean,
        $auctionId: String,
        $before: String,
        $carrierId: [String],
        $city: [String],
        $consignerId: [String],
        $country: [String],
        $first: Int,
        $keyword: String,
        $last: Int,
        $mode: LocationConnectionMode,
        $name: [String],
        $public: Boolean,
        $region: [String],
        $regionCode: [String],
        $sort: [Sort]
      ) {
        locationConnection(
          after: $after,
          archived: $archived,
          auctionId: $auctionId,
          before: $before,
          carrierId: $carrierId,
          city: $city,
          consignerId: $consignerId,
          country: $country,
          first: $first,
          keyword: $keyword,
          last: $last,
          mode: $mode,
          name: $name,
          public: $public,
          region: $region,
          regionCode: $regionCode,
          sort: $sort
        ) {
          edges {
            node {
              ...location
            }
          }
        }
      }
      ${locationFragment}
    `,
      variables: {
        after,
        archived,
        auctionId,
        before,
        carrierId,
        city,
        consignerId,
        country,
        first,
        keyword,
        last,
        mode,
        name,
        public: isPublic,
        region,
        regionCode,
        sort,
      },
    },
  });
