import classnames from 'classnames';

import blackBookLogo from 'glyphs/logo-black-book.svg';

import Sprite from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './blackBookCopyright.scss';

interface Props {
  /** CSS styling to override the default style. */
  className?: string;
  /** CSS styling to override the logo style. */
  logoClassName?: string;
  /**
   * True when the copyright logo is displayed.
   * @default true
   */
  showLogo?: boolean;
}

const BlackBookCopyright = ({ className, logoClassName, showLogo = true }: Props) => (
  <div className={classnames(style.blackBookCopyright, className)}>
    {!!showLogo && <Sprite className={classnames(style.blackBookLogo, logoClassName)} glyph={blackBookLogo} />}
    {t('black_book_copyright_info')}
  </div>
);

export default BlackBookCopyright;
