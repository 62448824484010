import { PropsWithChildren, RefObject, createContext, useCallback, useContext, useMemo, useRef } from 'react';

export interface MyBlockPrintContentContextState {
  /** Ref to the my block item content */
  contentRef: RefObject<HTMLDivElement>;
  /** Callback to set the item content ref */
  setContentRef: (ref: HTMLDivElement) => void;
}

/**
 * The my block print content context.
 * Provides the `MyBlockPrintContentContextState` to all consumers of `useMyBlockPrintContent()`.
 */
export const MyBlockPrintContentContext = createContext<MyBlockPrintContentContextState | undefined>(undefined);

export const MyBlockPrintContentProvider = ({ children }: PropsWithChildren) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  /**
   * Callback to set the item list ref
   */
  const setContentRef = useCallback((ref: HTMLDivElement) => {
    contentRef.current = ref;
  }, []);

  /**
   * Memoize the full context value
   */
  const contextValue: MyBlockPrintContentContextState = useMemo(
    () => ({
      contentRef,
      setContentRef,
    }),
    [contentRef, setContentRef]
  );

  return <MyBlockPrintContentContext.Provider value={contextValue}>{children}</MyBlockPrintContentContext.Provider>;
};

/**
 * A hook for getting a reference to the `MyBlockPrintContentContextState` data.
 */
export const useMyBlockPrintContent = (): MyBlockPrintContentContextState | undefined => {
  const context = useContext(MyBlockPrintContentContext);
  if (context === undefined) {
    throw new Error('"useMyBlockPrintContent()" must be used within the "MyBlockPrintContentProvider"');
  }
  return context;
};
