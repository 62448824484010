import { ComponentProps } from 'react';

import AddFeeForm, {
  AuctionFeeProps,
  AuctionItemFeeProps,
  FeeType,
  InventoryItemFeeProps,
  LocationFeeProps,
  ServiceCreateOptions,
  ServiceMetadataOptions,
} from 'forms/admin/auctions/addFeeForm';
import BaseClass from 'components/ui/shared/base';
import { AuctionServiceMetadata, AuctionServiceMetadataParty } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { ServiceFee } from 'components/sections/admin/auctions/details/fees/feesSlideOutListItem';
import {
  auctionItemAuctionServiceCreate,
  auctionServiceCreate,
} from 'store/shared/api/graph/mutations/auctionServices';
import {
  getAuctionItemAuctionServiceMetadata,
  getAuctionServiceMetadata,
} from 'store/shared/api/graph/queries/auctionServices';
import { getInventoryServiceMetadata } from 'store/shared/api/graph/queries/inventoryServices';
import { inventoryServiceCreate } from 'store/shared/api/graph/mutations/inventoryServices';
import { locationInventoryServiceCreate } from 'store/shared/api/graph/mutations/locationInventoryServices';

interface BaseProps {
  /** Service fees to exclude from new add fee options. */
  excludedServiceFees?: ServiceFee[];
  /** True when add fee dialog is open. */
  isOpen: boolean;
  /** Callback to handle onClose event */
  onClose: () => void;
  /** Callback to handle onUpdateList event */
  onUpdateList: () => Promise<any>;
  /** Buyer or Seller */
  party: AuctionServiceMetadataParty;
}

type Props =
  | (AuctionItemFeeProps & BaseProps)
  | (AuctionFeeProps & BaseProps)
  | (InventoryItemFeeProps & BaseProps)
  | (LocationFeeProps & BaseProps);

interface State {
  availableFees?: AuctionServiceMetadata[];
  errorMessages: ErrorMessages;
  isLoadingFeeMetadata: boolean;
  isRemoveFeeDialogOpen: boolean;
  isSubmitting: boolean;
}

class AddFeeFormContainer extends BaseClass<Props, State> {
  state = {
    availableFees: undefined,
    errorMessages: [],
    isLoadingFeeMetadata: false,
    isRemoveFeeDialogOpen: false,
    isSubmitting: false,
  };

  componentDidUpdate(prevProps) {
    const { isOpen: isOpenPrev } = prevProps;
    const { isOpen } = this.props;

    if (isOpenPrev && !isOpen) {
      // Clear selected fee when closing dialog
      this.setState({ availableFees: undefined, errorMessages: [] });
    }
  }

  getServiceMetadata = ({ feeType, options }: ServiceMetadataOptions) => {
    if (feeType === FeeType.INVENTORY || feeType === FeeType.LOCATION) {
      return getInventoryServiceMetadata(options);
    }

    if (feeType === FeeType.AUCTION_ITEM) {
      return getAuctionItemAuctionServiceMetadata(options);
    }

    return getAuctionServiceMetadata(options);
  };

  serviceCreate = ({ feeType, options }: ServiceCreateOptions) => {
    switch (feeType) {
      case FeeType.LOCATION:
        return locationInventoryServiceCreate(options);
      case FeeType.INVENTORY:
        return inventoryServiceCreate(options);
      case FeeType.AUCTION_ITEM:
        return auctionItemAuctionServiceCreate(options);
      default:
        return auctionServiceCreate(options);
    }
  };

  /**
   * Filter available fees that can be created.
   */
  filterAvailableFees = (serviceMetadata: AuctionServiceMetadata[] | undefined) => {
    const excludedMetadataIds = this.props.excludedServiceFees?.map((fee) => fee.serviceMetadata.id) ?? [];
    return serviceMetadata?.filter((metadata) => !excludedMetadataIds.includes(metadata.id));
  };

  getFeeMetadata = (options: ServiceMetadataOptions) => {
    this.setState({ isLoadingFeeMetadata: true });

    this.getServiceMetadata(options)
      .then((response) => {
        const metadata = response?.data?.data?.serviceMetadata;
        const availableFees = this.props.excludedServiceFees?.length ? this.filterAvailableFees(metadata) : metadata;
        this.setState({ availableFees });
      })
      .catch(this.onApiError)
      .finally(() => this.setState({ isLoadingFeeMetadata: false }));
  };

  onSubmit = (options: ServiceCreateOptions) => {
    const { onClose, onUpdateList } = this.props;

    this.setState({ isSubmitting: true });
    return this.serviceCreate(options)
      ?.then(() => onUpdateList()?.then(onClose))
      ?.catch(this.onApiError)
      ?.finally(() => this.setState({ isSubmitting: false }));
  };

  getAddFeeFormProps = (): ComponentProps<typeof AddFeeForm> => {
    const { isOpen, onClose, party, ...props } = this.props;
    const { availableFees, errorMessages, isLoadingFeeMetadata, isSubmitting } = this.state;

    const baseProps = {
      availableFees,
      errorMessages,
      getFeeMetadata: this.getFeeMetadata,
      isLoadingFeeMetadata,
      isOpen,
      isSubmitting,
      onClose,
      onSubmit: this.onSubmit,
      party,
    };

    switch (props.feeType) {
      case FeeType.LOCATION:
        return { ...baseProps, feeType: props.feeType, locationId: props.locationId };

      case FeeType.INVENTORY:
        return { ...baseProps, feeType: props.feeType, inventoryItemId: props.inventoryItemId };

      case FeeType.AUCTION_ITEM:
        return {
          ...baseProps,
          auctionId: props.auctionId,
          auctionItemId: props.auctionItemId,
          companyId: props.companyId,
          feeType: props.feeType,
          format: props.format,
        };

      case FeeType.AUCTION:
      case FeeType.AUCTION_COMPANY:
      default:
        return {
          ...baseProps,
          auctionId: props.auctionId,
          companyId: props.companyId,
          feeType: props.feeType,
          format: props.format,
        };
    }
  };

  render() {
    return <AddFeeForm {...this.getAddFeeFormProps()} />;
  }
}

export default AddFeeFormContainer;
