import classnames from 'classnames';
import { ReactNode } from 'react';

import Button from 'components/ui/shared/button';

import style from './header.scss';

interface Props {
  /** Auxiliary label shown at the end of the header. */
  auxLabel: string;

  /** CSS styling to overwrite the default auxiliary label style. */
  auxLabelClassName?: string;

  /** CSS styling to overwrite the default header style. */
  className?: string;

  /** A collection of labels displayed as the header. */
  label: ReactNode[];

  /** CSS styling to overwrite the default label style. */
  labelClassName?: string;

  /** Function invoked for auxiliary label. */
  onAuxLabelClick?: () => void;

  /** The title that shows when mouse hovering over the entire header. */
  title?: string;
}

const Header = ({ label, auxLabel, onAuxLabelClick, className, labelClassName, auxLabelClassName, title }: Props) => (
  <header className={classnames(style.header, className)} title={title}>
    <div className={labelClassName}>
      {label.map((labelComp, i) => (
        <span key={i} className={style.labelComp}>
          {labelComp}
        </span>
      ))}
    </div>
    {!onAuxLabelClick ? (
      <div className={auxLabelClassName}>{auxLabel}</div>
    ) : (
      <Button
        className={classnames(style.button, auxLabelClassName)}
        onClick={(event) => {
          event.preventDefault();
          onAuxLabelClick();
        }}
        theme="none"
      >
        {auxLabel}
      </Button>
    )}
  </header>
);

export default Header;
