import classnames from 'classnames';

import NoResults from 'components/ui/noResults/noResults';
import Button from 'components/ui/shared/button';
import { t } from 'utils/intlUtils';

import style from './noResultsList.scss';

interface Props {
  /** True when results are filtered. */
  isFilteredList?: boolean;
  /** CSS styling to overwrite no results style. */
  noResultClassName?: string;
  /** Function invoked on remove filters button click. */
  onClearFilters?: () => void;
  /** Function invoked on refresh button click. */
  onRefresh?: () => void;
}

const NoResultsList = ({ isFilteredList, noResultClassName, onClearFilters, onRefresh }: Props) => {
  if (isFilteredList) {
    return (
      <NoResults className={classnames(style.noResultsList, noResultClassName)}>
        <p className={style.noResultsDetails}>{t('not_finding_what_you_want')}</p>
        <p>{t('change_or_remove_filters_message')}</p>
        <Button onClick={() => onClearFilters?.()} theme="blue">
          {t('remove_all_filters')}
        </Button>
      </NoResults>
    );
  }

  return (
    <NoResults className={classnames(style.noResultsList, noResultClassName)}>
      <p className={style.noResultsDetails}>{t('not_finding_what_you_want')}</p>
      <p>{t('try_refreshing_the_page_in_a_few_minutes')}</p>
      <Button onClick={() => onRefresh?.()} theme="blue">
        {t('refresh')}
      </Button>
    </NoResults>
  );
};

export default NoResultsList;
