import { createAction } from 'redux-actions';

import { getCompaniesList } from './companiesApi';
import { parseQueryParams } from 'utils/apiUtils';

export const companiesListIsLoading = createAction('ADMIN_COMPANIES_LOADING');
export const companiesListLoaded = createAction('ADMIN_COMPANIES_LOADED');
export const companiesListCleared = createAction('ADMIN_COMPANIES_CLEARED');

export const processGetCompaniesList = (options, dispatch) => {
  dispatch(companiesListIsLoading(true));

  return getCompaniesList(parseQueryParams(options)).then((response) =>
    dispatch(companiesListLoaded(response?.data.data))
  );
};
