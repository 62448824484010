import ApiRequest from 'store/shared/api/apiRequest';
import {
  InventoryService,
  MutationinventoryServiceCreateArgs,
  MutationinventoryServiceRemoveArgs,
} from 'store/shared/api/graph/interfaces/types';

export const inventoryServiceCreate = ({
  amount,
  inventoryItemId,
  serviceMetadataId,
}: MutationinventoryServiceCreateArgs) =>
  ApiRequest<{ inventoryServiceCreate: InventoryService }>({
    data: {
      operationName: 'inventoryServiceCreate',
      query: `
        mutation ($amount: Float!, $inventoryItemId: String!, $serviceMetadataId: String!) {
          inventoryServiceCreate(
            amount: $amount
            inventoryItemId: $inventoryItemId
            serviceMetadataId: $serviceMetadataId
          ) {
            id
          }
        }
      `,
      variables: {
        amount,
        inventoryItemId,
        serviceMetadataId,
      },
    },
  });

export const removeInventoryFee = ({ inventoryServiceId }: MutationinventoryServiceRemoveArgs) =>
  ApiRequest<{ inventoryServiceRemove: boolean }>({
    data: {
      operationName: 'removeInventoryFee',
      query: `
        mutation ($inventoryServiceId: String!) {
          inventoryServiceRemove(inventoryServiceId: $inventoryServiceId)
        }
      `,
      variables: { inventoryServiceId },
    },
  });
