import ApiRequest from 'store/shared/api/apiRequest';
import { Location, LocationConnection, TransportRequest } from 'store/shared/api/graph/interfaces/types';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { transportRequest } from '../list/transportJobsApi';

export interface GetLocationsOptions {
  /** The default location id. */
  defaultLocationId?: string;
  /** The destination company. */
  destinationCompany?: { id?: string };
  /** The pickup company. */
  pickupCompany?: { id?: string };
}

export const getLocations = ({ pickupCompany, destinationCompany, defaultLocationId }: GetLocationsOptions) => {
  const connection = (consignerId) => `
    locationConnection(first: 500, after: "0", consignerId: "${consignerId}") {
      edges {
        node {
          ...location
        }
      }
    }
  `;

  return ApiRequest<{ locationConnection: LocationConnection }>({
    data: {
      query: `
        query {
          pickupLocations: ${connection(pickupCompany?.id)}
          ${destinationCompany ? `destinationLocations: ${connection(destinationCompany.id)}` : ''}
          ${defaultLocationId ? `defaultLocation:location(locationId:"${defaultLocationId}") {...location}` : ''}
        }
        ${locationFragment}
      `,
    },
  });
};

// TODO: Potentially standardize the location create call
export const locationCreate = ({
  id = null,
  name = null,
  address1 = null,
  address2 = null,
  phone = null,
  fax = null,
  latitude = null,
  longitude = null,
  city = null,
  zipCode = null,
  regionCode = null,
  region = null,
  country = null,
  countryCode = null,
  email = null,
}) =>
  ApiRequest<{ locationCreate: Location }>({
    data: {
      query: `
      mutation(
        $id: String,
        $name: String,
        $address1: String,
        $address2: String,
        $phone: String,
        $fax: String,
        $latitude: Float,
        $longitude: Float,
        $city: String,
        $zipCode: String,
        $regionCode: String,
        $region: String,
        $country: String,
        $countryCode: String,
        $email: String
      ) {
        locationCreate(
          consignerId: $id,
          name: $name,
          address1: $address1,
          address2: $address2,
          phone: $phone,
          fax: $fax,
          latitude: $latitude,
          longitude: $longitude,
          city: $city,
          zipCode: $zipCode,
          regionCode: $regionCode,
          region: $region,
          country: $country,
          countryCode: $countryCode,
          email: $email
        ) {
          ...location
        }
    }
    ${locationFragment}
    `,
      variables: {
        id,
        name,
        address1,
        address2,
        phone,
        fax,
        latitude,
        longitude,
        city,
        zipCode,
        regionCode,
        region,
        country,
        countryCode,
        email,
      },
    },
  });

export const saveTransportRequest = ({
  auctionItemId = null,
  carrierNote = null,
  destinationLocationId = null,
  inventoryItemId = null,
  paidBy = null,
  pickupLocationId = null,
  type = null,
}) =>
  ApiRequest<{ transportRequestCreate: TransportRequest }>({
    data: {
      query: `
      mutation(
        $auctionItemId: String,
        $carrierNote: String,
        $destinationLocationId: String,
        $inventoryItemId: String,
        $paidBy: TransportPaidBy!,
        $pickupLocationId: String,
        $type: TransportRequestType!,
      ) {
        transportRequestCreate(
          auctionItemId: $auctionItemId,
          carrierNote: $carrierNote,
          destinationLocationId: $destinationLocationId,
          inventoryItemId: $inventoryItemId,
          paidBy: $paidBy,
          pickupLocationId: $pickupLocationId,
          type: $type,
        ) {
          __typename
          ${transportRequest}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        auctionItemId,
        destinationLocationId,
        inventoryItemId,
        carrierNote,
        paidBy,
        pickupLocationId,
        type,
      },
    },
  });
