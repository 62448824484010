import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import { InventoryItemValueType, QueryinventoryItemArgs } from 'store/shared/api/graph/interfaces/types';

export const getInventoryItemValuesMetaData = (inventoryItemId) =>
  ApiRequest<{ inventoryItemValuesMetaData: InventoryItemValueType[] }>({
    data: {
      operationName: 'getInventoryItemValuesMetaData',
      query: `
        {
          inventoryItemValuesMetaData(inventoryItemId:"${inventoryItemId}") {
            id
            name
            canAdd
            canUpdate
            canDelete
          }
        }
      `,
    },
  });

export const getAutoGradeScoreData = ({ inventoryItemId }: QueryinventoryItemArgs) =>
  ApiRequest<{ inventoryItem: InventoryItem }>({
    data: {
      query: `
      query($inventoryItemId: String!) {
        inventoryItem(inventoryItemId: $inventoryItemId) {
          ... on Vehicle {
            autoGradeScoreData {
                autoGradeVehicleDamageCount
                autoGradeVehicleDamage {
                  damageCodeRecord {
                    id
                    sectionNumber
                    sectionName
                    itemCode
                    itemName
                    damageName
                    damageCode
                    severityName
                    severityCode
                  }
                  photos {
                    id
                    location
                    ...photoFrag
                  }
                }
              }
            }
        }
      }
    `,
      variables: {
        inventoryItemId,
      },
    },
  });
