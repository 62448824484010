import classnames from 'classnames';
import { MouseEventHandler, useCallback, useState, useEffect, useRef } from 'react';

import OfferRowSlideout from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerRowSlideOut';
import { IfBidStatus, TimedOffer } from 'store/shared/api/graph/interfaces/types';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { t } from 'utils/intlUtils';

import style from './yourOfferRow.scss';

export interface LocationQuery {
  showOffers: string;
}

interface Props extends RouterProps<LocationQuery> {
  /** List of offer */
  offers: TimedOffer[][] | null;
  /** True when waiting response */
  waitingOnThem: boolean;
  /** True when there is an offer pending */
  waitingOnYou: boolean;
}

const YourOfferRow = ({ location, offers, router, waitingOnThem, waitingOnYou }: Props) => {
  const locationRef = useRef(location);
  const routerRef = useRef(router);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Set isOpen based on the query params `showOffers`, this would allow us to open the slide out
   * outside of this component
   */
  useEffect(() => {
    if (offers?.length) {
      setIsOpen(location.query.showOffers === 'true');
    }
  }, [location.query.showOffers, offers?.length]);

  /**
   * Update the query params `showOffer` when `isOpen` change
   */
  useEffect(() => {
    routerRef.current.push({
      pathname: locationRef.current.pathname,
      query: {
        ...locationRef.current.query,
        showOffers: `${isOpen}`,
      },
    });
  }, [isOpen]);

  /**
   * Handles slide out on row click
   */
  const handleOnRowClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => setIsOpen(true), []);

  /**
   * Handles slide out close
   */
  const handleOnSlideOutClose = useCallback(() => setIsOpen(false), []);

  if (!offers?.length) {
    return <Row name={t('your_offer')}>-</Row>;
  }

  const latestOffer = offers[0][0];
  const activeOffer = latestOffer.status === IfBidStatus.ACTIVE ? latestOffer : null;

  return (
    <>
      <Row dataTestId="status-row-your-offer" name={t('your_offer')} onClick={handleOnRowClick}>
        {activeOffer && (
          <span className={classnames({ [style.green]: waitingOnThem, [style.red]: waitingOnYou })}>
            {activeOffer.amount.formattedAmountRounded}
          </span>
        )}
      </Row>
      <OfferRowSlideout isOpen={isOpen} offers={offers} onClose={handleOnSlideOutClose} />
    </>
  );
};

export default withRouter(YourOfferRow);
