import { MouseEventHandler, useCallback, useEffect, useState, useRef } from 'react';

import OfferRowSlideout from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerRowSlideOut';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { TimedOffer } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

export interface LocationQuery {
  showOffers: string;
}

interface Props extends RouterProps<LocationQuery> {
  /** List of offer */
  offers: TimedOffer[][] | null;
}

const OfferRow = ({ location, offers, router }: Props) => {
  const locationRef = useRef(location);
  const routerRef = useRef(router);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Set isOpen based on the query params `showOffers`, this would allow us to open the slide out
   * outside of this component
   */
  useEffect(() => {
    if (offers?.length) {
      setIsOpen(location.query.showOffers === 'true');
    }
  }, [location.query.showOffers, offers?.length]);

  /**
   * Update the query params `showOffer` when `isOpen` change
   */
  useEffect(() => {
    routerRef.current.push({
      pathname: locationRef.current.pathname,
      query: {
        ...locationRef.current.query,
        showOffers: `${isOpen}`,
      },
    });
  }, [isOpen]);

  /**
   * Handles slide out on row click
   */
  const handleOnRowClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => setIsOpen(true), []);

  /**
   * Handles slide out close
   */
  const handleOnSlideOutClose = useCallback(() => setIsOpen(false), []);

  if (!offers?.length) {
    return <Row name={t('offers')}>-</Row>;
  }

  return (
    <>
      <Row dataTestId="status-row-offers" name={t('offers')} onClick={handleOnRowClick}>
        {offers.length}
      </Row>
      <OfferRowSlideout isOpen={isOpen} offers={offers} onClose={handleOnSlideOutClose} />
    </>
  );
};

export default withRouter(OfferRow);
