import moment from 'moment';
import i18next from 'i18next';
import i18nextSprintf from 'i18next-sprintf-postprocessor';
import { initReactI18next } from 'react-i18next';

import locales from 'locales';
import { getUrlParams } from 'utils/urlUtils';
import { storageManager } from 'utils/storageUtils';

export const localeStorage = storageManager.createOrFetchStorage('locale');

export enum Locale {
  EN_CA = 'en-CA',
  EN_US = 'en-US',
  FR_CA = 'fr-CA',
  ES_US = 'es-US',
}

export enum MultilingualStringValue {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
}

export const getMultilingualValueFromLocale = (locale: Locale) =>
  ({
    [Locale.EN_CA]: MultilingualStringValue.EN,
    [Locale.EN_US]: MultilingualStringValue.EN,
    [Locale.FR_CA]: MultilingualStringValue.FR,
    [Locale.ES_US]: MultilingualStringValue.ES,
  })[locale];

/**
 * Determines the current locale by checking if there's something passed directly, in the store,
 * or in the `navigator` object. Called as part of the boot sequence for the app to determine the
 * locale for `react-i18n`. Defaults to 'en-CA'.
 *
 * Acceptable return values are any `Locale` enum (declared above).
 */
export const getCurrentLocale = (locale?: Locale) => {
  // Determine locale
  const localeOverride = getUrlParams()?.localeOverride;
  const localeFallback = localeStorage.get() || navigator?.languages?.[0] || navigator.language || Locale.EN_CA;
  const localeNext = localeOverride || locale || localeFallback;

  // Validate against available `Locale` options
  return Object.values(Locale).indexOf(localeNext) < 0 ? Locale.EN_CA : localeNext;
};

/**
 * Simple method that sets the locale to the provided locale and refreshes the page
 */
export const setLocale = (locale: Locale) => {
  const localeNext = getCurrentLocale(locale);

  localeStorage.set(localeNext);
  i18next?.changeLanguage(localeNext);
  moment.locale(localeNext);
};

/**
 * This bootSequence is what gets called before the app is rendered. It will try to see if we need to load the `Intl`
 * polyfill, load it if need be, load the extra necessary locale data for the lang to work, add the locale to storage
 * (handled inside `loadLocaleData`), and finally resolve a Promise with the locale code.
 */
export const bootSequence = (locale = getCurrentLocale()) => {
  return new Promise((resolve) => {
    // Initialize `i18next`
    i18next
      .use(initReactI18next)
      .use(i18nextSprintf)
      .init({
        interpolation: {
          skipOnVariables: false,
        },
        resources: locales,
        lng: locale,
        fallbackLng: Locale.EN_CA,
        returnEmptyString: false,
        preload: [Locale.EN_CA],
      });

    // Initialize `moment` with current locale
    moment.locale(locale);

    // Resolve and begin the render process
    resolve(locale);
  });
};

/**
 * I18next translation util for both classes and functional components.
 */
export const translate = Object.freeze({
  /**
   * Get i18next translations with/without sprintf support.
   * Used with static singular/plural keys, for dynamic keys based on
   * a `count`, use `tPlural` instead.
   *
   * Example:
   *  import { t } from 'utils/intlUtils';
   *
   *
   *  // Without sprintf support
   *  t('key');
   *
   *  // With sprintf support
   *  t('key', ['5', 'days']); // Due in %d %s ---> 'Due in 5 days'
   *
   * @param {string} namespace - The key name (singular/plural) of the desired translation
   * @param {array} args - An array of sprintf tokens to process
   * @param {object} options - Conforms to useTranslation's options config and not localized.
   */
  t: (namespace, args: any[] = [], options = {}): string => {
    if (args.length) {
      return i18next.t(namespace, { postProcess: 'sprintf', sprintf: args, ...options });
    }
    return i18next.t(namespace, options);
  },

  /**
   * Gets the singular or plural variation of a translation based on the `count` provided.
   * Different languages have different rules for plurals, thus the dynamic key to be used
   * is automatically handled by `i18next`.
   *
   * English language example:
   *  tPlural('car', 0); // 'Cars'
   *  tPlural('car', 1); // 'Car'
   *  tPlural('car', 2); // 'Cars'
   *
   * You can read more on i18next plurals here:
   * https://www.i18next.com/translation-function/plurals
   * https://edealer-wiki.netlify.app/documentation/localization.html#clients
   *
   * @param namespace - The singular key name of the desired translation
   * @param count - The value to determine whether to use the singular/plural key
   * @param args - An array of 'sprintf' args
   * @param options - An object of additional options or values
   */
  tPlural: (namespace: string, count: number = 0, args: any[] = [], options = {}): string =>
    i18next.t(namespace, { count: count || 0, postProcess: 'sprintf', sprintf: args, ...options }),
});

export const t = translate.t;
export const tPlural = translate.tPlural;
