import classnames from 'classnames';
import { Link } from 'react-router';

import User from 'constants/user';
import { MenuItem } from 'components/core/navigation/subNavigationMenuItems';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './subNavigation.scss';

interface Props extends RouterProps {
  className?: string;
  menu?: MenuItem;
  user: User;
}

const SubNavigation = ({ menu, className, user, location: { pathname } }: Props) => {
  if (!menu) {
    return null;
  }

  const isPermitted = (permission, restrictedTo) => {
    if (restrictedTo && !pathname.includes(restrictedTo)) {
      return false;
    }

    return permission !== undefined ? hasSystemPermission(user, [permission]) : true;
  };

  const activeClass = (str) => str.includes(pathname) && style.active;

  return (
    <div className={style.subNavigation}>
      <ul className={classnames(style.links, className)}>
        {menu.links.map(
          (menuItem, index) =>
            isPermitted(menuItem.permission, menuItem.restrictedTo) && (
              <li
                key={`sub-navigation-item-${index}`}
                className={classnames(style.item, menuItem.className && style[menuItem.className])}
                data-testid="sub-navigation-item"
              >
                {menuItem.component || (
                  <Link
                    activeClassName={style.active}
                    className={classnames(style.link, activeClass(menuItem.link))}
                    to={menuItem.link}
                  >
                    {t(menuItem.label)}
                  </Link>
                )}
              </li>
            )
        )}
      </ul>
      {menu?.buttons?.length && (
        <ul className={style.buttons}>
          {menu?.buttons?.map(
            (button, index) =>
              isPermitted(button?.permission, button.restrictedTo) && (
                <li key={`sub-navigation-button-${index}`} className={button.className && style[button.className]}>
                  {button.component}
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

export default withRouter(SubNavigation);
