import { connect, ConnectedProps } from 'react-redux';

import AddModifyIOUpdater from 'components/sections/inventoryItem/addModify/addModifyIOUpdater';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/sections/inventoryItem/addModify/confirmDialog';
import ErrorDialog from 'components/ui/shared/dialogs/confirmDialog';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import Loading from 'components/ui/loading/loading';
import VehicleForm, { PaneIndex } from 'components/sections/inventoryItem/addModify/vehicleForm';
import VinDuplicatesOverlay from 'components/sections/inventoryItem/addModify/vinDuplicatesOverlay';
import VinOverlay from 'components/sections/inventoryItem/addModify/vinOverlay';
import VinSelectTrimOverlay from 'components/sections/inventoryItem/addModify/vinSelectTrimOverlay';
import { AddModifyVehicleProps } from 'store/inventoryItem/addModify/addModifyModels';
import { AppDispatch, AppState } from 'store/configureStore';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { ErrorMessages } from 'constants/errors';
import {
  UploadInventoryPhotoOptions,
  addModifyClear,
  addModifyClearError,
  addModifyPhotosError,
  addModifySetError,
  addModifySetVehicle,
  processCreateInventoryItem,
  processGetDeclarationsMeta,
  processGetInventoryItemById,
  processSubmitAttachCarfax,
  processSubmitVinOverlay,
  processUpdateInventoryItem,
  processUploadInventoryPhoto,
  setCountryCode,
  setModal,
  setOverlay,
  setPage,
} from 'store/inventoryItem/addModify/addModifyActions';
import { LooseObject } from 'constants/objects';
import { Modal, Overlay, Page } from 'constants/enums/addModifyInventoryItem';
import {
  MutationvehicleCarfaxCanadaAttachReportArgs,
  PhotoType,
  QueryauctionItemArgs,
} from 'store/shared/api/graph/interfaces/types';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { SectionNavigation } from 'components/sections/inventoryItem/addModify/interfaces/vehicleForm';
import { correctPhotosShotCodeOrder } from 'utils/photoUtils';
import { getEnabledCompanyRelationships, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { processGetAuctionItemDetails } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { processGetInventoryItemDetails } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { processUpdateInventoryItemsList } from 'store/inventoryItemsList/inventoryItemsListActions';
import { t } from 'utils/intlUtils';

import style from './addModifyContainer.scss';

const stateConnect = (state: AppState) => ({
  /** Add modify inventory item. */
  inventoryItem: state.app.inventoryItem.toJS(),
  /** Current logged in user. */
  user: state.app.user.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to change country code. */
  changeCountryCode: (options: string | undefined) => dispatch(setCountryCode(options)),
  /** Callback function to change modal. */
  changeModal: (options: Modal) => dispatch(setModal(options)),
  /** Callback function to change overlay. */
  changeOverlay: (options: Overlay | null) => dispatch(setOverlay(options)),
  /** Callback function to change page. */
  changePage: (options: Page) => dispatch(setPage(options)),
  /** Callback function to clear errors. */
  clearError: () => dispatch(addModifyClearError()),
  /** Callback function to clear inventory item. */
  clearInventoryItem: () => dispatch(addModifyClear()),
  /** Callback function to create inventory item. */
  createInventoryItem: (options: AddModifyVehicleProps) => processCreateInventoryItem(dispatch, options),
  /** Callback function to get auction item details. */
  getAuctionItemDetails: (options: QueryauctionItemArgs) => processGetAuctionItemDetails(options, dispatch),
  /** Callback function to get declarations metadata. */
  getDeclarationsMeta: () => processGetDeclarationsMeta(dispatch),
  /** Callback function to get inventory item by id. */
  getInventoryItemById: (options: string) => processGetInventoryItemById(dispatch, options),
  /** Callback function to get inventory item details. */
  getInventoryItemDetails: (options: { inventoryItemId: string }, callback?: () => void) =>
    processGetInventoryItemDetails(options, dispatch, callback),
  /** Callback function to set errors. */
  setError: (options: ErrorMessages) => dispatch(addModifySetError(options)),
  /** Callback function to set photo error messages. */
  setPhotoErrorMessages: (options: ErrorMessages) => dispatch(addModifyPhotosError(options)),
  /** Callback function to set vehicle. */
  setVehicle: (options: AddModifyVehicleProps) => dispatch(addModifySetVehicle(options)),
  /** Callback function to attach carfax Canada report. */
  submitAttachCarfax: (options: MutationvehicleCarfaxCanadaAttachReportArgs) =>
    processSubmitAttachCarfax(dispatch, options),
  /** Callback function to submit vin overlay. */
  submitVinOverlay: (options: AddModifyVehicleProps) => processSubmitVinOverlay(dispatch, options),
  /** Callback function to update inventory item. */
  updateInventoryItem: (options: AddModifyVehicleProps) => processUpdateInventoryItem(dispatch, options),
  /** Callback function to update inventory list. */
  updateInventoryList: (options: LooseObject) => processUpdateInventoryItemsList(options, dispatch),
  /** Callback function to upload inventory photos. */
  uploadInventoryPhoto: (options: UploadInventoryPhotoOptions) => processUploadInventoryPhoto(dispatch, options),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends RouterProps, ConnectedProps<typeof connector> {
  /** Auction item id. */
  auctionItemId?: string | null;
  /** Callbackz function to close the modal. */
  closeModal: () => void;
  /** Inventory item id. */
  inventoryItemId?: string | null;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Information on which section to navigate to. */
  navigateToSection: SectionNavigation;
}

class AddModifyContainer extends BaseClass<Props> {
  static defaultProps = {
    auctionItemId: null,
    inventoryItemId: null,
    navigateToSection: {
      paneIndex: PaneIndex.VEHICLE_DETAILS,
      section: '',
    },
  };

  constructor(props: Props) {
    super(props);
    const { changeCountryCode, inventoryItem, user } = props;
    const vehicleId = inventoryItem.results.vehicle.id;
    const isUser = !isGroupManagerRole(user) && !isAuctionStaff(user);
    const userCompany = getEnabledCompanyRelationships(user)?.[0]?.company;

    if (!vehicleId && isUser && userCompany) {
      changeCountryCode(userCompany?.primaryLocation?.countryCode);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      auctionItemId,
      changeModal,
      changeOverlay,
      changePage,
      clearInventoryItem,
      closeModal,
      getAuctionItemDetails,
      getDeclarationsMeta,
      getInventoryItemById,
      getInventoryItemDetails,
      inventoryItem: {
        results: {
          vehicle: { id },
        },
        hasPhotosChanged,
        hasStateChanged,
        overlay: overlayNext,
      },
      inventoryItemId,
      isOpen,
      location: { pathname, query },
      router: { replace },
      updateInventoryList,
    } = this.props;
    const {
      isOpen: isOpenPrev,
      inventoryItem: { overlay },
    } = prevProps;
    if (!isOpenPrev && isOpen) {
      changeModal(Modal.ADD_MODIFY);
      changePage(Page.VIN_OVERLAY);
      getDeclarationsMeta();
      if (inventoryItemId) {
        changeOverlay(Overlay.LOADING);
        getInventoryItemById(inventoryItemId);
      }
    }

    if (isOpenPrev && !isOpen) {
      const shouldRefresh = hasStateChanged || hasPhotosChanged || !inventoryItemId;

      if (id && shouldRefresh) {
        if (auctionItemId) {
          // Refresh modified auctionItem details
          getAuctionItemDetails({ auctionItemId });
        } else if (inventoryItemId) {
          // Refresh modified inventoryItem details
          getInventoryItemDetails({ inventoryItemId });
        } else if (pathname === '/sell/parked') {
          // Save new inventoryItem when in inventory section
          replace(`/sell/parked?id=${id}`);
          updateInventoryList({ ...query, id });
        } else {
          // Save new inventoryItem and navigate to inventory section
          replace(`/sell/parked?id=${id}`);
        }
      }
      clearInventoryItem();
    }

    if (isOpenPrev && overlay === Overlay.SAVING && overlayNext === Overlay.CLOSE_MODAL) {
      closeModal();
    }
  }

  getFormattedPhotos = () => {
    const {
      inventoryItem: {
        results: {
          vehicle: { photos },
        },
      },
    } = this.props;

    const formattedPhotos = correctPhotosShotCodeOrder(photos || []);

    return formattedPhotos.map((photo) => ({
      damageLocation: photo.location,
      id: photo.id,
      shotCode: photo.shotCode,
      type: photo.type,
    }));
  };

  isValidateComplete = () => {
    const {
      inventoryItem: {
        results: { vehicle },
      },
    } = this.props;
    const { mileage, photos, ...props } = vehicle;
    const hasExteriorPhoto = !!photos?.filter((obj) => obj.type === PhotoType.EXTERIOR).length;
    const requiredFields = vehicle.incompleteFieldMessages;
    const modifiedVehicle = {
      ...props,
      mileage: mileage.amount,
      photo: hasExteriorPhoto,
    };

    return requiredFields ? !requiredFields.filter((item) => item && !modifiedVehicle[item.field]).length : false;
  };

  handleOnRequestClose = () => {
    const {
      changeOverlay,
      closeModal,
      inventoryItem: { hasStateChanged },
    } = this.props;

    if (hasStateChanged) {
      changeOverlay(Overlay.CONFIRM_DIALOG);
    } else {
      closeModal();
    }
  };

  handleOnConfirm = (shouldSubmit) => {
    const {
      changeOverlay,
      closeModal,
      inventoryItem: {
        hasStateChanged,
        results: { vehicle },
      },
    } = this.props;

    if (shouldSubmit && hasStateChanged) {
      changeOverlay(Overlay.SAVING);
      if (vehicle.id) {
        this.handleSubmitUpdateInventoryItem();
      } else {
        this.handleSubmitCreateInventoryItem();
      }
    } else if (shouldSubmit) {
      closeModal();
    } else {
      changeOverlay(null);
    }
  };

  handleSubmitUpdateInventoryItem = (obj: AddModifyVehicleProps = {}, persist = true) => {
    const {
      inventoryItem: {
        results: { vehicle },
      },
      updateInventoryItem,
    } = this.props;
    const vehicleObj = {
      ...vehicle,
      inventoryItemId: vehicle.id,
      photos: this.getFormattedPhotos(),
      validateComplete: this.isValidateComplete(),
      ...obj,
      persist,
    };
    updateInventoryItem(vehicleObj);
  };

  handleSubmitCreateInventoryItem = (obj: AddModifyVehicleProps = {}, persist = true) => {
    const {
      createInventoryItem,
      inventoryItem: {
        results: { vehicle },
      },
    } = this.props;
    const vehicleObj = { ...vehicle, ...obj, persist };

    createInventoryItem(vehicleObj);
  };

  render() {
    const {
      changeCountryCode,
      changeOverlay,
      changePage,
      clearError,
      closeModal,
      inventoryItem,
      inventoryItem: {
        _error,
        duplicates,
        isLoading,
        modal,
        overlay,
        page,
        results: { vehicle },
      },
      inventoryItemId,
      isOpen,
      navigateToSection,
      setError,
      setPhotoErrorMessages,
      setVehicle,
      submitAttachCarfax,
      submitVinOverlay,
      uploadInventoryPhoto,
      user,
    } = this.props;
    const errorMessages = _error && _error.length ? _error : [];
    const modalLabel = inventoryItemId ? t('modify_vehicle') : t('add_vehicle');
    const exitLabel = inventoryItemId ? t('exit_modify_vehicle') : t('exit_add_vehicle');
    const questionLabel = inventoryItemId
      ? t('exit_modify_vehicle_section_question')
      : t('exit_add_vehicle_section_question');
    const messageLabel = vehicle.id ? t('save_vehicle_question') : t('unsaved_progress_will_be_lost_message');

    return (
      <>
        <AddModifyIOUpdater inventoryItemId={inventoryItemId} />
        <FormDialog
          isOpen={isOpen && modal === Modal.ADD_MODIFY}
          onClose={this.handleOnRequestClose}
          title={modalLabel}
        >
          <Switch>
            <Case if={page === Page.VIN_OVERLAY}>
              <VinOverlay
                changeCountryCode={changeCountryCode}
                clearError={clearError}
                errorMessages={errorMessages}
                handleSubmitCreateInventoryItem={this.handleSubmitCreateInventoryItem}
                isLoading={isLoading}
                setError={setError}
                setVehicle={setVehicle}
                submitVinOverlay={submitVinOverlay}
                user={user}
                vehicle={vehicle}
              />
            </Case>
            <Case if={page === Page.SELECT_TRIM}>
              <VinSelectTrimOverlay
                changePage={changePage}
                errorMessages={errorMessages}
                handleSubmitCreateInventoryItem={this.handleSubmitCreateInventoryItem}
                inventoryItem={inventoryItem}
                isLoading={isLoading}
                setVehicle={setVehicle}
                submitVinOverlay={submitVinOverlay}
                vehicle={vehicle}
              />
            </Case>
            <Case if={page === Page.VIN_OVERLAY_DUPLICATE}>
              <VinDuplicatesOverlay
                clearError={clearError}
                duplicates={duplicates}
                errorMessages={errorMessages}
                handleSubmitCreateInventoryItem={this.handleSubmitCreateInventoryItem}
                isLoading={isLoading}
                onRequestClose={closeModal}
                vehicle={vehicle}
              />
            </Case>
            <Case if={page === Page.VEHICLE_FORM}>
              <VehicleForm
                changeOverlay={changeOverlay}
                clearError={clearError}
                errorMessages={errorMessages}
                handleSubmitCreateInventoryItem={this.handleSubmitCreateInventoryItem}
                handleSubmitUpdateInventoryItem={this.handleSubmitUpdateInventoryItem}
                inventoryItem={inventoryItem}
                isNewInventoryItem={!inventoryItemId}
                navigateToSection={navigateToSection}
                overlay={overlay}
                setPhotoErrorMessages={setPhotoErrorMessages}
                setVehicle={setVehicle}
                submitAttachCarfax={submitAttachCarfax}
                uploadInventoryPhoto={uploadInventoryPhoto}
              />
            </Case>
          </Switch>
          {(overlay === Overlay.SAVING || overlay === Overlay.LOADING) && (
            <Loading
              hasFullWidth
              isLoading
              overlayClassName={style.loadingContainer}
              text={overlay === Overlay.SAVING ? t('saving') : t('loading')}
            />
          )}
          {overlay === Overlay.CONFIRM_DIALOG && (
            <ConfirmDialog onCloseWithoutSaving={closeModal} onConfirm={this.handleOnConfirm} title={exitLabel}>
              <p>{questionLabel}</p>
              <p>{messageLabel}</p>
            </ConfirmDialog>
          )}
        </FormDialog>
        <ErrorDialog
          bringToFront
          isOpen={isOpen && modal === Modal.ERROR_DIALOG}
          onConfirm={closeModal}
          title={t('error_occurred')}
          titleClassName={style.confirmTitleClassName}
        >
          {errorMessages.map((err) => err.message)}
        </ErrorDialog>
      </>
    );
  }
}

export default withRouter(connector(AddModifyContainer));
