import { connect, ConnectedProps } from 'react-redux';

import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemArrivedArgs } from 'store/shared/api/graph/interfaces/types';
import { getErrorMessages } from 'utils/apiUtils';
import { processAuctionItemArrived } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function used to pull auction item from auction. */
  auctionItemArrived: (options: MutationauctionItemArrivedArgs) => processAuctionItemArrived(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the auction item */
  auctionItemId: string;
  /** Function invoked when auctionItemArrived has completed. */
  onDone?: (value?: boolean) => void;
}

interface State {
  /** Validation errors. */
  errorMessages?: ErrorMessages;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when the form is submitting. */
  isSubmitting: boolean;
}

class Arrived extends BaseClass<Props, State> {
  static defaultProps = {
    onDone: () => {},
  };

  constructor(props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit) => {
    const { auctionItemId, auctionItemArrived, onDone } = this.props;

    if (shouldSubmit) {
      this.setState({ isSubmitting: true });
      auctionItemArrived({ auctionItemId })
        .then(() => onDone?.(true))
        .catch((error) => {
          const errorMessages = getErrorMessages(error);
          this.setState({ errorMessages, isSubmitting: false });
        });
    } else {
      this.setState({ errorMessages: undefined, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    return (
      <>
        <OperationButton
          dataTestId="vehicleHasArrived-button"
          glyph={submitGlyph}
          onClick={() => this.setState({ isOpen: true })}
          theme="green"
        >
          {t('vehicle_has_arrived')}
        </OperationButton>
        <Dialog onSubmit={this.onSubmit} {...this.state} />
      </>
    );
  }
}

export default connector(Arrived);
