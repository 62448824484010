import { List } from 'immutable';

import AuctionItemType from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  FacetedPageInfo,
  FacetGroup,
  MyBlockBuyerResponse,
  MyBlockSellerResponse,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface MyBlockModel {
  /** My block buyer statistics. */
  buyerStats: MyBlockBuyerResponse | null;
  /** Auction items facet groups. */
  facetGroups: List<RecordOf<FacetGroup>>;
  /** Information about pagination. */
  pageInfo: RecordOf<FacetedPageInfo> | null;
  /** List of auction items. */
  resultList: List<AuctionItemType>;
  /** My block seller statistics. */
  sellerStats: MyBlockSellerResponse | null;
}

export type MyBlockProps = ServerRecordOf<MyBlockModel>;

export const InitialState = ServerRecord<MyBlockModel>({
  buyerStats: null,
  facetGroups: List([]),
  pageInfo: null,
  resultList: List([]),
  sellerStats: null,
});
