import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import ConfirmDialog, { ConfirmDialogProps } from 'components/ui/shared/dialogs/confirmDialog';
import { t } from 'utils/intlUtils';

export interface GlobalDialogContextProps {
  config?: ConfirmDialogProps;
  setConfig: (config?: Partial<ConfirmDialogProps>) => void;
}

export const GlobalDialogContext = createContext<GlobalDialogContextProps | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const GlobalDialogProvider = ({ children }: Props) => {
  const [config, setConfig] = useState<ConfirmDialogProps | undefined>();

  /**
   * Public setter to update the dialog's config
   */
  const setNewConfig = useCallback(
    (newConfig) => {
      setConfig(newConfig);
      return newConfig;
    },
    [setConfig]
  );

  /**
   * GlobalDialog `onClose` callback to clear the existing dialog config
   */
  const onConfirm = useCallback(
    (shouldSubmit, event) => {
      config?.onConfirm?.(shouldSubmit, event);
      setConfig(undefined);
    },
    [config, setConfig]
  );

  // Memoized context values
  const value = useMemo(() => ({ config, setConfig: setNewConfig }), [config, setNewConfig]);

  return (
    <GlobalDialogContext.Provider value={value}>
      {children}
      {
        // Global Dialog
        // Mainly used for error handling, hence the defaults.
        // Configuration at component level can overwrite defaults to suit many needs.
      }
      <ConfirmDialog
        actionLabel={t('ok')}
        bringToFront
        theme="red"
        title={t('error')}
        {...config}
        isOpen={!!config}
        onConfirm={onConfirm}
      />
    </GlobalDialogContext.Provider>
  );
};

export function useGlobalDialog() {
  const context = useContext(GlobalDialogContext);
  if (context === undefined) {
    throw new Error('useGlobalDialog must be used within a GlobalDialogProvider');
  }
  return context;
}
