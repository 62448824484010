import classnames from 'classnames';
import { ForwardedRef, HTMLAttributes, ReactNode } from 'react';
import { Link } from 'react-router';

import Button from 'components/ui/shared/button';
import Tag from 'components/ui/tag/tag';
import UserImage from 'components/ui/shared/images/userImage';
import { DefaultQuery, Location } from 'constants/reactRouter';

import style from './listItemLayout.scss';

export type ListItemRef = ForwardedRef<HTMLLIElement & HTMLDivElement>;

export interface ListItemProps<T = DefaultQuery> {
  /** The component's child elements to be rendered. */
  children: ReactNode;
  /** Optional CSS styling. */
  className?: string;
  /** If true, `<ListItemLink>` will wrap children. */
  isLink?: boolean;
  /** If true, the list item will be highlighted as selected. */
  isSelected?: boolean;
  /** Optional CSS styling for the link. */
  linkClassName?: string;
  /** Invoked when list item is clicked. */
  onClick?: (value) => void;
  /** Invoked when list item is right-clicked. */
  onContextMenu?: (value) => void;
  /** The ref to the list item. */
  reference?: ListItemRef | null;
  /** The type of the Html element to render */
  tagName?: 'li' | 'div';
  /** The link's url or location. */
  to?: string | Location<T>;
}

/**
 * ListItem
 *
 * Use this component to compose paginated list items.
 */
export const ListItem = <T extends unknown>({
  children,
  className,
  isLink = false,
  isSelected,
  linkClassName,
  onClick,
  onContextMenu,
  reference,
  tagName: TagName = 'li',
  to,
}: ListItemProps<T>) => {
  if (isLink && to) {
    return (
      <TagName
        ref={reference}
        className={classnames(style.listItem, className)}
        data-testid="listItem"
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <ListItemLink
          activeClassName={isSelected && style.listItemSelectedActive}
          className={classnames(isSelected && style.listItemSelected, linkClassName)}
          data-testid="list-item-link"
          to={to}
        >
          {children}
        </ListItemLink>
      </TagName>
    );
  }
  return (
    <TagName
      ref={reference}
      className={classnames(style.listItem, className)}
      data-testid="listItem"
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <div className={classnames(style.listItemContent, isSelected && style.listItemSelected)}>{children}</div>
    </TagName>
  );
};

interface ListItemLinkProps<T = DefaultQuery> {
  /** Optional active CSS styling. */
  activeClassName?: string | false;
  /** The component's child elements to be rendered. */
  children: ReactNode;
  /** Optional CSS styling. */
  className?: string | false;
  /** The link's url or location. */
  to: string | Location<T>;
}

/**
 * ListItemLink
 */
export const ListItemLink = <T extends unknown>({ activeClassName, className, ...props }: ListItemLinkProps<T>) => {
  return (
    <Link
      activeClassName={classnames(style.listItemLinkActive, activeClassName)}
      className={classnames(style.listItemLink, className)}
      {...props}
    >
      {props.children}
    </Link>
  );
};

interface ListItemHeaderProps {
  /** The component's child elements to be rendered. */
  children?: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** True when ellipsis should be displayed when text overflows its element. */
  isLabelEllipsisEnabled?: boolean;

  /** The header label. */
  label?: ReactNode;

  /** Invoked when the button is clicked. */
  onSubLabelClick?: () => void;

  /** The header's sub-label or button label. */
  subLabel?: string;
}

/**
 * ListItemHeader
 */
export const ListItemHeader = ({
  children,
  className,
  isLabelEllipsisEnabled,
  label,
  subLabel,
  onSubLabelClick,
}: ListItemHeaderProps) => (
  <header className={classnames(style.header, className)} data-testid="list-item-header">
    <div className={classnames(style.headerLabel, isLabelEllipsisEnabled && style.labelEllipsis)}>{label}</div>
    {!onSubLabelClick ? (
      <div className={classnames(style.headerSubLabel, !isLabelEllipsisEnabled && style.labelEllipsis)}>{subLabel}</div>
    ) : (
      <Button
        className={classnames(style.headerButton, !isLabelEllipsisEnabled && style.labelEllipsis, style.headerSubLabel)}
        onClick={(e) => {
          e.preventDefault();
          onSubLabelClick();
        }}
        theme="none"
      >
        {subLabel}
      </Button>
    )}
    {children}
  </header>
);

interface ListItemBodyProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * ListItemBody
 */
export const ListItemBody = ({ children, className }: ListItemBodyProps) => {
  return (
    <div className={classnames(style.listItemBody, className)} data-testid="list-item-body">
      {children}
    </div>
  );
};

interface PrimaryTitleProps extends HTMLAttributes<HTMLDivElement> {
  /** The component's child elements to be rendered. */
  children?: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * PrimaryTitle
 */
export const PrimaryTitle = ({ children, className, ...props }: PrimaryTitleProps) => (
  <div {...props} className={classnames(style.primaryTitle, className)}>
    {children}
  </div>
);

interface PrimaryTitleLightProps {
  /** The component's child elements to be rendered. */
  children?: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * PrimaryTitleLight
 */
export const PrimaryTitleLight = ({ children, className }: PrimaryTitleLightProps) => {
  return <div className={classnames(style.primaryTitleLight, className)}>{children}</div>;
};

interface PrimaryTitleWithStatusProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * PrimaryTitleWithStatus
 *
 * Meant to contain the following inline, and add an ellipsis to the left-title if necessary:
 *  - PrimaryTitle
 *  - PrimaryTitleStatus
 */
export const PrimaryTitleWithStatus = ({ children, className }: PrimaryTitleWithStatusProps) => {
  return <div className={classnames(style.primaryTitleWithStatus, className)}>{children}</div>;
};

interface PrimaryTitleStatusProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** The color theme of the title status. */
  theme?: 'red' | 'green';
}

/**
 * PrimaryTitleStatus
 * TODO: Add color
 *
 */
export const PrimaryTitleStatus = ({ children, className, theme = 'red' }: PrimaryTitleStatusProps) => (
  <div className={classnames(style.primaryTitleStatus, className, theme && style[`theme-${theme}`])}>{children}</div>
);

interface SecondaryTitleProps extends HTMLAttributes<HTMLDivElement> {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * SecondaryTitle
 */
export const SecondaryTitle = ({ children, className, ...props }: SecondaryTitleProps) => (
  <div className={classnames(style.secondaryTitle, className)} {...props}>
    {children}
  </div>
);

interface SecondaryTitleLightProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * SecondaryTitleLight
 */
export const SecondaryTitleLight = ({ children, className }: SecondaryTitleLightProps) => {
  return <div className={classnames(style.secondaryTitleLight, className)}>{children}</div>;
};

interface TertiaryTitleProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;
  /** Optional CSS styling. */
  className?: string;
  /** The id used for component testing. */
  dataTestId?: string;
}

/**
 * TertiaryTitle
 */
export const TertiaryTitle = ({ children, className, dataTestId }: TertiaryTitleProps) => {
  return (
    <div className={classnames(style.tertiaryTitle, className)} data-testid={dataTestId}>
      {children}
    </div>
  );
};

interface TertiaryTitleLightProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** The id used for component testing. */
  dataTestId?: string;
}

/**
 * TertiaryTitleLight
 */
export const TertiaryTitleLight = ({ children, className, dataTestId }: TertiaryTitleLightProps) => {
  return (
    <div className={classnames(style.tertiaryTitleLight, className)} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export interface ListItemStatusProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** The color theme of the title status. */
  theme?: 'red' | 'yellow' | 'gray';
}

/**
 * ListItemStatus
 *
 */
export const ListItemStatus = ({ children, className, theme = 'red' }: ListItemStatusProps) => {
  return (
    <Tag className={classnames(style.listItemStatusTag, className)} theme={theme}>
      {children}
    </Tag>
  );
};

interface ListItemFooterProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * ListFooter
 */
export const ListItemFooter = ({ children, className }: ListItemFooterProps) => {
  return children ? (
    <div className={classnames(style.listItemFooter, className)} data-testid="list-item-footer">
      {children}
    </div>
  ) : null;
};

interface ListItemWithUserImageProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** Optional CSS styling for the image. */
  imageClassName?: string;

  /** The url for the image. */
  imageUrl?: string;

  /** The type of the Html element to render */
  tagName?: 'li' | 'div';
}

/**
 * ListItemWithUserImage
 */
export const ListItemWithUserImage = ({
  children,
  className,
  imageUrl,
  imageClassName,
  tagName: TagName = 'li',
}: ListItemWithUserImageProps) => (
  <TagName className={classnames(style.listItemWithUserImage, className)}>
    <UserImage containerClassName={imageClassName} imageUrl={imageUrl} />
    <div className={style.listItemWithUserImageBody}>{children}</div>
  </TagName>
);
