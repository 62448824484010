import { handleActions } from 'redux-actions';

import {
  isLoading,
  isNotLoading,
  isUpdating,
  homeDashboardSearchLoaded,
  homeDashboardSearchClear,
} from './homeDashboardSearchActions';
import { HomeDashboardSearchResult, HomeDashboardSearchInitialState } from './homeDashboardSearchModels';

export const homeDashboardSearchReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [homeDashboardSearchClear().type]: (state) => state.setLoaded().merge({ results: null }),

    [homeDashboardSearchLoaded().type]: (state, action) => {
      const { parkedArchived, archived, ...results } = action.payload;
      const totalArchivedUnits = parkedArchived?.edges?.length + archived?.edges?.length;

      return state.setLoaded().merge({
        results: new HomeDashboardSearchResult({
          ...results,
          archived: {
            edges: [...parkedArchived?.edges, ...archived?.edges],
            pageInfo: { totalEdges: totalArchivedUnits },
          },
        }),
      });
    },
  },
  new HomeDashboardSearchInitialState()
);
