import ApiRequest from 'store/shared/api/apiRequest';
import {
  CarfaxAnnouncements,
  CarfaxUSAReportResponse,
  MutationorderCarfaxUSAReportArgs,
  QueryvehicleCarfaxAnnouncementsArgs,
} from 'store/shared/api/graph/interfaces/types';

export const orderCarfaxUSAReport = ({ authorizationCode = undefined, vin }: MutationorderCarfaxUSAReportArgs) =>
  ApiRequest<{ orderCarfaxUSAReport: CarfaxUSAReportResponse }>({
    data: {
      query: `
        mutation ($authorizationCode: String, $vin: String!) {
          orderCarfaxUSAReport(authorizationCode: $authorizationCode, vin: $vin) {
            reportUrl
            requiresAuthentication
          }
        }
      `,
      variables: {
        authorizationCode,
        vin,
      },
    },
  });

export const vehicleCarfaxAnnouncements = ({ inventoryItemId }: QueryvehicleCarfaxAnnouncementsArgs) =>
  ApiRequest<{ vehicleCarfaxAnnouncements: CarfaxAnnouncements }>({
    data: {
      query: `
        query ($inventoryItemId: String!) {
          vehicleCarfaxAnnouncements(inventoryItemId: $inventoryItemId) {
          announcements{
            detail
            type
            name
          }
            reportStatus
            reportUrl
          }
        }
      `,
      variables: {
        inventoryItemId,
      },
    },
  });
