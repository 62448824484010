import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import SystemBanner from 'containers/notifications/systemBanners/systemBanner';
import { AppDispatch } from 'store/configureStore';
import { setAccountVerificationFormActive } from 'store/system/systemActions';
import { t } from 'utils/intlUtils';

const AccountVerificationBanner = () => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * onClick - Opens the verify account dialog
   */
  const onClick = useCallback(() => {
    dispatch(setAccountVerificationFormActive(true));
  }, [dispatch]);

  return <SystemBanner buttonText={t('verify_account')} onButtonClick={onClick} text={t('verify_account_message')} />;
};

export default AccountVerificationBanner;
