import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { InventoryItemDetails, InventoryItem, InventoryItemDetailsNotes } from './inventoryItemDetailsModels';
import { formatCalendarDate } from 'utils/dateUtils';
import {
  inventoryItemAddNote,
  inventoryItemArchived,
  inventoryItemDetailsAuditLogLoaded,
  inventoryItemDetailsAuditLogUpdating,
  inventoryItemDetailsCleared,
  inventoryItemDetailsLoaded,
  inventoryItemDetailsUpdating,
  preloadInventoryItem,
  updateConsignerLocations,
  updateInventoryItem,
} from './inventoryItemDetailsActions';

export const inventoryItemDetailsReducer = handleActions(
  {
    [inventoryItemDetailsCleared().type]: () => new InventoryItem(),

    [inventoryItemDetailsUpdating().type]: (state) => state.setUpdating(),

    [inventoryItemDetailsLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        details: new InventoryItemDetails({
          ...action.payload,
        }),
      });
    },

    [updateInventoryItem().type]: (state, action) => {
      if (!state?.details?.id) {
        return state;
      }

      const inventoryItem = state.details.toJS();

      return state.set(
        'details',
        new InventoryItemDetails({
          ...inventoryItem,
          ...action.payload,
        })
      );
    },

    [preloadInventoryItem().type]: (state, action) => {
      if (action.payload) {
        const auctionItem = new InventoryItemDetails(action.payload);
        return state.setLoaded().set('details', auctionItem);
      }
      return state;
    },

    [inventoryItemAddNote().type]: (state, action) => {
      return state.merge({
        details: new InventoryItemDetails({
          ...state.details?.toJS(),
          notes: [
            new InventoryItemDetailsNotes({
              ...action.payload.note,
            }),
            ...(state.details?.notes || []),
          ],
        }),
      });
    },

    [inventoryItemArchived().type]: (state, action) => {
      return state.merge({
        details: new InventoryItemDetails({
          ...state.details?.toJS(),
          archived: action.payload.archived,
        }),
      });
    },

    [updateConsignerLocations().type]: (state, action) => {
      return state.set('consignerLocations', List(action.payload.edges.map((locationEdge) => locationEdge.node)));
    },

    [inventoryItemDetailsAuditLogUpdating().type]: (state) => {
      return state.merge({ logEntries: state.logEntries.setUpdating() });
    },

    [inventoryItemDetailsAuditLogLoaded().type]: (state, action) => {
      // Group log entries by date
      const formattedLogs = (action.payload || []).reduce((logs, log) => {
        const formattedDate = formatCalendarDate(log.created);
        const matchingIndex = logs.findIndex((l) => l && l.title === formattedDate);

        if (matchingIndex > -1) {
          logs[matchingIndex].logs.push(log);
          return logs;
        }
        return [...logs, { title: formattedDate, logs: [log] }];
      }, []);

      return state.merge({
        logEntries: state.logEntries.setLoaded().set('results', List(formattedLogs)),
      });
    },
  },
  new InventoryItem()
);
