import AuctionItem from 'constants/auctionItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { RecordOf } from 'store/shared/models/interfaces';

interface SearchResults {
  /** Cancelled items. */
  cancelled?: AuctionItem[];
  /** Purchased items. */
  purchased?: AuctionItem[];
  /** Sold items. */
  sold?: AuctionItem[];
}

export interface SearchResultsModel {
  results: RecordOf<SearchResults>;
}

export type SearchResultsProps = ServerRecordOf<SearchResultsModel>;
export type SearchResultsPropsJs = ReturnType<ServerRecordOf<SearchResultsModel>['toJS']>;

export const Results = Record<SearchResults>({
  cancelled: [],
  purchased: [],
  sold: [],
});

export const InitialState = ServerRecord<SearchResultsModel>({
  results: new Results(),
});
