import classnames from 'classnames';
import { InputActionMeta, OptionProps, components, ActionMeta, OnChangeValue } from 'react-select';
import { SetOptional } from 'type-fest';
import { debounce, isEqual } from 'lodash-es';
import { v1 as uuid } from 'uuid';

import BaseClass from 'components/ui/shared/base';
import Select, { SelectProps } from 'forms/shared/select';
import { Company } from 'store/shared/api/graph/interfaces/types';
import { FeatureFlag } from 'constants/featureFlags';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { formatFullLocation } from 'utils/stringUtils';
import { getCompanies } from 'store/shared/api/graph/queries/companies';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { t } from 'utils/intlUtils';

import style from './select.scss';

export interface SelectCompanyOption extends SelectOption<string> {
  /** Company address. */
  address?: string;
  /** Company numeric id. */
  numericId?: number;
}

export interface SelectCompanyProps<IsMulti extends boolean = false>
  extends Omit<SelectProps<SelectCompanyOption, IsMulti>, 'onChange' | 'value'> {
  className?: string;
  /** Name of connection query. Defaults to `companyConnection` */
  connectionName?: string;
  /** Add default variable values (IE: type:HOLDING - which queries only holding companies) */
  connectionVariables?: Record<string, any>;
  /** Name of query (e.g.: internalSaleCompanies) - Use if query isn't a traditional connection-query */
  customQueryName?: string;
  /** The id of the default selected company. */
  defaultId?: string;
  /** Array of excluded company ids. */
  excludedCompanyIds?: string[];
  /** Query function to fetch companies. Defaults to companyConnection */
  getCompanies?(args: any): Promise<any>;
  /** True when margin style is applied to bottom of selector */
  hasBottomMargin?: boolean;
  /** `id` given to dom element */
  id?: string;
  /** On select value change. */
  onChange?: (
    options: OnChangeValue<SelectCompanyOption, IsMulti> | null,
    actionMeta?: ActionMeta<SelectCompanyOption>
  ) => void;
  /** On input value change. */
  onInputChange?(args: any): void;
  /** Overwrite the default placeholder of selector */
  placeholder?: string;
  /** Selected company */
  value?: SetOptional<SelectCompanyOption, 'label'> | SetOptional<SelectCompanyOption, 'label'>[];
}

interface SelectCompanyState {
  /** The companies listed as dropdown options. */
  companyOptions: SelectCompanyOption[];
  /** The component's unique key. */
  key: string;
  /** The currently selected option(s). */
  selectedCompanyOptions: SelectCompanyOption[];
}

const CustomOption = <IsMulti extends boolean = false>(props: OptionProps<SelectCompanyOption, IsMulti>) => {
  return (
    <components.Option {...props}>
      <div className={style.option}>
        <div className={style.name}>{props.data.label}</div>
        {hasFeatureFlag(FeatureFlag.NUMERIC_COMPANY_ID) && <div>{props.data.numericId}</div>}
        <div>{props.data.address}</div>
      </div>
    </components.Option>
  );
};

class SelectCompany<IsMulti extends boolean = false> extends BaseClass<
  SelectCompanyProps<IsMulti>,
  SelectCompanyState
> {
  constructor(props: SelectCompanyProps<IsMulti>) {
    super(props);

    // Initialize state
    this.state = {
      companyOptions: [],
      key: 'select-company',
      selectedCompanyOptions: (Array.isArray(props?.value) ? props.value : [props.value]).filter(Boolean),
    };

    // Fetch companies
    this.getCompanies(props?.connectionVariables?.keyword);
  }

  static defaultProps = {
    className: undefined,
    connectionName: 'companyConnection',
    connectionVariables: {},
    customQueryName: undefined,
    getCompanies,
    hasBottomMargin: true,
    onChange: () => {},
    onCompanyChange: () => {},
    onInputChange: () => {},
    placeholder: t('choose_company'),
    value: undefined,
  };

  componentDidUpdate(prevProps: SelectCompanyProps<IsMulti>, prevState: SelectCompanyState) {
    const { companyOptions: companyOptionsPrev } = prevState;
    const { connectionVariables: connectionVariablesPrev } = prevProps;
    const { defaultId, connectionVariables } = this.props;
    const { companyOptions, selectedCompanyOptions } = this.state;

    // After companies load, set the selected company by default id
    if (!selectedCompanyOptions?.length && defaultId && companyOptionsPrev.length === 0 && !!companyOptions?.length) {
      const company = companyOptions?.find((c) => c.value === defaultId) || null;
      this.props.onChange?.(company as OnChangeValue<SelectCompanyOption, IsMulti>, undefined);
      this.setState({ selectedCompanyOptions: [company].filter(Boolean) });
    }

    // Re-query when connection variables change
    if (!!connectionVariables && !isEqual(connectionVariables, connectionVariablesPrev)) {
      this.getCompanies();
      // Update key to force re-render
      this.setState({ selectedCompanyOptions: [], key: `select-company-${uuid()}` });
    }
  }

  normalizedToCompanyOption = (company: Company): SelectCompanyOption => ({
    label: company.name,
    address: company.primaryLocation ? formatFullLocation(company.primaryLocation) : '',
    numericId: company.numericId || undefined,
    value: company.id,
  });

  sortCompanyOptionsById = (a: SelectCompanyOption, b: SelectCompanyOption) => a.value?.localeCompare(b.value);

  onChange: SelectCompanyProps<IsMulti>['onChange'] = (selectedOption, actionMeta) => {
    this.setState({
      selectedCompanyOptions: (Array.isArray(selectedOption) ? selectedOption : [selectedOption])
        .filter(Boolean)
        .sort(this.sortCompanyOptionsById),
    });
    this.props.onChange?.(selectedOption, actionMeta);
  };

  onInputChange = debounce((keyword: string, inputActionMeta: InputActionMeta) => {
    if (this._isMounted && inputActionMeta.action === 'input-change') {
      this.getCompanies(keyword);
    }
  }, 250);

  getCompanies = (keyword = '') => {
    const { connectionVariables, connectionName, customQueryName } = this.props;

    this.props
      .getCompanies?.({ ...connectionVariables, keyword })
      .then((response) => {
        const companies: Company[] =
          (customQueryName
            ? response?.data?.data?.[customQueryName]
            : connectionName &&
              response?.data?.data?.[connectionName]?.edges?.map?.((companyEdge) => companyEdge.node)) || [];

        this.setState((prevState, { excludedCompanyIds }) => {
          const companyOptions = companies.map(this.normalizedToCompanyOption);
          return {
            ...prevState,
            companyOptions: [
              ...companyOptions,
              /**
               * Append selected companies that are not part of the given payload.
               * This would allow pre-selected companies to be displayed in the text box
               */
              ...prevState.selectedCompanyOptions.filter(
                (selectedOption) =>
                  selectedOption?.value && !companyOptions.some((option) => option.value === selectedOption.value)
              ),
            ]
              .filter(({ value }) => !excludedCompanyIds?.includes(value))
              .sort(this.sortCompanyOptionsById),
          };
        });
      })
      .catch(() => {
        // Query error
      });
  };

  render() {
    const { className, hasBottomMargin, isMulti, placeholder } = this.props;
    const { companyOptions = [], key, selectedCompanyOptions } = this.state;

    const selectedOption = isMulti
      ? companyOptions.filter(({ value }) => !!selectedCompanyOptions?.find((company) => company?.value === value))
      : companyOptions.find(({ value }) => !!selectedCompanyOptions?.find((company) => company?.value === value));

    return (
      <Select
        key={key}
        id="select-company"
        theme="green"
        {...this.props}
        className={classnames(style.selectInput, { [style.hasBottomMargin]: hasBottomMargin }, className)}
        components={{ Option: CustomOption, ...this.props.components }}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        options={companyOptions}
        placeholder={placeholder}
        value={selectedOption}
      />
    );
  }
}

export default SelectCompany;
