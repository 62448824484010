import { UAParser } from 'ua-parser-js';
import { v1 as uuid } from 'uuid';

import { STORAGE_TYPES, storageManager } from 'utils/storageUtils';

const ua = UAParser(window.navigator?.userAgent);

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator?.userAgent);

export const isIE11 = !!/trident\//i.test(window.navigator?.userAgent);
export const isIE = !!/msie\s|trident\//i.test(window.navigator?.userAgent);

/* Branch name derived from .env.local file */
export const BRANCH = process.env.REACT_APP_BUILD_BRANCH;

/* Commit SHA derived from .env.local file */
export const COMMIT = process.env.REACT_APP_BUILD_COMMIT;

/* Unique browser tab id, set in session storage */
export const tabIDStorage = storageManager.createOrFetchStorage('tabID', STORAGE_TYPES.session);

/**
 *  Fetches unique tab id (creates if not yet set)
 */
const getTabId = () => {
  const idInStorage = tabIDStorage.get();
  if (!idInStorage) {
    const id = uuid();
    tabIDStorage.set(id);
    return id;
  }
  return idInStorage;
};

/* Generates unique tab id on application boot */
export const tabId = getTabId();

/**
 * Confirm we're running production mode, and that we're
 * in a valid environment (eblock.com, eblock.ca)
 *
 * @returns {boolean}
 */
export const isProductionEnvironment = () => {
  const domain = `.${window.location.hostname.split('.').slice(-2)}`;
  const isProdMode = process.env.NODE_ENV === 'production';
  // TODO: Add support for PRIVATE_LABEL urls
  return isProdMode && domain.includes('eblock');
};

export const stripNonAscii = (str) => str.replace(/[^\x20-\x7E]/g, '');

export const deviceInfoHeaders = {
  'Device-Tab-Id': tabId,
  'Device-Type': 'web',
  'Device-Name': stripNonAscii(
    `${ua.browser.name} (${ua.browser.major}), ${ua.engine.name} (${ua.engine.version}), ${ua.os.name} (${ua.os.version}), ${navigator.platform}, ${window.screen.width}x${window.screen.height}, colorDepth: ${window.screen.colorDepth}, pixelDepth: ${window.screen.pixelDepth}, devicePixelRatio: ${window.devicePixelRatio}`
  ),
  'App-Version': stripNonAscii(`${BRANCH}_${COMMIT}`),
};

const getClientId = () => {
  if (!localStorage.clientId) {
    localStorage.clientId = uuid();
  }
  return localStorage.clientId;
};

export const clientId = getClientId();

export const deviceInfoAbridged = `EBlock (${BRANCH}_${COMMIT}) - web ${ua.browser.name} ${ua.browser.major} - ${ua.os.name} ${ua.os.version} - ${clientId}`;

/**
 * Returns current browser name
 */
export const browserName = () => ua.browser.name;
