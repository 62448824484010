import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionItemConnection, QueryauctionItemConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { toBooleanValue } from 'store/inventoryItemsList/inventoryItemsListApi';

export const auctionItemConnection = (
  {
    after = null,
    archived = false,
    auctionIds = null,
    auctionItemEndTime = null,
    auctionItemIds = null,
    auctionLocationName = null,
    auctionStartTime = null,
    auctionTimeSlotLane = null,
    auctionTimeSlotStatus = null,
    auctionTitle = null,
    before = null,
    bodyType = null,
    consigner = null,
    consignerId = null,
    dateRanGTE = null,
    dateRanLTE = null,
    distance = null,
    driveTrain = null,
    exteriorColor = null,
    filterBy = null,
    first = null,
    formats = null,
    fuelType = null,
    keyword = null,
    last = null,
    latitude = null,
    longitude = null,
    makes = null,
    mileageGTE = null,
    mileageLTE = null,
    models = null,
    option = null,
    region = null,
    reservePriceGTE = null,
    reservePriceLTE = null,
    salesRepId = null,
    sort = null,
    status = null,
    subModels = null,
    transactionType = null,
    transmission = null,
    vehicleScoreGTE = null,
    vehicleScoreLTE = null,
    yearGTE = null,
    yearLTE = null,
  }: QueryauctionItemConnectionArgs,
  payloadFields = '',
  fragments: string[] = []
) =>
  ApiRequest<{ auctionItemConnection: Partial<AuctionItemConnection> }>({
    data: {
      operationName: 'auctionItemConnection',
      query: `
      query(
        $after: String
        $archived: Boolean
        $auctionIds: [String]
        $auctionItemEndTime: [String]
        $auctionItemIds: [String]
        $auctionLocationName: [String]
        $auctionStartTime: [String]
        $auctionTimeSlotLane: [String]
        $auctionTimeSlotStatus: [AuctionTimeSlotStatus]
        $auctionTitle: [String]
        $before: String
        $bodyType: [String]
        $consigner: [String]
        $consignerId: String
        $dateRanGTE: DateTime
        $dateRanLTE: DateTime
        $distance: Int
        $driveTrain: [String]
        $exteriorColor: [String]
        $filterBy: String
        $first: Int
        $formats: [AuctionItemFormat]
        $fuelType: [String]
        $keyword: String
        $last: Int
        $latitude: Float
        $longitude: Float
        $makes: [String]
        $mileageGTE: Int
        $mileageLTE: Int
        $models: [String]
        $option: [String]
        $region: [String]
        $reservePriceGTE: BigDecimal
        $reservePriceLTE: BigDecimal
        $salesRepId: String
        $sort: [Sort]
        $status: [AuctionItemStatus]
        $subModels: [String]
        $transactionType: TransactionType
        $transmission: [String]
        $vehicleScoreGTE: BigDecimal
        $vehicleScoreLTE: BigDecimal
        $yearGTE: Int
        $yearLTE: Int
      ) {
        auctionItemConnection(
          after: $after
          archived: $archived
          auctionIds: $auctionIds
          auctionItemEndTime: $auctionItemEndTime
          auctionItemIds: $auctionItemIds
          auctionLocationName: $auctionLocationName
          auctionStartTime: $auctionStartTime
          auctionTimeSlotLane: $auctionTimeSlotLane
          auctionTimeSlotStatus: $auctionTimeSlotStatus
          auctionTitle: $auctionTitle
          before: $before
          bodyType: $bodyType
          consigner: $consigner
          consignerId: $consignerId
          dateRanGTE: $dateRanGTE
          dateRanLTE: $dateRanLTE
          distance: $distance
          driveTrain: $driveTrain
          exteriorColor: $exteriorColor
          filterBy: $filterBy
          first: $first
          formats: $formats
          fuelType: $fuelType
          keyword: $keyword
          last: $last
          latitude: $latitude
          longitude: $longitude
          makes: $makes
          mileageGTE: $mileageGTE
          mileageLTE: $mileageLTE
          models: $models
          option: $option
          region: $region
          reservePriceGTE: $reservePriceGTE
          reservePriceLTE: $reservePriceLTE
          salesRepId: $salesRepId
          sort: $sort
          status: $status
          subModels: $subModels
          transactionType: $transactionType
          transmission: $transmission
          vehicleScoreGTE: $vehicleScoreGTE
          vehicleScoreLTE: $vehicleScoreLTE
          yearGTE: $yearGTE
          yearLTE: $yearLTE
        ) {
          ${payloadFields}
        }
      }
      ${fragments?.length ? fragments?.toString()?.split(',')?.join(' ') : ''}
    `,
      variables: {
        after,
        archived: toBooleanValue(archived),
        auctionIds,
        auctionItemEndTime,
        auctionItemIds,
        auctionLocationName,
        auctionStartTime,
        auctionTimeSlotLane,
        auctionTimeSlotStatus,
        auctionTitle,
        before,
        bodyType,
        consigner,
        consignerId,
        dateRanGTE,
        dateRanLTE,
        distance,
        driveTrain,
        exteriorColor,
        filterBy,
        first,
        formats,
        fuelType,
        keyword,
        last,
        latitude,
        longitude,
        makes,
        mileageGTE,
        mileageLTE,
        models,
        option,
        region,
        reservePriceGTE,
        reservePriceLTE,
        salesRepId,
        sort,
        status,
        subModels,
        transactionType,
        transmission,
        vehicleScoreGTE,
        vehicleScoreLTE,
        yearGTE,
        yearLTE,
      },
    },
  });
