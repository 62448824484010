import { handleActions } from 'redux-actions';

import { InitialState } from './auctionClerkModels';
import {
  auctionItemLoaded,
  auctionItemPhysicalEnded,
  auctionItemPhysicalStarted,
  auctionItemUpdated,
  auctionTimeSlotLaneUpdated,
  clearAuctionClerk,
  isLoading,
  isNotUpdating,
  isUpdating,
  liveLaneLoaded,
  runItemsLoaded,
} from './auctionClerkActions';

export const auctionClerkReducer = handleActions(
  {
    [clearAuctionClerk().type]: () => new InitialState(),

    [isLoading().type]: (state) => state.setLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [auctionItemLoaded().type]: (state, action) => {
      return state.set('auctionItem', action.payload);
    },

    [auctionItemPhysicalEnded().type]: (state, action) => {
      const stateNext = state.toJS();

      // Add ended item to ran list
      const ranItem = { ...stateNext.auctionItem, ...action.payload };
      const ranItems = stateNext.ranItems ? [ranItem, ...stateNext.ranItems] : [ranItem];

      return state.set('ranItems', ranItems);
    },

    [auctionItemPhysicalStarted().type]: (state, action) => {
      const stateNext = state.toJS();
      const auctionItemId = action?.payload?.auctionItemId;

      // Filter out items that already started
      const ranItems = stateNext?.ranItems?.filter(({ id }) => id !== auctionItemId);
      const upcomingItems = stateNext?.upcomingItems?.filter(({ id }) => id !== auctionItemId);

      return state.set('ranItems', ranItems).set('upcomingItems', upcomingItems);
    },

    [auctionItemUpdated().type]: (state, action) => {
      const auctionClerkState = state.toJS();
      return state.set('auctionItem', { ...auctionClerkState.auctionItem, ...action.payload });
    },

    [auctionTimeSlotLaneUpdated().type]: (state, action) => {
      const auctionClerkState = state.toJS();
      return state.set('auctionTimeSlotLane', { ...auctionClerkState.auctionTimeSlotLane, ...action.payload });
    },

    [liveLaneLoaded().type]: (state, action) => {
      return state
        .setLoaded()
        .set('auctionTimeSlotLane', action?.payload?.auctionTimeSlotLane)
        .set('ranItems', action?.payload?.ranItems ?? state.ranItems)
        .set('upcomingItems', action?.payload?.upcomingItems ?? state.upcomingItems);
    },

    [runItemsLoaded().type]: (state, action) => {
      return state
        .setLoaded()
        .set('ranItems', action?.payload?.ranItems ?? state.ranItems)
        .set('upcomingItems', action?.payload?.upcomingItems ?? state.upcomingItems);
    },
  },
  new InitialState()
);
