import classnames from 'classnames';
import { MouseEvent, ReactNode, useCallback } from 'react';

import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';

import style from './radioPanel.scss';

interface Props {
  /** The elements in the panel to be rendered */
  children: ReactNode;
  /** Id used for testing */
  dataTestId?: string;
  /** The text to be displayed when the panel is selected */
  displayedTextOnSelected?: string;
  /** True when the panel is selected */
  isSelected: boolean;
  /** The value of the panel */
  value: string | number;
  /** The function to be called when the panel is selected */
  onSelect: (value: string | number) => void;
  /** The class name to override the radio button styling */
  radioButtonClassName?: string;
}

const RadioPanel = ({
  children,
  dataTestId,
  displayedTextOnSelected,
  value,
  isSelected,
  onSelect,
  radioButtonClassName,
}: Props) => {
  /**
   * Handles the click event of the radio panel
   */
  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect(value);
    },
    [onSelect, value]
  );

  return (
    <>
      <button
        className={classnames(style.radioPanel, { [style.selected]: isSelected })}
        data-testid={dataTestId}
        onClick={onClick}
      >
        {children}
        <div
          className={classnames(style.radioButton, { [style.selected]: isSelected }, radioButtonClassName)}
          data-testid="radio-button"
        />
      </button>
      {isSelected && displayedTextOnSelected && (
        <NotificationBanner className={style.banner} primaryGlyphType="info" theme="green-solid">
          {displayedTextOnSelected}
        </NotificationBanner>
      )}
    </>
  );
};

export default RadioPanel;
