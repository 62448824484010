import { useMemo } from 'react';

import CheckoutItemPreview from 'components/sections/checkout/forms/checkoutItemPreview';
import { SecondaryTitleLight, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { AuctionItem } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './checkoutItemOrderOverview.scss';

const CheckoutItemOrderOverview = ({ auctionItem, onEdit }: { auctionItem: AuctionItem; onEdit: () => void }) => {
  const addOns = useMemo(
    () => auctionItem?.order?.lineItems?.filter(({ optional, selected }) => !!optional && !!selected),
    [auctionItem]
  );

  return (
    <div className={style.container} data-testid="single-order-section">
      <CheckoutItemPreview auctionItem={auctionItem} onEdit={onEdit} />
      {!!addOns?.length && (
        <section className={style.section}>
          <TertiaryTitleLight className={style.title}>{t('add_ons')}</TertiaryTitleLight>
          {addOns?.map((auctionItemOrderLineItem) => (
            <div key={auctionItemOrderLineItem?.id} className={style.addOnItem} data-testid="add-on-item">
              <div className={style.addOnItemRow}>
                <div>
                  {auctionItemOrderLineItem?.auctionService?.serviceMetadata?.serviceName ??
                    auctionItemOrderLineItem?.inventoryService?.serviceMetadata?.serviceName}
                </div>
                <div>{auctionItemOrderLineItem?.amount?.formattedAmount || t('fee_amount_to_be_provided')}</div>
              </div>
              {(auctionItemOrderLineItem?.auctionService?.serviceMetadata?.transportService ??
                auctionItemOrderLineItem?.inventoryService?.serviceMetadata?.transportService) &&
                auctionItem?.order?.buyerPickupLocation?.name && (
                  <SecondaryTitleLight className={style.addOnItemPickupLocation}>
                    {t('pickup_location_x', [auctionItem?.order?.buyerPickupLocation?.name])}
                  </SecondaryTitleLight>
                )}
            </div>
          ))}
        </section>
      )}
      {auctionItem?.order?.comment && (
        <section>
          <TertiaryTitleLight>{t('comments')}</TertiaryTitleLight>
          <p className={style.comments}>{auctionItem?.order?.comment}</p>
        </section>
      )}
    </div>
  );
};

export default CheckoutItemOrderOverview;
