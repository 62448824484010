import BaseClass from 'components/ui/shared/base';
import OperationButton from '../operationButton';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { t, tPlural } from 'utils/intlUtils';

import style from './missingItems.scss';

interface Props {
  /** The number of missing items. */
  count: number;
}

interface State {
  /** True when the dialog is open. */
  isOpen: boolean;
}

// TODO: Move to Operations.js
class MissingItems extends BaseClass<Props, State> {
  state = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;
    const { count } = this.props;
    const missingItemsLabel = tPlural('x_items_missing', count, [count]);

    return [
      <OperationButton
        key="button"
        className={style.buttonLabel}
        dataTestId="missingItems-submitToAuctionButton"
        onClick={() => this.setState({ isOpen: true })}
        theme="gray-outline"
      >
        {t('submit_to_auction')}
        <br />
        <small>{missingItemsLabel}</small>
      </OperationButton>,
      <ConfirmDialog
        key="dialog"
        isOpen={isOpen}
        onConfirm={() => this.setState({ isOpen: false })}
        title={missingItemsLabel}
        titleClassName={style.confirmTitleClassName}
      >
        {t('missing_items_message')}
      </ConfirmDialog>,
    ];
  }
}

export default MissingItems;
