import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { AuctionItemStatus, MutationauctionItemSetSellerIntentArgs } from 'store/shared/api/graph/interfaces/types';
import { getErrors } from 'utils/apiUtils';
import { processSetSellerIntent } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function to update the seller intent. */
  auctionItemSetSellerIntent: (options: MutationauctionItemSetSellerIntentArgs) =>
    processSetSellerIntent(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the current auction item. */
  auctionItemId: string;
  /** The status of the seller intent. */
  sellerIntentStatus: AuctionItemStatus | null;
}

const SellerIntent = ({ auctionItemId, sellerIntentStatus, auctionItemSetSellerIntent }: Props) => {
  const { setConfig } = useGlobalDialog();
  const [currentSellerIntentStatus, setCurrentSellerIntentStatus] = useState<AuctionItemStatus | null>(null);

  /**
   * Update current seller intent status, when auction item changes
   */
  useEffect(() => {
    setCurrentSellerIntentStatus(sellerIntentStatus);
  }, [auctionItemId, sellerIntentStatus]);

  /**
   * Invokes the auctionItemSetSellerIntent update.
   */
  const setSellerIntent = useCallback(
    (status: AuctionItemStatus) => {
      // If the current status matches the selected status (button toggled off), then clear the seller intent.
      const intentStatus = status === currentSellerIntentStatus ? null : status;

      auctionItemSetSellerIntent({ auctionItemId, status: intentStatus })
        ?.then(() => setCurrentSellerIntentStatus(intentStatus))
        ?.catch((error) => {
          // Show alert dialog with errors
          setConfig({ errorsOverride: getErrors(error) });
        });
    },
    [auctionItemId, auctionItemSetSellerIntent, currentSellerIntentStatus, setConfig]
  );

  return (
    <>
      <OperationButton
        dataTestId="seller-intent-sell-button"
        onClick={() => setSellerIntent(AuctionItemStatus.SOLD)}
        theme={currentSellerIntentStatus === AuctionItemStatus.SOLD ? 'green' : 'gray-outline'}
      >
        {t('seller_intent_sell')}
      </OperationButton>
      <OperationButton
        dataTestId="seller-intent-if-bid-button"
        onClick={() => setSellerIntent(AuctionItemStatus.IN_IF_BID)}
        theme={currentSellerIntentStatus === AuctionItemStatus.IN_IF_BID ? 'green' : 'gray-outline'}
      >
        {t('seller_intent_if_bid')}
      </OperationButton>
      <OperationButton
        dataTestId="seller-intent-no-sale-button"
        onClick={() => setSellerIntent(AuctionItemStatus.NO_SALE)}
        theme={currentSellerIntentStatus === AuctionItemStatus.NO_SALE ? 'green' : 'gray-outline'}
      >
        {t('seller_intent_no_sale')}
      </OperationButton>
    </>
  );
};

export default connector(SellerIntent);
