import { ReactNode } from 'react';

import style from './statusColumn.scss';

interface Props {
  children?: ReactNode | ReactNode[];
}

const StatusColumn = ({ children }: Props) => <div className={style.column}>{children}</div>;

export default StatusColumn;
