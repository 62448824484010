import classnames from 'classnames';
import { ReactNode, HTMLAttributes, memo } from 'react';
import { RequireAtLeastOne } from 'type-fest';

import style from './chip.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Chip color */
  color?:
    | 'blue'
    | 'default'
    | 'gray-darker'
    | 'gray-lighter'
    | 'gray-lightest'
    | 'green'
    | 'green-dark'
    | 'red'
    | 'red-dark'
    | 'yellow-dark';
  /**
   * To override and allow custom React Components to be rendered, use `label` if
   * displaying just a string
   */
  component: ReactNode;
  /** The content of the component */
  label: string;
}

const Chip = ({
  className,
  color = 'default',
  component,
  label,
  ...props
}: RequireAtLeastOne<Props, 'component' | 'label'>) => (
  <div {...props} className={classnames(style.container, style[color], className)}>
    {component || label}
  </div>
);

export default memo(Chip);
