import { MouseEvent } from 'react';

import ChevronGlyph from 'glyphs/chevron.svg';

import Button from 'components/ui/shared/button';
import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './badge.scss';

export type BadgeTheme = 'default' | 'green' | 'link' | 'none' | 'orange' | 'red' | 'red-solid' | 'square';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The label or glyph to display on the badge. */
  content: string | Glyph;
  /** An id used for testing.*/
  dataTestId?: string;
  /** Set the html 'disabled' attribute */
  disabled?: boolean;
  /** Function invoked on badge click. */
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** The CSS theme for the badge theme. */
  theme?: BadgeTheme;
  /** Set the html `title` attribute. */
  title?: string;
}

const Badge = ({ content, className, dataTestId, disabled, onClick, title, theme = 'default' }: Props) => {
  const classes = [
    style.badge,
    className,
    typeof content !== 'string' && style.glyph,
    style[`theme-${theme}`],
    !onClick && style.static,
  ].filter(Boolean);
  const contentItem = typeof content === 'string' ? <span>{content}</span> : <Sprite glyph={content} />;
  const showArrow = theme === 'link';

  return (
    <Button
      className={classes}
      dataTestId={dataTestId}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      theme={theme === 'none' ? 'none' : undefined}
      title={title}
    >
      {contentItem}
      {showArrow && <Sprite className={style.chevron} glyph={ChevronGlyph} />}
    </Button>
  );
};

export default Badge;
