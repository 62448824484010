import { useMemo } from 'react';

import { ChatErrorState, ErrorStatus, getDefaultFatalErrorState } from 'store/chat/chatModels';
import { ConnectionState } from 'io/twilio/simulcastChatManagerTypes';

interface Props {
  /** The current connection state to Twilio */
  connectionState: ConnectionState;
  /** The current error state of the chat window */
  errorState: ChatErrorState;
}

/**
 * Aggregate connectionState and errorState together into one aggregated errorState
 */
export const useAggregatedErrorState = ({ connectionState, errorState }: Props) => {
  /**
   * Combine our understanding of the errorState and the connectionState so that the chat
   * window can display an error if necessary.
   */
  return useMemo(() => {
    if (!errorState || errorState.status === ErrorStatus.NONE) {
      if (connectionState === ConnectionState.ERROR || connectionState === ConnectionState.UNAUTHORIZED) {
        return getDefaultFatalErrorState();
      }
    }

    return errorState;
  }, [connectionState, errorState]);
};
