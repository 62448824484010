import { handleActions } from 'redux-actions';
import { unionBy } from 'lodash-es';

import { InitialState } from './laneManagerModels';
import {
  clearLaneManager,
  isLoading,
  isUpdating,
  laneDestinationLoaded,
  laneSourceLoaded,
  timeSlotLoaded,
} from './laneManagerActions';
import { getFilteredUrlParams, getUrlParams } from 'utils/urlUtils';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

// A list of ignorable search param keys
const IGNORABLE_SEARCH_PARAM_NAMES = [
  'id',
  'after',
  'auctionTimeSlotId',
  'auctionTimeSlotLaneId',
  'before',
  'destinationAfter',
  'destinationAuctionTimeSlotLaneId',
  'destinationBefore',
  'isReserved',
];

/**
 * Formats and unionizes the AuctionItemConnection response with a list of reserved/intended slots.
 */
const formatResponse = (payload) => {
  // Determine if we're on the first or last page; we'll use this later when filtering the unionized results.
  const pageInfo = payload?.auctionItemConnection?.pageInfo;
  const startCursor = Number(pageInfo?.startCursor ?? 1);
  const isFirstPage = startCursor === 1 && pageInfo?.hasPreviousPage === false;
  const isLastPage = pageInfo?.hasNextPage === false;

  // Define reserved/intended slots and the auction items payload, merge and sort by their `runNumber`.
  const reservedLaneSlots = payload?.reservedLaneSlots ?? [];
  const results = parseQueryConnectionResponse(payload?.auctionItemConnection) ?? [];
  const unionResults = unionBy(reservedLaneSlots, results, 'runNumber')?.sort(
    (a, b) => Number(a?.runNumber) - Number(b?.runNumber)
  );

  // Determine if any filters are active or viewing by "All", if so, short-circuit and return without reserved slots.
  const filteredUrlParams = getFilteredUrlParams(IGNORABLE_SEARCH_PARAM_NAMES);
  const isViewingAllAvailableTimeSlotLanes = !getUrlParams()?.auctionTimeSlotLaneId;
  const hasActiveSearch = !!Object.keys(filteredUrlParams).length;
  if (isViewingAllAvailableTimeSlotLanes || hasActiveSearch) {
    return results;
  }

  // Determine the last result for both the auction items payload,
  // and the union list; we'll use these for sorting.
  const lastResult = Number(results[results.length - 1]?.runNumber);
  const lastUnionResult = Number(unionResults[unionResults.length - 1]?.runNumber);

  // Filter out any reserved/intended slots that shouldn't appear within the loaded paginated boundary.
  const filteredResults = unionResults?.filter((result) => {
    const firstResult = Number(results[0]?.runNumber);
    const runNumber = Number(result.runNumber);

    return (
      runNumber >= (isFirstPage ? startCursor : firstResult) && runNumber <= (isLastPage ? lastUnionResult : lastResult)
    );
  });

  return filteredResults;
};

export const laneManagerReducer = handleActions(
  {
    [clearLaneManager().type]: () => new InitialState(),

    [isLoading().type]: (state, action) => state.setLoading(),

    [isUpdating().type]: (state, action) => state.setUpdating(),

    [timeSlotLoaded().type]: (state, action) => state.set('auctionTimeSlot', action.payload),

    [laneSourceLoaded().type]: (state, action) => {
      const laneSourceResults = action?.payload?.auctionItemConnection;
      const formattedResults = formatResponse(action?.payload);

      return state
        .setLoaded()
        .set('laneSource', formattedResults)
        .set('laneSourceFacetGroups', laneSourceResults?.facetGroups)
        .set('laneSourcePageInfo', laneSourceResults?.pageInfo);
    },

    [laneDestinationLoaded().type]: (state, action) => {
      const laneDestinationResults = action?.payload?.auctionItemConnection;
      const formattedResults = formatResponse(action?.payload);

      return state
        .setLoaded()
        .set('laneDestination', formattedResults)
        .set('laneDestinationPageInfo', laneDestinationResults?.pageInfo);
    },
  },
  new InitialState()
);
