import { EnumTranslationMap } from 'utils/formUtils';

/**
 * Enum for modal type values.
 */
export enum Modal {
  ERROR_DIALOG = 'ERROR_DIALOG',
  ADD_MODIFY = 'ADD_MODIFY',
}

/**
 * Enum for page type values.
 */
export enum Page {
  SELECT_TRIM = 'SELECT_TRIM',
  VEHICLE_FORM = 'VEHICLE_FORM',
  VIN_OVERLAY = 'VIN_OVERLAY',
  VIN_OVERLAY_DUPLICATE = 'VIN_OVERLAY_DUPLICATE',
}

/**
 * Enum for overlay type values.
 */
export enum Overlay {
  CARFAX_CONFIRM_DIALOG = 'CARFAX_CONFIRM_DIALOG',
  SAVING = 'SAVING',
  LOADING = 'LOADING',
  CONFIRM_DIALOG = 'CONFIRM_DIALOG',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export enum TireGeneralCondition {
  POOR = 'POOR',
  AVERAGE = 'AVERAGE',
  GOOD = 'GOOD',
}

export const tireGeneralConditionTranslationMap: EnumTranslationMap<typeof TireGeneralCondition> = {
  POOR: 'condition_poor',
  AVERAGE: 'condition_average',
  GOOD: 'condition_good',
};
