import { omit } from 'lodash-es';

import { joinStrings } from 'utils/stringUtils';

/**
 * @returns {object}
 */
export const getUrlParams = <TParams extends Record<string, any> = Record<string, any>>(): TParams =>
  window.location.search
    .replace(/^\?/, '')
    .split(/&/)
    .filter((a) => a)
    .map((nvp) => nvp.split(/=/))
    .reduce((p, [n, v]) => {
      if (p[n]) {
        let vals = p[n];
        if (!(vals instanceof Array)) {
          vals = [vals];
        }
        vals.push(decodeURIComponent(v).replace(/\+/g, ' '));
        return { ...p, [n]: vals };
      }
      return { ...p, [n]: decodeURIComponent(v).replace(/\+/g, ' ') };
    }, {} as TParams);

/**
 * Similar to `getUrlParams`, but with the ability to ignore a list of undesired keys.
 */
export const getFilteredUrlParams = <TParams extends Record<string, any> = Record<string, any>>(
  ignorableParams: string[] = []
) => omit<TParams>(getUrlParams<TParams>(), ignorableParams);

/**
 * Clear url params - ignored items remain intact.
 *
 * @example clearUrlParams({ a:'a', b:'b' }, ['b']); // { b:'b' }
 * @param {object} params
 * @param {array} ignorableParams
 * @returns {object}
 */
export const clearUrlParams = (params: Record<string, any>, ignorableParams: string[] = []) =>
  Object.keys(params)
    .filter((key) => ignorableParams.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: params[key] }), {});

export const paramsToQueryString = (params) => {
  let qParams = [];
  Object.keys(params).forEach((k) => {
    let vals = params[k];
    if (!(vals instanceof Array)) {
      vals = [vals];
    }
    qParams = qParams.concat(vals.map((val) => `${k}=${encodeURIComponent(val)}`));
  });
  return qParams.join('&');
};

/**
 * Similar to `getUrlParams`, but returns a formatted value with `paramsToQueryString()`.
 */
export const getUrlParamsToQueryString = (ignorableParams: string[] = []) =>
  paramsToQueryString(getFilteredUrlParams(ignorableParams));

/**
 * Gets the current url and filters out all query params, expect any included in `ignorableParams`
 *
 * @param {string[]} ignorableParams
 */
export const getClearedUrl = (ignorableParams: string[] = []) => {
  const currentUrl = window.location.href.split('?')[0];
  const params = clearUrlParams(getUrlParams(), ignorableParams);
  return joinStrings([currentUrl, ignorableParams?.length > 0 && paramsToQueryString(params)], '?');
};
