import classnames from 'classnames';

import Button, { ButtonProps } from 'components/ui/shared/button';
import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './iconButton.scss';

interface Props extends Omit<ButtonProps, 'theme' | 'children'> {
  /** CSS styling to overwrite the default button style. */
  className?: string;
  /** The glyph to display. */
  glyph: Glyph;
  /** CSS styling to overwrite the default sprite style. */
  spriteClassName?: string;
}

const IconButton = ({ className, spriteClassName, glyph, ...props }: Props) => {
  return (
    <Button className={classnames(style.iconButton, className)} {...props} theme="none">
      <Sprite className={classnames(style.sprite, spriteClassName)} glyph={glyph} />
    </Button>
  );
};

export default IconButton;
