import { PartialDeep } from 'type-fest';

import InventoryItem from 'constants/inventoryItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { ErrorMessages } from 'constants/errors';
import {
  InventoryItemOption,
  Mileage as MileageType,
  PhotoType,
  SellHoldStatus,
  VehicleBuilder,
  VehicleInput,
  VehicleMetaData,
} from 'store/shared/api/graph/interfaces/types';
import { Modal, Overlay, Page } from 'constants/enums/addModifyInventoryItem';
import { RecordOf } from 'store/shared/models/interfaces';

export interface InventoryItemVehicleModel
  extends Omit<
      InventoryItem,
      'auctionLocation' | 'conditionReport' | 'mileage' | 'options' | 'startingLocation' | 'tireCondition'
    >,
    Pick<VehicleInput, 'auctionLocationId' | 'locationId' | 'pickupLocationId' | 'tireCondition'> {
  conditionReport?: InventoryItem['conditionReport'] & {
    declarations: string[];
  };
  /** Consigner id. */
  consignerId?: string;
  /** Remaining uploads count. */
  ignoreHasStateChanged: boolean;
  /** Mileage */
  mileage: RecordOf<MileageType>;
  /** Vehicle options. */
  options?: (string | InventoryItemOption)[];
  /** Number of photos remaining */
  remainingUploads: number;
  /** Current uploading photo type. */
  uploadPhotoType: PhotoType;
  /** Whether validation is complete or not. */
  validateComplete: boolean;
}

export type AddModifyVehicleProps = PartialDeep<
  ReturnType<RecordOf<InventoryItemVehicleModel>['toJS']>,
  { recurseIntoArrays: true }
>;

export interface InventoryItemModel {
  /** Country code. */
  countryCode: string;

  /** Vehicle meta data. */
  declarationsMeta: VehicleMetaData;

  /** Duplicated inventory item. */
  duplicates: {
    data: InventoryItem & { id: string; created: string };
    section: string;
  }[];

  /** Error messages. */
  errorMessages: ErrorMessages;

  /** Whether has continued with capture or not. */
  hasContinuedWithCapture: boolean;

  /** Whether has photos changed or not. */
  hasPhotosChanged: boolean;

  /** Whether has state changed or not. */
  hasStateChanged: boolean;

  /** Modal type. */
  modal: Modal;

  /** Overlay type. */
  overlay: Overlay | null;

  /** Page type. */
  page: Page;

  /** Inventory item results. */
  results: RecordOf<InventoryItemResultModel>;
}

export type InventoryItemProps = ServerRecordOf<InventoryItemModel>;
export type InventoryItemPropsJs = ReturnType<ServerRecordOf<InventoryItemModel>['toJS']>;

export const Mileage = Record<MileageType>({
  amount: null,
  unit: 'km',
  formattedAmount: null,
});

export const InventoryItemVehicle = Record<InventoryItemVehicleModel>({
  canBeListed: false,
  consignerId: null,
  id: null,
  vin: null,
  stockNumber: null,
  year: null,
  makeId: null,
  minimalCR: null,
  modelId: null,
  subModelId: null,
  trimId: null,
  trim: null,
  compoundHistory: [],
  conditionReport: {
    carfaxCanadaDisclosuresExist: false,
    carfaxCanadaReportUrl: null,
    carfaxCanadaClaimAmount: null,
    overallConditionRating: 0,
    declarations: [],
  },
  extensiveVehicleConditionScore: null,
  tireCondition: null,
  mileage: new Mileage({}),
  lastKnownMileage: null,
  lastKnownMileageDate: null,
  bodyType: null,
  chargingCable: null,
  exteriorColor: null,
  interiorColor: null,
  options: null,
  numberOfDoors: null,
  numberOfPassengers: null,
  driveTrain: null,
  transmission: null,
  displacement: null,
  cylinders: null,
  fuelType: null,
  sellerNotes: null,
  incompleteFieldMessages: null,
  captureType: null,
  status: null,
  photos: null,
  auctionLocationId: null,
  locationId: null,
  pickupLocationId: null,
  remainingUploads: null,
  uploadPhotoType: null,
  pickupLocation: null,
  archived: null,
  availableAuctions: null,
  company: null,
  complete: null,
  completionPercentage: null,
  isUpdatable: null,
  redirectPath: null,
  releaseStatus: null,
  tasks: null,
  timelineActions: null,
  uniqueStockNumber: null,
  comparableValuations: null,
  operable: true,
  printableUrl: null,
  sellHoldStatus: SellHoldStatus.NO_HOLD,
  stickerUrl: null,
  transportDetails: null,
  ignoreHasStateChanged: null,
  validateComplete: false,
});

interface InventoryItemResultModel extends Omit<VehicleBuilder, 'vehicle'> {
  vehicle: RecordOf<InventoryItemVehicleModel>;
}

export const InventoryItemResult = Record<InventoryItemResultModel>({
  vehicle: new InventoryItemVehicle({}),
  bodyType: [],
  chargingCable: [],
  cylinders: [],
  displacement: null,
  driveTrain: [],
  exteriorColor: [],
  fuelType: [],
  interiorColor: [],
  location: [],
  startingLocation: [],
  pickupLocation: [],
  make: [],
  model: [],
  numberOfDoors: [],
  numberOfPassengers: [],
  options: [],
  subModel: [],
  transmission: [],
  trim: [],
  year: [],
  metadata: null,
});

export const InventoryItemInitialState = ServerRecord<InventoryItemModel>({
  results: new InventoryItemResult({}),
  errorMessages: [],
  duplicates: [],
  declarationsMeta: null,
  modal: null,
  page: Page.VIN_OVERLAY,
  overlay: null,
  hasContinuedWithCapture: false,
  hasStateChanged: false,
  hasPhotosChanged: false,
  countryCode: 'CA',
});
