import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router';

import UserImage from 'components/ui/shared/images/userImage';
import { AuctionRepresentative } from 'store/shared/api/graph/interfaces/types';
import { DetailsSection, DetailsSectionProps } from 'layouts/list/listLayout';
import { formatUserName } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './reps.scss';

interface RepresentativeProps extends HTMLAttributes<HTMLElement> {
  /** Additional representative details. */
  additionalDetails?: string[];
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The auction representative. */
  rep: AuctionRepresentative;
}

export const Representative = ({ additionalDetails, className, rep, ...props }: RepresentativeProps) => {
  const title = rep?.representativeType?.name || rep?.user?.title;

  return (
    <section className={classnames(style.rep, className)} {...props}>
      <UserImage
        className={style.userImage}
        containerClassName={style.imgContainer}
        imageUrl={rep.user.photoUrl || undefined}
      />
      <div>
        <div className={style.name}>{formatUserName(rep.user)}</div>
        <div className={style.detail}>
          {title ? (
            <>
              <span>{title}</span> • {rep.user.cellPhone || rep.user.secondaryPhone}
            </>
          ) : (
            rep.user.cellPhone || rep.user.secondaryPhone
          )}
        </div>
        <div className={style.detail}>{rep.user.email}</div>
        {additionalDetails?.map((detail) => (
          <div key={detail} className={style.detail}>
            {detail}
          </div>
        ))}
      </div>
    </section>
  );
};

interface RepresentativesProps {
  /** True when representative is rendered as a link. */
  isLinked?: boolean;
  /** A collection of auction representatives. */
  reps?: AuctionRepresentative[];
}

export const Representatives = ({ isLinked = false, reps = [] }: RepresentativesProps) => (
  <div className={style.sectionInner}>
    {reps.map((rep, index) =>
      isLinked ? (
        <Link key={`rep-${rep.user.id}-${index}`} className={style.linkedRep} to={`/admin/users?id=${rep.user.id}`}>
          <Representative rep={rep} />
        </Link>
      ) : (
        <Representative key={`rep-${rep.user.id}-${index}`} rep={rep} />
      )
    )}
  </div>
);

interface RepsProps extends Omit<DetailsSectionProps, 'children'> {
  /** Function invoked on edit click. */
  onEdit?: () => void;
  /** A collection of auction representatives. */
  reps?: AuctionRepresentative[];
  /** The title of representatives section. */
  title?: string;
}

const Reps = ({ onEdit, reps = [], title, ...props }: RepsProps) => (
  <DetailsSection
    dataTestId="reps-section"
    hasContentPadding={false}
    isEmpty={!reps.length}
    onClickSubButton={onEdit}
    subButtonType="edit"
    title={title || t('representatives')}
    {...props}
  >
    <Representatives reps={reps} />
  </DetailsSection>
);

export default Reps;
