import classnames from 'classnames';
import { useCallback } from 'react';

import AuthService from 'store/shared/services/authService';
import {
  AuctionItemFormat,
  AuctionItemHistory,
  AuctionItemRun,
  AuctionItemStatus,
} from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { formatCalendarTime } from 'utils/dateUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './vehicleHistory.scss';

interface Props extends SlideOutComponentProps {
  /**
   * The vehcicle history of the auction item.
   */
  inventoryDetailsData: AuctionItemHistory;
}

const InventoryItemDetailsVehicleHistory = ({ inventoryDetailsData }: Props) => {
  /**
   * Renders the vehicle history rows for timed offer format.
   */
  const renderTimedOfferItems = useCallback((historyItem: AuctionItemRun | null) => {
    const { auction, buyNowPrice, topOffer, totalOffers } = historyItem || {};
    return (
      <>
        {isAuctionStaff(AuthService.user, auction?.id) && (
          <li className={style.listItem} data-testid="top-offer-company">
            <div>{t('top_offer_company')}</div>
            <div>{topOffer?.company?.name ?? t('none')}</div>
          </li>
        )}
        <li className={style.listItem} data-testid="top-offer-amount">
          <div>{t('top_offer_amount')}</div>
          <div>{topOffer?.amount?.formattedAmount ?? t('none')}</div>
        </li>
        <li className={style.listItem} data-testid="buy-now-price">
          <div>{t('buy_now_price')}</div>
          <div>{buyNowPrice?.formattedAmount}</div>
        </li>
        <li className={style.listItem} data-testid="top-offers">
          <div>{t('total_offers')}</div>
          <div>{totalOffers}</div>
        </li>
      </>
    );
  }, []);

  /**
   * Renders the vehicle history rows.
   */
  const renderItems = useCallback((historyItem: AuctionItemRun | null) => {
    const { auction, format, reserve, topOffer, totalBids } = historyItem || {};
    return (
      <>
        {isAuctionStaff(AuthService.user, auction?.id) && (
          <li className={style.listItem} data-testid="high-bidder">
            <div>{t('high_bidder')}</div>
            <div>{topOffer?.company?.name ?? t('none')}</div>
          </li>
        )}
        <li className={style.listItem} data-testid="high-bid">
          <div>{t('high_bid')}</div>
          <div>{topOffer?.amount?.formattedAmount ?? t('none')}</div>
        </li>
        {format !== AuctionItemFormat.APPRAISAL && (
          <li className={style.listItem} data-testid="reserve-price">
            <div>{t('reserve_price')}</div>
            <div>{reserve?.formattedAmount}</div>
          </li>
        )}
        <li className={style.listItem} data-testid="total-bids">
          <div>{t('total_bids')}</div>
          <div>{totalBids}</div>
        </li>
      </>
    );
  }, []);

  return (
    <div>
      {inventoryDetailsData?.list?.map((historyItem, index) => {
        const { auction, format, status, ranOn } = historyItem || {};

        return (
          <div key={`vehicle-history-${index}`} className={style.summarySection}>
            <ul>
              <li className={style.listItem}>
                <div>
                  <div>{`${t(`auction_item_format_${format?.toLowerCase()}`)} • ${auction?.title}`}</div>
                  <div className={style.ranOn}>{formatCalendarTime(ranOn)}</div>
                </div>
                <div
                  className={classnames(
                    style.status,
                    status === AuctionItemStatus.SOLD && style.green,
                    (status === AuctionItemStatus.SALE_CANCELLED ||
                      status === AuctionItemStatus.NO_SALE ||
                      status === AuctionItemStatus.FAILED) &&
                      style.red
                  )}
                >
                  {t(`auction_item_status_${status?.toLowerCase()}`)}
                </div>
              </li>
              {format === AuctionItemFormat.TIMED_OFFER ? renderTimedOfferItems(historyItem) : renderItems(historyItem)}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default InventoryItemDetailsVehicleHistory;
