import {
  CompanySubType as CompanySubTypeType,
  CompanyTier,
  CompanyType,
} from 'store/shared/api/graph/interfaces/types';
import { EnumTranslationMap } from 'utils/formUtils';

/**
 * Enum for Status values.
 */
export enum CompanyStatus {
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

/**
 * Enum for company subtype.
 */
export const CompanySubType = Object.freeze({
  [CompanyType.AUCTION]: {
    GOVERNMENT: 'GOVERNMENT',
    ONLINE: 'ONLINE',
    PHYSICAL: 'PHYSICAL',
    PRIVATE: 'PRIVATE',
    SALVAGE: 'SALVAGE',
  },

  [CompanyType.CARRIER]: {
    COMPANY: 'COMPANY',
    OWNER_OPERATOR: 'OWNER_OPERATOR',
  },

  [CompanyType.CONSIGNER]: {
    CAPTIVE_FINANCE: 'CAPTIVE_FINANCE',
    DEALER_FRANCHISE: 'DEALER_FRANCHISE',
    DEALER_INDEPENDENT: 'DEALER_INDEPENDENT',
    FLEET_LEASE_FINANCE: 'FLEET_LEASE_FINANCE',
    FLEET_LEASE_OTHER: 'FLEET_LEASE_OTHER',
    FLEET_LEASE_RENTAL: 'FLEET_LEASE_RENTAL',
    OEM: 'OEM',
  },
});

export const companyTypeTranslationMap: EnumTranslationMap<typeof CompanyType> = {
  AUCTION: 'company_type_auction',
  CARRIER: 'company_type_carrier',
  CONSIGNER: 'company_type_consigner',
  HOLDING: 'company_type_holding',
};

export const companyTierTranslationMap: EnumTranslationMap<typeof CompanyTier> = {
  ELITE: 'consigner_tier_elite',
  POWER: 'consigner_tier_power',
  NEW: 'consigner_tier_new',
  NONE: 'none',
};

const companySubTypeAuctionTranslationMap: EnumTranslationMap<typeof CompanySubType.AUCTION> = {
  GOVERNMENT: 'company_sub_type_government',
  ONLINE: 'company_sub_type_online',
  PHYSICAL: 'company_sub_type_physical',
  PRIVATE: 'company_sub_type_private',
  SALVAGE: 'company_sub_type_salvage',
};

const companySubTypeCarrierTranslationMap: EnumTranslationMap<typeof CompanySubType.CARRIER> = {
  COMPANY: 'company_sub_type_company',
  OWNER_OPERATOR: 'company_sub_type_owner_operator',
};

const companySubTypeConsignerTranslationMap: EnumTranslationMap<typeof CompanySubType.CONSIGNER> = {
  CAPTIVE_FINANCE: 'company_sub_type_captive_finance',
  DEALER_FRANCHISE: 'company_sub_type_dealer_franchise',
  DEALER_INDEPENDENT: 'company_sub_type_dealer_independent',
  FLEET_LEASE_FINANCE: 'company_sub_type_fleet_lease_finance',
  FLEET_LEASE_OTHER: 'company_sub_type_fleet_lease_other',
  FLEET_LEASE_RENTAL: 'company_sub_type_fleet_lease_rental',
  OEM: 'company_sub_type_oem',
};

/**
 * Company subtype enum translation map.
 */
export const companyTypeSubTypeTranslationMap = Object.freeze({
  [CompanyType.AUCTION]: companySubTypeAuctionTranslationMap,

  [CompanyType.CARRIER]: companySubTypeCarrierTranslationMap,

  [CompanyType.CONSIGNER]: companySubTypeConsignerTranslationMap,
});

export const companySubTypeTranslationMap: EnumTranslationMap<typeof CompanySubTypeType> = {
  ...companySubTypeAuctionTranslationMap,
  ...companySubTypeCarrierTranslationMap,
  ...companySubTypeConsignerTranslationMap,
  TEST: 'company_sub_type_test',
};

export const companyStatusTranslationMap: EnumTranslationMap<typeof CompanyStatus> = {
  ENABLED: 'enabled',
  PENDING: 'pending',
  SUSPENDED: 'suspended',
};
