import Photo from 'constants/photo';
import { PhotoDTO, PhotoType } from 'store/shared/api/graph/interfaces/types';
import { readFileAsDataURL } from 'utils/fileUtils';

export const convertPhotoDtoToPhoto = (photoDTO: PhotoDTO): Photo => {
  return {
    id: photoDTO.id,
    location: photoDTO.damageLocation,
    shotCode: photoDTO.shotCode ?? undefined,
    status: photoDTO.status || undefined,
    type: photoDTO.type,
    url: photoDTO.downloadUrl || undefined,
  };
};

export const getPhotosByType = (photos: Photo[], type: PhotoType) => {
  const sortShotCodeNumbers = (itemA: Photo, itemB: Photo) => Number(itemA.shotCode) - Number(itemB.shotCode);
  return photos.filter((photo) => photo.type === type).sort(sortShotCodeNumbers);
};

export const mapShotCodes = (arr: Photo[]) => {
  return arr.map((item, index) => ({ ...item, shotCode: index + 1 }));
};

export const isCorrectShotCodeOrder = (arr: Photo[]) => {
  if (arr.length && arr[0].shotCode !== 1) {
    return false;
  }

  return arr
    .slice(1)
    .map((item, index) => {
      return Number(item.shotCode) > Number(arr[index].shotCode) && item.shotCode === Number(arr[index].shotCode) + 1;
    })
    .every((x) => x);
};

export const correctPhotosShotCodeOrder = (photos: Photo[]) => {
  let exteriorPhotos = getPhotosByType(photos, PhotoType.EXTERIOR);
  let undercarriagePhotos = getPhotosByType(photos, PhotoType.UNDERCARRIAGE);
  let interiorPhotos = getPhotosByType(photos, PhotoType.INTERIOR);
  let damagePhotos = getPhotosByType(photos, PhotoType.DAMAGE);

  if (!isCorrectShotCodeOrder(exteriorPhotos)) {
    exteriorPhotos = mapShotCodes(exteriorPhotos);
  }
  if (!isCorrectShotCodeOrder(undercarriagePhotos)) {
    undercarriagePhotos = mapShotCodes(undercarriagePhotos);
  }
  if (!isCorrectShotCodeOrder(interiorPhotos)) {
    interiorPhotos = mapShotCodes(interiorPhotos);
  }
  if (!isCorrectShotCodeOrder(damagePhotos)) {
    damagePhotos = mapShotCodes(damagePhotos);
  }

  return exteriorPhotos.concat(undercarriagePhotos).concat(interiorPhotos).concat(damagePhotos);
};

type ImageHeader = {
  /** The magic number header of the image. */
  header: string;
  /** The type of the image. */
  type: string;
};

// List of known image magic number headers for PNG and JPEG
const imageHeaders: ImageHeader[] = [
  { header: '89504e47', type: 'image/png' },
  { header: 'ffd8ff', type: 'image/jpeg' },
];

/**
 * Function to validate the image format by checking the magic number.
 */
export const isValidImageFormat = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      // Create a Uint8Array from the file to check the header.
      const uint8Array = new Uint8Array(e.target!.result as ArrayBuffer);
      let header = '';
      // Convert each byte to a hexadecimal string and concatenate them.
      uint8Array.forEach((byte: number) => {
        header += byte.toString(16).padStart(2, '0');
      });
      // Check if any known header matches the file's header.
      const foundHeader = imageHeaders.some((imageHeader) => header.startsWith(imageHeader.header));
      resolve(foundHeader);
    };
    reader.onerror = () => resolve(false);
    // Read only the first 4 bytes of the file.
    reader.readAsArrayBuffer(file.slice(0, 4));
  });
};

/**
 * Function to check if the file is a valid image.
 */
export const isValidImage = async (url: string): Promise<boolean> => {
  // Create an object URL for the file.
  return new Promise<boolean>((resolve) => {
    const img = new Image();
    img.onload = () => {
      // Check if the image has non-zero dimensions.
      resolve(img.naturalWidth !== 0);
    };
    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

/**
 * Function to read the image file as a data URL.
 * @return {Promise<string | undefined>} The data URL of the image file, undefined if the file is not an image.
 */
export const readImageAsDataURL = async (file: File): Promise<string | undefined> => {
  const isImageFormat = await isValidImageFormat(file);

  if (!isImageFormat) {
    return undefined;
  }
  const photoDataURL = await readFileAsDataURL(file);
  const isValid = await isValidImage(photoDataURL);
  return isValid ? photoDataURL : undefined;
};
