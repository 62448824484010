import { ComponentProps, memo } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import User from 'constants/user';
import UserImage from 'components/ui/shared/images/userImage';
import { AppState } from 'store/configureStore';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { ListItem, ListItemBody, SecondaryTitle, TertiaryTitle } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { formatUserName } from 'utils/stringUtils';
import { hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './potentialBuyersSlideOut.scss';

interface Props extends Pick<ComponentProps<typeof SlideOut>, 'isOpen' | 'onClose'> {
  /** Potential Buyers */
  buyers: User[];
  /** True when the slide out is loading. */
  isLoading: boolean;
}

const PotentialBuyersSlideOut = ({ buyers, isLoading, ...props }: Props) => {
  /**
   * Check if the current user has permission to view users.
   */
  const hasPermission = useSelector((state: AppState) => hasSystemPermission(state.app.user, ['SYSTEM_VIEW_USERS']));

  return (
    <SlideOut contentInnerClassName={style.contentInner} title={t('potential_buyers')} {...props}>
      <Switch>
        <Case if={isLoading}>
          <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
        </Case>
        <Case if={buyers.length > 0}>
          {buyers.map(({ companyRelationships, firstName, id, lastName, mainPhotoThumb }) => (
            <ListItem key={id} className={style.listItem} isLink={hasPermission} to={`${Route.ADMIN_USERS}?id=${id}`}>
              <ListItemBody className={style.body}>
                <UserImage containerClassName={style.img} imageUrl={mainPhotoThumb || undefined} />
                <div>
                  <SecondaryTitle>{formatUserName({ firstName, lastName })}</SecondaryTitle>
                  {companyRelationships?.list[0]?.company.name && (
                    <TertiaryTitle>{companyRelationships.list[0].company.name}</TertiaryTitle>
                  )}
                </div>
              </ListItemBody>
            </ListItem>
          ))}
        </Case>
        <Case>
          <NoResults title={t('no_items_to_show')} />
        </Case>
      </Switch>
    </SlideOut>
  );
};

export default memo(PotentialBuyersSlideOut);
