import { createAction } from 'redux-actions';

import { getErrors, parseQueryParams } from 'utils/apiUtils';
import { getUsersList, getFacets } from './usersApi';

export const usersListIsLoading = createAction('ADMIN_USERS_LIST_IS_LOADING');
export const usersListLoaded = createAction('ADMIN_USERS_LIST_LOADED');
export const usersListCleared = createAction('ADMIN_USERS_LIST_CLEARED');
export const usersListError = createAction('ADMIN_USERS_LIST_ERROR');
export const usersListUpdateItem = createAction('ADMIN_USERS_LIST_UPDATE_ITEM');
export const facetGroupsLoaded = createAction('ADMIN_USERS_LIST_FACET_GROUPS_LOADED');
export const pageInfoLoaded = createAction('ADMIN_USERS_LIST_PAGE_INFO_LOADED');

export const processGetUsersList = async (options, dispatch) => {
  try {
    dispatch(usersListIsLoading(true));
    const users = (await getUsersList(parseQueryParams(options)))?.data.data;
    dispatch(usersListLoaded(users));
  } catch (err) {
    dispatch(usersListIsLoading(false));
    if (err.response) {
      dispatch(usersListError(getErrors(err)));
    } else {
      dispatch(usersListError(err.message));
    }
  }
};

export const processUpdateFacets = async (options, dispatch) => {
  return getFacets(parseQueryParams(options)).then((response) =>
    dispatch(facetGroupsLoaded(response?.data?.data?.userConnection?.facetGroups))
  );
};
