import { connect, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash-es';

import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import AuctionItem from 'constants/auctionItem';
import AuthService from 'store/shared/services/authService';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import InventoryItem from 'constants/inventoryItem';
import Logger from 'logging/Logger';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch, AppState } from 'store/configureStore';
import { Location } from 'constants/reactRouter';
import {
  clearAuctionSubmission,
  processCreateAuctionItem,
  processGetAuctionSubmissionList,
  processGetUpcomingTimeSlots,
} from 'store/auctionSubmission/auctionSubmissionActions';
import {
  MutationauctionItemCreateArgs,
  QueryauctionArgs,
  QueryauctionConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { myBlockUpdateAuctionItem } from 'store/myBlock/list/myBlockActions';
import { processUpdateInventoryItemsList } from 'store/inventoryItemsList/inventoryItemsListActions';

const stateConnect = (state: AppState) => ({
  /** Auction item submission details. */
  auctionSubmission: state.app.auctionSubmission.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Clears/resets auction submission store */
  clear: () => dispatch(clearAuctionSubmission()),
  /** Function invoked to create auction item. */
  createAuctionItem: (options: MutationauctionItemCreateArgs) => processCreateAuctionItem(dispatch, options),
  /** Function to get auction submission details. */
  getAuctionSubmissionList: (options: QueryauctionConnectionArgs) => processGetAuctionSubmissionList(dispatch, options),
  /** Function to get the upcoming timeslots of the selected auction */
  getUpcomingTimeSlots: (options: QueryauctionArgs) => processGetUpcomingTimeSlots(dispatch, options),
  /** Function to update the inventory item list. */
  updateInventoryItemsList: (options: { [key: string]: any }) => processUpdateInventoryItemsList(options, dispatch),
  /** Function to update my block list item. */
  updateMyBlockListItem: (auctionItem) => dispatch(myBlockUpdateAuctionItem(auctionItem)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem?: AuctionItem;
  /** The button label. */
  buttonText: string;
  /** CSS style to override default style. */
  className?: string;
  /** True when button icon is displayed, defaults to true. */
  hasIcon?: boolean;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** The current url information. */
  location: Location;
}

interface State {
  /** True when the dialog is open. */
  isOpen: boolean;
}

class SubmitToAuction extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = prevState;
    const { isOpen: isOpenNext } = this.state;
    const { location } = prevProps;
    const { auctionItem, auctionSubmission, clear, location: locationNext, updateMyBlockListItem } = this.props;

    if (isOpen && !isEqual(location, locationNext)) {
      // Run deferred update to My Block list item after form is closed. We can't
      // automatically update the value; otherwise the form will auto-close on user.
      // Since we're re-running the unit, a new auctionItem id will be returned;
      // pass `idNext` to tell the reducer to swap the auctionItem's ids
      const auctionItemNext = {
        ...auctionSubmission?.auctionItem,
        id: auctionItem?.id,
        idNext: auctionSubmission?.auctionItem?.id,
      };
      updateMyBlockListItem(auctionItemNext);
      clear();

      this.setState({ isOpen: false });
      return;
    }

    if (!isOpen && isOpenNext) {
      this.getAuctionList();
    } else if (isOpen && !isOpenNext) {
      clear();
    }
  }

  getAuctionList = () => {
    const {
      getAuctionSubmissionList,
      inventoryItemDetails: { location },
    } = this.props;

    return getAuctionSubmissionList({
      regionCode: location?.regionCode,
    });
  };

  handleCloseModal = () => {
    const {
      updateInventoryItemsList,
      location: { query },
    } = this.props;

    this.setState({ isOpen: false });
    updateInventoryItemsList(query);
  };

  render() {
    const { isOpen } = this.state;
    const {
      auctionItem,
      auctionSubmission,
      buttonText,
      className,
      createAuctionItem,
      getUpcomingTimeSlots,
      hasIcon = true,
      inventoryItemDetails,
      location,
    } = this.props;

    return [
      <OperationButton
        key="button"
        className={className}
        dataTestId="submitToAuctionButton"
        glyph={hasIcon ? submitGlyph : undefined}
        onClick={() => {
          this.setState({ isOpen: true });
          Logger.trackUserAction(auctionItem ? UserAction.RUN_AGAIN : UserAction.SUBMIT_TO_AUCTION, {
            is_auction_staff: !!auctionItem?.auction?.id && AuthService.representsAuction(auctionItem.auction.id),
          });
        }}
        theme="green"
      >
        {buttonText}
      </OperationButton>,
      <Dialog
        key="dialog"
        auctionItem={auctionItem}
        auctionItemId={auctionSubmission?.auctionItem?.id}
        closeModal={this.handleCloseModal}
        createAuctionItem={createAuctionItem}
        getUpcomingTimeSlots={getUpcomingTimeSlots}
        inventoryItemDetails={inventoryItemDetails}
        isOpen={isOpen}
        list={auctionSubmission?.list}
        location={location}
      />,
    ];
  }
}

export default connector(SubmitToAuction);
