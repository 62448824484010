import classnames from 'classnames';
import { HTMLAttributes, ReactNode, useCallback, useRef, useState, Children, useEffect } from 'react';

import style from './scrollable.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The content of the component. */
  children: ReactNode;
  /** Overwrite scrollable container classname, only valid if `isScrollable` is True */
  scrollableClassName?: string;
}

export const Scrollable = ({ className, children, scrollableClassName, ...props }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showFade, setShowFade] = useState<boolean>(false);
  const hasContent = Boolean(Children.count(children));

  /**
   * Handles scroll event - show/hide fade based on content
   **/
  const calculateToShowFade = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight } = scrollRef.current;
    const { height } = scrollRef.current.getBoundingClientRect();

    setShowFade((previousState) => {
      if (hasContent) {
        return Math.floor(scrollHeight - scrollTop) !== Math.floor(height);
      }

      return previousState;
    });
  }, [hasContent]);

  /**
   * Calculate whether to show fade on mount
   */
  useEffect(() => {
    if (!scrollRef.current) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(calculateToShowFade);
    resizeObserver.observe(scrollRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateToShowFade]);

  return (
    <div {...props} className={classnames(style.container, style.scrollable, className)}>
      <div
        ref={scrollRef}
        className={classnames(style.scrollableContainer, { [style.fade]: showFade }, scrollableClassName)}
        onScroll={calculateToShowFade}
      >
        {children}
      </div>
    </div>
  );
};
