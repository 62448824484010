import { PartialDeep, SetOptional } from 'type-fest';
import { memoize } from 'lodash-es';

import InventoryItem from 'constants/inventoryItem';
import { VehicleConditionReport } from 'store/shared/api/graph/interfaces/types';
import { getScoreRangeByInventoryItem, ScoreRange } from 'components/ui/shared/scores/scoreRanges';
import { isVerifiedAutoGrade, isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';

/**
 * Takes a  {@link VehicleConditionReport}, return true if carfax claim amount is not 0 or declarations is not empty.
 * Otherwise, return false.
 *
 * @param conditionReport {object} condition report
 * @returns {boolean}
 */
export const hasCarfaxClaimOrDeclarations = (conditionReport?: PartialDeep<VehicleConditionReport>): boolean => {
  if (conditionReport) {
    const { carfaxCanadaClaimAmount, declarations } = conditionReport;
    return !!carfaxCanadaClaimAmount?.amount || !!declarations?.length;
  }
  return false;
};

export const isAutogradeEnabled = (
  inventoryItem: SetOptional<Pick<InventoryItem, 'autoGradeScore' | 'captureType'>, 'captureType'>
): boolean => {
  return (
    (inventoryItem?.autoGradeScore !== null && inventoryItem?.autoGradeScore !== undefined) ||
    isVerifiedAutoGrade(inventoryItem?.captureType)
  );
};

/**
 * Basic vin regex validation
 * @param vin
 */
export const isValidVin = (vin: string): boolean =>
  /(?=.*\d|=.*[A-Z])(?=.*[A-Z])[A-Z0-9]{17}/g.test(vin?.toUpperCase());

export interface VehicleScoreInput extends Pick<InventoryItem, 'autoGradeScore' | 'extensiveVehicleConditionScore'> {
  captureType?: InventoryItem['captureType'];
  countryCode?: string;
  overallConditionRating?: number;
}

export interface VehicleScore {
  /** True if it is an autograde score */
  isAutoGrade: boolean;
  /** The score range */
  range: ScoreRange;
  /** The score */
  score: number | undefined;
}

/**
 * Gets vehicle score based on {@link VehicleScoreInput} and returns {@link VehicleScore}
 * to be used with `<ScoreBadge />`
 *
 * @param {VehicleScoreInput} VehicleScoreInput - VehicleScore Input
 * @returns {VehicleScore}
 */
export const getVehicleScore = memoize(
  ({
    autoGradeScore,
    captureType,
    countryCode,
    extensiveVehicleConditionScore,
    overallConditionRating,
  }: VehicleScoreInput): VehicleScore => {
    const range = getScoreRangeByInventoryItem({
      autoGradeScore,
      captureType,
      location: countryCode ? { countryCode } : undefined,
    });
    const vehicleScore = isVerifiedExtensiveVehicleCondition(captureType)
      ? extensiveVehicleConditionScore
      : overallConditionRating || 0;
    const isAutoGrade = isAutogradeEnabled({ autoGradeScore, captureType });
    const score = (isAutoGrade ? autoGradeScore : vehicleScore) ?? undefined;

    return {
      isAutoGrade,
      range,
      score,
    };
  }
);

/**
 * Gets vehicle score based on {@link InventoryItem} and returns {@link VehicleScore}
 * tob be used with `<ScoreBadge >`
 *
 * @param {InventoryItem} InventoryItem - InventoryItem input
 * @returns {VehicleScore}
 */
export const getVehicleScoreByInventoryItem = ({
  autoGradeScore,
  captureType,
  conditionReport,
  extensiveVehicleConditionScore,
  location,
}: InventoryItem): VehicleScore =>
  getVehicleScore({
    autoGradeScore,
    captureType,
    countryCode: location?.countryCode,
    extensiveVehicleConditionScore,
    overallConditionRating: conditionReport?.overallConditionRating || undefined,
  });
