import { ReactNode } from 'react';

import errorGlyph from 'glyphs/error.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import NoResults from 'components/ui/noResults/noResults';
import SentryLogger from 'logging/SentryLogger';
import { getClearedUrl } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';
import style from './404/404.scss';

interface Props {
  /** The component to render. */
  children: ReactNode;
}

interface State {
  /** True when an error occurred. */
  hasError: boolean;
}

class ErrorBoundary extends BaseClass<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* Error */) {
    // Apply global error class; used to hide any active dialogs/modals
    document.getElementById('modal-root')?.classList?.add?.('global-error');

    return { hasError: true };
  }

  componentDidCatch(error, info) {
    SentryLogger.captureException(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoResults className={style.error404} glyph={errorGlyph} title={t('something_went_wrong')}>
          <p>{t('you_found_a_bug_message')}</p>
          <p>{t('track_error_message')}</p>
          <Button
            onClick={() => {
              window.location.assign(getClearedUrl(['id']));
            }}
            theme="blue"
          >
            {t('refresh')}
          </Button>
        </NoResults>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
