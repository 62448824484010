import classnames from 'classnames';
import { MouseEvent } from 'react';

import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import Photo from 'constants/photo';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { t } from 'utils/intlUtils';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './conditionReport.scss';

interface Props {
  /** Vehicle damage photos. */
  damagePhotos?: InventoryItem['damagePhotos'];
  /** Callback function to handle feature click event. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: { photos: Photo[] }) => void;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal: (feature: VehicleFeature) => void;
  /** True when inventory item details are editable. */
  isUpdatable: boolean;
  /** Function invoked on damage photos button click. */
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
}

const DamagePhotosButton = ({
  damagePhotos,
  handleFeatureClicked,
  handleOpenAddModifyModal,
  isUpdatable,
  onClick,
}: Props) => {
  const { damage } = vehicleFeatures;

  return (
    <Button
      className={classnames(
        style.crButton,
        isUpdatable && style.crButtonUpdatable,
        !damagePhotos && style.disabled,
        damagePhotos && damagePhotos.length > 0 && style.crButtonRed
      )}
      dataTestId="damage-photos-button"
      onClick={() => {
        onClick?.();
        isUpdatable ? handleOpenAddModifyModal(damage) : handleFeatureClicked('DAMAGE', { photos: damagePhotos || [] });
      }}
      theme="none"
    >
      <div>
        <span className={classnames(style.crButtonText, style.red)}>{damagePhotos?.length || 0}</span>
        <span className={style.crButtonSubText}>{t('damage_photos')}</span>
        {isUpdatable && <Sprite className={style.crEditGlyph} glyph={editGlyph} />}
      </div>
      <Chevron className={style.chevron} />
    </Button>
  );
};

export default DamagePhotosButton;
