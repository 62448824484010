import ApiRequest from 'store/shared/api/apiRequest';
import { CarrierConnection } from 'store/shared/api/graph/interfaces/types';
import { getFacetGroupsByUrl } from 'store/shared/api/graph/schema/connection';

export const getFacets = ({
  first = null,
  last = null,
  after = null,
  before = null,
  active = null,
  transactionType = null,
  region = null,
  city = null,
  consigner = null,
  compound = null,
  psi = null,
  keyword = null,
  sort = null,
}) =>
  ApiRequest<{ carrierConnection: Pick<CarrierConnection, 'facetGroups'> }>({
    data: {
      query: `
      query(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $status: String
        $shipmentMode: [String!]
        $deliveryRegion: [String!]
        $deliveryRegionCodes: [String!]
        $keyword: String
      ) {
        carrierConnection(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          status: $status,
          shipmentModes: $shipmentMode
          deliveryRegions: $deliveryRegion
          deliveryRegionCodes: $deliveryRegionCodes
          keyword: $keyword
        ) {
          ${getFacetGroupsByUrl()}
        }
      }
    `,
      variables: {
        first,
        last,
        after,
        before,
        active,
        transactionType,
        region,
        city,
        consigner,
        compound,
        psi,
        keyword,
        sort,
      },
    },
  });
