import classnames from 'classnames';
import { useEffect, useRef } from 'react';

import Button from 'components/ui/shared/button';
import { ChatChannel } from 'components/ui/chat/chatSidePanel';
import { ConversationType } from 'store/shared/api/graph/interfaces/types';
import { useOnScreen } from 'hooks/useOnScreen';

import style from './chatChannelButton.scss';

export enum ScrollIntoViewType {
  NONE,
  TOP,
  BOTTOM,
}

export interface ChatChannelButtonProps extends ChatChannel {
  /** The conversation type of the channel */
  conversationType: ConversationType;
  /** Index of the button in the section - used for testing sort order */
  index?: number;
  /** Callback function to handle on chat channel click event. */
  onChannelClick: ((channelId: string) => void | undefined) | undefined;
  /** Callback function when channel visibility changes */
  onVisibilityChange?: (isVisible: boolean) => void;
  /** If set, the element will scroll into view */
  scrollIntoViewType?: ScrollIntoViewType | false;
  /** Section name that the button belongs to - necessary for test id */
  sectionName?: string;
  /** Currently selected chat channel id. */
  selectedChannelId?: string;
}

const ChatChannelButton = ({
  channelId,
  conversationType,
  index,
  name,
  onChannelClick,
  onVisibilityChange = () => {},
  scrollIntoViewType,
  sectionName,
  selectedChannelId,
  unreadMessageCount,
}: ChatChannelButtonProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(itemRef, true);
  const hasUnreadMessage = Number(unreadMessageCount) > 0;

  useEffect(() => {
    onVisibilityChange(!!isVisible);
  }, [isVisible, onVisibilityChange]);

  useEffect(() => {
    if (!scrollIntoViewType) {
      return;
    }

    itemRef?.current?.scrollIntoView &&
      itemRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: scrollIntoViewType === ScrollIntoViewType.BOTTOM ? 'end' : 'start',
      });
  }, [scrollIntoViewType]);

  return (
    <Button
      className={classnames(style.channelButton, {
        [style.isSelected]: selectedChannelId === channelId,
        [style.isBroadcast]: conversationType === ConversationType.LIVE_LANE_BROADCAST,
      })}
      dataTestId={`chat-channel-button-${channelId}-${sectionName}`}
      onClick={() => onChannelClick?.(channelId)}
      theme="none"
    >
      <div
        ref={itemRef}
        className={classnames(style.channelName, {
          [style.hasUnreadMessage]: hasUnreadMessage,
          [style.isSelected]: selectedChannelId === channelId,
          [style.isBroadcast]: conversationType === ConversationType.LIVE_LANE_BROADCAST,
        })}
        data-index={index}
        data-testid={`chat-channel-${channelId}-${sectionName}`}
        title={name}
      >
        {name}
      </div>
      {hasUnreadMessage && (
        <div className={style.unreadMessageCount} data-testid={`chat-channel-unread-${channelId}-${sectionName}`}>
          {unreadMessageCount}
        </div>
      )}
    </Button>
  );
};

export default ChatChannelButton;
