import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { t } from 'utils/intlUtils';

interface Props {
  /** Validation errors. */
  errorMessages?: ErrorMessages;
  /** True when dialog is open. */
  isOpen?: boolean;
  /** True when form is submitting. */
  isSubmitting: boolean;
  /** Function invoked on confirm click. */
  onSubmit?: (shouldSubmit: boolean) => void;
}

class Dialog extends BaseClass<Props> {
  render() {
    const { isOpen, onSubmit, errorMessages, isSubmitting } = this.props;

    return (
      <ConfirmDialog
        actionProgress={isSubmitting}
        isOpen={!!isOpen}
        onConfirm={onSubmit}
        theme="red"
        title={t('pull_from_auction')}
      >
        <FormErrors errorMessages={errorMessages} isSmallDialog />
        {t('pull_from_auction_dialog_message')}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
