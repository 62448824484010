import { t } from 'utils/intlUtils';

import style from 'forms/account/accountVerification/accountVerification.scss';

const AccountVerificationSuccess = () => (
  <>
    <p className={style.title}>{t('successfully_verified')}</p>
    <p>{t('successfully_verified_message')}</p>
  </>
);

export default AccountVerificationSuccess;
