import classnames from 'classnames';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { t } from 'i18next';

import { Chevron } from 'components/ui/shared/chevrons';

import style from './collapsibleList.scss';

interface Props<T> {
  /** The className to overwrite default button styles */
  buttonClassName?: string;
  /** The className to overwrite default styles */
  className?: string;
  /** The number of items to display intitially */
  defaultDisplayCount: number;
  /** The function to get the id of the item */
  getItemId: (item: T) => string;
  /** The className to overwrite default item styles */
  itemClassName?: string;
  /** List of items to display */
  items: T[];
  /** Function to render each item */
  renderItem: (item: T) => ReactNode;
  /** Overwrite default show more text */
  showMoreText?: string;
  /** Overwrite default show less text */
  showLessText?: string;
}

const CollapsibleList = <T extends unknown>({
  buttonClassName,
  className,
  defaultDisplayCount,
  getItemId,
  itemClassName,
  items,
  renderItem,
  showLessText = t('less'),
  showMoreText = t('more'),
}: Props<T>) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [displayCount, setDisplayCount] = useState<number>(defaultDisplayCount);

  /**
   * Handles show more/less button click event. Toggles between showing more
   * or showing less
   */
  const handleButtonClickEvent = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setShowAll(!showAll);
      setDisplayCount(showAll ? defaultDisplayCount : items.length);
    },
    [defaultDisplayCount, items.length, showAll]
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classnames(style.container, className)}>
      <ul className={style.list} data-testid="more-list">
        {items.slice(0, displayCount).map((item) => (
          <li key={getItemId(item)} className={classnames(style.item, itemClassName)}>
            {renderItem(item)}
          </li>
        ))}
      </ul>
      {items.length > defaultDisplayCount && (
        <button
          className={classnames(style.button, buttonClassName)}
          data-testid="more-button"
          onClick={handleButtonClickEvent}
        >
          {showAll ? showLessText : showMoreText}
          <Chevron className={classnames(style.chevron, { [style.less]: showAll })} />
        </button>
      )}
    </div>
  );
};

export default CollapsibleList;
