import { useRef, useState } from 'react';

import { AccountVerificationStepHandleRef } from 'forms/account/accountVerification/steps/accountVerificationHelpers';

export const useFormState = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formData, setFormData] = useState<Record<string, any>>();
  const [isActionable, setIsActionable] = useState<boolean>(true);
  const [isConfirmCloseDialogOpen, setIsConfirmCloseDialogOpen] = useState<boolean>(false);
  const [isConfirmUnverifiedDialogOpen, setIsConfirmUnverifiedDialogOpen] = useState<boolean>(false);
  const formRef = useRef<AccountVerificationStepHandleRef>();

  return {
    currentStep,
    formData,
    formRef,
    isActionable,
    isConfirmCloseDialogOpen,
    isConfirmUnverifiedDialogOpen,
    setCurrentStep,
    setFormData,
    setIsActionable,
    setIsConfirmCloseDialogOpen,
    setIsConfirmUnverifiedDialogOpen,
  };
};
