import moment from 'moment';

import VehicleImage from 'components/ui/shared/images/vehicleImage';
import EditButton from 'components/ui/shared/buttons/editButton';
import Countdown from 'components/ui/countdowns/countdown';
import { Flex, FlexRow } from 'layouts/shared/flexbox';
import { PrimaryTitle, SecondaryTitle, SecondaryTitleLight, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { TimerFormats } from 'constants/enums/dateAndTime';
import { formatYMMT } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './checkoutItemPreview.scss';

const CheckoutItemPreview = ({
  auctionItem,
  onEdit,
  isSingleForm,
}: {
  auctionItem: any;
  onEdit?: () => void;
  isSingleForm?: boolean;
}) => (
  <Flex className={style.container}>
    <VehicleImage imageUrl={auctionItem?.inventoryItem?.photos?.[0]?.thumb} />
    <div className={style.columnMiddle}>
      <PrimaryTitle className={style.primaryTitle}>{formatYMMT(auctionItem?.inventoryItem)}</PrimaryTitle>
      <FlexRow className={style.subTitles}>
        {!isSingleForm && (
          <>
            <SecondaryTitle className={style.paymentMethod}>{t('payment_method')}</SecondaryTitle>
            <SecondaryTitleLight>{auctionItem?.order?.paymentType?.name}</SecondaryTitleLight>
          </>
        )}
        <TertiaryTitleLight className={style.expiryDate}>
          <Countdown end={moment(auctionItem?.checkoutExpiry)} timeFormat={TimerFormats} />{' '}
          {t('time_left_to_complete_checkout')}
        </TertiaryTitleLight>
      </FlexRow>
    </div>
    <div className={style.columnRight}>
      <PrimaryTitle className={style.primaryTitle}>
        {auctionItem?.topOffer?.amount?.formattedAmountRounded}
      </PrimaryTitle>
      {onEdit && (
        <EditButton className={style.editButton} onClick={onEdit} text={t('edit_order')} theme="blue-pencil" />
      )}
    </div>
  </Flex>
);

export default CheckoutItemPreview;
