import { JSONObject, Message } from '@twilio/conversations';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import SimulcastChatManager from 'io/twilio/simulcastChatManager';
import { ConnectionState } from 'io/twilio/simulcastChatManagerTypes';
import { Conversation, ConversationMessageType, ConversationType } from 'store/shared/api/graph/interfaces/types';
import { ConversationMap, ConversationMessageState, ConversationState } from 'store/chat/chatModels';
import { processAddMessage, setConversationsListening } from 'store/chat/chatActions';
import { useLoadMessages } from 'components/sections/auctionChat/hooks/useLoadMessages';

export enum InitialMessageLoadPolicy {
  /** Load all by default ( Auctioneer flow - filtered to sellers in redux ) */
  ALL,
  /** Only load the current conversation and no others */
  CURRENT_CONVERSATION,
  /** Load the current conversation and the broadcast channel */
  CURRENT_AND_BROADCAST,
}

interface Props {
  /** Chat manager broker for twillio events */
  chatManager: SimulcastChatManager;
  /** Current connection state of the chat manager */
  connectionState: ConnectionState;
  /** Current set of conversations to listen to */
  conversations: ConversationMap;
  /** Current conversation id */
  currentConversationId: string;
  /** Message loading policy to determine which conversation messages get initially loaded */
  messageLoadingPolicy: InitialMessageLoadPolicy;
  /** Set error state */
  setError: (err: Error) => void;
}

/**
 * This effect will add listeners to each conversation so that when new messages are added, the
 * component will be notified and can update the chat window accordingly.
 */
export const useConversationsListener = ({
  chatManager,
  connectionState,
  conversations,
  currentConversationId,
  messageLoadingPolicy,
  setError,
}: Props) => {
  const dispatch = useDispatch();

  /**
   * This callback is called when an event is received from Twilio.  If it's a message
   * from the local user we will ignore it since the redux state will already have the
   * message.
   */
  const onMessageAdded = useCallback(
    (message: Message) => {
      const messageObj: ConversationMessageState = {
        auctionItemId: (message.attributes as JSONObject)?.auctionItemId?.toString(),
        created: message.dateCreated?.toISOString() || '',
        createdById: message.author,
        currentBidAmount: (message.attributes as JSONObject)?.currentBidAmount?.valueOf() as number,
        id: message.sid,
        message: message.body,
        type: (message.attributes as JSONObject).broadcast
          ? ConversationMessageType.LIVE_LANE_BROADCAST
          : ConversationMessageType.LIVE_LANE,
      };

      processAddMessage({ conversationId: message.conversation.uniqueName, message: messageObj }, dispatch);
    },
    [dispatch]
  );

  /**
   * Add listener for message added
   */
  useEffect(() => {
    chatManager?.onMessageAdded(onMessageAdded);
  }, [chatManager, onMessageAdded]);

  /**
   * We need to listen to all the conversations in the sidebar as well so that
   * the unread message annotation can be updated.
   */
  useEffect(() => {
    const conversationArray: ConversationState[] = Object.values(conversations)?.filter(
      (conversation) => !conversation.listening && !conversation.inactive
    );

    if (connectionState === ConnectionState.CONNECTED && !!conversationArray?.length) {
      Promise.all(
        conversationArray?.map(async (conversation: Conversation) => {
          if (!conversation?.staff) {
            // This is a virtual conversation - no listener required
            return;
          }

          await chatManager?.listenToConversation(conversation.id)?.catch((err) => {
            setError(err);
          });
        })
      ).then(() => {
        dispatch(
          // TODO: Migrate to modern redux infra https://einc.atlassian.net/browse/EB-12368
          // TODO: Clean up `any` typing
          setConversationsListening({
            conversationIds: conversationArray.map((conversation) => conversation.id),
          }) as any
        );
      });
    }
  }, [chatManager, conversations, connectionState, dispatch, setError]);

  /**
   * We need to turn off listeners for any inactive conversations
   */
  useEffect(() => {
    const conversationArray: ConversationState[] = Object.values(conversations)?.filter(
      (conversation) => conversation.inactive
    );

    if (connectionState === ConnectionState.CONNECTED && !!conversationArray?.length) {
      conversationArray?.forEach((conversation: Conversation) => {
        chatManager?.removeListeners(conversation.id);
      });
    }
  }, [chatManager, conversations, connectionState, dispatch, setError]);

  /**
   * Calculate the array of conversations that we need to initially load in the main conversation pane
   * */
  const conversationsToLoadMessages: ConversationState[] = useMemo((): ConversationState[] => {
    switch (messageLoadingPolicy) {
      case InitialMessageLoadPolicy.ALL: {
        return Object.values(conversations);
      }

      case InitialMessageLoadPolicy.CURRENT_AND_BROADCAST: {
        const currentAndBroadCastConversations: ConversationState[] = [];
        const currentConversation: ConversationState = conversations[currentConversationId];

        // It's possible current conversation could be null - if a staff viewing the auction joins
        if (currentConversation) {
          currentAndBroadCastConversations.push(conversations[currentConversationId]);
        }
        const broadCastConversation: ConversationState | undefined = Object.values(conversations).find(
          (conversation) => conversation.type === ConversationType.LIVE_LANE_BROADCAST
        );
        if (broadCastConversation && broadCastConversation.id !== currentConversation?.id) {
          currentAndBroadCastConversations.push(broadCastConversation);
        }
        return currentAndBroadCastConversations;
      }

      case InitialMessageLoadPolicy.CURRENT_CONVERSATION:
      default:
        return conversations[currentConversationId] ? [conversations[currentConversationId]] : [];
    }
  }, [conversations, currentConversationId, messageLoadingPolicy]);

  /**
   * Hook to load messages from Twillio only when conversation is loaded
   */
  useLoadMessages({
    chatManager,
    conversations: conversationsToLoadMessages,
    setError,
  });
};
