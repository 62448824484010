import { MouseEventHandler, useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import Dialog from 'components/sections/auctionItem/details/status/statusRows/offerRow/declineOffer/dialog';
import { Company, MonetaryAmount } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

interface Props {
  /** Company that is declining an offer */
  companyDeclining: Pick<Company, 'id' | 'name'>;
  /** Company that is offering */
  companyOffering: Pick<Company, 'id' | 'name'>;
  /** True if it's a seller */
  isSeller: boolean;
  /** Offer Id */
  offerId: string;
  /** Offered amount */
  offeredAmount: Pick<MonetaryAmount, 'formattedAmountRounded'>;
  /** Called each time the dialog is opened or closed */
  onIsOpenChange: (isOpen: boolean) => void;
}

const DeclineOffer = ({ onIsOpenChange, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Handles decline offer action - Opens Dialog for confirmation
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault();

      setIsOpen(true);
      onIsOpenChange(true);
    },
    [onIsOpenChange]
  );

  /**
   * Handles cancel action - Closes Dialog
   */
  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    onIsOpenChange(false);
  }, [onIsOpenChange]);

  return (
    <>
      <Button onClick={handleOnClick} theme="red">
        {t('decline')}
      </Button>
      {isOpen && <Dialog {...props} onClose={handleOnClose} />}
    </>
  );
};

export default DeclineOffer;
