import { handleActions } from 'redux-actions';

import { Arbitration, InitialState, ArbitrationMetaData } from './arbitrationDetailsModels';
import {
  isLoaded,
  isUpdating,
  isNotUpdating,
  preloadArbitrationItem,
  updateArbitrationItem,
  updateArbitrationMetaData,
} from './arbitrationDetailsActions';

export const arbitrationDetailsReducer = handleActions(
  {
    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [isLoaded().type]: (state, action) => {
      const { auctionItem } = action.payload;
      return state.setLoaded().set('details', new Arbitration(auctionItem));
    },

    [preloadArbitrationItem().type]: (state, action) => {
      if (action.payload && action.payload.id) {
        const arbitration = new Arbitration(action.payload);
        return state.setLoaded().set('details', arbitration);
      }
      return state;
    },

    [updateArbitrationItem().type]: (state, action) => {
      const details = state.details.toJS();

      return state.setLoaded().merge({
        details: new Arbitration({
          ...details,
          ...action.payload,
        }),
      });
    },

    [updateArbitrationMetaData().type]: (state, action) => {
      return state.set('metadata', new ArbitrationMetaData(action.payload));
    },
  },
  new InitialState()
);
