import classnames from 'classnames';
import { ReactElement, SVGProps } from 'react';

import _style from './sprite.scss';

export type Glyph = (props) => ReactElement;

interface Props extends SVGProps<SVGElement> {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** CSS fill style. */
  fillStyle?: string;
  /** Function invoked on sprite click. */
  onClick?(...args: unknown[]): unknown;
  /** The rendered glyph image. */
  glyph?: Glyph;
}

const Sprite = ({ className, fillStyle, glyph: Component, onClick = () => {}, ...props }: Props) => {
  if (typeof Component === 'function') {
    return (
      <Component
        className={classnames('sprite', _style.sprite, fillStyle && _style[fillStyle], className)}
        onClick={onClick}
        {...props}
      />
    );
  }
  return null;
};

export default Sprite;
