import { MouseEvent } from 'react';

import GoogleMapsIcon from 'glyphs/location-pin.svg';

import Button from 'components/ui/shared/button';
import ChangeLocation from 'components/sections/inventoryItem/operations/changeLocation/changeLocation';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import { DetailsSection } from 'layouts/list/listLayout';
import { PaneIndex } from '../addModify/vehicleForm';
import { formatAddress } from 'utils/stringUtils';
import { isVerified } from 'utils/auctionItemUtils';
import { openMapLink } from 'components/ui/maps/map';
import { t } from 'utils/intlUtils';

import style from './pickupLocation.scss';

interface Props {
  /** The id of the section. */
  id?: string;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem | null;
  /** Opens the ChangeLocation dialog if true; otherwise, the add/modify modal is opened. */
  isDialogMode?: boolean;
  /** True when pickup location is editable. */
  isEditable?: boolean;
  /** Function invoked on confirm button click. */
  onConfirmClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Function invoked on edit button click. */
  onEditClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Function invoked on view map button click. */
  onViewMapClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Function invoked on open/add button click. */
  openAddModifyModal?: (section: string, index: number) => void;
  /** Type of item the pickup location is associated to. */
  type?: 'inventoryItem' | 'auctionItem';
}

const PickupLocation = ({
  id,
  inventoryItemDetails,
  isDialogMode = false,
  isEditable = false,
  onEditClick,
  onConfirmClick,
  onViewMapClick,
  openAddModifyModal,
  type = 'inventoryItem',
}: Props) => {
  const { captureType, company, id: itemId, location, pickupLocation } = inventoryItemDetails || {};
  const verified = isVerified(captureType);

  if (!location?.id || !pickupLocation?.id) {
    return null;
  }

  return (
    <DetailsSection
      contentClassName={style.content}
      dataTestId="pickupLocation-section"
      headerEndContent={
        isEditable &&
        (verified || isDialogMode) && (
          <ChangeLocation
            consignerId={company?.id}
            inventoryItemId={itemId}
            locationId={location?.id}
            onConfirmClick={onConfirmClick}
            onEditClick={onEditClick}
            pickupLocationId={pickupLocation?.id}
            type={type}
          />
        )
      }
      id={id}
      onClickSubButton={
        isEditable && !verified && !isDialogMode
          ? () => {
              onEditClick?.();
              openAddModifyModal?.('location', PaneIndex.VEHICLE_DETAILS);
            }
          : null
      }
      subButtonType="edit"
      title={t('pick_up_location')}
    >
      <div className={style.details} data-testid="pickupLocation-details">
        <div className={style.detailsText}>
          <div className={style.name}>{pickupLocation.name}</div>
          <div>{formatAddress(pickupLocation, true)}</div>
        </div>
      </div>
      <Button
        className={style.mapBtn}
        onClick={(event) => {
          onViewMapClick?.(event);
          openMapLink(pickupLocation);
        }}
        theme="gray-outline"
      >
        <Sprite key="edit" glyph={GoogleMapsIcon} />
        {t('view_map')}
      </Button>
    </DetailsSection>
  );
};

export default PickupLocation;
