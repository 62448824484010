import { handleActions } from 'redux-actions';

import { InitialState } from './marqueeModels';
import {
  auctionItemLoaded,
  auctionItemUpdated,
  clearMarquee,
  isLoading,
  isNotUpdating,
  isUpdating,
  liveLaneLoaded,
} from './marqueeActions';

export const marqueeReducer = handleActions(
  {
    [clearMarquee().type]: () => new InitialState(),

    [isLoading().type]: (state) => state.setLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [auctionItemLoaded().type]: (state, action) => {
      return state.setLoaded().set('auctionItem', action.payload);
    },

    [auctionItemUpdated().type]: (state, action) => {
      const marqueeState = state.toJS();
      return state.set('auctionItem', { ...marqueeState.auctionItem, ...action.payload });
    },

    [liveLaneLoaded().type]: (state, action) => {
      return state
        .setLoaded()
        .set('auctionTimeSlotLane', action?.payload?.auctionTimeSlotLane)
        .set('upcomingItems', action?.payload?.upcomingItems);
    },
  },
  new InitialState()
);
