/**
 * This file is meant to hold any utilities for interacting with client-side storage, be it cookies, localStorage, or
 * sessionStorage. Any individual components that need to interact with the store can create an instance of storage
 * to allow for a separate mgmt of storage. A default storage is created to start for quick usage.
 */

import { ValueOf } from 'type-fest';

export const STORAGE_TYPES = {
  local: 'LOCAL',
  session: 'SESSION',
} as const;

export type StorageTypes = ValueOf<typeof STORAGE_TYPES>;

const STORAGE_MAPPING = {
  LOCAL: window.localStorage,
  SESSION: window.sessionStorage,
};

class Storage<T = string> {
  name: string;
  type: StorageTypes;
  store: globalThis.Storage;

  constructor(name: string, type: StorageTypes = STORAGE_TYPES.local) {
    this.name = `eBlock.${name}`;
    this.type = type;
    this.store = STORAGE_MAPPING[this.type];
  }

  get(): T | null {
    try {
      const item = this.store.getItem(this.name);
      return item ? JSON.parse(item) : null;
    } catch (err) {
      // Remove if parse fails.
      this.remove();
      return null;
    }
  }

  set(value: T): Storage<T> {
    try {
      if (value !== null || value !== undefined) {
        this.store.setItem(this.name, JSON.stringify(value));
        window.dispatchEvent(new Event('storage'));
      }
    } catch (err) {
      throw Error(`Error setting ${value} in storage ${this.name}. \n ${err}`);
    }
    return this;
  }

  remove(): void {
    try {
      this.store.removeItem(this.name);
    } catch (err) {
      throw Error(`Error removing storage with name ${this.name}. \n ${err}`);
    }
  }

  getIn<K extends keyof T>(key: K): T[K] | null {
    const item = this.store.getItem(this.name);
    return item ? JSON.parse(item)[key] : null;
  }

  setIn<K extends keyof T>(childKey: K, value: T[K]): Storage<T> {
    const item = this.store.getItem(this.name);
    const toSave = item ? JSON.parse(item) : {};
    toSave[childKey] = value;
    this.store.setItem(this.name, JSON.stringify(toSave));
    window.dispatchEvent(new Event('storage'));
    return this;
  }

  removeIn<K extends keyof T>(key: K): Storage<T> {
    const item = this.store.getItem(this.name);
    const parent = item ? JSON.parse(item) : {};
    delete parent[key];
    this.store.setItem(this.name, JSON.stringify(parent));
    return this;
  }
}

class StorageManager {
  // Private dict that holds references to instances of `Storage`
  private managedStorage: Record<string, Storage<any>> = {};

  constructor() {
    this.loadStorage();
  }

  createOrFetchStorage<T = string>(name: string, type: StorageTypes | undefined = undefined): Storage<T> {
    // Check if the storage already exists and matches the requested type
    const existingStorage = this.managedStorage[name];
    if (existingStorage && type === existingStorage.type) {
      return existingStorage as Storage<T>;
    }

    // Create a new Storage instance if it does not exist or the type is different
    const newStorage = new Storage<T>(name, type);
    this.managedStorage[name] = newStorage;
    return newStorage;
  }

  private loadStorage(): void {
    this.loopAndLoad(window.localStorage, STORAGE_TYPES.local);
    this.loopAndLoad(window.sessionStorage, STORAGE_TYPES.session);
  }

  private loopAndLoad<T = string>(storage: globalThis.Storage, storageType: StorageTypes | undefined): void {
    Object.keys(storage).forEach((key) => {
      const removeNameSpace = key.replace('eBlock.', '');
      if (!this.managedStorage[removeNameSpace]) {
        this.managedStorage[removeNameSpace] = new Storage<T>(removeNameSpace, storageType);
      }
    });
  }
}

export const storageManager = new StorageManager();
