import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  QueryauctionItemConnectionArgs,
  QuerymyBlockBuyerStatisticsArgs,
  QuerymyBlockSellerStatisticsArgs,
} from 'store/shared/api/graph/interfaces/types';
import { getMyBlockBuyerStats, getMyBlockList, getMyBlockSellerStats } from 'store/myBlock/list/myBlockApi';

export const myBlockBuyerListLoaded = createAction('MY_BLOCK_BUYER_LOADED');
export const myBlockBuyerStatsLoaded = createAction('MY_BLOCK_BUYER_STATS_LOADED');
export const myBlockListCleared = createAction('MY_BLOCK_CLEARED');
export const myBlockListIsLoading = createAction('MY_BLOCK_LOADING');
export const myBlockSellerListLoaded = createAction('MY_BLOCK_SELLER_LOADED');
export const myBlockSellerStatsLoaded = createAction('MY_BLOCK__SELLER_STATS_LOADED');
export const myBlockUpdateAuctionItem = createAction('MY_BLOCK_UPDATE_AUCTION_ITEM');
export const myBlockUpdateAuctionItemValues = createAction('MY_BLOCK_UPDATE_AUCTION_ITEM_VALUES');

export const processGetMyBlockSellerList = (
  listOptions: QueryauctionItemConnectionArgs,
  statsOptions: QuerymyBlockSellerStatisticsArgs,
  dispatch: AppDispatch
) => {
  dispatch(myBlockListIsLoading());

  // Fetch stats
  getMyBlockSellerStats(statsOptions)?.then((response) => {
    dispatch(myBlockSellerStatsLoaded(response?.data?.data?.myBlockSellerStatistics));
  });

  // Fetch list
  return getMyBlockList(listOptions)?.then((response) => {
    const formattedResponse = response?.data?.data?.auctionItemConnection;
    dispatch(myBlockSellerListLoaded(formattedResponse));
    return formattedResponse;
  });
};

export const processGetMyBlockBuyerList = (
  listOptions: QueryauctionItemConnectionArgs,
  statsOptions: QuerymyBlockBuyerStatisticsArgs,
  dispatch: AppDispatch
) => {
  dispatch(myBlockListIsLoading());

  // Fetch stats
  getMyBlockBuyerStats(statsOptions)?.then((response) => {
    dispatch(myBlockBuyerStatsLoaded(response?.data?.data?.myBlockBuyerStatistics));
  });

  // Fetch list
  return getMyBlockList(listOptions)?.then((response) => {
    const formattedResponse = response?.data?.data?.auctionItemConnection;
    dispatch(myBlockBuyerListLoaded(formattedResponse));
    return formattedResponse;
  });
};
