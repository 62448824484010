import { Auction, MutationauctionItemSellInternalArgs } from 'store/shared/api/graph/interfaces/types';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import Select from 'forms/shared/select';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import { ErrorMessages } from 'constants/errors';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getCompaniesForInternalSale } from 'store/shared/api/graph/queries/internalSaleCompanies';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** A collection of auctions which have sell internally enabled. */
  auctions: Auction[];
  /** Validation messages. */
  errorMessages?: ErrorMessages;
  /** The id of the inventory item. */
  inventoryItemId: string;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when form is submitting. */
  isSubmitting: boolean;
  /** Function invoked on confirm click. */
  onSubmit: (shouldSubmit: boolean, options: Omit<MutationauctionItemSellInternalArgs, 'inventoryItemId'>) => void;
}

interface State {
  /** The id of the selected auction. */
  auctionId: string;
  /** The id of the selected company. */
  companyId: string | undefined;
  /** The current input price. */
  price: number | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      auctionId: '',
      companyId: undefined,
      price: undefined,
    };
  }

  onSubmit = (shouldSubmit: boolean) => {
    const { auctionId, companyId, price } = this.state;
    const { onSubmit } = this.props;

    if (!companyId || !price) {
      return;
    }

    const options: Omit<MutationauctionItemSellInternalArgs, 'inventoryItemId'> = {
      auctionId,
      companyId,
      price,
    };

    onSubmit(shouldSubmit, options);
  };

  render() {
    const { auctionId, companyId, price } = this.state;
    const { auctions, inventoryItemId, isOpen, errorMessages, isSubmitting } = this.props;

    const hasError = (name) => errorMessages && errorMessages.find((err) => err.name === name);
    const actionable = !!(companyId && price !== undefined && !Number.isNaN(price));

    const auctionsDropdownData: SelectOption[] = auctions?.map((auction) => ({
      value: auction.id,
      label: auction.title,
    }));

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel="Confirm"
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="green"
        title="Sell Internally"
      >
        <p className={style.instructions}>Select the buyer to sell the unit internally.</p>
        <fieldset className={style.inputGroup}>
          <Select
            className={style.selectInput}
            onChange={(auction: SelectOption) => this.setState({ auctionId: auction?.value })}
            options={auctionsDropdownData}
            placeholder={t('choose_auction')}
            theme="green"
          />
          <SelectCompany
            connectionVariables={{ auctionId, inventoryItemId }}
            customQueryName="internalSaleCompanies"
            getCompanies={getCompaniesForInternalSale}
            onChange={(company: SelectCompanyOption) => this.setState({ companyId: company?.value })}
            placeholder={t('choose_company')}
          />
          <InputText
            className={style.amount}
            onChange={(value) => this.setState({ price: parseInt(value, 10) })}
            placeholder={t('price')}
            theme={hasError('amount') ? 'error' : undefined}
            type="currency"
          />
        </fieldset>
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
