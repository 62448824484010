import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectUser from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemIfBidAcceptArgs, TransactionType } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { getIfBidCompany } from 'utils/formatting/auctionItemFormatUtils';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Auction item information. */
  auctionItem: AuctionItem;

  /** Error messages. */
  errorMessages?: ErrorMessages;

  /** Whether the dialog is open or not. */
  isOpen: boolean;

  /** Whether user is staff user or not. */
  isStaffUser: boolean;

  /** Callback function to handle on submit event. */
  onSubmit: (shouldSubmit: boolean, options: Partial<MutationauctionItemIfBidAcceptArgs>) => void;

  /** Whether is submitting or not. */
  isSubmitting: boolean;
}

interface State {
  /** Accept message. */
  message?: string;

  /** User id. */
  userId?: string;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: undefined,
      userId: undefined,
    };
  }

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  acceptOffer = (shouldSubmit) => {
    const { message, userId } = this.state;
    const { auctionItem, onSubmit } = this.props;
    const company = getIfBidCompany(auctionItem);
    const options = {
      comment: message,
      consignerId: company?.id,
      userId,
    };
    onSubmit(shouldSubmit, options);
  };

  render() {
    const { auctionItem, errorMessages, isOpen, isStaffUser, isSubmitting } = this.props;
    const { transactionType } = auctionItem;

    const ifBidTimeline = auctionItem?.ifBidTimeline?.list;
    const latestOffer = ifBidTimeline?.[0];
    const buyer = ifBidTimeline?.[ifBidTimeline?.length - 1]?.company;
    const seller = auctionItem?.inventoryItem?.company;
    const hasError = errorMessages && errorMessages?.length > 0;
    const amount = latestOffer?.amount?.formattedAmountRounded;
    let companyId;

    if (isStaffUser) {
      const currentCompany = latestOffer?.company?.id === seller?.id ? buyer : seller;
      companyId = currentCompany?.id;
    }

    let description = '';
    if (transactionType === TransactionType.BUYING) {
      description = t('accept_if_bid_message_buyer', [amount]);
    } else if (transactionType === TransactionType.SELLING) {
      description = t('accept_if_bid_message_seller', [amount]);
    } else if (transactionType === TransactionType.ALL) {
      description = t('accept_if_bid_message_all', [buyer?.name, amount, seller?.name]);
    }

    return (
      <ConfirmDialog
        actionLabel={t('accept')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.acceptOffer}
        theme="green"
        title={t('accept_offer')}
      >
        <h3 className={style.currentOfferAccept}>{amount}</h3>
        <p className={style.instructions}>{description}</p>
        {isStaffUser && (
          <SelectUser
            companyId={companyId}
            hasError={hasError}
            onChange={(user: SelectOption) => {
              this.setState({ userId: user?.value });
            }}
          />
        )}
        <TextArea
          className={style.message}
          onChange={this.onMessageChange}
          placeholder={t('attach_optional_message')}
        />
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage?.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
