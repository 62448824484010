import { getCurrentLocale, Locale } from 'utils/intlUtils';

export enum DateFormat {
  DATE_TIME_FORMAT = 'MMM DD, YYYY - h:mm a',
  DAY_AT_TIME = 'MMM DD, LT',
  DAY_DATE_YEAR_FORMAT = 'ddd, MMM DD, YYYY',
  DEFAULT_TIME_ZONE = 'UTC',
  HOUR_FORMAT = 'hh',
  HOUR_FORMAT_SHORT = 'h',
  HOUR_MINUTE_SEPARATOR = ':',
  MERIDIEM_FORMAT = 'A',
  MINUTE_FORMAT = 'mm',
  MINUTE_FORMAT_SHORT = 'm',
  MONTH_DAY_YEAR_FORMAT = 'MMM DD, YYYY',
  MONTH_DAY_YEAR_LONG_FORMAT = 'MMMM DD, YYYY',
  MONTH_DAY_YEAR_SHORT_FORMAT = 'MMM D, YYYY',
  TIME_24HR_FORMAT = 'HH:mm',
  TIME_FORMAT = 'h:mm a',
  TIME_STAMP_FORMAT = 'hh:mm A',
  TIME_TYPE_FORMAT = 'YYYY-MM-DD hh:mm A',
  YEAR_MONTH_DAY_DASH_FORMAT = 'YYYY-MM-DD',
  YEAR_MONTH_DAY_SLASH_FORMAT = 'YYYY/MM/DD',
  YEAR_MONTH_DAY_UNDERSCORE_FORMAT = 'YYYY_MM_DD',
}

export enum TimeFormat {
  DAY_HOUR_MINUTE = 'd [d] hh [h] mm [m]',
  HOUR_MINUTE_SECOND = 'hh [h] mm [m] ss [s]',
  MINUTE_SECOND = 'mm [m] ss [s]',
}

export const TimerFormats = {
  day: TimeFormat.HOUR_MINUTE_SECOND,
  default: TimeFormat.DAY_HOUR_MINUTE,
  hour: TimeFormat.MINUTE_SECOND,
};

enum DateFormatFrench {
  TIME_FORMAT = DateFormat.TIME_24HR_FORMAT,
}

/**
 * Similar to DateFormat, but fetches a Localised variation if it exists; otherwise defaults to the English default
 *
 * @example LocalisedDateFormat().TIME_FORMAT // "h:mm a" if the current locale is "Locale.EN_CA"
 * @example LocalisedDateFormat().TIME_FORMAT // "HH:mm" if the current locale is "Locale.FR_CA"
 */
export const LocalisedDateFormat = (): typeof DateFormat => {
  const locale = getCurrentLocale();
  const formats = { ...DateFormat };

  if (locale === Locale.FR_CA) {
    Object.keys(DateFormatFrench).forEach((key: string) => {
      formats[key] = DateFormatFrench[key];
    });
  }

  return formats;
};

export enum MeridiemIndicator {
  AM = 'AM',
  PM = 'PM',
}

export type TimeObject = {
  /** Number of hours, currently 12hr clock */
  hour: number;
  /** Number of minutes */
  minute: number;
  /** AM/PM */
  meridiem: MeridiemIndicator;
};
