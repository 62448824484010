import { useMemo } from 'react';

import Loading from 'components/ui/loading/loading';
import ObdiiCodesTooltip from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiCodesTooltip';
import ObdiiScanListItem from 'components/sections/inventoryItem/details/conditionReport/blueDriverScanData/obdiiScanListItem';
import SlideOut from 'components/ui/slideOuts/slideOut';
import {
  BlueDriverCode,
  BlueDriverCodeScanEventType,
  BlueDriverScanStatus,
} from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';
import NoResults from 'components/ui/noResults/noResults';
import Button from 'components/ui/shared/button';

import style from './obdiiSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** The code scan events. */
  blueDriverCodes: BlueDriverCode[];
  /** The status of the scan event. */
  codeScanStatus?: BlueDriverScanStatus | null;
  /** Whether the list is loading or not. */
  isLoading?: boolean;
  /** Whether the slide out is open or not. */
  isOpen?: boolean;
  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
  /** Function invoked when refresh button clicked. */
  onRefresh: () => void;
}

const obdiiCodesTooltip = <ObdiiCodesTooltip />;

const ObdiiSlideOut = ({ blueDriverCodes, codeScanStatus, isLoading, isOpen, onClose, onRefresh }: Props) => {
  /**
   * Slide out content rendered when scan has pending status.
   */
  const renderScanPendingPlaceholder = useMemo(() => {
    return (
      <NoResults title={t('scan_pending')}>
        <div className={style.message}>{t('obdii_scan_pending_message')}</div>
        <Button className={style.refreshButton} onClick={onRefresh} theme="blue">
          {t('refresh')}
        </Button>
      </NoResults>
    );
  }, [onRefresh]);

  const scanDetails = useMemo(() => {
    if (!blueDriverCodes || isLoading) {
      return null;
    }

    const scanEventsList = Object.values(BlueDriverCodeScanEventType).map((scanEventType) => {
      return blueDriverCodes
        .filter((blueDriverCode) => blueDriverCode.type === scanEventType)
        .map((blueDriverCode) => (
          <li key={`${blueDriverCode?.type}-${blueDriverCode?.code}`} className={style.listItem}>
            <ObdiiScanListItem blueDriverCode={blueDriverCode} />
          </li>
        ));
    });

    return (
      <div className={style.container}>
        <ul className={style.list}>{scanEventsList}</ul>
      </div>
    );
  }, [blueDriverCodes, isLoading]);

  return (
    <SlideOut headerActions={obdiiCodesTooltip} isOpen={isOpen} onClose={onClose} title={t('obdii')}>
      {codeScanStatus === BlueDriverScanStatus.PENDING && renderScanPendingPlaceholder}
      {codeScanStatus !== BlueDriverScanStatus.PENDING && <div className={style.scanDetails}>{scanDetails}</div>}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default ObdiiSlideOut;
