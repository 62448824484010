import { useEffect } from 'react';

import User from 'constants/user';
import { AuctionEventTimelineEntry, AuctionEventTimelineEntryAudience } from 'store/shared/api/graph/interfaces/types';
import { QUERY } from 'components/sections/auctionItem/details/events/events';
import { client } from 'store/shared/apollo/apolloClient';
import { onLiveAuctionItemTimelineEvent } from 'store/auctionItemsList/auctionItemsEvents';

interface Props {
  /** The active AuctionTimeSlotLaneId's `id` */
  auctionTimeSlotLaneId: string | undefined;
  /** The designated audience to retrieve messages */
  audience: AuctionEventTimelineEntryAudience[];
  /** The logged-in user */
  user: User;
}

const AuctionItemEventsIOUpdater = ({ auctionTimeSlotLaneId, audience, user }: Props) => {
  useEffect(() => {
    /**
     * Listens for new `AuctionEventTimelineEntry`s from PubNub, and adds them to the redux store
     */
    const timelineEventStream = onLiveAuctionItemTimelineEvent((item) => {
      if (item?.auctionTimeSlotLaneId !== auctionTimeSlotLaneId) {
        // Skip timeline update on unrelated timeline events
        return;
      }

      // Filter by designated audience entries
      const filteredEntries = item?.entries?.filter((entry) => audience.includes(entry?.audience));

      // Map PubNub response to AuctionEventTimelineEntry[]
      const formattedEntries: AuctionEventTimelineEntry[] = filteredEntries?.map((entry) => ({
        __typename: 'AuctionEventTimelineEntry',
        auctionItemId: entry?.auctionItemId,
        auctionTimeSlotLaneId,
        created: entry?.created,
        id: entry?.id,
        message: entry?.message?.find((message) => message?.locale === user?.locale)?.content,
        style: entry?.style,
      }));

      if (formattedEntries?.length) {
        // Push timeline update if we have new entries to add
        const query = QUERY;
        const variables = { auctionTimeSlotLaneId };
        const queryCache = client.readQuery({ query, variables });

        const auctionEventTimeline = formattedEntries
          // Filter out any duplicate timeline entries
          ?.reduce((events, event) => {
            if (events?.find(({ id }) => event?.id === id)) {
              return events;
            }
            return [event, ...events];
          }, queryCache.auctionEventTimeline ?? [])
          // Sort by creation date
          ?.sort((a, b) => b?.created - a?.created);

        const queryCacheNext = { auctionEventTimeline };
        client.writeQuery({ query, variables, data: queryCacheNext });
      }
    });

    return () => {
      timelineEventStream?.cancel();
    };
  }, [auctionTimeSlotLaneId, audience, user?.locale]);

  return undefined;
};

export default AuctionItemEventsIOUpdater;
