import classnames from 'classnames';
import { useId } from 'react';

import Tooltip from 'components/ui/shared/tooltips/tooltip';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { getSaleLightsInOrder } from 'components/ui/shared/saleLights/saleLightsButton';
import { t } from 'utils/intlUtils';

import style from './saleLights.scss';

const SaleLightTooltipTextMap = {
  [SaleLight.GREEN]: 'arbitration_eligible',
  [SaleLight.RED]: 'arbitration_limited',
  [SaleLight.YELLOW]: 'arbitration_eligible',
};

interface SingleSaleLightProps {
  isSelected?: boolean;
  /** True when the SaleLight should be visible, but grayed out */
  isDisabled?: boolean;
  /** CSS styling to override default sale light style */
  saleLightClassName?: string;
  /** Determines which variant of a SaleLight to render */
  variant: SaleLight;
}

export const SingleSaleLight = ({
  isSelected = true,
  isDisabled = false,
  saleLightClassName,
  variant: saleLight,
}: SingleSaleLightProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(
          style.saleLight,
          saleLightClassName,
          isSelected && style[saleLight.toLowerCase()],
          isDisabled && style.isDisabled
        )}
        data-testid={`sale-light-${saleLight.toLowerCase()}`}
        data-tooltip-id={id}
      >
        {t(`${saleLight.toLowerCase()}_abbreviation`)}
      </div>
      <Tooltip key={`sale-light-${saleLight}`} anchorId={id} delayShow={1000} showTooltip={!isDisabled}>
        {t(SaleLightTooltipTextMap[saleLight])}
      </Tooltip>
    </>
  );
};

interface Props {
  /** CSS styling to override default container style */
  className?: string;
  /** The collection of sale lights to be displayed on the list item. */
  saleLights: SaleLight[] | undefined;
  /** CSS styling to override default sale light style */
  saleLightClassName?: string;
}

const SaleLights = ({ className, saleLights, saleLightClassName }: Props) => {
  if (!saleLights?.length) {
    return null;
  }

  return (
    <div className={classnames(style.container, className)} data-testid="sale-lights">
      {getSaleLightsInOrder(saleLights).map((saleLight: SaleLight) => (
        <SingleSaleLight key={`sale-light-${saleLight}`} saleLightClassName={saleLightClassName} variant={saleLight} />
      ))}
    </div>
  );
};

export default SaleLights;
