import ApiRequest from 'store/shared/api/apiRequest';
import { Company } from 'store/shared/api/graph/interfaces/types';
import { getRequestData } from 'utils/apiUtils';

export const getCompany = ({ id = null }) =>
  ApiRequest<{ company: Company }>({
    data: {
      query: `
        {
          company(companyId: "${id}") {
            id
            name
            primaryLocation {
              address1
              address2
              city
              name
              regionCode
              zipCode
            }
          }
        }
      `,
    },
  });

export const getCompanyReportGroups = ({ companyId = null }) =>
  ApiRequest<{ company: Pick<Company, 'reportGroups'> }>({
    data: {
      query: `
      query($companyId: String!) {
        company(companyId: $companyId) {
          reportGroups {
            id
            name
            questions {
              id
              name
              description
              parameters {
                parameterName
                name
                required
                type
                selectedValues {
                  name
                  value
                  selected
                }
                description
              }
            }
          }
        }
      }
    `,
      variables: {
        companyId,
      },
    },
  });

export const getCompanyReport = async ({ reportId, fileType = 'json', ...props }) => {
  let binOptions = {};

  if (fileType === 'xlsx') {
    binOptions = { responseType: 'blob' };
  }

  return getRequestData<Blob>({
    ...binOptions,
    data: JSON.stringify({ ...props }),
    url: `${process.env.REACT_APP_GRAPH_REPORTS}/report/${reportId}.${fileType}`,
    method: 'POST',
  });
};
