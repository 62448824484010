import Button from 'components/ui/shared/button';
import { t } from 'utils/intlUtils';

import style from './notAvailable.scss';

interface Props {
  /** Header text. If not provided, 'Auction item not available' will be rendered.*/
  headerText?: string;
  /** Sub-header text. If not provided, 'Auction item not available' will be rendered.*/
  subHeaderText?: string;
  /** Function invoked on close button click. */
  onClose: () => void;
}

const NotAvailable = ({ headerText, subHeaderText, onClose }: Props) => (
  <div className={style.notAvailable} data-testid="not-available">
    <h3>{headerText || t('auction_item_not_available')}</h3>
    <p>{subHeaderText || t('auction_item_not_available_message')}</p>
    <Button onClick={onClose} theme="blue">
      {t('close')}
    </Button>
  </div>
);

export default NotAvailable;
