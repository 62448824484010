import ServerRecord from 'store/shared/models/serverRecord';
import { Broadcast } from 'store/shared/api/graph/interfaces/types';

interface BroadcastDetailsModel {
  details: Broadcast | null;
}

export const InitialState = ServerRecord<BroadcastDetailsModel>({
  details: null,
});
