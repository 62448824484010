import DatadogLogger from 'logging/DatadogLogger';
import GTMLogger from 'logging/GTMLogger';
import SentryLogger from 'logging/SentryLogger';
import User from 'constants/user';
import { LooseObject } from 'constants/objects';
import { formatUserName } from 'utils/stringUtils';

/**
 * A common logger class to trigger shared actions to various loggers
 */
class Logger {
  /**
   * Set User
   */
  static setUser(user: User) {
    const formattedUser = {
      id: user?.id,
      email: user?.email,
      name: formatUserName(user),
    } as User & { name: string };

    DatadogLogger.setUser(formattedUser);
    GTMLogger.setUser(formattedUser);
    SentryLogger.setUser(formattedUser);
  }

  /**
   * Set User
   */
  static clearUser() {
    DatadogLogger.clearUser();
    GTMLogger.clearUser();
    SentryLogger.clearUser();
  }

  /**
   * Track a user action.
   * A centralized function that dispatches the user action across multiple loggers.
   *
   * @param actionName
   * @param attributes
   */
  static trackUserAction(actionName: string, attributes?: LooseObject) {
    DatadogLogger.trackUserAction(actionName, attributes);
    GTMLogger.trackUserAction(actionName, attributes);
    SentryLogger.trackUserAction(actionName, attributes);
  }
}

export default Logger;
