import { ComponentProps, memo, useMemo } from 'react';

import Chip from 'components/ui/chip/chip';
import { IfBidStatus } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './offerStatusLabel.scss';

export enum ExtendedIfBidStatus {
  COUNTERED = 'COUNTERED',
}

export type OfferStatus = IfBidStatus | ExtendedIfBidStatus;

interface Props {
  /** Current timed offer status */
  offerStatus: OfferStatus;
}

const OfferStatusLabel = ({ offerStatus }: Props) => {
  const label = useMemo<string | null>(() => {
    switch (offerStatus) {
      case IfBidStatus.ACCEPTED:
        return t('if_bid_type_accepted');

      case IfBidStatus.EXPIRED:
        return t('if_bid_status_offer_expired');

      case IfBidStatus.DECLINED:
        return t('if_bid_status_declined');

      case ExtendedIfBidStatus.COUNTERED:
        return t('if_bid_status_countered');

      default:
        return null;
    }
  }, [offerStatus]);

  const color = useMemo<ComponentProps<typeof Chip>['color']>(() => {
    switch (offerStatus) {
      case IfBidStatus.ACCEPTED:
        return 'green';

      case IfBidStatus.EXPIRED:
      case IfBidStatus.DECLINED:
        return 'red-dark';

      case ExtendedIfBidStatus.COUNTERED:
        return 'blue';

      default:
        return 'default';
    }
  }, [offerStatus]);

  if (!label) {
    return null;
  }

  return <Chip className={style.chip} color={color} label={label} />;
};

export default memo(OfferStatusLabel);
