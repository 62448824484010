import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { Carrier, InitialState } from './carrierDetailsModels';
import {
  isLoaded,
  isNotUpdating,
  isUpdating,
  preloadCarrierItem,
  setCarrierData,
  updateCarrierItem,
} from './carrierDetailsActions';

export const carrierDetailsReducer = handleActions(
  {
    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [isLoaded().type]: (state, action) => {
      const {
        carrier,
        transportConnection: { facetGroups },
      } = action.payload;
      return state.setLoaded().merge({
        details: new Carrier(carrier),
        transportJobs: List(facetGroups.map((facetGroupData) => facetGroupData.facets).flat()),
      });
    },

    [preloadCarrierItem().type]: (state, action) => {
      if (action.payload) {
        const carrier = new Carrier(action.payload);
        return state.setLoaded().set('details', carrier);
      }
      return state;
    },

    [setCarrierData().type]: (state, action) => {
      const { data, path } = action.payload;
      return state.setIn(['details'].concat(path), data);
    },

    [updateCarrierItem().type]: (state, action) => {
      const details = state.details.toJS();

      return state.merge({
        details: new Carrier({
          ...details,
          ...action.payload,
        }),
      });
    },
  },
  new InitialState()
);
