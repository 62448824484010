import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useState } from 'react';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import EditButton from 'components/ui/shared/buttons/editButton';
import InventoryItem from 'constants/inventoryItem';
import SelectLocations from 'forms/shared/selectLocations';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { Location, LocationConnectionMode } from 'store/shared/api/graph/interfaces/types';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserAction } from 'logging/analytics/events/userActions';
import { auctionItemUpdateInventoryItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';
import { updateAuctionLocation } from 'store/inventoryItemDetails/inventoryItemDetailsApi';
import { updateInventoryItem as updateInventoryItemAction } from 'store/inventoryItemDetails/inventoryItemDetailsActions';

import style from './auctionLocationRow.scss';

const stateConnect = (state: AppState) => ({
  /** AuctionItem details */
  auctionItemDetails: state.app.auctionItemDetails?.toJS(),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to update the auction item's inventory item */
  updateAuctionItemInventoryItem: (inventoryItem: InventoryItem) =>
    dispatch(auctionItemUpdateInventoryItem(inventoryItem)),
  /** Callback function to update inventory item */
  updateInventoryItem: (inventoryItem: Partial<InventoryItem>) => dispatch(updateInventoryItemAction(inventoryItem)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Inventory item */
  inventoryItem: InventoryItem | null;
}

const AuctionLocationRow = ({
  auctionItemDetails,
  updateAuctionItemInventoryItem,
  updateInventoryItem,
  ...props
}: Props) => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const isAuctionItem = !!auctionItemDetails?.details;
  const inventoryItem = isAuctionItem ? auctionItemDetails?.details?.inventoryItem : props.inventoryItem;

  /**
   * Track user action
   */
  const trackUserAction = useCallback(
    (userAction: UserAction) => {
      if (isAuctionItem) {
        trackUserActionWithAuctionItemAttributes(userAction, auctionItemDetails?.details);
      } else {
        trackUserActionWithInventoryItemAttributes(userAction, inventoryItem);
      }
    },
    [auctionItemDetails?.details, isAuctionItem, inventoryItem]
  );

  /**
   * Open dialog on edit button click
   */
  const onEditButtonClick = useCallback(() => {
    trackUserAction(UserAction.VDP_AUCTION_LOCATION_EDIT_CLICK);
    setErrorMessages([]);
    setSelectedLocationId(inventoryItem?.auctionLocation?.id);
    setIsDialogOpen(true);
  }, [inventoryItem?.auctionLocation?.id, trackUserAction]);

  /**
   * Submit auction location
   */
  const onSubmit = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        const options = {
          inventoryItemId: inventoryItem?.id,
          auctionLocationId: inventoryItem?.auctionLocation?.id && !selectedLocationId ? '' : selectedLocationId,
        };

        trackUserAction(UserAction.VDP_AUCTION_LOCATION_EDIT_SAVE_CLICK);
        setIsSubmitting(true);
        updateAuctionLocation(options)
          .then((response) => {
            const auctionLocation = response?.data?.data?.inventoryItemUpdate?.vehicle?.auctionLocation;
            // Update entity's `inventoryItem.auctionLocation` based on whether it's an `AuctionItem` or `InventoryItem`
            if (isAuctionItem && inventoryItem) {
              updateAuctionItemInventoryItem({ ...inventoryItem, auctionLocation });
            } else {
              updateInventoryItem({ auctionLocation });
            }

            // Details view updated; close dialog
            setIsDialogOpen(false);
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setIsDialogOpen(false);
      }
    },
    [
      inventoryItem,
      isAuctionItem,
      selectedLocationId,
      trackUserAction,
      updateAuctionItemInventoryItem,
      updateInventoryItem,
    ]
  );

  if (!inventoryItem?.isUpdatable && !inventoryItem?.auctionLocation?.name) {
    // Hide the row if the entity isn't eligible for editing AND its AuctionLocation isn't set
    return null;
  }

  return (
    <Row name={t('auction_location')}>
      <div className={style.content}>
        {inventoryItem?.auctionLocation?.name || '-'}
        {inventoryItem?.isUpdatable && (
          <EditButton className={style.editButton} onClick={onEditButtonClick} theme="blue-pencil" />
        )}
      </div>
      <ConfirmDialog
        actionLabel={t('save')}
        actionProgress={isSubmitting}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={onSubmit}
        theme="blue"
        title={t('modify_auction_location')}
      >
        <FormErrors errorMessages={errorMessages} isSmallDialog />
        <SelectLocations
          companyId={inventoryItem?.company?.id}
          connectionVariables={{ mode: LocationConnectionMode.COMPOUNDS_ONLY }}
          defaultValue={inventoryItem?.auctionLocation}
          isClearable
          onLocationChange={(auctionLocation: Location) => setSelectedLocationId(auctionLocation?.id)}
          placeholder={t('choose_location')}
          showCompoundSelectionOption={false}
          theme="blue"
        />
      </ConfirmDialog>
    </Row>
  );
};

export default connector(AuctionLocationRow);
