import { MouseEventHandler, useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import User from 'constants/user';
import WatchedBySlideOut from 'components/sections/auctionItem/details/status/statusRows/watchedByRow/watchedBySlideOut';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserAction } from 'logging/analytics/events/userActions';
import { getAuctionItemWatchers } from 'store/auctionItemDetails/auctionItemDetailsApi';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './watchedByRow.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** The current user. */
  user: User;
}

const WatchedByRow = ({ auctionItem, user }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState<boolean>(false);
  const [watchers, setWatchers] = useState<User[]>([]);

  /**
   * Get auction item watchers.
   */
  const getWatchers = useCallback(() => {
    getAuctionItemWatchers({ auctionItemId: auctionItem.id })
      ?.then((response) => setWatchers(response?.data?.data?.auctionItem?.watchers?.list?.filter(Boolean) || []))
      ?.finally(() => setIsLoading(false));
  }, [auctionItem.id]);

  /**
   * Close slideOut
   */
  const onCloseSlideOut = useCallback(() => {
    setIsSlideOutOpen(false);
  }, []);

  /**
   * Open slide out on row click
   */
  const onOpenSlideOut = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setIsLoading(true);
    setIsSlideOutOpen(true);
    getWatchers();
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_WATCHED_BY_CLICK, auctionItem);
  }, [auctionItem, getWatchers]);

  return (
    <>
      <Row
        className={style.watchedByRow}
        dataTestId="status-row-watched-by"
        name={t('watched_by')}
        onClick={onOpenSlideOut}
      />
      <WatchedBySlideOut
        isLoading={isLoading}
        isOpen={isSlideOutOpen}
        onClose={onCloseSlideOut}
        user={user}
        watchers={watchers}
      />
    </>
  );
};

export default WatchedByRow;
