import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import Overlay from 'components/sections/auctionItem/operations/buyNow/overlay';
import { AppState } from 'store/configureStore';
import { AuctionItemDetailsPropsJs } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { AuctionItemFormat, AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './buyNow.scss';

export enum BuyNowVariant {
  ITEM_LIST = 'AuctionItemList',
  DETAILS = 'AuctionDetails',
}

/**
 * Returns user tracking action for different variants
 */
export const getUserTrackingAction = (variant: BuyNowVariant, route: Route) => {
  switch (variant) {
    case BuyNowVariant.DETAILS:
      return {
        click: UserAction.VDP_BUY_NOW_CLICK,
        cancel: UserAction.VDP_BUY_NOW_CANCEL_CLICK,
        confirm: UserAction.VDP_BUY_NOW_CONFIRM_CLICK,
      };

    case BuyNowVariant.ITEM_LIST:
    default:
      if (route === Route.BUY_BUY_NOW) {
        return {
          click: UserAction.BUY_NOW_LIST_BUY_NOW_CLICK,
          cancel: UserAction.BUY_NOW_LIST_BUY_NOW_CANCEL_CLICK,
          confirm: UserAction.BUY_NOW_LIST_BUY_NOW_CONFIRM_CLICK,
        };
      }

      return {
        click: UserAction.TIMED_AUCTION_LIST_BUY_NOW_CLICK,
        cancel: UserAction.TIMED_AUCTION_LIST_BUY_NOW_CANCEL_CLICK,
        confirm: UserAction.TIMED_AUCTION_LIST_BUY_NOW_CONFIRM_CLICK,
      };
  }
};

interface Props extends RouterProps {
  /** The auction item details. */
  auctionItem?: AuctionItemDetailsPropsJs;
  /** CSS styling to override default overlay style. */
  overlayClassName?: string;
  /** Different variants of the Buy Now overlays */
  variant: BuyNowVariant;
}

const BuyNow = ({ auctionItem, location: { pathname }, overlayClassName, variant }: Props) => {
  const user = useSelector((state: AppState) => state.app.user);
  const userTrackingAction = useMemo(() => getUserTrackingAction(variant, pathname as Route), [variant, pathname]);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const isAuthorized = isAuctionStaff(user, auctionItem?.auction?.id) || !auctionItem?.isMyItem;

  /**
   * Handles buy now action - Opens overlay for confirmation
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault();

      setShowOverlay(true);
      trackUserActionWithAuctionItemAttributes(userTrackingAction.click, auctionItem);
    },
    [auctionItem, userTrackingAction.click]
  );

  /**
   * Handles cancel action - Closes overlay
   */
  const handleOnCancel = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault?.();

      setShowOverlay(false);
      trackUserActionWithAuctionItemAttributes(userTrackingAction.cancel, auctionItem);
    },
    [auctionItem, userTrackingAction.cancel]
  );

  // Do not display `Buy Now` button if any of the follow conditions are true
  if (
    !isAuthorized ||
    !auctionItem ||
    ![AuctionItemFormat.APPRAISAL, AuctionItemFormat.GROUNDED, AuctionItemFormat.TIMED_OFFER].includes(
      auctionItem.format
    ) ||
    auctionItem.status !== AuctionItemStatus.LIVE ||
    !auctionItem.buyNowPrice ||
    auctionItem.buyNowPrice.amount <= (auctionItem.topOffer?.amount.amount || 0) ||
    auctionItem._ended
  ) {
    return null;
  }

  return (
    <>
      {showOverlay && (
        <Overlay auctionItem={auctionItem} className={overlayClassName} onCancel={handleOnCancel} variant={variant} />
      )}
      <OperationButton className={style.button} dataTestId="buyNow-button" onClick={handleOnClick} theme="yellow">
        {t('buy_now_x', [auctionItem.buyNowPrice.formattedAmountRounded])}
      </OperationButton>
    </>
  );
};

export default withRouter(BuyNow);
