import { useState, MouseEventHandler, useCallback } from 'react';

import PotentialBuyersSlideOut from 'components/sections/auctionItem/details/status/statusRows/potentialBuyersRow/potentialBuyersSlideOut';
import User from 'constants/user';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserAction } from 'logging/analytics/events/userActions';
import { getAuctionItemPotentialBuyers } from 'store/auctionItemDetails/auctionItemDetailsApi';
import { getErrors } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItemDetailsProps;
}

const PotentialBuyersRow = ({ auctionItem }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [potentialBuyers, setPotentialBuyers] = useState<User[]>([]);
  const { setConfig } = useGlobalDialog();

  /**
   * Get auction item potential buyers
   */
  const getPotentialBuyers = useCallback(async () => {
    const response = await getAuctionItemPotentialBuyers({ auctionItemId: auctionItem.id })
      .catch((error) => {
        setConfig({ errorsOverride: getErrors(error) });
        setIsOpen(false);
        setPotentialBuyers([]);
      })
      .finally(() => setIsLoading(false));

    if (response) {
      setPotentialBuyers(response.data.data?.auctionItem.potentialBuyers?.filter(Boolean) || []);
    } else {
      setPotentialBuyers([]);
    }
  }, [auctionItem.id, setConfig]);

  /**
   * Handles slide out close
   */
  const onSlideOutClose = useCallback(() => setIsOpen(false), []);

  /**
   * Open slide out on row click
   */
  const onRowClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setIsLoading(true);
    setIsOpen(true);
    getPotentialBuyers();
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_POTENTIAL_BUYERS_CLICK, auctionItem);
  }, [auctionItem, getPotentialBuyers]);

  return (
    <>
      <Row dataTestId="status-row-potential-buyers" name={t('potential_buyers')} onClick={onRowClick} />
      <PotentialBuyersSlideOut
        buyers={potentialBuyers}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onSlideOutClose}
      />
    </>
  );
};

export default PotentialBuyersRow;
