import classnames from 'classnames';
import { Link } from 'react-router';
import { useCallback } from 'react';

import ArrowGlyph from 'glyphs/pagination-arrow.svg';

import Sprite from 'components/ui/shared/sprite';
import { FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { Location } from 'constants/reactRouter';
import { formatNumber } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './listPagination.scss';

interface Props {
  /** Current URL information. */
  location: Location<any>;
  /** Faceted pagination information. */
  pageInfo: FacetedPageInfo | null;
  /**
   * A custom field name override for the `after` pagination argument
   *
   * @default 'after'
   */
  queryNameAfter?: string;
  /**
   * A custom field name override for the `before` pagination argument
   *
   * @default 'before'
   */
  queryNameBefore?: string;
}

const ListPagination = ({ pageInfo, location, queryNameAfter = 'after', queryNameBefore = 'before' }: Props) => {
  const getPaginationQuery = useCallback(
    (pagination, ignoredKey) => ({
      ...Object.keys(location.query).reduce((obj, key) => {
        const paginationQuery = { ...obj };
        if (key !== ignoredKey) {
          paginationQuery[key] = location.query[key];
        }
        return paginationQuery;
      }, {}),
      ...pagination,
    }),
    [location.query]
  );

  if (!pageInfo) {
    return null;
  }

  const { startCursor, endCursor, hasNextPage, hasPreviousPage, totalEdges } = pageInfo;
  const prevLinkClassNames = classnames(style.link, hasPreviousPage && style.previous);
  const prevQuery = getPaginationQuery({ [queryNameBefore]: startCursor }, queryNameAfter);
  const nextLinkClassNames = classnames(style.link, hasNextPage && style.next);
  const nextQuery = getPaginationQuery({ [queryNameAfter]: endCursor }, queryNameBefore);
  const start = startCursor && formatNumber(startCursor.toString());
  const end = endCursor && formatNumber(endCursor.toString());
  const total = formatNumber(totalEdges.toString());

  return (
    <div className={style.pagination} data-testid="list-pagination">
      {prevQuery && (
        <Link className={prevLinkClassNames} to={{ ...location, query: prevQuery }}>
          <Sprite glyph={ArrowGlyph} /> {t('prev')}
        </Link>
      )}
      <span>{t('showing_x_to_y_out_of_z_results', [start, end, total])}</span>
      {nextQuery && (
        <Link className={nextLinkClassNames} to={{ ...location, query: nextQuery }}>
          {t('next')} <Sprite glyph={ArrowGlyph} />
        </Link>
      )}
    </div>
  );
};

export default ListPagination;
