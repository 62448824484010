import classnames from 'classnames';
import { ActionMeta, InputActionMeta, OnChangeValue, PropsValue } from 'react-select';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Select, { SelectProps } from 'forms/shared/select';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { customOptionFiltering } from 'utils/selectUtils';
import { formatUserName } from 'utils/stringUtils';
import { getUsers } from 'store/shared/api/graph/queries/users';
import { parseQueryParams } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';

import style from './select.scss';

export interface SelectUserProps<IsMulti extends boolean = false> extends SelectProps<SelectOption, IsMulti> {
  /** Company id. */
  companyId?: string;
  /** Add default variable values (IE: type:HOLDING - which queries only holding companies) */
  connectionVariables?: object;
  /** Whether to include the default bottom margin. */
  hasBottomMargin?: boolean;
  /** Callback when the value changes. */
  onChange?: (options: OnChangeValue<SelectOption, IsMulti> | null, actionMeta?: ActionMeta<SelectOption>) => void;
}

const SelectUser = <IsMulti extends boolean = false>({
  className,
  companyId,
  connectionVariables,
  hasBottomMargin = true,
  onChange,
  placeholder,
  value,
  ...props
}: SelectUserProps<IsMulti>) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<PropsValue<SelectOption> | undefined>(value);
  const prevCompanyId = usePrevious(companyId);

  /**
   * On mount, fetch the user options.
   */
  useMountEffect(() => {
    getUserOptions();
    setMounted(true);
    return () => setMounted(false);
  });

  /**
   * When the value changes, update the selected value.
   */
  const onSelectionChange = useCallback<NonNullable<SelectUserProps<IsMulti>['onChange']>>(
    (selectedOptions, actionMeta) => {
      setSelectedValue(selectedOptions);
      onChange?.(selectedOptions, actionMeta);
    },
    [onChange]
  );

  /**
   * Fetch the user options.
   */
  const getUserOptions = useCallback(
    (keyword: string = '') => {
      const queryOption = { keyword, consignerId: companyId };

      getUsers?.(parseQueryParams({ ...connectionVariables, ...queryOption }))?.then((response) => {
        const users = response?.data?.data?.userConnection?.edges?.map((userEdge) => userEdge?.node);
        const userOptions =
          users?.map<SelectOption>((user) => ({
            value: user?.id,
            label: formatUserName(user) || '',
          })) || [];

        setOptions(userOptions);
      });
    },
    [companyId, connectionVariables]
  );

  /**
   * Debounce the search input.
   */
  const onInputChange = useMemo(
    () =>
      debounce((keyword: string, inputActionMeta: InputActionMeta) => {
        if (inputActionMeta.action === 'input-change') {
          getUserOptions(keyword);
        }
      }, 250),
    [getUserOptions]
  );

  /**
   * When the company id changes, reset the selected value.
   */
  useEffect(() => {
    if (isMounted && companyId !== prevCompanyId) {
      onSelectionChange(null);
      getUserOptions();
    }
  }, [companyId, getUserOptions, isMounted, onSelectionChange, prevCompanyId]);

  return (
    <Select
      theme="green"
      {...props}
      className={classnames(style.selectInput, { [style.hasBottomMargin]: hasBottomMargin }, className)}
      filterOption={customOptionFiltering}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder ?? t('choose_user')}
      value={selectedValue}
    />
  );
};

export default SelectUser;
