import classnames from 'classnames';
import { HTMLAttributes } from 'react';

import Button from 'components/ui/shared/button';

import style from './stepsProgress.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The current active step */
  currentStep?: number;
  /** The amount of available steps */
  steps: number;
  /** Function to set the `currentStep` value */
  onSetStep?: (index: number) => void;
  /**
   * The colour theme
   * @default 'green'
   */
  theme?: 'blue' | 'green';
}

const StepsProgress = ({ currentStep, onSetStep, steps, theme = 'green', ...props }: Props) => (
  <div {...props} className={classnames(style.container, props?.className)}>
    <div className={style.steps}>
      {Array(steps)
        .fill('')
        .map((step, index) => {
          const isActiveStep = currentStep === index + 1;

          return (
            <Button
              key={`step-${index}`}
              className={classnames(
                style.step,
                isActiveStep && style[`step-theme-${theme}`],
                isActiveStep && style.activeStep
              )}
              dataTestId={`step-${index + 1}`}
              onClick={() => onSetStep?.(index + 1)}
              theme="none"
            >
              {index + 1}
            </Button>
          );
        })}
    </div>
  </div>
);

export default StepsProgress;
