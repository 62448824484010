import { concat } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { ConversationMessage, ConversationType } from 'store/shared/api/graph/interfaces/types';
import {
  AUCTIONEER_CONVERSATION_ID,
  ConversationMap,
  ConversationState,
  InitialState,
  UserNamesMap,
} from 'store/chat/chatModels';
import {
  addChatUserNames,
  addConversation,
  clearChatUserNames,
  clearUserOnlineStatuses,
  messageAdded,
  setConversations,
  setConversationsListening,
  setConversationsMessagesLoaded,
  setCurrentConversationId,
  setCurrentConversationMessages,
  setErrorState,
  setUnreadMessagesStale,
  setUserOnlineStatus,
  updateConversationsInActive,
} from 'store/chat/chatActions';
import { representsCompany } from 'utils/userUtils';

/**
 * Exported so that we can reuse in our mock for the chat actions
 *
 * @param state
 * @param action
 */
const handleAddMessage = (state, action) => {
  const newMessage: ConversationMessage = {
    ...action.payload.message,
  };

  const conversationForMessage: ConversationState = state.conversations[action.payload.conversationId];

  /**
   * If this message is for the current conversation, or this is the auctioneer conversation or
   * if this is a broadcast conversation, then add to the current conversation messages
   */
  if (
    conversationForMessage?.id === state.currentConversationId ||
    conversationForMessage.type === ConversationType.LIVE_LANE_BROADCAST ||
    state.currentConversationId === AUCTIONEER_CONVERSATION_ID
  ) {
    // Avoid duplicates - check to see if message already exists
    if (state.currentConversationMessages?.find((message) => message?.id === newMessage?.id)) {
      return state;
    }

    return state.set('currentConversationMessages', concat(state.currentConversationMessages, [newMessage]));
  }

  // Force conversation messages to be reloaded from Twilio if there's an incoming message
  if (conversationForMessage && conversationForMessage.messagesLoaded) {
    const conversations: ConversationMap = {
      ...state.conversations,
      [conversationForMessage.id]: {
        ...conversationForMessage,
        messagesLoaded: false,
      },
    };

    return state.set('conversations', conversations);
  }

  return state;
};

export const chatReducer = handleActions(
  {
    [addChatUserNames().type]: (state, action) => {
      const userNamesMap: UserNamesMap = {
        ...state.userNamesMap,
        ...action.payload,
      };

      return state.set('userNamesMap', userNamesMap);
    },

    [setErrorState().type]: (state, action) => {
      return state.set('errorState', action.payload);
    },

    [clearChatUserNames().type]: (state) => state?.set('userNamesMap', {}),

    [messageAdded().type]: handleAddMessage,

    [setConversations().type]: (state, action) => {
      const conversationMap: ConversationMap = {};
      action.payload?.forEach((conversation: ConversationState) => {
        conversationMap[conversation.id] = {
          ...conversation,
          messages: [], // Messages loaded only when conversation is made active
        };
      });
      return state.set('conversations', conversationMap);
    },

    [addConversation().type]: (state, action) => {
      const conversations: ConversationMap = {
        ...state.conversations,
        [action.payload.id]: {
          ...action.payload,
          messages: [], // Messages loaded only when conversation is made active
        },
      };

      return state.set('conversations', conversations);
    },

    [setConversationsListening().type]: (state, action) => {
      const conversations: ConversationMap = { ...state.conversations };
      action.payload.conversationIds.forEach((conversationId: string) => {
        const conversation: ConversationState = conversations[conversationId];
        conversations[conversationId] = {
          ...conversation,
          listening: true,
        };
      });

      return state.set('conversations', conversations);
    },

    [setConversationsMessagesLoaded().type]: (state, action) => {
      const conversations: ConversationMap = { ...state.conversations };
      action.payload.conversationIds.forEach((conversationId: string) => {
        const conversation: ConversationState = conversations[conversationId];
        conversations[conversationId] = {
          ...conversation,
          messagesLoaded: action.payload.messagesLoaded,
        };
      });

      return state.setLoaded().set('conversations', conversations);
    },

    [setCurrentConversationMessages().type]: (state, action) => {
      return state.set('currentConversationMessages', action.payload.messages);
    },

    [setCurrentConversationId().type]: (state, action) => {
      return state.set('currentConversationId', action.payload);
    },

    [setUnreadMessagesStale().type]: (state, action) => {
      return state.set('unreadMessagesStale', action.payload);
    },

    [setUserOnlineStatus().type]: (state, action) => {
      const userOnlineStatusMap = { ...state.userOnlineStatusMap };
      userOnlineStatusMap[action.payload.userId] = action.payload.onlineStatus;
      return state.set('userOnlineStatusMap', userOnlineStatusMap);
    },

    [updateConversationsInActive().type]: (state, action) => {
      const conversations: ConversationMap = { ...state.conversations };
      Object.values(conversations).forEach((conversation: ConversationState) => {
        conversations[conversation.id] = {
          ...conversation,
          inactive: conversation.customer
            ? !representsCompany(conversation.customer, action.payload.inventoryItem?.company?.id)
            : conversation.inactive,
        };
      });

      return state.set('conversations', conversations);
    },

    [clearUserOnlineStatuses().type]: (state) => state?.set('userOnlineStatusMap', {}),
  },
  new InitialState()
);
