import SaleLights from 'components/ui/lists/listItem/saleLights';
import { RunNumberBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';

import style from './runNumberAndSaleLights.scss';

interface Props {
  runNumber?: string;
  saleLights?: SaleLight[];
}

const RunNumberAndSaleLights = ({ runNumber, saleLights }: Props) => {
  return (
    <div className={style.labelContainer}>
      {!!runNumber && <RunNumberBadge className={style.runNumber} text={runNumber} />}
      {!!saleLights?.length && <SaleLights saleLights={saleLights} />}
    </div>
  );
};

export default RunNumberAndSaleLights;
