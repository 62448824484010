import { ReactElement, useCallback } from 'react';

import externalGlyph from 'glyphs/external-link.svg';
import infoGlyph from 'glyphs/info-icon.svg';

import Button from 'components/ui/shared/button';
import CollapsibleList from 'components/ui/lists/collapsibleList/collapsibleList';
import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';
import Sprite from 'components/ui/shared/sprite';

import style from './groupedValuesItem.scss';

interface ValueItem {
  /** The label of the item. */
  label: string;
  /** The value of the item. */
  value?: string;
}

interface Props {
  /** The number of items to display by default. */
  defaultDisplayCount?: number;
  /** The placeholder to be rendered when there are no values. */
  emptyValuePlaceholder?: ReactElement;
  /** The footer component to be rendered. */
  footerComponent?: ReactElement;
  /** The url to redirect to when link icon clicked. */
  redirectUrl?: string | undefined;
  /** A callback to trigger a custom event */
  redirectUrlEvent?: () => void;
  /** The title of the grouped values. */
  title: string;
  /** The tooltip text to be rendered. */
  tooltipText?: ReactElement;
  /** The values to be rendered. */
  values: ValueItem[] | undefined;
}

/**
 * Get the item id.
 */
const getItemId = (valueItem: ValueItem) => valueItem.label;

const GroupedValuesItem = ({
  defaultDisplayCount,
  emptyValuePlaceholder,
  footerComponent,
  redirectUrl,
  redirectUrlEvent,
  title,
  tooltipText,
  values,
}: Props) => {
  /**
   * Render the item.
   */
  const renderItem = useCallback((valueItem: ValueItem) => {
    return (
      <div className={style.listItemValue} data-testid={`${valueItem?.label}-feeValueItem`}>
        <div className={style.valueName}>{valueItem.label}</div>
        {valueItem.value && <div className={style.valueAmount}>{valueItem.value}</div>}
      </div>
    );
  }, []);

  /**
   * Opens redirect url in new tab.
   */
  const onRedirectClick = useCallback(() => {
    redirectUrlEvent?.();
    window.open(`${redirectUrl}`);
  }, [redirectUrl, redirectUrlEvent]);

  if (!values?.length && !emptyValuePlaceholder) {
    return null;
  }

  return (
    <div className={style.listItem} data-testid="grouped-values-item">
      <div className={style.listItemInner}>
        <div className={style.listItemHeader}>
          {title}
          {tooltipText && (
            <InfoTooltip
              className={style.tooltip}
              description={tooltipText}
              glyph={infoGlyph}
              glyphClassName={style.tooltipGlyph}
              title={title}
            />
          )}
          {!!redirectUrl && (
            <Button className={style.linkButton} onClick={onRedirectClick} theme="none">
              <Sprite className={style.link} glyph={externalGlyph} />
            </Button>
          )}
        </div>
        {!values?.length ? (
          <div className={style.emptyValuePlaceholder}>{emptyValuePlaceholder}</div>
        ) : (
          <div className={style.listItemValues}>
            <CollapsibleList
              buttonClassName={style.listButton}
              className={style.valueList}
              defaultDisplayCount={defaultDisplayCount ?? values.length}
              getItemId={getItemId}
              itemClassName={style.valueItem}
              items={values}
              renderItem={renderItem}
            />
          </div>
        )}
        {footerComponent && <div>{footerComponent}</div>}
      </div>
    </div>
  );
};

export default GroupedValuesItem;
