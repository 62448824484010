import { createAction } from 'redux-actions';

import { getArbitrations, saveArbitration, getArbitrationMetaData } from './arbitrationDetailsApi';
import { arbitrationsListUpdateItem } from '../list/arbitrationsActions';

export const isLoaded = createAction('ADMIN_ARBITRATION_DETAILS_IS_LOADED');
export const isUpdating = createAction('ADMIN_ARBITRATION_DETAILS_SET_IS_UPDATING');
export const isNotUpdating = createAction('ADMIN_ARBITRATION_DETAILS_SET_IS_NOT_UPDATING');
export const preloadArbitrationItem = createAction('ADMIN_ARBITRATION_DETAILS_IS_PRELOADED');
export const updateArbitrationItem = createAction('ADMIN_ARBITRATION_DETAILS_IS_UPDATED');
export const updateArbitrationMetaData = createAction('ADMIN_ARBITRATION_UPDATE_ARBITRATION_METADATA');

export const processGetArbitration = async (options, dispatch) => {
  dispatch(isUpdating());
  const arbitrationDetails = (await getArbitrations(options))?.data?.data;
  dispatch(isLoaded(arbitrationDetails));
};

export const processSaveArbitration = async (options, dispatch) => {
  dispatch(isUpdating());
  const arbitrationDetails = (await saveArbitration(options))?.data?.data?.auctionItemArbitrationUpdate;
  dispatch(isLoaded({ auctionItem: arbitrationDetails }));
  dispatch(arbitrationsListUpdateItem(arbitrationDetails));
};

export const processGetArbitrationMetaData = (dispatch) => {
  return getArbitrationMetaData().then((response) => {
    const formattedResponse = response?.data?.data?.arbitrationMetaData;
    dispatch(updateArbitrationMetaData(formattedResponse));
  });
};
