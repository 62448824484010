import classnames from 'classnames';
import { Children, useState, ReactNode } from 'react';

import arrowGlyph from 'glyphs/arrow-left.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { Circle } from 'components/ui/shared/shapes';

import style from './toggleVerticalPanelSize.scss';

interface Props {
  /** The child elements to be rendered. */
  children?: ReactNode;

  /** Expanded percentage - percentage area of expanded pane relative to the other one */
  expandedPercentage?: number;
}

const ToggleVerticalPanelSize = ({ children, expandedPercentage = 69 }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const expandedVal: number = isExpanded ? expandedPercentage : 100 - expandedPercentage;
  const collapsedVal: number = 100 - expandedVal;

  const arrayChildren = Children.toArray(children);
  if (arrayChildren?.length !== 2) {
    return <>{children}</>;
  }

  const top = children?.[0];
  const bottom = children?.[1];

  return (
    <div
      className={classnames(style.sectionWindow, isExpanded && style.expanded)}
      data-testid="expand-collapse-container"
      style={{ gridTemplateRows: `${collapsedVal}% ${expandedVal}%` }}
    >
      {top}
      <div className={style.bottomContainer}>
        {bottom}
        <div className={style.buttonContainer}>
          <Button
            className={style.arrowButton}
            dataTestId="expand-collapse-button"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            theme="none"
          >
            <Circle className={style.circle} color="white" height="20px" width="20px">
              <Sprite className={classnames(style.arrowGlyph, isExpanded && style.down)} glyph={arrowGlyph} />
            </Circle>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ToggleVerticalPanelSize;
