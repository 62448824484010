import classnames from 'classnames';
import { Link } from 'react-router';

import adminGlyph from 'glyphs/admin.svg';
import buyGlyph from 'glyphs/buy.svg';
import completeGlyph from 'glyphs/complete.svg';
import myBlockGlyph from 'glyphs/my-block.svg';
import myMarketGuideGlyph from 'glyphs/my-market-guide.svg';
import pendingGlyph from 'glyphs/pending.svg';
import truffleGlyph from 'glyphs/truffle.svg';
import vehicleGlyph from 'glyphs/sell.svg';

import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import { FeatureFlag } from 'constants/featureFlags';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { getFirstAvailableAdminMenuItem, hasAdminVisibility } from 'components/core/navigation/subNavigationMenuItems';
import { hasFeatureFlag, isMarketGuideEnabled } from 'utils/featureFlagUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

import style from './navigation.scss';

interface Props extends RouterProps {
  /** The logged-in user */
  user: User;
}

const Nav = ({ location, user }: Props) => {
  const activeClass = (str) => location?.pathname.startsWith(str) && style.active;

  // TODO: This is temporary; Possibly extend this type of configuration via private label config
  // @see: https://github.com/carmigo/eblock-web/issues/2245
  const buyRoute = process.env.PRIVATE_LABEL ? Route.BUY_RUN_LIST : Route.BUY_TIMED_AUCTION;

  return (
    <ul className={classnames(style.navigation, user?.sessionActingAsOtherUser && style.actingAsUser)}>
      {hasFeatureFlag(FeatureFlag.BROWSE) && (
        <li>
          <Link className={classnames(style.link, activeClass(Route.BROWSE))} to={Route.BROWSE}>
            <span>{t('browse')}</span>
          </Link>
        </li>
      )}
      <li>
        <Link className={classnames(style.link, activeClass(Route.BUY))} to={buyRoute}>
          <Sprite className={style.icon} glyph={buyGlyph} />
          <span>{t('buy')}</span>
        </Link>
      </li>
      <li>
        <Link className={classnames(style.link, activeClass(Route.SELL))} to={Route.SELL_PARKED}>
          <Sprite className={style.icon} glyph={vehicleGlyph} />
          <span>{t('sell')}</span>
        </Link>
      </li>
      <li>
        <Link className={classnames(style.link, activeClass(Route.PENDING))} to={Route.PENDING_IN_IF_BID}>
          <Sprite className={style.icon} glyph={pendingGlyph} />
          <span>{t('pending')}</span>
        </Link>
      </li>
      <li>
        <Link className={classnames(style.link, activeClass(Route.FINISHED))} to={Route.FINISHED_SOLD}>
          <Sprite className={style.icon} glyph={completeGlyph} />
          <span>{t('finished')}</span>
        </Link>
      </li>

      {isAuctionStaff(user) && (
        <>
          <li>
            <Link className={style.link} target="_blank" to={{ pathname: process.env.REACT_APP_TRUFFLE_TRANSPORT }}>
              <Sprite className={style.icon} glyph={truffleGlyph} />
              <span>{t('transport')}</span>
            </Link>
          </li>
        </>
      )}

      {hasAdminVisibility() && (
        <li>
          <Link
            className={classnames(style.link, activeClass(Route.ADMIN))}
            to={getFirstAvailableAdminMenuItem()?.link}
          >
            <Sprite className={style.icon} glyph={adminGlyph} />
            <span>{t('admin')}</span>
          </Link>
        </li>
      )}

      <li>
        <Link className={classnames(style.link, activeClass(Route.MY_BLOCK))} to={Route.MY_BLOCK}>
          <Sprite className={style.icon} glyph={myBlockGlyph} />
          <span>{t('my_block')}</span>
        </Link>
      </li>

      {isMarketGuideEnabled() && (
        <li>
          <Link
            className={classnames(style.link, activeClass(Route.MY_MARKET_GUIDE))}
            onClick={() => trackUserActionWithUserAttributes(UserAction.MY_MARKET_GUIDE_CLICK)}
            to={Route.MY_MARKET_GUIDE}
          >
            <Sprite className={style.marketGuideIcon} glyph={myMarketGuideGlyph} />
            <span>{t('my_market_guide')}</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default withRouter(Nav);
