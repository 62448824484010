import ApiRequest from 'store/shared/api/apiRequest';
import { carrier } from 'store/shared/api/graph/schema/carrier';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { MutationupdateCarrierSuperDispatchArgs } from 'store/shared/api/graph/interfaces/types';

export const getCarrier = ({ carrierId = null }) =>
  ApiRequest({
    data: {
      query: `
      query ($carrierId: String!) {
        carrier(carrierId: $carrierId) {
          ${carrier}
          created
          updated
        }
        transportConnection(
          first:10,
          after:"0",
          carrierId:[$carrierId]
        ) {
          facetGroups(name:["status"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
        }
      }
      ${locationFragment}
    `,
      variables: {
        carrierId,
      },
    },
  });

export const updateCarrier = ({
  id = null,
  name = null,
  shipmentModes = null,
  deliveryIsLocalOnly = null,
  deliveryWhitelistedRegionCodes = null,
  deliveryNotes = null,
}) =>
  ApiRequest({
    data: {
      query: `
      mutation(
        $id: String!,
        $name: String!,
        $deliveryIsLocalOnly: Boolean,
        $deliveryNotes: String,
        $shipmentModes: [CarrierShipmentMode!],
        $deliveryWhitelistedRegionCodes: [String!]
      ) {
        carrierUpdate(
          carrierId:$id,
          name:$name,
          deliveryIsLocalOnly:$deliveryIsLocalOnly,
          deliveryNotes:$deliveryNotes,
          deliveryWhitelistedRegionCodes:$deliveryWhitelistedRegionCodes,
          shipmentModes:$shipmentModes
        ) {
          ${carrier}
          created
          updated
        }
      }
      ${locationFragment}
    `,
      variables: {
        id,
        name,
        deliveryIsLocalOnly,
        deliveryNotes,
        shipmentModes,
        deliveryWhitelistedRegionCodes,
      },
    },
  });

export const createCarrier = ({
  name = null,
  shipmentModes = null,
  deliveryIsLocalOnly = null,
  deliveryWhitelistedRegionCodes = null,
  deliveryNotes = null,
}) =>
  ApiRequest({
    data: {
      query: `
      mutation(
        $name: String!,
        $deliveryIsLocalOnly: Boolean,
        $deliveryNotes: String,
        $shipmentModes: [CarrierShipmentMode!],
        $deliveryWhitelistedRegionCodes: [String!]
      ) {
        carrierCreate(
          name:$name,
          deliveryIsLocalOnly:$deliveryIsLocalOnly,
          deliveryNotes:$deliveryNotes,
          deliveryWhitelistedRegionCodes:$deliveryWhitelistedRegionCodes,
          shipmentModes:$shipmentModes
        ) {
          ${carrier}
        }
      }
      ${locationFragment}
    `,
      variables: {
        name,
        deliveryIsLocalOnly,
        deliveryNotes,
        shipmentModes,
        deliveryWhitelistedRegionCodes,
      },
    },
  });

export const updateCarrierSuperDispatch = ({ carrierId, carrierSDGuid }: MutationupdateCarrierSuperDispatchArgs) =>
  ApiRequest({
    data: {
      query: `
        mutation($carrierId: String!, $carrierSDGuid: String) {
          updateCarrierSuperDispatch(carrierId: $carrierId, carrierSDGuid: $carrierSDGuid) {
            id
            sdCarrierGuid
          }
        }
      `,
      variables: {
        carrierId,
        carrierSDGuid,
      },
    },
  });
