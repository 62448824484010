import { storageManager } from 'utils/storageUtils';

export const authStorage = storageManager.createOrFetchStorage('auth');

export const clearAuthTokenFromUrlHash = () => {
  window.location.hash = '';
};

export const getAuthToken = () => authStorage.get();

let tokenRefreshing = false;
export const getTokenRefreshing = () => tokenRefreshing;
export const setTokenRefreshing = (val) => {
  tokenRefreshing = val;
};

export const cacheAuth = (token) => {
  authStorage.set(token);
  clearAuthTokenFromUrlHash();
  tokenRefreshing = false;
};

export const clearAuthCache = () => {
  authStorage.remove();
  tokenRefreshing = false;
};

export const setActiveSessionCookie = (value) => {
  const { hostname } = window.location;
  const key = 'authenticatedUserId';
  const domain = `.${hostname.split('.').slice(-2).join('.')}`;
  const cookie = `${key}=${value};domain=${domain};path=/;SameSite=Lax;${
    value === false ? 'expires=Thu, 01 Jan 1970 00:00:00 UTC;' : ''
  }`;

  document.cookie = cookie;
};
