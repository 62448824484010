import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import {
  transportJobsListLoaded,
  transportJobsListError,
  facetGroupsLoaded,
  pageInfoLoaded,
  transportJobsListIsLoading,
  transportJobsListCleared,
  transportJobsListUpdateItem,
} from './transportJobsActions';
import { InitialState, TransportJob, FacetGroup, PageInfo, TransportRequest } from './transportJobsModels';

export const getTransportEntityByTypename = (entity) => {
  if (entity.__typename === 'TransportRequest') {
    return new TransportRequest(entity);
  }
  return new TransportJob(entity);
};

export const transportJobsReducer = handleActions(
  {
    [transportJobsListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [transportJobsListLoaded().type]: (state, action) => {
      const { edges, pageInfo, facetGroups } = action.payload;

      return state.setLoaded().merge({
        resultList: List(edges.map((edge) => getTransportEntityByTypename(edge.node))),
        facetGroups: List(facetGroups.map((facetGroupData) => new FacetGroup(facetGroupData))),
        pageInfo: new PageInfo(pageInfo),
      });
    },

    [transportJobsListCleared().type]: (state) =>
      state.unsetLoading().merge({
        resultList: List([]),
        facetGroups: List([]),
        pageInfo: null,
      }),

    [pageInfoLoaded().type]: (state, action) => state.set('pageInfo', action.payload),

    [facetGroupsLoaded().type]: (state, action) =>
      state.set('facetGroups', List(action.payload.map((facetGroupData) => new FacetGroup(facetGroupData)))),

    [transportJobsListError().type]: (state, action) => state.setError(action.payload),

    [transportJobsListUpdateItem().type]: (state, action) => {
      const transportJobNext = action.payload;
      const jobIndex = state.resultList.findIndex((item) => item?.id === transportJobNext.id);

      if (jobIndex === -1) {
        return state.merge({ resultList: state.resultList.insert(0, getTransportEntityByTypename(transportJobNext)) });
      }

      const existingItem = state.resultList.get(jobIndex)!.toJS();
      const updatedItem = getTransportEntityByTypename(merge({}, existingItem, transportJobNext));
      return state.merge({ resultList: state.resultList.set(jobIndex, updatedItem) });
    },
  },
  new InitialState()
);
