import ApiRequest from 'store/shared/api/apiRequest';
import { auctionItemConnectionSchema, inventoryItemConnectionSchema } from './homeDashboardSearchSchemas';

export const MAX_ARCHIVED_UNITS = 8;

export const getSearchResults = ({ keyword = null }) => {
  return ApiRequest({
    data: {
      operationName: 'getHomeDashboardSearchResults',
      query: `
        query($keyword: String) {
          timedAuction: auctionItemConnection(
            first: 4,
            status: [LIVE],
            formats:[APPRAISAL, GROUNDED],
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          runList: auctionItemConnection(
            first: 4,
            status: [UPCOMING],
            formats: [AUCTION, AUCTION_PHYSICAL],
            sort: [{id: "CREATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          timedOffer: auctionItemConnection(
            first: 4,
            status: [LIVE],
            formats: [TIMED_OFFER],
            sort: [{id: "CREATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          parked: inventoryItemConnection(
            first: 4,
            keyword: $keyword,
            archived: false,
          ) {
            ${inventoryItemConnectionSchema}
          }
          parkedArchived: inventoryItemConnection(
            first: ${MAX_ARCHIVED_UNITS},
            keyword: $keyword,
            archived: true,
          ) {
            ${inventoryItemConnectionSchema}
          }
          inIfBid: auctionItemConnection(
            first: 4,
            status: [IN_IF_BID],
            sort: [{id: "UPDATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          pendingDelivery: auctionItemConnection(
            first: 4,
            status: [PENDING_DELIVERY],
            sort: [{id: "UPDATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          awaitingCheckout: auctionItemConnection(
            first: 4,
            status: [AWAITING_CHECKOUT],
            sort: [{id: "CHECKOUT_EXPIRY", ascending: false}],
            keyword: $keyword,
            archived: false
          ) {
            ${auctionItemConnectionSchema}
          }
          sold: auctionItemConnection(
            first: 4,
            status: [SOLD],
            transactionType: SELLING,
            sort: [{id: "DATE_SOLD", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          purchased: auctionItemConnection(
            first: 4,
            status: [SOLD],
            transactionType: BUYING,
            sort: [{id: "DATE_SOLD", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          noSale: auctionItemConnection(
            first: 4,
            status: [NO_SALE],
            sort: [{id: "UPDATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          cancelled: auctionItemConnection(
            first: 4,
            status: [SALE_CANCELLED],
            sort: [{id: "UPDATED", ascending: false}]
            keyword: $keyword,
            archived: false,
          ) {
            ${auctionItemConnectionSchema}
          }
          archived: auctionItemConnection(
            first: ${MAX_ARCHIVED_UNITS},
            status: [NO_SALE, SALE_CANCELLED],
            sort: [{id: "UPDATED", ascending: false}]
            keyword: $keyword,
            archived: true,
          ) {
            ${auctionItemConnectionSchema}
          }
        }
        fragment thumbnailListFrag on Photo {
          thumb: url (width: 110, height: 110)
        }
      `,
      variables: {
        keyword,
      },
    },
  });
};
