export const companyIntegrationFragment = `
  fragment companyIntegration on CompanyIntegration {
    id
    type
    name
    description
    values {
      id
      name
      description
      encrypted
      value
      required
    }
  }
`;

export const companyIntegrationMetadataFragment = `
  fragment companyIntegrationMetadata on CompanyIntegrationMetadata {
    type
    name
    description
    values {
      id
      name
      description
      encrypted
      value
      required
    }
  }
`;
