import { createAction } from 'redux-actions';

import { getHomeDashboard } from './homeDashboardApi';

export const isLoading = createAction('HOME_DASHBOARD_SET_IS_LOADING');
export const isUpdating = createAction('HOME_DASHBOARD_SET_IS_UPDATING');
export const isNotLoading = createAction('HOME_DASHBOARD_UNSET_IS_LOADING');
export const homeDashboardLoaded = createAction('HOME_DASHBOARD_LOADED');
export const homeDashboardClear = createAction('HOME_DASHBOARD_CLEAR');

export const processGetHomeDashboardData = (options, dispatch) => {
  dispatch(isLoading());

  return getHomeDashboard(options).then((response) => dispatch(homeDashboardLoaded(response?.data?.data)));
};

export const processUpdateHomeDashboardData = (options, dispatch) => {
  dispatch(isUpdating());

  return getHomeDashboard(options).then((response) => dispatch(homeDashboardLoaded(response?.data?.data)));
};

export const processClearHomeDashboardData = (dispatch) => {
  dispatch(homeDashboardClear());
};
