import { facetNamesByRoute } from 'constants/enums/facetGroups';

export const pageInfo = `
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
    totalEdges
  }
`;

export const facetGroups = `
  facetGroups {
    allowMultiple
    facets {
      count
      localizedName
      name
      selected
    }
    name
  }
`;

/**
 * Only return the facet groups that are relevant to the current route.
 * If pathname doesn't exist in 'facetNamesByRoute', return all facet groups.
 *
 * @see facetNamesByRoute
 */
export const getFacetGroupsByUrl = () => {
  const pathname = window.location.pathname;
  const facetNames = facetNamesByRoute[pathname];
  if (!facetNames) {
    return facetGroups;
  }

  return `facetGroups(name: ${JSON.stringify(facetNames)}) { 
    allowMultiple
    facets {
      count
      localizedName
      name
      selected
    }
    name
  }`;
};
