import SelectServerDialog from './selectServerDialog';
import { getGraphBaseURL, setGraphBaseURL } from 'utils/apiUtils';

const defaultAddress = getGraphBaseURL();

interface Props {
  onClose: () => void;
}

const SelectServerDialogContainer = ({ onClose }: Props) => (
  <SelectServerDialog
    defaultAddress={defaultAddress}
    onClose={onClose}
    onSubmit={(graphQLBase: string) => setGraphBaseURL(graphQLBase)}
  />
);

export default SelectServerDialogContainer;
