import classnames from 'classnames';
import { MouseEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import editGlyph from 'glyphs/edit.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Dialog from './dialog';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch, AppState } from 'store/configureStore';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { getBiddingSelectionByAuctionId, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { processSetAndPersistBiddingAsSelection } from 'store/user/userActions';
import { t } from 'utils/intlUtils';

import style from './selectConsigner.scss';

const stateConnect = (state: AppState) => ({
  /** The logged-in user */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function invoked to update local storage with bid selection. */
  setCurrentBiddingAsSelection: (options: { [auctionId: string]: BidSelection }) =>
    processSetAndPersistBiddingAsSelection(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the auction. */
  auctionId: string;
  /** The className to overwrite default styles */
  className?: string;
  /** Function invoked on dialog close. */
  onCancel?: (event?: MouseEvent) => void;
  /** The theme style to be applied. */
  theme?: 'blue' | 'green' | 'red' | 'yellow';
}

interface State {
  /** True when dialog is open. */
  isOpen: boolean;
}

class SelectConsigner extends BaseClass<Props, State> {
  static defaultProps = {
    onCancel: () => {},
  };

  constructor(props) {
    super(props);

    const { auctionId, user } = props;
    const biddingAs = getBiddingSelectionByAuctionId(auctionId);
    const isGroupUser = isGroupManagerRole(user);
    const isStaffUser = isAuctionStaff(user);

    this.state = {
      isOpen: (isGroupUser && !biddingAs?.company) || (isStaffUser && (!biddingAs?.company || !biddingAs?.user)),
    };
  }

  onSubmit = (shouldSubmit: boolean, options: BidSelection) => {
    if (shouldSubmit) {
      const { auctionId, setCurrentBiddingAsSelection } = this.props;
      const biddingSelection = { [auctionId]: options };
      setCurrentBiddingAsSelection(biddingSelection);

      this.setState({ isOpen: false });
    }
  };

  onClose = () => {
    this.setState({ isOpen: false });
    this.props.onCancel?.();
  };

  onOpen = (e) => {
    this.preventDefault(e);
    this.setState({ isOpen: true });
  };

  preventDefault = (e) => {
    // This component may be nested within a link; prevent click events from bubbling up.
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { auctionId, className, theme = 'blue', user } = this.props;
    const { isOpen } = this.state;

    const biddingAs = getBiddingSelectionByAuctionId(auctionId);
    const buttonLabel = biddingAs?.company?.name || t('select_company');
    const isStaffUser = isAuctionStaff(user);

    return (
      <>
        <Button key="button" className={classnames(style.button, className)} onClick={this.onOpen} theme="none">
          <span className={style.text}>{buttonLabel}</span>
          <Sprite className={style.glyph} glyph={editGlyph} />
        </Button>
        <Dialog
          key="dialog"
          auctionId={auctionId}
          currentBiddingSelection={biddingAs}
          isOpen={isOpen}
          isStaffUser={isStaffUser}
          onClose={this.onClose}
          onConsignerChange={() => {}}
          onSubmit={this.onSubmit}
          preventDefault={this.preventDefault}
          theme={theme}
        />
      </>
    );
  }
}

export default connector(SelectConsigner);
