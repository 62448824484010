import { ComponentProps } from 'react';

import { AutogradeScoreBadge, EBlockScoreBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { ScoreRangeValueProps } from 'components/ui/shared/scores/useScoreRangeValue';
import { rangeCA } from 'components/ui/shared/scores/scoreRanges';

type ScoreBadgeProps = ComponentProps<typeof AutogradeScoreBadge> & ComponentProps<typeof EBlockScoreBadge>;
type Props = ScoreBadgeProps & ScoreRangeValueProps;

const ScoreBadge = ({ isAutoGrade = false, range = rangeCA, ...props }: Props) =>
  isAutoGrade ? <AutogradeScoreBadge {...props} /> : <EBlockScoreBadge range={range} {...props} />;

export default ScoreBadge;
