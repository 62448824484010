import { ReactNode } from 'react';
import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';

import style from './tabs.scss';

interface Props {
  /** CSS Styling to overwrite default active tab style. */
  activeButtonClass?: string;

  /** CSS Styling to overwrite default button style. */
  buttonClass?: string;

  /** The elements to be rendered as children. */
  children: ReactNode;

  /** CSS Styling to overwrite default tab container style. */
  className?: string;

  /** Optional dataTestID for tabs component. */
  dataTestId?: string;

  /** Function invoked when a tab has been clicked. */
  onChange?: (value: number) => void;

  /** The index of the selected tab. */
  selected?: number;

  /** CSS Styling to overwrite default tab style. */
  tabClass?: string;
}

interface State {
  /** The index of the currently selected tab.. */
  selected?: number;
}

class Tabs extends BaseClass<Props, State> {
  private tabsRef: HTMLUListElement | null;
  private tabRef: HTMLDivElement | null;
  static defaultProps = {
    selected: 0,
  };

  state = {
    selected: this.props.selected,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
    if (prevState.selected !== this.state.selected && this.tabRef) {
      this.tabRef?.scrollIntoView?.(false);
    }
  }

  handleClick(index) {
    this.props.onChange?.(index);
  }

  render() {
    const { className, activeButtonClass, buttonClass, children, dataTestId, tabClass } = this.props;
    const { selected } = this.state;

    const filteredChildren = (children instanceof Array ? children.flat() : [children]).filter(Boolean);
    const tabChildren = filteredChildren.filter((child) => child instanceof Object);

    return (
      <div className="tabs" data-testid={dataTestId || 'tabs-section'}>
        <ul
          ref={(div) => {
            this.tabsRef = div;
          }}
          className={className}
        >
          {tabChildren.map((child, index) => (
            <div
              key={child.props.label}
              ref={(div) => {
                if (index === selected) {
                  this.tabRef = div;
                }
              }}
              className={classnames(
                style.tabContainer,
                tabClass,
                selected === index ? activeButtonClass || style.active : ''
              )}
              data-testid={child.props.label}
            >
              <Button
                className={classnames(style.tab, buttonClass, child.props.disabled && style.disabled)}
                disabled={child.props.disabled}
                onClick={() => this.handleClick(index)}
                theme="none"
              >
                {child.props.label}
              </Button>
            </div>
          ))}
        </ul>
        <div className="tabsContent">{filteredChildren[Number(selected)]}</div>
      </div>
    );
  }
}

interface PaneProps {
  /** The elements to be rendered as children. */
  children: ReactNode;

  /** True when the Pane is disabled. */
  disabled?: boolean;

  /** The label of the Pane. */
  label: string;
}

/**
 * A child component of the Tabs component.
 * Note: The unused props are accessed from the Tabs component.
 */
const Pane = ({ children, disabled, label }: PaneProps) => {
  return <div>{children}</div>;
};

export { Tabs, Pane };
