import classnames from 'classnames';
import { MouseEventHandler, useCallback } from 'react';

import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserAction } from 'logging/analytics/events/userActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './bidsRow.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The auction item details. */
  details: AuctionItemDetailsProps;
  /** Function invoked on button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AutobidsRow = ({ className, details, onClick }: Props) => {
  /**
   * Handles row on click event
   */
  const handleRowOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (onClick) {
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_VIEW_AUTOBIDS_CLICK, details);
        onClick(e);
      }
    },
    [details, onClick]
  );

  if (Number(details.autoBids?.count) > 0) {
    return (
      <Row
        className={classnames(style.bids, style.autobidsRow, className)}
        dataTestId="status-row-autobids"
        name={t('autobids')}
        onClick={handleRowOnClick}
      >
        {details?.autoBids?.count}
      </Row>
    );
  }
  return (
    <Row dataTestId="status-row-autobids" name={t('autobids')}>
      {t('none')}
    </Row>
  );
};

export default AutobidsRow;
