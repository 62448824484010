import { useCallback, useMemo } from 'react';

import GroupedValuesItem from 'components/sections/inventoryItem/details/feesAndValues/groupedValuesItem';
import MarketGuideEmptyValuePlaceholder from 'components/sections/inventoryItem/details/feesAndValues/marketGuideValues/marketGuideEmptyValuePlaceholder';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { MarketGuideValuesTooltipText } from 'components/sections/inventoryItem/details/feesAndValues/marketGuideValues/marketGuideValuesTooltip';
import { Route } from 'store/routing/routes';
import { UserAction } from 'logging/analytics/events/userActions';
import { getMarketGuideValues, marketGuideValueTranslationMap } from 'utils/formatting/marketGuideFormatUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

export interface Props {
  /** The country code of the inventory item. */
  countryCode: string | undefined;
  /** The make of the inventory item. */
  make: string | undefined | null;
  /** The model of the inventory item. */
  model: string | undefined | null;
  /** The sub model of the inventory item. */
  subModel: string | undefined | null;
  /** The values of the inventory item. */
  values: InventoryItemValue[] | undefined;
  /** The year of the inventory item. */
  year: number | undefined | null;
}

const MarketGuideValuesItem = ({ countryCode, make, model, subModel, values, year }: Props) => {
  const url = useMemo(() => {
    return `${Route.MY_MARKET_GUIDE}?soldWithinDays=90&make=${make}&model=${model}&subModels=${subModel}&yearGTE=${year}&yearLTE=${year}`;
  }, [make, model, subModel, year]);

  const formattedValues = useMemo(
    () =>
      getMarketGuideValues(values)?.map(({ type, value }) => ({
        label: t(marketGuideValueTranslationMap[type.id]),
        value: value.formattedAmountRounded,
      })),
    [values]
  );

  /**
   * Tracks when a user clicks the redirect button
   */
  const onClickRedirectButton = useCallback(
    () => trackUserActionWithUserAttributes(UserAction.MY_MARKET_GUIDE_CLICK),
    []
  );

  const emptyValuePlaceholder = useMemo(() => {
    if (countryCode !== 'CA') {
      return undefined;
    }
    return <MarketGuideEmptyValuePlaceholder redirectUrl={url} redirectUrlEvent={onClickRedirectButton} />;
  }, [countryCode, onClickRedirectButton, url]);

  return (
    <GroupedValuesItem
      emptyValuePlaceholder={emptyValuePlaceholder}
      redirectUrl={url}
      redirectUrlEvent={onClickRedirectButton}
      title={t('my_market_guide')}
      tooltipText={<MarketGuideValuesTooltipText />}
      values={formattedValues}
    />
  );
};

export default MarketGuideValuesItem;
