import { MouseEventHandler, useCallback, useState } from 'react';
import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import linkGlyph from 'glyphs/link.svg';

import Button from 'components/ui/shared/button';
import OAuthDialog from 'components/ui/shared/dialogs/oAuthDialog';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch } from 'store/configureStore';
import { LooseObject } from 'constants/objects';
import { getErrors } from 'utils/apiUtils';
import { orderCarfaxUSAReport } from 'store/shared/api/graph/mutations/carfax';
import { processUpdateUser } from 'store/admin/users/details/userDetailsActions';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

import style from './carfaxUSButton.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to update user. */
  updateUser: (options: LooseObject) => processUpdateUser(options, dispatch, true),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** CSS styles for the button. */
  className: string;
  /** Function invoked on carfax button click. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Inventory item's vin. */
  vin?: string;
}

const CarfaxUSButton = ({ className, onClick, updateUser, vin }: Props) => {
  const { setConfig } = useGlobalDialog();
  const [isShowingDialog, setIsShowingDialog] = useState<boolean>(false);

  const orderCarfaxReport = useCallback(
    (authorizationCode: string | undefined = undefined) => {
      if (!vin) {
        return;
      }

      orderCarfaxUSAReport({ authorizationCode, vin })
        ?.then((response) => {
          if (response?.data?.data?.orderCarfaxUSAReport?.requiresAuthentication) {
            setIsShowingDialog(true);
            return;
          }

          try {
            window.open(response?.data?.data?.orderCarfaxUSAReport?.reportUrl || undefined);
          } catch (error) {
            setConfig({ errorsOverride: getErrors(error) });
            setIsShowingDialog(false);
          }

          setIsShowingDialog(false);
        })
        ?.catch((error) => {
          setConfig({ errorsOverride: getErrors(error) });
          setIsShowingDialog(false);
        });
    },
    [setConfig, vin]
  );

  const onButtonClick = useCallback(
    (event) => {
      orderCarfaxReport();
      onClick?.(event);
    },
    [onClick, orderCarfaxReport]
  );

  const onCode = useCallback(
    (code) => {
      orderCarfaxReport(code);
      updateUser({ hasCarfaxUSASession: true });
    },
    [orderCarfaxReport, updateUser]
  );

  const clientId = process.env.REACT_APP_OAUTH_CARFAXUSA_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_OAUTH_CARFAXUSA_REDIRECT_URI;
  const url = `${process.env.REACT_APP_OAUTH_CARFAXUSA_URI}?client_id=${clientId}&redirect_uri=${redirectUri}&state=cryptogenerateduniquestringwithbase64encoding&response_type=code&audience=https://connect.carfax.com&scope=offline_access`;

  return (
    <>
      <OAuthDialog
        disableClickTrigger
        height={600}
        onClose={() => setIsShowingDialog(false)}
        onCode={onCode}
        shouldForceTrigger={isShowingDialog}
        title={t('carfax_login')}
        url={url}
        width={700}
      >
        <Button
          className={classnames(style.button, className)}
          dataTestId="carfax-us-button"
          onClick={onButtonClick}
          theme="none"
        >
          <div className={style.buttonContent}>
            {t('carfax')}
            <Sprite className={style.link} glyph={linkGlyph} />
          </div>
        </Button>
      </OAuthDialog>
    </>
  );
};

export default connector(CarfaxUSButton);
