import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { Company, InitialState } from './companyDetailsModels';
import { CompanyCreditLogEntry } from 'store/admin/consigners/details/consignerDetailsModels';
import {
  companyAuctionRelationshipUpdated,
  companyDetailsCleared,
  companyDetailsError,
  companyDetailsIsLoading,
  companyDetailsLoaded,
  companyDetailsUpdated,
  integrationsUpdated,
  preloadCompanyItem,
  setCompanyData,
  updateCompanySignature,
  updateCreditsLog,
} from './companyDetailsActions';

export const companyDetailsReducer = handleActions(
  {
    [companyDetailsCleared().type]: () => new InitialState(),

    [preloadCompanyItem().type]: (state, action) => {
      if (action.payload && action.payload.id) {
        const company = new Company(action.payload);
        return state.setLoaded().set('details', company);
      }
      return state;
    },

    [companyDetailsError().type]: (state, action) => state.setError(action.payload),

    [companyDetailsIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [companyDetailsLoaded().type]: (state, action) => {
      const {
        company,
        appraisals,
        buyNow,
        upcoming,
        parked,
        inIfBid,
        pendingDelivery,
        awaitingCheckout,
        purchased,
        sold,
        noSale,
        cancelled,
      } = action.payload;

      return state.setLoaded().merge({
        details: new Company(company),
        vehicles: {
          timedAuctions: appraisals.pageInfo.totalEdges + buyNow.pageInfo.totalEdges,
          upcoming: upcoming.pageInfo.totalEdges,
          parked: parked.pageInfo.totalEdges,
          inIfBid: inIfBid.pageInfo.totalEdges,
          pendingDelivery: pendingDelivery.pageInfo.totalEdges,
          awaitingCheckout: awaitingCheckout?.facetGroups
            ?.find(({ name }) => name === 'transactionType')
            ?.facets?.find(({ name }) => name === 'BUYING')?.count,
          purchased: purchased.pageInfo.totalEdges,
          sold: sold.pageInfo.totalEdges,
          noSale: noSale.pageInfo.totalEdges,
          cancelled: cancelled.pageInfo.totalEdges,
        },
      });
    },

    [setCompanyData().type]: (state, action) => {
      const { data, path } = action.payload;
      return state.setIn(['details'].concat(path), data);
    },

    [integrationsUpdated().type]: (state, action) => {
      const company = state.details.toJS();
      const integrations =
        company?.integrations?.filter((integration) => integration?.id !== action.payload?.id).filter(Boolean) || [];

      return state.set(
        'details',
        new Company({
          ...company,
          integrations: [...integrations, action.payload],
        })
      );
    },

    [companyDetailsUpdated().type]: (state, action) => {
      return state.mergeIn(['details'], action.payload);
    },

    [companyAuctionRelationshipUpdated().type]: (state, action) => {
      const company = state.details.toJS();
      const auctionRelationships = company.auctionRelationships;
      const updatedItem = action.payload;
      const auctionRelationshipsList = auctionRelationships?.list.filter(Boolean) || [];
      const updatedItemIndex = auctionRelationshipsList.findIndex((a) => a.id === updatedItem.id);

      auctionRelationshipsList[updatedItemIndex] = { ...auctionRelationshipsList[updatedItemIndex], ...updatedItem };

      const companyNext = {
        ...company,
        auctionRelationships: { ...auctionRelationships, list: auctionRelationshipsList },
      };
      return state.setLoaded().set('details', new Company(companyNext));
    },

    [updateCreditsLog().type]: (state, action) => {
      if (!action.payload) {
        return state.set('creditsLog', null);
      }
      return state.set('creditsLog', List(action.payload.edges.map(({ node }) => new CompanyCreditLogEntry(node))));
    },

    // Company api does not have an invoice signature mutation so we must update from the consigner api
    [updateCompanySignature().type]: (state, action) => {
      return state.mergeIn(['details'], action.payload);
    },
  },
  new InitialState()
);
