import classnames from 'classnames';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import infoGlyph from 'glyphs/info.svg';

import MiniButton from 'components/ui/shared/buttons/miniButton';
import SaleLightFormButton from 'components/sections/auctionItem/operations/submitToAuction/pages/saleLightForm/saleLightFormButton';
import Sprite from 'components/ui/shared/sprite';
import TitleBanner, { TitleBannerTheme } from 'components/ui/shared/notifications/titleBanner';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLightFormSection.scss';

interface Props {
  /** The active sale lights of the auction lane. */
  activeSaleLights: SaleLight[];
  /** True when the light options can't be modified */
  isSelectionDisabled?: boolean;
  /** The class name to override the info message style. */
  infoMessageClassName?: string;
  /** True when the current lights differ from the suggested lights. */
  isSuggestedOverridden: boolean;
  /** Function invoked on sale light changes. */
  onChange: (activeLights: SaleLight[]) => void;
  /** Function invoked when the user reverts to the suggested lights. */
  onRevert: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Determines if sale lights should be automated or manually overridden */
  saleLightsOverride?: SaleLight[];
  /** The title to be displayed. */
  title?: string;
}

const SaleLightFormSection = ({
  activeSaleLights,
  infoMessageClassName,
  isSelectionDisabled,
  isSuggestedOverridden,
  onChange,
  onRevert,
  saleLightsOverride,
  title,
}: Props) => {
  const [selectedSaleLights, setSelectedSaleLights] = useState<SaleLight[]>(activeSaleLights ?? []);

  // When sale lights are overridden, limit which ones are displayed; otherwise, display all
  const saleLights = useMemo(
    () => [...(saleLightsOverride?.length ? saleLightsOverride : [SaleLight.GREEN, SaleLight.YELLOW, SaleLight.RED])],
    [saleLightsOverride]
  );

  /**
   * Update selected sale lights when active sale lights change.
   */
  useEffect(() => {
    setSelectedSaleLights(activeSaleLights);
  }, [activeSaleLights]);

  /**
   * Add sale light.
   * Possible selections:
   *   - Red or Green selected; not both (Future changes will allow multiple lights to be selected).
   *   - Yellow can only be active if red or green active.
   *   - Neither selected.
   */
  const onAddSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = [...selectedSaleLights, value];

      // Sale light rules only apply when not overridden
      if (!saleLightsOverride?.length) {
        // Yellow light is never active by itself.
        if (value === SaleLight.YELLOW && currentLights.length === 1) {
          currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
        }

        // Only green OR red can be selected, not both.
        if (value === SaleLight.GREEN) {
          currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.RED);
        } else if (value === SaleLight.RED) {
          currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.GREEN);
        }
      }

      setSelectedSaleLights(currentLights);
      onChange(currentLights);
    },
    [saleLightsOverride, selectedSaleLights, onChange]
  );

  /**
   * Remove sale light.
   */
  const onRemoveSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = selectedSaleLights.filter((saleLight) => saleLight !== value);

      // Sale light rules only apply when not overridden
      if (!saleLightsOverride?.length) {
        // Yellow light must be deactivated when red or green light deactivated.
        if ([SaleLight.RED, SaleLight.GREEN].includes(value)) {
          currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
        }
      }

      setSelectedSaleLights(currentLights);
      onChange(currentLights);
    },
    [saleLightsOverride, selectedSaleLights, onChange]
  );

  /**
   * Click handler for sale light buttons.
   **/
  const onClickSaleLight = useCallback(
    (saleLight: SaleLight) => {
      if (isSelectionDisabled) {
        return null;
      }
      const isActive = selectedSaleLights.includes(saleLight);
      return isActive ? onRemoveSaleLight(saleLight) : onAddSaleLight(saleLight);
    },
    [isSelectionDisabled, onAddSaleLight, onRemoveSaleLight, selectedSaleLights]
  );

  return (
    <div className={style.section} data-testid="sale-light-form">
      {title && <div className={style.title}>{title}</div>}
      <div className={classnames(style.infoMessage, infoMessageClassName)}>
        <Sprite className={style.iconInfo} glyph={infoGlyph} />
        <span>{t('suggested_sale_lights_message')}</span>
      </div>
      <div className={classnames(style.buttonContainer)} data-testid="sale-light-buttons">
        {saleLights.map((saleLight: SaleLight) => (
          <SaleLightFormButton
            key={`sale-light-${saleLight}`}
            isActive={selectedSaleLights.includes(saleLight)}
            isDisabled={
              isSelectionDisabled ||
              (!saleLightsOverride?.length && saleLight === SaleLight.YELLOW && selectedSaleLights.length === 0)
            }
            onClick={isSelectionDisabled ? undefined : onClickSaleLight}
            saleLight={saleLight}
          />
        ))}
      </div>
      {isSuggestedOverridden && (
        <div className={style.overriddenBanner}>
          <div>{t('suggested_sale_lights_overriden_message')}</div>
          <MiniButton onClick={onRevert} theme="black">
            {t('revert')}
          </MiniButton>
        </div>
      )}

      {saleLights.map((saleLight: SaleLight) => {
        const light = saleLight.toLowerCase();
        return (
          <TitleBanner key={saleLight} theme={light as TitleBannerTheme} title={t(`${light}_light`)}>
            {t(`${light}_light_description`)}
          </TitleBanner>
        );
      })}
    </div>
  );
};

export default SaleLightFormSection;
