// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","modalCustom":"modalCustom_d72d170","tabsContainer":"tabsContainer_e94c7ac","footer":"footer_b512f0c","footerContainer":"footerContainer_e45015a","progressText":"progressText_dd11b93","smallButton":"smallButton_b8faf22","backButton":"backButton_c6968c0","arrowSprite":"arrowSprite_b7bf729","errorMessageContainer":"errorMessageContainer_a9a8d64","spinnerStyle":"spinnerStyle_e061fe5","prevNextContainer":"prevNextContainer_f36252e","sprite":"sprite_db1eb35"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"modalCustom\":\"modalCustom_d72d170\",\"tabsContainer\":\"tabsContainer_e94c7ac\",\"footer\":\"footer_b512f0c\",\"footerContainer\":\"footerContainer_e45015a\",\"progressText\":\"progressText_dd11b93\",\"smallButton\":\"smallButton_b8faf22\",\"backButton\":\"backButton_c6968c0\",\"arrowSprite\":\"arrowSprite_b7bf729\",\"errorMessageContainer\":\"errorMessageContainer_a9a8d64\",\"spinnerStyle\":\"spinnerStyle_e061fe5\",\"prevNextContainer\":\"prevNextContainer_f36252e\",\"sprite\":\"sprite_db1eb35\"}";
        // 1734114906080
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  