import { List } from 'immutable';

import InventoryItemType from 'constants/inventoryItem';
import Record from 'store/shared/models/record';
import ServerRecord from 'store/shared/models/serverRecord';
import { FacetGroup as FacetGroupType, FacetedPageInfo, SellHoldStatus } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface InventoryItemsResultsModel {
  /** Inventory Items facet groups */
  facetGroups: List<RecordOf<FacetGroupType>>;
  /** Faceted page info */
  pageInfo: RecordOf<FacetedPageInfo>;
  /** Inventory Items list */
  resultList: List<RecordOf<InventoryItemType>>;
}

export const InventoryItemsResults = ServerRecord<InventoryItemsResultsModel>({
  facetGroups: List([]),
  resultList: List([]),
  pageInfo: null,
});

export const InventoryItem = Record<InventoryItemType>({
  archived: null,
  availableAuctions: [],
  captureType: null,
  canBeListed: false,
  company: null,
  comparableValuations: [],
  complete: null,
  completionPercentage: null,
  compoundHistory: [],
  conditionReport: null,
  createdBy: null,
  cylinders: null,
  description: null,
  displacement: null,
  fuelType: null,
  id: null,
  incompleteFieldMessages: null,
  isUpdatable: false,
  location: null,
  make: null,
  mileage: null,
  minimalCR: null,
  model: null,
  notes: null,
  operable: true,
  photos: [],
  printableUrl: null,
  redirectPath: null,
  releaseStatus: null,
  sellHoldStatus: SellHoldStatus.NO_HOLD,
  status: null,
  stickerUrl: null,
  stockNumber: null,
  tasks: [],
  timelineActions: [],
  transmission: null,
  transportDetails: [],
  trim: null,
  uniqueStockNumber: 0,
  vehicleBuyerShield: null,
  vin: null,
  year: null,
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});

export const FacetGroup = Record<FacetGroupType>({
  name: null,
  allowMultiple: false,
  facets: null,
});

export const Facet = Record({
  name: null,
  localizedName: null,
  count: null,
  selected: false,
});
