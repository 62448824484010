import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import EditButton from 'components/ui/shared/buttons/editButton';
import Map from 'components/ui/maps/map';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectLocations from 'forms/shared/selectLocations';
import { AppState } from 'store/configureStore';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import { CompanyAuctionRelationshipStatus, Location } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { Spinner } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { joinStrings } from 'utils/stringUtils';
import { storageManager } from 'utils/storageUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { transportFeeQuote } from 'store/admin/transportJobs/details/transportJobDetailsApi';
import { useGlobalDialog } from 'contexts/globalDialogContext';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './transportEstimate.scss';

type AuctionId = string;
type TransportEstimateParams = {
  company: {
    id: string;
    name: string;
  };
  locationId: string;
};
type TransportEstimateParamsRecord = Record<AuctionId, TransportEstimateParams>;

const companyStorage = storageManager.createOrFetchStorage<TransportEstimateParamsRecord>('transportEstimateCompany');

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    /** The id of the auction item */
    auctionItem: AuctionItem;
    /** Function invoked on dialog close */
    onClose: () => void;
  };
}

const TransportEstimate = ({ inventoryDetailsData, setIsClickOutsideDisabled }: Props) => {
  const { setConfig } = useGlobalDialog();
  const user = useSelector((state: AppState) => state.app.user);
  const { auctionItem, onClose } = inventoryDetailsData;
  const cachedTransportEstimateParams: TransportEstimateParamsRecord = useMemo(() => companyStorage.get() ?? {}, []);

  // If the user only manages one company, then pre-populate that company. Otherwise, use cached transport estimate params if they exist.
  let initialCompanyDefaultOption;
  let initialLocationDefaultOption;
  if (user?.companyRelationships?.count === 1) {
    const company = user?.companyRelationships?.list?.[0]?.company;
    initialCompanyDefaultOption = { value: company?.id };
  } else if (cachedTransportEstimateParams[auctionItem?.auction?.id]) {
    const cachedParams: TransportEstimateParams = cachedTransportEstimateParams[auctionItem?.auction?.id];
    initialCompanyDefaultOption = { value: cachedParams.company.id, label: cachedParams.company.name };
    initialLocationDefaultOption = { id: cachedParams.locationId };
  }

  const [disclaimers, setDisclaimers] = useState<string[]>();
  const [estimate, setEstimate] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<SelectCompanyOption>(initialCompanyDefaultOption);
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(initialLocationDefaultOption);

  /**
   * OnMount - If location is cached, submit query. Otherwise, open company and location dialog.
   */
  useMountEffect(() => {
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSPORT_ESTIMATE_CLICK, auctionItem);
    selectedLocation ? onConfirm(true) : openCompanyLocationDialog();
  });

  /**
   * Opens dialog for company and location selections.
   */
  const openCompanyLocationDialog = useCallback(() => {
    setDialogOpen(true);
    setIsClickOutsideDisabled?.(true);
  }, [setIsClickOutsideDisabled]);

  /**
   * onEdit - Invoked on edit button click.
   */
  const onEditButtonClick = useCallback(() => {
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSPORT_ESTIMATE_EDIT_CLICK, auctionItem);
    openCompanyLocationDialog();
  }, [auctionItem, openCompanyLocationDialog]);

  /**
   * onConfirm - Query for transport fee quote estimate.
   */
  const onConfirm = useCallback(
    (shouldSubmit: boolean) => {
      setDialogOpen(false);
      setIsClickOutsideDisabled?.(false);

      if (shouldSubmit && selectedLocation?.id) {
        setLoading(true);
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSPORT_ESTIMATE_CONFIRM_CLICK, auctionItem);
        transportFeeQuote({ auctionItemId: auctionItem.id, dropOffLocationId: selectedLocation?.id })
          .then((response) => {
            const quote = response?.data?.data?.transportFeeQuote;
            setEstimate(quote?.amount?.formattedAmount || t('tbd'));
            setDisclaimers(quote?.disclaimers?.split('\n\n'));
            setSelectedLocation(quote?.dropOffLocation);
          })
          .catch((error) => setConfig({ errorsOverride: getErrors(error) }))
          .finally(() => setLoading(false));

        // Store company and location selections in local storage.
        if (selectedCompany && selectedLocation) {
          const transportEstimateParams: TransportEstimateParams = {
            company: { id: selectedCompany.value, name: selectedCompany.label },
            locationId: selectedLocation.id,
          };
          cachedTransportEstimateParams[auctionItem?.auction?.id] = transportEstimateParams;
          companyStorage.set(cachedTransportEstimateParams);
        }
      } else {
        // Close slideout when user closes confirm dialog.
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSPORT_ESTIMATE_CANCEL_CLICK, auctionItem);
        onClose();
      }
    },
    [
      auctionItem,
      cachedTransportEstimateParams,
      onClose,
      selectedCompany,
      selectedLocation,
      setConfig,
      setIsClickOutsideDisabled,
    ]
  );

  const renderDisclaimers = useMemo(() => {
    return disclaimers?.map((disclaimer, index) => <li key={`disclaimer_${index}`}>{disclaimer}</li>);
  }, [disclaimers]);

  if (isLoading) {
    return <Spinner className={style.detailsLoading} />;
  }

  return (
    <>
      <div className={style.sectionBanner}>
        <div className={style.sectionName}>{t('drop_off_location')}</div>
        <EditButton className={style.editButton} onClick={onEditButtonClick} />
      </div>
      <div className={style.location}>
        {selectedLocation && <Map className={style.map} locations={[selectedLocation]} />}
        <div>
          <div className={style.estimate} data-testid="transport-estimate">
            {estimate}
          </div>
          <div className={style.companyName}>{selectedCompany?.label}</div>
          {selectedLocation && (
            <div className={style.dropOffLocation}>
              <div>{selectedLocation.address1}</div>
              {selectedLocation.address2 && <div>{selectedLocation.address2}</div>}
              <div>
                {joinStrings([selectedLocation.city, selectedLocation.regionCode, selectedLocation.countryCode])}
              </div>
            </div>
          )}
        </div>
      </div>
      {!!disclaimers && (
        <>
          <div className={style.sectionBanner}>
            <div className={style.sectionName}>{t('disclaimers')}</div>
          </div>
          <div>
            <ul className={style.disclaimers}>{renderDisclaimers}</ul>
          </div>
        </>
      )}
      <ConfirmDialog
        actionable={!!selectedLocation?.id}
        bringToFront
        className={style.confirmPickupLocation}
        isOpen={isDialogOpen}
        onConfirm={onConfirm}
        theme="blue"
        title={t('select_drop_off_location')}
      >
        <SelectCompany
          className={style.consigners}
          connectionVariables={{
            auctionId: auctionItem?.auction?.id,
            auctionRelationshipPermission: [AuctionRelationshipPermission.CAN_BUY],
            auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
          }}
          defaultId={selectedCompany?.value}
          onChange={(option: SelectCompanyOption) => setSelectedCompany(option)}
          placeholder={t('select_company')}
          theme="blue"
        />
        <SelectLocations
          companyId={selectedCompany?.value}
          defaultId={(!!selectedCompany?.value && selectedLocation?.id) || undefined}
          onLocationChange={(option) => setSelectedLocation(option)}
          placeholder={t('choose_location')}
          showCompoundSelectionOption={false}
          theme="blue"
        />
      </ConfirmDialog>
    </>
  );
};

export default TransportEstimate;
