import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  MutationAddInventoryItemNote,
  MutationUpdateInventoryItemStatusArgs,
  addInventoryItemNote,
  archiveInventoryItem,
  getAuditLog,
  getConsignerLocations,
  getInventoryItemDetails,
  saveInventoryItemFees,
  saveInventoryItemValues,
  updateInventoryItemLocations,
  updateInventoryItemStatus,
} from './inventoryItemDetailsApi';
import { QuerylocationConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { auctionItemAddNote, auctionItemUpdateInventoryItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { currentNotesCompanies, notesSetCurrentCompanies } from 'store/notes/notesActions';

export const inventoryItemDetailsUpdating = createAction('INVENTORY_ITEM_DETAILS_UPDATING');
export const inventoryItemDetailsLoaded = createAction('INVENTORY_ITEM_DETAILS_LOADED');
export const inventoryItemDetailsCleared = createAction('INVENTORY_ITEM_DETAILS_CLEARED');

export const inventoryItemAddNote = createAction('INVENTORY_ITEM_ADD_NOTE');
export const inventoryItemArchived = createAction('INVENTORY_ITEM_ARCHIVE_SET');
export const preloadInventoryItem = createAction('INVENTORY_ITEM_DETAILS_PRELOAD');
export const updateInventoryItem = createAction('INVENTORY_ITEM_DETAILS_UPDATE');
export const updateConsignerLocations = createAction('INVENTORY_ITEM_DETAILS_UPDATE_CONSIGNER_LOCATIONS');
export const inventoryItemDetailsAuditLogUpdating = createAction('INVENTORY_ITEM_DETAILS_AUDIT_LOG_UPDATING');
export const inventoryItemDetailsAuditLogLoaded = createAction('INVENTORY_ITEM_DETAILS_AUDIT_LOG_LOADED');

export const processGetInventoryItemDetails = (options, dispatch, resetInventoryItemsCallback = () => {}) => {
  dispatch(inventoryItemDetailsUpdating());
  return getInventoryItemDetails(options).then((response) => {
    if (response?.data?.data?.inventoryItem === null) {
      dispatch(inventoryItemDetailsCleared());
      resetInventoryItemsCallback();
      return;
    }
    dispatch(inventoryItemDetailsLoaded(response?.data?.data?.inventoryItem));
  });
};

export const processClearInventoryItemDetails = (dispatch) => {
  dispatch(inventoryItemDetailsCleared());
};

export const processArchiveInventoryItem = (options, dispatch) => {
  return archiveInventoryItem(options).then((response) =>
    dispatch(inventoryItemArchived(response?.data?.data?.inventoryItemArchive))
  );
};

export const processGetConsignerLocations = (
  options: Pick<QuerylocationConnectionArgs, 'consignerId'>,
  dispatch: AppDispatch
) =>
  getConsignerLocations(options).then((response) =>
    dispatch(updateConsignerLocations(response?.data?.data?.locationConnection))
  );

export const processUpdateInventoryItemLocations = (options, dispatch) => {
  const isInventoryItem = options.type === 'inventoryItem';
  const updateFunc = isInventoryItem ? updateInventoryItem : auctionItemUpdateInventoryItem;

  return updateInventoryItemLocations(options).then((response) =>
    dispatch(updateFunc(response?.data?.data?.inventoryItemChangeLocation))
  );
};

export const processAddInventoryItemNote = (options: MutationAddInventoryItemNote, dispatch: AppDispatch) => {
  const addNoteFunc = options.isAuctionItem ? auctionItemAddNote : inventoryItemAddNote;
  return addInventoryItemNote(options).then((response) => {
    dispatch(
      addNoteFunc({
        note: response?.data?.data?.noteCreate,
        inventoryItemId: options?.inventoryItemId,
      })
    );

    if (options?.companies?.length) {
      const companies = options?.companies;

      // TODO: Change name of actions/localStorage
      dispatch(notesSetCurrentCompanies(companies));
      currentNotesCompanies.set(JSON.stringify(companies));
    }
  });
};

export const processSaveInventoryItemFees = (options, dispatch) => {
  const updateFunc = options.isInventoryItem ? updateInventoryItem : auctionItemUpdateInventoryItem;
  return saveInventoryItemFees(options).then((response) =>
    dispatch(updateFunc(response?.data?.data?.inventoryItemFeeUpdate))
  );
};

export const processSaveInventoryItemValues = (options, dispatch) => {
  const updateFunc = options.isInventoryItem ? updateInventoryItem : auctionItemUpdateInventoryItem;

  return saveInventoryItemValues(options).then((response) => {
    const formattedResponse =
      response?.data?.data?.[options?.isAdding ? 'inventoryItemValueCreate' : 'inventoryItemValueUpdate'];
    dispatch(updateFunc(formattedResponse));
    return formattedResponse;
  });
};

export const processUpdateInventoryItemStatus = (
  options: MutationUpdateInventoryItemStatusArgs,
  dispatch: AppDispatch
) => {
  return updateInventoryItemStatus(options).then((response) =>
    dispatch(updateInventoryItem(response?.data?.data?.inventoryItemUpdate?.vehicle))
  );
};

export const processGetAuditLog = async (options, dispatch) => {
  dispatch(inventoryItemDetailsAuditLogUpdating());
  const logEntries = (await getAuditLog(options))?.data?.data?.inventoryItem?.logEntries;
  dispatch(inventoryItemDetailsAuditLogLoaded(logEntries));
};
