import classnames from 'classnames';
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash-es';

import Checkbox from 'forms/shared/checkbox';
import InputText from 'forms/shared/inputText';
import OutsideClick from 'components/ui/shared/directives/outsideClick';
import raf from 'utils/raf';
import { Location as LocationType } from 'store/shared/api/graph/interfaces/types';
import { formatAddress } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './location.scss';

interface Props {
  /** Whether has error or not. */
  hasError?: boolean;
  /** Ignored location name. */
  ignoredLocation?: string;
  /** Whether is compound enabled or not. */
  isCompoundEnabled?: boolean;
  /** Whether is compound location or not. */
  isCompoundLocation?: boolean;
  /** Whether is dialog theme or not. */
  isDialogTheme?: boolean;
  /** Whether is pickup location or not. */
  isPickupLocation?: boolean;
  /** Whether is small theme or not. */
  isSmallTheme: boolean;
  /** Label to be rendered. */
  label: string;
  /** List of location options. */
  locations: LocationType[];
  /** Callback function triggered when location id changed. */
  onChange: (locationId: string) => void;
  /** Callback function triggered when user toggle compound checkbox. */
  onCompoundChange?: (isCompound?: boolean) => void;
  /** Currently selected location. */
  selectedLocation?: string;
}

const renderNoLocations = () => <div className={style.header}>{t('no_locations')}</div>;

const Location = ({
  hasError,
  ignoredLocation,
  isCompoundEnabled,
  isCompoundLocation,
  isDialogTheme,
  isPickupLocation,
  isSmallTheme,
  label,
  locations,
  onChange,
  onCompoundChange,
  selectedLocation,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>();
  const locationsWrapper = useRef<HTMLDivElement | null>(null);

  /**
   * Resize locations container
   */
  const resizeLocationsWrapper = useCallback(() => {
    if (isActive && locationsWrapper.current?.style) {
      locationsWrapper.current.style.height = 'auto';
      const { height, y } = locationsWrapper.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (y + height >= windowHeight) {
        locationsWrapper.current.style.height = `${windowHeight - y - 10}px`;
      }
    }
  }, [isActive]);

  const resizeListener = throttle(resizeLocationsWrapper, 200);

  /**
   * Add resize event listener on mount
   */
  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener]);

  /**
   * On location changed
   */
  const onLocationChange = useCallback(
    (id: string) => {
      setIsActive(false);
      onChange(id);
    },
    [onChange]
  );

  /**
   * On compound location changed
   */
  const onCompoundLocationChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onCompoundChange?.(target.checked);
    },
    [onCompoundChange]
  );

  /**
   * Render location
   */
  const renderLocation = useCallback(
    (location: LocationType) => {
      const { id, name } = location;

      return (
        <li key={id}>
          <Checkbox
            className={style.checkbox}
            dataTestId={name}
            id={id}
            labelClassName={style.checkboxLabel}
            onChange={() => onLocationChange(id)}
            setChecked={selectedLocation === id}
          >
            <span>{name}</span>
            {formatAddress(location, true, true)}
          </Checkbox>
        </li>
      );
    },
    [onLocationChange, selectedLocation]
  );

  /**
   * Render location list
   */
  const renderLocationList = useCallback(
    (title: string, options: LocationType[]) =>
      options.length ? (
        <>
          <div className={style.header}>{title}</div>
          <ul>{options.map(renderLocation)}</ul>
        </>
      ) : (
        renderNoLocations()
      ),
    [renderLocation]
  );

  /**
   * Render all locations
   */
  const renderLocations = useCallback(() => {
    const dealerships = locations.filter((l) => !l.public);
    const compounds = locations.filter((l) => l.public && l.id !== ignoredLocation);

    if (!dealerships.length && !compounds.length) {
      return <div className={style.locationsList}>{renderNoLocations()}</div>;
    }

    raf(() => {
      resizeLocationsWrapper();
    });

    return (
      <div ref={locationsWrapper} className={classnames(style.locationsList, isSmallTheme && style.isSmallTheme)}>
        {!isCompoundLocation && renderLocationList(t('your_locations'), dealerships)}
        {renderLocationList(t('compounds'), compounds)}
      </div>
    );
  }, [ignoredLocation, isCompoundLocation, isSmallTheme, locations, renderLocationList, resizeLocationsWrapper]);

  const selectedLocationName = locations.find((l) => l.id === selectedLocation);

  return (
    <>
      <label className={style.label}>{label}</label>
      <OutsideClick onClick={() => setIsActive(false)}>
        <div
          className={classnames(
            style.locations,
            isSmallTheme && style.isSmallTheme,
            isDialogTheme && style.isDialogTheme
          )}
        >
          <div className={style.inputContainer}>
            <div className={style.input}>
              <InputText
                className={classnames({ [style.inputSmall]: isSmallTheme })}
                dataTestId="select-location"
                defaultValue={(selectedLocationName || {}).name}
                fontLarge={!isSmallTheme}
                max={0}
                onFocus={() => setIsActive(true)}
                placeholder={t('select')}
                textClassName={hasError ? style.inputError : undefined}
                theme={hasError ? 'error' : undefined}
              />
              <div className={style.actions}>
                <div className={classnames(style.chevron, isActive && style.isActive)} />
              </div>
            </div>
            {isPickupLocation && (
              <Checkbox
                className={classnames(style.checkbox, style.transportToCompoundCheckbox)}
                dataTestId="select-compound"
                id="current-location"
                onChange={onCompoundLocationChange}
                setChecked={isCompoundEnabled}
                text={t('transport_to_compound')}
              />
            )}
          </div>
          {isActive && renderLocations()}
        </div>
      </OutsideClick>
    </>
  );
};

export default memo(Location);
