import classnames from 'classnames';

import { t } from 'utils/intlUtils';

import style from './loading.scss';

export const LOADER_TEXT = 'loading';

type Theme = 'black' | 'blue' | 'gray' | 'green' | 'lightGray' | 'red' | 'white';

interface SpinnerProps {
  /** The className to overwrite spinner container styles. */
  className?: string;
  /** Id used for testing. */
  dataTestId?: string;
  /** The className to overwrite spinner styles. */
  spinnerStyleClassName?: string;
  /** Theme of the spinner. */
  theme?: Theme;
}

/**
 * Common spinner
 */
export const Spinner = ({
  className,
  dataTestId = 'loading-spinner',
  spinnerStyleClassName,
  theme = 'blue',
}: SpinnerProps) => (
  <div className={classnames(style.loadingSpinner, className, style[theme])} data-testid={dataTestId}>
    <div className={classnames(style.loadingSpinner1, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner2, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner3, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner4, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner5, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner6, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner7, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner8, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner9, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner10, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner11, style.child, spinnerStyleClassName)} />
    <div className={classnames(style.loadingSpinner12, style.child, spinnerStyleClassName)} />
  </div>
);

interface SpinnerCenteredProps extends SpinnerProps {
  /** Whether is details loader or not. */
  isDetailsLoader?: boolean;
  /** The className to overwrite spinner styles. */
  spinnerClassName?: string;
}

/**
 * Similar to Spinner, but centered within its parent.
 */
export const SpinnerCentered = ({
  className,
  dataTestId = 'loading-spinner',
  isDetailsLoader = false,
  spinnerClassName,
  spinnerStyleClassName,
  theme,
}: SpinnerCenteredProps) => (
  <div className={classnames(style.spinnerCentered, isDetailsLoader && style.spinnerCenteredDetails, className)}>
    <Spinner className={classnames(spinnerClassName, spinnerStyleClassName)} dataTestId={dataTestId} theme={theme} />
  </div>
);

interface SpinnerBlockProps {
  /** Loader text for spinner block. */
  text?: string;
}

export const SpinnerBlock = ({ text = `${t(LOADER_TEXT)}...` }: SpinnerBlockProps) => (
  <div className={style.spinnerBlock}>
    <Spinner />
    <h5 className={style.spinnerText}>{text}</h5>
  </div>
);

interface LoadingProps {
  /** Whether the loading component has full width or not. */
  hasFullWidth?: boolean;
  /** Whether is loading or not. */
  isLoading?: boolean;
  /** Whether the loading component is modal transparent or not. */
  isModalTransparent?: boolean;
  /** The className to overwrite overlay styles. */
  overlayClassName?: string;
  /** Text message to overwrite the loading message. */
  text?: string;
}

const Loading = ({ hasFullWidth, isLoading, isModalTransparent, overlayClassName, text }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={classnames(style.loaderOverlay, hasFullWidth && style.loaderOverlayWithFullWidth, overlayClassName)}
      data-testid="loading"
    >
      <div
        className={classnames(style.modalDialog, style.loaderModal, isModalTransparent && style.loadedModalTransparent)}
      >
        <SpinnerBlock text={text} />
      </div>
    </div>
  );
};

export default Loading;
