import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { AppState } from 'store/configureStore';
import { getUrlParams } from 'utils/urlUtils';
import { onInventoryItemUpdatedEvent } from 'store/inventoryItem/addModify/addModifyEvents';
import { processGetInventoryItemDetails } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { processSetAsyncCarfaxDeclarations } from 'store/inventoryItem/addModify/addModifyActions';

interface Props {
  /** Inventory item id. */
  inventoryItemId?: string | null;
}
const AddModifyIOUpdater = ({ inventoryItemId }: Props) => {
  const dispatch = useDispatch();
  const isCaptureDialogOpen = useSelector((state: AppState) => !!state.app.inventoryItem.loadedDate);

  useEffect(() => {
    const inventoryItemUpdateStream = onInventoryItemUpdatedEvent((item) => {
      if (inventoryItemId === item?.inventoryItemId) {
        const declarations = item?.declarations ?? [];
        const carfaxCanadaReportStatus = item?.carfaxCanadaReportStatus;

        // Update declarations to include any that were included when attaching the CarFax report
        processSetAsyncCarfaxDeclarations(dispatch, { carfaxCanadaReportStatus, declarations });
      }

      const loadedInventoryItemId = getUrlParams()?.id;
      if (!inventoryItemId && !isCaptureDialogOpen && loadedInventoryItemId === item?.inventoryItemId) {
        // Refresh the Parked VDP if a change was done by another user/session
        // TODO: This could potentially be done silently instead.
        processGetInventoryItemDetails({ inventoryItemId: loadedInventoryItemId }, dispatch);
      }
    });

    return () => {
      inventoryItemUpdateStream?.cancel();
    };
  }, [dispatch, inventoryItemId, isCaptureDialogOpen]);

  return undefined;
};

export default AddModifyIOUpdater;
