import { createAction } from 'redux-actions';

import { getArbitrationsList } from './arbitrationsApi';
import { getErrors, parseQueryParams } from 'utils/apiUtils';

export const arbitrationsListIsLoading = createAction('ADMIN_ARBITRATION_LIST_IS_LOADING');
export const arbitrationsListLoaded = createAction('ADMIN_ARBITRATION_LIST_LOADED');
export const arbitrationsListCleared = createAction('ADMIN_ARBITRATION_LIST_CLEARED');
export const arbitrationsListError = createAction('ADMIN_ARBITRATION_LIST_ERROR');
export const arbitrationsListUpdateItem = createAction('ADMIN_ARBITRATION_LIST_UPDATE_ITEM');
export const facetGroupsLoaded = createAction('ADMIN_ARBITRATION_LIST_FACET_GROUPS_LOADED');
export const pageInfoLoaded = createAction('ADMIN_ARBITRATION_LIST_PAGE_INFO_LOADED');

export const processGetArbitrationsList = async (options, dispatch) => {
  try {
    dispatch(arbitrationsListIsLoading(true));
    const arbitrations = (await getArbitrationsList(parseQueryParams(options)))?.data?.data
      ?.auctionItemArbitrationConnection;
    dispatch(arbitrationsListLoaded(arbitrations));
  } catch (err) {
    dispatch(arbitrationsListIsLoading(false));
    if (err.response) {
      dispatch(arbitrationsListError(getErrors(err)));
    } else {
      dispatch(arbitrationsListError(err.message));
    }
  }
};
