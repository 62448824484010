import Helmet from 'react-helmet';
import i18next from 'i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useMemo } from 'react';

import { AppState } from 'store/configureStore';
import { PageTitle } from 'store/routing/pageTitle';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

const stateConnect = (state: AppState) => ({
  /* The i18n translation key for the text to be used as the page title */
  titleId: state.app.pageTitle,
});

const connector = connect(stateConnect);

interface Props extends RouterProps, ConnectedProps<typeof connector> {}

/**
 * Page title formatter that adds common suffix.
 *
 * @example formatTitleWithSuffix(t('account')); // "Account | EBlock"
 */
const formatTitleWithSuffix = (title: string) => {
  return joinStrings([title, 'EBlock'], ' | ');
};

/**
 * Wrapper for Helmet which allows passing of a `titleId` to set the page title using `react-i18next`
 */
const Head = ({ titleId, routes }: Props) => {
  let title: string;

  // We can derive that the Error404 page is present when
  // one of loaded routes "path" property has the value
  // of "*", which is set in our `routesConfig`.
  const isError404Page = useMemo(() => !!routes?.find((route) => route?.path === '*'), [routes]);

  if (titleId === PageTitle[Route.HOME]) {
    // Homepage/Dashboard
    // This check is required otherwise a warning is thrown.
    title = t(titleId);
  } else if (!!titleId && i18next.exists(titleId)) {
    title = formatTitleWithSuffix(t(titleId));
  } else if (isError404Page) {
    // Invalid route pathname, set 404 error page title
    title = formatTitleWithSuffix(t('404_page_not_found'));
  } else {
    title = t(PageTitle[Route.HOME]);
    console.warn('Page title:', titleId, 'was not found in translation file!');
  }

  return <Helmet title={title} />;
};

export default withRouter(connect(stateConnect)(Head));
