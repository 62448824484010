import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { TransportRequest } from 'store/shared/api/graph/interfaces/types';
import { TransportJobStoreType, TransportRequestModel } from 'store/admin/transportJobs/list/transportJobsModels';
import { RecordOf } from 'store/shared/models/interfaces';

interface TransportJobDetailsModel {
  /** Transport job/request details. */
  details?: RecordOf<TransportJobStoreType> | RecordOf<TransportRequestModel>;
  /** Whether is saving state. */
  isSaving: boolean;
  /** Selected quote id. */
  selectedQuoteId?: string;
  /** Transport requests list. */
  transportRequests: TransportRequest[];
}

export type TransportJobDetailsProps = ServerRecordOf<TransportJobDetailsModel>;

export const InitialState = ServerRecord<TransportJobDetailsModel>({
  details: null,
  isSaving: false,
  selectedQuoteId: null,
  transportRequests: [], // List used in AddTransportJob dialog
});
