export const broadcastFragment = `
  fragment broadcast on Broadcast {
    id
    auction {
      id
      title
    }
    end
    message
    regionCodes
    start
    status
    type
  }
`;
