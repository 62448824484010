import AuctionItemType from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';

interface AuctionClerkModel {
  auctionItem: AuctionItemType;
  /** Current auction time slot lane information. */
  auctionTimeSlotLane: AuctionTimeSlotLane;
  /** List of auction items that have already ran in the AuctionTimeSlotLane. */
  ranItems: AuctionItemType[];
  /** List of upcoming auction items. */
  upcomingItems: AuctionItemType[];
}

export type AuctionClerkProps = ServerRecordOf<AuctionClerkModel>;

export const InitialState = ServerRecord<AuctionClerkModel>({
  auctionItem: null,
  auctionTimeSlotLane: null,
  ranItems: [],
  upcomingItems: [],
});
