import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import { AuctionUpdateInput, AuctionSettingsUpdateInput } from 'store/shared/api/graph/interfaces/types';
import { auctionSettingsUpdate } from 'store/shared/api/graph/mutations/auctionSettingsUpdate';
import { getAuctionDetails, updateAuctionDetails } from 'store/admin/auctions/details/auctionDetailsApi';

export const auctionDetailsCleared = createAction('ADMIN_AUCTION_DETAILS_CLEARED');
export const auctionDetailsIsLoading = createAction('ADMIN_AUCTION_DETAILS_LOADING');
export const auctionDetailsLoaded = createAction('ADMIN_AUCTION_DETAILS_LOADED');
export const auctionDetailsUpdated = createAction('ADMIN_AUCTION_DETAILS_UPDATED');
export const auctionDetailsUpdating = createAction('ADMIN_AUCTION_DETAILS_UPDATING');
export const auctionSettingsUpdated = createAction('ADMIN_AUCTION_SETTINGS_UPDATED');
export const preloadAuctionDetails = createAction('ADMIN_AUCTION_DETAILS_PRELOADED');
export const setAuctionData = createAction('ADMIN_AUCTION_DETAILS_SETTING_DATA');

export const processGetAuctionDetails = (options, dispatch) => {
  dispatch(auctionDetailsIsLoading());
  return getAuctionDetails(options).then((response) => dispatch(auctionDetailsLoaded(response?.data?.data?.auction)));
};

export const processUpdateAuctionSettings = (options: AuctionSettingsUpdateInput, dispatch: AppDispatch) =>
  auctionSettingsUpdate(options).then((response) =>
    dispatch(auctionSettingsUpdated(response?.data?.data?.auctionSettingsUpdate))
  );

export const processUpdateAuctionDetails = (options: AuctionUpdateInput, dispatch: AppDispatch) =>
  updateAuctionDetails(options).then((response) =>
    dispatch(auctionDetailsUpdated(response?.data?.data?.auctionUpdate))
  );
