import classnames from 'classnames';
import { ChangeEvent } from 'react';

import commercialGlyph from 'glyphs/bodyType/commercial.svg';
import convertibleGlyph from 'glyphs/bodyType/convertible.svg';
import coupeGlyph from 'glyphs/bodyType/coupe.svg';
import hatchbackGlyph from 'glyphs/bodyType/hatchback.svg';
import sedanGlyph from 'glyphs/bodyType/sedan.svg';
import suvGlyph from 'glyphs/bodyType/suv.svg';
import truckGlyph from 'glyphs/bodyType/truck.svg';
import vanGlyph from 'glyphs/bodyType/van.svg';
import wagonGlyph from 'glyphs/bodyType/wagon.svg';

import BaseClass from 'components/ui/shared/base';
import Checkbox from 'forms/shared/checkbox';
import Declarations from 'components/sections/inventoryItem/addModify/vehicleFormPanes/segments/declarations';
import InputText from 'forms/shared/inputText';
import RadioButtons, { RadioButtonLabel } from 'forms/shared/radioButtons';
import Select from 'forms/shared/select';
import SelectLocation from 'components/sections/inventoryItem/addModify/selectLocation/selectLocation';
import { AccountSection } from 'layouts/accountLayouts/accountLayouts';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import {
  BuilderOption,
  BuilderOptionInt,
  BuilderOptionLocation,
  InventoryItemOption,
  VehicleMetaData,
} from 'store/shared/api/graph/interfaces/types';
import { ClearableFieldInput } from 'constants/enums/forms';
import { ErrorMessages } from 'constants/errors';
import { getIdByValue, getValueById } from 'utils/arrayUtils';
import { t } from 'utils/intlUtils';

import style from './vehicleDetails.scss';

const CHARGING_CABLE_NOT_PLUG_IN_ID = 'NOT_PLUG_IN';
const CHARGING_CABLE_OPTION_ID = 'Charging Cable';

export enum ChargingCableFuelType {
  ELECTRIC = 'Electric',
  FLEX_FUEL_ELECTRIC_HYBRID = 'Flex Fuel / Electric Hybrid',
  GASOLINE_ELECTRIC_HYBRID = 'Gasoline / Electric Hybrid',
}

export const FUEL_TYPES_CHARGING_CABLE = Object.values<string>(ChargingCableFuelType);

const bodyTypeGlpyhs = Object.freeze({
  Coupe: coupeGlyph,
  Hatchback: hatchbackGlyph,
  Convertible: convertibleGlyph,
  Sedan: sedanGlyph,
  Wagon: wagonGlyph,
  Van: vanGlyph,
  SUV: suvGlyph,
  Truck: truckGlyph,
  Commercial: commercialGlyph,
});

interface Props {
  /** Callback function to clear error messages. */
  clearError: () => void;
  /** The declarations available for selection. */
  declarationsMeta?: VehicleMetaData;
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Callback function to create inventory item updates. */
  handleSubmitCreateInventoryItem: (object?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Callback function to submit inventory item updates. */
  handleSubmitUpdateInventoryItem: (object?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs['results'];
  /** Inventory item id. */
  inventoryItemId?: string;
  /** Callback function to scroll the modal by offset value. */
  scrollModalByValue: (scrollOffset: number) => void;
  /** Name to the section reference. */
  sectionRef?: string;
  /** Callback function to set vehicle information. */
  setVehicle: (option: AddModifyVehicleProps) => void;
}

interface State {
  /** Whether has displacement or not. */
  hasDisplacement: boolean;
  /** Whether has mileage amount or not. */
  hasMileageAmount: boolean;
  /** Whether has trim or not. */
  hasTrim: boolean;
  /** Whether has vin or not. */
  hasVin: boolean;
  /** Whether has pickup location enabled or not. */
  pickupLocationEnabled: boolean;
}

class VehicleDetails extends BaseClass<Props, State> {
  private bodyType: HTMLDivElement | null = null;
  private chargingCable: HTMLDivElement | null = null;
  private container: HTMLDivElement | null = null;
  private cylinders: HTMLDivElement | null = null;
  private declarations: HTMLDivElement | null = null;
  private displacement: HTMLDivElement | null = null;
  private driveTrain: HTMLDivElement | null = null;
  private exteriorColor: HTMLDivElement | null = null;
  private fuelType: HTMLDivElement | null = null;
  private interiorColor: HTMLDivElement | null = null;
  private location: HTMLDivElement | null = null;
  private mileage: HTMLDivElement | null = null;
  private numberOfDoors: HTMLDivElement | null = null;
  private numberOfPassengers: HTMLDivElement | null = null;
  private selectedOptions: HTMLDivElement | null = null;
  private transmission: HTMLDivElement | null = null;
  private vehicleTitle: HTMLDivElement | null = null;
  private vinDetails: HTMLDivElement | null = null;
  private bodyTypeOptions: RadioButtonLabel[] = [];
  private driveTrainsOptions: RadioButtonLabel[] = [];
  private chargingCableOptions: RadioButtonLabel[] = [];
  private exteriorColorOptions: RadioButtonLabel[] = [];
  private fuelTypeOptions: RadioButtonLabel<ChargingCableFuelType>[] = [];
  private interiorColorOptions: RadioButtonLabel[] = [];
  private transmissionOptions: RadioButtonLabel[] = [];

  constructor(props: Props) {
    super(props);
    const {
      inventoryItem: {
        bodyType,
        chargingCable,
        driveTrain,
        exteriorColor,
        fuelType,
        interiorColor,
        pickupLocation,
        transmission,
        vehicle: {
          displacement,
          mileage: { amount: mileageAmount },
          trim,
          vin,
        },
      },
      setVehicle,
    } = props;

    this.bodyTypeOptions =
      bodyType
        ?.filter(Boolean)
        .filter((type) => type.id !== 'Recreational')
        .map<RadioButtonLabel>((type) => ({ title: type.value, value: type.id, svg: bodyTypeGlpyhs[type.id] })) || [];
    this.chargingCableOptions = this.getRadioButtonLabels(chargingCable);
    this.driveTrainsOptions = this.getRadioButtonLabels(driveTrain);
    this.exteriorColorOptions = this.getRadioButtonLabels(exteriorColor);
    this.fuelTypeOptions = this.getRadioButtonLabels(fuelType) as RadioButtonLabel<ChargingCableFuelType>[];
    this.interiorColorOptions = this.getRadioButtonLabels(interiorColor);
    this.transmissionOptions = this.getRadioButtonLabels(transmission);

    this.state = {
      hasDisplacement: !!displacement,
      hasMileageAmount: !!mileageAmount,
      hasTrim: !!trim,
      hasVin: !!vin,
      pickupLocationEnabled: !!this.getSelectedId(pickupLocation) || false,
    };

    // Set initial charging cable value
    setVehicle({ chargingCable: chargingCable?.find((item) => !!item?.selected)?.id || null });
  }

  componentDidMount() {
    super.componentDidMount();

    const { scrollModalByValue, sectionRef } = this.props;

    if (sectionRef && this[sectionRef]) {
      const offsetValue = this[sectionRef].getBoundingClientRect().top;
      scrollModalByValue(offsetValue);
    }
  }

  getRadioButtonLabels(options: (BuilderOption | null)[] | null | undefined) {
    return options?.filter(Boolean).map((item) => ({ title: item.value, value: item.id })) || [];
  }

  getSelectedId(options: (BuilderOption | BuilderOptionInt | BuilderOptionLocation | null)[] | null | undefined) {
    return (options?.find((item) => !!item?.selected) || {}).id;
  }

  getSelectedValue(options: (BuilderOption | BuilderOptionInt | null)[] | null | undefined) {
    return (options?.find((item) => !!item?.selected) || {}).value || '';
  }

  getSegmentClasses = (refName: string, hasCustom?: boolean) => {
    const { sectionRef } = this.props;

    return classnames(
      style.segment,
      hasCustom && style.paddingBottomCustom,
      sectionRef && sectionRef === refName && style.highlighted
    );
  };

  getCheckboxArray = (e: ChangeEvent<HTMLInputElement>, value: string, array: (string | InventoryItemOption)[]) =>
    e.target.checked ? [...array, value] : array.filter((val) => val !== value);

  concatArrayValues(array: BuilderOptionInt[], num?: number) {
    return array.map((item) => ({ id: item.id.toString(), value: item.value.split(' ')[0] })).slice(0, num);
  }

  resetVehicleDetailsByKey(key: string) {
    const arr = ['year', 'makeId', 'modelId', 'subModelId', 'trimId', 'trim'];
    return arr.slice(arr.indexOf(key) + 1, arr.length).reduce((obj, v) => {
      const newObj = obj;
      newObj[v] = '';
      return newObj;
    }, {});
  }

  handleSelectChange = (
    key: string,
    array: (BuilderOption | BuilderOptionInt | BuilderOptionLocation)[] | undefined,
    value: string | undefined
  ) => {
    if (value) {
      const { handleSubmitCreateInventoryItem, handleSubmitUpdateInventoryItem, inventoryItemId, setVehicle } =
        this.props;

      const valueId = getIdByValue(array, value);
      const obj = { ...this.resetVehicleDetailsByKey(key), [key]: valueId };

      this.handleClearError();
      this.forceUpdate();
      setVehicle(obj);
      if (inventoryItemId) {
        handleSubmitUpdateInventoryItem(obj, false);
      } else {
        handleSubmitCreateInventoryItem(obj, false);
      }
    }
  };

  handleOnBlur = (key: string, originalValue: string | number, value: string | number | undefined) => {
    const { setVehicle } = this.props;
    if (originalValue !== value) {
      setVehicle({ [key]: value });
    }
  };

  handleOnBlurAmount = (key: string, obj: AddModifyVehicleProps['mileage'], value: string | undefined) => {
    const { setVehicle } = this.props;
    const { amount: originalValue } = obj || {};
    const amount = (value && parseInt(value, 10)) || null;

    if (originalValue !== amount) {
      setVehicle({ [key]: { ...obj, amount } });
    }
  };

  handleClearError = () => {
    if (this.props.errorMessages.length) {
      this.props.clearError();
    }
  };

  handleLocationChange = (
    locationId?: string | null,
    pickupLocationId?: string | null,
    isCompoundEnabled?: boolean
  ) => {
    const { setVehicle, inventoryItemId, handleSubmitUpdateInventoryItem, handleSubmitCreateInventoryItem } =
      this.props;
    const obj = { pickupLocationId, locationId };

    this.forceUpdate();
    setVehicle(obj);
    this.setState({ pickupLocationEnabled: !!isCompoundEnabled });
    if (inventoryItemId) {
      handleSubmitUpdateInventoryItem(obj, false);
    } else {
      handleSubmitCreateInventoryItem(obj, false);
    }
  };

  getSelectedFuelType = (): string | undefined => {
    const {
      inventoryItem: {
        fuelType: fuelTypes,
        vehicle: { fuelType },
      },
    } = this.props;
    return fuelType || fuelTypes?.find((type) => type?.selected)?.id;
  };

  isChargingCableRequired = (): boolean => {
    // Whether the selected fuel type requires a charging cable
    const selectedFuelType = this.getSelectedFuelType();
    return !!selectedFuelType && FUEL_TYPES_CHARGING_CABLE.includes(selectedFuelType);
  };

  renderLocationSection() {
    const { pickupLocationEnabled } = this.state;
    const {
      errorMessages,
      inventoryItem: { pickupLocation, startingLocation },
    } = this.props;

    return (
      <div className={this.getSegmentClasses('location')}>
        {!!errorMessages.length && (
          <ul className={style.errorMessageContainer}>
            {errorMessages.map((errorItem, index) => (
              <li key={`errorItem-${index}`}>{errorItem.message}</li>
            ))}
          </ul>
        )}
        <div
          ref={(div) => {
            this.location = div;
          }}
        />
        <SelectLocation
          hasError={!!errorMessages.find((item) => item.name === 'locationId')}
          isCompoundEnabled={pickupLocationEnabled}
          isSmallTheme
          isVehicleBuilderActive
          onChange={this.handleLocationChange}
          onCompoundChange={this.handleLocationChange}
          pickupLocations={pickupLocation?.filter(Boolean)}
          startingLocations={startingLocation?.filter(Boolean)}
        />
      </div>
    );
  }

  renderVinDetailsSection() {
    const { hasVin } = this.state;
    const {
      handleSubmitUpdateInventoryItem,
      inventoryItem: {
        vehicle: { id: vehicleId, vin, stockNumber },
      },
      setVehicle,
    } = this.props;

    return (
      <div className={this.getSegmentClasses('vinDetails')}>
        <div
          ref={(div) => {
            this.vinDetails = div;
          }}
        />
        <div className={classnames(style.segmentLayout, style.segmentLayoutCustom)}>
          <AccountSection
            className={classnames(style.column, style.vinSection)}
            title={t('vin')}
            titleClass={style.title}
          >
            <InputText
              className={style.inputClass}
              defaultValue={vin || undefined}
              onBlur={(e) => {
                const { value } = e?.target || {};
                this.handleOnBlur('vin', vin || '', value);
                if (vehicleId) {
                  handleSubmitUpdateInventoryItem({ vin: value });
                }
              }}
              onChange={(value) => {
                this.handleClearError();
                if (hasVin !== !!value) {
                  this.setState({ hasVin: !!value });
                  setVehicle({ vin: value });
                }
              }}
              placeholder={t('enter_vin')}
              textClassName={style.inputTextClass}
              theme="gray"
              type="text"
            />
          </AccountSection>
          <AccountSection className={style.column} title={t('stock_number')} titleClass={style.title}>
            <InputText
              className={style.inputClass}
              defaultValue={stockNumber || undefined}
              onBlur={(e) => this.handleOnBlur('stockNumber', stockNumber || '', e?.target.value)}
              onChange={this.handleClearError}
              placeholder={t('enter_stock_number')}
              textClassName={style.inputTextClass}
              theme="gray"
              type="text"
            />
          </AccountSection>
        </div>
      </div>
    );
  }

  renderMakeModelSection() {
    const { hasTrim } = this.state;
    const {
      errorMessages,
      inventoryItem: {
        make,
        model,
        subModel,
        trim: trims,
        vehicle: { trimId: trimIdValue, trim },
        year,
      },
      setVehicle,
    } = this.props;
    const makes = make?.filter(Boolean);
    const models = model?.filter(Boolean);
    const subModels = subModel?.filter(Boolean);
    const years = year?.filter(Boolean);
    const makeId = this.getSelectedValue(makes);
    const modelId = this.getSelectedValue(models);
    const subModelId = this.getSelectedValue(subModels);
    const yearId = this.getSelectedValue(years);
    const trimsArray = trims?.filter(Boolean).concat([{ id: 'OTHER', value: t('other'), selected: false }]);
    const trimId = getValueById(trimsArray, trimIdValue);
    const trimDisabled = !trimId || trimIdValue !== 'OTHER';
    const hasErrorByValue = (value) => !!errorMessages.find((item) => item.name === value);

    return (
      <div className={this.getSegmentClasses('vehicleTitle')}>
        <div
          ref={(div) => {
            this.vehicleTitle = div;
          }}
        />
        <div className={classnames(style.topRow, style.segmentLayout)}>
          <AccountSection className={style.column} title={t('year')} titleClass={style.title}>
            <Select
              className={style.inputClass}
              getOptionLabel={({ value }) => value}
              hasError={hasErrorByValue('year')}
              onChange={(e) => this.handleSelectChange('year', years, e?.value)}
              options={years}
              placeholder={t('choose_year')}
              themeOverrides={{ maxMenuHeight: '170px' }}
              value={years?.find(({ value }) => value === yearId) || null}
            />
          </AccountSection>
          <AccountSection
            className={style.column}
            title={t('make')}
            titleClass={classnames(style.title, !yearId && style.disabled)}
          >
            <Select
              className={style.inputClass}
              getOptionLabel={({ value }) => value}
              hasError={hasErrorByValue('makeId')}
              isDisabled={!yearId}
              onChange={(e) => this.handleSelectChange('makeId', makes, e?.value)}
              options={makes}
              placeholder={t('choose_make')}
              themeOverrides={{ maxMenuHeight: '170px' }}
              value={makes?.find(({ value }) => value === makeId) || null}
            />
          </AccountSection>
          <AccountSection
            className={style.column}
            title={t('model')}
            titleClass={classnames(style.title, !models?.length && style.disabled)}
          >
            <Select
              className={style.inputClass}
              getOptionLabel={({ value }) => value}
              hasError={hasErrorByValue('modelId')}
              isDisabled={!makeId}
              onChange={(e) => this.handleSelectChange('modelId', models, e?.value)}
              options={models}
              placeholder={t('choose_model')}
              themeOverrides={{ maxMenuHeight: '170px' }}
              value={models?.find(({ value }) => value === modelId) || null}
            />
          </AccountSection>
        </div>
        <div className={style.segmentLayout}>
          <AccountSection
            className={style.column}
            title={t('submodel')}
            titleClass={classnames(style.title, !subModels?.length && style.disabled)}
          >
            <Select
              className={style.inputClass}
              getOptionLabel={({ value }) => value}
              hasError={hasErrorByValue('subModelId')}
              isDisabled={!modelId}
              onChange={(e) => this.handleSelectChange('subModelId', subModels, e?.value)}
              options={subModels}
              placeholder={t('choose_submodel')}
              themeOverrides={{ maxMenuHeight: '170px' }}
              value={subModels?.find(({ value }) => value === subModelId) || null}
            />
          </AccountSection>
          <AccountSection
            className={style.column}
            title={t('trim')}
            titleClass={classnames(style.title, !subModelId && style.disabled)}
          >
            <Select
              className={style.inputClass}
              getOptionLabel={({ value }) => value}
              isDisabled={!subModelId}
              onChange={(e) => this.handleSelectChange('trimId', trimsArray, e?.value)}
              options={trimsArray}
              placeholder={t('choose_trim')}
              themeOverrides={{ maxMenuHeight: '170px' }}
              value={trimsArray?.find(({ value }) => value === trimId) || null}
            />
          </AccountSection>
          <AccountSection
            className={style.column}
            title={t('other_trim')}
            titleClass={classnames(trimDisabled && style.disabled)}
          >
            <InputText
              className={style.inputClass}
              defaultValue={trim || undefined}
              disabled={trimDisabled}
              onBlur={(e) => this.handleOnBlur('trim', trim || '', e?.target.value)}
              onChange={(value) => {
                this.handleClearError();
                if (hasTrim !== !!value) {
                  this.setState({ hasTrim: !!value });
                  setVehicle({ trim: value });
                }
              }}
              textClassName={style.inputTextClass}
              theme="gray"
              type="text"
            />
          </AccountSection>
        </div>
      </div>
    );
  }

  renderVehicleMileage() {
    const { hasMileageAmount } = this.state;
    const {
      inventoryItem: {
        vehicle: {
          mileage,
          mileage: { amount: mileageAmount, unit: mileageUnit },
        },
      },
      setVehicle,
    } = this.props;

    return (
      <div className={this.getSegmentClasses('mileage')}>
        <div
          ref={(div) => {
            this.mileage = div;
          }}
        />
        <div className={classnames(style.segmentLayout, style.segmentLayoutCustom)}>
          <AccountSection className={style.column} title={t('vehicle_mileage')} titleClass={style.title}>
            <div className={style.inputContainer}>
              <InputText
                className={style.inputCustom}
                defaultValue={mileageAmount ? mileageAmount.toString() : ''}
                onBlur={(e) => this.handleOnBlurAmount('mileage', mileage, e?.target.value)}
                onChange={(value) => {
                  this.handleClearError();
                  if (hasMileageAmount !== !!value) {
                    this.setState({ hasMileageAmount: !!value });
                    setVehicle({ mileage: { amount: parseInt(value, 10) || null, unit: mileageUnit } });
                  }
                }}
                placeholder={t('enter_mileage')}
                textClassName={style.inputTextCustom}
                theme="minimalSmall"
                type="number"
              />
              <RadioButtons
                className={style.innerButtons}
                id="mileage"
                innerButtons
                labels={[
                  { title: t('km'), value: 'km' },
                  { title: t('mi'), value: 'mi' },
                ]}
                onChange={(e) => {
                  setVehicle({ mileage: { amount: mileageAmount, unit: e.target.value } });
                }}
                selectedOption={mileageUnit || undefined}
                textClassName={classnames(style.textRadioButtons, style.innerButtonsText)}
                theme="green"
              />
            </div>
          </AccountSection>
        </div>
      </div>
    );
  }

  renderVehicleInfoSection() {
    const {
      inventoryItem: {
        bodyType: bodyTypes,
        exteriorColor: exteriorColors,
        interiorColor: interiorColors,
        vehicle: { bodyType, exteriorColor, interiorColor },
      },
      setVehicle,
    } = this.props;
    const selectedBodyType = bodyType || this.getSelectedId(bodyTypes);
    return (
      <>
        {selectedBodyType !== 'Recreational' && (
          <div className={this.getSegmentClasses('bodyType', true)}>
            <div
              ref={(div) => {
                this.bodyType = div;
              }}
            />

            <AccountSection className={style.section} title={t('body_type')} titleClass={style.title}>
              <RadioButtons
                id="bodyType"
                labels={this.bodyTypeOptions}
                onChange={(e) => {
                  setVehicle({ bodyType: e.target.value });
                }}
                selectedOption={selectedBodyType}
                squareLabels
                textClassName={style.textRadioButtons}
                theme="green"
              />
            </AccountSection>
          </div>
        )}
        <div className={this.getSegmentClasses('exteriorColor', true)}>
          <div
            ref={(div) => {
              this.exteriorColor = div;
            }}
          />
          <AccountSection className={style.section} title={t('exterior_colour')} titleClass={style.title}>
            <RadioButtons
              id="exteriorColor"
              labels={this.exteriorColorOptions}
              onChange={(e) => {
                setVehicle({ exteriorColor: e.target.value });
              }}
              selectedOption={exteriorColor || this.getSelectedId(exteriorColors)}
              squareLabels
              textClassName={style.textRadioButtons}
              theme="colorful"
            />
          </AccountSection>
        </div>
        <div className={this.getSegmentClasses('interiorColor', true)}>
          <div
            ref={(div) => {
              this.interiorColor = div;
            }}
          />
          <AccountSection className={style.section} title={t('interior_colour')} titleClass={style.title}>
            <RadioButtons
              id="interiorColor"
              labels={this.interiorColorOptions}
              onChange={(e) => {
                setVehicle({ interiorColor: e.target.value });
              }}
              selectedOption={interiorColor || this.getSelectedId(interiorColors)}
              squareLabels
              textClassName={style.textRadioButtons}
              theme="colorful"
            />
          </AccountSection>
        </div>
      </>
    );
  }

  renderVehicleOptions() {
    const {
      inventoryItem: {
        options,
        vehicle: { options: setOptions },
      },
      setVehicle,
    } = this.props;

    const selectedOptions =
      options
        ?.filter(Boolean)
        .filter((option) => option?.selected)
        ?.map((option) => option?.id) || [];
    const filteredOptions = options?.filter((option) => {
      if (option?.id === CHARGING_CABLE_OPTION_ID) {
        // Filter out 'Charging Cable' if none of the eligible fuel types are selected
        return this.isChargingCableRequired();
      }
      return option;
    });
    const checkOption = (id: string) => setOptions && setOptions.indexOf(id) > -1;

    return (
      <div className={this.getSegmentClasses('selectedOptions', true)}>
        <div
          ref={(div) => {
            this.selectedOptions = div;
          }}
        />
        <AccountSection className={style.section} title={t('select_options')} titleClass={style.title}>
          {filteredOptions?.filter(Boolean).map((item) => (
            <Checkbox
              key={item.id}
              checked={checkOption(item.id) || Number(selectedOptions?.indexOf(item.id)) > -1}
              id={item.id}
              isButtonTheme
              onChange={(e) => {
                const newArray = this.getCheckboxArray(e, item.id, setOptions || selectedOptions);
                setVehicle({ options: newArray });
              }}
              text={item.value}
            />
          ))}
        </AccountSection>
      </div>
    );
  }

  renderNumberOfDoors() {
    const {
      inventoryItem: {
        numberOfDoors: numberOfDoorsArray,
        vehicle: { numberOfDoors },
      },
      setVehicle,
    } = this.props;
    const numberOfDoorsId = numberOfDoors || this.getSelectedId(numberOfDoorsArray);

    return (
      <div className={this.getSegmentClasses('numberOfDoors', true)}>
        <div
          ref={(div) => {
            this.numberOfDoors = div;
          }}
        />
        <AccountSection className={style.section} title={t('number_of_doors')} titleClass={style.title}>
          <RadioButtons
            id="numberOfDoors"
            labels={this.concatArrayValues(numberOfDoorsArray?.filter(Boolean) || [], 6)}
            onChange={(e) => {
              setVehicle({ numberOfDoors: parseInt(e.target.value, 10) });
            }}
            selectedOption={numberOfDoorsId ? numberOfDoorsId.toString() : ''}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderNumberOfPassengers() {
    const {
      inventoryItem: {
        numberOfPassengers: numberOfPassengersArray,
        vehicle: { numberOfPassengers },
      },
      setVehicle,
    } = this.props;
    const numberOfPassengersId = numberOfPassengers || this.getSelectedId(numberOfPassengersArray);

    return (
      <div className={this.getSegmentClasses('numberOfPassengers', true)}>
        <div
          ref={(div) => {
            this.numberOfPassengers = div;
          }}
        />
        <AccountSection className={style.section} title={t('number_of_passengers')} titleClass={style.title}>
          <RadioButtons
            id="numberOfPassengers"
            labels={this.concatArrayValues(numberOfPassengersArray?.filter(Boolean) || [], 8)}
            onChange={(e) => {
              setVehicle({ numberOfPassengers: parseInt(e.target.value, 10) });
            }}
            selectedOption={numberOfPassengersId ? numberOfPassengersId.toString() : ''}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderTransmission() {
    const {
      inventoryItem: {
        transmission: transmissions,
        vehicle: { transmission },
      },
      setVehicle,
    } = this.props;

    return (
      <div className={this.getSegmentClasses('transmission', true)}>
        <div
          ref={(div) => {
            this.transmission = div;
          }}
        />
        <AccountSection className={style.section} title={t('transmission')} titleClass={style.title}>
          <RadioButtons
            id="transmission"
            labels={this.transmissionOptions}
            onChange={(e) => {
              setVehicle({ transmission: e.target.value });
            }}
            selectedOption={transmission || this.getSelectedId(transmissions)}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderDriveTrain() {
    const {
      inventoryItem: {
        driveTrain: driveTrains,
        vehicle: { driveTrain },
      },
      setVehicle,
    } = this.props;

    return (
      <div className={this.getSegmentClasses('driveTrain', true)}>
        <div
          ref={(div) => {
            this.driveTrain = div;
          }}
        />
        <AccountSection className={style.section} title={t('drivetrain')} titleClass={style.title}>
          <RadioButtons
            id="driveTrain"
            labels={this.driveTrainsOptions}
            onChange={(e) => {
              setVehicle({ driveTrain: e.target.value });
            }}
            selectedOption={driveTrain || this.getSelectedId(driveTrains)}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderDisplacement() {
    const { hasDisplacement } = this.state;
    const {
      inventoryItem: {
        vehicle: { displacement },
      },
      setVehicle,
    } = this.props;
    const selectedFuelType = this.getSelectedFuelType();
    const isDisabled = (selectedFuelType as ChargingCableFuelType) === ChargingCableFuelType.ELECTRIC;

    return (
      <div className={this.getSegmentClasses('displacement')}>
        <div
          ref={(div) => {
            this.displacement = div;
          }}
        />
        <div
          className={classnames(style.segmentLayout, style.segmentLayoutCustom, isDisabled && style.segmentDisabled)}
        >
          <AccountSection className={style.column} title={t('displacement')} titleClass={style.title}>
            <div className={style.inputContainer}>
              <InputText
                className={style.inputCustom}
                defaultValue={Number(displacement) < 1 ? '' : displacement?.toString()}
                disabled={isDisabled}
                hasSuffixText
                onBlur={(e) => this.handleOnBlur('displacement', Number(displacement), Number(e?.target.value) || -1)}
                onChange={(value) => {
                  this.handleClearError();
                  if (hasDisplacement !== !!value) {
                    this.setState({ hasDisplacement: !!value });
                    setVehicle({ displacement: value });
                  }
                }}
                textClassName={style.inputTextCustom}
                theme="minimalSmall"
                type="float"
              />
              <span className={style.litreText}>L</span>
            </div>
          </AccountSection>
        </div>
      </div>
    );
  }

  renderCylinders() {
    const {
      inventoryItem: {
        cylinders: cylindersArray,
        vehicle: { cylinders },
      },
      setVehicle,
    } = this.props;
    const cylindersId = cylinders || this.getSelectedId(cylindersArray);
    const selectedFuelType = this.getSelectedFuelType();
    const isDisabled = (selectedFuelType as ChargingCableFuelType) === ChargingCableFuelType.ELECTRIC;

    return (
      <div className={classnames(this.getSegmentClasses('cylinders', true), isDisabled && style.segmentDisabled)}>
        <div
          ref={(div) => {
            this.cylinders = div;
          }}
        />
        <AccountSection className={style.section} title={t('number_of_cylinders')} titleClass={style.title}>
          <RadioButtons
            id="cylinders"
            labels={this.concatArrayValues(cylindersArray?.filter(Boolean) || [])}
            onChange={(e) => setVehicle({ cylinders: parseInt(e.target.value, 10) || -1 })}
            selectedOption={cylindersId === -1 ? '' : cylindersId?.toString()}
            textClassName={style.textRadioButtons}
            theme="green"
            toggleEnabled
          />
        </AccountSection>
      </div>
    );
  }

  renderFuelType() {
    const {
      inventoryItem: {
        fuelType: fuelTypes,
        options,
        vehicle: { chargingCable, fuelType, options: vehicleOptions },
      },
      setVehicle,
    } = this.props;

    return (
      <div className={this.getSegmentClasses('fuelType', true)}>
        <div
          ref={(div) => {
            this.fuelType = div;
          }}
        />
        <AccountSection className={style.section} title={t('fuel_type')} titleClass={style.title}>
          <RadioButtons
            id="fuelType"
            labels={this.fuelTypeOptions}
            onChange={(e: { target: { value: ChargingCableFuelType } }) => {
              const fuelTypeNext = e.target.value;

              // Clear `Charging Cable` option if set, and an ineligible
              // fuelType is selected (`FUEL_TYPES_CHARGING_CABLE`)
              const isChargingCableRequired = FUEL_TYPES_CHARGING_CABLE.includes(fuelTypeNext);

              const optionsNext = (
                vehicleOptions ||
                options
                  ?.filter(Boolean)
                  .filter((option) => option.selected)
                  .map<string>((option) => option.id) ||
                []
              ).filter((option) => !(option === CHARGING_CABLE_OPTION_ID && !isChargingCableRequired));

              const hasInvalidChargingCable =
                !isChargingCableRequired ||
                (fuelTypeNext === ChargingCableFuelType.ELECTRIC && chargingCable === CHARGING_CABLE_NOT_PLUG_IN_ID);

              setVehicle({
                ...(fuelTypeNext === ChargingCableFuelType.ELECTRIC && {
                  displacement: -1,
                  cylinders: -1,
                }),
                ...(hasInvalidChargingCable && {
                  chargingCable: ClearableFieldInput.STRING,
                }),
                fuelType: e.target.value,
                options: optionsNext,
              });
            }}
            selectedOption={fuelType || this.getSelectedId(fuelTypes)}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderChargingCable() {
    const {
      inventoryItem: {
        vehicle: { chargingCable },
      },
      setVehicle,
    } = this.props;

    if (!this.isChargingCableRequired()) {
      return null;
    }

    const selectedFuelType = this.getSelectedFuelType();
    // Filter out `NOT_PLUG_IN` option if `Electric` fuelType is selected
    const chargingCableOptions = this.chargingCableOptions.filter(
      (item) =>
        !(
          (selectedFuelType as ChargingCableFuelType) === ChargingCableFuelType.ELECTRIC &&
          item.value === CHARGING_CABLE_NOT_PLUG_IN_ID
        )
    );

    return (
      <div className={this.getSegmentClasses('chargingCable', true)} data-testid="charging-cable">
        <div
          ref={(div) => {
            this.chargingCable = div;
          }}
        />
        <AccountSection className={style.section} title={t('charging_cable')} titleClass={style.title}>
          <RadioButtons
            id="chargingCable"
            labels={chargingCableOptions}
            onChange={(e) => {
              setVehicle({
                chargingCable: e.target.value,
              });
            }}
            selectedOption={chargingCable ?? undefined}
            textClassName={style.textRadioButtons}
            theme="green"
          />
        </AccountSection>
      </div>
    );
  }

  renderDeclarations() {
    const { inventoryItem, setVehicle, declarationsMeta } = this.props;

    return (
      <div className={this.getSegmentClasses('declarations', true)}>
        <div
          ref={(div) => {
            this.declarations = div;
          }}
        />
        <AccountSection className={style.section} title={t('declarations')} titleClass={style.title}>
          <Declarations declarationsMeta={declarationsMeta} inventoryItem={inventoryItem} setVehicle={setVehicle} />
        </AccountSection>
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(div) => {
          this.container = div;
        }}
        className={style.vehicleDetails}
        data-testid="add-vehicle-details"
      >
        {this.renderLocationSection()}
        {this.renderVinDetailsSection()}
        {this.renderMakeModelSection()}
        {this.renderVehicleMileage()}
        {this.renderVehicleInfoSection()}
        {this.renderVehicleOptions()}
        {this.renderNumberOfDoors()}
        {this.renderNumberOfPassengers()}
        {this.renderTransmission()}
        {this.renderDriveTrain()}
        {this.renderDisplacement()}
        {this.renderCylinders()}
        {this.renderFuelType()}
        {this.renderChargingCable()}
        {this.renderDeclarations()}
      </div>
    );
  }
}

export default VehicleDetails;
