import classnames from 'classnames';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import sendIcon from 'glyphs/send-right.svg';

import Button from 'components/ui/shared/button';
import InputText, { InputTextProps, InputTextValueType } from 'forms/shared/inputText';
import Sprite, { Glyph } from 'components/ui/shared/sprite';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { t, tPlural } from 'utils/intlUtils';

import style from './messageInput.scss';

interface Props extends InputTextProps {
  /** Whether the input is disabled or not */
  disabled?: boolean;
  /** Whether is submitting or not. */
  isSubmitting?: boolean;
  /** Callback function to handle on submit event. */
  onSubmit?: (value?: InputTextValueType) => void;
  /** Message recipient name. */
  recipientName?: string;
  /** Send icon rendered in the message input. */
  sendMessageIcon?: Glyph;
}

const MESSAGE_LIMIT: number = 500;
const MESSAGE_LIMIT_TOLERANCE: number = 10;

const MessageInput = ({
  className,
  disabled,
  isSubmitting,
  placeholder,
  recipientName,
  sendMessageIcon = sendIcon,
  ...props
}: Props) => {
  const [value, setValue] = useState<InputTextValueType | undefined>(props?.defaultValue);

  /**
   * Triggers when input value change
   */
  const onChange = useCallback(
    (newValue: InputTextValueType) => {
      setValue(newValue);
      props.onChange?.(newValue);
    },
    [props]
  );

  /**
   * Submit the input value
   */
  const onSubmit = useCallback(
    (e) => {
      e?.preventDefault();
      props.onSubmit?.(value);
      setValue(''); // Reset value to empty
    },
    [value, props]
  );

  /**
   * Number of characters remaining before hitting the allowed limit before sending
   */
  const charactersRemaining: number = useMemo((): number => {
    const currentLength: number = value?.toString().length ?? 0;
    return MESSAGE_LIMIT - currentLength;
  }, [value]);

  /**
   * The message to be displayed if we are within a tolerance of the message length limit
   */
  const getMessageLimitWarning: ReactNode = useMemo((): ReactNode => {
    let charRemainingMessage: string | null = null;
    if (charactersRemaining <= 0) {
      charRemainingMessage = t('chat_no_characters_remaining');
    } else if (charactersRemaining <= MESSAGE_LIMIT_TOLERANCE) {
      charRemainingMessage = tPlural('chat_characters_remaining', charactersRemaining, [charactersRemaining]);
    }

    return charRemainingMessage ? <div className={style.charMessageLimit}>{charRemainingMessage}</div> : null;
  }, [charactersRemaining]);

  return (
    <form className={style.container} onSubmit={onSubmit}>
      {getMessageLimitWarning}
      <InputText
        className={style.input}
        defaultValue={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={recipientName ? t('send_message_to', [recipientName]) : placeholder}
        type="text"
        {...props}
      />
      <Button
        className={classnames(style.sendButton, { [style.messageLimitSend]: !!getMessageLimitWarning })}
        dataTestId="chat-submit-button"
        disabled={value ? !(value as string).trim() || charactersRemaining <= 0 : true}
        onClick={onSubmit}
        theme="none"
      >
        <Sprite glyph={sendMessageIcon} />
      </Button>

      {isSubmitting && (
        <div className={style.submittingOverlay}>
          <SpinnerCentered />
        </div>
      )}
    </form>
  );
};

export default MessageInput;
