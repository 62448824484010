import { List } from 'immutable';

import AuctionItem from 'constants/auctionItem';
import InventoryItem from 'constants/inventoryItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  FacetGroup as FacetGroupType,
  FacetedPageInfo,
  TransportJob as TransportJobType,
  TransportRequest as TransportRequestType,
  TransportJobStatus,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export interface TransportRequestModel extends Omit<TransportRequestType, 'auctionItem' | 'inventoryItem'> {
  auctionItem?: AuctionItem | null;
  inventoryItem: InventoryItem;
}

export type TransportJobStoreType = Omit<TransportJobType, 'status' | 'declined' | 'transportRequests'> & {
  /** Transport job requests */
  transportRequests?: TransportRequestModel[] | null;
  /** Transport job status. */
  transportJobStatus?: TransportJobStatus;
  /** Whether transport job is declined or not. */
  transportJobDeclined?: boolean;
};

interface TransportJobsModel {
  /** Transport jobs facet groups. */
  facetGroups: List<FacetGroupType>;
  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;
  /** Transport job/request list. */
  resultList: List<RecordOf<TransportJobStoreType> | RecordOf<TransportRequestModel>>;
}

export type TransportJobsProps = ServerRecordOf<TransportJobsModel>;

export const InitialState = ServerRecord<TransportJobsModel>({
  facetGroups: List([]),
  pageInfo: null,
  resultList: List([]),
});

export type TransportRequestProps = RecordOf<TransportRequestModel>;

export const TransportRequest = Record<TransportRequestModel>({
  __typename: 'TransportRequest',
  auctionItem: null,
  cancellationDate: null,
  cancellationInitiator: null,
  cancellationReason: null,
  cancellationReasonComment: null,
  cancelledBy: null,
  carrierNote: null,
  created: null,
  createdBy: null,
  declined: null,
  destinationLocation: null,
  distance: null,
  flagged: null,
  id: null,
  inventoryItem: null,
  isCancellable: null,
  isCloneable: null,
  make: null,
  model: null,
  notes: [],
  paidBy: null,
  pickupLocation: null,
  status: null,
  type: null,
  updated: null,
});

export const TransportJob = Record<TransportJobStoreType>({
  __typename: 'TransportJob',
  acceptedQuote: null,
  activeRequestCount: null,
  created: null,
  createdBy: null,
  destinationLocations: null,
  id: null,
  isCancellable: null,
  jobNumber: null,
  notes: [],
  pickupLocations: null,
  quotes: null,
  quotesCompleteCount: null,
  quotesCount: null,
  syncedWithSuperDispatch: null,
  transportJobDeclined: null,
  transportJobStatus: null,
  transportRequests: null,
  updated: null,
  vins: null,
});

export const FacetGroup = Record<FacetGroupType>({
  allowMultiple: false,
  facets: [],
  name: null,
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});
