import { useState } from 'react';

import InventoryItem from 'constants/inventoryItem';
import LinkRow from 'components/ui/lists/details/linkRow';
import RepsSlideOut from 'components/ui/slideOuts/repsSlideOut';
import User from 'constants/user';
import { AuctionRepresentative } from 'store/shared/api/graph/interfaces/types';
import { isAuctionStaff } from 'utils/userUtils';
import { t, tPlural } from 'utils/intlUtils';

import style from './assignedReps.scss';

interface InventoryItemRepsProps {
  onClick: (reps: AuctionRepresentative[]) => void;
  sellerReps: AuctionRepresentative[] | undefined;
}

const InventoryItemReps = ({ onClick, sellerReps }: InventoryItemRepsProps) => {
  const groupedRepsByAuction = sellerReps?.reduce(
    (reps, rep) => {
      const auctionId = rep?.auction?.id;
      if (!auctionId) {
        return reps;
      }

      if (reps?.[auctionId]) {
        return {
          ...reps,
          [auctionId]: [...reps?.[auctionId], rep],
        };
      }

      return { ...reps, [auctionId]: [rep] };
    },
    {} as Record<string, AuctionRepresentative[]>
  );

  if (!groupedRepsByAuction) {
    return null;
  }

  return (
    <>
      {Object.keys(groupedRepsByAuction)?.map((auction) => {
        const reps = groupedRepsByAuction[auction];

        return (
          <LinkRow
            key={reps?.[0]?.auction?.id}
            className={style.auctionReps}
            label={
              <>
                {reps?.[0]?.auction?.title} <span>- {tPlural('x_assigned_reps', reps?.length, [reps?.length])}</span>
              </>
            }
            onClick={() => onClick(groupedRepsByAuction[auction])}
          />
        );
      })}
    </>
  );
};

interface AuctionItemRepsProps {
  buyerReps?: AuctionRepresentative[];
  sellerReps?: AuctionRepresentative[];
  onClick: (reps: AuctionRepresentative[]) => void;
  user: User;
}

const AuctionItemReps = ({ buyerReps, onClick, sellerReps, user }: AuctionItemRepsProps) => (
  <>
    {sellerReps?.length ? (
      <LinkRow
        className={style.auctionReps}
        label={
          isAuctionStaff(user) ? (
            <>
              {t('seller')}{' '}
              <span>
                <span>- {tPlural('x_assigned_reps', sellerReps?.length, [sellerReps?.length])}</span>
              </span>
            </>
          ) : (
            <>{tPlural('x_assigned_reps', sellerReps?.length, [sellerReps?.length])}</>
          )
        }
        onClick={() => onClick(sellerReps)}
      />
    ) : null}

    {buyerReps?.length ? (
      <LinkRow
        className={style.auctionReps}
        label={
          isAuctionStaff(user) ? (
            <>
              {t('buyer')} <span>- {tPlural('x_assigned_reps', buyerReps?.length, [buyerReps?.length])}</span>
            </>
          ) : (
            <>{tPlural('x_assigned_reps', buyerReps?.length, [buyerReps?.length])}</>
          )
        }
        onClick={() => onClick(buyerReps)}
      />
    ) : null}
  </>
);

interface AssignedRepsProps {
  buyerReps?: AuctionRepresentative[];
  inventoryItem: InventoryItem;
  isAuctionItemView: boolean;
  sellerReps?: AuctionRepresentative[];
  user: User;
}

const AssignedReps = ({ buyerReps, inventoryItem, isAuctionItemView, sellerReps, user }: AssignedRepsProps) => {
  const [selectedReps, setSelectedReps] = useState<AuctionRepresentative[]>();

  return (
    <>
      <div>
        {isAuctionItemView ? (
          <AuctionItemReps
            buyerReps={buyerReps}
            onClick={(reps) => setSelectedReps(reps)}
            sellerReps={sellerReps}
            user={user}
          />
        ) : (
          <InventoryItemReps
            onClick={(reps) => setSelectedReps(reps)}
            sellerReps={inventoryItem?.sellerReps?.filter(Boolean)}
          />
        )}
      </div>
      <RepsSlideOut
        isOpen={!!selectedReps}
        onClose={() => setSelectedReps(undefined)}
        reps={selectedReps}
        title={t('assigned_reps')}
        user={user}
      />
    </>
  );
};

export default AssignedReps;
