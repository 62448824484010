const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&callback=mapsApiLoaded&libraries=marker`;

let apiLoading = false;
let apiLoaded = false;
const onLoadedFns: ((googleMaps: typeof google.maps) => void | undefined)[] = [];

export const isApiLoaded = () => apiLoaded;

/**
 * Load Google Maps API and get notified when the api is loaded.
 *
 * @param {function} fn callback function
 */
export const onMapsApiLoaded = (fn) => {
  if (apiLoaded) {
    fn(window.google.maps);
  } else {
    onLoadedFns.push(fn);

    if (!apiLoading) {
      apiLoading = true;

      ((d, s) => {
        (window as any).mapsApiLoaded = () => {
          onLoadedFns.forEach((_fn) => _fn(window.google.maps));
          onLoadedFns.splice(0);
          apiLoaded = true;
        };
        const fjs = d.getElementsByTagName(s)[0];
        const js = d.createElement(s) as HTMLScriptElement;
        js.src = GOOGLE_MAPS_API_URL;
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script');
    }
  }
};
