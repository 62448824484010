import classnames from 'classnames';
import { MouseEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import editGlyph from 'glyphs/edit.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import Swatch from './swatch';
import { AppState } from 'store/configureStore';
import { Locale, t } from 'utils/intlUtils';
import { UserAction } from 'logging/analytics/events/userActions';

import style from './vehicleDetails.scss';

const stateConnect = (state: AppState) => ({
  /** The current user's locale. */
  locale: state.app.user?.locale,
});

const connector = connect(stateConnect);

export interface VehicleFeature {
  /** The category of the feature. */
  category?: string;
  /** The key of the feature. */
  key: string;
  /** The title of the feature. */
  title: string;
  /** The value of the inventory item feature. */
  value?: string | number | null;
}

interface Props extends ConnectedProps<typeof connector> {
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** True when inventory item details are editable. */
  isUpdatable?: boolean;
  /** Function invoked on vehicle feature edit. */
  onEdit?: (feature: VehicleFeature) => void;
  /** Function invoked on user action. */
  trackUserAction?: (userAction: UserAction, event?: MouseEvent<HTMLButtonElement>) => void;
}

class VehicleDetails extends BaseClass<Props> {
  static defaultProps = {
    onEdit: () => {},
  };

  isRequiredField = (field) => {
    const {
      inventoryItemDetails: { incompleteFieldMessages },
    } = this.props;

    return !!incompleteFieldMessages?.find((ifm) => ifm?.field === field);
  };

  formatDisplacement() {
    const {
      inventoryItemDetails: { displacement, cylinders },
    } = this.props;
    return [displacement ? `${displacement} L ` : null, cylinders ? `${cylinders}-Cyl` : null].join('');
  }

  getListConfig = (): (VehicleFeature & { action?: UserAction })[] => {
    const { inventoryItemDetails } = this.props;

    return [
      {
        key: 'year',
        action: UserAction.VDP_YEAR_EDIT_CLICK,
        category: 'vehicleTitle',
        title: 'year',
        value: inventoryItemDetails.year,
      },
      {
        key: 'make',
        action: UserAction.VDP_MAKE_EDIT_CLICK,
        category: 'vehicleTitle',
        title: 'make',
        value: inventoryItemDetails.make,
      },
      {
        key: 'model',
        action: UserAction.VDP_MODEL_EDIT_CLICK,
        category: 'vehicleTitle',
        title: 'model',
        value: inventoryItemDetails.model,
      },
      {
        key: 'trim',
        action: UserAction.VDP_TRIM_EDIT_CLICK,
        category: 'vehicleTitle',
        title: 'trim',
        value: inventoryItemDetails.trim,
      },
      {
        key: 'stockNumber',
        action: UserAction.VDP_STOCK_NUM_EDIT_CLICK,
        category: 'vinDetails',
        title: 'stock_number',
        value: inventoryItemDetails.stockNumber,
      },
      {
        key: 'mileage',
        action: UserAction.VDP_MILEAGE_EDIT_CLICK,
        title: 'mileage',
        value: inventoryItemDetails.mileage?.formattedAmount,
      },
      {
        key: 'numberOfDoors',
        action: UserAction.VDP_DOORS_EDIT_CLICK,
        title: 'doors',
        value: inventoryItemDetails.numberOfDoors,
      },
      {
        key: 'numberOfPassengers',
        action: UserAction.VDP_PASSENGERS_EDIT_CLICK,
        title: 'passengers',
        value: inventoryItemDetails.numberOfPassengers,
      },
      {
        key: 'bodyType',
        action: UserAction.VDP_BODY_TYPE_EDIT_CLICK,
        title: 'body_type',
        value: inventoryItemDetails.bodyType,
      },
      {
        key: 'exteriorColor',
        action: UserAction.VDP_EXTERIOR_COLOUR_EDIT_CLICK,
        title: 'exterior_colour',
        value: inventoryItemDetails.exteriorColor,
      },
      {
        key: 'interiorColor',
        action: UserAction.VDP_INTERIOR_COLOUR_EDIT_CLICK,
        title: 'interior_colour',
        value: inventoryItemDetails.interiorColor,
      },
      {
        key: 'transmission',
        action: UserAction.VDP_TRANSMISSION_EDIT_CLICK,
        title: 'transmission',
        value: inventoryItemDetails.transmission,
      },
      { key: 'displacement', title: 'engine', value: this.formatDisplacement() },
      { key: 'driveTrain', title: 'drivetrain', value: inventoryItemDetails.driveTrain },
      { key: 'fuelType', title: 'fuel_type', value: inventoryItemDetails.fuelType },
      (inventoryItemDetails.chargingCable || this.isRequiredField('chargingCable')) && {
        key: 'chargingCable',
        title: 'charging_cable',
        value: inventoryItemDetails.chargingCable,
      },
    ].filter(Boolean);
  };

  render() {
    const list = this.getListConfig();
    const { isUpdatable, locale, onEdit, trackUserAction } = this.props;

    return (
      <div>
        <ul className={style.featureList}>
          {list.map(({ action, ...feature }, index) => {
            const { key, title, value } = feature;
            const formattedTitle = t(title);
            const featureValue = value?.toString() || '-';
            const isColorFeature =
              /** TODO: EB-2979 Fix color swatch for french locale once backend supports it. */
              (key === 'interiorColor' || key === 'exteriorColor') &&
              !!feature.value &&
              locale !== (Locale.FR_CA as string);
            const isRequired = this.isRequiredField(key);
            const innerClassName = classnames(
              style.featureListItemText,
              !isUpdatable && style.disabled,
              isUpdatable && !value && style.emptyFeatureValue,
              index % 4 === 0 && style.leftColumn,
              ((index + 1) % 4 === 0 || index + 1 === list.length) && style.rightColumn
            );

            return (
              <Button
                key={key}
                className={classnames(style.featureListItem, isRequired && style.isRequired)}
                dataTestId={`${key}-edit-button`}
                onClick={(event) => {
                  if (isUpdatable) {
                    action && trackUserAction?.(action, event);
                    onEdit?.(feature);
                  }
                }}
                theme="none"
              >
                <div className={innerClassName}>
                  <p className={style.featureTitle}>{formattedTitle}</p>
                  <p title={featureValue}>
                    {isColorFeature && <Swatch color={featureValue.toLowerCase()} />}
                    <span className={style.featureValue}>{featureValue}</span>
                    {isUpdatable && (
                      <span className={style.crEditButton} title={t('edit_x', [formattedTitle])}>
                        <Sprite glyph={editGlyph} />
                      </span>
                    )}
                  </p>
                </div>
              </Button>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default connector(VehicleDetails);
