import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import './portal.scss';

interface Props {
  /** The rendered child elements. */
  children: ReactNode;

  /** Function invoked to set tooltip dimensions. */
  onResize?: (width: number, height: number) => void;

  /** The id of the portal. */
  portalId?: string;

  /** The id of the portal root. */
  portalRootId?: string;
}

const Portal = ({ children, onResize, portalId, portalRootId = 'portal-root' }: Props) => {
  const portalRoot = document.getElementById(portalRootId) || document.body.appendChild(document.createElement('div'));
  portalRoot.id = portalRootId;
  portalRoot.dataset.testid = portalRootId;

  const portal = document.createElement('div');
  portal.id = portalId || '';

  useEffect(() => {
    portalRoot.appendChild(portal);

    if (onResize) {
      const { width, height } = portal.getBoundingClientRect();
      onResize(width, height);
    }
    return () => {
      portalRoot.removeChild(portal);
    };
  }, [portal, portalRoot, onResize]);

  return createPortal(children, portal);
};

export default Portal;
