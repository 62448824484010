/**
 * Generates query and field arguments for queries and mutations.
 *
 * @param graphQLArgs {object}
 * @param variables {object}
 * @returns {{queryArgs: string, fieldsArgs: string, variables: {}}}
 */
export const createGraphQLArgs = (graphQLArgs: Record<string, any> = {}, variables: Record<string, any> = {}) => {
  const queryArgs: string[] = [];
  const fieldsArgs: string[] = [];
  const formattedVariables = {};

  Object.keys(graphQLArgs).forEach((arg) => {
    queryArgs.push(`$${arg}: ${graphQLArgs[arg]}`);
    fieldsArgs.push(`${arg}: $${arg}`);
    formattedVariables[arg] = variables[arg] || null;
  });

  return {
    queryArgs: queryArgs.join(', '),
    fieldsArgs: fieldsArgs.join(', '),
    variables: formattedVariables,
  };
};
