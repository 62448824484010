import { MouseEventHandler, useMemo } from 'react';

import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import BuyNowPriceRow from 'components/sections/auctionItem/details/status/statusRows/buyNowPriceRow';
import IfBidsRow from 'components/sections/auctionItem/details/status/statusRows/ifBidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import WorkflowProcessRows from 'components/sections/auctionItem/details/status/statusRows/workflowProcessRows';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarDate, formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './cancelled.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItemDetailsProps;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show buyer. */
  onShowBuyer: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids. */
  onShowIfBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show seller. */
  onShowSeller: MouseEventHandler<HTMLButtonElement>;
  /** The current user. */
  user: User;
}

const Cancelled = ({
  details,
  details: {
    auction,
    deliveryDate,
    format,
    guaranteedAuctionPrice,
    inventoryItem,
    isMyItem,
    ranOn,
    topOffer,
    transactionDetails,
  },
  onShowAuditLog,
  onShowAutobids,
  onShowBids,
  onShowBuyer,
  onShowIfBids,
  onShowRanBefore,
  onShowSeller,
  user,
}: Props) => {
  const auctionName = getAuctionNameByDetails(details);
  const dateRan = formatCalendarTime(ranOn, 'MMMM DD, YYYY [at] h:mm A');
  const estimatedDelivery = deliveryDate && formatCalendarDate(deliveryDate, false);
  const transactionDate = formatCalendarDate(transactionDetails?.created, false);
  const isAuction = isAuctionFormat(format);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  return (
    <div className={style.cancelled} data-testid="cancelled">
      <WorkflowProcessRows details={details} user={user} />
      <Rows>
        {!isMyItem && (
          <Row name={t('purchased_from')} onClick={onShowSeller}>
            {inventoryItem.company.name}
          </Row>
        )}
        {isMyItem && topOffer && (
          <Row name={t('sold_to')} onClick={onShowBuyer}>
            {details.buyer?.company?.name}
          </Row>
        )}
      </Rows>
      <Rows className={style.topRows} data-after={t('cancelled')?.toUpperCase()}>
        <Row dataTestId="status-row-transaction" mergeNext name={t('transaction')}>
          {transactionDetails?.transactionNumber ? `#${transactionDetails?.transactionNumber}` : t('none')}
        </Row>
        <Row name={t(isMyItem ? 'date_sold' : 'date_purchased')}>{transactionDate}</Row>
        <Row mergeNext name={t(isMyItem ? 'sold_for' : 'purchased_for')}>
          {transactionDetails?.purchasePrice.formattedAmount}
        </Row>
        <Row mergeNext name={t('total')}>
          {transactionDetails?.sellTotal?.formattedAmount}
        </Row>
        <Row name={t('view_invoice_for_details')} />
      </Rows>
      <Rows>
        <IfBidsRow details={details} onClick={onShowIfBids} />
        {(isStaffUser || isMyItem) && <BidsRow details={details} onClick={isAuction ? onShowBids : undefined} />}
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row name={t('auction')}>{auctionName}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('date_ran')}>{dateRan}</Row>
        {estimatedDelivery && <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>}
        <ReservePriceRow auctionItem={details} user={user} />
        <BuyNowPriceRow auctionItem={details} user={user} />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction?.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default Cancelled;
