import AuditLog from '../auditLog';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';

import style from './auditLog.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    inventoryItemId: string;
  };
}

const AuditLogSlideOutWrapper = ({ inventoryDetailsData }: Props) => (
  <div className={style.container}>
    <AuditLog inventoryItemId={inventoryDetailsData?.inventoryItemId} />
  </div>
);

export default AuditLogSlideOutWrapper;
