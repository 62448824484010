import classnames from 'classnames';
import { max } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import style from './soundIndicator.scss';

const MIN_TICK_HEIGHT = 3;
const SVG_VIEW_HEIGHT = 18;
const TICK_GAP = 1;
const TICK_NUMBER = 7;
const TICK_WIDTH = 1.5;
const TICK_RADIUS_X = TICK_WIDTH / 2;
const SVG_VIEW_WIDTH = (TICK_GAP + TICK_WIDTH) * TICK_NUMBER - TICK_GAP;

interface Props {
  /** Audio tracks to render the sound indicator for. */
  audioTracks?: MediaStreamTrack[];
  /** CSS style to override default style. */
  className?: string;
}

const SoundIndicator = ({ audioTracks, className }: Props) => {
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const svgElementRef = useRef<SVGSVGElement | null>(null);

  /**
   * Create audio context and analyser
   */
  useEffect(() => {
    let audioContext;
    let source;

    if (audioTracks?.length) {
      audioContext = new AudioContext();
      const audioAnalyser = audioContext.createAnalyser();
      audioAnalyser.fftSize = 64;

      source = audioContext.createMediaStreamSource(new MediaStream(audioTracks));

      source.connect(audioAnalyser);
      setAnalyser(audioAnalyser);
    }

    return () => {
      source?.disconnect();
      audioContext?.close();
    };
  }, [audioTracks]);

  /**
   * Update volume rect
   */
  useEffect(() => {
    let animationFrame: number;

    if (svgElementRef.current && analyser) {
      const frequencyData = new Uint8Array(analyser.frequencyBinCount);
      const rectElements = svgElementRef.current.querySelectorAll('rect');
      const rectsCount = rectElements.length;
      const step = Math.floor(frequencyData.length / rectsCount);

      const animate = () => {
        analyser.getByteFrequencyData(frequencyData);

        rectElements.forEach((rectElement, index) => {
          // Skip the first step since it has too much noise
          const volumeFrequencyBatch = frequencyData.slice((index + 1) * step, (index + 2) * step);
          const volumePeakFrequency = max(volumeFrequencyBatch) || 0;
          const volume = volumePeakFrequency / 255;
          const height = (SVG_VIEW_HEIGHT - MIN_TICK_HEIGHT) * volume + MIN_TICK_HEIGHT;

          // Set rect element's height and y
          rectElement.setAttribute('height', `${height}`);
          rectElement.setAttribute('y', `${(SVG_VIEW_HEIGHT - height) / 2}`);
        });

        animationFrame = requestAnimationFrame(animate);
      };
      animationFrame = requestAnimationFrame(animate);
    }

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [analyser, audioTracks]);

  return (
    <div className={classnames(style.soundIndicator, className)} data-testid="sound-indicator">
      <svg
        ref={svgElementRef}
        fill="none"
        viewBox={`0 0 ${SVG_VIEW_HEIGHT} ${SVG_VIEW_WIDTH}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {Array(TICK_NUMBER)
          .fill('')
          .map((_, index) => {
            return (
              <rect
                key={index}
                fill="currentColor"
                height={MIN_TICK_HEIGHT}
                rx={TICK_RADIUS_X}
                width={TICK_WIDTH}
                x={index * (TICK_WIDTH + TICK_GAP)}
              />
            );
          })}
      </svg>
    </div>
  );
};

export default SoundIndicator;
