import clearGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { getGraphBaseURL, setGraphBaseURL } from 'utils/apiUtils';
import { isProductionEnvironment } from 'utils/deviceInfoUtils';

import style from './environmentModeWarning.scss';

const EnvironmentModeWarning = () =>
  !isProductionEnvironment() && process.env.REACT_APP_GRAPH_BASE !== getGraphBaseURL() ? (
    <div className={style.envModeWarning}>
      <div>{getGraphBaseURL()}</div>
      <Button className={style.clearButton} onClick={() => setGraphBaseURL(null)} theme="none">
        <Sprite glyph={clearGlyph} />
      </Button>
    </div>
  ) : null;

export default EnvironmentModeWarning;
