import classnames from 'classnames';
import { FocusEvent } from 'react';

import BaseClass from 'components/ui/shared/base';
import Label from './label';
import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';
import { formatStandardFloat } from 'utils/numberUtils';

import style from './inputText.scss';

export type InputTextType = 'text' | 'number' | 'password' | 'email' | 'currency' | 'float';

export type InputTextValueType = string | number;

export interface InputTextProps {
  type?: InputTextType;
  theme?: 'error' | 'minimal' | 'minimalSmall' | 'minimalSmallCurrency' | 'gray' | 'bold' | 'default';
  className?: string;
  dataTestId?: string;
  labelClassName?: string;
  textClassName?: string;
  placeholder?: string;
  label?: string;
  /** The label's tooltip description string */
  labelTooltip?: string;
  reference?(input): void;
  defaultValue?: InputTextValueType;
  onChange?(value: any): void;
  onFocus?(event?: FocusEvent<HTMLInputElement>): void;
  onBlur?(event?: FocusEvent<HTMLInputElement>): void;
  autoComplete?: string;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  name?: string;
  hasSuffixText?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  fontLarge?: boolean;
  currencyLarge?: boolean; // TODO: Convert to theme;
  readOnly?: boolean; // TODO: Convert to theme;
}

interface InputTextState {
  value?: number | string;
}

class InputText extends BaseClass<InputTextProps, InputTextState> {
  static defaultProps = {
    type: 'text',
    theme: 'default',
    className: '',
    labelClassName: '',
    label: null,
    labelTooltip: null,
    defaultValue: '',
    currencyLarge: false,
    reference: () => {},
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    autoComplete: 'on',
    autoFocus: false,
    disabled: false,
    hidden: false,
    readOnly: false,
  };

  constructor(props) {
    super(props);

    const { defaultValue } = props;
    const value = defaultValue || defaultValue === 0 ? defaultValue : '';
    this.state = {
      value,
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = prevProps;
    const { defaultValue: defaultValueNext } = this.props;

    if (!defaultValueNext && defaultValue) {
      this.setState({ value: '' });
    } else if (defaultValueNext !== defaultValue) {
      this.setState({ value: defaultValueNext });
    }
  }

  handleChange = (e) => {
    const { value } = e.target;

    e.preventDefault();
    this.setState({ value });
    this.props.onChange?.(value);
  };

  handleNumberChange = (e) => {
    e.preventDefault();

    const { value } = e.target;
    const { type, max } = this.props;
    let nextValue;

    // Note: This is a simple fix to apply a max-length on a numbered input,
    // which is enough for typed input, but in the case of using arrows to increment,
    // there are bugs (value isn't clamped, for example).
    //
    // Better validation for numbered inputs can certainly be improved here.
    if (type === 'number' && max !== undefined) {
      nextValue = formatStandardFloat(value.slice(0, max));
    } else {
      nextValue = formatStandardFloat(value);
    }

    this.setState({ value: nextValue });
    this.props.onChange?.(nextValue);
  };

  render() {
    const { value } = this.state;
    const {
      theme,
      name,
      label,
      labelTooltip,
      className,
      dataTestId,
      labelClassName,
      textClassName,
      placeholder,
      reference,
      type,
      min,
      max,
      onFocus,
      onBlur,
      autoComplete,
      autoFocus,
      fontLarge,
      hasSuffixText,
      disabled,
      hidden,
      currencyLarge,
      readOnly,
    } = this.props;
    const isCurrency = type === 'currency';
    const isNumberType = type && ['currency', 'float', 'number'].includes(type);

    return [
      label && (
        <div key={label} className={style.labelContainer}>
          <Label className={classnames(style[`theme-label-${theme}`], labelClassName)} name={name}>
            {label}
          </Label>
          {labelTooltip && <InfoTooltip className={style.labelTooltip} description={labelTooltip} />}
        </div>
      ),
      <div
        key="input"
        className={classnames(
          style.inputText,
          type && style[type],
          style[`theme-${theme}`],
          fontLarge && style.fontLargeContainer,
          isCurrency && currencyLarge && style.currencyLarge,
          disabled && style.disabled,
          hidden && style.hidden,
          className
        )}
      >
        <input
          ref={reference}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classnames(
            style.input,
            style[`theme-${theme}-input`],
            textClassName,
            fontLarge && style.fontLarge,
            hasSuffixText && style.rightPadding
          )}
          data-testid={dataTestId}
          disabled={disabled}
          id={name}
          maxLength={max}
          min={min}
          name={name}
          onBlur={onBlur}
          onChange={(e) => (isNumberType ? this.handleNumberChange(e) : this.handleChange(e))}
          onFocus={onFocus}
          onWheel={(e) => e?.currentTarget?.blur?.()}
          placeholder={placeholder}
          readOnly={readOnly}
          type={isNumberType ? 'number' : type}
          value={value}
        />
      </div>,
    ];
  }
}

export default InputText;
