import classnames from 'classnames';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import Select from 'forms/shared/select';
import {
  AuctionItemFormat,
  AuctionItemPriceInput,
  AuctionItemUpdateInput,
  AuctionLaneControlType,
  ReserveType,
} from 'store/shared/api/graph/interfaces/types';
import { ClearableFieldInput } from 'constants/enums/forms';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** Validation errors. */
  errorMessages?: ErrorMessages;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when for is submitting. */
  isSubmitting: boolean;
  /** Function used to modify pricing amounts. */
  onSubmit: (shouldSubmit: boolean, options: AuctionItemUpdateInput) => void;
}

interface State {
  /** The set buy now price. */
  buyNowAmount?: number;
  /** The set reserved price. */
  reserveAmount?: number;
  /** The selected reserve type. */
  reserveType?: ReserveType;
  /** The set starting price. */
  startingAmount?: number;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      buyNowAmount: undefined,
      reserveAmount: undefined,
      reserveType: undefined,
      startingAmount: undefined,
    };
  }

  onAmountChange = (amountType: keyof State, value: string) => {
    this.setState({ [amountType]: !value?.toString().length ? undefined : parseFloat(value) });
  };

  onReserveTypeChange = ({ value: reserveType }) => {
    this.setState({ reserveType });
  };

  onSubmit = (shouldSubmit: boolean) => {
    const { buyNowAmount, reserveAmount, reserveType, startingAmount } = this.state;
    const { auctionItem, onSubmit } = this.props;

    const options: AuctionItemUpdateInput = {
      auctionItemId: auctionItem?.id,
      prices: {
        startingAmount,
        ...(auctionItem.format === AuctionItemFormat.GROUNDED && { buyNowAmount, reserveAmount }),
        // Allow for reserve to be cleared for manually controlled units
        ...(auctionItem?.auctionTimeSlotLane?.controlType === AuctionLaneControlType.MANUAL && {
          reserveAmount,
          reserveType,
          startingAmount: ClearableFieldInput.NUMBER,
        }),
        ...(auctionItem.format === AuctionItemFormat.AUCTION && { reserveAmount, reserveType }),
        ...(auctionItem.format === AuctionItemFormat.TIMED_OFFER && { buyNowAmount, startingAmount }),
      },
    };

    onSubmit(shouldSubmit, options);
  };

  buyNowPlaceholder = (auctionItemFormat: AuctionItemFormat) => {
    return auctionItemFormat === AuctionItemFormat.TIMED_OFFER ? t('buy_now_price') : t('buy_now_price_optional');
  };

  startingAmountPlaceholder = (auctionItemFormat: AuctionItemFormat) => {
    return auctionItemFormat === AuctionItemFormat.TIMED_OFFER ? t('minimum_offer_amount') : t('starting_amount');
  };

  modifyPricingDescription = (auctionItemFormat: AuctionItemFormat) => {
    return auctionItemFormat === AuctionItemFormat.TIMED_OFFER
      ? t('modify_pricing_timed_offer_description')
      : t('modify_pricing_appraisal_description');
  };

  render() {
    const { auctionItem, errorMessages, isOpen, isSubmitting } = this.props;
    const hasError = (name: keyof AuctionItemPriceInput) =>
      errorMessages && errorMessages.some((err) => err.name === name);
    const reserveTypeOptions = [
      { value: ReserveType.FLEXIBLE, label: t('flexible') },
      { value: ReserveType.FIRM, label: t('firm') },
    ];

    return (
      <ConfirmDialog
        actionLabel={t('confirm')}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="green"
        title={t('modify_pricing')}
      >
        <FormErrors errorMessages={errorMessages} isSmallDialog />
        <p className={style.instructions}>{this.modifyPricingDescription(auctionItem?.format)}</p>
        <fieldset className={style.inputGroup}>
          {auctionItem?.auctionTimeSlotLane?.controlType !== AuctionLaneControlType.MANUAL && (
            <InputText
              className={classnames(style.amount, hasError('startingAmount') && style.inputError)}
              onChange={(value) => this.onAmountChange('startingAmount', value)}
              placeholder={this.startingAmountPlaceholder(auctionItem.format)}
              textClassName={style.amount}
              theme={hasError('startingAmount') ? 'error' : undefined}
              type="currency"
            />
          )}
          {(auctionItem?.format === AuctionItemFormat.GROUNDED || isAuctionFormat(auctionItem?.format)) && (
            <InputText
              className={classnames(style.amount, hasError('reserveAmount') && style.inputError)}
              dataTestId="reserve-amount-input"
              onChange={(value) => this.onAmountChange('reserveAmount', value)}
              placeholder={t('reserve_amount')}
              textClassName={style.amount}
              theme={hasError('reserveAmount') ? 'error' : undefined}
              type="currency"
            />
          )}
          {(auctionItem?.format === AuctionItemFormat.GROUNDED ||
            auctionItem?.format === AuctionItemFormat.TIMED_OFFER) && (
            <InputText
              className={classnames(style.amount, hasError('buyNowAmount') && style.inputError)}
              dataTestId="buynow-amount-input"
              onChange={(value) => this.onAmountChange('buyNowAmount', value)}
              placeholder={this.buyNowPlaceholder(auctionItem.format)}
              textClassName={style.amount}
              theme={hasError('buyNowAmount') ? 'error' : undefined}
              type="currency"
            />
          )}
          {isAuctionFormat(auctionItem?.format) && (
            <Select
              className={style.select}
              hasError={!!hasError('reserveType')}
              id="selectReserveType"
              onChange={this.onReserveTypeChange}
              options={reserveTypeOptions}
              placeholder={t('select_reserve_type')}
              theme="green"
            />
          )}
        </fieldset>
      </ConfirmDialog>
    );
  }
}

export default Dialog;
