export enum Route {
  HOME = '/',

  ACCOUNT = '/account',
  ACCOUNT_COMPANIES = '/account/companies',
  ACCOUNT_DELETE_ACCOUNT = '/account/delete-account',

  ACKNOWLEDGEMENTS = '/acknowledgements',

  ADMIN = '/admin',
  ADMIN_ARBITRATIONS = '/admin/arbitrations',
  ADMIN_AUCTIONS = '/admin/auctions',
  ADMIN_BROADCASTS = '/admin/broadcasts',
  ADMIN_COMPANIES = '/admin/companies',
  ADMIN_EVENTS = '/admin/events',
  ADMIN_EVENTS_LANE_MANAGEMENT = '/admin/events/lane-management',
  ADMIN_LIVE_LANES_AUCTIONEER = '/admin/auctioneer',
  ADMIN_LIVE_LANES_AUCTION_CLERK = '/admin/auction-clerk',
  ADMIN_LIVE_LANES_MARQUEE = '/admin/marquee',
  ADMIN_REPORT = '/admin/report',
  ADMIN_REPORTS = '/admin/reports',
  ADMIN_USERS = '/admin/users',

  AUTH = '/auth',
  AUTH_CARFAX_USA = '/auth/carfaxusa',
  AUTH_EMPLOYEE = '/auth/employee',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGOUT = '/auth/logout',
  AUTH_PIPELINE = '/auth/pipeline',
  AUTH_REGISTER = '/register',
  AUTH_REQUEST_INVITE = '/auth/request-invite',
  AUTH_RESET_PASSWORD = '/auth/reset-password/:token',

  BROWSE = '/browse',

  BUY = '/buy',
  BUY_COMPARABLES = '/buy/comparables',
  BUY_HOURLY_AUCTION = '/buy/hourly-auction',
  BUY_LIVE_AUCTION = '/buy/live-auction',
  BUY_RUN_LIST = '/buy/run-list',
  BUY_TIMED_AUCTION = '/buy/timed-auction',
  BUY_BUY_NOW = '/buy/buy-now',

  ERROR_404 = '/404',

  FINISHED = '/finished',
  FINISHED_CANCELLED = '/finished/cancelled',
  FINISHED_NO_SALE = '/finished/no-sale',
  FINISHED_PURCHASED = '/finished/purchased',
  FINISHED_SOLD = '/finished/sold',

  MY_BLOCK = '/my-block',
  MY_BLOCK_BUYER = '/my-block/buyer',
  MY_BLOCK_SELLER = '/my-block/seller',

  MY_MARKET_GUIDE = '/my-market-guide',

  NOTIFICATION_SETTINGS = '/notification-settings',

  PENDING = '/pending',
  PENDING_AWAITING_CHECKOUT = '/pending/awaiting-checkout',
  PENDING_DELIVERY = '/pending/pending-delivery',
  PENDING_IN_IF_BID = '/pending/in-if-bid',

  SELL = '/sell',
  SELL_PARKED = '/sell/parked',

  TRANSPORT = '/transport',
  TRANSPORT_CARRIERS = '/transport/carriers',
  TRANSPORT_JOBS = '/transport/transport-jobs',
}
