import { ReactNode, useCallback, useState } from 'react';

import RadioPanel from 'forms/shared/radioPanel';

import style from './radioPanels.scss';

export interface Panel {
  /** The elements in the panel to be rendered */
  children: ReactNode;
  /** The text to be displayed when the panel is selected */
  displayedTextOnSelected?: string;
  /** The value of the panel */
  value: string | number;
}

interface Props {
  /** The function to be called when the panel is selected */
  onClick: (value: string | number) => void;
  /** The panels to be rendered */
  panels: Panel[];
  /** The class name to override the radio button styling */
  radioButtonClassName?: string;
}

const RadioPanels = ({ onClick, panels, radioButtonClassName }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string | number>();

  /**
   * Handles the click event of the radio panel
   */
  const onPanelSelect = useCallback(
    (value: string | number) => {
      setSelectedValue(value);
      onClick(value);
    },
    [onClick]
  );

  return (
    <div className={style.radioPanels}>
      {panels.map((panel) => (
        <RadioPanel
          key={panel.value}
          dataTestId={`radio-panel-${panel.value}`}
          displayedTextOnSelected={panel.displayedTextOnSelected}
          isSelected={panel.value === selectedValue}
          onSelect={onPanelSelect}
          radioButtonClassName={radioButtonClassName}
          value={panel.value}
        >
          {panel.children}
        </RadioPanel>
      ))}
    </div>
  );
};

export default RadioPanels;
