export const companyFragment = `
  fragment company on Company {
    primaryLocation {
      city
      region
      regionCode
      distanceAway
    }
  }
`;
