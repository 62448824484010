import { createAction } from 'redux-actions';

import { getNextAuctions } from 'store/admin/events/list/eventsApi';
import { parseQueryParams } from 'utils/apiUtils';

export const eventsListIsLoading = createAction('ADMIN_EVENTS_LOADING');
export const eventsListLoaded = createAction('ADMIN_EVENTS_LOADED');
export const eventsListCleared = createAction('ADMIN_EVENTS_CLEARED');
export const eventsListUpdateItem = createAction('ADMIN_EVENTS_LIST_UPDATE_ITEM');

export const processGetAuctionTimeSlotsList = (options, dispatch) => {
  dispatch(eventsListIsLoading());

  return getNextAuctions(parseQueryParams(options)).then((response) => {
    const formattedResponse = response?.data.data?.auctionTimeSlotConnection;
    dispatch(eventsListLoaded(formattedResponse));
    return formattedResponse;
  });
};
