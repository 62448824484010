import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import { AuctionItemUpdateInput } from 'store/shared/api/graph/interfaces/types';

export const auctionItemUpdate = ({ auctionItemId, prices = null, saleLightsInput = null }: AuctionItemUpdateInput) =>
  ApiRequest<{ auctionItemUpdate: AuctionItem }>({
    data: {
      operationName: 'auctionItemUpdate',
      query: `
          mutation ($auctionItemId: String!, $prices: AuctionItemPriceInput, $saleLightsInput: AuctionItemSaleLightsInput) {
            auctionItemUpdate(input: { auctionItemId: $auctionItemId, prices: $prices, saleLightsInput: $saleLightsInput }) {
              id
              reserve {
                amount
                formattedAmount
                formattedAmountRounded
              }
              saleLights
            }
          }
        `,
      variables: {
        auctionItemId,
        prices,
        saleLightsInput,
      },
    },
  });
