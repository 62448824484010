import classnames from 'classnames';

import AssignedReps from 'components/sections/inventoryItem/details/slideOut/assignedReps';
import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Chip from 'components/ui/chip/chip';
import Comparables from 'components/sections/inventoryItem/details/comparables/comparables';
import ConditionReport from 'components/sections/inventoryItem/details/conditionReport/conditionReport';
import Fees from 'components/sections/inventoryItem/details/feesAndValues/fees';
import InteractiveText from 'components/ui/shared/interactiveText';
import InventoryItem from 'constants/inventoryItem';
import LastKnownMileageRow from 'components/sections/auctionItem/details/status/statusRows/lastKnownMileageRow';
import LegacyFees from 'components/sections/inventoryItem/details/feesAndValues/feesLegacy';
import LinkWithArrow from 'components/ui/shared/linkWithArrow';
import PickupLocation from 'components/sections/inventoryItem/details/pickupLocation';
import SellerDetails from 'components/sections/inventoryItem/details/seller';
import SellerRequestedValues from 'components/sections/inventoryItem/details/sellerRequestedValues/sellerRequestedValues';
import TransportDetails from 'components/sections/inventoryItem/details/transportDetails';
import User from 'constants/user';
import Values from 'components/sections/inventoryItem/details/feesAndValues/values';
import VehicleDetails, { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { AnchorTabsConfig } from 'components/sections/inventoryItem/details/anchorTabs';
import { AuctionRepresentative } from 'store/shared/api/graph/interfaces/types';
import { DetailsSection } from 'layouts/list/listLayout';
import { FeeType } from 'forms/admin/auctions/addFeeForm';
import { Location } from 'constants/reactRouter';
import { LooseObject } from 'constants/objects';
import { PaneIndex } from '../addModify/vehicleForm';
import { Representative } from 'components/ui/lists/details/reps';
import { UserAction } from 'logging/analytics/events/userActions';
import { formatDate } from 'utils/dateUtils';
import { hasSystemPermission, isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

import style from './inventoryItemDetails.scss';

export const vehicleFeatures: Record<string, VehicleFeature> = {
  declarations: { key: 'declarations', title: 'declarations' },
  selectedOptions: { key: 'selectedOptions', title: 'options' },
  damage: { key: 'damagePhotos', title: 'damage' },
  photos: { key: 'photos', title: 'photos' },
  score: { key: 'score', title: 'score' },
  description: { key: 'description', title: 'seller_notes' },
  carfax: { key: 'carfax', title: 'carfax' },
  generalCondition: { key: 'generalCondition', title: 'general_condition' },
  tireCondition: { key: 'tireCondition', title: 'tire_condition' },
};

interface Props {
  /** Mapping of section name to anchor tab config. */
  anchorTabsConfig: AnchorTabsConfig;
  /** The auction item details. */
  auctionItem?: AuctionItem;
  /** Function invoked when inventory option clicked. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
  /** The inventory item details. */
  inventoryItemDetails: InventoryItem;
  /** True when item is sold as is. */
  isAsIs?: boolean;
  /** True when item is assured. */
  isAssured?: boolean;
  /** True when navigated from auction item. */
  isAuctionItemView?: boolean;
  /** True when inventory item details are editable. */
  isUpdatable: boolean;
  /** The current url information for the component. */
  location?: Location;
  /** Function invoked on add/edit button click. */
  openAddModifyModal?: (section: string, index: number) => void;
  /** The current user. */
  user: User;
}

class InventoryItemDetails extends BaseClass<Props> {
  static defaultProps = {
    auctionItem: undefined,
    isAsIs: false,
    isAssured: false,
    isAuctionItemView: false,
    isMyItem: false,
    location: null,
  };

  getPaneIndexByKey(key) {
    if (key === 'damagePhotos' || key === 'photos') {
      return PaneIndex.PHOTOS;
    }
    if (key === 'score' || key === 'description' || key === 'carfax') {
      return PaneIndex.DESCRIPTION;
    }
    if (key === 'generalCondition' || key === 'tireCondition') {
      return PaneIndex.TIRES;
    }
    return PaneIndex.VEHICLE_DETAILS;
  }

  handleOpenAddModifyModal = (feature: VehicleFeature) => {
    const { openAddModifyModal } = this.props;
    const { key, category } = feature;
    const section = category || key;
    const paneIndex = this.getPaneIndexByKey(key);

    return openAddModifyModal?.(section, paneIndex);
  };

  trackUserAction = (action: UserAction) => {
    const { auctionItem, inventoryItemDetails, isAuctionItemView } = this.props;
    action && isAuctionItemView
      ? trackUserActionWithAuctionItemAttributes(action, auctionItem)
      : trackUserActionWithInventoryItemAttributes(action, inventoryItemDetails);
  };

  onAddModifyFeature(feature: VehicleFeature, action?: UserAction) {
    const { isUpdatable } = this.props;
    return isUpdatable
      ? () => {
          action && this.trackUserAction(action);
          this.handleOpenAddModifyModal(feature);
        }
      : null;
  }

  render() {
    const {
      anchorTabsConfig,
      auctionItem,
      handleFeatureClicked,
      inventoryItemDetails,
      isAsIs,
      isAssured,
      isAuctionItemView,
      isUpdatable,
      openAddModifyModal,
      user,
    } = this.props;
    const { conditionReport, capturedBy, createdBy, selectedOptions, sellerNotes } = inventoryItemDetails || {};
    const activeDeclarations = conditionReport && conditionReport.activeDeclarations;
    const hasActiveDeclarations = activeDeclarations && !!activeDeclarations.length;
    const hasSelectedOptions = selectedOptions && !!selectedOptions.length;

    const buyerReps = auctionItem?.buyerReps;
    const sellerReps = auctionItem?.sellerReps || inventoryItemDetails?.sellerReps;

    return (
      <div className={classnames(style.inventory)}>
        {anchorTabsConfig.ASSIGNED_REPS.isVisible ? (
          <DetailsSection
            contentClassName={style.assignedRepsContent}
            hasContentPadding={false}
            id={anchorTabsConfig.ASSIGNED_REPS.id}
            title={t('assigned_reps')}
          >
            <AssignedReps
              buyerReps={buyerReps?.filter(Boolean)}
              inventoryItem={inventoryItemDetails}
              isAuctionItemView={!!isAuctionItemView}
              sellerReps={sellerReps?.filter(Boolean)}
              user={user}
            />
          </DetailsSection>
        ) : null}

        {anchorTabsConfig.CAPTURED_BY.isVisible && (
          <DetailsSection id={anchorTabsConfig.CAPTURED_BY.id} title={t('captured_by')}>
            {hasSystemPermission(user, ['SYSTEM_VIEW_ALL_USERS']) ? (
              <LinkWithArrow to={`/admin/users?id=${capturedBy?.id}`}>
                <Representative
                  additionalDetails={[t('captured_x', [formatDate(inventoryItemDetails?.captured)])]}
                  rep={{ user: capturedBy } as AuctionRepresentative}
                />
              </LinkWithArrow>
            ) : (
              <Representative
                additionalDetails={[t('captured_x', [formatDate(inventoryItemDetails?.captured)])]}
                rep={{ user: capturedBy } as AuctionRepresentative}
              />
            )}
          </DetailsSection>
        )}

        {anchorTabsConfig.REGISTERED_BY.isVisible && (
          <DetailsSection id={anchorTabsConfig.REGISTERED_BY.id} title={t('registered_by')}>
            {hasSystemPermission(user, ['SYSTEM_VIEW_ALL_USERS']) ? (
              <LinkWithArrow to={`/admin/users?id=${user?.id}`}>
                <Representative
                  additionalDetails={[t('registered_x', [formatDate(inventoryItemDetails?.created)])]}
                  rep={{ user: createdBy } as AuctionRepresentative}
                />
              </LinkWithArrow>
            ) : (
              <Representative
                additionalDetails={[t('registered_x', [formatDate(inventoryItemDetails?.created)])]}
                rep={{ user: createdBy } as AuctionRepresentative}
              />
            )}
          </DetailsSection>
        )}

        {anchorTabsConfig.DECLARATIONS.isVisible && (
          <DetailsSection
            id={anchorTabsConfig.DECLARATIONS.id}
            isEmpty={!hasActiveDeclarations}
            onClickSubButton={this.onAddModifyFeature(
              vehicleFeatures.declarations,
              hasActiveDeclarations ? UserAction.VDP_EDIT_DECLARATIONS_CLICK : UserAction.VDP_ADD_DECLARATIONS_CLICK
            )}
            subButtonType={hasActiveDeclarations ? 'edit' : 'add'}
            title={t(hasActiveDeclarations ? 'declarations' : 'no_declarations')}
          >
            <div className={style.declarations}>
              {activeDeclarations?.map((item) => (
                <Chip key={item.name} className={style.declaration} label={item.name} />
              ))}
            </div>
            <LastKnownMileageRow
              className={style.lastKnownMileageRow}
              inventoryItem={inventoryItemDetails}
              onEdit={() => this.handleOpenAddModifyModal(vehicleFeatures?.declarations)}
            />
          </DetailsSection>
        )}

        {anchorTabsConfig.COMPARABLES.isVisible && (
          <Comparables
            id={anchorTabsConfig.COMPARABLES.id}
            inventoryItemId={auctionItem?.inventoryItem?.id || inventoryItemDetails?.id}
            onClick={() => this.trackUserAction(UserAction.COMPARABLES_VIEW_VEHICLES_CLICK)}
          />
        )}

        {anchorTabsConfig.CONDITION_REPORT.isVisible && (
          <DetailsSection id={anchorTabsConfig.CONDITION_REPORT.id} title={t('condition_report')}>
            <ConditionReport
              handleFeatureClicked={handleFeatureClicked}
              handleOpenAddModifyModal={this.handleOpenAddModifyModal}
              inventoryItemDetails={inventoryItemDetails}
              isAsIs={isAsIs}
              isAssured={isAssured}
              isUpdatable={isUpdatable}
              trackUserAction={this.trackUserAction}
              user={user}
            />
          </DetailsSection>
        )}

        {anchorTabsConfig.OPTIONS.isVisible && (
          <DetailsSection
            id={anchorTabsConfig.OPTIONS.id}
            isEmpty={!hasSelectedOptions}
            onClickSubButton={this.onAddModifyFeature(
              vehicleFeatures.selectedOptions,
              hasSelectedOptions ? UserAction.VDP_EDIT_OPTIONS_CLICK : UserAction.VDP_ADD_OPTIONS_CLICK
            )}
            subButtonType={hasSelectedOptions ? 'edit' : 'add'}
            title={t(hasSelectedOptions ? 'options' : 'no_options')}
          >
            <div className={style.options}>
              {selectedOptions?.map((item) => <Chip key={item.name} color="gray-lightest" label={item.name} />)}
            </div>
          </DetailsSection>
        )}

        {anchorTabsConfig.SELLER_NOTES.isVisible && (
          <DetailsSection
            id={anchorTabsConfig.SELLER_NOTES.id}
            isEmpty={!sellerNotes}
            onClickSubButton={this.onAddModifyFeature(
              vehicleFeatures.description,
              sellerNotes ? UserAction.VDP_EDIT_SELLER_NOTES_CLICK : UserAction.VDP_ADD_SELLER_NOTES_CLICK
            )}
            subButtonType={sellerNotes ? 'edit' : 'add'}
            title={sellerNotes ? t('seller_notes') : t('no_seller_notes')}
          >
            {sellerNotes && (
              <p className={style.sellerNotes}>
                <InteractiveText className={style.sellerNotesContent}>{sellerNotes}</InteractiveText>
              </p>
            )}
          </DetailsSection>
        )}

        {anchorTabsConfig.VEHICLE_DETAILS.isVisible && (
          <DetailsSection id={anchorTabsConfig.VEHICLE_DETAILS.id} title={t('vehicle_details')}>
            <VehicleDetails
              inventoryItemDetails={inventoryItemDetails}
              isUpdatable={this.props.isUpdatable}
              onEdit={this.handleOpenAddModifyModal}
              trackUserAction={this.trackUserAction}
            />
          </DetailsSection>
        )}

        {anchorTabsConfig.SOLD_BY.isVisible && (
          <SellerDetails
            handleFeatureClicked={handleFeatureClicked}
            id={anchorTabsConfig.SOLD_BY.id}
            inventoryItemDetails={inventoryItemDetails}
            onClick={() => this.trackUserAction(UserAction.VDP_SOLD_BY_SELLER_CLICK)}
          />
        )}

        {anchorTabsConfig.PICK_UP_LOCATION.isVisible && (
          <PickupLocation
            id={anchorTabsConfig.PICK_UP_LOCATION.id}
            inventoryItemDetails={inventoryItemDetails}
            isDialogMode={isAuctionItemView}
            isEditable={
              isAuctionItemView
                ? isAuctionStaff(user, auctionItem?.auction?.id) ||
                  (auctionItem?.status && ['NO_SALE', 'SALE_CANCELLED'].includes(auctionItem?.status))
                : true
            }
            onConfirmClick={() => this.trackUserAction(UserAction.VDP_PICK_UP_LOCATION_EDIT_CONFIRM_CLICK)}
            onEditClick={() => this.trackUserAction(UserAction.VDP_PICK_UP_LOCATION_EDIT_CLICK)}
            onViewMapClick={() => this.trackUserAction(UserAction.VDP_PICK_UP_LOCATION_VIEW_MAP_CLICK)}
            openAddModifyModal={openAddModifyModal}
            type={isAuctionItemView ? 'auctionItem' : 'inventoryItem'}
          />
        )}

        <TransportDetails inventoryItem={inventoryItemDetails} isInventoryItem={!isAuctionItemView} />

        <Fees
          {...(isAuctionItemView && auctionItem
            ? {
                feeType: FeeType.AUCTION_ITEM,
                auctionItem,
              }
            : {
                feeType: FeeType.INVENTORY,
                inventoryItem: inventoryItemDetails,
              })}
          user={user}
        />

        <LegacyFees
          auctionItem={auctionItem}
          inventoryItem={inventoryItemDetails}
          isInventoryItem={!isAuctionItemView}
          user={user}
        />

        {anchorTabsConfig.VALUES.isVisible && (
          <Values
            id={anchorTabsConfig.VALUES.id}
            inventoryItem={inventoryItemDetails}
            isInventoryItem={!isAuctionItemView}
            trackUserAction={this.trackUserAction}
            user={user}
          />
        )}

        {anchorTabsConfig.SELLER_REQUESTED_VALUES.isVisible && (
          <SellerRequestedValues
            id={anchorTabsConfig.SELLER_REQUESTED_VALUES.id}
            inventoryItem={inventoryItemDetails}
          />
        )}
      </div>
    );
  }
}

export default InventoryItemDetails;
