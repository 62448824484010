import { EnumTranslationMap } from 'utils/formUtils';

export enum AuctionFeeVerified {
  ALL_VEHICLES = 'ALL_VEHICLES',
  VERIFIED = 'VERIFIED',
  NON_VERIFIED = 'NON_VERIFIED',
}

export enum AuctionFeeBuyerShield {
  ALL_VEHICLES = 'ALL_VEHICLES',
  BUYER_SHIELD_VEHICLES = 'BUYER_SHIELD_VEHICLES',
  NON_BUYER_SHIELD_VEHICLES = 'NON_BUYER_SHIELD_VEHICLES',
}

export const auctionFeeVerifiedTranslationMap: EnumTranslationMap<typeof AuctionFeeVerified> = {
  ALL_VEHICLES: 'all_vehicles',
  VERIFIED: 'verified_vehicles',
  NON_VERIFIED: 'non_verified_vehicles',
};

export const auctionFeeBuyerShieldTranslationMap: EnumTranslationMap<typeof AuctionFeeBuyerShield> = {
  ALL_VEHICLES: 'all_vehicles',
  BUYER_SHIELD_VEHICLES: 'buyer_shield_vehicles',
  NON_BUYER_SHIELD_VEHICLES: 'non_buyer_shield_vehicles',
};
