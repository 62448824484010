import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import {
  Auction,
  AuctionConnection,
  AuctionTimeSlot,
  AuctionTimeSlotLaneopenRunNumbersArgs,
  MutationauctionItemCreateArgs,
  QueryauctionConnectionArgs,
  QueryauctionItemSuggestedSaleLightsArgs,
  QueryauctionTimeSlotArgs,
  SaleLight,
} from 'store/shared/api/graph/interfaces/types';
import { auctionItemSchemaFragments, getAuctionItemSchema } from 'store/auctionItemDetails/auctionItemDetailsApi';

export const getAuctionSubmissionList = ({ regionCode }: QueryauctionConnectionArgs) =>
  ApiRequest<{ auctionConnection: AuctionConnection }>({
    data: {
      operationName: 'getAuctionSubmissionList',
      query: `
        query ($regionCode: String) {
          auctionConnection(first: 50, after: "0", regionCode: $regionCode) {
            edges {
              node {
                id  
                title
                buyNowMessage
                appraisalMessage
                featureFlagKeys
                settings {
                  requireAuctionLocation
                  saleLightsOverride
                }
              }
            }
          }
        }
      `,
      variables: { regionCode },
    },
  });

export type AuctionTimeSlotList = Auction & {
  upcomingAppraisalTimeSlots: Auction['upcomingTimeSlots'];
  upcomingAuctionTimeSlots: Auction['upcomingTimeSlots'];
  upcomingGroundedTimeSlots: Auction['upcomingTimeSlots'];
  upcomingTimedOfferTimeSlots: Auction['upcomingTimeSlots'];
};

export const getUpcomingTimeSlots = (options: { auctionId: string }) =>
  ApiRequest<{ auction: AuctionTimeSlotList }>({
    data: {
      operationName: 'getAuctionSubmissionList',
      query: `
        query ($auctionId: String!) {
          auction(auctionId: $auctionId) {
            id
            upcomingAuctionTimeSlots: upcomingTimeSlots(format: AUCTION) {
              id
              startTime
              cutOffTime
              itemCount
            }
            upcomingTimedOfferTimeSlots: upcomingTimeSlots(format: TIMED_OFFER) {
              id
              startTime
              finishTime
              itemCount
            }
            upcomingAppraisalTimeSlots: upcomingTimeSlots(format: APPRAISAL) {
              id
              startTime
              finishTime
              itemCount
            }
            upcomingGroundedTimeSlots: upcomingTimeSlots(format: GROUNDED) {
              id
              startTime
              finishTime
              itemCount
            }
          }
        }
      `,
      variables: options,
    },
  });

export const getAuctionTimeSlot = ({
  auctionTimeSlotId,
  companyId,
}: QueryauctionTimeSlotArgs & AuctionTimeSlotLaneopenRunNumbersArgs) =>
  ApiRequest<{ auctionTimeSlot: Pick<AuctionTimeSlot, 'auctionTimeSlotLanes'> }>({
    data: {
      operationName: 'getAuctionTimeSlot',
      query: `
        query($auctionTimeSlotId: String!, $companyId: String!) {
          auctionTimeSlot(auctionTimeSlotId: $auctionTimeSlotId) {
            auctionTimeSlotLanes(active: true) {
              id
              name
              type
              openRunNumbers(companyId: $companyId) {
                runNumber
                reservedForSeller
              }
            }
          }
        }
      `,
      variables: { auctionTimeSlotId, companyId },
    },
  });

export const getSuggestedSaleLights = ({ asIs, inventoryItemId }: QueryauctionItemSuggestedSaleLightsArgs) =>
  ApiRequest<{ auctionItemSuggestedSaleLights: SaleLight[] }>({
    data: {
      operationName: 'suggestedSaleLights',
      query: `
        query($asIs: Boolean!, $inventoryItemId: String!) {
          auctionItemSuggestedSaleLights(asIs: $asIs, inventoryItemId: $inventoryItemId)
        }
      `,
      variables: { asIs, inventoryItemId },
    },
  });

export const createAuctionItem = (variables: MutationauctionItemCreateArgs) =>
  ApiRequest<{ auctionItemCreate: AuctionItem }>({
    data: {
      operationName: 'createAuctionItem',
      query: `
        mutation (
          $auctionItemCreateInput: AuctionItemCreateInput
          $format: AuctionItemFormat!
          $inventoryItemId: String!
        ) {
          auctionItemCreate(auctionItemCreateInput: $auctionItemCreateInput, format: $format, inventoryItemId: $inventoryItemId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables,
    },
  });
