import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  AuctionItem,
  AuctionTimeSlotLane,
  QueryauctionItemConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { getAuctionItem, getLiveLane } from './marqueeApi';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const auctionItemLoaded = createAction('ADMIN_MARQUEE_AUCTION_ITEM_LOADED');
export const auctionItemUpdated = createAction('ADMIN_MARQUEE_AUCTION_ITEM_UPDATED');
export const clearMarquee = createAction('ADMIN_MARQUEE_CLEAR_MARQUEE');
export const isLoading = createAction('ADMIN_MARQUEE_SET_IS_LOADING');
export const isNotUpdating = createAction('ADMIN_MARQUEE_SET_IS_NOT_UPDATING');
export const isUpdating = createAction('ADMIN_MARQUEE_SET_IS_UPDATING');
export const liveLaneLoaded = createAction('ADMIN_MARQUEE_LIVE_LANE_LOADED');

export interface ProcessGetAuctionItemArgs {
  auctionItemId: string;
}

export const processGetAuctionItem = async (options: ProcessGetAuctionItemArgs, dispatch: AppDispatch) => {
  const auctionItemDetails = (await getAuctionItem(options))?.data?.data?.auctionItem;
  dispatch(auctionItemLoaded(auctionItemDetails));
};

type LiveLaneResponse = Promise<{
  auctionTimeSlotLane: AuctionTimeSlotLane | undefined;
  upcomingItems: AuctionItem[] | undefined;
}>;

export const processGetLiveLane = (
  options: QueryauctionItemConnectionArgs,
  dispatch: AppDispatch
): LiveLaneResponse => {
  dispatch(isLoading());

  return getLiveLane(options)?.then((response) => {
    const formattedResponse = {
      auctionTimeSlotLane: response?.data?.data?.auctionTimeSlotLane,
      upcomingItems: parseQueryConnectionResponse(response?.data?.data?.auctionItemConnection),
    };

    dispatch(liveLaneLoaded(formattedResponse));
    return formattedResponse;
  });
};
