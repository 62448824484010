import CurrencyToggle from 'forms/shared/currencyToggle';
import InputGroup from 'forms/shared/inputGroup';
import { AuctionFeeMode } from 'store/shared/api/graph/interfaces/types';
import { FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { t } from 'utils/intlUtils';

interface Props {
  /** True will hide the toggle */
  hideCurrencyToggle?: boolean;
  /** Callback to handle amount change */
  onChange?: (amount: number) => void;
  /** Callback to toggle between each auction fee mode */
  onFeeModeChange?: (feeMode: AuctionFeeMode) => void;
  /** The auction fee mode */
  type?: AuctionFeeMode;
  /** The value of the fee */
  value?: number;
}

const AuctionFeesSectionFixed = ({
  hideCurrencyToggle,
  onChange = () => {},
  onFeeModeChange = () => {},
  type = AuctionFeeMode.AMOUNT,
  value,
}: Props) => (
  <FormSection dataTestId="fixed-fee-section">
    {hideCurrencyToggle ? (
      // TODO: [EB-8983] [WEB] Update currency toggle to have an SVG icon based `$`/`%` as part of the placeholder
      <InputGroup
        dataTestId="currency-input"
        defaultValue={value}
        hasMargin={false}
        label={t('fee_amount')}
        name="amount"
        onChange={onChange}
        placeholder={`$   ${t('enter_amount')}`}
      />
    ) : (
      <CurrencyToggle
        label={t('fee_amount')}
        onChange={onChange}
        onTypeChange={onFeeModeChange}
        type={type}
        value={value}
      />
    )}
  </FormSection>
);

export default AuctionFeesSectionFixed;
