import { connect, ConnectedProps } from 'react-redux';

import Badge from 'components/ui/badges/badge';
import BaseClass from 'components/ui/shared/base';
import Checkbox from 'forms/shared/checkbox';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InventoryItem from 'constants/inventoryItem';
import { AppDispatch } from 'store/configureStore';
import { DetailsSection } from 'layouts/list/listLayout';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { TransportDetail } from 'store/shared/api/graph/interfaces/types';
import { auctionItemUpdateInventoryItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { getTransportDetailsMetadata } from 'store/shared/api/graph/queries/inventoryItemMetaData';
import { inventoryItemUpdate } from 'store/shared/api/graph/mutations/inventoryItemUpdate';
import { isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';
import { updateInventoryItem as updateInventoryItemAction } from 'store/inventoryItemDetails/inventoryItemDetailsActions';

import style from './transportDetails.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  updateAuctionItem: (inventoryItem: InventoryItem | undefined) =>
    dispatch(auctionItemUpdateInventoryItem(inventoryItem)),
  updateInventoryItem: (inventoryItem: InventoryItem) => dispatch(updateInventoryItemAction(inventoryItem)),
});

const connector = connect(undefined, dispatchConnect);

interface TransportDetailsProps extends ConnectedProps<typeof connector> {
  inventoryItem: InventoryItem;
  isInventoryItem: boolean;
}

interface TransportDetailsState {
  isDialogOpen: boolean;
  isLoadingMetadata: boolean;
  isSaving: boolean;
  transportDetails: TransportDetail[];
  transportDetailsMetadata: TransportDetail[];
}

class TransportDetails extends BaseClass<TransportDetailsProps, TransportDetailsState> {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      isLoadingMetadata: false,
      isSaving: false,
      transportDetails: [],
      transportDetailsMetadata: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState?.isDialogOpen && this.state?.isDialogOpen) {
      // Reset the `transportDetails` based on the loaded inventoryItem
      this.setState({ transportDetails: this.props?.inventoryItem?.transportDetails });

      if (!this.state?.transportDetailsMetadata?.length) {
        // Fetch metadata if it hasn't already been set in state
        this.setState({ isLoadingMetadata: true });
        getTransportDetailsMetadata()
          ?.then((response) =>
            this.setState({
              transportDetailsMetadata:
                response?.data?.data?.inventoryItemMetaData?.vehicleMetaData?.transportDetails || [],
            })
          )
          ?.catch(this.onApiError)
          ?.finally(() => this.setState({ isLoadingMetadata: false }));
      }
    }

    if (prevState?.isDialogOpen && !this.state?.isDialogOpen) {
      // Reset selected values in state
      this.setState({ transportDetails: [] });
    }
  }

  onConfirm = (shouldSubmit) => {
    const { inventoryItem, isInventoryItem, updateAuctionItem, updateInventoryItem } = this.props;
    const { transportDetails } = this.state;

    if (shouldSubmit) {
      const options = {
        inventoryItemId: inventoryItem?.id,
        vehicle: {
          transportDetails: transportDetails?.map(({ id }) => id),
          persist: true,
        },
      };

      this.setState({ isSaving: true });
      inventoryItemUpdate(options)
        ?.then((response) => {
          const inventoryItemNext = response?.data?.data?.inventoryItemUpdate?.vehicle || undefined;
          const updateFunc = isInventoryItem ? updateInventoryItem : updateAuctionItem;
          updateFunc(inventoryItemNext);
          this.setState({ isDialogOpen: false });
        })
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ isSaving: false }));
      return;
    }
    this.setState({ isDialogOpen: false });
  };

  onToggleTransportDetail = (transportDetail) => {
    const transportDetails = this.state?.transportDetails?.find(({ id }) => transportDetail?.id === id)
      ? this.state?.transportDetails?.filter(({ id }) => transportDetail?.id !== id)
      : [...this.state?.transportDetails, transportDetail];

    this.setState({ transportDetails });
  };

  render() {
    const { inventoryItem } = this.props;
    const { isDialogOpen, isLoadingMetadata, isSaving, transportDetails, transportDetailsMetadata } = this.state;
    const isEmpty = !inventoryItem?.transportDetails?.length;
    const isUpdatable = inventoryItem?.isUpdatable;

    if (!isVerifiedExtensiveVehicleCondition(inventoryItem?.captureType)) {
      return null;
    }

    return (
      <>
        <DetailsSection
          dataTestId="transportDetails-section"
          isEmpty={isEmpty}
          onClickSubButton={isUpdatable ? () => this.setState({ isDialogOpen: true }) : null}
          subButtonType={isEmpty ? 'add' : 'edit'}
          title={isEmpty ? t('no_transport_details') : t('transport_details')}
        >
          <div className={style.options}>
            {inventoryItem?.transportDetails?.map((transportDetail) => (
              <Badge
                key={transportDetail?.id}
                content={transportDetail?.value}
                dataTestId={transportDetail?.id}
                theme="red-solid"
              />
            ))}
          </div>
        </DetailsSection>

        {isDialogOpen && (
          <ConfirmDialog
            actionLabel={t('save')}
            actionProgress={isSaving}
            isOpen={isDialogOpen}
            onConfirm={this.onConfirm}
            theme="green"
            title={t('modify_transport_details')}
          >
            {isLoadingMetadata ? (
              <SpinnerCentered />
            ) : (
              <div className={style.buttonContainer}>
                {transportDetailsMetadata?.map((transportDetail) => (
                  <Checkbox
                    key={transportDetail?.id}
                    dataTestId={transportDetail?.id}
                    id={transportDetail?.id}
                    isButtonTheme
                    onChange={() => this.onToggleTransportDetail(transportDetail)}
                    setChecked={!!transportDetails?.find(({ id }) => id === transportDetail?.id)}
                    text={transportDetail?.value}
                    theme="red"
                  />
                ))}
              </div>
            )}
          </ConfirmDialog>
        )}
      </>
    );
  }
}

export default connector(TransportDetails);
