import classnames from 'classnames';
import { ChangeEvent, ReactNode } from 'react';

import BaseClass from 'components/ui/shared/base';
import { CheckXs } from 'components/ui/icons/check';

import style from './checkbox.scss';

interface CheckboxProps {
  /** CSS styling to overwrite default button style. */
  buttonClassName?: string;
  /** Sets the 'defaultChecked' input attribute. */
  checked?: boolean;
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default button container style. */
  className?: string;
  /** Id used for testing. */
  dataTestId?: string;
  /** True when checkbox is disabled. */
  disabled?: boolean;
  /** The displayed CSS attribute for the checkbox. */
  display?: 'block';
  /** True applies margin top styling to the container. */
  hasMarginTop?: boolean;
  /** The id of the checkbox. */
  id: string;
  /** True applies the 'indeterminate' style. */
  indeterminate?: boolean;
  /** True when button theme is used. */
  isButtonTheme?: boolean;
  /** CSS styling to overwrite default label style. */
  labelClassName?: string;
  /** The name of the checkbox. */
  name?: string;
  /** Function invoked on checkbox onChange. */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  /** The checkbox reference */
  reference?: () => void;
  /** Sets the 'checked' input attribute. */
  setChecked?: boolean;
  /** The label used for the checkbox. */
  text?: string;
  /** The button theme. */
  theme?: 'red' | 'blue' | 'check';
}

/**
 * Checkbox renders a customized button with styles
 */
class Checkbox extends BaseClass<CheckboxProps> {
  static defaultProps = {
    hasMarginTop: true,
  };

  handleChange = (e) => {
    const { onChange, disabled } = this.props;

    if (disabled || !onChange) {
      return;
    }

    onChange({
      ...e,
      target: {
        ...this.props,
        checked: e.target.checked,
      },
    });
  };

  render() {
    const {
      buttonClassName,
      checked,
      children,
      className,
      dataTestId,
      disabled,
      display,
      hasMarginTop,
      id,
      indeterminate,
      isButtonTheme,
      labelClassName,
      name,
      reference,
      setChecked,
      text,
      theme,
    } = this.props;
    const innerCheckboxClasses = classnames(
      style.innerCheckbox,
      theme && style[theme],
      disabled && style.disabledInnerCheckbox,
      indeterminate && style.indeterminate
    );
    const label = children || text;

    return (
      <div
        className={classnames(
          style.container,
          hasMarginTop && style.hasMarginTop,
          isButtonTheme && style.buttonContainer,
          display && style[display],
          className
        )}
        data-testid={`${dataTestId}-checkbox`}
      >
        <input
          ref={reference}
          checked={setChecked}
          className="inputCheckbox"
          data-testid={dataTestId}
          defaultChecked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={this.handleChange}
          type="checkbox"
        />
        {isButtonTheme ? (
          <label htmlFor={id}>
            <div
              className={classnames(
                style.button,
                theme && style[theme],
                buttonClassName,
                disabled && style.defaultCursor
              )}
            >
              {text}
            </div>
          </label>
        ) : (
          <label className={classnames(style.label, disabled && style.defaultCursor)} htmlFor={id}>
            <div className={classnames(style.outerCheckbox, theme && style[theme], disabled && style.disabled)}>
              {theme !== 'check' ? (
                <>
                  <div className={innerCheckboxClasses} />
                </>
              ) : (
                <CheckXs className={innerCheckboxClasses} />
              )}
            </div>
            {label && (
              <div className={classnames(style.text, disabled ? style.disabledLabel : labelClassName)}>{label}</div>
            )}
          </label>
        )}
      </div>
    );
  }
}

export default Checkbox;
