import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';

import style from './selectServerDialog.scss';

enum ServerOptions {
  DEMO = 'Demo',
  DEVELOPMENT = 'Development',
  PARTNER = 'Partner',
  PRIVATE = 'Private',
  PRODUCTION = 'Production',
  STAGE = 'Stage',
}

const ServerAddresses = Object.freeze({
  [ServerOptions.DEMO]: 'https://graph.demo.eblock.com',
  [ServerOptions.DEVELOPMENT]: 'https://graph.development.eblock.com',
  [ServerOptions.PARTNER]: 'https://graph.partner.eblock.com',
  [ServerOptions.PRODUCTION]: 'https://graph.eblock.com',
  [ServerOptions.STAGE]: 'https://graph.stage.eblock.com',
});

interface ServerButtonProps {
  /** The className to overwrite default styles */
  className?: string;
  /** Whether the button is selected or not. */
  isSelected?: boolean;
  /** Label of the input. */
  label: string;
  /** Callback function triggered on click. */
  onClick: (label: string) => void;
}

const ServerButton = ({ className, isSelected, label, onClick }: ServerButtonProps) => (
  <Button
    className={classnames(style.serverButton, className, !isSelected && style.unselectedButton)}
    onClick={() => onClick(label)}
  >
    <span>{label}</span>
    <div className={classnames(style.circle, isSelected && style.circleOn)} />
  </Button>
);

interface Props {
  /** The default custom address */
  defaultAddress?: string;
  /** Callback function when dialog is closed */
  onClose: () => void;
  /** Callback function when dialog is submitted */
  onSubmit: (graphQLBase: string) => void;
}

class SelectServerDialog extends BaseClass<Props> {
  state = {
    /** The selected server/environment */
    selectedServer: ServerOptions.DEVELOPMENT,
    /** The custom/private host address */
    privateHostAddress: undefined,
  };

  constructor(props) {
    super(props);

    const { defaultAddress } = props;
    let privateHostAddress;
    let selectedServer;

    if (defaultAddress === ServerAddresses[ServerOptions.DEVELOPMENT]) {
      selectedServer = ServerOptions.DEVELOPMENT;
    } else if (defaultAddress === ServerAddresses[ServerOptions.STAGE]) {
      selectedServer = ServerOptions.STAGE;
    } else if (defaultAddress === ServerAddresses[ServerOptions.DEMO]) {
      selectedServer = ServerOptions.DEMO;
    } else if (defaultAddress === ServerAddresses[ServerOptions.PARTNER]) {
      selectedServer = ServerOptions.PARTNER;
    } else if (defaultAddress === ServerAddresses[ServerOptions.PRODUCTION]) {
      selectedServer = ServerOptions.PRODUCTION;
    } else {
      selectedServer = ServerOptions.PRIVATE;
      privateHostAddress = defaultAddress;
    }
    this.state = { selectedServer, privateHostAddress };
  }

  /**
   * Sets the active service selection in state
   *
   * @param selectedServer
   */
  onClickButton = (selectedServer: string) => {
    this.setState({ selectedServer });
  };

  /**
   * Submits and saves the selection to localStorage
   *
   * @param shouldSubmit
   */
  onClickSave = (shouldSubmit: boolean) => {
    const { selectedServer, privateHostAddress } = this.state;
    const graphQLBase = selectedServer === ServerOptions.PRIVATE ? privateHostAddress : ServerAddresses[selectedServer];

    if (shouldSubmit && graphQLBase) {
      this.props.onSubmit(graphQLBase);
    }
  };

  render() {
    const { onClose } = this.props;
    const { privateHostAddress, selectedServer } = this.state;

    return (
      <ConfirmDialog
        actionLabel="SAVE"
        isOpen
        onClose={onClose}
        onConfirm={this.onClickSave}
        theme="green"
        title="Select Server"
      >
        <div>Please select an environment or enter a private server</div>

        <div className={style.buttons}>
          <ServerButton
            isSelected={selectedServer === ServerOptions.PRODUCTION}
            label={ServerOptions.PRODUCTION}
            onClick={this.onClickButton}
          />
          <ServerButton
            isSelected={selectedServer === ServerOptions.PARTNER}
            label={ServerOptions.PARTNER}
            onClick={this.onClickButton}
          />
          <ServerButton
            isSelected={selectedServer === ServerOptions.DEMO}
            label={ServerOptions.DEMO}
            onClick={this.onClickButton}
          />
          <ServerButton
            isSelected={selectedServer === ServerOptions.STAGE}
            label={ServerOptions.STAGE}
            onClick={this.onClickButton}
          />
          <ServerButton
            isSelected={selectedServer === ServerOptions.DEVELOPMENT}
            label={ServerOptions.DEVELOPMENT}
            onClick={this.onClickButton}
          />
          <div
            className={classnames(
              style.hostContainer,
              selectedServer !== ServerOptions.PRIVATE && style.unselectedButton
            )}
          >
            <ServerButton
              className={style.privateButton}
              isSelected={selectedServer === ServerOptions.PRIVATE}
              label={ServerOptions.PRIVATE}
              onClick={this.onClickButton}
            />
            <div className={style.hostInputContainer}>
              <span>Host:</span>
              <InputText
                className={style.hostInput}
                defaultValue={privateHostAddress}
                onChange={(value) => this.setState({ privateHostAddress: value })}
                onFocus={() => this.onClickButton(ServerOptions.PRIVATE)}
                placeholder="e.g. http://127.0.0.1:8080"
                theme="minimal"
              />
            </div>
          </div>
        </div>
      </ConfirmDialog>
    );
  }
}

export default SelectServerDialog;
