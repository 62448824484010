import classnames from 'classnames';

import DollarGlyph from 'glyphs/dollar.svg';

import Button, { ButtonTheme } from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';

import style from './comboButton.scss';

interface ComboButtonProps {
  /** True when the bulge style is used. */
  bulge?: boolean;
  /** CSS styling to override the default style. */
  className?: string;
  /** Set the html `data-testid` attribute. */
  dataTestId?: string;
  /** True when the combo style is used. */
  isCombo?: boolean;
  /** True when button disabled style is used. */
  isDisabled?: boolean;
  /** True when button is on winning button. */
  isWinning?: boolean;
  /** The label of the combo button. */
  label: string;
  /** Function invoked on custom bid click. */
  onCustomBidClick(): void;
  /** Function invoked on quick bid click. */
  onQuickBidClick(): void;
  /** The theme of the button. */
  theme?: ButtonTheme;
}

const ComboButton = (props: ComboButtonProps) => {
  const {
    bulge = false,
    className,
    dataTestId,
    isCombo = true,
    isWinning,
    isDisabled,
    label,
    onCustomBidClick,
    onQuickBidClick,
    theme = 'green-outline',
  } = props;

  return (
    <div
      className={classnames(
        style.buttonContainer,
        isCombo && style.isCombo,
        isDisabled && style.isDisabled,
        isWinning && style.isWinning,
        bulge && style.bulge,
        className
      )}
      data-testid={dataTestId}
    >
      <Button
        className={classnames(style.quickBid, theme && style[theme])}
        onClick={onQuickBidClick}
        state={isWinning ? 'active' : undefined}
        theme={theme}
      >
        {label}
      </Button>
      {isCombo && (
        <Button className={style.customBid} onClick={onCustomBidClick} theme={theme}>
          <Sprite className={classnames(style.plus)} glyph={DollarGlyph} />
        </Button>
      )}
    </div>
  );
};

export default ComboButton;
