import classnames from 'classnames';

import { WorkflowProcess } from 'store/shared/api/graph/interfaces/types';

import style from './postSaleStatuses.scss';

interface Props {
  /** Auction item's post sale workflow processes. */
  workflowProcesses: WorkflowProcess[] | undefined;
}

const PostSaleStatuses = ({ workflowProcesses }: Props) => {
  if (!workflowProcesses?.length) {
    return null;
  }

  return (
    <div className={style.statuses} data-testid="post-sale-statuses">
      {workflowProcesses.map((process) => (
        <div key={process.id} className={style.row}>
          <div className={style.label}>{process.name}</div>
          <div className={classnames(style.value, style[process.statusColorScheme.toLowerCase()])}>
            {process.status}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostSaleStatuses;
