import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

import checkoutGlyph from 'glyphs/checkout.svg';

import Button from 'components/ui/shared/button';
import CheckoutFormContainer from 'containers/checkout/checkoutFormContainer';
import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import { AppDispatch, AppState } from 'store/configureStore';
import { getAuctionItemOrders } from 'store/shared/api/graph/queries/checkout';
import { showCheckoutNotification } from 'store/checkout/checkoutActions';

import style from './checkoutNotificationButton.scss';

const stateConnect = (state: AppState) => ({
  isVisible: state?.app?.checkout?.showNotification,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  setVisibility: (isVisible: boolean) => dispatch(showCheckoutNotification(isVisible)),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  className?: string;
  shouldForceVisibility?: boolean;
  showNotificationBadge?: boolean;
  user?: User;
}

const CheckoutNotificationsButton = ({
  className = undefined,
  isVisible,
  setVisibility,
  shouldForceVisibility = false,
  showNotificationBadge = false,
  user = undefined,
  ...props
}: Props) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const intervalRef = useRef<number | undefined>(undefined);

  const getOrders = useCallback(() => {
    getAuctionItemOrders(false)?.then((response) => {
      setVisibility(Number(response?.data?.data?.auctionItemOrders?.auctionItems?.length) > 0);
    });
  }, [setVisibility]);

  // Check for new orders on mount, and every 5 minutes thereafter
  useEffect(() => {
    getOrders();
    intervalRef.current = window.setInterval(getOrders, 1000 * 60 * 5);

    return () => window.clearInterval(intervalRef.current);
  }, [getOrders, user?.id]);

  if (!shouldForceVisibility && !isVisible) {
    return null;
  }

  return (
    <>
      <Button
        dataTestId="checkout-notification-button"
        theme="none"
        {...props}
        className={classnames(style.button, className)}
        onClick={() => setIsFormOpen(true)}
      >
        <Sprite className={style.sprite} glyph={checkoutGlyph} />
        {showNotificationBadge && <span className={style.buttonBadge} data-testid="notification-badge" />}
      </Button>
      <CheckoutFormContainer isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
    </>
  );
};

export default connector(CheckoutNotificationsButton);
