import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash-es';

import InputText from 'forms/shared/inputText';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getFilteredUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './startingBidRange.scss';

interface LocationQuery {
  startingBidGTE: string;
  startingBidLTE: string;
}

interface Props extends RouterProps<LocationQuery> {
  /** CSS style for the title. */
  titleClassName?: string;
}

const StartingBidRange = ({ location, router, titleClassName }: Props) => {
  // A memoized debounced onChange handler for the price inputs below
  const onChange = useMemo(
    () =>
      debounce((paramName, value) => {
        const filteredParams = getFilteredUrlParams([paramName]);
        const paramsNext = paramsToQueryString({
          ...filteredParams,
          ...(value !== '' && Number(value) > -1 && { [paramName]: value }),
        });

        // Update URL params
        router?.push(`${location?.pathname}?${paramsNext}`);
      }, 250),
    [location?.pathname, router]
  );

  return (
    <>
      <div className={style.container} data-testid="starting-bid-range">
        <div className={classnames(style.title, titleClassName)}>
          {location.pathname.includes('/buy/timed-auction') ? t('current_bid') : t('starting_bid')}
        </div>
        <div className={style.inputs}>
          <InputText
            className={style.input}
            defaultValue={location?.query?.startingBidGTE || undefined}
            onChange={useCallback((value) => onChange('startingBidGTE', value), [onChange])}
            placeholder={t('min_price')}
            textClassName={style.inputText}
            type="number"
          />
          <span>{t('to')}</span>
          <InputText
            className={style.input}
            defaultValue={location?.query?.startingBidLTE || undefined}
            onChange={useCallback((value) => onChange('startingBidLTE', value), [onChange])}
            placeholder={t('max_price')}
            textClassName={style.inputText}
            type="number"
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(StartingBidRange);
