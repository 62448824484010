import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import { QuerymarketGuideConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { getMarketGuideList } from 'store/marketGuide/marketGuideApi';
import { parseQueryParams } from 'utils/apiUtils';

export const marketGuideListCleared = createAction('MARKET_GUIDE_CLEARED');
export const marketGuideListIsLoading = createAction('MARKET_GUIDE_LOADING');
export const marketGuideListLoaded = createAction('MARKET_GUIDE_BUYER_LOADED');

export const processGetMarketGuideList = async (options: QuerymarketGuideConnectionArgs, dispatch: AppDispatch) => {
  dispatch(marketGuideListIsLoading());

  return getMarketGuideList(parseQueryParams(options))?.then((response) => {
    const formattedResponse = response?.data?.data;
    dispatch(marketGuideListLoaded(formattedResponse));
    return formattedResponse;
  });
};
