import AuctionItem from 'constants/auctionItem';
import ListPagination from 'components/ui/lists/listPagination';
import MyBlockListItem from 'components/sections/myBlock/list/listItem/myBlockListItem';
import NoResultsList from 'components/ui/noResults/noResultsList';
import User from 'constants/user';
import { FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { FlexColumn } from 'layouts/shared/flexbox';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';

import style from './myBlockList.scss';

interface Props extends RouterProps {
  /** List of auction items */
  auctionItems: AuctionItem[];
  /** Callback to clear filters */
  onClearFilters?: () => void;
  /** Page info */
  pageInfo: FacetedPageInfo | null;
  /** Current user */
  user: User;
  /** The variation of my block view */
  variant: MyBlockVariant;
}

const MyBlockList = ({ auctionItems = [], location, onClearFilters, pageInfo, router, user, variant }: Props) => {
  return (
    <FlexColumn className={style.column}>
      {auctionItems.length ? (
        <>
          <div className={style.listContainer}>
            <ul>
              {auctionItems.map((auctionItem) => (
                <MyBlockListItem
                  key={auctionItem?.id}
                  auctionItem={auctionItem}
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    const params = getUrlParams();
                    const paramsNext = paramsToQueryString({ ...params, id: auctionItem?.id, showDetails: true });
                    router?.push(`${location?.pathname}?${paramsNext}`);
                  }}
                  user={user}
                  variant={variant}
                />
              ))}
            </ul>
          </div>
          <ListPagination location={location} pageInfo={pageInfo} />
        </>
      ) : (
        <NoResultsList isFilteredList noResultClassName={style.noResults} onClearFilters={onClearFilters} />
      )}
    </FlexColumn>
  );
};

export default withRouter(MyBlockList);
