import classnames from 'classnames';
import { Link as ReactRouterLink } from 'react-router';
import { LinkProps } from 'react-router/lib/Link';
import { ReactNode } from 'react';

import { Location } from 'constants/reactRouter';

import style from './link.scss';

export interface LinkComponentProps extends LinkProps {
  /** A label for accessibility. */
  ariaLabelMessage?: string;

  /** The elements to be rendered as the component's children. */
  children?: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;

  /** Test id */
  dataTestId?: string;

  /** The link theme. */
  theme?: 'button';

  /** The title to be displayed. */
  title?: string;

  /** The destination url or location. */
  to: string | Location;
}

const Link = ({ ariaLabelMessage, children, className, dataTestId, theme, title, ...props }: LinkComponentProps) => {
  const classNames = classnames(theme && style[`theme-${theme}`], className);

  return (
    <ReactRouterLink
      aria-label={ariaLabelMessage}
      className={classNames}
      data-testid={dataTestId || 'link'}
      title={title}
      {...props}
    >
      {children}
    </ReactRouterLink>
  );
};

export default Link;
