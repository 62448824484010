import { MutationauctionItemTransferNoSaleArgs } from 'store/shared/api/graph/interfaces/types';
import { changeAuctionItemConsigner, changeInventoryItemConsigner } from './consignersApi';

export const processChangeInventoryItemConsigner = async (options) => {
  return changeInventoryItemConsigner(options).then((response) => response?.data?.data?.inventoryItemTransfer?.id);
};

export const processChangeAuctionItemConsigner = async (options: MutationauctionItemTransferNoSaleArgs) => {
  return changeAuctionItemConsigner(options).then((response) => response?.data?.data?.auctionItemTransferNoSale?.id);
};
