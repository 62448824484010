import { useState, RefObject } from 'react';

import { useMountEffect } from 'hooks/useMountEffect';

/**
 * Checks if an element is on screen
 *
 * @example
 *
 * const Component = () => {
 *   const ref = useRef();
 *   const isVisible = useOnScreen(ref);
 *   ...
 *
 *   return <div ref={ref}>Example</div>
 * }
 */
export const useOnScreen = (ref: RefObject<Element>, isOptimistic?: boolean) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean | undefined>(isOptimistic);
  const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));

  useMountEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  });

  return isIntersecting;
};
