import { useState } from 'react';
import classnames from 'classnames';

import InputGroup from 'forms/shared/inputGroup';
import Button from 'components/ui/shared/button';
import { t } from 'utils/intlUtils';

import style from './mileageInput.scss';

export type MileageType = 'km' | 'mi';

const MileageInput = ({
  className,
  label,
  onChange = () => {},
  onUnitChange = () => {},
  placeholder = t('enter_mileage'),
  unit,
  value,
}: {
  className?: string;
  label?: string;
  onChange?: (amount) => void;
  onUnitChange?: (unit) => void; // eslint-disable-line no-shadow
  placeholder?: string;
  unit?: MileageType;
  value?: number;
}) => {
  const [activeUnit, setActiveUnit] = useState<MileageType>(unit || 'km');

  return (
    <div className={classnames(style.inputContainer, className)}>
      <InputGroup
        className={style.input}
        defaultValue={value}
        hasMargin={false}
        label={label}
        min={0}
        name="amount"
        onChange={onChange}
        placeholder={placeholder}
        type="number"
      />
      <div className={style.units}>
        {['km', 'mi']?.map((distanceUnit) => (
          <Button
            key={distanceUnit}
            className={classnames(style.unit, !!label && style.hasLabel)}
            onClick={() => {
              setActiveUnit(distanceUnit as 'km' | 'mi');
              onUnitChange(distanceUnit);
            }}
            theme={activeUnit === distanceUnit ? 'blue' : 'gray-outline'}
          >
            {distanceUnit}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default MileageInput;
