import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  Company as CompanyType,
  FacetedPageInfo,
  FacetGroup as FacetGroupType,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface CompaniesModel {
  /** Companies facet groups. */
  facetGroups: List<FacetGroupType>;

  /** Faceted page info. */
  pageInfo: RecordOf<FacetedPageInfo>;

  /** Companies list. */
  resultList: List<RecordOf<CompanyType>>;
}

export type CompaniesProps = ServerRecordOf<CompaniesModel>;

export const InitialState = ServerRecord<CompaniesModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});

export const Company = Record<CompanyType>({
  accountingSystemRelationships: null,
  archived: null,
  auctionRelationships: { count: 0, list: [] },
  created: null,
  deliveryWhitelistedRegions: null,
  description: null,
  id: null,
  legalName: null,
  locations: null,
  name: null,
  numericId: null,
  parentCompany: null,
  primaryLocation: null,
  reportGroups: [],
  restrictCarfaxCanadaOrdering: null,
  rolePermissionsEnabled: null,
  shipmentModes: null,
  status: null,
  subType: null,
  tasks: null,
  timelineActions: null,
  type: null,
  updated: null,
  visibleRegions: null,
});

export const FacetGroup = Record<FacetGroupType>({
  name: null,
  allowMultiple: false,
  facets: [],
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});
