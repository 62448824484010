import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import {
  usersListLoaded,
  usersListError,
  facetGroupsLoaded,
  pageInfoLoaded,
  usersListIsLoading,
  usersListCleared,
  usersListUpdateItem,
} from './usersActions';
import { InitialState, User, FacetGroup, PageInfo } from './usersModels';

export const usersReducer = handleActions(
  {
    [usersListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [usersListLoaded().type]: (state, action) => {
      const {
        userConnection: { edges, pageInfo, facetGroups },
      } = action.payload;

      return state.setLoaded().merge({
        resultList: List(edges.map((userEdge) => new User(userEdge.node))),
        facetGroups: List(facetGroups.map((facetGroupData) => new FacetGroup(facetGroupData))),
        pageInfo: new PageInfo(pageInfo),
      });
    },

    [usersListCleared().type]: () => new InitialState(),

    [pageInfoLoaded().type]: (state, action) => state.set('pageInfo', action.payload),

    [facetGroupsLoaded().type]: (state, action) =>
      state.set('facetGroups', List(action.payload.map((facetGroupData) => new FacetGroup(facetGroupData)))),

    [usersListError().type]: (state, action) => state.setError(action.payload),

    [usersListUpdateItem().type]: (state, action) => {
      const userNext = action.payload;
      const userIndex = state.resultList.findIndex((item) => item?.id === userNext.id);

      if (userIndex === -1) {
        return state.merge({ resultList: state.resultList.insert(0, new User(userNext)) });
      }

      const existingItem = state.resultList.get(userIndex)!.toJS();
      const updatedItem = new User(merge({}, existingItem, userNext));
      return state.merge({ resultList: state.resultList.set(userIndex, updatedItem) });
    },
  },
  new InitialState()
);
