import 'react-dates/lib/css/_datepicker.css';
import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import ButtonsFacetGroup from './buttonsFacetGroup';
import CheckboxFacetGroup from './checkboxFacetGroup';
import DateRangeFacetGroup from './dateRangeFacetGroup';
import Logger from 'logging/Logger';
import MiniButton from 'components/ui/shared/buttons/miniButton';
import MultiFacetGroup from './multiFacetGroup';
import MultiFacetGroupEdit from './multiFacetGroupEdit';
import RadioFacetGroup from './radioFacetGroup';
import RangeFacetGroup, { RangeFacetGroupProp } from './rangeFacetGroup';
import ReserveRange from './reserveRange';
import SaveSearch from 'components/ui/lists/filters/facetGroups/saveSearch';
import SavedSearches from 'components/ui/lists/filters/facetGroups/savedSearches';
import ShowMyUnitsSwitch from './showMyUnitsSwitch';
import StartingBidRange from 'components/ui/lists/filters/facetGroups/startingBidRange';
import { AuctionItemSavedFilter, Facet, FacetGroup } from 'store/shared/api/graph/interfaces/types';
import {
  FacetLabel,
  FacetLabelGroup,
  FacetGroupConfig,
} from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { Location, RouterProps, withRouter } from 'constants/reactRouter';
import { SecondaryTitle } from 'layouts/list/listItemLayout';
import { UserAction } from 'logging/analytics/events/userActions';
import { convertStringToEnum } from 'utils/stringUtils';
import { findSelectedFacets } from 'utils/listUtils';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './facetGroups.scss';

interface Props extends RouterProps {
  /** The configuration information for the facet groups. */
  config: FacetGroupConfig;

  /** The facet groupings. */
  facetGroups: FacetGroup[];

  /** Function invoked when a saved search has been deleted. */
  onSavedSearchDelete?: (id: string) => void;

  /** Function invoked when saving a search. */
  onSaveSearch?: ((name: string, isDefault: boolean, params) => void) | null;

  /** A list of saved searches. */
  savedSearches?: AuctionItemSavedFilter[];

  /** Text to override the default 'Clear All' value */
  clearAllTextOverride?: string;
}

interface State {
  /** The current name of the multi facet group selected. */
  editFacetGroupName: string | null;

  /** The current location pathname. */
  pathname: string;
}

class FacetGroups extends BaseClass<Props, State> {
  private root: HTMLDivElement | null;
  private rootScrollTop: number | undefined;

  static defaultProps = {
    savedSearches: [],
  };

  constructor(props: Props) {
    super(props);
    this.root = null;
    this.rootScrollTop = 0;
    this.state = { editFacetGroupName: null, pathname: props.location.pathname };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { pathname: pathnamePrev } = state;
    const {
      location: { pathname },
    } = props;

    if (pathname !== pathnamePrev) {
      return { editFacetGroupName: null, pathname };
    }
    return null;
  }

  onFacetGroupEdit = (editFacetGroupName: string | null) => {
    if (editFacetGroupName) {
      this.rootScrollTop = this.root?.scrollTop;
    }
    this.setState({ editFacetGroupName }, () => {
      if (!editFacetGroupName && this.root) {
        this.root.scrollTop = Number(this.rootScrollTop);
      }
    });
  };

  onFacetClick = (facet: Facet, facetGroupName: string) => {
    switch (facetGroupName) {
      case 'filterBy':
        return () => {
          const userAction = UserAction[`${convertStringToEnum(facet.name)}_FILTER_CLICK`];
          userAction && Logger.trackUserAction(userAction);
        };
      default:
        return undefined;
    }
  };

  render() {
    const { editFacetGroupName } = this.state;
    const {
      clearAllTextOverride,
      config,
      facetGroups,
      location,
      onSaveSearch,
      onSavedSearchDelete,
      router,
      savedSearches,
    } = this.props;

    if (editFacetGroupName) {
      const facetGroupConfig = config
        .filter((item) => item?.type !== 'heading')
        .find((item) => (item || {}).name === editFacetGroupName);

      if (!facetGroupConfig) {
        throw new Error(`FacetGroupError: The current configuration doesn't include the facet: ${editFacetGroupName}`);
      }

      const facetGroup = facetGroups.find((f) => f.name === editFacetGroupName);

      if (facetGroup && facetGroupConfig.type === 'multi') {
        const { label, facetFormat, showAny } = facetGroupConfig;
        return (
          <MultiFacetGroupEdit
            facetGroup={{
              ...facetGroup,
              facets: facetGroup?.facets?.map((facet) => ({
                ...facet,
                facetLabel: facetFormat?.(facet.name) || facet.localizedName || facet.name,
              })),
            }}
            label={label}
            location={location}
            onClose={() => this.onFacetGroupEdit(null)}
            showAny={showAny !== false}
          />
        );
      }
    }

    const urlParams = getUrlParams();
    delete urlParams.after;
    const adHocFacetGroups: FacetLabelGroup[] = [];

    config.forEach((configItem) => {
      const facetGroup = facetGroups.find((f) => f.name === configItem.name);
      if (!facetGroup && 'values' in configItem) {
        adHocFacetGroups.push({
          allowMultiple: false,
          facets: configItem.values?.map((value) => ({
            name: String(value),
            count: -1,
            selected:
              urlParams[configItem.name] === String(value) ||
              (!urlParams[configItem.name] && configItem.defaultValue === value),
          })),
          name: configItem.name,
          valueLabel: 'valueLabel' in configItem ? configItem.valueLabel : undefined,
          values: configItem.values,
        });
      }
    });

    const configKeys = config
      .map(({ name, type }) =>
        ['dateRange', 'range', 'reserveRange', 'startingBidRange']?.includes(type)
          ? [`${name}LTE`, `${name}GTE`]
          : [name]
      )
      .reduce((keys, names) => keys.concat(names), []);
    const hasSelections = !!Object.keys(urlParams).find((k) => configKeys.includes(k));

    const allFacetGroups = [...facetGroups, ...adHocFacetGroups];

    const currentSavedSearchId = savedSearches?.find((savedSearch) => savedSearch.id === urlParams.savedSearchId)?.id;

    const clearUrl = `${location.pathname}?${Object.keys(urlParams)
      .filter((k) => k !== 'savedSearchId' && !configKeys.includes(k))
      .map((k) => `${k}=${encodeURIComponent(urlParams[k])}`)
      .join('&')}`;

    return (
      allFacetGroups.length > 0 && (
        <div
          ref={(div) => {
            this.root = div;
          }}
          className={style.facetGroups}
          data-testid="facetGroups"
        >
          <div className={style.titleContainer}>
            <SecondaryTitle className={classnames(style.title)}>{t('filters')}</SecondaryTitle>
            {hasSelections && (
              <MiniButton data-testid="clearAll-button" onClick={() => router.push(clearUrl)}>
                {t(clearAllTextOverride || 'clear_all')}
              </MiniButton>
            )}
          </div>
          {onSaveSearch && onSavedSearchDelete && Number(savedSearches?.length) > 0 && (
            <SavedSearches
              clearUrl={clearUrl}
              config={config}
              currentSavedSearchId={currentSavedSearchId}
              location={location}
              onSavedSearchDelete={onSavedSearchDelete}
              savedSearches={savedSearches || []}
            />
          )}

          {config.map((configItem) => {
            if (configItem.type === 'heading') {
              const { auxLabel, label } = configItem;
              return (
                <div key={`heading-${label}`} className={style.heading}>
                  {label}
                  {auxLabel && <span>{auxLabel}</span>}
                </div>
              );
            }

            if (configItem.type === 'spacer') {
              return <div key={`spacer-${configItem.name}`} className={style.spacer} />;
            }

            const { name } = configItem;
            const facetFormat = 'facetFormat' in configItem ? configItem.facetFormat : undefined;

            const facetGroup = allFacetGroups.find((f) => f.name === name);
            const facets: FacetLabel[] = facetGroup
              ? facetGroup.facets?.map((facet) => ({
                  ...facet,
                  facetLabel: facetFormat?.(facet.name) || facet.localizedName || facet.name,
                  trackUserAction: this.onFacetClick(facet, facetGroup.name),
                })) || []
              : [];

            switch (configItem.type) {
              case 'showMyUnits': {
                return (
                  <div key={name} className={style.showMyUnitsSwitch}>
                    <ShowMyUnitsSwitch
                      parameterName={configItem?.parameterName}
                      parameterValue={configItem?.parameterValue}
                      titleClassName={style.heading}
                    />
                  </div>
                );
              }

              case 'multi':
                return (
                  <MultiFacetGroup
                    key={name}
                    disabled={configItem.disabled}
                    facetGroup={facetGroup ? { ...facetGroup, facets } : undefined}
                    label={configItem.label}
                    onEdit={this.onFacetGroupEdit}
                  />
                );

              case 'buttons':
                return (
                  <ButtonsFacetGroup
                    key={name}
                    configItem={configItem}
                    facetGroup={facetGroup ? { ...facetGroup, facets } : undefined}
                    label={configItem.label}
                    location={location}
                  />
                );

              case 'range': {
                const selectedLowerBound = findSelectedFacets(allFacetGroups, `${name}GTE`)?.[0]?.name;
                const selectedUpperBound = findSelectedFacets(allFacetGroups, `${name}LTE`)?.[0]?.name;

                return (
                  <RangeFacetGroup
                    key={name}
                    configItem={configItem}
                    facetGroup={facetGroup as RangeFacetGroupProp}
                    label={configItem.label}
                    selectedLowerBound={Number(selectedLowerBound)}
                    selectedUpperBound={Number(selectedUpperBound)}
                  />
                );
              }

              case 'startingBidRange':
                return <StartingBidRange key={name} />;

              case 'reserveRange':
                return <ReserveRange key={name} />;

              case 'dateRange':
                return (
                  <DateRangeFacetGroup
                    key={name}
                    format={configItem.format}
                    isOutsideRange={configItem.isOutsideRange}
                    isUTC={!!configItem.isUTC}
                    label={configItem.label}
                    name={name}
                  />
                );

              case 'checkboxGroup':
                return (
                  <CheckboxFacetGroup
                    key={name}
                    facetGroup={facetGroup ? { ...facetGroup, facets } : undefined}
                    label={configItem.label}
                  />
                );

              default:
                return (
                  <RadioFacetGroup
                    key={name}
                    configItem={configItem}
                    facetGroup={facetGroup ? { ...facetGroup, facets } : undefined}
                    label={configItem.label}
                    location={location}
                  />
                );
            }
          })}

          {onSaveSearch && !currentSavedSearchId && <SaveSearch config={config} onSaveSearch={onSaveSearch} />}
        </div>
      )
    );
  }
}

export const facetLink = (
  facetGroupName: string,
  location: Location,
  facetName: string | null = null,
  toggle = false,
  canSave = true
) => {
  const params = getUrlParams();

  if (facetName) {
    if (toggle) {
      let vals = params[facetGroupName] || [];
      if (!(vals instanceof Array)) {
        vals = [vals];
      }
      if (vals.includes(facetName)) {
        vals = vals.filter((v) => v !== facetName);
      } else {
        vals = [...vals, facetName];
      }
      if (vals.length === 0) {
        delete params[facetGroupName];
      } else {
        params[facetGroupName] = vals;
      }
    } else {
      params[facetGroupName] = facetName;
    }
  } else {
    delete params[facetGroupName];
  }

  if (canSave) {
    delete params.savedSearchId;
  }

  delete params.before;
  delete params.after;

  return `${location.pathname}?${paramsToQueryString(params)}`;
};

export default withRouter(FacetGroups);
