export const locationFragment = `
  fragment location on Location {
    id
    public
    name
    address1
    address2
    city
    region
    regionCode
    zipCode
    countryCode
    country
    latitude
    longitude
    pickupInstructions
    dropOffInstructions
    distanceAway
    phone
    email
    fax
    public
  }
`;

export const photoFragment = `
  fragment photoFrag on Photo {
    id
    main: url(width: 800, height: 600)
    expanded: url(width: 1200, height: 768)
    thumb: url(width: 160, height: 130)
  }
`;
