import { ReactNode } from 'react';

import HR from 'components/ui/shared/hr';
import { PrimaryTitle, SecondaryTitle, SecondaryTitleLight, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { t } from 'utils/intlUtils';

import style from './checkoutInvoice.scss';

export const InvoiceContainer = ({ children }: { children?: ReactNode }) => (
  <div className={style.container}>{children}</div>
);

export const InvoiceSection = ({ title, children }: { title?: string; children?: ReactNode }) => (
  <section className={style.invoiceSection} data-testid="invoice-section">
    {title && <TertiaryTitleLight className={style.title}>{title}</TertiaryTitleLight>}
    {children}
  </section>
);

export const InvoiceRow = ({ name, value = '-' }: { name: string | undefined; value: string | undefined }) => (
  <section className={style.invoiceRow} data-testid="invoice-row">
    <SecondaryTitleLight>{name}</SecondaryTitleLight>
    <SecondaryTitle>{value}</SecondaryTitle>
  </section>
);

export const InvoiceTotal = ({ value }: { value: string | undefined }) => (
  <div className={style.invoiceTotal} data-testid="invoice-total">
    <HR className={style.hr} />
    <div className={style.invoiceRow}>
      <PrimaryTitle className={style.invoiceTotalValue}>{t('total')}</PrimaryTitle>
      <PrimaryTitle className={style.invoiceTotalValue}>{value}</PrimaryTitle>
    </div>
  </div>
);
