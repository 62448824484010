import classnames from 'classnames';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import InteractiveText from 'components/ui/shared/interactiveText';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import LinkButton from 'components/ui/shared/buttons/linkButton';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import OneTimeCodeInput from 'forms/shared/oneTimeCodeInput';
import RadioPanels from 'forms/shared/radioPanels';
import User from 'constants/user';
import { AuthContent } from 'layouts/authLayouts/authLayouts';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  MutationuserAccountSendVerificationCodeArgs,
  MutationuserVerifyAuthenticationArgs,
  UserAccountVerificationType,
} from 'store/shared/api/graph/interfaces/types';
import { ONE_TIME_CODE_LENGTH } from 'components/sections/auth/loginMFA/loginMFA';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { SubmitButton } from 'components/sections/auth/auth';
import { cacheAuth } from 'utils/authUtils';
import { onApiError } from 'utils/apiUtils';
import { processTryLogin } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';
import {
  userAccountSendVerificationCode,
  userVerifyAuthentication,
} from 'store/shared/api/graph/mutations/userAccountSendVerificationCode';

import style from './loginMFA.scss';

interface Props extends RouterProps {
  /** The logged in user */
  user: User;
}

const LoginMFAScenario1 = ({ router, user }: Props) => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [otcInputValue, setOTCInputValue] = useState<string>('');
  const [isAutoSubmittable, setIsAutoSubmittable] = useState<boolean>(true);
  const [isCodeInputView, setIsCodeInputView] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<UserAccountVerificationType>();
  const [showResendBanner, setShowResendBanner] = useState<boolean>(false);

  const panels = useMemo(
    () => [
      {
        children: (
          <div className={style.panel}>
            <div className={style.label}>{t('send_code_to_mobile_number')}</div>
            <div className={style.subLabel}>{user?.cellPhone}</div>
          </div>
        ),
        value: UserAccountVerificationType.SMS,
        displayedTextOnSelected: t('mfa_sms_consent_message'),
      },
      {
        children: (
          <div className={style.panel}>
            <div className={style.label}>{t('send_code_to_email_address')}</div>
            <div className={style.subLabel}>{user?.email}</div>
          </div>
        ),
        value: UserAccountVerificationType.EMAIL,
      },
    ],
    [user]
  );

  /**
   * onSendCode
   */
  const onSendCode = useCallback(() => {
    const options: MutationuserAccountSendVerificationCodeArgs = {
      userId: user?.id,
      verificationType:
        selectedOption === UserAccountVerificationType.SMS
          ? UserAccountVerificationType.SMS
          : UserAccountVerificationType.EMAIL,
      verifiedField: (selectedOption === UserAccountVerificationType.SMS ? user?.cellPhone : user?.email)!,
    };

    return userAccountSendVerificationCode(options)
      ?.then(() => setIsCodeInputView(true))
      ?.catch((error) => onApiError(error, setErrorMessages));
  }, [selectedOption, user]);

  /**
   * onResendCode
   */
  const onResendCode = useCallback(() => {
    onSendCode()?.then(() => setShowResendBanner(true));
  }, [onSendCode]);

  /**
   * onResendCode - debounced
   */
  const onResendCodeDebounced = useMemo(() => debounce(onResendCode, 250), [onResendCode]);

  /**
   * onBack
   */
  const onBack = useCallback(() => {
    setIsAutoSubmittable(false);
    setIsCodeInputView(false);
    setShowResendBanner(false);
  }, []);

  /**
   * onVerifyAuth
   */
  const onVerifyAuth = useCallback(() => {
    const options: MutationuserVerifyAuthenticationArgs = {
      userId: user?.id,
      verificationCode: otcInputValue,
      verificationType:
        selectedOption === UserAccountVerificationType.SMS
          ? UserAccountVerificationType.SMS
          : UserAccountVerificationType.EMAIL,
      verifiedField: (selectedOption === UserAccountVerificationType.SMS ? user?.cellPhone : user?.email)!,
    };

    // Send verification code; show error state when necessary
    userVerifyAuthentication(options)
      ?.then((response) => {
        cacheAuth(response?.data?.data?.userVerifyAuthentication?.token);
        processTryLogin(dispatch)?.then(() => router?.replace('/'));
      })
      ?.catch((error) => onApiError(error, setErrorMessages));
  }, [dispatch, otcInputValue, router, selectedOption, user]);

  /**
   * onOTCInputChange
   */
  const onOTCInputChange = useCallback((value) => {
    setOTCInputValue(value);
  }, []);

  /**
   * Handles the click event of the radio panel
   */
  const onRadioPanelClick = useCallback((value: string | number) => {
    setSelectedOption(value as UserAccountVerificationType);
  }, []);

  /**
   * Auto-submit code input form when user first enters their code
   */
  useEffect(() => {
    if (isAutoSubmittable && otcInputValue?.length === ONE_TIME_CODE_LENGTH) {
      setIsAutoSubmittable(false);
      onVerifyAuth();
    }
  }, [isAutoSubmittable, onVerifyAuth, otcInputValue]);

  return (
    <>
      <FormErrors className={style.formErrors} errorMessages={errorMessages} isSmallDialog />
      <AuthContent>
        <div className={style.authForm} data-testid="loginMFA-scenario1">
          {!isCodeInputView && (
            /* Initial view; user selects their verification method */
            <>
              <h2 className={style.header}>{t('choose_verification_method')}</h2>
              <p className={style.subHeader}>{t('mfa_intro_message')}</p>
              <RadioPanels onClick={onRadioPanelClick} panels={panels} />
              <SubmitButton dataTestId="submit" disabled={!selectedOption} onClick={onSendCode}>
                {t('send_code')}
              </SubmitButton>
            </>
          )}

          {isCodeInputView && (
            /* Secondary view; user confirms one-time-code */
            <>
              <h2 className={style.header}>{t('enter_verification_code')}</h2>
              <p className={style.subHeader}>
                <InterpolatedTranslation
                  args={[
                    `<strong>${selectedOption === UserAccountVerificationType.SMS ? user?.cellPhone : user?.email}</strong>`,
                  ]}
                  namespace="mfa_verification_code_sent_message"
                />
              </p>
              <OneTimeCodeInput
                className={style.codeInput}
                inputLength={ONE_TIME_CODE_LENGTH}
                onChange={onOTCInputChange}
              />
              {showResendBanner && (
                <NotificationBanner primaryGlyphType="info" theme="green-solid">
                  {t('verification_code_sent')}
                </NotificationBanner>
              )}
              <LinkButton className={style.linkButton} onClick={onResendCodeDebounced} theme="white">
                {t('resend_code')}
              </LinkButton>
              <SubmitButton
                dataTestId="submit"
                disabled={otcInputValue?.length < ONE_TIME_CODE_LENGTH}
                onClick={onVerifyAuth}
              >
                {t('send_code')}
              </SubmitButton>
              <LinkButton className={classnames(style.linkButton, style.backButton)} onClick={onBack} theme="white">
                {t('back')}
              </LinkButton>
            </>
          )}

          <div className={classnames(style.altLinks, style.supportText)}>
            <p>
              <InteractiveText linkClassName={style.altLink}>{t('mfa_need_help_message')}</InteractiveText>
            </p>
          </div>
        </div>
      </AuthContent>
    </>
  );
};

export default withRouter(LoginMFAScenario1);
