import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Route } from 'store/routing/routes';
import { setIsPubSubActive } from 'store/system/systemActions';
import { useLocation } from 'constants/reactRouter';

// A collection of routes that require PubSub to be enabled
const enabledPubSubRoutes = [
  Route.HOME,
  Route.ADMIN_LIVE_LANES_AUCTIONEER,
  Route.ADMIN_LIVE_LANES_AUCTION_CLERK,
  Route.ADMIN_LIVE_LANES_MARQUEE,
  Route.BUY,
  Route.BUY_BUY_NOW,
  Route.BUY_HOURLY_AUCTION,
  Route.BUY_LIVE_AUCTION,
  Route.BUY_TIMED_AUCTION,
  Route.SELL,
  Route.SELL_PARKED,
];

export const usePubSub = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  /**
   * Callback to enable/disable PubSub service
   */
  const setPubSubActive = useCallback(
    (isActive: boolean) =>
      // TODO: Fix typing
      dispatch(setIsPubSubActive(isActive) as any),
    [dispatch]
  );

  useEffect(() => {
    // Route change detected; determine if our PubSub service should be enabled
    const isPubSubEnabledRoute = enabledPubSubRoutes.includes(pathname as Route);
    setPubSubActive(isPubSubEnabledRoute);
  }, [pathname, setPubSubActive]);

  return () => {
    // Disable when unmounting
    setPubSubActive(false);
  };
};
