import { clamp } from 'lodash-es';

import addGlyph from 'glyphs/add.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Checkbox from 'forms/shared/checkbox';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import FormSectionFixed from 'forms/admin/auctions/addModifyFeeFormSectionFixed';
import FormSectionTiered from 'forms/admin/auctions/addModifyFeeFormSectionTiered';
import InputGroup from 'forms/shared/inputGroup';
import MultiSelectCard from 'forms/shared/multiSelectCard';
import RowButtonWithArrow from 'components/ui/shared/buttons/rowButtonWithArrow';
import Sprite from 'components/ui/shared/sprite';
import {
  AuctionFeeBuyerShield,
  auctionFeeBuyerShieldTranslationMap,
  AuctionFeeVerified,
  auctionFeeVerifiedTranslationMap,
} from 'constants/enums/auctionFees';
import {
  AuctionFeeMode,
  AuctionItemFormat,
  AuctionServiceFeeTier,
  AuctionServiceMetadata,
  AuctionServiceMetadataParty,
  AuctionServiceTriState,
  AuctionServiceType,
  CompanySubType,
  MutationauctionItemAuctionServiceCreateArgs,
  MutationauctionServiceCreateArgs,
  MutationinventoryServiceCreateArgs,
  MutationlocationInventoryServiceCreateArgs,
  QueryauctionItemAuctionServiceMetadataArgs,
  QueryauctionServiceMetadataArgs,
  QueryinventoryServiceMetadataArgs,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { FormDialogBody, FormDialogFooter, FormErrors, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { Spinner, SpinnerCentered } from 'components/ui/loading/loading';
import { auctionServiceTypeTranslationMap } from 'constants/auctionService';
import { getCheckboxArray, getSelectOptionsForEnum } from 'utils/formUtils';
import { joinStrings, nullifyEmptyString } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './addFeeForm.scss';

const emptyTier = {
  id: undefined,
  startPrice: undefined,
  endPrice: undefined,
  value: undefined,
  mode: AuctionFeeMode.AMOUNT,
} as unknown as AuctionServiceFeeTier;

const verifiedValues = {
  [AuctionFeeVerified.ALL_VEHICLES]: AuctionServiceTriState.BOTH,
  [AuctionFeeVerified.NON_VERIFIED]: AuctionServiceTriState.FALSE,
  [AuctionFeeVerified.VERIFIED]: AuctionServiceTriState.TRUE,
};

const buyerShieldValues = {
  [AuctionFeeBuyerShield.ALL_VEHICLES]: AuctionServiceTriState.BOTH,
  [AuctionFeeBuyerShield.NON_BUYER_SHIELD_VEHICLES]: AuctionServiceTriState.FALSE,
  [AuctionFeeBuyerShield.BUYER_SHIELD_VEHICLES]: AuctionServiceTriState.TRUE,
};

const companySubTypeOptions = [
  { id: CompanySubType.CAPTIVE_FINANCE, title: 'company_sub_type_captive_finance' },
  { id: CompanySubType.DEALER_INDEPENDENT, title: 'company_sub_type_dealer_independent' },
  { id: CompanySubType.DEALER_FRANCHISE, title: 'company_sub_type_dealer_franchise' },
  { id: CompanySubType.FLEET_LEASE_FINANCE, title: 'company_sub_type_fleet_lease_finance' },
  { id: CompanySubType.FLEET_LEASE_OTHER, title: 'company_sub_type_fleet_lease_other' },
  { id: CompanySubType.FLEET_LEASE_RENTAL, title: 'company_sub_type_fleet_lease_rental' },
  { id: CompanySubType.OEM, title: 'company_sub_type_oem' },
];

export enum FeeType {
  AUCTION = 'AUCTION',
  AUCTION_COMPANY = 'AUCTION_COMPANY',
  AUCTION_ITEM = 'AUCTION_ITEM',
  INVENTORY = 'INVENTORY',
  LOCATION = 'LOCATION',
}

export interface AuctionFeeProps {
  /** The id of the auction. */
  auctionId: string;
  /** The id of the auction. */
  companyId?: string;
  /** Type of fees */
  feeType: FeeType.AUCTION | FeeType.AUCTION_COMPANY;
  /** Auction Item Format */
  format: AuctionItemFormat;
}

export interface AuctionItemFeeProps extends Omit<AuctionFeeProps, 'feeType'> {
  /** The id of the auction item. */
  auctionItemId: string;
  /** Type of fees */
  feeType: FeeType.AUCTION_ITEM;
}

export interface InventoryItemFeeProps {
  /** Type of fees */
  feeType: FeeType.INVENTORY;
  /** The id of the inventory item. */
  inventoryItemId: string;
}

export interface LocationFeeProps {
  /** Type of fees */
  feeType: FeeType.LOCATION;
  /** The id of the location. */
  locationId: string;
}

export type ServiceMetadataOptions =
  | { feeType: FeeType.AUCTION_ITEM; options: QueryauctionItemAuctionServiceMetadataArgs }
  | { feeType: FeeType.AUCTION | FeeType.AUCTION_COMPANY; options: QueryauctionServiceMetadataArgs }
  | { feeType: FeeType.INVENTORY; options: QueryinventoryServiceMetadataArgs }
  | { feeType: FeeType.LOCATION; options: QueryinventoryServiceMetadataArgs };

export type ServiceCreateOptions =
  | { feeType: FeeType.AUCTION_ITEM; options: MutationauctionItemAuctionServiceCreateArgs }
  | { feeType: FeeType.AUCTION | FeeType.AUCTION_COMPANY; options: MutationauctionServiceCreateArgs }
  | { feeType: FeeType.INVENTORY; options: MutationinventoryServiceCreateArgs }
  | { feeType: FeeType.LOCATION; options: MutationlocationInventoryServiceCreateArgs };

interface BaseProps {
  /** Service meta data to be displayed */
  availableFees?: AuctionServiceMetadata[];
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Callback to handle retrieving of meta data */
  getFeeMetadata: (options: ServiceMetadataOptions) => void;
  /** True when meta data is loading */
  isLoadingFeeMetadata: boolean;
  /** True opens slide out */
  isOpen: boolean;
  /** True when form is submitting */
  isSubmitting: boolean;
  /** Callback to handle onClose event */
  onClose: () => void;
  /** Callback to handle fee submission */
  onSubmit: (options: ServiceCreateOptions) => void;
  /** Buyer or Seller */
  party: AuctionServiceMetadataParty;
}

export type IntersectedAuctionFeeProps = AuctionFeeProps & BaseProps;
export type IntersectedAuctionItemFeeProps = AuctionItemFeeProps & BaseProps;
export type IntersectedInventoryItemFeeProps = InventoryItemFeeProps & BaseProps;
export type IntersectedLocationFeeProps = LocationFeeProps & BaseProps;

type Props =
  | IntersectedAuctionFeeProps
  | IntersectedAuctionItemFeeProps
  | IntersectedInventoryItemFeeProps
  | IntersectedLocationFeeProps;

interface State {
  buyerShield: SelectOption<AuctionFeeBuyerShield>;
  companySubTypes: CompanySubType[];
  currentStep: 1 | 2 | 3;
  fee: AuctionServiceMetadata | undefined;
  fixedMode: AuctionFeeMode;
  fixedValue: number | string | undefined;
  isTieredFeeType: boolean;
  paidByBuyer: boolean | undefined;
  skipInitialStep: boolean;
  tierType: SelectOption<AuctionServiceType>;
  tiers: AuctionServiceFeeTier[];
  verified: SelectOption<AuctionFeeVerified>;
}

class AddFeeForm extends BaseClass<Props, State> {
  private readonly buyerShieldOptions: SelectOption<AuctionFeeBuyerShield>[];
  private readonly typeOptions: SelectOption<AuctionServiceType>[];
  private readonly verifiedOptions: SelectOption<AuctionFeeVerified>[];

  constructor(props: Props) {
    super(props);

    this.buyerShieldOptions = getSelectOptionsForEnum(AuctionFeeBuyerShield, auctionFeeBuyerShieldTranslationMap);

    this.typeOptions = getSelectOptionsForEnum(AuctionServiceType, auctionServiceTypeTranslationMap);

    this.verifiedOptions = getSelectOptionsForEnum(AuctionFeeVerified, auctionFeeVerifiedTranslationMap);

    this.state = {
      buyerShield: this.buyerShieldOptions[0],
      companySubTypes: [],
      currentStep: 1,
      fee: undefined,
      fixedMode: AuctionFeeMode.AMOUNT,
      fixedValue: undefined,
      isTieredFeeType: false,
      paidByBuyer: undefined,
      skipInitialStep: this.props.feeType === FeeType.INVENTORY || this.props.feeType === FeeType.LOCATION,
      tierType: this.typeOptions[0],
      tiers: [emptyTier],
      verified: this.verifiedOptions[0],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps?.isOpen && !this.props?.isOpen) {
      // Reset state
      this.setState({
        buyerShield: this.buyerShieldOptions[0],
        companySubTypes: [],
        currentStep: 1,
        fee: undefined,
        fixedMode: AuctionFeeMode.AMOUNT,
        fixedValue: undefined,
        isTieredFeeType: false,
        paidByBuyer: undefined,
        tierType: this.typeOptions[0],
        tiers: [emptyTier],
        verified: this.verifiedOptions[0],
      });
    }

    // Skip to the next step if `skipInitialStep` is true
    if (this.props.isOpen && this.state.skipInitialStep && this.state.currentStep === 1) {
      this.onNextStep();
    }
  }

  addTier = () => {
    this.setState({ tiers: [...this.state.tiers, emptyTier] });
  };

  removeTier = (index) => {
    this.setState({ tiers: this.state.tiers.filter((tier, i) => i !== index) });
  };

  getMetadataOptions = (): ServiceMetadataOptions => {
    const { party, ...props } = this.props;

    switch (props.feeType) {
      case FeeType.LOCATION:
      case FeeType.INVENTORY:
        return {
          feeType: props.feeType,
          options: {
            party,
          },
        };

      case FeeType.AUCTION_ITEM:
        return {
          feeType: props.feeType,
          options: {
            auctionItemId: props.auctionItemId,
            party,
          },
        };

      case FeeType.AUCTION_COMPANY:
        return {
          feeType: props.feeType,
          options: {
            auctionId: props.auctionId,
            buyerShield: buyerShieldValues[this.state.buyerShield.value],
            companyId: props.companyId,
            companySubTypes: this.state?.companySubTypes?.length ? this.state?.companySubTypes : undefined,
            formats: [props.format],
            paidBy: this.state.paidByBuyer ? AuctionServiceMetadataParty.BUYER : party,
            party,
            verified: verifiedValues[this.state.verified.value],
          },
        };

      case FeeType.AUCTION:
      default: {
        return {
          feeType: props.feeType,
          options: {
            auctionId: props.auctionId,
            buyerShield: buyerShieldValues[this.state.buyerShield.value],
            companyId: props.companyId,
            companySubTypes: this.state?.companySubTypes?.length ? this.state?.companySubTypes : undefined,
            formats: [props.format],
            party,
            verified: verifiedValues[this.state.verified.value],
          },
        };
      }
    }
  };

  getFeeCreateOptions = (): ServiceCreateOptions => {
    const feeType = this.props.feeType;
    const tierFeeType = this.state.tierType?.value;

    const baseAuctionAuctionItemOptions = {
      auctionServiceMetadataId: this.state.fee?.id,
      feeType: tierFeeType,
      ...(tierFeeType === AuctionServiceType.FIXED && {
        feeFixedMode: this.state.fixedMode,
        feeFixedValue: nullifyEmptyString(this.state?.fixedValue as string),
      }),
      ...(tierFeeType === AuctionServiceType.TIERED && {
        feeTiers: this.state.tiers?.map((tier) => ({
          mode: tier?.mode,
          value: nullifyEmptyString(tier?.value),
          startPrice: nullifyEmptyString(tier?.startPrice),
          endPrice: nullifyEmptyString(tier?.endPrice),
        })),
      }),
    } as MutationauctionItemAuctionServiceCreateArgs | MutationauctionServiceCreateArgs;

    switch (feeType) {
      case FeeType.LOCATION:
        return {
          feeType,
          options: {
            amount: Number(this.state.fixedValue),
            locationId: this.props.locationId,
            serviceMetadataId: this.state.fee?.id,
          } as MutationlocationInventoryServiceCreateArgs,
        };

      case FeeType.INVENTORY:
        return {
          feeType,
          options: {
            amount: Number(this.state.fixedValue),
            inventoryItemId: this.props.inventoryItemId,
            serviceMetadataId: this.state.fee?.id,
          } as MutationinventoryServiceCreateArgs,
        };

      case FeeType.AUCTION_ITEM:
        return {
          feeType,
          options: {
            ...baseAuctionAuctionItemOptions,
            auctionItemId: this.props.auctionItemId,
          },
        };

      case FeeType.AUCTION:
      case FeeType.AUCTION_COMPANY:
      default: {
        const buyerShield = buyerShieldValues[this.state.buyerShield.value];
        const verified = verifiedValues[this.state.verified.value];

        return {
          feeType,
          options: {
            ...baseAuctionAuctionItemOptions,
            auctionId: this.props.auctionId,
            buyerShield,
            companyId: this.props.companyId,
            companySubTypes: this.state?.companySubTypes?.length ? this.state?.companySubTypes : undefined,
            format: this.props.format,
            paidBy: this.state.paidByBuyer ? AuctionServiceMetadataParty.BUYER : undefined,
            verified,
          },
        };
      }
    }
  };

  onNextStep = () => {
    if (this.state?.currentStep === 1) {
      const options = this.getMetadataOptions();
      // Fetch fee metadata and render options
      this.props?.getFeeMetadata(options);
      this.setState({ currentStep: 2 });
    } else if (this.state?.currentStep === 3) {
      this.onSubmit();
    }
  };

  onSubmit = () => {
    const options = this.getFeeCreateOptions();
    this.props.onSubmit(options);
  };

  render() {
    const { availableFees, errorMessages, isLoadingFeeMetadata, isOpen, isSubmitting, feeType, onClose, party } =
      this.props;
    const { companySubTypes, currentStep, fee, isTieredFeeType, tiers } = this.state;

    const title = joinStrings([t('add_x_fee', [party]), fee?.feeName], ' - ');
    const nextButtonTitle = currentStep === 3 ? t('save') : t('next');

    return (
      <FormDialog isOpen={isOpen} onClose={onClose} title={title}>
        <FormErrors errorMessages={errorMessages} />
        <FormDialogBody>
          {currentStep === 1 && (
            <>
              <FormSection>
                <InputGroup
                  groupType="select"
                  label={t('type')}
                  name="type"
                  onChange={(tierType) => {
                    this.setState({ tierType, isTieredFeeType: tierType?.value === AuctionServiceType.TIERED });
                  }}
                  options={this.typeOptions}
                  value={this?.state?.tierType}
                />
                {(feeType === FeeType.AUCTION || feeType === FeeType.AUCTION_COMPANY) && (
                  <>
                    <InputGroup
                      groupType="select"
                      label={t('verified')}
                      name="verified"
                      onChange={(value) => this.setState({ verified: value })}
                      options={this.verifiedOptions}
                      value={this?.state?.verified}
                    />
                    <InputGroup
                      groupType="select"
                      label={t('buyer_shield')}
                      name="buyerShield"
                      onChange={(value) => this.setState({ buyerShield: value })}
                      options={this.buyerShieldOptions}
                      value={this?.state?.buyerShield}
                    />
                  </>
                )}
              </FormSection>
              {feeType === FeeType.AUCTION && (
                <FormSection title={t('dealer_category')}>
                  {companySubTypeOptions?.map((companySubType) => (
                    <Checkbox
                      key={companySubType?.id}
                      checked={companySubTypes?.includes(companySubType?.id)}
                      dataTestId={t(companySubType?.title)}
                      id={companySubType?.id}
                      isButtonTheme
                      onChange={(e) => {
                        const companySubTypesNext = getCheckboxArray(e, companySubType?.id, companySubTypes);
                        this.setState({ companySubTypes: companySubTypesNext });
                      }}
                      text={t(companySubType?.title)}
                    />
                  ))}
                </FormSection>
              )}
              {feeType === FeeType.AUCTION_COMPANY && party === AuctionServiceMetadataParty.SELLER && (
                <FormSection>
                  <MultiSelectCard
                    dataTestId="paid-by-buyer"
                    isSelected={this.state.paidByBuyer}
                    onSelect={() => this.setState({ paidByBuyer: !this.state.paidByBuyer })}
                    title={t('paid_by_buyer')}
                  />
                </FormSection>
              )}
            </>
          )}

          {currentStep === 2 && (
            <FormSection flexDirection="column">
              <p className={style.chooseFieldHeader}>
                {!availableFees?.length && !isLoadingFeeMetadata ? t('no_fees_to_add') : t('choose_fee_type')}
              </p>

              <div className={style.chooseFieldRows}>
                {availableFees?.map((availableFee) => (
                  <RowButtonWithArrow
                    key={availableFee?.id}
                    className={style.chooseFieldRow}
                    label={availableFee?.feeName}
                    onClick={() => this.setState({ currentStep: 3, fee: availableFee })}
                  />
                ))}
              </div>
              {isLoadingFeeMetadata && <SpinnerCentered />}
            </FormSection>
          )}

          {currentStep === 3 && (
            <>
              {isTieredFeeType && (
                <>
                  {tiers?.map((tier, index) => (
                    <FormSectionTiered
                      key={tier?.id || `tiered-section-${index}`}
                      onChange={(updatedTier) =>
                        this.setState({ tiers: Object.assign([], tiers, { [index]: updatedTier }) })
                      }
                      onRemoveFeeTier={tiers.length > 1 ? () => this.removeTier(index) : undefined}
                      tier={tier}
                    />
                  ))}
                  <FormSection>
                    <Button className={style.addTier} dataTestId="addTier-button" onClick={this.addTier} theme="none">
                      <Sprite className={style.sprite} glyph={addGlyph} />
                      {t('add_tier')}
                    </Button>
                  </FormSection>
                </>
              )}

              {!isTieredFeeType && (
                <FormSectionFixed
                  hideCurrencyToggle={feeType === FeeType.INVENTORY}
                  onChange={(value) => this.setState({ fixedValue: value })}
                  onFeeModeChange={(value) => this.setState({ fixedMode: value })}
                />
              )}
            </>
          )}
        </FormDialogBody>

        <FormDialogFooter>
          <Button
            disabled={currentStep === 1}
            onClick={() => this.setState({ currentStep: clamp(currentStep - 1, 1, 3) })}
            style={{ marginRight: 'auto' }}
            theme="gray-outline"
          >
            {t('back')}
          </Button>
          <Button
            disabled={
              isLoadingFeeMetadata ||
              isSubmitting ||
              currentStep === 2 ||
              (currentStep === 3 &&
                isTieredFeeType &&
                tiers?.some((tier) => !tier?.startPrice?.toString()?.length || !tier?.value?.toString()?.length))
            }
            onClick={this.onNextStep}
            theme="blue"
          >
            {isLoadingFeeMetadata || isSubmitting ? <Spinner /> : nextButtonTitle}
          </Button>
        </FormDialogFooter>
      </FormDialog>
    );
  }
}

export default AddFeeForm;
