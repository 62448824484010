import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import { isEqual } from 'lodash-es';

import { Declaration } from 'store/shared/api/graph/interfaces/types';
import { InventoryItemInitialState, InventoryItemResult, InventoryItemVehicle, Mileage } from './addModifyModels';
import { Page, Overlay } from 'constants/enums/addModifyInventoryItem';
import {
  addModifyAddPhoto,
  addModifyClear,
  addModifyClearError,
  addModifyPhotosError,
  addModifySet,
  addModifySetAsyncCarfaxDeclarations,
  addModifySetAttachCarfax,
  addModifySetError,
  addModifySetVehicle,
  isLoading,
  isNotLoading,
  isUpdating,
  pinVehicleDetails,
  setCountryCode,
  setDeclarations,
  setDuplicateVins,
  setModal,
  setOverlay,
  setPage,
  setPageImplicitly,
  setPhotoRemainingUploads,
} from './addModifyActions';

const getHasStateChanged = (results) => {
  const { vehicle, displacement, metadata, ...fields } = results;
  const hasSelectedFields = Object.keys(fields)?.some((k) => fields[k]?.some((o) => o?.selected));
  return hasSelectedFields || !!displacement;
};

const getSelectedId = (array) => (array.find((item) => !!item.selected) || {}).id;

const getSelectedItems = (array) => array.filter((item) => !!item.selected).map((item) => item.id);

export const addModifyInventoryItemReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [addModifyClear().type]: (state) => {
      return state.setLoaded().merge(new InventoryItemInitialState());
    },

    [addModifySetVehicle().type]: (state, action) => {
      const prevState = state.toJS();
      const { ignoreHasStateChanged, ...actionPayload } = action.payload;

      return state.setLoaded().merge({
        results: new InventoryItemResult({
          ...prevState.results,
          vehicle: new InventoryItemVehicle({
            ...prevState.results.vehicle,
            ...actionPayload,
            displacement:
              (actionPayload?.displacement || prevState.results?.vehicle?.displacement) ??
              prevState?.results?.displacement,
            mileage: new Mileage({
              ...prevState.results.vehicle.mileage,
              ...actionPayload.mileage,
            }),
          }),
        }),
        hasStateChanged: ignoreHasStateChanged ? prevState.hasStateChanged : prevState.page !== Page.VIN_OVERLAY,
      });
    },

    [addModifySet().type]: (state, action) => {
      const prevState = state.toJS();
      const {
        overlay,
        countryCode,
        results: { vehicle: vehiclePrev },
      } = prevState;
      const { results: resultsPayload, switchPage } = action.payload;
      const cylinders = getSelectedId(resultsPayload?.cylinders) || vehiclePrev?.cylinders;
      const displacement = resultsPayload.displacement;

      const CRPrev = vehiclePrev.conditionReport;
      const mileagePrev = { ...vehiclePrev.mileage, unit: vehiclePrev.mileage?.unit };
      const vehiclePayload = resultsPayload.vehicle;
      const CRPayload = vehiclePayload && vehiclePayload.conditionReport;
      const getCRValueByKey = (key: string) => {
        if (CRPayload) {
          return CRPayload[key];
        }

        if (CRPrev) {
          return CRPrev[key];
        }

        return null;
      };
      const declarationsPayload = CRPayload && getSelectedItems(CRPayload.declarations);
      const tireConditionPayload = resultsPayload?.tireCondition ?? vehiclePayload?.tireCondition ?? {};

      let trimId = getSelectedId(resultsPayload.trim);
      const trim = vehiclePayload && !trimId ? vehiclePayload.trim : vehiclePrev.trim;

      if (vehiclePrev.trimId === 'OTHER' || trim) {
        trimId = 'OTHER';
      }

      if (!vehiclePrev.id) {
        mileagePrev.unit = ['US', 'UK', 'LI', 'MM'].includes(countryCode) ? 'mi' : 'km';
      }

      const vehicle = new InventoryItemVehicle({
        ...vehiclePrev,
        ...(vehiclePayload || {}),
        mileage: new Mileage({
          ...mileagePrev,
          ...(vehiclePayload && vehiclePayload.mileage),
        }),
        cylinders,
        displacement,
        trimId,
        trim,
        auctionLocationId: resultsPayload?.auctionLocation?.[0]?.id,
        locationId: getSelectedId(resultsPayload.startingLocation),
        pickupLocationId: getSelectedId(resultsPayload.pickupLocation),
        conditionReport: {
          carfaxCanadaClaimAmount: getCRValueByKey('carfaxCanadaClaimAmount') || { amount: null },
          carfaxCanadaReportUrl: getCRValueByKey('carfaxCanadaReportUrl') || null,
          carfaxCanadaReportStatus: getCRValueByKey('carfaxCanadaReportStatus') || null,
          overallConditionRating: getCRValueByKey('overallConditionRating') || 0,
          declarations: declarationsPayload || CRPrev?.declarations || [],
        },
        tireCondition: {
          ...tireConditionPayload,
          driverFrontTread: tireConditionPayload.driverFrontTread?.value,
          driverRearTread: tireConditionPayload.driverRearTread?.value,
          passengerFrontTread: tireConditionPayload.passengerFrontTread?.value,
          passengerRearTread: tireConditionPayload.passengerRearTread?.value,
        },
      });

      // If the previous value of photos is `null`,
      // we know that's the initial value and can exempt it from being 'changed'.
      const hasPhotosChanged = vehiclePrev.photos === null ? false : !isEqual(vehiclePrev.photos, vehicle.photos);
      const hasVinDecoded = !vehiclePayload && switchPage && getHasStateChanged(resultsPayload);
      const isSaving = overlay === Overlay.SAVING;

      // Reset hasStateChanged upon newly created vehicle
      let hasStateChanged = hasVinDecoded || isSaving || prevState.hasStateChanged;
      if (!vehiclePrev.id && vehicle.id) {
        hasStateChanged = false;
      }

      return state.setLoaded().merge({
        results: new InventoryItemResult({
          ...prevState.results,
          ...resultsPayload,
          vehicle,
        }),
        overlay: isSaving ? Overlay.CLOSE_MODAL : null,
        hasStateChanged,
        hasPhotosChanged,
      });
    },

    [pinVehicleDetails().type]: (state) => {
      const prevState = state.toJS();

      const results = prevState?.results;
      const vehicle = results?.vehicle;
      let trimId = getSelectedId(results?.trim);
      const trim = vehicle && !trimId ? vehicle?.trim : vehicle?.trim;

      if (vehicle?.trimId === 'OTHER' || trim) {
        trimId = 'OTHER';
      }

      return state.set(
        'results',
        new InventoryItemResult({
          ...prevState?.results,
          vehicle: new InventoryItemVehicle({
            ...prevState?.results?.vehicle,
            year: getSelectedId(state?.results?.year),
            makeId: getSelectedId(state?.results?.make),
            modelId: getSelectedId(state?.results?.model),
            subModelId: getSelectedId(state?.results?.subModel),
            trimId,
            trim,
            bodyType: getSelectedId(state?.results?.bodyType),
            chargingCable: getSelectedId(state?.results?.chargingCable),
            exteriorColor: getSelectedId(state?.results?.exteriorColor),
            interiorColor: getSelectedId(state?.results?.interiorColor),
            options: getSelectedItems(state?.results?.options),
            numberOfDoors: getSelectedId(state?.results?.numberOfDoors),
            numberOfPassengers: getSelectedId(state?.results?.numberOfPassengers),
            transmission: getSelectedId(state?.results?.transmission),
            driveTrain: getSelectedId(state?.results?.driveTrain),
            displacement: state?.results?.displacement,
            fuelType: getSelectedId(state?.results?.fuelType),
          }),
        })
      );
    },

    [addModifySetAsyncCarfaxDeclarations().type]: (state, action) => {
      const prevState = state.toJS();
      const carfaxCanadaReportStatus = action.payload?.carfaxCanadaReportStatus;
      const declarations = prevState?.results?.vehicle?.conditionReport?.declarations ?? [];
      const declarationsNext = Array.from(new Set([...declarations, ...action.payload?.declarations]));

      return (
        state
          // Update CARFAX report status
          ?.setIn(['results', 'vehicle', 'conditionReport', 'carfaxCanadaReportStatus'], carfaxCanadaReportStatus)

          // Mark any declarations received via CarFax as selected
          ?.setIn(['results', 'vehicle', 'conditionReport', 'declarations'], declarationsNext)
      );
    },

    [addModifySetAttachCarfax().type]: (state, action) => {
      const prevState = state.toJS();
      const prevConditionReport = prevState.results.vehicle.conditionReport;
      const declarations = prevConditionReport?.declarations ?? [];
      const declarationPayload = action.payload.declarations.map((declaration: Declaration) => declaration.id);
      const declarationsNext = Array.from(new Set([...declarations, ...declarationPayload]));

      return state.setLoaded().merge({
        results: new InventoryItemResult({
          ...prevState.results,
          vehicle: new InventoryItemVehicle({
            ...prevState.results.vehicle,
            conditionReport: {
              ...prevConditionReport,
              ...action.payload,
              declarations: declarationsNext,
            },
          }),
        }),
        hasStateChanged: true,
      });
    },

    [addModifyAddPhoto().type]: (state, action) => {
      const prevState = state.toJS();
      const error = prevState._error || [];
      const { photo, ...actionPayload } = action.payload;
      return state.setError(error).merge({
        results: new InventoryItemResult({
          ...prevState.results,
          vehicle: new InventoryItemVehicle({
            ...prevState.results.vehicle,
            ...actionPayload,
            photos: [...(prevState.results.vehicle.photos ?? []), photo],
          }),
        }),
        hasPhotosChanged: true,
      });
    },

    [addModifyPhotosError().type]: (state, action) => {
      const prevState = state.toJS();
      const stateError = prevState._error || [];
      const error = List<Error>([
        ...stateError,
        ...(Array.isArray(action.payload) ? action.payload : [action.payload]),
      ]);

      return state.setError(error).merge({
        results: new InventoryItemResult({
          ...prevState.results,
          vehicle: new InventoryItemVehicle({
            ...prevState.results.vehicle,
            remainingUploads: action.payload.remainingUploads,
            uploadPhotoType: action.payload.uploadPhotoType,
          }),
        }),
      });
    },

    [addModifySetError().type]: (state, action) => state.setError(List(action.payload)).set('overlay', null),

    [addModifyClearError().type]: (state) => state.clearError(),

    [setDuplicateVins().type]: (state, action) => state.setLoaded().set('duplicates', action.payload),

    [setDeclarations().type]: (state, action) => state.set('declarationsMeta', action.payload),

    [setModal().type]: (state, action) => state.setLoaded().set('modal', action.payload),

    [setPage().type]: (state, action) => state.setLoaded().set('page', action.payload),

    [setPageImplicitly().type]: (state, action) => {
      const prevState = state.toJS();

      const { page, results } = prevState;
      const { switchPage } = action.payload;
      const vehicle = results?.vehicle;
      let trimId = getSelectedId(results.trim);
      const trim = vehicle && !trimId ? vehicle.trim : vehicle.trim;

      if (vehicle?.trimId === 'OTHER' || trim) {
        trimId = 'OTHER';
      }

      let hasContinuedWithCapture = prevState?.hasContinuedWithCapture;

      // If multiple trims are returned and none are selected, force user to select a trim.
      let pageNext = switchPage ? Page.VEHICLE_FORM : page;
      if ((page === Page.VIN_OVERLAY || page === Page.VIN_OVERLAY_DUPLICATE) && pageNext === Page.VEHICLE_FORM) {
        if (results.trim && results.trim.length > 1 && !trimId) {
          // Prevent displaying the `VIN_OVERLAY_DUPLICATE` again.
          hasContinuedWithCapture = true;
          pageNext = Page.SELECT_TRIM;
        }
      }

      return state.merge({ page: pageNext, hasContinuedWithCapture });
    },

    [setOverlay().type]: (state, action) => state.setLoaded().set('overlay', action.payload),

    [setCountryCode().type]: (state, action) => state.setLoaded().set('countryCode', action.payload),

    [setPhotoRemainingUploads().type]: (state, action) => {
      const prevState = state.toJS();

      return state.merge({
        results: new InventoryItemResult({
          ...prevState.results,
          vehicle: new InventoryItemVehicle({
            ...prevState.results.vehicle,
            remainingUploads: action.payload.remainingUploads,
            uploadPhotoType: action.payload.uploadPhotoType,
          }),
        }),
      });
    },
  },
  new InventoryItemInitialState()
);
