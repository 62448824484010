import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionTimeSlot, MutationauctionTimeSlotChangeCutoffArgs } from 'store/shared/api/graph/interfaces/types';

export const auctionTimeSlotChangeCutoff = (options: MutationauctionTimeSlotChangeCutoffArgs) =>
  ApiRequest<{ auctionTimeSlotChangeCutoff: AuctionTimeSlot }>({
    data: {
      query: `
        mutation($auctionTimeSlotId: String!) {
          auctionTimeSlotChangeCutoff(auctionTimeSlotId: $auctionTimeSlotId) {
            id
            cutOffTime
          }
        }
      `,
      variables: {
        ...options,
      },
    },
  });
