import Button from 'components/ui/shared/button';
import Map, { openMapLink } from 'components/ui/maps/map';
import { Chevron } from 'components/ui/shared/chevrons';
import { Company, Location } from 'store/shared/api/graph/interfaces/types';
import { FeatureFlag } from 'constants/featureFlags';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { Spinner } from 'components/ui/loading/loading';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { joinStrings } from 'utils/stringUtils';

import style from './companyLocation.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    /** The company details. */
    company: Company;
    /** The company's primary location. */
    location?: Location;
  };
}

const CompanyLocation = ({ inventoryDetailsData }: Props) => {
  if (!inventoryDetailsData) {
    return <Spinner className={style.detailsLoading} />;
  }

  const { company, location } = inventoryDetailsData;

  return (
    <div className={style.location}>
      {location && <Map className={style.map} dataTestId="company-location-map" locations={[location]} />}
      <Button className={style.detailsButton} onClick={() => openMapLink(location)} theme="none">
        <div>
          <div className={style.companyName}>{company.name}</div>
          {hasFeatureFlag(FeatureFlag.NUMERIC_COMPANY_ID) && <div>{company.numericId}</div>}
          {location && (
            <div className={style.companyPreferredLocation}>
              <div>{location.address1}</div>
              {location.address2 && <div>{location.address2}</div>}
              <div>{joinStrings([location.city, location.regionCode, location.countryCode])}</div>
            </div>
          )}
        </div>
        <Chevron />
      </Button>
    </div>
  );
};

export default CompanyLocation;
