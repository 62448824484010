import { ReactNode } from 'react';
import { RequireAtLeastOne } from 'type-fest';

import AuctionItem from 'constants/auctionItem';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { AuctionItemStatus, VehicleCaptureType, VehicleStatus } from 'store/shared/api/graph/interfaces/types';
import { hasSystemPermission, isAuctionStaff, representsCompany } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

/**
 * Anchor tab keys that is used to construct the config
 */
enum AnchorTabKeys {
  AUCTION_DETAILS = 'AUCTION_DETAILS',
  ASSIGNED_REPS = 'ASSIGNED_REPS',
  CAPTURED_BY = 'CAPTURED_BY',
  REGISTERED_BY = 'REGISTERED_BY',
  DECLARATIONS = 'DECLARATIONS',
  COMPARABLES = 'COMPARABLES',
  CONDITION_REPORT = 'CONDITION_REPORT',
  OPTIONS = 'OPTIONS',
  SELLER_NOTES = 'SELLER_NOTES',
  SELLER_REQUESTED_VALUES = 'SELLER_REQUESTED_VALUES',
  VEHICLE_DETAILS = 'VEHICLE_DETAILS',
  SOLD_BY = 'SOLD_BY',
  PICK_UP_LOCATION = 'PICK_UP_LOCATION',
  VALUES = 'VALUES',
}

export type AnchorTabProps = RequireAtLeastOne<
  {
    /**
     * To override and allow custom React Components to be rendered, use `label` if
     * displaying just a string
     */
    component: ReactNode;

    /** ID of the element to scroll to.*/
    id: string;

    /** Whether the tab is visible or not.*/
    isVisible: boolean;

    /** Label of the tab.*/
    label: string;
  },
  'component' | 'label'
>;

export type AnchorTabsConfig = Record<AnchorTabKeys, AnchorTabProps>;

/**
 * Used to get anchor configuration for auction/inventory item details view.
 *
 * @param inventoryItemDetails inventory item details
 * @param auctionItem auction item
 * @param user current user
 * @param isAuctionItemView whether is auction item view or not
 */
export const getAnchorTabsConfig = (
  inventoryItemDetails: InventoryItem | null,
  auctionItem: AuctionItem | null,
  user: User,
  isAuctionItemView: boolean
): AnchorTabsConfig => {
  const buyerReps = auctionItem?.buyerReps;
  const isSeller = representsCompany(
    user,
    auctionItem ? auctionItem?.inventoryItem?.company?.id : inventoryItemDetails?.company?.id
  );
  const isStaffUser = isAuctionStaff(user);
  const isUpdatable = !!inventoryItemDetails?.isUpdatable;
  const sellerReps = auctionItem?.sellerReps || inventoryItemDetails?.sellerReps;

  return {
    [AnchorTabKeys.AUCTION_DETAILS]: {
      label: t('auction_details'),
      id: 'auctionDetailsSection',
      isVisible: true,
    },
    [AnchorTabKeys.ASSIGNED_REPS]: {
      label: t('assigned_reps'),
      id: 'assignedRepsSection',
      isVisible: !!(isAuctionItemView
        ? buyerReps?.length || sellerReps?.length
        : inventoryItemDetails?.sellerReps?.length),
    },
    [AnchorTabKeys.CAPTURED_BY]: {
      label: t('captured_by'),
      id: 'capturedBySection',
      isVisible:
        !!inventoryItemDetails?.capturedBy &&
        (isStaffUser || inventoryItemDetails.captureType === VehicleCaptureType.SELF_CAPTURE),
    },
    [AnchorTabKeys.REGISTERED_BY]: {
      label: t('registered_by'),
      id: 'registeredBySection',
      isVisible:
        !!inventoryItemDetails?.createdBy &&
        (inventoryItemDetails.status === VehicleStatus.AWAITING_CAPTURE ||
          inventoryItemDetails.status === VehicleStatus.REGISTERED),
    },
    [AnchorTabKeys.DECLARATIONS]: { label: t('declarations'), id: 'declarations', isVisible: true },
    [AnchorTabKeys.COMPARABLES]: {
      label: t('comparables'),
      id: 'comparablesSection',
      isVisible: hasSystemPermission(user, ['SYSTEM_VIEW_COMPARABLES']),
    },
    [AnchorTabKeys.CONDITION_REPORT]: { label: t('condition_report'), id: 'conditionReportSection', isVisible: true },
    [AnchorTabKeys.OPTIONS]: { label: t('options'), id: 'optionsSection', isVisible: true },
    [AnchorTabKeys.SELLER_NOTES]: {
      label: t('seller_notes'),
      id: 'sellerNotesSection',
      isVisible: !!inventoryItemDetails?.sellerNotes || isUpdatable,
    },
    [AnchorTabKeys.VEHICLE_DETAILS]: { label: t('vehicle_details'), id: 'vehicleDetailsSection', isVisible: true },
    [AnchorTabKeys.SOLD_BY]: {
      label: t('sold_by'),
      id: 'soldBySection',
      isVisible: !auctionItem?.hideSellerNameEnabled,
    },
    [AnchorTabKeys.PICK_UP_LOCATION]: { label: t('pick_up_location'), id: 'pickUpLocationSection', isVisible: true },
    [AnchorTabKeys.VALUES]: { label: t('values'), id: 'valuesSection', isVisible: true },
    [AnchorTabKeys.SELLER_REQUESTED_VALUES]: {
      label: t('seller_requested_values'),
      id: 'sellerRequestedValuesSection',
      isVisible: (isStaffUser || isSeller) && (!auctionItem || auctionItem?.status === AuctionItemStatus.NO_SALE),
    },
  };
};
