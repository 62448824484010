import { useMemo } from 'react';

import checkboxGlyph from 'glyphs/checkbox.svg';

import InventoryItem from 'constants/inventoryItem';
import Sprite from 'components/ui/shared/sprite';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './conditionReport.scss';

interface Props {
  /** Vehicle capture type. */
  captureType: InventoryItem['captureType'];
  /** The tire condition. */
  tireCondition?: InventoryItem['tireCondition'];
}

const TireDetails = ({ captureType, tireCondition }: Props) => {
  const formattedTireDetails = useMemo(() => {
    if (!isVerified(captureType) || !tireCondition) {
      return null;
    }

    const {
      driverFrontBrand,
      driverFrontSize,
      driverFrontTread,
      driverRearBrand,
      driverRearSize,
      driverRearTread,
      passengerFrontBrand,
      passengerFrontSize,
      passengerFrontTread,
      passengerRearBrand,
      passengerRearSize,
      passengerRearTread,
    } = tireCondition;

    try {
      const driverFrontTires = [driverFrontBrand, driverFrontSize, driverFrontTread?.formatted].join(' ');
      const driverRearTires = [driverRearBrand, driverRearSize, driverRearTread?.formatted].join(' ');
      const passengerFrontTires = [passengerFrontBrand, passengerFrontSize, passengerFrontTread?.formatted].join(' ');
      const passengerRearTires = [passengerRearBrand, passengerRearSize, passengerRearTread?.formatted].join(' ');

      // Join tires and strip spaces from slash separators
      return [
        driverFrontTires.split(/\s*\/\s*/).join('/'),
        passengerFrontTires.split(/\s*\/\s*/).join('/'),
        driverRearTires.split(/\s*\/\s*/).join('/'),
        passengerRearTires.split(/\s*\/\s*/).join('/'),
      ];
    } catch (err) {
      return null;
    }
  }, [captureType, tireCondition]);

  return formattedTireDetails ? (
    <div className={style.crRow} data-testid="tire-details">
      <div className={style.crRowTitle}>{t('tires')}</div>
      <div className={style.crRowContent}>
        {tireCondition?.tirePressureMonitoringSystem && (
          <Sprite className={style.crRowContentIcon} glyph={checkboxGlyph} />
        )}
        <ul className={style.crRowList}>
          {tireCondition?.tirePressureMonitoringSystem && <li>{t('tpms')}</li>}
          {formattedTireDetails.map((tireDetail, index) => (
            <li key={`tireDetail-${index}`}>{tireDetail}</li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default TireDetails;
