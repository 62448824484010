import * as companyUtils from './companyUtils';
import User from 'constants/user';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { CountryCode } from 'constants/enums/countryCode';
import { UserCompanyRelationshipStatus } from 'store/shared/api/graph/interfaces/types';
import { currentBiddingAsSelections } from 'store/user/userActions';

/**
 * Checks if a user is a Group Manager (has more than 1 company relationship with a status of ENABLED or PENDING)
 *
 * @param {object} user
 * @returns {boolean}
 */
export const isGroupManagerRole = (user) =>
  user?.companyRelationships?.list?.filter?.((companyRelationship) =>
    [UserCompanyRelationshipStatus.PENDING, UserCompanyRelationshipStatus.ENABLED].includes(companyRelationship.status)
  )?.length > 1;

/**
 * Returns a list of enabled company relationships
 *
 * @param user
 * @returns {[]}
 */
export const getEnabledCompanyRelationships = (user: Partial<User>) =>
  user?.companyRelationships?.list
    ?.filter(Boolean)
    ?.filter?.((companyRelationship) => [UserCompanyRelationshipStatus.ENABLED].includes(companyRelationship?.status));

/**
 * Returns an enabled company relationship by CompanyId
 *
 * @param user
 * @param companyId
 * @returns {{}}
 */
export const getEnabledCompanyRelationship = (user, companyId) =>
  getEnabledCompanyRelationships(user)?.filter(
    (companyRelationship) => companyRelationship?.company?.id === companyId
  )?.[0];

/**
 * Returns a lit of enabled company ids
 *
 * @param user
 * @returns {[string]}
 */
export const getEnabledCompanyIds = (user) =>
  getEnabledCompanyRelationships(user)?.map((companyRelationship) => companyRelationship?.company?.id);

/**
 * Returns a list of enabled companies by CompanyType
 *
 * @param user
 * @param companyType
 * @returns {[]}
 */
export const getEnabledCompaniesByType = (user, companyType) =>
  getEnabledCompanyRelationships(user)?.filter(
    (companyRelationship) => companyRelationship?.company?.type === companyType
  );

/**
 * Returns a list of auctions the user has ownership of
 *
 * @param user
 * @returns {[]}
 */
export const getOwnedAuctions = (user: User) =>
  getEnabledCompanyRelationships(user)?.flatMap((companyRelationship) =>
    companyUtils.getOwnedAuctions(companyRelationship?.company)
  );

/**
 * Returns a list of auction ids the user has ownership of
 *
 * @param user
 * @returns {[string]}
 */
export const getOwnedAuctionIds = (user) =>
  getEnabledCompanyRelationships(user)?.flatMap((companyRelationship) =>
    companyUtils.getOwnedAuctionIds(companyRelationship?.company)
  );

/**
 * Returns a list of the ids of auctions the user has on any ENABLED relationships
 *
 * @param user
 * @return {[string]}
 */
export const getAccessibleActionIds = (user) =>
  getEnabledCompanyRelationships(user)?.flatMap((companyRelationship) =>
    companyUtils.getEnabledAuctionIds(companyRelationship?.company)
  );

/**
 * Returns true if the user has any of the passed in permissions.
 */
export const hasAuctionPermission = (user: User, auctionId: string, permissions: string[] = []) =>
  Number(
    getEnabledCompanyRelationships(user)?.filter(
      (companyRelationship) =>
        companyUtils.getOwnedAuctionIds(companyRelationship?.company)?.includes(auctionId) &&
        companyRelationship?.roles?.find((role) =>
          permissions.some((permissionId) => role?.permissions?.find((permission) => permission?.id === permissionId))
        )
    )?.length
  ) > 0;

/**
 * Returns true if the user represents one or more companies with the required operating region.
 */
export const hasCompanyWithOperatingRegions = (user: User, countryCode: CountryCode) =>
  !!getEnabledCompanyRelationships(user)?.find((companyRelationship) =>
    companyRelationship?.company?.visibleRegions?.find((visibleRegion) => visibleRegion === (countryCode as string))
  );

/**
 * Returns true if the user has any of the passed in permissions.
 *
 * Similar to hasAuctionPermission, but without the strict auction check for items like inventory items in the parked section.
 * When the auction id is available, hasAuctionPermission should be used.
 */
export const hasPermission = (user: User, permissions: string[] = []) =>
  Number(
    getEnabledCompanyRelationships(user)?.filter((companyRelationship) =>
      companyRelationship?.roles?.find((role) =>
        permissions.some((permissionId) => role?.permissions?.find((permission) => permission?.id === permissionId))
      )
    )?.length
  ) > 0;

/**
 * Returns true if a user has a company permission
 *
 * @param user
 * @param companyId
 * @param permissions
 * @returns {boolean}
 */
export const hasCompanyPermission = (user: User, companyId: string, permissions: string[] = []) =>
  Number(
    getEnabledCompanyRelationships(user)?.filter(
      (companyRelationship) =>
        companyRelationship?.company?.id === companyId &&
        companyRelationship?.roles?.find((role) =>
          permissions.every((permissionId) => role?.permissions?.find((permission) => permission?.id === permissionId))
        )
    )?.length
  ) > 0;

/**
 * Returns true if at least one company relationship role has the specified system permissionId
 *
 * @param user
 * @param permissions
 * @returns {boolean}
 */
export const hasSystemPermission = (user, permissions: string[] = []) =>
  permissions?.length > 0 &&
  user?.companyRelationships?.list?.filter((companyRelationship) =>
    permissions.every((permissionId) =>
      companyRelationship?.roles?.find((role) =>
        role?.permissions?.find((permission) => permission?.id === permissionId)
      )
    )
  )?.length > 0;

/**
 * Returns true if the user represents an auction
 *
 * @param user
 * @param auctionId
 * @returns {boolean}
 */
export const representsAuction = (user, auctionId) =>
  getOwnedAuctions(user)?.find((auction) => auction?.id === auctionId) !== undefined;

/**
 * Returns true if the user represents the provided company id
 *
 * Example: const isBuyer = representsCompany(user, auctionItem?.buyer?.company?.id);
 * Example: const isSeller = representsCompany(user, auctionItem?.inventoryItem?.company?.id);
 */
export const representsCompany = (user: User, companyId: string | undefined): boolean =>
  !!companyId && !!getEnabledCompanyIds(user)?.includes(companyId);

/**
 * Get the previous company/user bidding selection of an action (if it exists) from local storage).
 */
export const getBiddingSelectionByAuctionId = (auctionId: string | undefined): BidSelection | undefined => {
  if (auctionId) {
    const bidSelection = JSON.parse(currentBiddingAsSelections?.get() || 'null')?.[auctionId];
    // Check if the bid selection is valid (has company id)
    if (bidSelection?.company?.id) {
      return bidSelection;
    }
  }
  return undefined;
};

/**
 * Returns true if the user represents the provided auction.
 * Returns true if no auction id provided and user represents any auction.
 * Otherwise, false.
 */
export const isAuctionStaff = (user: User, auctionId?: string) => {
  if (auctionId) {
    return representsAuction(user, auctionId);
  }
  return !!getOwnedAuctions(user)?.length;
};

/**
 * Gets a mileage unit based on the user's primary location
 *
 * E.g.:
 *  - user.getMileageUnit()      // returns 'KM'
 *  - user.getMileageUnit(false) // returns 'Kilometers'
 */
export const getMileageUnit = (user: Partial<User> | undefined, useShortCode = true) => {
  const unitDisplayType = useShortCode ? 0 : 1;
  const units = Object.freeze({
    KM: ['KM', 'Kilometers'],
    MI: ['MI', 'Miles'],
  });
  return ((user?.distanceUnit && units[user?.distanceUnit]) || units.KM)[unitDisplayType];
};
