import { ReactNode } from 'react';
import classnames from 'classnames';

import Link, { LinkComponentProps } from 'components/ui/shared/link';
import { Chevron } from 'components/ui/shared/chevrons';

import style from './linkWithArrow.scss';

interface Props extends LinkComponentProps {
  /** The rendered child elements. */
  children: ReactNode;

  /** CSS styling to overwrite default link style. */
  className?: string;
}

const LinkWithArrow = ({ children, className, ...props }: Props) => (
  <Link className={classnames(className)} {...props}>
    <div className={style.innerContainer}>
      {children}
      <Chevron className={style.chevron} />
    </div>
  </Link>
);

export default LinkWithArrow;
