import classnames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

import { Scrollable } from 'components/ui/shared/scrollable';

import style from './sectionContent.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The content of the component. */
  children: ReactNode;
  /** True would make container scrollable */
  isScrollable?: boolean;
  /** Overwrite scrollable container classname, only valid if `isScrollable` is True */
  scrollableClassName?: string;
}

const SectionContent = ({ className, children, isScrollable = false, scrollableClassName, ...props }: Props) => {
  if (isScrollable) {
    return (
      <Scrollable {...props} className={className} scrollableClassName={scrollableClassName}>
        {children}
      </Scrollable>
    );
  }

  return (
    <div {...props} className={classnames(style.container, className)}>
      {children}
    </div>
  );
};

export default SectionContent;
