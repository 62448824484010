import { ApolloProvider } from '@apollo/client';
import { History } from 'history';

import EBlockProvider from 'contexts/eblockContext';
import ErrorBoundary from 'components/core/errors/errorBoundary';
import Root from 'components/core/root';
import { Locale } from 'utils/intlUtils';
import { client } from 'store/shared/apollo/apolloClient';

interface Props {
  syncedHistory: History;
  initialLocale: Locale;
}

const App = ({ syncedHistory, initialLocale }: Props) => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <EBlockProvider>
          <Root history={syncedHistory} initialLocale={initialLocale} />
        </EBlockProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
