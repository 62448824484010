import { createAction } from 'redux-actions';

import { getAuctionsList } from './auctionsApi';
import { parseQueryParams } from 'utils/apiUtils';

export const auctionsListIsLoading = createAction('ADMIN_AUCTIONS_LOADING');
export const auctionsListLoaded = createAction('ADMIN_AUCTIONS_LOADED');
export const auctionsListCleared = createAction('ADMIN_AUCTIONS_CLEARED');

export const processGetAuctionsList = (options, dispatch) => {
  dispatch(auctionsListIsLoading());

  return getAuctionsList(parseQueryParams(options)).then((response) =>
    dispatch(auctionsListLoaded(response?.data.data?.auctionConnection))
  );
};
