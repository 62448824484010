import classnames from 'classnames';

import chevronGlyph from 'glyphs/chevron.svg';

import Sprite from './sprite';

import style from './chevrons.scss';

interface ChevronProps {
  /** CSS stying to overwrite the default Sprite style. */
  className?: string;
}

export const Chevron = ({ className }: ChevronProps) => {
  return <Sprite className={classnames(style.chevron, className)} glyph={chevronGlyph} />;
};

interface ChevronFilledProps {
  /** CSS stying to overwrite the default style. */
  className?: string;
}

export const ChevronFilled = ({ className }: ChevronFilledProps) => (
  <span className={classnames(style.chevronFilled, className)} />
);
