import moment from 'moment';
import classnames from 'classnames';

import InteractiveText from 'components/ui/shared/interactiveText';
import { IfBidTimeline, User } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { formatUserName } from 'utils/stringUtils';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './ifBids.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    ifBidTimeline: IfBidTimeline;
    status: string;
    user: User;
  };
}

const InventoryItemDetailsIfBids = ({ inventoryDetailsData }: Props) => {
  const { ifBidTimeline, status, user } = inventoryDetailsData;
  const isSold = status === 'SOLD';

  return (
    <div className={style.summarySection}>
      <ul>
        {ifBidTimeline?.list?.filter(Boolean).map((ifBid, index) => (
          <li key={`if-bid-item-${ifBid.id}-${Math.random() * 99999}`} className={style.listItem}>
            <div className={style.details}>
              <div>
                <span className={style.accentuate}>
                  {getEnabledCompanyIds(user)?.includes(ifBid?.company?.id)
                    ? t('you')
                    : formatUserName(ifBid?.createdBy)}{' '}
                  {t(`if_bid_type_${ifBid?.type?.toLowerCase()}`)}
                </span>
                {` - ${moment(ifBid.created).format('h:mm A')}`}
              </div>
              <div className={classnames(style.price, isSold && index === 0 && style.green)}>
                {ifBid.amount?.formattedAmountRounded}
              </div>
            </div>
            {isAuctionStaff(user) && (
              <>
                <div className={style.details}>
                  <span className={style.accentuate}>{ifBid?.company?.name}</span>
                </div>
                <div className={style.details}>
                  <span className={style.accentuate}>
                    {ifBid?.createdBy?.cellPhone} • <InteractiveText>{ifBid?.createdBy.email}</InteractiveText>
                  </span>
                </div>
              </>
            )}
            {ifBid.comment && <div className={style.content}>{ifBid.comment}</div>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryItemDetailsIfBids;
