import { handleActions } from 'redux-actions';

import { System } from './systemModels';
import {
  networkDown,
  networkUp,
  setAccountVerificationFormActive,
  setInteractionTimeout,
  setIsError429Active,
  setIsPubSubActive,
  setTimeOffset,
} from './systemActions';

export const systemReducer = handleActions(
  {
    [networkUp().type]: (state) => state.set('isNetworkUp', true),

    [networkDown().type]: (state) => state.set('isNetworkUp', false),

    [setIsError429Active().type]: (state, action) => state.set('isError429Active', action.payload),

    [setTimeOffset().type]: (state, action) => state.set('timeOffset', action.payload),

    [setInteractionTimeout().type]: (state) => state.set('isTimedOut', true),

    [setIsPubSubActive().type]: (state, action) => state.set('isPubSubActive', action.payload),

    [setAccountVerificationFormActive().type]: (state, action) =>
      state.set('isAccountVerificationFormOpen', action.payload),
  },
  new System()
);
