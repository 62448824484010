import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  MutationauctionItemCreateArgs,
  QueryauctionArgs,
  QueryauctionConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { createAuctionItem, getAuctionSubmissionList, getUpcomingTimeSlots } from './auctionSubmissionApi';

export const auctionItemLoaded = createAction('AUCTION_SUBMISSION_AUCTION_ITEM_LOADED');
export const auctionSubmissionListLoaded = createAction('AUCTION_SUBMISSION_LIST_LOADED');
export const auctionTimeSlotsLoaded = createAction('AUCTION_SUBMISSION_AUCTION_TIME_SLOTS_LOADED');
export const clearAuctionSubmission = createAction('AUCTION_SUBMISSION_CLEAR');
export const isLoading = createAction('AUCTION_SUBMISSION_SET_IS_LOADING');

export const processGetAuctionSubmissionList = (dispatch: AppDispatch, options: QueryauctionConnectionArgs) => {
  dispatch(isLoading());

  return getAuctionSubmissionList(options).then((response) =>
    dispatch(auctionSubmissionListLoaded(response?.data?.data?.auctionConnection.edges))
  );
};

export const processGetUpcomingTimeSlots = (dispatch: AppDispatch, options: QueryauctionArgs) => {
  dispatch(isLoading());

  return getUpcomingTimeSlots(options).then((response) =>
    dispatch(auctionTimeSlotsLoaded(response?.data?.data?.auction))
  );
};

export const processCreateAuctionItem = async (dispatch: AppDispatch, options: MutationauctionItemCreateArgs) =>
  createAuctionItem(options).then((response) => dispatch(auctionItemLoaded(response?.data?.data?.auctionItemCreate)));
