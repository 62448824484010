import { connect, ConnectedProps } from 'react-redux';

import transferGlyph from 'glyphs/operationIcons/transfer.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemTransferRunningArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { processTransferRunningItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to transfer item. */
  transferItem: (options: Partial<MutationauctionItemTransferRunningArgs>) =>
    processTransferRunningItem(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item. */
  auctionItem: AuctionItem;
  /** Callback function to trigger on done. */
  onDone?: (options: boolean) => void;
}

interface State {
  /** Error messages. */
  errorMessages?: ErrorMessages;

  /** Whether the dialog is open or not. */
  isOpen: boolean;

  /** Whether is progressing or not. */
  progress: boolean;
}

class TransferRunningItem extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessages: undefined,
      isOpen: false,
      progress: false,
    };
  }

  onSubmit = (shouldSubmit: boolean = false, options: Partial<MutationauctionItemTransferRunningArgs> = {}) => {
    const { auctionItem, onDone, transferItem } = this.props;

    if (shouldSubmit) {
      this.setState({ progress: true });
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSFER_CONSIGNER_CONFIRM_CLICK, auctionItem);

      transferItem?.({ auctionItemId: auctionItem.id, ...options })
        ?.then(() => {
          onDone?.(true);
          this.setState({ errorMessages: null, isOpen: false, progress: false });
        })
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ progress: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    const { auctionItem } = this.props;
    const { isOpen } = this.state;
    return (
      <>
        <OperationButton
          key="button"
          dataTestId="transferConsigner"
          glyph={transferGlyph}
          onClick={() => {
            this.setState({ isOpen: true });
            trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSFER_CONSIGNER_CLICK, auctionItem);
          }}
          theme="green"
        >
          {t('transfer_consigner')}
        </OperationButton>
        {isOpen && <Dialog key="dialog" onSubmit={this.onSubmit} {...this.state} />}
      </>
    );
  }
}

export default connector(TransferRunningItem);
