import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import AuctionItem from 'constants/auctionItem';
import AuthService from 'store/shared/services/authService';
import Body from 'components/ui/lists/listItem/body';
import Header from 'components/ui/lists/listItem/header';
import User from 'constants/user';
import { ListItem as ListItemLayout } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { formatDate } from 'utils/dateUtils';
import { getMileage } from 'utils/numberUtils';
import { getMileageUnit } from 'utils/userUtils';
import { getVehicleScoreByInventoryItem } from 'utils/inventoryItemUtils';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './listItem.scss';

interface Props {
  /** The comparable auction item. */
  auctionItem: AuctionItem;

  /** Callback function to handle on list item click. */
  onClick?: (auctionItemId: string) => void;

  /** The current user. */
  user?: User;
}

const ListItem = ({ auctionItem, onClick, user }: Props) => {
  const [thumbnailError, setThumbnailError] = useState<boolean>(false);
  const isLink = AuthService?.isStaffUser(auctionItem?.auction?.id);
  const linkUrl = `${Route.BUY_COMPARABLES}?inventoryItemId=${auctionItem?.inventoryItem?.id}&id=${auctionItem?.id}`;

  const header = useMemo(() => {
    const inventoryItem = auctionItem.inventoryItem;
    const distanceAway = inventoryItem?.location?.distanceAway;
    const headerLabels: string[] = [];
    if (!auctionItem.isMyItem && distanceAway) {
      const mileageUnit = getMileageUnit(user)?.toLowerCase();
      const distanceAwayFormatted = getMileage(distanceAway, getMileageUnit(user)?.toLowerCase());
      headerLabels.push(t('x_distance_away', [`${distanceAwayFormatted} ${mileageUnit}`]));
    }
    return <Header auxLabel="" label={headerLabels} />;
  }, [auctionItem.inventoryItem, auctionItem.isMyItem, user]);

  const body = useMemo(() => {
    const { inventoryItem, isAssured, listPrice, reserveMet } = auctionItem;
    const { captureType, make, mileage, model, photos, trim, vin, year } = inventoryItem;

    const amount = listPrice?.formattedAmountRounded;
    const subtitle = `${model} ${trim || ''}`;
    const thumbnail = photos?.[0]?.thumb;
    const title = `${year} ${make}`;

    const badges = {
      archived: inventoryItem?.archived,
      hasScore: true,
      isAssured,
      isReserveMet: reserveMet,
      isVerified: isVerified(captureType),
    };

    const vehicleScore = getVehicleScoreByInventoryItem(inventoryItem);

    return (
      <Body
        amount={amount}
        auctionId={auctionItem.auction.id}
        auctionItem={auctionItem}
        badges={badges}
        highlightTitle={false}
        mileage={mileage?.formattedAmount || undefined}
        onThumbnailError={() => {
          setThumbnailError(true);
        }}
        subtitle={subtitle}
        theme="green"
        thumbnail={thumbnailError ? undefined : thumbnail}
        title={title}
        vehicleScore={vehicleScore}
        vin={vin || undefined}
      />
    );
  }, [auctionItem, thumbnailError]);

  const footer = useMemo(() => {
    const createdDate = formatDate(auctionItem.transactionDetails?.created);
    return (
      <footer className={style.footer}>
        <div>{t('sold_x', [createdDate])}</div>
      </footer>
    );
  }, [auctionItem.transactionDetails?.created]);

  /**
   * Handles on item click event.
   */
  const onItemClick = useCallback(() => {
    onClick?.(auctionItem.id);
  }, [auctionItem.id, onClick]);

  return (
    <ListItemLayout
      className={classNames(style.item, onClick && !isLink && style.isButton)}
      isLink={isLink}
      onClick={onItemClick}
      tagName="div"
      to={linkUrl}
    >
      {header}
      {body}
      {footer}
    </ListItemLayout>
  );
};

export default ListItem;
