import classnames from 'classnames';
import { useCallback, useState } from 'react';

import DecrementGlyph from 'glyphs/decrement.svg';
import IncrementGlyph from 'glyphs/increment.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { CurrencyCode } from 'store/shared/api/graph/interfaces/types';
import { formatCurrency } from 'utils/stringUtils';

import style from './incrementButtons.scss';

export interface IncrementButtonsProps {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The currency code for the current auction */
  currencyCode?: CurrencyCode;
  /** True if increment buttons should be disabled */
  disabled?: boolean;
  /** The increment or decrement bid amount. */
  furtherBidIncrement: number;
  /** Callback function with the incremented/decremented value */
  onChange: (nextBidIncrement: number) => void;
  /** The controlled value */
  value: number;
}

const IncrementButtons = ({
  className,
  currencyCode = CurrencyCode.CAD,
  disabled,
  furtherBidIncrement,
  onChange,
  value,
}: IncrementButtonsProps) => {
  const [adjustment, setAdjustment] = useState<number>(0);

  /**
   * onIncrementChange
   */
  const onIncrementChange = useCallback(
    (nextIncrement: number) => () => {
      const nextAdjustment = adjustment + nextIncrement;

      if (nextAdjustment >= 0) {
        setAdjustment(nextAdjustment);
        onChange(value + nextIncrement);
      }
    },
    [adjustment, onChange, value]
  );

  return (
    <div className={classnames(style.container, className)}>
      {!disabled && (
        <Button
          className={style.button}
          dataTestId="increment-button-decrease"
          disabled={adjustment === 0}
          onClick={onIncrementChange(-furtherBidIncrement)}
          theme="none"
        >
          <Sprite className={style.decrement} glyph={DecrementGlyph} />
        </Button>
      )}

      <div className={style.value} data-testid="increment-button-value">
        {formatCurrency(value, currencyCode)}
      </div>

      {!disabled && (
        <Button
          className={style.button}
          dataTestId="increment-button-increase"
          onClick={onIncrementChange(furtherBidIncrement)}
          theme="none"
        >
          <Sprite className={style.increment} glyph={IncrementGlyph} />
        </Button>
      )}
    </div>
  );
};

export default IncrementButtons;
