import moment from 'moment';
import { Link } from 'react-router';
import { useCallback, useMemo } from 'react';

import defaultVehicleGlyph from 'glyphs/default-vehicle.svg';
import nextArrowBlack from 'glyphs/next-arrow-black.svg';

import Button from 'components/ui/shared/button';
import DialogContent from 'layouts/inventoryItemLayouts/addModify/dialogContentLayouts';
import Sprite from 'components/ui/shared/sprite';
import { AddModifyVehicleProps, InventoryItemProps } from 'store/inventoryItem/addModify/addModifyModels';
import { DateFormat } from 'constants/enums/dateAndTime';
import { ErrorMessages } from 'constants/errors';
import { Spinner } from 'components/ui/loading/loading';
import { isVerified } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './vinDuplicatesOverlay.scss';

export const sectionInfo = {
  timedAuction: { label: t('timed_auction'), path: '/buy/timed-auction' },
  runList: { label: t('auction_item_status_upcoming'), path: '/buy/run-list' },
  parked: { label: t('parked'), path: '/sell/parked' },
  inIfBid: { label: t('auction_item_status_in_if_bid'), path: '/pending/in-if-bid' },
  pendingDelivery: { label: t('auction_item_status_pending_delivery'), path: '/pending/pending-delivery' },
  sold: { label: t('auction_item_status_sold'), path: '/finished/sold' },
  purchased: { label: t('purchased'), path: '/finished/purchased' },
  noSale: { label: t('auction_item_status_no_sale'), path: '/finished/no-sale' },
  cancelled: { label: t('auction_item_status_sale_cancelled'), path: '/finished/cancelled' },
};

interface Props {
  /** Callback function to clear errors. */
  clearError: () => void;
  /** Duplicated inventory items with the same vin. */
  duplicates?: InventoryItemProps['duplicates'];
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Callback function to submit create inventory item. */
  handleSubmitCreateInventoryItem: (obj?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Whether is loading duplicates or not. */
  isLoading: boolean;
  /** Callback function to close the dialog. */
  onRequestClose: () => void;
  /** Vehicle information. */
  vehicle: AddModifyVehicleProps;
}

const VinDuplicatesOverlay = ({
  clearError,
  duplicates = [],
  errorMessages,
  handleSubmitCreateInventoryItem,
  isLoading,
  onRequestClose,
  vehicle,
}: Props) => {
  const hasError = !!errorMessages.length;

  /**
   * Submit create inventory item on continue
   */
  const handleContinueButton = useCallback(() => {
    const obj = { ...vehicle, validateVin: true, decodeVin: true, switchPage: true };
    handleSubmitCreateInventoryItem(obj, false);
  }, [handleSubmitCreateInventoryItem, vehicle]);

  /**
   * Create duplicates list
   */
  const createDuplicatesList = useMemo(() => {
    return (
      <div className={style.duplicatesContainer} data-testid="duplicateVehicles">
        {duplicates.map((item, index) => {
          const {
            data: { captureType, created, id, photos },
            section,
          } = item;
          const dateCaptured = created ? moment(created).format(DateFormat.MONTH_DAY_YEAR_SHORT_FORMAT) : '';

          return (
            <Link
              key={index}
              className={style.auctionItem}
              data-testid="duplicateVehicle"
              onClick={onRequestClose}
              to={`${sectionInfo[section].path}?id=${id}`}
            >
              {photos && photos.length ? (
                <img alt={sectionInfo[section].label} src={photos[0].thumb} />
              ) : (
                <Sprite className={style.defaultVehicle} glyph={defaultVehicleGlyph} />
              )}
              <div>
                <span>{sectionInfo[section].label}</span>
                <span>{`${
                  isVerified(captureType) ? t('verified_capture') : t('non_verified_capture')
                } ${dateCaptured}`}</span>
              </div>
              <Sprite glyph={nextArrowBlack} />
            </Link>
          );
        })}
      </div>
    );
  }, [duplicates, onRequestClose]);

  return (
    <DialogContent className={style.modalCustom} hasScroll>
      {hasError && (
        <ul className={style.errorMessageContainer}>
          {errorMessages.map((errorItem) => (
            <li key={errorItem.name}>{errorItem.message}</li>
          ))}
          <Button className={style.closeErrors} onClick={clearError} theme="none">
            &times;
          </Button>
        </ul>
      )}
      <h4 className={(errorMessages.length && style.marginCustom) || undefined}>{t('alert')}</h4>
      <p className={style.subHeader}>{t('duplicate_vin_inventory_item_message')}</p>
      {createDuplicatesList}
      <Button
        className={style.button}
        dataTestId="capture-button"
        disabled={isLoading}
        onClick={handleContinueButton}
        theme="blue"
      >
        {isLoading ? <Spinner spinnerStyleClassName={style.spinnerStyle} /> : t('continue_with_capture')}
      </Button>
    </DialogContent>
  );
};

export default VinDuplicatesOverlay;
