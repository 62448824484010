import { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react';

type Component = FC<PropsWithChildren>;

/**
 * Compose components together
 * @example:
 * ```
 *   const ComposedComponent = composeComponents([C1, C2, C3]);
 *   render(<ComposedComponent><div>Example</div></ComposedComponent>);
 *  // Will render:
 *   <C1>
 *     <C2>
 *       <C3>
 *         <div>Example</div>
 *       </C3>
 *     </C2>
 *   </C1>
 * ```
 *
 * @param components
 */
export const composeComponents = (components: Component[]): Component => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: ComponentProps<Component>): ReactElement => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

/**
 * Replace newLine (\n) with <br> html element.
 *
 * @example splitOnNewLine('The fox \n ran fast, \n really fast'); // [The fox, [<br>, ran fast,], [<br>, really fast]]
 * @param {string} string the original string
 * @returns {(string | (JSX.Element | string)[])[]} the original string if no `\n` exists, or formatted JSX.
 */
export const splitOnNewLine = (string: string): (string | (JSX.Element | string)[])[] => {
  return string.split('\\n').map((item, index) => (index === 0 ? item : [<br key={index} />, item]));
};
