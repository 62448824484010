import {
  AccountingSystem,
  Company,
  CompanyAuctionRelationship,
  CompanyAuctionRelationshipStatus,
  CompanyStatus,
  CompanySubType,
  CompanyType,
} from 'store/shared/api/graph/interfaces/types';
import {
  companyStatusTranslationMap,
  companySubTypeTranslationMap,
  companyTypeTranslationMap,
} from 'constants/enums/company';
import { t } from 'utils/intlUtils';

/**
 * Returns a list of enabled auction relationships
 *
 * @param company
 * @returns {[]}
 */
export const getEnabledAuctionRelationships = (company: Company | undefined) =>
  company?.auctionRelationships?.list
    ?.filter(Boolean)
    ?.filter((auctionRelationship) => [CompanyAuctionRelationshipStatus.ENABLED].includes(auctionRelationship?.status));

/**
 * Returns a list of enabled auction ids
 *
 * @param company
 * @returns {[string]}
 */
export const getEnabledAuctionIds = (company) =>
  getEnabledAuctionRelationships(company)?.map((auctionRelationship) => auctionRelationship?.auction?.id);

/**
 * Returns a list of auctions the user has ownership of
 *
 * @param company
 * @returns {[]}
 */
export const getOwnedAuctions = (company) =>
  getEnabledAuctionRelationships(company)
    ?.filter((auctionRelationship) => auctionRelationship?.permissions.find((permission) => permission?.id === 'OWN'))
    ?.map((auctionRelationship) => auctionRelationship?.auction);

/**
 * Returns a boolean indicating whether or not this company name can be displayed in the auction context.  This is
 * useful if you need to understand if seller should be hidden REGARDLESS of user permissions.  i.e. for public displays
 * such as the marquee.
 *
 * @param company
 * @param auctionId
 */
export const isSellerNameHidden = (company: Company, auctionId: string): boolean => {
  const auctionRelationships = getEnabledAuctionRelationships(company)?.find(
    (auctionRelationship) => auctionRelationship?.auction.id === auctionId
  );
  return auctionRelationships?.hideSellerNameEnabled ?? false;
};

/**
 * Returns a list of auction ids the user has ownership of
 *
 * @param company
 * @returns {[string]}
 */
export const getOwnedAuctionIds = (company) =>
  getEnabledAuctionRelationships(company)
    ?.filter((auctionRelationship) => auctionRelationship?.permissions.find((permission) => permission?.id === 'OWN'))
    ?.map((auctionRelationship) => auctionRelationship?.auction?.id);

/**
 * Returns a unique list of the company's accounting systems.
 *
 * @param companyAuctionRelationships
 */
export const getUniqueAccountingSystems = (
  companyAuctionRelationships: CompanyAuctionRelationship[] | undefined
): AccountingSystem[] => {
  const uniqueAccountingSystemIds = new Set<string>();
  return (
    companyAuctionRelationships
      ?.map((relationship) => relationship?.auction.accountingSystem)
      .filter(Boolean)
      .filter(
        (accountingSystem) =>
          !uniqueAccountingSystemIds.has(accountingSystem.id) && uniqueAccountingSystemIds.add(accountingSystem.id)
      ) || []
  );
};

/**
 * Returns translated company status
 * @param status
 */
export const formatCompanyStatus = (status: CompanyStatus) => {
  return t(companyStatusTranslationMap[status]);
};

/**
 * Returns translated company subtype
 * @param subType
 */
export const formatCompanySubType = (subType: CompanySubType) => {
  return t(companySubTypeTranslationMap[subType]);
};

/**
 * Returns translated company type
 * @param type
 */
export const formatCompanyType = (type: CompanyType) => {
  return t(companyTypeTranslationMap[type]);
};
