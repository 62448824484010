import { uniq } from 'lodash-es';
import { useMemo, useState } from 'react';

import trashGlyph from 'glyphs/trash.svg';

import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import Sprite from 'components/ui/shared/sprite';
import { NotificationMakeModelFilter } from 'store/shared/api/graph/interfaces/types';
import { Notifications } from 'components/sections/notifications/userNotificationsSlideOut';
import { UserAction } from 'logging/analytics/events/userActions';
import { getMileage } from 'utils/numberUtils';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

import style from 'components/sections/notifications/userNotificationsSlideOut.scss';

/**
 * Gets formatted mileage string
 */
export const getMileageString = (
  mileage: { min: number | null | undefined; max: number | null | undefined },
  mileageUnit: string
): string => {
  if (mileage.min && mileage.max) {
    return joinStrings([`${mileage.min} ${mileageUnit}`, `${mileage.max} ${mileageUnit}`], ' - ');
  }
  if (mileage.min) {
    return `more than ${mileage.min} ${mileageUnit}`;
  }
  if (mileage.max) {
    return `up to ${mileage.max} ${mileageUnit}`;
  }
  return '';
};

interface GroupedMakeModelFilters {
  filters: {
    [key: string]: NotificationMakeModelFilter[];
  };
  keys: string[];
}

interface Props {
  makeModelFilters: Notifications['makeModelFilters'];
  mileageUnit: string;
  notifications: Notifications | null;
  saveNotifications: (notifications: Notifications, shouldUpdateFromResponse: boolean) => Promise<void>;
  setIsClickOutsideDisabled: (isDisabled: boolean) => void;
}

const MakeModelFilterList = ({
  makeModelFilters,
  mileageUnit,
  notifications,
  saveNotifications,
  setIsClickOutsideDisabled,
}: Props) => {
  const [removalIndex, setRemovalIndex] = useState<number | undefined>();

  /**
   * Group `makeModelFilters` by matching makes, year and mileage selections
   */
  const groupedMakeModelFilters = useMemo(
    () =>
      (makeModelFilters ?? []).reduce(
        (filterMap, filter) => {
          const { makeId, maxMileage, minMileage, yearFrom, yearTo } = filter ?? ({} as NotificationMakeModelFilter);
          const groupName = `${yearFrom}-${yearTo}-${makeId}-${minMileage}-${maxMileage}`;

          // Convert mileage units if US user
          const formattedFilter = {
            ...filter,
            maxMileage: getMileage(maxMileage, mileageUnit) || null,
            minMileage: getMileage(minMileage, mileageUnit) || null,
          };

          return {
            ...filterMap,
            filters: {
              ...filterMap.filters,
              [groupName]: [...(filterMap.filters?.[groupName] ?? []), formattedFilter],
            },
            keys: uniq([...filterMap.keys, groupName]) ?? [],
          };
        },

        { filters: {}, keys: [] } as GroupedMakeModelFilters
      ),
    [makeModelFilters, mileageUnit]
  );

  if (!makeModelFilters?.length) {
    return <div className={style.makeModelFilterPlaceholder}>{t('vehicle_listing_details_msg')}</div>;
  }

  return (
    <div className={style.makeModelFilters}>
      {groupedMakeModelFilters.keys.map((groupKey: string, index: number) => {
        const filters = groupedMakeModelFilters?.filters?.[groupKey] as NotificationMakeModelFilter[];
        const make = filters[0].make;
        const makeId = filters[0].makeId;
        const maxMileage = filters[0].maxMileage;
        const minMileage = filters[0].minMileage;
        const models = joinStrings(uniq([filters.map((filter) => filter.model)]));
        const yearFrom = filters[0].yearFrom;
        const yearTo = filters[0].yearTo;

        return (
          <div key={`${index}-${makeId}`} className={style.makeModelFilter} data-testid={`make-model-filter-${index}`}>
            {(yearFrom || yearTo) && (
              <div className={style.makeModelFilterYear}>
                <div>{joinStrings([yearFrom, yearTo], ' - ')}</div>
              </div>
            )}
            <div className={style.makeModelFilterMake}>
              <div>{[joinStrings([make, models], ' - ')]}</div>
            </div>
            {(minMileage || maxMileage) && (
              <div>
                <div>{getMileageString({ min: minMileage, max: maxMileage }, mileageUnit)}</div>
              </div>
            )}
            <Button
              className={style.removeButton}
              dataTestId="delete_button"
              onClick={() => {
                trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_FILTER_DELETE_CLICK);
                setIsClickOutsideDisabled(true);
                setRemovalIndex(index);
              }}
              theme="none"
              title={t('delete')}
            >
              <Sprite glyph={trashGlyph} />
            </Button>
          </div>
        );
      })}

      <ConfirmDialog
        bringToFront
        isOpen={removalIndex !== undefined}
        onConfirm={(shouldSubmit: boolean) => {
          if (shouldSubmit && removalIndex !== undefined) {
            // Remove selected notifications and save
            const makeModelFiltersNext = groupedMakeModelFilters.keys
              .filter((key) => key !== groupedMakeModelFilters.keys[removalIndex])
              .reduce((filters, filterGroup) => {
                return [...filters, ...(groupedMakeModelFilters?.filters?.[filterGroup] ?? [])];
              }, []);

            trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_FILTER_DELETE_CONFIRM_CLICK);
            const notificationsNext = { ...notifications, makeModelFilters: makeModelFiltersNext } as Notifications;
            saveNotifications(notificationsNext, true);
          } else {
            trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_FILTER_DELETE_CANCEL_CLICK);
          }

          setIsClickOutsideDisabled(false);
          setRemovalIndex(undefined);
        }}
        theme="red"
        title={t('remove_filter')}
      >
        <p>{t('notifications_confirm_remove_filter')}</p>
      </ConfirmDialog>
    </div>
  );
};

export default MakeModelFilterList;
