import { List } from 'immutable';
import { handleActions } from 'redux-actions';

import { FacetGroup, PageInfo } from 'store/shared/models/graph/connections';
import { InitialState } from './marketGuideModels';
import {
  marketGuideListCleared,
  marketGuideListIsLoading,
  marketGuideListLoaded,
} from 'store/marketGuide/marketGuideActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const marketGuideReducer = handleActions(
  {
    [marketGuideListCleared().type]: () => new InitialState(),

    [marketGuideListIsLoading().type]: (state) => state.set('isLoading', true),

    [marketGuideListLoaded().type]: (state, action) => {
      const auctionItems = parseQueryConnectionResponse(action.payload?.marketGuideConnection);
      const facetGroups = action.payload?.marketGuideConnection?.facetGroups?.map(
        (facetGroup) => new FacetGroup(facetGroup)
      );

      return state
        .setLoaded()
        ?.set('resultList', List(auctionItems ?? []))
        ?.set('facetGroups', List(facetGroups ?? []))
        ?.set('pageInfo', new PageInfo(action.payload?.marketGuideConnection?.pageInfo))
        ?.set('metadata', action.payload?.marketGuideConnection?.metadata);
    },
  },
  new InitialState()
);
