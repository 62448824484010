import { useCallback } from 'react';

import InputText from 'forms/shared/inputText';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import NotificationText from 'components/ui/shared/notifications/notificationText';
import RadioButtons from 'forms/shared/radioButtons';
import { AuctionItemFormat, AuctionLaneType, ReserveType } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './reservePrice.scss';

interface Props {
  /** The format of the auction item. */
  auctionItemFormat: AuctionItemFormat | null;
  /** The type of the auction lane. */
  auctionLaneType: AuctionLaneType | null;
  /** Callback function to clears error messages. */
  clearErrorMessages: () => void;
  /** Callback function to handle price change. */
  onPriceChange: (price: number | undefined) => void;
  /** Callback function to handle reserve type change. */
  onReserveTypeChange: (reserveType: ReserveType) => void;
  /** The reserve amount. */
  reserveAmount?: number;
  /** The reserve type. */
  reserveType?: ReserveType;
}

const ReservePrice = ({
  auctionItemFormat,
  auctionLaneType,
  clearErrorMessages,
  onPriceChange,
  onReserveTypeChange,
  reserveAmount,
  reserveType,
}: Props) => {
  const isGrounded = auctionItemFormat === AuctionItemFormat.GROUNDED;
  const isAuction = auctionItemFormat === AuctionItemFormat.AUCTION;
  const isPhysicalLane = auctionLaneType === AuctionLaneType.PHYSICAL;

  const isReserveTypeVisible = (isAuction && !isPhysicalLane) || isGrounded;

  const reserveTypeLabels = [
    { title: t('flexible_reserve'), value: ReserveType.FLEXIBLE },
    { title: t('firm_reserve'), value: ReserveType.FIRM },
    { title: t('unreserved'), value: ReserveType.UNRESERVED },
  ];

  const reserveTypeDescriptionTags = ['flexible_reserve_message', 'firm_reserve_message', 'unreserved_message'];

  const onTypeChange = useCallback(
    (e: { target: { value: ReserveType } }) => {
      const { value } = e.target;
      clearErrorMessages();
      onReserveTypeChange(value);
      if (value === ReserveType.UNRESERVED) {
        onPriceChange(undefined);
      }
    },
    [clearErrorMessages, onPriceChange, onReserveTypeChange]
  );

  return (
    <div className={style.container}>
      {isReserveTypeVisible && (
        <RadioButtons
          className={style.radioButtons}
          labels={reserveTypeLabels}
          onChange={onTypeChange}
          selectedOption={reserveType}
          theme="button"
        />
      )}
      <div className={style.inputContainer}>
        <InputText
          dataTestId="currency-input"
          defaultValue={reserveAmount || ''}
          disabled={reserveType === ReserveType.UNRESERVED}
          onChange={onPriceChange}
          onFocus={clearErrorMessages}
          placeholder={t('reserve_price')}
          theme="minimal"
          type="currency"
        />
      </div>
      {isReserveTypeVisible && (
        <NotificationText
          className={style.infoContainer}
          contentClassName={style.infoContent}
          glyphClassName={style.infoIcon}
        >
          {reserveTypeDescriptionTags.map((tag) => (
            <InterpolatedTranslation key={tag} namespace={tag} />
          ))}
        </NotificationText>
      )}
    </div>
  );
};

export default ReservePrice;
