import { ReactNode } from 'react';
import classnames from 'classnames';

import Image from 'components/ui/shared/image';
import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './imageOrSprite.scss';

interface Props {
  /** Alternative text for the image. */
  alt: string;

  /** The components children to be rendered. */
  children?: ReactNode;

  /** CSS styling to overwrite the default image style. */
  className?: string;

  /** CSS styling to overwrite default container style. */
  containerClassName?: string;

  dataTestId?: string;

  /** Fallback glyph rendered with a Sprite */
  glyph?: Glyph;

  /** Url of the image to be rendered.*/
  imageUrl?: string;
}

/**
 * ImageOrSprite shared class that renders either an avatar image or the default profile image
 */
const ImageOrSprite = ({ alt, glyph, imageUrl, className, containerClassName, children, dataTestId }: Props) => {
  const placeholder = (
    <div className={classnames(style.imgContainer, containerClassName)}>
      <Sprite className={className} glyph={glyph} />
      {children}
    </div>
  );

  return imageUrl ? (
    <Image
      alt={alt}
      className={classnames(style.img, className)}
      containerClassName={classnames(style.imgContainer, containerClassName)}
      dataTestId={dataTestId || 'image'}
      disableSpinner
      placeholder={placeholder}
      src={imageUrl}
    >
      {children}
    </Image>
  ) : (
    placeholder
  );
};

export default ImageOrSprite;
