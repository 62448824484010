import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import LiveStreamBadge from 'components/ui/badges/liveStreamBadge';
import MillicastViewerManager, { QualityLevel } from 'io/millicast/millicastViewerManager';
import VideoStream, { VideoStreamProps } from 'components/ui/media/video/videoStream';
import { VideoStreamConfig } from 'store/shared/api/graph/interfaces/types';
import { useLiveLanesVideoStream } from 'contexts/liveLanesVideoStreamContext';
import { usePrevious } from 'hooks/usePrevious';

import style from './auctionVideoStream.scss';

interface Props extends VideoStreamProps {
  /** The current auction time slot id. */
  auctionTimeSlotId: string;
  /** The current auction time slot lane id. */
  auctionTimeSlotLaneId: string;
  /** Whether live badge is visible or not. */
  isLiveBadgeVisible?: boolean;
  /** Whether should update focused lane or not. */
  shouldUpdateFocusedLane?: boolean;
  /** The video stream config. */
  videoStreamConfig: VideoStreamConfig | undefined;
}

const AuctionVideoStream = ({
  auctionTimeSlotId,
  auctionTimeSlotLaneId,
  className,
  isLiveBadgeVisible,
  shouldUpdateFocusedLane,
  videoStreamConfig,
  ...props
}: Props) => {
  const [manager, setManager] = useState<MillicastViewerManager>();
  const prevAuctionTimeSlotLaneId: string | undefined = usePrevious(auctionTimeSlotLaneId);
  const videoStreamState = useLiveLanesVideoStream();

  const streamState = videoStreamState?.getStreamState(auctionTimeSlotLaneId);
  const isFocusedAuctionLane = videoStreamState?.focusedAuctionTimeSlotLaneId === auctionTimeSlotLaneId;

  /**
   * Set quality level for current video stream
   */
  const setQualityLevel = useCallback(
    (quality?: QualityLevel) => {
      manager?.setQualityLevel(quality);
    },
    [manager]
  );

  /**
   * On manager active qualities change, set quality level
   */
  useEffect(() => {
    if (streamState?.activeQualities?.length) {
      setQualityLevel(isFocusedAuctionLane ? QualityLevel.HIGHEST : QualityLevel.LOWEST);
    }
  }, [isFocusedAuctionLane, streamState?.activeQualities, setQualityLevel]);

  /**
   * Update focused lane when stream is available and live
   */
  useEffect(() => {
    if (streamState?.stream && streamState?.isLive && shouldUpdateFocusedLane) {
      // Set currently focused lane id, and unmute the stream
      videoStreamState?.updateFocusedLane(auctionTimeSlotId, auctionTimeSlotLaneId, streamState?.stream);
    }
  }, [
    auctionTimeSlotId,
    auctionTimeSlotLaneId,
    shouldUpdateFocusedLane,
    streamState?.isLive,
    streamState?.stream,
    videoStreamState,
  ]);

  /**
   * Initialize millicast viewer manager on lane id change
   */
  useEffect(() => {
    if (prevAuctionTimeSlotLaneId !== auctionTimeSlotLaneId) {
      const viewerManager = videoStreamState?.getViewerManager(auctionTimeSlotLaneId, videoStreamConfig);
      setManager(viewerManager);
    }
  }, [auctionTimeSlotLaneId, prevAuctionTimeSlotLaneId, videoStreamConfig, videoStreamState]);

  return streamState?.stream && streamState?.isLive ? (
    <div className={classnames(style.container, className)} data-testid="auction-video-stream">
      <VideoStream
        isMuted={videoStreamState?.isMuted}
        mediaStream={streamState?.stream}
        overrideAudio
        setIsMuted={videoStreamState?.setIsMuted}
        showFullScreenButton={streamState?.isVideoTrackActivated}
        showVideo={streamState?.isVideoTrackActivated}
        {...props}
      />
      {isLiveBadgeVisible && <LiveStreamBadge className={style.liveStreamBadge} />}
    </div>
  ) : null;
};

export default AuctionVideoStream;
