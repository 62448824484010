import classnames from 'classnames';
import { Moment } from 'moment';

import ClockGlyph from 'glyphs/clock.svg';

import Countdown from 'components/ui/countdowns/countdown';
import Sprite from 'components/ui/shared/sprite';
import TimerBar from 'components/ui/progressBars/timerBar';
import { TimerFormats } from 'constants/enums/dateAndTime';

import style from './footerProgressBar.scss';

interface Props {
  /** The footer labels to render. */
  label: string[];

  /** Function invoked when timer bar ends. */
  onEnd?: () => void;

  /** The percentage for timer bar width. */
  percentage?: number;

  /** True when the clock glyph should be displayed. */
  showClock?: boolean;

  /** True when the timer count down should be displayed. */
  showTimer?: boolean;

  /** The themed style of the footer. */
  theme: string;

  /** The end time of the timer bar and countdown. */
  timeEnd?: Moment;

  /** The start time of the timer bar. */
  timeStart?: Moment;
}

const FooterProgressBar = ({
  theme,
  timeStart,
  timeEnd,
  percentage,
  label,
  onEnd = () => {},
  showClock,
  showTimer,
}: Props) => (
  <footer className={classnames(style.footer, { [style[theme]]: true })}>
    <div className={style.timerBarContainer} data-testid="footer-progress-bar">
      {timeStart && timeEnd && <TimerBar className={style.timerBar} end={timeEnd} onEnd={onEnd} start={timeStart} />}
      {percentage && <TimerBar className={style.timerBar} width={percentage} />}
    </div>
    <div className={style.timerText}>
      {showClock && <Sprite className={style.clockGlyph} glyph={ClockGlyph} />}
      <div>
        {showTimer && timeEnd && <Countdown end={timeEnd} timeFormat={TimerFormats} />}
        {label.map((labelComp, i) => (
          <span key={i} className={style.labelComp}>
            {labelComp}
          </span>
        ))}
      </div>
    </div>
  </footer>
);

export default FooterProgressBar;
