import phoneImg from 'static/images/download-app@2x.png';
import appStoreImage from 'static/images/app-store-badge.png';
import playStoreImage from 'static/images/google-play-badge.png';
import { t } from 'utils/intlUtils';

import style from './mobileGate.scss';

const MobileGate = () => {
  return (
    <div className={style.mobileGate} data-testid="error-mobile-gate">
      <img alt="No phone" className={style.phone} src={phoneImg} />
      <h1>{t('download_app')}</h1>
      <p>{t('download_app_message')}</p>
      <div className={style.links}>
        <a href={process.env.REACT_APP_MOBILE_URL_APP_STORE}>
          <img alt="" className={style.linkImage} src={appStoreImage} />
        </a>
        <a href={process.env.REACT_APP_MOBILE_URL_PLAY_STORE}>
          <img alt="" className={style.linkImage} src={playStoreImage} />
        </a>
      </div>
    </div>
  );
};

export default MobileGate;
