import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemIfBidAcceptArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { isAuctionStaff } from 'utils/userUtils';
import { processAuctionItemIfBidAccept } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const stateConnect = (state: AppState) => ({
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to set if bid accepted. */
  ifBidAccept: (options: MutationauctionItemIfBidAcceptArgs) => processAuctionItemIfBidAccept(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item information. */
  auctionItem: AuctionItem;

  /** CSS style to override default style. */
  className?: string;

  /** Whether is amount displayed or not. */
  isAmountDisplayed?: boolean;

  /** Callback function to trigger on done. */
  onDone?: (options: AuctionItem) => void;
}

interface State {
  /** Error messages. */
  errorMessages: ErrorMessages | undefined;

  /** Whether the dialog is open or not. */
  isOpen: boolean;

  /** Whether is submitting or not. */
  isSubmitting: boolean;
}

class AcceptOffer extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isOpen: false, errorMessages: undefined, isSubmitting: false };
  }

  onSubmit = (shouldSubmit: boolean = false, options: Partial<MutationauctionItemIfBidAcceptArgs> = {}) => {
    const {
      auctionItem,
      auctionItem: { id },
      ifBidAccept,
      onDone,
    } = this.props;

    if (shouldSubmit) {
      this.setState({ isSubmitting: true });

      trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_ACCEPT_CONFIRM_CLICK, auctionItem);

      ifBidAccept?.({ auctionItemId: id, ...options })
        ?.then((item: AuctionItem) => {
          this.setState({ errorMessages: null, isOpen: false });
          onDone?.(item);
        })
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
    }
  };

  render() {
    const { auctionItem, className, isAmountDisplayed, user } = this.props;
    const currentOffer = auctionItem.ifBidTimeline?.list?.[0]?.amount;
    const isStaffUser = isAuctionStaff(user, auctionItem?.auction?.id);

    return (
      <>
        <OperationButton
          key="button"
          className={className}
          onClick={() => {
            this.setState({ isOpen: true });
            trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_ACCEPT_CLICK, auctionItem);
          }}
          theme="green"
        >
          {t('accept')} {isAmountDisplayed ? currentOffer?.formattedAmountRounded : ''}
        </OperationButton>
        <Dialog
          key="dialog"
          auctionItem={auctionItem}
          isStaffUser={isStaffUser}
          onSubmit={this.onSubmit}
          {...this.state}
        />
      </>
    );
  }
}

export default connector(AcceptOffer);
