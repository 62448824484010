import { handleActions } from 'redux-actions';

import { getField } from 'utils/objectUtils';
import {
  consignerReportGroupsUpdating,
  consignerReportGroupsLoaded,
  consignerReportGroupsCleared,
  consignerReportLoading,
  consignerReportUpdating,
  consignerReportLoaded,
  consignerReportError,
  consignerReportCleared,
  consignerLoaded,
} from './reportsActions';
import { InitialState } from 'store/reports/reportsModels';

export const reportsReducer = handleActions(
  {
    [consignerReportGroupsUpdating().type]: (state) => state.setUpdating(),

    [consignerReportGroupsLoaded().type]: (state, action) => {
      return state.setLoaded().set('reportGroups', action.payload);
    },

    [consignerReportGroupsCleared().type]: (state) =>
      new InitialState().set('selectedConsigner', state.selectedConsigner),

    [consignerReportLoading().type]: (state) => {
      return state.set('isReportLoading', true);
    },

    [consignerReportUpdating().type]: (state) => {
      return state.set('isReportUpdating', true);
    },

    [consignerReportLoaded().type]: (state, action) => {
      if (state.isReportLoading || state.isReportUpdating) {
        const report = action.payload;

        // TODO: We should only assign an empty array to a field when it's empty.
        //  Currently both are being set to empty arrays if one or the other has !length
        if (!getField(report, 'header.length') || !getField(report, 'rows.length')) {
          Object.assign(report, { header: [], rows: [] });
        }

        return state.set('report', report).merge({
          isReportLoading: false,
          isReportUpdating: false,
        });
      }
      return state.merge({
        isReportLoading: false,
        isReportUpdating: false,
      });
    },

    [consignerReportError().type]: (state, action) => {
      return state.set('reportErrors', action.payload).merge({
        isReportLoading: false,
        isReportUpdating: false,
      });
    },

    [consignerReportCleared().type]: (state) => {
      return state.merge({
        report: null,
        reportErrors: null,
        isReportLoading: false,
        isReportUpdating: false,
      });
    },

    [consignerLoaded().type]: (state, action) => state.set('selectedConsigner', action.payload),
  },
  new InitialState()
);
