import { t } from 'utils/intlUtils';

import style from 'forms/account/accountVerification/accountVerification.scss';

const AccountVerificationIntro = () => (
  <>
    <p className={style.title}>{t('verify_your_account_information')}</p>
    <p>{t('verify_your_account_information_message')}</p>
  </>
);

export default AccountVerificationIntro;
