import AuctionItem from 'constants/auctionItem';
import InventoryItem from 'constants/inventoryItem';
import { AuctionItemFormat, AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { isAuctionFormat } from 'utils/auctionItemUtils';

/**
 * Returns a url path for a vehicle based on auctionItem and inventoryItem details:
 */
export const getVehiclePath = (
  auctionItem?: Pick<AuctionItem, 'status' | 'format' | 'id'>,
  inventoryItemId: string | null = null
) => {
  if (auctionItem) {
    const { status, format, id } = auctionItem;

    switch (status) {
      case AuctionItemStatus.UPCOMING:
        return `/buy/run-list?id=${id}`;
      case AuctionItemStatus.LIVE:
        if (format === AuctionItemFormat.TIMED_OFFER) {
          return `/buy/buy-now?id=${id}`;
        }
        return isAuctionFormat(format) ? `/buy/live-auction?id=${id}` : `/buy/timed-auction?id=${id}`;
      case AuctionItemStatus.IN_IF_BID:
        return `/pending/in-if-bid?id=${id}`;
      case AuctionItemStatus.PENDING_DELIVERY:
        return `/pending/pending-delivery?id=${id}`;
      case AuctionItemStatus.AWAITING_CHECKOUT:
        return `/pending/awaiting-checkout?id=${id}`;
      case AuctionItemStatus.SOLD:
        return `/finished/sold?id=${id}`;
      case AuctionItemStatus.NO_SALE:
        return `/finished/no-sale?id=${id}`;
      case AuctionItemStatus.SALE_CANCELLED:
      case AuctionItemStatus.FAILED:
        return `/finished/cancelled?id=${id}`;
    }
  }

  return `/sell/parked?id=${inventoryItemId}`;
};

/**
 * Check if the item is an auction item.
 */
const isAuctionItem = (item: AuctionItem | InventoryItem | undefined): item is AuctionItem => {
  return !!(item as AuctionItem)?.inventoryItem;
};

/**
 * Similar to `getVehiclePath`, but when you also need to check if
 * you're passing either an AuctionItem or InventoryItem.
 */
export const getVehiclePathByUnknownEntity = (entity: AuctionItem | InventoryItem | undefined) => {
  const auctionItem = isAuctionItem(entity) ? entity : undefined;
  const inventoryItemId = auctionItem?.inventoryItem?.id || entity?.id;

  return getVehiclePath(auctionItem, inventoryItemId);
};
