import Button from 'components/ui/shared/button';
import NotAvailable from './notAvailable';
import Placeholder from 'components/ui/shared/placeholder';
import defaultVehicleBg from 'static/images/default-vehicle-bg.jpg';
import { Spinner } from 'components/ui/loading/loading';
import { Switch, Case } from 'components/ui/shared/directives/switch';

import style from './empty.scss';

interface Props {
  /**
   * Whether is available or not.
   * @default true
   */
  isAvailable?: boolean;

  /** Callback function to handle on close event. */
  onClose?: () => void;

  /** Whether show loader or not. */
  showLoader: boolean;

  /** Unavailable sub header text. */
  unavailableSubHeaderText?: string;
}

const Empty = ({ isAvailable = true, onClose = () => {}, showLoader, unavailableSubHeaderText }: Props) => (
  <div className={style.empty} data-testid="empty">
    <div className={style.content}>
      <div className={style.photoContainer}>
        <div className={style.photoContainerInner}>
          <div className={style.defaultVehicleContainer}>
            <img alt="Vehicle placeholder" src={defaultVehicleBg} />
          </div>
        </div>
        {showLoader && <Spinner className={style.loading} />}
      </div>
      <div className={style.status}>
        <Switch>
          <Case if={isAvailable}>
            <div className={style.row}>
              <Placeholder width="60%" />
              <Placeholder width="90%" />
            </div>
            <div className={style.row}>
              <Placeholder width="40%" />
              <Placeholder width="30%" />
            </div>
            <div className={style.buttons}>
              <Button disabled>&nbsp;</Button>
              <Button disabled>&nbsp;</Button>
            </div>
          </Case>
          <Case>
            <NotAvailable onClose={onClose} subHeaderText={unavailableSubHeaderText} />
          </Case>
        </Switch>
      </div>
    </div>
  </div>
);

export default Empty;
