import { memo } from 'react';

import ValuesInfoItems, {
  Props as ValuesInfoItemsProps,
  isValuesInfoVisible,
} from 'components/sections/inventoryItem/details/feesAndValues/valuesInfoItems';
import { FeeValueRow } from 'components/sections/inventoryItem/details/feesAndValues/feeValueItem';

type Props = ValuesInfoItemsProps;

const FeeValuesInfoRow = ({ ...props }: Props) => {
  if (!isValuesInfoVisible(props.inventoryItemValues, props.countryCode)) {
    return null;
  }

  return (
    <FeeValueRow>
      <ValuesInfoItems {...props} />
    </FeeValueRow>
  );
};

export default memo(FeeValuesInfoRow);
