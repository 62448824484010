/**
 * Enum for facet names.
 */
export enum FacetName {
  AUTOBIDS = 'Autobids',
  OFFER = 'Offer',
  WAITING_ON_THEM = 'Waiting On Them',
  WAITING_ON_YOU = 'Waiting On You',
  WATCHING = 'Watching',
}
