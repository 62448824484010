import { LOCATION_CHANGE } from 'react-router-redux';
import { handleActions } from 'redux-actions';

import { PageTitle } from 'store/routing/pageTitle';
import { Route } from 'store/routing/routes';

export const pageTitleReducer = handleActions(
  {
    [LOCATION_CHANGE]: (state, action) => {
      // Generate an id from the pathname that can be mapped to an intl message
      let pathname: string = action.payload.pathname || Route.HOME;

      if (pathname.includes(Route.AUTH_RESET_PASSWORD.split(':token')?.[0])) {
        pathname = Route.AUTH_RESET_PASSWORD;
      }

      return PageTitle[pathname] ?? pathname;
    },
  },
  PageTitle[Route.HOME]
);
