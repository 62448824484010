import ApiRequest from 'store/shared/api/apiRequest';
import { VehicleMetaData } from 'store/shared/api/graph/interfaces/types';

export const getYears = () =>
  ApiRequest<{ inventoryItemMetaData: { vehicleMetaData: { year: VehicleMetaData['year'] } } }>({
    data: {
      operationName: 'getYears',
      query: `
        {
          inventoryItemMetaData {
            vehicleMetaData {
              year
            }
          }
        }
      `,
    },
  });

export const getMakes = (options?: { year: number }) =>
  ApiRequest<{ inventoryItemMetaData: { vehicleMetaData: { mmt: VehicleMetaData['mmt'] } } }>({
    data: {
      operationName: 'getMakes',
      query: `
        query($year: Int) {
          inventoryItemMetaData {
            vehicleMetaData {
              mmt(year: $year) {
                id
                name
              }
            }
          }
        }
      `,
      variables: options,
    },
  });

export const getModels = (options: { makeId: string }) =>
  ApiRequest<{ inventoryItemMetaData: { vehicleMetaData: { mmt: VehicleMetaData['mmt'] } } }>({
    data: {
      operationName: 'getModels',
      query: `
        query($makeId: String) {
          inventoryItemMetaData {
            vehicleMetaData {
              mmt(makeId: $makeId) {
                models {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: options,
    },
  });
