import { debounce, groupBy, omit } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import AddNewMakeModeFilter from 'components/sections/notifications/tabs/vehicleListings/addNewMakeModeFilter';
import AnchorTabs from 'components/ui/shared/tabs/anchorTabs';
import AuthService from 'store/shared/services/authService';
import OtherTab from 'components/sections/notifications/tabs/otherTab';
import SlideOut from 'components/ui/slideOuts/slideOut';
import VehicleListingsTab from 'components/sections/notifications/tabs/vehicleListings/vehicleListingsTab';
import { AppState } from 'store/configureStore';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { ClearableFieldInput } from 'constants/enums/forms';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  MutationuserNotificationSettingsUpdateArgs,
  Notification,
  NotificationCategory,
  NotificationSettings,
} from 'store/shared/api/graph/interfaces/types';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { camelCaseToSnakeCase } from 'utils/stringUtils';
import { getMileageUnit, isAuctionStaff } from 'utils/userUtils';
import { getUserNotifications } from 'store/shared/api/graph/queries/notifications';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';
import { usePrevious } from 'hooks/usePrevious';
import { userNotificationSettingsUpdate } from 'store/shared/api/graph/mutations/notifications';

import style from './userNotificationsSlideOut.scss';

export enum NotificationTab {
  VEHICLE_LISTINGS,
  IN_AUCTION,
  POST_SALE,
  STAFF,
  VEHICLE_LISTINGS_ADD,
}

export const NotificationTabMap = {
  [NotificationTab.IN_AUCTION]: NotificationCategory.IN_AUCTION,
  [NotificationTab.POST_SALE]: NotificationCategory.POST_SALE,
  [NotificationTab.STAFF]: NotificationCategory.STAFF,
  [NotificationTab.VEHICLE_LISTINGS]: NotificationCategory.VEHICLE_LISTINGS,
};

export type Notifications = Omit<NotificationSettings, 'notifications'> & {
  notifications: {
    [NotificationTab: string]: NotificationSettings['notifications'];
  };
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UserNotificationsSlideOut = ({ isOpen, onClose }: Props) => {
  const isOpenPrev = usePrevious(isOpen);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notifications | null>();
  const [selectedTab, setSelectedTab] = useState<NotificationTab>(NotificationTab.VEHICLE_LISTINGS);

  const user = useSelector((state: AppState) => state.app.user);
  const mileageUnit = getMileageUnit(user).toLowerCase();

  // Memoized anchor tabs
  // Visibility based on user permissions as well as available notifications
  const anchorTabs = useMemo(
    () => [
      { id: 'vehicleListings', isVisible: true, label: t('vehicle_listings') },
      {
        id: 'inAuction',
        isVisible: !!notifications?.notifications[NotificationCategory.IN_AUCTION]?.length,
        label: t('in_auction'),
      },
      {
        id: 'postSale',
        isVisible: !!notifications?.notifications[NotificationCategory.POST_SALE]?.length,
        label: t('post_sale'),
      },
      {
        id: 'staff',
        isVisible:
          isAuctionStaff(AuthService.user) && !!notifications?.notifications[NotificationCategory.STAFF]?.length,
        label: t('staff'),
      },
    ],
    [notifications]
  );

  /**
   * Save Notifications
   */
  const saveNotifications = useCallback((_notifications: Notifications, shouldUpdateFromResponse = false) => {
    const settings = { ...omit(_notifications, ['notifications']) };

    const notificationSettings: MutationuserNotificationSettingsUpdateArgs = {
      ...settings,
      // Remove `make` and `model` fields from `makeModelFiltersInput`
      makeModelFilters: settings?.makeModelFilters?.map((filter) => omit(filter, ['make', 'model'])),
      // Transform `NotificationSettings['notifications']` into `NotificationInput[]`
      notifications: Object.keys(_notifications?.notifications ?? {})
        .reduce((acc, key) => [...acc, ...(_notifications?.notifications?.[key] ?? [])], [])
        .map((notification) => ({
          id: notification?.id ?? '',
          email: !!notification?.email,
          push: !!notification?.push,
          sms: !!notification?.sms,
        })),
      userId: AuthService.user?.id,
    };

    return userNotificationSettingsUpdate(notificationSettings)
      ?.then((response) => {
        // TODO: Potentially trigger a short-lived "saved" state

        if (shouldUpdateFromResponse) {
          const settingsNext = response?.data?.data?.userNotificationSettingsUpdate
            ?.notificationSettings as NotificationSettings;
          const notificationGroups = groupBy(settingsNext?.notifications, 'category');
          const notificationsNext = { ...settingsNext, notifications: notificationGroups } as Notifications;
          setNotifications(notificationsNext);
        }
      })
      ?.catch((error) => {
        onApiError(error, setErrorMessages);
        throw error;
      });
  }, []);

  /**
   * TODO: Improve docs
   * Save Notifications (Debounced)
   * Debounced handler to save notification settings in real-time,
   * as opposed to relying on confirm/save button
   */
  const saveNotificationsDebounced = useMemo(() => debounce(saveNotifications, 500), [saveNotifications]);

  /**
   * Update notifications in state, as well as with an option to save with API
   */
  const updateNotifications = useCallback(
    (_notifications: Notifications, shouldSave: boolean = true) => {
      setNotifications(_notifications);
      if (shouldSave) {
        saveNotificationsDebounced(_notifications);
      }
    },
    [saveNotificationsDebounced, setNotifications]
  );

  /**
   * Group and set notifications
   *
   * Organizes `user.notificationSettings.notifications` by `category`
   */
  const groupAndSetNotifications = useCallback(
    (notificationSettings: NotificationSettings) => {
      const notificationGroups = groupBy(notificationSettings?.notifications, 'category');
      const notificationsNext = { ...notificationSettings, notifications: notificationGroups } as Notifications;
      updateNotifications(notificationsNext, false);
    },
    [updateNotifications]
  );

  /**
   * Fetch notifications and save in state
   */
  const getNotifications = useCallback(() => {
    setErrorMessages([]);
    setIsLoading(true);

    getUserNotifications()
      ?.then((response) => {
        const notificationSettings = response?.data?.data?.user?.notificationSettings as NotificationSettings;
        groupAndSetNotifications(notificationSettings);
      })
      ?.catch((error) => setErrorMessages(error))
      ?.finally(() => setIsLoading(false));
  }, [groupAndSetNotifications]);

  /**
   * Get notification options
   * Omits an option if the API returns its value as `null`
   */
  const getNotificationOptions = useCallback((notification: Notification): string[] => {
    return ['sms', 'push', 'email'].filter((option) => notification?.[option] !== null);
  }, []);

  /**
   * onToggleChange
   * Toggles the boolean value of a vehicle listing settings
   */
  const onToggleVehicleListing = useCallback(
    (fieldName: string) => () => {
      const eventName = `notifications_auction_format_${camelCaseToSnakeCase(fieldName)}_clicked`;
      trackUserActionWithUserAttributes(eventName as UserAction);
      const notificationsNext = { ...notifications, [fieldName]: !notifications?.[fieldName] } as Notifications;
      updateNotifications(notificationsNext);
    },
    [notifications, updateNotifications]
  );

  /**
   * onToggleNotification
   * Toggles the boolean value of a notification setting
   */
  const onToggleNotification = useCallback(
    (notification: Notification) => {
      const notificationsNext = {
        ...notifications,
        notifications: {
          ...notifications?.notifications,
          [NotificationTabMap[selectedTab]]: [
            ...(notifications?.notifications?.[NotificationTabMap[selectedTab]] ?? [])?.map((_notification) => {
              if (_notification?.id === notification.id) {
                return notification;
              }
              return _notification;
            }),
          ],
        },
      } as Notifications;

      updateNotifications(notificationsNext);
    },
    [notifications, selectedTab, updateNotifications]
  );

  /**
   * onDistanceChange
   */
  const onDistanceChange = useCallback(
    (value: number | null) => () => {
      const eventName = `notifications_distance_away_${value === ClearableFieldInput.NUMBER ? 'any' : value}_clicked`;
      trackUserActionWithUserAttributes(eventName as UserAction);
      updateNotifications({ ...notifications, distanceFromPreferredLocation: value } as Notifications);
    },
    [notifications, updateNotifications]
  );

  /**
   * onOpen
   *
   * Clear notification state and re-fetch settings from API
   */
  useEffect(() => {
    if (isOpen && !isOpenPrev) {
      setErrorMessages([]);
      setNotifications(null);
      setSelectedTab(0);
      getNotifications();
    }
  }, [getNotifications, isOpen, isOpenPrev]);

  // TODO: Make tabs sticky to top
  return (
    <SlideOut
      className={style.container}
      contentClassName={style.contentClassName}
      contentInnerClassName={style.containerContentInner}
      isClickOutsideDisabled={isClickOutsideDisabled}
      isOpen={isOpen}
      onClose={onClose}
      title="Notifications"
    >
      {isLoading ? (
        <SpinnerCentered className={style.spinner} />
      ) : (
        <>
          <AnchorTabs
            className={style.anchorTabs}
            defaultSelected={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            tabClass={style.tab}
            tabs={anchorTabs}
            tabsClass={style.tabs}
          />

          <FormErrors errorMessages={errorMessages} />
          <Switch>
            <Case if={selectedTab === NotificationTab.VEHICLE_LISTINGS}>
              <VehicleListingsTab
                getNotificationOptions={getNotificationOptions}
                mileageUnit={mileageUnit}
                notifications={notifications || null}
                onAddVehicleListing={() => {
                  trackUserActionWithUserAttributes(UserAction.NOTIFICATIONS_ADD_NEW_CLICK);
                  setSelectedTab(NotificationTab.VEHICLE_LISTINGS_ADD);
                  setIsClickOutsideDisabled(true);
                }}
                onDistanceChange={onDistanceChange}
                onToggleNotification={onToggleNotification}
                onToggleVehicleListing={onToggleVehicleListing}
                saveNotifications={saveNotifications}
                setIsClickOutsideDisabled={setIsClickOutsideDisabled}
              />
            </Case>
            <Case if={selectedTab === NotificationTab.VEHICLE_LISTINGS_ADD}>
              <AddNewMakeModeFilter
                mileageUnit={mileageUnit}
                notifications={notifications || null}
                onClose={() => {
                  setErrorMessages([]);
                  setSelectedTab(NotificationTab.VEHICLE_LISTINGS);
                  setIsClickOutsideDisabled(false);
                }}
                saveNotifications={saveNotifications}
              />
            </Case>
            <Case
              if={[NotificationTab.IN_AUCTION, NotificationTab.POST_SALE, NotificationTab.STAFF].includes(selectedTab)}
            >
              <OtherTab
                getNotificationOptions={getNotificationOptions}
                notifications={notifications || null}
                onToggleNotification={onToggleNotification}
                selectedTab={selectedTab}
              />
            </Case>
          </Switch>
        </>
      )}
    </SlideOut>
  );
};

export default UserNotificationsSlideOut;
