import ApiRequest from 'store/shared/api/apiRequest';
import { UserCompanyRelationshipConnection } from 'store/shared/api/graph/interfaces/types';
import { parseQueryParams } from 'utils/apiUtils';
import { repUserFragment } from 'store/shared/api/graph/fragments/reps';

/**
 * GetCompanyUsers
 *
 * @param {object} options - Arguments associated to query.UserCompanyRelationshipConnection
 * @param {string} querySchema - The fields to fetch (required)
 * @param {string} fragments - Fragments used in querySchema (optional)
 * @returns {Promise}
 */
export const getCompanyUsers = (
  {
    first = 25,
    last = null,
    after = null,
    before = null,
    companyIds = null,
    keyword = null,
    status = null,
    department = null,
    title = null,
    auctionAccessStatus = null,
    country = null,
    region = null,
    city = null,
    photoStatus = null,
    sort = null,
  },
  querySchema,
  fragments
) =>
  ApiRequest<{ companyUserConnection: UserCompanyRelationshipConnection }>({
    data: {
      operationName: 'getCompanyUsers',
      query: `
        query(
          $first: Int,
          $last: Int,
          $after: String,
          $before: String,
          $companyIds: [String],
          $keyword: String,
          $status: [UserCompanyRelationshipStatus],
          $department: [String],
          $title: [String],
          $auctionAccessStatus: [String],
          $country: [String],
          $region: [String],
          $city: [String],
          $photoStatus: [String],
          $sort: [Sort]
        ) {
          companyUserConnection(
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            companyIds: $companyIds,
            keyword: $keyword,
            status: $status,
            department: $department,
            title: $title,
            auctionAccessStatus: $auctionAccessStatus,
            country: $country,
            region: $region,
            city: $city,
            photoStatus: $photoStatus,
            sort: $sort
          ) {
            edges {
              node {
                ${querySchema}
              }
            }
          }
        }
        ${fragments}
    `,
      variables: {
        first,
        last,
        after,
        before,
        companyIds,
        keyword,
        status,
        department,
        title,
        auctionAccessStatus,
        country,
        region,
        city,
        photoStatus,
        sort,
      },
    },
  });

/**
 * Gets users (reps) from company
 *
 * @param {object} options - Arguments associated to query.UserCompanyRelationshipConnection
 * @returns {Promise}
 */
export const getRepsFromCompany = (options) =>
  getCompanyUsers(parseQueryParams(options), 'user { ...repUser }', repUserFragment);
