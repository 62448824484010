import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';

import InputText from 'forms/shared/inputText';
import {
  AccountVerificationStepHandleRef,
  AccountVerificationStepProps,
} from 'forms/account/accountVerification/steps/accountVerificationHelpers';
import { AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  MutationuserAccountSendVerificationCodeArgs,
  UserAccountVerificationType,
} from 'store/shared/api/graph/interfaces/types';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';
import { userAccountSendVerificationCode } from 'store/shared/api/graph/mutations/userAccountSendVerificationCode';

import style from 'forms/account/accountVerification/accountVerification.scss';

const EmailVerificationEmailInput = forwardRef<AccountVerificationStepHandleRef, AccountVerificationStepProps>(
  ({ defaultValue, onChange }, ref) => {
    const user = useSelector((state: AppState) => state.app.user);
    const [inputValue, setInputValue] = useState<string>((defaultValue || user?.email) ?? '');
    const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);

    /**
     * Update internal state when any inputs have changed, and notify
     * the form controller if the submit button is eligible for submission.
     */
    const onInputChange = useCallback(
      (value) => {
        setInputValue(value);
        onChange(!!value?.length);
      },
      [onChange]
    );

    // Exposes `validate` function to parent form controller
    useImperativeHandle(ref, () => ({
      validate: () => {
        const options: MutationuserAccountSendVerificationCodeArgs = {
          verificationType: UserAccountVerificationType.EMAIL,
          verifiedField: inputValue,
        };

        // Send verification code; show error state when necessary
        return new Promise((resolve) => {
          return userAccountSendVerificationCode(options)
            ?.then(() => resolve(options))
            ?.catch((error) => onApiError(error, setErrorMessages));
        });
      },
    }));

    // On mount, notify the form controller if the submit button is eligible for submission
    useMountEffect(() => onChange(!!inputValue?.length));

    return (
      <>
        <p className={style.title}>{t('email_address_verification')}</p>
        <p>{t('email_address_verification_message')}</p>
        <FormErrors className={style.formErrors} errorMessages={errorMessages} isSmallDialog />
        <InputText
          className={style.input}
          defaultValue={inputValue}
          onChange={onInputChange}
          placeholder={t('enter_email_address')}
        />
      </>
    );
  }
);

export default EmailVerificationEmailInput;
