import classnames from 'classnames';
import { MouseEvent, ReactNode } from 'react';

import Button from 'components/ui/shared/button';

import style from './miniButton.scss';

interface Props {
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Function invoked on button click. */
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /**
   * The theme of the mini button.
   * @default 'blue'
   */
  theme?: 'black' | 'blue' | 'red';
}

const MiniButton = ({ children, className, theme = 'blue', ...props }: Props) => {
  return (
    <Button className={classnames(style.miniButton, style[`theme-${theme}`], className)} theme="none" {...props}>
      {children}
    </Button>
  );
};

export default MiniButton;
