import { ActionPayload, handleActions } from 'redux-actions';
import { List } from 'immutable';

import { User, UserCompanyRelationship, InitialState } from './userDetailsModels';
import { User as UserType } from 'store/shared/api/graph/interfaces/types';
import {
  clearUser,
  isLoaded,
  isNotUpdating,
  isUpdating,
  preloadUser,
  setUserData,
  updateUser,
  userRelationshipsAreLoading,
  userRelationshipsLoaded,
} from './userDetailsActions';

export const userDetailsReducer = handleActions(
  {
    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [clearUser().type]: () => new InitialState(),

    [isLoaded().type]: (state, action) => {
      const { user } = action.payload;
      return state.setLoaded().merge({ details: new User(user) });
    },

    [preloadUser().type]: (state, action: ActionPayload<UserType>) => {
      if (action.payload && action.payload.id) {
        const user = new User(action.payload);
        return state.setLoaded().set('details', user);
      }
      return state;
    },

    [setUserData().type]: (state, action) => {
      const { data, path } = action.payload;
      return state.setIn(['details'].concat(path), data);
    },

    [updateUser().type]: (state, action) => {
      if (!state.details) {
        return state;
      }

      const details = state.details.toJS();
      return state.merge({
        details: new User({
          ...details,
          ...action.payload,
        }),
      });
    },

    [userRelationshipsAreLoading().type]: (state) => {
      return state.set('isLoadingUserRelationships', true);
    },

    [userRelationshipsLoaded().type]: (state, { payload }) => {
      return state.merge({
        isLoadingUserRelationships: false,
        userRelationships: List(
          payload.edges.map(({ node }) => {
            return new UserCompanyRelationship(node);
          })
        ),
      });
    },
  },
  new InitialState()
);
