import { handleActions } from 'redux-actions';

import { showCheckoutNotification } from './checkoutActions';
import { Checkout } from './checkoutModels';

export const checkoutReducer = handleActions(
  {
    [showCheckoutNotification().type]: (state, action) => state.set('showNotification', action.payload),
  },

  new Checkout()
);
