import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionServiceMetadata,
  QueryauctionItemAuctionServiceMetadataArgs,
  QueryauctionServiceDefaultsMetadataArgs,
  QueryauctionServiceMetadataArgs,
} from 'store/shared/api/graph/interfaces/types';

export const getAuctionServiceMetadata = ({
  auctionId,
  buyerShield,
  companyId,
  companySubTypes,
  paidBy,
  party,
  formats,
  verified,
}: QueryauctionServiceMetadataArgs) =>
  ApiRequest<{ serviceMetadata: AuctionServiceMetadata[] }>({
    data: {
      operationName: 'getAuctionServiceMetadata',
      query: `
        query (
          $auctionId: String!
          $buyerShield: AuctionServiceTriState
          $companyId: String
          $companySubTypes: [CompanySubType]
          $paidBy: AuctionServiceMetadataParty
          $party: AuctionServiceMetadataParty!
          $formats: [AuctionItemFormat!]
          $verified: AuctionServiceTriState
        ) {
          serviceMetadata: auctionServiceMetadata(
            auctionId: $auctionId
            buyerShield: $buyerShield
            companyId: $companyId
            companySubTypes: $companySubTypes
            paidBy: $paidBy
            party: $party
            formats: $formats
            verified: $verified
          ) {
            id
            feeName
          }
        }
      `,
      variables: {
        auctionId,
        buyerShield,
        companyId,
        companySubTypes,
        paidBy,
        party,
        formats,
        verified,
      },
    },
  });

export const getAuctionItemAuctionServiceMetadata = ({
  auctionItemId,
  party,
}: QueryauctionItemAuctionServiceMetadataArgs) =>
  ApiRequest<{ serviceMetadata: AuctionServiceMetadata[] }>({
    data: {
      query: `
        query ($auctionItemId: String!, $party: AuctionServiceMetadataParty!) {
          serviceMetadata: auctionItemAuctionServiceMetadata(auctionItemId: $auctionItemId, party: $party) {
            id
            feeName
          }
        }
      `,
      variables: {
        auctionItemId,
        party,
      },
    },
  });

export const getAuctionServiceDefaultsMetadata = ({
  companyAuctionRelationshipId,
  party,
  type,
}: QueryauctionServiceDefaultsMetadataArgs) =>
  ApiRequest<{ auctionServiceDefaultsMetadata: AuctionServiceMetadata[] }>({
    data: {
      operationName: 'getAuctionServiceDefaultsMetadata',
      query: `
        query (
          $companyAuctionRelationshipId: ID!
          $party: AuctionServiceMetadataParty
          $type: AuctionServiceMetadataDefaultType
        ) {
          auctionServiceDefaultsMetadata(
            companyAuctionRelationshipId: $companyAuctionRelationshipId
            party: $party
            type: $type
          ) {
            id
            defaultSelected
            feeName
            serviceName
            transportService
          }
        }
      `,
      variables: {
        companyAuctionRelationshipId,
        party,
        type,
      },
    },
  });
