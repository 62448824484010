import classnames from 'classnames';

import AuctionItem from 'constants/auctionItem';
import User from 'constants/user';
import { DateFormat } from 'constants/enums/dateAndTime';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatDate } from 'utils/dateUtils';
import { isAuctionStaff, representsCompany } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './workflowProcessRows.scss';

interface WorkflowProcessRowsProps {
  /** The auction item details. */
  details: AuctionItem;
  /** The current user. */
  user: User;
}

const WorkflowProcessRows = ({ details, user }: WorkflowProcessRowsProps) => {
  const isBuyer = representsCompany(user, details?.topOffer?.company?.id);
  const isSeller = representsCompany(user, details?.inventoryItem?.company?.id);

  if (isBuyer || isSeller || isAuctionStaff(user)) {
    const workflowProcesses = details.workflowProcesses ?? [];
    return (
      <Rows data-testid="workflow-process-rows">
        {workflowProcesses.map((process, index) => (
          <Row
            key={process.id}
            className={classnames(style.workflowProcess, style[process.statusColorScheme.toLowerCase()])}
            mergeNext={index < workflowProcesses.length - 1}
            name={process.name}
          >
            {t('workflow_process_value', [
              process.status,
              formatDate(process.updated, DateFormat.MONTH_DAY_YEAR_FORMAT),
            ])}
          </Row>
        ))}
      </Rows>
    );
  }
  return null;
};

export default WorkflowProcessRows;
