/**
 * Find an id based on a name
 *
 * @param array {array}
 * @param name {string}
 * @returns {string}
 */
export const getIdByName = (array, name) => (array.find((item) => item.name === name) || {}).id || '';

/**
 * Find an id based on a value
 *
 * @param array {array}
 * @param value {string}
 * @returns {string}
 */
export const getIdByValue = (array, value) => array.find((item) => item.value === value)?.id || '';

/**
 * Find an name based on a id
 *
 * @param array {array}
 * @param id {string}
 * @returns {string}
 */
export const getNameById = (array, id) => (array.find((item) => item.id === id) || {}).name || '';

/**
 * Find a value based on a id
 *
 * @param array {array}
 * @param id {string}
 * @returns {string}
 */
export const getValueById = (array, id) => array.find((item) => item.id === id)?.value || '';

/**
 * Move an array item to a different position
 *
 * @param {array} array
 * @param {int} from - starting position
 * @param {int} to - ending position
 * @returns {array}
 */
export const move = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;
    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
};

/**
 * Gets an item from an array at a random index
 */
export const getRandomItemInArray = (array: any[]): any => array[Math.floor(Math.random() * array.length)];

/**
 * Removes duplicate entries with matching `id` values
 */
export const removeDuplicateIds = (array?: any[]) =>
  array?.filter((item, index, _array) => _array?.findIndex((_item) => _item?.id === item?.id) === index);

/**
 * Checks to make sure array is not undefined and is not empty -- Also adds type guard for `NonEmptyArray`
 */
export const isNonEmptyArray = <T>(arr: Array<T> | undefined): arr is NonEmptyArray<T> => {
  return !!arr && arr.length > 0;
};

/**
 * Similar to Lodash's 'omit', but for string arrays
 *
 * @example omit(['a', 'b', 'c'], ['c]); // ['a', 'b']
 * @param source
 * @param ignorablePaths
 */
export const omit = (source: string[], ignorablePaths: string[]) => {
  return source.filter((path) => !ignorablePaths.includes(path));
};
