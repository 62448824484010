import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Company, ReportGroup } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { LooseObject } from 'constants/objects';

export interface ReportsProps {
  /** Whether is report loading or not. */
  isReportLoading: boolean;

  /** Whether is report updating or not. */
  isReportUpdating: boolean;

  /** Report file. */
  report: LooseObject | null;

  /** Report errors. */
  reportErrors: ErrorMessages | null;

  /** Report groups. */
  reportGroups: ReportGroup[];

  /** Selected consigner. */
  selectedConsigner: Company | null;
}

export type ReportsPropsJs = ReturnType<ServerRecordOf<ReportsProps>['toJS']>;

export const InitialState = ServerRecord<ReportsProps>({
  isReportLoading: false,
  isReportUpdating: false,
  report: null,
  reportErrors: null,
  reportGroups: [],
  selectedConsigner: null,
});
