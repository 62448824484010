import { ReactElement, useCallback, useEffect, useRef } from 'react';

/**
 * A list of global classnames that should prevent this component
 * from closing if the clicked target is contained by any.
 */
const GLOBAL_IGNORED_CLASSNAMES = ['.ReactModalPortal'];

interface Props {
  /** The component's child elements to be rendered. */
  children: ReactElement;

  /** True when directional keys should invoke provided onClick function. */
  directionalKeys?: boolean;

  /** True when click is disabled. */
  disabled?: boolean;

  /** Elements that should ignore click. */
  ignoredElements?: HTMLElement[];

  /** The function that is invoked when click or key pressed occurs. */
  onClick: (event?) => void;

  /** True when click event does not propagate up to other event handlers. */
  stopPropagation?: boolean;
}

/**
 * Component enhancer that provides click outside detection, and ESC key to trigger click.
 * Directional keys optional.
 */
const OutsideClick = ({ children, directionalKeys, disabled, ignoredElements, onClick, stopPropagation }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * onKeyDown
   */
  const onKeyDown = useCallback(
    (e) => {
      const esc = 27; // ESC
      const directions = [37, 38, 39, 40]; // Left, up, right, down

      if (disabled) {
        return;
      }

      if (e.keyCode === esc || (directionalKeys && directions.includes(e.keyCode))) {
        onClick();
      }
    },
    [directionalKeys, disabled, onClick]
  );

  /**
   * onClickOutside
   */
  const onClickOutside = useCallback(
    (event) => {
      const isIgnoredItem =
        ignoredElements?.find((e) => e?.contains(event.target)) ||
        GLOBAL_IGNORED_CLASSNAMES?.find((className) => document.querySelector(className)?.contains(event.target));

      if (disabled) {
        return;
      }

      if (stopPropagation) {
        event.stopPropagation();
      }

      if (containerRef.current && !containerRef.current?.contains(event.target) && !isIgnoredItem) {
        onClick();
      }
    },
    [disabled, ignoredElements, stopPropagation, onClick]
  );

  /**
   * Listen for mousedown/keydown events
   */
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [disabled, onClickOutside, onKeyDown]);

  return <div ref={containerRef}>{children}</div>;
};

export default OutsideClick;
