import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import InventoryItem from 'constants/inventoryItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { Auction } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { auctionItemSellInternal } from 'store/shared/api/graph/mutations/auctionItemSellInternal';
import { getVehiclePath } from 'components/sections/admin/adminHelpers';
import { t } from 'utils/intlUtils';

interface Props extends RouterProps {
  /** A collection of auctions which have sell internally enabled. */
  auctions: Auction[];
  /** The inventory item details. */
  inventoryItem: InventoryItem;
}

interface State {
  /** Validation errors. */
  errorMessages: ErrorMessages | undefined;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when form is submitting. */
  isSubmitting: boolean;
}

class SellInternally extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit, options) => {
    if (shouldSubmit) {
      const { router, inventoryItem } = this.props;

      const formattedOptions = {
        ...options,
        inventoryItemId: inventoryItem.id,
      };

      this.setState({ isSubmitting: true });
      auctionItemSellInternal(formattedOptions)
        .then((response) => {
          const auctionItem = response?.data?.data?.auctionItemSellInternal;
          const vehiclePath = getVehiclePath(auctionItem);
          this.setState({ errorMessages: undefined, isOpen: false, isSubmitting: false });
          router.push(vehiclePath);
        })
        .catch(this.onApiError)
        .finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: undefined, isOpen: false });
    }
  };

  render() {
    const { auctions, inventoryItem } = this.props;

    return (
      <>
        <OperationButton glyph={submitGlyph} onClick={() => this.setState({ isOpen: true })} theme="green">
          {t('sell_internally')}
        </OperationButton>
        <Dialog auctions={auctions} inventoryItemId={inventoryItem.id} onSubmit={this.onSubmit} {...this.state} />
      </>
    );
  }
}

export default withRouter(SellInternally);
