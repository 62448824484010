import { HTMLAttributes } from 'react';

import { getGraphBaseURL } from 'utils/apiUtils';

interface Props extends HTMLAttributes<HTMLImageElement> {
  /** Height of barcode image */
  height?: number;
  /** VIN number */
  vin: string;
  /** Width of barcode image */
  width?: number;
}

const VINBarcode = ({ vin, width = 200, height = 30, ...props }: Props) => (
  <img alt="vin barcode" {...props} src={`${getGraphBaseURL()}/barcode/code_39/w_${width},h_${height}/${vin}.png`} />
);

export default VINBarcode;
