import { useMemo } from 'react';

import LiveLanesGlyph from 'glyphs/live-lanes.svg';

import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import { AuctionItem, AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { ListItemFooter, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { hasAuctionPermission } from 'utils/userUtils';
import { isUpcomingManual } from 'utils/auctionItemUtils';

import style from './auctionItemsListItemFooter.scss';

interface Props {
  /** The auction item that the footer belongs to. */
  auctionItem: AuctionItem;
  /** The current user.*/
  user: User;
}

const AuctionItemsListItemFooter = ({ auctionItem, user }: Props) => {
  /**
   * When auction item has IN_IF_BID status, the name of the user that claimed the IF bid.
   */
  const ifBidUserName = useMemo(() => {
    let userName;

    if (
      auctionItem?.status === AuctionItemStatus.IN_IF_BID &&
      auctionItem?.ifBidClaimedByUserName &&
      hasAuctionPermission(user, auctionItem?.auction?.id, ['AUCTION_IF_BIDS_CLAIM'])
    ) {
      userName = auctionItem?.ifBidClaimedByUserName;
    }

    return userName;
  }, [auctionItem?.auction?.id, auctionItem?.ifBidClaimedByUserName, auctionItem?.status, user]);

  /**
   * A conditional label/logo that is rendered at the end of the footer.
   */
  const auxLabel = useMemo(() => {
    if (ifBidUserName)
      return (
        <TertiaryTitleLight className={style.rightText} dataTestId="list-item-footer-if-bid-name">
          {ifBidUserName}
        </TertiaryTitleLight>
      );

    if (isUpcomingManual(auctionItem)) {
      return (
        <div data-testid="live-lanes-logo">
          <Sprite className={style.liveLanesLogo} glyph={LiveLanesGlyph} />
        </div>
      );
    }

    return null;
  }, [auctionItem, ifBidUserName]);

  return (
    <ListItemFooter className={style.footer}>
      <TertiaryTitleLight>{auctionItem?.auction?.title}</TertiaryTitleLight>
      {auxLabel}
    </ListItemFooter>
  );
};

export default AuctionItemsListItemFooter;
