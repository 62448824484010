import { createAction } from 'redux-actions';

import { getInventoryItemsList } from './inventoryItemsListApi';
import { getOptionsWithRequestSequence, parseQueryParams } from 'utils/apiUtils';

export const inventoryItemsListIsLoading = createAction('INVENTORY_ITEMS_SET_IS_LOADING');
export const inventoryItemsListLoaded = createAction('INVENTORY_ITEMS_LIST_LOADED');
export const inventoryItemsListClear = createAction('INVENTORY_ITEMS_LIST_CLEAR');
export const inventoryItemsListUpdate = createAction('INVENTORY_ITEMS_LIST_UPDATE');

export const processGetInventoryItemsList = (options, dispatch) => {
  dispatch(inventoryItemsListIsLoading());
  return getInventoryItemsList(parseQueryParams(options)).then((response) => {
    const formattedResponse = getOptionsWithRequestSequence(response, response?.data?.data);
    return dispatch(inventoryItemsListLoaded(formattedResponse));
  });
};

export const processClearInventoryItemsList = (dispatch) => {
  dispatch(inventoryItemsListClear());
};

export const processUpdateInventoryItemsList = (options, dispatch) => {
  dispatch(inventoryItemsListUpdate());
  return getInventoryItemsList(parseQueryParams(options)).then((response) => {
    const formattedResponse = getOptionsWithRequestSequence(response, response?.data?.data);
    return dispatch(inventoryItemsListLoaded(formattedResponse));
  });
};
