/* eslint-disable import/no-import-module-exports */
import invariant from 'invariant';
import promiseMiddleware from 'redux-promise';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { History } from 'history';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import rootReducer from './rootReducer';

// A mutable instance of our store; exposed in `getStore()`
let store: ReturnType<typeof configureStore>;

const configureStore = (browserHistory: History, initialState = {}) => {
  invariant(rootReducer, 'Root Reducer is missing!');

  const reducer = combineReducers({ app: rootReducer, routing: routerReducer });

  // Attach middleware for app.
  const middleware = applyMiddleware(thunk, promiseMiddleware, routerMiddleware(browserHistory));
  const createdStore = createStore(reducer, initialState, composeWithDevToolsDevelopmentOnly(middleware));

  store = createdStore;

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../store/rootReducer', () => {
      store.replaceReducer(reducer);
    });
  }

  return createdStore;
};

/**
 * Infer the `AppState` and `AppDispatch` types from the store itself
 */
export type AppGetState = typeof store.getState;
export type AppState = ReturnType<AppGetState>;
export type AppDispatch = ThunkDispatch<AppState, void, AnyAction>;

/**
 * Gets an instance of the store; should only be referenced in "Service" classes
 */
export const getStore = () => store;

export default configureStore;
