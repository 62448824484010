import { MouseEvent, MouseEventHandler, useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import ComparablesSlideOut from './comparablesSlideOut';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import DetailsDialog from 'components/sections/inventoryItem/details/comparables/detailsDialog';
import { DetailsSection, DetailsSectionAuxButton } from 'layouts/list/listLayout';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { UserAction } from 'logging/analytics/events/userActions';
import { getComparables } from 'store/shared/api/graph/queries/comparables';
import { onApiError } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './comparables.scss';

interface Props {
  /** ID of the component. */
  id?: string;
  /** The inventory item id that the comparables belongs to. */
  inventoryItemId: string;
  /** Function invoked on view comparables link click. */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const Comparables = ({ id, inventoryItemId, onClick }: Props) => {
  const [comparableAuctionItems, setComparableAuctionItems] = useState<AuctionItem[]>([]);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState<boolean>(false);
  const [selectedComparable, setSelectedComparable] = useState<AuctionItem | undefined>(undefined);

  /**
   * Fetch comparables by sold date sorting order
   */
  const fetchComparables = useCallback(
    (soldDateDesc: boolean) => {
      getComparables({ inventoryItemId, soldDateDesc })
        ?.then((response) =>
          setComparableAuctionItems(response?.data?.data?.inventoryItem?.comparables?.list?.filter(Boolean) || [])
        )
        ?.catch((error) => onApiError(error, setErrorMessages))
        ?.finally(() => setIsLoading(false));
    },
    [inventoryItemId]
  );

  /**
   * Open slideOut
   */
  const onOpenSlideOut = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      setIsSlideOutOpen(true);
      setIsLoading(true);
      fetchComparables(true);
      onClick?.(event);
    },
    [fetchComparables, onClick]
  );

  /**
   * Close slideOut
   */
  const onCloseSlideOut = useCallback(() => {
    setIsSlideOutOpen(false);
  }, []);

  /**
   * Handle on list item click
   */
  const onListItemClick = useCallback(
    (comparableId) => {
      const comparableAuctionItem = comparableAuctionItems.find((comparable) => comparable.id === comparableId);
      setSelectedComparable(comparableAuctionItem);
      setIsDetailsDialogOpen(true);
      trackUserActionWithAuctionItemAttributes(
        UserAction.COMPARABLES_VIEW_VEHICLES_DETAILS_CLICK,
        comparableAuctionItem
      );
    },
    [comparableAuctionItems]
  );

  /**
   * Handle on close details dialog
   */
  const onCloseDetailsDialog = useCallback(() => {
    setIsDetailsDialogOpen(false);
  }, []);

  /**
   * Handle on confirm error dialog
   */
  const onConfirmErrorDialog = useCallback(() => {
    setErrorMessages([]);
    setIsSlideOutOpen(false);
  }, []);

  return (
    <>
      <DetailsSection
        hasContentPadding={false}
        headerEndContent={<DetailsSectionAuxButton label={t('view_vehicles')} onClick={onOpenSlideOut} />}
        id={id}
        title={t('comparables')}
      />
      <ComparablesSlideOut
        auctionItems={comparableAuctionItems}
        fetchComparables={fetchComparables}
        isClickOutsideDisabled={isDetailsDialogOpen}
        isLoading={isLoading}
        isOpen={isSlideOutOpen}
        onClose={onCloseSlideOut}
        onListItemClick={onListItemClick}
      />
      {selectedComparable && (
        <DetailsDialog auctionItem={selectedComparable} isOpen={isDetailsDialogOpen} onClose={onCloseDetailsDialog} />
      )}
      <ConfirmDialog
        isOpen={!!errorMessages.length}
        onConfirm={onConfirmErrorDialog}
        theme="red"
        title={t('error')}
        titleClassName={style.errorTitle}
      >
        <FormErrors errorMessages={errorMessages} isSmallDialog />
      </ConfirmDialog>
    </>
  );
};

export default Comparables;
