/*
 * Format numerical french float to standard float:
 *
 * Examples: "3,2" becomes 3.2
 *           "0,6" becomes 0.6
 *
 * @param value {string|number}
 * @returns {number}
 */
import { formatNumber } from 'utils/stringUtils';

export const formatStandardFloat = (value) => {
  return value ? Number(value.toString().replace(',', '.')) : value;
};

/**
 * Convert kilometers to miles
 *
 * Example: 1 => 0.621371
 *
 * @param km {number}
 * @returns {number}
 */
export const kmToMi = (km) => Math.round(km * 0.621371);

/**
 * Convert miles to kilometers
 *
 * Example: 1 => 1.60934
 *
 * @param mi {number}
 * @returns {number}
 */
export const miToKm = (mi) => Math.round(mi * 1.60934);

/**
 * Checks if US mileage conversion is required, and returns a rounded value.
 *
 * Examples: getMileage(750.1231) => 750
 *           getMileage(750.1231, 'MI') => 466
 *           getMileage(750.1231, 'MI', true) => 750
 *
 * @param mileage {number}
 * @param mileageUnit {string}
 * @param skipConversion {boolean}
 */
export const getMileage = (mileage, mileageUnit = 'km', skipConversion = false) => {
  if (!skipConversion && mileageUnit.toLowerCase() === 'mi') {
    return Math.round(kmToMi(mileage));
  }
  return Math.round(mileage);
};

/**
 * Same as `getMileage()`, but formats number based on user's locale
 *
 * @param mileage {number}
 * @param mileageUnit {string}
 * @param skipConversion {boolean}
 */
export const getMileageFormatted = (mileage, mileageUnit = 'km', skipConversion = false) => {
  return formatNumber(getMileage(mileage, mileageUnit, skipConversion));
};

/**
 * Extracts a formatted number's value (`formattedAmount` -> `amount`).
 *
 * Examples:  getFormattedAmount(US$45,456) => 45456
 *            getFormattedAmount(US$45,456.12) => 45456.12
 *
 * @param formattedAmount {string}
 */
export const getFormattedAmount = (formattedAmount: string | undefined) => {
  if (formattedAmount) {
    return Number(formattedAmount.replace(/[^\d.-]/g, ''));
  }
  return undefined;
};

/**
 * Formats a number as a dollar amount with a '+' or '-' sign.
 *
 * @param {number} amount - The number to format.
 * @returns {string} - The formatted dollar amount.
 *
 * @example formatSignedDollarAmount(12) // '+$12'
 * @example formatSignedDollarAmount(-12345.67) // '-$12,345.67'
 */
export function formatSignedDollarAmount(amount: number): string {
  if (amount === 0) {
    return '$0';
  }
  const sign = amount < 0 ? '-' : '+';
  return `${sign}$${formatNumber(Math.abs(amount))}`;
}
