import DOMPurify from 'dompurify';

import { t } from 'utils/intlUtils';
import { LooseObject } from 'constants/objects';

interface Props {
  args?: any[];
  namespace: string;
  options?: LooseObject;
}

/**
 * Similar to React-i18n's Trans component, a utility component to interpolate JSX markup into our translations.
 *
 * @example:
 * <InterpolatedTranslation namespace="x_bar" args={['<em>foo</em>']} /> // "<em>bar</em> foo"
 */
const InterpolatedTranslation = ({ namespace, args = [], options = {} }: Props) => {
  const translation = t(namespace, args, options);
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(translation, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] }),
      }}
    />
  );
};

export default InterpolatedTranslation;
