import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import { merge } from 'lodash-es';

import { AuctionItemStatus, FacetGroup as FacetGroupType } from 'store/shared/api/graph/interfaces/types';
import { FacetGroup, PageInfo } from 'store/shared/models/graph/connections';
import { InitialState } from 'store/myBlock/list/myBlockModels';
import { MY_BLOCK_BUYER_ENABLED_STATUSES } from 'containers/myBlock/myBlockBuyerContainer';
import { MY_BLOCK_SELLER_ENABLED_STATUSES } from 'containers/myBlock/myBlockSellerContainer';
import {
  myBlockBuyerListLoaded,
  myBlockBuyerStatsLoaded,
  myBlockListCleared,
  myBlockListIsLoading,
  myBlockSellerListLoaded,
  myBlockSellerStatsLoaded,
  myBlockUpdateAuctionItem,
  myBlockUpdateAuctionItemValues,
} from 'store/myBlock/list/myBlockActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';

export const myBlockReducer = handleActions(
  {
    [myBlockListCleared().type]: () => new InitialState(),

    [myBlockListIsLoading().type]: (state) => state.set('isLoading', true),

    [myBlockSellerListLoaded().type]: (state, action) => {
      const enabledStatuses = MY_BLOCK_SELLER_ENABLED_STATUSES;
      const facetGroups: FacetGroupType[] | undefined = action?.payload?.facetGroups;
      const filteredFacetGroups =
        facetGroups?.map((facetGroup) => {
          if (facetGroup?.name === 'status') {
            const filteredStatusFacets = facetGroup?.facets?.filter((facet) =>
              enabledStatuses?.includes(facet?.name as AuctionItemStatus)
            );
            const isAllStatusSelected = filteredStatusFacets.every((statusFacet) => statusFacet.selected);

            return {
              ...facetGroup,
              facets: filteredStatusFacets.map((facet) =>
                isAllStatusSelected ? { ...facet, selected: false } : facet
              ),
            };
          }
          return facetGroup;
        }) ?? [];

      return state?.setLoaded()?.merge({
        facetGroups: List(filteredFacetGroups.map((facetGroup) => new FacetGroup(facetGroup))),
        pageInfo: new PageInfo(action?.payload?.pageInfo),
        resultList: List(parseQueryConnectionResponse(action?.payload) || []),
      });
    },

    [myBlockBuyerListLoaded().type]: (state, action) => {
      const enabledStatuses = MY_BLOCK_BUYER_ENABLED_STATUSES;
      const facetGroups: FacetGroupType[] | undefined = action?.payload?.facetGroups;
      const filteredFacetGroups =
        facetGroups?.map((facetGroup) => {
          if (facetGroup?.name === 'status') {
            const filteredStatusFacets = facetGroup?.facets?.filter((facet) =>
              enabledStatuses?.includes(facet?.name as AuctionItemStatus)
            );
            const isAllStatusSelected = filteredStatusFacets.every((statusFacet) => statusFacet.selected);

            return {
              ...facetGroup,
              facets: filteredStatusFacets?.map(({ selected, ...facet }) => {
                // Rename 'Sold' to 'Purchased'
                const localizedName = facet?.name === 'SOLD' ? t('purchased') : facet.localizedName;
                return { ...facet, localizedName, selected: isAllStatusSelected ? false : selected };
              }),
            };
          }
          return facetGroup;
        }) ?? [];

      return state?.setLoaded()?.merge({
        facetGroups: List(filteredFacetGroups.map((facetGroup) => new FacetGroup(facetGroup))),
        pageInfo: new PageInfo(action?.payload?.pageInfo),
        resultList: List(parseQueryConnectionResponse(action?.payload) || []),
      });
    },

    [myBlockSellerStatsLoaded().type]: (state, action) => {
      return state?.set('sellerStats', action?.payload);
    },

    [myBlockBuyerStatsLoaded().type]: (state, action) => {
      return state?.set('buyerStats', action?.payload);
    },

    [myBlockUpdateAuctionItem().type]: (state, action) => {
      const auctionItemNext = action?.payload;
      const itemIndex = state.resultList.findIndex((item) => item?.id === auctionItemNext?.id);

      if (itemIndex === -1) {
        // Nothing to update; return previous state
        return state;
      }

      const existingItem = state.resultList.get(itemIndex);
      const updatedItem = merge({}, existingItem, {
        ...auctionItemNext,
        ...(auctionItemNext?.idNext && { id: auctionItemNext?.idNext }),
      });
      return state.merge({ resultList: state.resultList.set(itemIndex, updatedItem) });
    },

    [myBlockUpdateAuctionItemValues().type]: (state, action) => {
      const itemIndex = state.resultList.findIndex((item) => item?.id === action?.payload?.auctionItemId);

      if (itemIndex === -1) {
        // Nothing to update; return previous state
        return state;
      }

      const existingItem = state.resultList.get(itemIndex);
      const updatedItem = merge({}, existingItem, { inventoryItem: { values: action?.payload?.values } });
      return state.merge({ resultList: state.resultList.set(itemIndex, updatedItem) });
    },
  },
  new InitialState()
);
