import classnames from 'classnames';
import { debounce } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Checkbox from 'forms/shared/checkbox';
import Select, { SelectProps } from 'forms/shared/select';
import { Location } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { customOptionFiltering } from 'utils/selectUtils';
import { getLocations } from 'store/shared/api/graph/queries/locations';
import { parseQueryConnectionResponse, parseQueryParams } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';

import selectStyles from './select.scss';
import style from './selectLocations.scss';

interface Props extends Omit<SelectProps, 'defaultValue'> {
  className?: string;
  /** The current company's id; used to fetch the company's locations */
  companyId?: string;
  /** Add default variable values (IE: type:HOLDING - which queries only holding companies) */
  connectionVariables?: object;
  /** The id of the default selected location. */
  defaultId?: string;
  /** The default selected location */
  defaultValue?: Location | null;
  /** A callback function for when the location changes */
  onLocationChange?(location?: Location): void;
  /** A callback function for when the pickup location changes */
  onPickupLocationChange?(location?: Location): void;
  /** Whether to show compound checkbox toggle */
  showCompoundSelectionOption?: boolean;
}

interface State {
  /** True when compound selection is enabled */
  isCompoundListEnabled: boolean;
  /** The locations available for the dropdowns */
  locations: Location[] | undefined;
  /** The selected location */
  selectedLocation: SelectOption<Location> | undefined;
  /** The selected pickup location */
  selectedPickupLocation: SelectOption<Location> | undefined;
}

class SelectLocations extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isCompoundListEnabled: false,
      selectedLocation: this.formatSelectOption(props?.defaultValue),
      locations: [],
      selectedPickupLocation: undefined,
    };

    if (props?.companyId) {
      this.getLocations();
    }
  }

  static defaultProps = {
    connectionVariables: {},
    onLocationChange: () => {},
    onPickupLocationChange: () => {},
    showCompoundSelectionOption: true,
  };

  componentDidUpdate(prevProps, prevState) {
    const { companyId: companyIdPrev } = prevProps;
    const { locations: locationsPrev } = prevState;
    const { companyId, defaultId } = this.props;
    const { locations, selectedLocation } = this.state;

    if (companyId && companyId !== companyIdPrev) {
      // The company changed; reset location selections and fetch the new company's locations
      this.setState({ selectedLocation: undefined, selectedPickupLocation: undefined });
      this.onLocationChange();
      this.onPickupLocationChange();
      this.getLocations();
    }

    // After locations load, set the selected location by default id
    if (!selectedLocation && defaultId && locationsPrev.length === 0 && !!locations?.length) {
      const location = locations?.find((l) => l.id === defaultId);
      this.props.onLocationChange?.(location);
      this.setState({ selectedLocation: this.formatSelectOption(location) });
    }
  }

  /**
   * Formats a Location object to a SelectOption<Location>
   */
  formatSelectOption = (location: Location | undefined): SelectOption<Location> | undefined =>
    location
      ? {
          label: location.name,
          value: location,
        }
      : undefined;

  /**
   * Fetch the available locations of the current company
   *
   * @param {string} keyword
   */
  getLocations = (keyword = '') => {
    const { companyId, connectionVariables } = this.props;
    const options = { keyword, consignerId: companyId ? [companyId] : undefined, ...connectionVariables };

    getLocations(parseQueryParams(options))
      .then((response) =>
        this.setState({ locations: parseQueryConnectionResponse(response?.data?.data?.locationConnection) })
      )
      .catch(() => {
        // Query error
      });
  };

  /**
   * A throttled callback that fetches the current company's locations, filtered by input keyword search
   */
  onInputChange = debounce((keyword, inputActionMeta) => {
    if (this._isMounted && inputActionMeta.action === 'input-change') {
      this.getLocations(keyword);
    }
  }, 250);

  /**
   * The location has changed. Set updated value in state and notify parent component of the change
   */
  onLocationChange = (selectedLocation?: SelectOption<Location>) => {
    this.setState({ selectedLocation });
    this.props.onLocationChange?.(selectedLocation?.value);
  };

  /**
   * The pickup location has changed. Set updated value in state and notify parent component of the change
   */
  onPickupLocationChange = (selectedLocation?: SelectOption<Location>) => {
    this.setState({ selectedPickupLocation: selectedLocation });
    this.props.onPickupLocationChange?.(selectedLocation?.value);
  };

  render() {
    const { className, companyId, isDisabled, showCompoundSelectionOption } = this.props;
    const { locations, selectedLocation, selectedPickupLocation, isCompoundListEnabled } = this.state;

    const options: SelectOption<Location>[] | undefined = locations
      ?.map((location) => this.formatSelectOption(location))
      ?.filter(Boolean);

    return (
      <>
        <Select
          theme="green"
          {...this.props}
          className={classnames(selectStyles.selectInput, className)}
          filterOption={customOptionFiltering}
          id="select-location"
          isDisabled={!companyId || isDisabled}
          onChange={this.onLocationChange}
          onInputChange={this.onInputChange}
          options={options}
          placeholder={t('choose_location')}
          value={selectedLocation}
        />

        {showCompoundSelectionOption && (
          <Checkbox
            checked={isCompoundListEnabled}
            className={style.checkbox}
            disabled={!companyId || isDisabled}
            id="pickupLocationsEnabled"
            onChange={(e) => {
              this.setState({ isCompoundListEnabled: e.target.checked });
              if (!e.target.checked) {
                this.onPickupLocationChange(undefined);
              }
            }}
          >
            {t('transport_to_compound')}
          </Checkbox>
        )}

        {showCompoundSelectionOption && isCompoundListEnabled && (
          <Select
            theme="green"
            {...this.props}
            className={classnames(selectStyles.selectInput, style.compoundSelect)}
            filterOption={customOptionFiltering}
            id="selectCompound"
            isDisabled={!companyId || isDisabled}
            onChange={this.onPickupLocationChange}
            options={options?.filter((option) => option?.value?.public)}
            placeholder={t('choose_compound_location')}
            value={selectedPickupLocation}
          />
        )}
      </>
    );
  }
}

export default SelectLocations;
