import { Dispatch, SetStateAction, useCallback } from 'react';

import { AccountVerificationStepProps } from 'forms/account/accountVerification/steps/accountVerificationHelpers';

interface Props {
  /** The current form step */
  formData: AccountVerificationStepProps['formData'];
  /** Callback function to set `setIsConfirmCloseDialogOpen` */
  setIsConfirmCloseDialogOpen: Dispatch<SetStateAction<boolean>>;
  /** Callback function to set `setIsConfirmUnverifiedDialogOpen` */
  setIsConfirmUnverifiedDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const useOnRequestClose = ({ formData, setIsConfirmCloseDialogOpen, setIsConfirmUnverifiedDialogOpen }: Props) =>
  useCallback(() => {
    if (formData && Object.keys(formData).length) {
      // We can assume since there is persisted form data in state,
      // that the user is attempting to close with unverified information
      setIsConfirmUnverifiedDialogOpen(true);
      return;
    }

    // Show default confirm close dialog
    setIsConfirmCloseDialogOpen(true);
  }, [formData, setIsConfirmCloseDialogOpen, setIsConfirmUnverifiedDialogOpen]);
