import { Fragment } from 'react';

import { isURL, isEmail, isPhoneNumber } from 'utils/stringUtils';
import Link from './link';

interface Props {
  /** The text to be displayed. */
  children: string;

  /** CSS styling to overwrite the default style. */
  className?: string;

  /** CSS styling to overwrite the default style of the link. */
  linkClassName?: string;
}

/**
 * This component makes urls, emails, and phone numbers clickable.
 */
const InteractiveText = ({ children: text, className, linkClassName }: Props) => {
  return (
    <span className={className}>
      {text?.split?.('\n')?.map((line, lineKey) => {
        const processedLine = line?.split?.(/\s/)?.map((part, key) => {
          if (isURL(part)) {
            const label = `${part?.split?.('?')?.[0]} `;
            return (
              <Link
                key={`${part}-${key}`}
                className={linkClassName}
                target="_blank"
                to={`${isEmail(part) ? 'mailto:' : ''}${part}`}
              >
                {label}
              </Link>
            );
          }
          if (isPhoneNumber(part)) {
            return (
              <Link key={`${part}-${key}`} className={linkClassName} target="_blank" to={`tel:${part}`}>
                {`${part} `}
              </Link>
            );
          }
          return `${part || '\n'} `;
        });

        return (
          <Fragment key={`${line}-${lineKey}`}>
            {processedLine}
            <br />
          </Fragment>
        );
      })}
    </span>
  );
};

export default InteractiveText;
