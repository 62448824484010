import { Key as KeyEnum } from 'ts-key-enum';

/**
 * Alphabets
 */
enum AlphabetKeys {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',

  a = 'a',
  b = 'b',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  j = 'j',
  k = 'k',
  l = 'l',
  m = 'm',
  n = 'n',
  o = 'o',
  p = 'p',
  q = 'q',
  r = 'r',
  s = 's',
  t = 't',
  u = 'u',
  v = 'v',
  w = 'w',
  x = 'x',
  y = 'y',
  z = 'z',
}

/**
 * Numbers
 */
enum NumberKeys {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Zero = '0',
}

/**
 * Special Characters
 */
enum SpecialKeys {
  // TODO: Add more when required
  Period = '.',
  Minus = '-',
  Plus = '+',
  Space = ' ',
}

export const Key = { ...AlphabetKeys, ...NumberKeys, ...SpecialKeys, ...KeyEnum };

export type KeyType = (typeof Key)[keyof typeof Key];
