import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import copyGlyph from 'glyphs/copyFile.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { ChevronFilled } from 'components/ui/shared/chevrons';
import { t } from 'utils/intlUtils';

import style from './copyToClipboardButton.scss';

interface Props {
  /** CSS stying to overwrite default style */
  className?: string;
  /** Function invoked on button click */
  onClick?: () => void;
  /** Text displayed after button clicked */
  successMessageText?: string;
  /** Text that is copied to the clip board */
  textToCopy: string;
  /** Sets the html `title` attribute. */
  title?: string;
}

const CopyToClipboardButton = ({
  className,
  onClick,
  successMessageText = t('copied_success_message'),
  textToCopy,
  title = t('click_to_copy'),
}: Props) => {
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState<boolean>(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  const onCopy = useCallback(() => {
    navigator?.clipboard
      ?.writeText(textToCopy)
      ?.then(() => {
        setIsSuccessMessageVisible(true);
        timeoutRef.current = window.setTimeout(() => setIsSuccessMessageVisible(false), 1500);
        onClick?.();
      })
      ?.catch((error) => {
        /* Catch error */
      });
  }, [onClick, setIsSuccessMessageVisible, textToCopy]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    };
  }, []);

  return (
    <Button className={style.container} dataTestId="copyClipBoardButton" onClick={onCopy} theme="none" title={title}>
      <Sprite className={classnames(style.sprite, className)} glyph={copyGlyph} />
      {isSuccessMessageVisible && (
        <div className={style.successMessage}>
          <ChevronFilled className={style.chevron} />
          <span>{successMessageText}</span>
        </div>
      )}
    </Button>
  );
};

export default CopyToClipboardButton;
