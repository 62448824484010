import classnames from 'classnames';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import searchGlyph from 'glyphs/search.svg';
import closeGlyph from 'glyphs/clear-outlined.svg';

import Button from 'components/ui/shared/button';
import Sprite, { Glyph } from 'components/ui/shared/sprite';
import raf from 'utils/raf';
import { Spinner } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './searchInput.scss';

export interface SearchInputRef {
  /** Set input value */
  setValue: (value?: string) => void;
  /** Focus input */
  focus: (options?: FocusOptions) => void;
}

interface SearchInputProps {
  /** CSS styles to overwrite the style of the aux container. */
  auxContainerClassName?: string;
  /** CSS styles to overwrite the style of the search input. */
  className?: string;
  /** The default value of the search input. */
  defaultValue?: string;
  /** The search input icon. */
  glyph?: Glyph;
  /** CSS styles to overwrite the style of the input. */
  inputClassName?: string;
  /** True when the search input is loading. */
  isLoading?: boolean;
  /** Callback invoked when the search input value changes. */
  onChange?: (e?) => void;
  /** Callback invoked when the search input key up event is triggered. */
  onKeyUp?: (e?) => void;
  /** Callback invoked when the search input is submitted. */
  onSubmit?: (value?: string) => void;
  /** The placeholder of the search input. */
  placeholder?: string;
}

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  (
    {
      auxContainerClassName,
      className,
      defaultValue,
      glyph = searchGlyph,
      inputClassName,
      isLoading = false,
      onChange = () => {},
      onKeyUp = () => {},
      onSubmit = () => {},
      placeholder = t('search_all'),
    },
    ref
  ) => {
    const [hasValue, setHasValue] = useState<boolean>(!!defaultValue);
    const inputRef = useRef<HTMLInputElement | null>();

    /**
     * onKeyUp
     */
    const onInputKeyUp = useCallback(
      (e) => {
        setHasValue(!!e?.target?.value);
        onKeyUp?.(e);
      },
      [onKeyUp]
    );

    /**
     * onClear
     */
    const onClear = useCallback(() => {
      raf(() => setHasValue(false));
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      onInputKeyUp?.(undefined);
      onChange?.();
    }, [onChange, onInputKeyUp]);

    /**
     * Set input value
     */
    const setValue = useCallback((value?: string) => {
      if (!value) {
        setHasValue(false);
      }
      if (inputRef.current) {
        setHasValue(!!value);
        inputRef.current.value = value || '';
      }
    }, []);

    /**
     * Expose search input methods
     */
    useImperativeHandle(
      ref,
      () => ({
        setValue,
        focus: (options?: FocusOptions) => inputRef.current?.focus(options),
      }),
      [setValue]
    );

    return (
      <div className={classnames(style.searchInput, className)}>
        <div className={style.searchIcon}>
          <Sprite className={style.sprite} glyph={glyph} />
        </div>
        <input
          ref={(input) => {
            inputRef.current = input;
          }}
          className={classnames(style.input, inputClassName)}
          data-testid="search-input"
          defaultValue={defaultValue}
          onChange={onChange}
          onKeyUp={onInputKeyUp}
          onSubmit={() => onSubmit?.(inputRef.current?.value)}
          placeholder={placeholder}
          type="search"
        />
        <div className={classnames(style.auxContainer, auxContainerClassName)}>
          {isLoading && <Spinner className={style.loading} />}
          {!isLoading && hasValue && (
            <Button className={style.cancelButton} dataTestId="search-input-cancel" onClick={onClear} theme="none">
              <span className={style.cancelButtonIcon}>
                <Sprite glyph={closeGlyph} />
              </span>
            </Button>
          )}
        </div>
      </div>
    );
  }
);

export default SearchInput;
