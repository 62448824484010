import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import {
  AuctionItemBuyNowInput,
  MutationauctionItemAcceptOfferArgs,
  MutationauctionItemChangeBuyerArgs,
  MutationauctionItemDeclineOfferArgs,
  MutationauctionItemMakeOfferArgs,
  MutationauctionItemSetSellerIntentArgs,
  MutationauctionItemTransferSaleArgs,
  QueryauctionItemArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionItemOrderFragment } from 'store/shared/api/graph/fragments/auctionItemOrder';
import { inventoryItemFees, inventoryItemValues } from 'store/shared/api/graph/schema/inventoryItem';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { repsFragment } from 'store/shared/api/graph/fragments/reps';
import { topOffer, transactionDetails } from 'store/shared/api/graph/schema/auctionItem';

// TODO: This doesn't need to be a function
export const getAuctionItemSchema = () => `
  id
  status
  format
  asIs
  archived
  isMyItem
  ranOn
  displayRunNumber
  deliveryDate
  dmvReg398Url
  checkoutExpiry
  colorScheme
  timerText
  timerEnd
  releaseStatus
  vehicleReleaseForm
  guaranteedAuctionPrice {
    amount
    formattedAmount
  }
  holdback {
    type
    amount
  }
  holdbackActive
  buyer {
    company {
      id
      name
      primaryLocation {
        ...location
      }
    }
    user {
      id
      firstName
      lastName
    }
  }
  hideSellerNameEnabled
  ${topOffer}
  timedOfferTimeline {
    offerThreads {
      amount {
        amount
        formattedAmountRounded
      }
      company {
        id
        name
      }
      comment
      created
      createdBy {
        id
        cellPhone
        email
        firstName
        lastName
      }
      id
      expiry
      status
    }
    waitingOnThem
    waitingOnYou
  }
  bidTimeline {
    count
    winning
    outbid
    list {
      id
      company {
        id
        name
      }
      amount {
        amount
        formattedAmountRounded
      }
      type
      created
      retracted
    }
  }
  buyNowPrice {
    amount
    formattedAmountRounded
  }
  startingBid {
    amount
    formattedAmountRounded
  }
  listPrice {
    amount
    currencyCode
    formattedAmountRounded
  }
  reserveMet
  reserve {
    amount
    currencyCode
    formattedAmountRounded
  }
  reserveType
  net {
    amount
    formattedAmountRounded
  }
  nextBidAmount {
    amount
    formattedAmountRounded
  }
  furtherBidIncrement {
    amount
    currencyCode
    formattedAmountRounded
  }
  watchers {
    count
    isWatched
  }
  auction {
    settings {
      dealerArbitrationEnabled
      saleLightsOverride
    }
    currencyCode
    featureFlagKeys
    id
    title
    type
    videoStreamConfig {
      accountId
      subscriptionToken
    }
  }
  auctionTimeSlot {
    id
    startTime
    finishTime
    status
  }
  auctionTimeSlotLane {
    controlType
    id
    name
  }
  autoBids {
    myAutoBid {
      createdBy {
        id
      }
      maxBid {
        formattedAmountRounded
      }
    }
    count
    list(first: 0) {
      active
      actionBy {
        id
        firstName
        lastName
      }
      company {
        name
      }
      created
      createdBy {
        id
        firstName
        lastName
        photoUrl(width: 100, height: 100)
      }
      id
      maxBid {
        formattedAmountRounded
      }
    }
  }
  ifBidClaimedByUserId
  ifBidClaimedByUserName
  ifBidTimeline {
    count
    ending
    awaitingMyResponse
    inIfBidWithCompany {
      id
      name
    }
    amountApart {
      formattedAmountRounded
    }
    list {
      id
      type
      company {
        id
        name
      }
      amount {
        formattedAmountRounded
      }
      comment
      created
      createdBy {
        id
        cellPhone
        email
        firstName
        lastName
      }
    }
  }
  isAssured
  buyerReps {
    ...reps
  }
  sellerReps {
    ...reps
  }
  sellerTier
  history {
    count
    topOffers {
      amount {
        formattedAmountRounded
      }
    }
    list {
      auction {
        status
        title
      }
      buyNowPrice {
        formattedAmount
      }
      ranOn
      format
      status
      reserve {
        formattedAmount
      }
      ${topOffer}
      totalBids
      totalOffers
    }
  }
  ${transactionDetails}
  transactionType
  arbitrationRequest {
    createdBy {
      id
    }
  }
  order {
    ...auctionItemOrder
  }
  saleLights
  sellerIntent {
    status
    name
  }
  workflowProcesses {
    created
    id
    name
    status
    statusColorScheme
    updated
  }
  inventoryItem {
    id
    isUpdatable
    ... on Vehicle {
      type:__typename
      asIsCause
      autocheckExceptions {
        date
        description
        stateCode
      }
      blueDriverScanData {
        blueDriverSmogScanEvent {
          result
        }
        blueDriverCodeScanEventsCount
        codeScanCompleted
        smogScanCompleted
      }
      autocheckExceptionsReceived
      selectedOptions: options(selected: true) {
        name
      }
      company {
        id
        numericId
        name
        auctionRelationships {
          list {
            auction {
              id
            }
          }
        }
        holdback {
          type
          amount
        }
        primaryLocation {
          regionCode
        }
        rolePermissionsEnabled
      }
      created
      createdBy {
        ...repUser
      }
      captured
      capturedBy {
        ...repUser
      }
      captureType
      verifiedBy
      year
      make
      model
      subModel
      notes {
        id
        content
        created
        createdBy {
          firstName
          lastName
        }
      }
      description
      trim
      vin
      stockNumber
      bodyType
      chargingCable
      numberOfDoors
      numberOfPassengers
      exteriorColor
      interiorColor
      transmission
      cylinders
      displacement
      driveTrain
      fuelType
      autoGradeScore
      autoGradeScoreData {
        autoGradeVehicleDamageCount
        autoGradeVehicleDamage {
            damageCodeRecord {
                id
                sectionNumber
                sectionName
                itemCode
                itemName
                damageName
                damageCode
                severityName
                severityCode
              }
              photos {
                id
                location
                ...photoFrag
              }
        }
      }
      photos(type: EXTERIOR) {
        ...photoFrag
      }
      undercarriagePhotos: photos(type: UNDERCARRIAGE) {
        ...photoFrag
      }
      interiorPhotos: photos(type: INTERIOR) {
        ...photoFrag
      }
      damagePhotos: photos(type: DAMAGE) {
        ...photoFrag
        location
      }
      auctionLocation {
        ...location
      }
      location {
        ...location
      }
      pickupLocation {
        ...location
      }
      conditionReport {
        ...conditionFrag
      }
      paintCondition {
        ...paintConditionFrag
      }
      tireCondition {
        ...tireConditionFrag
      }
      mileage {
        formattedAmount
      }
      lastKnownMileage {
        formattedAmount
      }
      lastKnownMileageDate
      drivetrainBuyerShield
      engineBuyerShield
      transmissionBuyerShield
      vehicleBuyerShield
      extensiveVehicleConditionScore
      exteriorNote
      exteriorRating
      interiorNote
      interiorRating
      legalSaleDate
      mechanicalDrivetrainNote
      mechanicalDrivetrainRating
      mechanicalEngineNote
      mechanicalEngineRating
      mechanicalNote
      mechanicalRating
      mechanicalTransmissionNote
      mechanicalTransmissionRating
      source
      tiresNote
      tiresRating
      transportDetails {
        id
        value
      }
      sellerNotes
      printableUrl
      ${inventoryItemFees}
      ${inventoryItemValues}
    }
  }
`;

export const auctionItemSchemaFragments = `
  fragment photoFrag on Photo {
    main: url (width: 800, height: 600)
    expanded: url (width: 1200, height: 768)
    thumb: url (width: 110, height: 110)
    tile: url (width: 360, height: 360)
  }

  ${locationFragment}
  ${repsFragment}
  ${auctionItemOrderFragment}

  fragment conditionFrag on VehicleConditionReport {
    overallConditionRating
    carfaxCanadaReportUrl
    carfaxCanadaClaimAmount {
      amount
      formattedAmountRounded
    }
    carfaxCanadaDisclosuresExist
    carfaxCanadaReportStatus
    repaintOnHowManyPanels
    activeDeclarations: declarations(selected: true) {
      id
      name
    }
    inactiveDeclarations: declarations(selected: false) {
      id
      name
    }
  }

  fragment paintConditionFrag on VehiclePaintCondition {
    hoodDepth
    driverFenderDepth
    driverFrontDoorDepth
    driverRearDoorDepth
    driverQuarterPanelDepth
    passengerFenderDepth
    passengerFrontDoorDepth
    passengerRearDoorDepth
    passengerQuarterPanelDepth
    trunkDepth
    roofDepth
  }

  fragment tireConditionFrag on VehicleTireCondition {
    tirePressureMonitoringSystem
    tirePressureMonitoringSystemPhotoUrl(width: 320, height: 190)
    fourMatchingTires
    afterMarketTires
    winterTires
    studdedWinterTires
    secondSetOfTires
    secondSetOfTiresHasRims
    generalCondition
    driverFrontBrand
    driverFrontSize
    driverFrontTread {
      formatted
    }
    driverRearBrand
    driverRearSize
    driverRearTread {
      formatted
    }
    passengerFrontBrand
    passengerFrontSize
    passengerFrontTread {
      formatted
    }
    passengerRearBrand
    passengerRearSize
    passengerRearTread {
      formatted
    }
  }
`;

export const getAuctionItemDetails = (options) => {
  return ApiRequest<{ auctionItem?: AuctionItem }>({
    data: {
      operationName: 'getAuctionItemDetails',
      query: `
        query ($auctionItemId: String!, $holdbackActive: Boolean = false) {
          auctionItem(auctionItemId: $auctionItemId, holdbackActive: $holdbackActive)  {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        holdbackActive: options.holdbackActive,
      },
    },
    seqKey: `auctionItem[${options.auctionItemId}]`,
  });
};

export const getAuctionItemWatchers = (options: QueryauctionItemArgs) => {
  return ApiRequest<{ auctionItem?: AuctionItem }>({
    data: {
      operationName: 'getAuctionItemWatchers',
      query: `
        query ($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId) {
            watchers {
              list {
                id
                companyRelationships {
                list {
                  company {
                    name
                  }
                }
               }
               firstName
               lastName
               photoUrl(width: 100, height: 100)
              }
            }
          }
        }
      `,
      variables: {
        auctionItemId: options.auctionItemId,
      },
    },
  });
};

export const getAuctionItemViewers = (options: QueryauctionItemArgs) => {
  return ApiRequest<{ auctionItem?: AuctionItem }>({
    data: {
      operationName: 'getAuctionItemViewers',
      query: `
        query ($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId) {
            viewers {
              id
              companyRelationships {
                list {
                  company {
                    name
                  }
                }
             }
             firstName
             lastName
             photoUrl(width: 100, height: 100)
            }
          }
        }
      `,
      variables: {
        auctionItemId: options.auctionItemId,
      },
    },
  });
};

export const setAuctionItemAutobid = (options) => {
  return ApiRequest<{ auctionItemSetAutoBid?: AuctionItem }>({
    data: {
      operationName: 'setAuctionItemAutobid',
      query: `
        mutation($auctionItemId: String!, $maxBidPrice: Int, $userId: String, $consignerId: String) {
          auctionItemSetAutoBid(auctionItemId: $auctionItemId, maxBidPrice: $maxBidPrice, userId: $userId, consignerId: $consignerId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        maxBidPrice: options.maxBidPrice,
        userId: options.userId,
        consignerId: options.consignerId,
      },
    },
  });
};

export const setAuctionItemWatching = (options) => {
  return ApiRequest<{ auctionItemSetWatching?: AuctionItem }>({
    data: {
      operationName: 'setAuctionItemWatching',
      query: `
        mutation($auctionItemId: String!, $watching: Boolean!) {
          auctionItemSetWatching(auctionItemId: $auctionItemId, watching: $watching) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        watching: options.watching,
      },
    },
  });
};

export const pullAuctionItem = (options) => {
  return ApiRequest<{ auctionItemPullFromAuction?: AuctionItem }>({
    data: {
      operationName: 'pullAuctionItem',
      query: `
        mutation($auctionItemId: String!) {
          auctionItemPullFromAuction(auctionItemId: $auctionItemId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
      },
    },
  });
};

export const inventoryItemShare = ({ auctionItemId, inventoryItemId }) => {
  return ApiRequest<{ inventoryItemShare: string }>({
    data: {
      operationName: 'inventoryItemShare',
      query: `
        mutation(
          $auctionItemId: String
          $inventoryItemId: String
        ) {
          inventoryItemShare(
            auctionItemId: $auctionItemId
            inventoryItemId: $inventoryItemId
          )
        }
      `,
      variables: {
        auctionItemId,
        inventoryItemId,
      },
    },
  });
};

export const arriveAuctionItem = (options) => {
  return ApiRequest<{ auctionItemArrived?: AuctionItem }>({
    data: {
      operationName: 'arriveAuctionItem',
      query: `
        mutation($auctionItemId: String!) {
          auctionItemArrived(auctionItemId: $auctionItemId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
      },
    },
  });
};

export const ifBidAccept = ({ auctionItemId, consignerId, userId, comment }) => {
  return ApiRequest<{ auctionItemIfBidAccept?: AuctionItem }>({
    data: {
      operationName: 'ifBidAccept',
      query: `
        mutation($auctionItemId: String!, $consignerId: String, $userId: String, $comment: String) {
          auctionItemIfBidAccept(auctionItemId: $auctionItemId, consignerId: $consignerId, userId: $userId, comment: $comment) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId,
        consignerId,
        userId,
        comment,
      },
    },
  });
};

export const ifBidCounter = (options) => {
  return ApiRequest<{ auctionItemIfBidCounter?: AuctionItem }>({
    data: {
      operationName: 'ifBidCounter',
      query: `
        mutation($auctionItemId: String!, $amount: Int!, $consignerId: String, $userId: String, $comment: String) {
          auctionItemIfBidCounter(auctionItemId: $auctionItemId, amount: $amount, consignerId: $consignerId, userId: $userId, comment: $comment) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        amount: options.amount,
        consignerId: options.consignerId,
        userId: options.userId,
        comment: options.comment,
      },
    },
  });
};

export const requestArbitration = (options) => {
  return ApiRequest<{ auctionItemRequestArbitration?: AuctionItem }>({
    data: {
      operationName: 'requestArbitration',
      query: `
        mutation($auctionItemId: String!, $consignerId: String, $content: String, $userId: String) {
          auctionItemRequestArbitration(auctionItemId: $auctionItemId, consignerId: $consignerId, content: $content, userId: $userId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: options,
    },
  });
};

export const submitBid = (options) => {
  return ApiRequest<{ auctionItemSubmitBid?: Pick<AuctionItem, 'id'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $amount: Float, $consignerId: String, $userId: String) {
          auctionItemSubmitBid(auctionItemId: $auctionItemId, amount: $amount, consignerId: $consignerId, userId: $userId) {
            id
          }
        }
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        amount: options.amount,
        consignerId: options.consignerId,
        userId: options.userId,
      },
    },
  });
};

export const setHoldback = (options) => {
  return ApiRequest<{ auctionItemSetHoldback?: Pick<AuctionItem, 'id' | 'holdback' | 'holdbackActive'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $type: AmountType, $amount: Float, $holdbackActive: Boolean) {
          auctionItemSetHoldback(auctionItemId: $auctionItemId, type: $type, amount: $amount, holdbackActive: $holdbackActive) {
            id
            holdback {
              type
              amount
            }
            holdbackActive
          }
        }
      `,
      variables: {
        auctionItemId: options.auctionItemId,
        type: options.type,
        amount: options.amount,
        holdbackActive: options.holdbackActive,
      },
    },
  });
};

export const forceReserveMet = (auctionItemId) => {
  return ApiRequest<{ auctionItemReserveMet?: Pick<AuctionItem, 'id'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemReserveMet(auctionItemId: $auctionItemId) {
            id
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const archiveAuctionItem = ({ auctionItemId = null, archive = null }) =>
  ApiRequest<{ auctionItemArchive?: Pick<AuctionItem, 'archived'> }>({
    data: {
      operationName: 'archiveAuctionItem',
      query: `
      mutation($auctionItemId: String!, $archive: Boolean!) {
        auctionItemArchive(auctionItemId: $auctionItemId, archive: $archive) {
          archived
        }
      }
    `,
      variables: {
        auctionItemId,
        archive,
      },
    },
  });

export const cancelIfBid = ({ auctionItemId = null, consignerId = null, userId = null, comment = null }) =>
  ApiRequest<{ auctionItemIfBidCancel?: Pick<AuctionItem, 'id'> }>({
    data: {
      operationName: 'cancelIfBid',
      query: `
      mutation(
        $auctionItemId: String!,
        $consignerId: String,
        $userId: String,
        $comment: String
      ) {
        auctionItemIfBidCancel(
          auctionItemId: $auctionItemId,
          consignerId: $consignerId,
          userId: $userId,
          comment: $comment
        ) {
          id
        }
      }
    `,
      variables: {
        auctionItemId,
        consignerId,
        userId,
        comment,
      },
    },
  });

export const cancelPendingDelivery = ({ auctionItemId = null }) =>
  ApiRequest<{ auctionItemCancelPendingDelivery?: Pick<AuctionItem, 'id'> }>({
    data: {
      operationName: 'cancelPendingDelivery',
      query: `
      mutation($auctionItemId: String!) {
        auctionItemCancelPendingDelivery(auctionItemId: $auctionItemId) {
          id
        }
      }
    `,
      variables: {
        auctionItemId,
      },
    },
  });

export const cancelSale = ({ auctionItemId = null }) =>
  ApiRequest<{ auctionItemCancelSale?: Pick<AuctionItem, 'id'> }>({
    data: {
      operationName: 'cancelSale',
      query: `
      mutation($auctionItemId: String!) {
        auctionItemCancelSale(auctionItemId: $auctionItemId) {
          id
        }
      }
    `,
      variables: {
        auctionItemId,
      },
    },
  });

export const transferSale = ({
  auctionItemId,
  consignerId = null,
  userId = null,
  amount = null,
  status = null,
  asIs = null,
}: MutationauctionItemTransferSaleArgs) =>
  ApiRequest<{ auctionItemTransferSale?: Pick<AuctionItem, 'id' | 'status' | 'format'> }>({
    data: {
      query: `
      mutation(
        $auctionItemId: String!,
        $consignerId: String,
        $userId: String,
        $amount: Int,
        $status: AuctionItemStatus,
        $asIs: Boolean
      ) {
        auctionItemTransferSale(
          auctionItemId: $auctionItemId,
          consignerId: $consignerId,
          userId: $userId,
          amount: $amount,
          status: $status,
          asIs: $asIs
        ) {
          id
          status
          format
        }
      }
    `,
      variables: {
        auctionItemId,
        consignerId,
        userId,
        amount,
        status,
        asIs,
      },
    },
  });

export const setAsIs = ({ auctionItemId = null, asIs = null }) =>
  ApiRequest<{ auctionItemSetAsIs?: Pick<AuctionItem, 'id' | 'asIs'> }>({
    data: {
      operationName: 'setAsIs',
      query: `
      mutation($auctionItemId: String!, $asIs: Boolean!) {
        auctionItemSetAsIs(auctionItemId: $auctionItemId, asIs: $asIs) {
          id
          asIs
        }
      }
    `,
      variables: {
        auctionItemId,
        asIs,
      },
    },
  });

export const transferRunningItem = ({
  auctionItemId = null,
  consignerId = null,
  locationId = null,
  pickupLocationId = null,
}) =>
  ApiRequest<{ auctionItemTransferRunning?: Pick<AuctionItem, 'id' | 'format' | 'status'> }>({
    data: {
      operationName: 'transferRunningItem',
      query: `
      mutation($auctionItemId: String!, $consignerId: String, $locationId: String, $pickupLocationId: String) {
        auctionItemTransferRunning(auctionItemId: $auctionItemId, consignerId: $consignerId, locationId: $locationId, pickupLocationId: $pickupLocationId) {
          id
          format
          status
        }
      }
    `,
      variables: {
        auctionItemId,
        consignerId,
        locationId,
        pickupLocationId,
      },
    },
  });

export const auctionItemChangeBuyer = ({ auctionItemId, companyId, userId }: MutationauctionItemChangeBuyerArgs) =>
  ApiRequest<{ auctionItemChangeBuyer: AuctionItem }>({
    data: {
      operationName: 'auctionItemChangeBuyer',
      query: `
        mutation ($auctionItemId: String!, $companyId: String!, $userId: String!) {
          auctionItemChangeBuyer(auctionItemId: $auctionItemId, companyId: $companyId, userId: $userId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables: {
        auctionItemId,
        companyId,
        userId,
      },
    },
  });

export const auctionItemSetSellerIntent = ({ auctionItemId, status }: MutationauctionItemSetSellerIntentArgs) =>
  ApiRequest<{ auctionItemSetSellerIntent: Pick<AuctionItem, 'id' | 'sellerIntent'> }>({
    data: {
      operationName: 'auctionItemSetSellerIntent',
      query: `
        mutation ($auctionItemId: String!, $status: AuctionItemStatus) {
          auctionItemSetSellerIntent(auctionItemId: $auctionItemId, status: $status) {
            id
            sellerIntent {
              status
              name
            }
          }
        }
      `,
      variables: {
        auctionItemId,
        status,
      },
    },
  });

export const buyNow = ({ auctionItemId, companyId, userId }: AuctionItemBuyNowInput) =>
  ApiRequest<{ auctionItemBuyNow?: Pick<AuctionItem, 'id'> }>({
    data: {
      operationName: 'buyNow',
      query: `
        mutation ($auctionItemId: String!, $companyId: String, $userId: String) {
          auctionItemBuyNow(input: { auctionItemId: $auctionItemId, companyId: $companyId, userId: $userId }) {
            id
          }
        }
      `,
      variables: {
        auctionItemId,
        companyId,
        userId,
      },
    },
  });

export const makeOffer = (variables: MutationauctionItemMakeOfferArgs) =>
  ApiRequest<{ auctionItemMakeOffer?: Pick<AuctionItem, 'id' | 'timedOfferTimeline'> }>({
    data: {
      operationName: 'makeOffer',
      query: `
        mutation ($input: MakeOfferInput!) {
          auctionItemMakeOffer(input: $input) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables,
    },
  });

export const acceptOffer = (variables: MutationauctionItemAcceptOfferArgs) =>
  ApiRequest<{ auctionItemAcceptOffer?: Pick<AuctionItem, 'id'> }>({
    data: {
      operationName: 'acceptOffer',
      query: `
        mutation ($companyId: String, $comment: String, $offerId: String!, $userId: String) {
          auctionItemAcceptOffer(companyId: $companyId, comment: $comment, offerId: $offerId, userId: $userId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables,
    },
  });

export const declineOffer = (variables: MutationauctionItemDeclineOfferArgs) =>
  ApiRequest<{ auctionItemDeclineOffer: AuctionItem }>({
    data: {
      operationName: 'declineOffer',
      query: `
        mutation ($offerId: String!, $userId: String, $companyId: String) {
          auctionItemDeclineOffer(offerId: $offerId, userId: $userId, companyId: $companyId) {
            ${getAuctionItemSchema()}
          }
        }
        ${auctionItemSchemaFragments}
      `,
      variables,
    },
  });

export const getAuctionItemPotentialBuyers = ({ auctionItemId }: Pick<QueryauctionItemArgs, 'auctionItemId'>) =>
  ApiRequest<{ auctionItem: Pick<AuctionItem, 'potentialBuyers'> }>({
    data: {
      operationName: 'getPotentialBuyers',
      query: `
        query ($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId) {
            potentialBuyers {
              companyRelationships {
                list {
                  company {
                    name
                  }
                }
              }
              firstName
              id
              lastName
              mainPhotoThumb: mainPhotoUrl(width: 100, height: 100)
            }
          }
        }
      `,
      variables: { auctionItemId },
    },
  });
