import { ReactElement, ReactNode } from 'react';

interface Props {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** True if the child elements should be rendered. */
  if?: boolean;
}

/**
 *   The first <Case> whose "if" attribute evaluates to true will be rendered. A
 *   <Case>'s "if" attribute is implicitly {true} when not specified which allows
 *   it to behave like a switch's default block.
 *
 *   Example:
 *
 *   <Switch>
 *     <Case if={status === 'init'}>
 *       <h1>Initial</h1>
 *     </Case>
 *     <Case if={status === 'running'}>
 *       <h1>Running</h1>
 *     </Case>
 *     <Case if={status === 'complete'}>
 *       <h1>Finished</h1>
 *     </Case>
 *     <Case>
 *       <h1>N/A</h1>
 *     </Case>
 *   </Switch>
 *
 *
 *   The <Case> can be used independently as well. For example:
 *
 *   <Case if={status === 'running'}>
 *     <p>
 *       The machine is running.
 *     </p>
 *   </Case>
 * @returns {React.ReactElement | null}
 */
export const Case = ({ children, if: ifProp = true }: Props) => {
  if (!ifProp) {
    return null;
  }

  const rendered: ReactElement[] = [];
  let hasCaseChild = false;
  (children instanceof Array ? children : [children]).forEach((c) => {
    if (!c) {
      return;
    }
    // Note: Do not check type by type.name, since it is stripped out on Production builds.
    if (c.type !== Case && c.type !== (<Case if>test</Case>).type) {
      rendered.push(c);
    } else if (!hasCaseChild && c.props.if !== false) {
      rendered.push(c);
      hasCaseChild = true;
    }
  });

  return <>{rendered}</>;
};

export const Switch = Case;
