import { useMemo } from 'react';

import InventoryItem from 'constants/inventoryItem';
import { t } from 'utils/intlUtils';

import style from './conditionReport.scss';

interface Props {
  /** The paint condition. */
  paintCondition: InventoryItem['paintCondition'];
}

const PaintConditionDetails = ({ paintCondition }: Props) => {
  const formattedPaintDetails = useMemo(() => {
    if (!paintCondition) {
      return null;
    }
    const {
      driverFenderDepth,
      driverFrontDoorDepth,
      driverQuarterPanelDepth,
      driverRearDoorDepth,
      hoodDepth,
      passengerFenderDepth,
      passengerFrontDoorDepth,
      passengerQuarterPanelDepth,
      passengerRearDoorDepth,
      roofDepth,
      trunkDepth,
    } = paintCondition;

    return [
      hoodDepth,
      driverFenderDepth,
      driverFrontDoorDepth,
      driverRearDoorDepth,
      driverQuarterPanelDepth,
      roofDepth,
      passengerFenderDepth,
      passengerFrontDoorDepth,
      passengerRearDoorDepth,
      passengerQuarterPanelDepth,
      trunkDepth,
    ].filter(Boolean);
  }, [paintCondition]);

  return paintCondition?.hoodDepth ? (
    <div className={style.crRow} data-testid="paint-condition-details">
      <div className={style.crRowTitle}>{t('paint')}</div>
      <div className={style.crRowContent}>
        <ul className={style.crRowList}>
          {formattedPaintDetails?.map((paintDetail) => <li key={paintDetail + Math.random()}>{paintDetail}</li>)}
        </ul>
      </div>
    </div>
  ) : null;
};

export default PaintConditionDetails;
