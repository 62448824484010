import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import StatusRow from 'components/sections/myBlock/list/status/statusRow';
import { AppDispatch } from 'store/configureStore';
import {
  InventoryItemValueTypeEnum,
  MutationinventoryItemValueCreateArgs,
  MutationinventoryItemValueUpdateArgs,
} from 'store/shared/api/graph/interfaces/types';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getErrors } from 'utils/apiUtils';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { myBlockUpdateAuctionItem } from 'store/myBlock/list/myBlockActions';
import { processSaveInventoryItemValues } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

import style from './statusRowCost.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  saveValues: (options: MutationinventoryItemValueCreateArgs | MutationinventoryItemValueUpdateArgs) =>
    processSaveInventoryItemValues(options, dispatch),
  updateListItem: (auctionItem: AuctionItem) => dispatch(myBlockUpdateAuctionItem(auctionItem)),
});

const connector = connect(null, dispatchConnect);

interface Props extends RouterProps, ConnectedProps<typeof connector> {
  /** The details of the auction item */
  auctionItem: AuctionItem;
  /** ID used for testing. */
  dataTestId?: string;
}

const StatusRowCost = ({ auctionItem, dataTestId, location, router, saveValues, updateListItem }: Props) => {
  const { setConfig } = useGlobalDialog();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const costValue = auctionItem?.inventoryItem?.values?.find(
    (value) => value?.type?.id === InventoryItemValueTypeEnum.COST
  );

  /**
   * Show error alert dialog
   */
  const showError = useCallback(
    (message: string) => {
      setConfig({ errorsOverride: [{ message }] });
    },
    [setConfig]
  );

  /**
   * OnSuccess
   */
  const onSuccess = useCallback(
    (response) => {
      const item: AuctionItem = {
        ...auctionItem,
        inventoryItem: {
          ...auctionItem?.inventoryItem,
          values: response?.values,
        },
      };

      const params = getUrlParams();
      const paramsNext = paramsToQueryString({ ...params, forceUpdate: new Date().getTime() });
      router?.push(`${location?.pathname}?${paramsNext}`);
      return updateListItem?.(item);
    },
    [auctionItem, location, router, updateListItem]
  );

  /**
   * OnError
   */
  const onError = useCallback(
    (error) => {
      showError(
        getErrors(error)
          .map(({ message }) => message)
          ?.join('\n')
      );
    },
    [showError]
  );

  /**
   * OnSubmit
   */
  const onSubmit = useCallback(
    (value) => {
      const isAdding = !costValue?.id;

      const options = {
        inventoryItemId: auctionItem?.inventoryItem?.id,
        isAdding,
        valueId: costValue?.id,
        valueInput: {
          ...(isAdding && { valueType: InventoryItemValueTypeEnum.COST }),
          amount: Number(value?.toString()?.trim()) || undefined,
        },
      };

      if (!options?.valueInput?.amount) {
        showError(t('please_enter_a_valid_amount'));
        return;
      }

      setIsSubmitting(true);
      saveValues?.(options)
        ?.then(onSuccess)
        ?.catch(onError)
        ?.finally(() => {
          setIsSubmitting(false);
          setIsEditing(false);
        });
    },
    [auctionItem, costValue, onSuccess, onError, saveValues, setIsSubmitting, showError]
  );

  return (
    <StatusRow
      dataTestId={dataTestId}
      inputType="number"
      isEditing={isEditing}
      isSubmitting={isSubmitting}
      onCancel={useCallback(() => setIsEditing(false), [])}
      onEdit={() => setIsEditing(true)}
      onSubmit={onSubmit}
      subTitle={isEditing ? costValue?.value?.amount : costValue?.value?.formattedAmount}
      subTitleClassName={style.subTitle}
      title={t('cost')}
    />
  );
};

export default withRouter(connector(StatusRowCost));
