import { createAction } from 'redux-actions';

import {
  AUCTIONEER_CONVERSATION_ID,
  ConversationState,
  DEFAULT_CONNECTED,
  ErrorStatus,
  UserNamesMap,
  getDefaultFatalErrorState,
} from 'store/chat/chatModels';
import { Conversation, ConversationType, LiveLaneConversationGroup } from 'store/shared/api/graph/interfaces/types';
import {
  clerkLiveLaneConversation,
  clerkLiveLaneConversations,
  liveLaneConversationRegister,
} from 'store/chat/chatApi';
import { formatUserName } from 'utils/stringUtils';
import { getErrors } from 'utils/apiUtils';
import { representsCompany } from 'utils/userUtils';

export const addChatUserNames = createAction('CHAT_CONVERSATION_ADD_USER_NAMES');
export const addConversation = createAction('CHAT_CONVERSATION_ADD');
export const clearChatUserNames = createAction('CHAT_CONVERSATION_CLEAR_USER_NAMES');
export const clearUserOnlineStatuses = createAction('CHAT_CLEAR_USER_ONLINE_STATUSES');
export const messageAdded = createAction('CHAT_MESSAGE_ADDED');
export const setConversations = createAction('CHAT_CONVERSATIONS_SET');
export const setConversationsListening = createAction('CHAT_CONVERSATIONS_SET_LISTENING');
export const setConversationsMessagesLoaded = createAction('CHAT_CONVERSATIONS_MESSAGES_SET_LOADED');
export const setCurrentConversationId = createAction('CHAT_CONVERSATION_ID_SET_CURRENT');
export const setCurrentConversationMessages = createAction('CHAT_CONVERSATION_MESSAGES_SET_CURRENT');
export const setErrorState = createAction('CHAT_ERROR_STATE_SET');
export const setUnreadMessagesStale = createAction('CHAT_MESSAGE_SET_UNREAD_STALE');
export const setUserOnlineStatus = createAction('CHAT_USER_ONLINE_STATUS_SET');
export const updateConversationsInActive = createAction('CHAT_CONVERSATION_UPDATE_INACTIVE');

export const processLoadConversation = (options, dispatch) => {
  return clerkLiveLaneConversation(options)
    .then((response) => {
      const conversation: Conversation | undefined = response?.data?.data?.clerkLiveLaneConversation;
      if (conversation) {
        dispatch(setErrorState(DEFAULT_CONNECTED));

        const conversationState: ConversationState = {
          ...conversation,
          inactive: false,
          listening: false,
          messagesLoaded: false,
        };

        /** If this is a buyer conversation, then we don't want to see any of the messages for the auctioneer */
        if (
          options.isAuctioneer &&
          conversation.customer &&
          !representsCompany(conversation.customer, options.inventoryItem?.company?.id)
        ) {
          conversationState.inactive = true;
          conversationState.messagesLoaded = true;
        }

        processConversationsUsers([conversationState], dispatch);
        dispatch(addConversation(conversationState));

        return;
      }
      dispatch(setErrorState(getDefaultFatalErrorState()));
    })
    .catch((err: Error) => {
      console.error(err);
      dispatch(
        setErrorState({
          status: ErrorStatus.FATAL_ERROR,
          error: getErrors(err),
        })
      );
    });
};

const processConversationsUsers = (conversations: ConversationState[], dispatch) => {
  const userNamesMap: UserNamesMap = {};
  conversations?.forEach((conversation: Conversation) => {
    if (conversation.staff) {
      userNamesMap[conversation.staff.id] = formatUserName(conversation.staff);
    }

    if (conversation.customer) {
      userNamesMap[conversation.customer.id] = formatUserName(conversation.customer);
    }
  });

  dispatch(addChatUserNames(userNamesMap));
};

export const processAuctioneerLoadAllConversations = (options, dispatch) => {
  return clerkLiveLaneConversations({ ...options, isAuctioneer: true })
    .then((response) => {
      const conversations: Conversation[] | undefined = response?.data?.data?.clerkLiveLaneConversations;
      if (conversations) {
        dispatch(setErrorState(DEFAULT_CONNECTED));

        const conversationStates = conversations.map<ConversationState>((conversation) => {
          const conversationState: ConversationState = {
            ...conversation,
            inactive: false,
            listening: false,
            messagesLoaded: false,
          };

          /** If this is a buyer conversation, then we don't want to see any of the messages for the auctioneer */
          if (conversation.customer && !representsCompany(conversation.customer, options.inventoryItem?.company?.id)) {
            conversationState.inactive = true;
            conversationState.messagesLoaded = true;
          }

          return conversationState;
        });

        /**
         * We need to create a virtual conversation for auctioneer that will be container for all clerk conversations.
         * This conversations isn't in Twilio and we have a pre-defined ID that distinguishes it from other conversations.
         */
        const auctionConversation: ConversationState = {
          customer: null,
          id: AUCTIONEER_CONVERSATION_ID,
          inactive: true,
          listening: false,
          messages: [],
          messagesLoaded: true,
          staff: null,
          type: ConversationType.LIVE_LANE,
        };
        conversationStates.push(auctionConversation);

        processConversationsUsers(conversationStates, dispatch);
        dispatch(setConversations(conversationStates));
        dispatch(setCurrentConversationId(auctionConversation.id));
        return;
      }
      dispatch(setErrorState(getDefaultFatalErrorState()));
    })
    .catch((err: Error) => {
      console.error(err);
      throw err; // Throw error so we know this operation should be retried
    });
};

export const processAuctioneerAuctionItemChanged = (options, dispatch) => {
  dispatch(setCurrentConversationMessages({ messages: [] }));
  dispatch(updateConversationsInActive(options));
};

export const processClerkLoadAllConversations = (options, dispatch) => {
  return clerkLiveLaneConversations(options)
    .then((response) => {
      const conversations: Conversation[] | undefined = response?.data?.data?.clerkLiveLaneConversations;
      if (conversations) {
        dispatch(setErrorState(DEFAULT_CONNECTED));

        processConversationsUsers(conversations, dispatch);
        dispatch(setConversations(conversations));
        const broadcastConversation: Conversation | undefined = conversations?.find(
          (conversation) => conversation.type === ConversationType.LIVE_LANE_BROADCAST
        );
        if (broadcastConversation) {
          dispatch(setCurrentConversationId(broadcastConversation.id));
        }
        return;
      }
      dispatch(setErrorState(getDefaultFatalErrorState()));
    })
    .catch((err: Error) => {
      console.error(err);
      throw err; // Throw error so we know this operation should be retried
    });
};

export const processRegisterConversation = (options, dispatch) => {
  return liveLaneConversationRegister(options)
    .then((response) => {
      const liveLaneConversationGroup: LiveLaneConversationGroup | undefined =
        response?.data?.data?.liveLaneConversationRegister;
      if (liveLaneConversationGroup) {
        dispatch(setErrorState(DEFAULT_CONNECTED));

        const conversations: Conversation[] = [];
        if (liveLaneConversationGroup.liveLaneConversation) {
          conversations.push(liveLaneConversationGroup.liveLaneConversation);
        }

        if (liveLaneConversationGroup.liveLaneBroadcastConversation) {
          conversations.push(liveLaneConversationGroup.liveLaneBroadcastConversation);
        }

        processConversationsUsers(conversations, dispatch);
        dispatch(setConversations(conversations));

        // If there is no regular conversation, then the broadcast conversation is default in read only mode
        dispatch(
          setCurrentConversationId(
            liveLaneConversationGroup.liveLaneConversation
              ? liveLaneConversationGroup.liveLaneConversation.id
              : liveLaneConversationGroup.liveLaneBroadcastConversation.id
          )
        );
        return;
      }
      dispatch(setErrorState(getDefaultFatalErrorState()));
    })
    .catch((err: Error) => {
      console.error(err);
      throw err; // Throw error so we know this operation should be retried
    });
};

export const processAddMessage = (options, dispatch) => {
  dispatch(setErrorState(DEFAULT_CONNECTED));
  dispatch(messageAdded(options));
  dispatch(setUnreadMessagesStale(true));
};

export const processSetCurrentConversationId = (options, dispatch) => {
  dispatch(setCurrentConversationId(options.conversationId));
  dispatch(setCurrentConversationMessages({ messages: [] }));
  dispatch(setConversationsMessagesLoaded({ conversationIds: [options.conversationId], messagesLoaded: false })); // Force messages to load
  dispatch(setUnreadMessagesStale(true));
};

export const processSetCurrentConversationMessages = (options, dispatch) => {
  dispatch(setCurrentConversationMessages(options));
  dispatch(setConversationsMessagesLoaded({ conversationIds: options.conversationIds, messagesLoaded: true })); // Messages loaded
};

export const processClearConversations = (options, dispatch) => {
  dispatch(clearChatUserNames());
  dispatch(clearUserOnlineStatuses());
  dispatch(setCurrentConversationId(''));
  dispatch(setConversations([]));
  dispatch(setCurrentConversationMessages({ messages: [] }));
  dispatch(setUnreadMessagesStale(true));
};

export const processSetUserOnlineStatus = (options, dispatch) => {
  dispatch(setUserOnlineStatus({ userId: options.userId, onlineStatus: options.onlineStatus }));
};
