import AuctionItemType from 'constants/auctionItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionEventTimelineEntry } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface AuctionItemModel {
  /** The timeline of events for an AuctionItem's TimeSlotLane */
  auctionEventTimeline: AuctionEventTimelineEntry[];

  /** Auction item details. */
  details: AuctionItemDetailsProps;
}

export type AuctionItemProps = ServerRecordOf<AuctionItemModel>;

/**
 * Initial state of model
 */
export const InitialState = ServerRecord<AuctionItemModel>({
  auctionEventTimeline: null,
  details: null,
});

/**
 * Auction Item Details
 */
interface AuctionItemDetailsModel extends AuctionItemType {
  _ended?: boolean;
  _error?: string;
}

export type AuctionItemDetailsProps = RecordOf<AuctionItemDetailsModel>;
export type AuctionItemDetailsPropsJs = ReturnType<RecordOf<AuctionItemDetailsModel>['toJS']>;

export const AuctionItemDetails = Record<AuctionItemDetailsModel>({
  _ended: false,
  auction: {
    featureFlagKeys: [],
    id: null,
    title: null,
    type: null,
  },
  id: null,
  isUpdatable: false,
  status: null,
  format: null,
  asIs: false,
  archived: false,
  isMyItem: false,
  ranOn: null,
  displayRunNumber: null,
  deliveryDate: null,
  dmvReg398Url: null,
  checkoutExpiry: null,
  colorScheme: null,
  timerText: null,
  timerEnd: null,
  releaseStatus: null,
  vehicleReleaseForm: null,
  guaranteedAuctionPrice: null,
  auctionTimeSlot: {
    startTime: null,
    finishTime: null,
  },
  auctionTimeSlotLane: {
    id: null,
    name: null,
  },
  buyerReps: [],
  sellerReps: [],
  sellerTier: null,
  holdback: null,
  holdbackActive: false,
  buyer: null,
  hideSellerNameEnabled: false,
  topOffer: {
    amount: {
      amount: null,
      formattedAmountRounded: null,
    },
  },
  timedOfferTimeline: null,
  bidTimeline: {
    count: 0,
  },
  buyNowPrice: {
    amount: null,
    formattedAmountRounded: null,
  },
  startingBid: {
    amount: null,
    formattedAmountRounded: null,
  },
  listPrice: {
    formattedAmountRounded: null,
  },
  reserveMet: false,
  reserve: {
    amount: null,
    formattedAmountRounded: null,
  },
  reserveType: null,
  nextBidAmount: {
    formattedAmountRounded: null,
    amount: null,
  },
  furtherBidIncrement: {
    formattedAmountRounded: null,
    amount: null,
  },
  watchers: {
    isWatched: null,
  },
  autoBids: null,
  ifBidClaimedByUserId: null,
  ifBidClaimedByUserName: null,
  ifBidTimeline: {
    count: 0,
  },
  isAssured: false,
  history: {
    count: 0,
    topOffers: [],
    list: [],
  },
  transactionDetails: {
    transactionNumber: null,
    purchasePrice: null,
    purchaseTaxes: null,
    buyBillOfSale: null,
    buyTotal: null,
    sellBillOfSale: null,
    sellFeeCredit: null,
    sellTotal: null,
    created: null,
  },
  transactionType: null,
  arbitrationRequest: null,
  order: null,
  saleLights: [],
  sellerIntent: null,
  workflowProcesses: [],
  inventoryItem: {
    asIsCause: null,
    autocheckExceptions: null,
    autocheckExceptionsReceived: null,
    blueDriverScanData: null,
    type: null,
    id: null,
    notes: [],
    selectedOptions: [],
    company: null,
    verifiedBy: null,
    year: null,
    make: null,
    model: null,
    subModel: null,
    description: null,
    trim: null,
    vin: null,
    stockNumber: null,
    bodyType: null,
    chargingCable: null,
    numberOfDoors: null,
    numberOfPassengers: null,
    exteriorColor: null,
    interiorColor: null,
    transmission: null,
    cylinders: null,
    displacement: null,
    driveTrain: null,
    fuelType: null,
    photos: [],
    interiorPhotos: [],
    undercarriagePhotos: [],
    damagePhotos: [],
    location: {
      name: null,
      address1: null,
      address2: null,
      city: null,
      regionCode: null,
      zipCode: null,
      countryCode: null,
      latitude: null,
      longitude: null,
      distanceAway: 0,
    },
    autoGradeScore: null,
    autoGradeScoreData: null,
    captureType: null,
    conditionReport: {
      overallConditionRating: null,
      carfaxCanadaClaimAmount: {
        formattedAmountRounded: null,
      },
      repaintOnHowManyPanels: null,
      activeDeclarations: [],
      inactiveDeclarations: [],
    },
    paintCondition: {
      driverFenderDepth: null,
      driverFrontDoorDepth: null,
      driverQuarterPanelDepth: null,
      driverRearDoorDepth: null,
      hoodDepth: null,
      passengerFenderDepth: null,
      passengerFrontDoorDepth: null,
      passengerQuarterPanelDepth: null,
      passengerRearDoorDepth: null,
      roofDepth: null,
      trunkDepth: null,
    },
    tireCondition: {
      driverFrontBrand: null,
      driverFrontSize: null,
      driverFrontTread: { formatted: null },
      driverRearBrand: null,
      driverRearSize: null,
      driverRearTread: { formatted: null },
      fourMatchingTires: null,
      passengerFrontBrand: null,
      passengerFrontSize: null,
      passengerFrontTread: { formatted: null },
      passengerRearBrand: null,
      passengerRearSize: null,
      passengerRearTread: { formatted: null },
      secondSetOfTires: null,
      secondSetOfTiresHasRims: false,
      tirePressureMonitoringSystem: null,
      tirePressureMonitoringSystemPhotoUrl: null,
      winterTires: null,
    },
    mileage: {
      formattedAmount: null,
    },
    lastKnownMileage: null,
    lastKnownMileageDate: null,
    sellerNotes: null,
    drivetrainBuyerShield: null,
    engineBuyerShield: null,
    transmissionBuyerShield: null,
    vehicleBuyerShield: null,
    extensiveVehicleConditionScore: null,
    exteriorNote: null,
    exteriorRating: null,
    interiorNote: null,
    interiorRating: null,
    legalSaleDate: null,
    mechanicalDrivetrainNote: null,
    mechanicalDrivetrainRating: null,
    mechanicalEngineNote: null,
    mechanicalEngineRating: null,
    mechanicalNote: null,
    mechanicalRating: null,
    mechanicalTransmissionNote: null,
    mechanicalTransmissionRating: null,
    source: null,
    tiresNote: null,
    tiresRating: null,
    transportDetails: null,
    printableUrl: null,
    inventoryItemFee: null,
    values: null,
  },
} as unknown as AuctionItemDetailsModel);
