import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import { auctionTimeSlotLaneSchema } from 'store/admin/liveLanes/shared/liveLaneSchemas';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';
import {
  AuctionItemConnection,
  AuctionTimeSlotLane,
  BidBadge,
  Company,
  MutationauctionItemOverrideOnlineBidArgs,
  MutationauctionItemPhysicalClearBidsArgs,
  MutationauctionItemPhysicalEndArgs,
  MutationauctionItemPhysicalPauseArgs,
  MutationauctionItemPhysicalRerunArgs,
  MutationauctionItemPhysicalResumeArgs,
  MutationauctionItemPhysicalRetractBidArgs,
  MutationauctionItemPhysicalStartArgs,
  MutationauctionItemReserveMetArgs,
  MutationauctionItemSetBidIncrementArgs,
  MutationauctionItemSetStartPriceArgs,
  MutationauctionItemSubmitFloorBidArgs,
  MutationauctionPhysicalLaneEndArgs,
  MutationauctionPhysicalLaneStartArgs,
  QueryauctionItemConnectionArgs,
  QueryauctionTimeSlotLaneArgs,
  QuerybidBadgeArgs,
  QuerycompanyArgs,
} from 'store/shared/api/graph/interfaces/types';
import { pageInfo } from 'store/shared/api/graph/schema/connection';
import { topOffer } from 'store/shared/api/graph/schema/auctionItem';

export const getAuctionItem = ({ auctionItemId }: { auctionItemId: string }) => {
  return ApiRequest<{ auctionItem: AuctionItem }>({
    data: {
      query: `
        query($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId)  {
            id
            asIs
            auction {
              id
              settings {
                saleLightsOverride
              }
              currencyCode
              title
              videoStreamConfig {
                publishingToken
              }
            }
            auctionTimeSlot {
              id
            }
            auctionTimeSlotLane {
              id
              controlType
              name
            }
            autoBids {
              count
            }
            bidIncrement {
              amount
              formattedAmountRounded
            }
            bidTimeline {
              count
              list {
                type
              }
            }
            format
            inventoryItem {
              notes {
                id
              }
              ... on Vehicle {
                id
                autoGradeScore
                captureType
                company {
                  id
                  name
                }
                conditionReport {
                  carfaxCanadaClaimAmount {
                    amount
                    formattedAmountRounded
                  }
                  declarations(selected:true) {
                    id
                    name
                  }
                  overallConditionRating
                }
                extensiveVehicleConditionScore
                exteriorColor
                lastKnownMileage {
                  amount
                  unit
                }
                lastKnownMileageDate
                location {
                  countryCode
                }
                make
                mileage {
                  formattedAmount
                }
                model
                pickupLocation {
                  countryCode
                }
                photos (first: 1) { ...listItemPhotoFragment }
                sellerNotes
                trim
                vehicleBuyerShield
                vin
                year
              }
            }
            isAssured
            nextBidAmount {
              amount
              formattedAmountRounded
            }
            reserve {
              amount
              currencyCode
              formattedAmount
              formattedAmountRounded
            }
            reserveMet
            reserveType
            displayRunNumber
            saleLights
            sellerIntent {
              status
              createdBy {
                id
                firstName
                lastName
              }
            }
            startingBid {
              amount
              formattedAmountRounded
            }
            status
            ${topOffer}
            watchers {
              count
              isWatched
            }
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const auctionItemPhysicalEnd = ({ input }: MutationauctionItemPhysicalEndArgs) =>
  ApiRequest<{ auctionItemPhysicalEnd: Pick<AuctionItem, 'id' | 'status' | 'auctionTimeSlotLane'> }>({
    data: {
      query: `
        mutation($input: AuctionItemPhysicalEndInput!) {
          auctionItemPhysicalEnd(input: $input) {
            id
            noSaleReason
            status
            auctionTimeSlotLane {
              itemsRanCount
              itemsRemainingCount
              liveItem {
                id
                status
              }
            }
          }
        }
      `,
      variables: {
        input,
      },
    },
  });

export const auctionItemPhysicalPause = ({ auctionItemId }: MutationauctionItemPhysicalPauseArgs) =>
  ApiRequest<{ auctionItemPhysicalPause: Pick<AuctionItem, 'id' | 'status' | 'auctionTimeSlotLane'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemPhysicalPause(auctionItemId: $auctionItemId) {
            id
            status
            auctionTimeSlotLane {
              itemsRanCount
              itemsRemainingCount
              liveItem {
                id
                status
              }
            }
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });

export const auctionItemPhysicalResume = ({ auctionItemId }: MutationauctionItemPhysicalResumeArgs) =>
  ApiRequest<{ auctionItemPhysicalResume: Pick<AuctionItem, 'id' | 'status' | 'auctionTimeSlotLane'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemPhysicalResume(auctionItemId: $auctionItemId) {
            id
            status
            auctionTimeSlotLane {
              itemsRanCount
              itemsRemainingCount
              liveItem {
                id
                status
              }
            }
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });

export const auctionItemSetBidIncrement = ({ auctionItemId, bidIncrement }: MutationauctionItemSetBidIncrementArgs) =>
  ApiRequest<{ auctionItemSetBidIncrement: Pick<AuctionItem, 'bidIncrement' | 'nextBidAmount'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $bidIncrement: Float!) {
          auctionItemSetBidIncrement(auctionItemId: $auctionItemId, bidIncrement: $bidIncrement) {
            bidIncrement {
              amount
              formattedAmountRounded
            }
            nextBidAmount {
              amount
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        auctionItemId,
        bidIncrement,
      },
    },
  });

export const auctionItemSetReserveMet = ({ auctionItemId }: MutationauctionItemReserveMetArgs) => {
  return ApiRequest<{ auctionItemReserveMet: Pick<AuctionItem, 'id' | 'reserveMet'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemReserveMet(auctionItemId: $auctionItemId) {
            id
            reserveMet
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const auctionItemSetStartPrice = ({
  auctionItemId,
  auctionTimeSlotLaneId,
  startPrice,
}: MutationauctionItemSetStartPriceArgs) =>
  ApiRequest<{
    auctionItemSetStartPrice: Pick<
      AuctionItem,
      'id' | 'auctionTimeSlotLane' | 'nextBidAmount' | 'startingBid' | 'status'
    >;
  }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $auctionTimeSlotLaneId: String!, $startPrice: Float!) {
          auctionItemSetStartPrice(auctionItemId: $auctionItemId, auctionTimeSlotLaneId: $auctionTimeSlotLaneId, startPrice: $startPrice) {
            id
            auctionTimeSlotLane {
              ${auctionTimeSlotLaneSchema}
            }
            nextBidAmount {
              amount
              formattedAmountRounded
            }
            startingBid {
              amount
              formattedAmountRounded
            }
            status
          }
        }
      `,
      variables: {
        auctionItemId,
        auctionTimeSlotLaneId,
        startPrice,
      },
    },
  });

export const auctionItemPhysicalRerun = ({
  auctionItemId,
  auctionTimeSlotLaneId,
  cancellationReason,
}: MutationauctionItemPhysicalRerunArgs) =>
  ApiRequest<{ auctionItemPhysicalRerun: Pick<AuctionItem, 'id' | 'status' | 'auctionTimeSlotLane'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $auctionTimeSlotLaneId: String!, $cancellationReason: String) {
          auctionItemPhysicalRerun(auctionItemId: $auctionItemId, auctionTimeSlotLaneId: $auctionTimeSlotLaneId, cancellationReason: $cancellationReason) {
            id
            status
            auctionTimeSlotLane {
              itemsRanCount
              itemsRemainingCount
              liveItem {
                id
                status
              }
            }
          }
        }
      `,
      variables: {
        auctionItemId,
        auctionTimeSlotLaneId,
        cancellationReason,
      },
    },
  });

export const auctionItemPhysicalStart = ({
  auctionItemId,
  auctionTimeSlotLaneId,
}: MutationauctionItemPhysicalStartArgs) =>
  ApiRequest<{ auctionItemPhysicalStart: Pick<AuctionItem, 'id' | 'status' | 'auctionTimeSlotLane'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $auctionTimeSlotLaneId: String!) {
          auctionItemPhysicalStart(auctionItemId: $auctionItemId, auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            id
            status
            auctionTimeSlotLane {
              itemsRanCount
              itemsRemainingCount
              liveItem {
                id
                status
              }
            }
          }
        }
      `,
      variables: {
        auctionItemId,
        auctionTimeSlotLaneId,
      },
    },
  });

export const auctionItemSubmitFloorBid = ({ amount, auctionItemId }: MutationauctionItemSubmitFloorBidArgs) => {
  return ApiRequest<{
    auctionItemSubmitFloorBid: Pick<AuctionItem, 'id' | 'bidTimeline' | 'nextBidAmount' | 'topOffer'>;
  }>({
    data: {
      query: `
        mutation($amount: Float!, $auctionItemId: String!) {
          auctionItemSubmitFloorBid(amount: $amount, auctionItemId: $auctionItemId) {
            id
            bidTimeline {
              count
            }
            ${topOffer}
            nextBidAmount {
              amount
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        amount,
        auctionItemId,
      },
    },
  });
};

export const auctionItemOverrideOnlineBid = ({
  auctionItemId,
  onlineBidId,
}: MutationauctionItemOverrideOnlineBidArgs) => {
  return ApiRequest<{
    auctionItemOverrideOnlineBid: Pick<AuctionItem, 'id' | 'bidTimeline' | 'topOffer' | 'nextBidAmount'>;
  }>({
    data: {
      query: `
        mutation($auctionItemId: String!, $onlineBidId: String!) {
          auctionItemOverrideOnlineBid(auctionItemId: $auctionItemId, onlineBidId: $onlineBidId) {
            id
            bidTimeline {
              count
            }
            ${topOffer}
            nextBidAmount {
              amount
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        auctionItemId,
        onlineBidId,
      },
    },
  });
};

export const auctionItemPhysicalRetractBid = ({ auctionItemId }: MutationauctionItemPhysicalRetractBidArgs) => {
  return ApiRequest<{
    auctionItemPhysicalRetractBid: Pick<AuctionItem, 'id' | 'bidTimeline' | 'topOffer' | 'nextBidAmount'>;
  }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemPhysicalRetractBid(auctionItemId: $auctionItemId) {
            id
            bidTimeline {
              count
            }
            ${topOffer}
            nextBidAmount {
              amount
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const auctionPhysicalLaneEnd = ({ auctionTimeSlotLaneId }: MutationauctionPhysicalLaneEndArgs) =>
  ApiRequest<{ auctionPhysicalLaneEnd: AuctionTimeSlotLane }>({
    data: {
      query: `
        mutation($auctionTimeSlotLaneId: String!) {
          auctionPhysicalLaneEnd(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            ${auctionTimeSlotLaneSchema}
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId,
      },
    },
  });

export const auctionPhysicalLaneStart = ({ auctionTimeSlotLaneId }: MutationauctionPhysicalLaneStartArgs) =>
  ApiRequest<{ auctionPhysicalLaneStart: AuctionTimeSlotLane }>({
    data: {
      query: `
        mutation($auctionTimeSlotLaneId: String!) {
          auctionPhysicalLaneStart(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            ${auctionTimeSlotLaneSchema}
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId,
      },
    },
  });

export const auctionTimeSlotLane = ({ auctionTimeSlotLaneId }: QueryauctionTimeSlotLaneArgs) =>
  ApiRequest<{ auctionTimeSlotLane: AuctionTimeSlotLane }>({
    data: {
      query: `
        query($auctionTimeSlotLaneId: String!) {
          auctionTimeSlotLane(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            ${auctionTimeSlotLaneSchema}
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId,
      },
    },
  });

export const bidBadge = ({ auctionTimeSlotId, bidBadgeNumber }: QuerybidBadgeArgs) =>
  ApiRequest<{ bidBadge: BidBadge }>({
    data: {
      query: `
        query($auctionTimeSlotId: String!, $bidBadgeNumber: String!) {
          bidBadge(auctionTimeSlotId: $auctionTimeSlotId, bidBadgeNumber: $bidBadgeNumber) {
            id
            userCompanyRelationship {
              company {
                id
                name
              }
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        auctionTimeSlotId,
        bidBadgeNumber,
      },
    },
  });

export const getCompanyAuctionsCreditLimit = ({ companyId }: QuerycompanyArgs) =>
  ApiRequest<{ company: Pick<Company, 'id' | 'auctionRelationships'> }>({
    data: {
      operationName: 'getCompanyAuctionsCreditLimit',
      query: `
        query($companyId: String!) {
          company(companyId: $companyId) {
            id
            auctionRelationships {
              list {
                id
                auction {
                  id
                }
                buyerCreditLimit {
                  amount
                }
              }
            }
          }
        }
      `,
      variables: {
        companyId,
      },
    },
  });

export const getLiveLane = (options: QueryauctionItemConnectionArgs, isFullQuery = true) =>
  ApiRequest<{
    auctionItemConnection: AuctionItemConnection;
    auctionTimeSlotLane?: AuctionTimeSlotLane;
  }>({
    data: {
      operationName: 'getLiveLane',
      query: `
        query(
          $auctionTimeSlotIds: [String],
          $auctionTimeSlotLaneIds: [String],
          $first: Int,
          $formats: [AuctionItemFormat]
          $keyword: String,
          $sort: [Sort],
          $status: [AuctionItemStatus]
        ) {
          auctionItemConnection(
            auctionTimeSlotIds: $auctionTimeSlotIds,
            auctionTimeSlotLaneIds: $auctionTimeSlotLaneIds,
            first: $first,
            formats: $formats,
            keyword: $keyword,
            sort: $sort,
            status: $status
          ) {
            edges {
              node {
                id
                auctionTimeSlotLane {
                  controlType
                  name
                  itemsRemainingCount
                  itemsRanCount
                }
                inventoryItem {
                  notes {
                    id
                  }
                  ... on Vehicle {
                    id
                    captureType
                    company {
                      id
                      name
                    }
                    conditionReport {
                      carfaxCanadaClaimAmount {
                        amount
                        formattedAmountRounded
                      }
                      declarations(selected:true) {
                        id
                        name
                      }
                    }
                    make
                    mileage {
                      formattedAmount
                    }
                    model
                    photos (first: 1) { ...listItemPhotoFragment }
                    trim
                    vehicleBuyerShield
                    vin
                    year
                  }
                }
                isAssured
                displayRunNumber
                saleLights
                status
                format
                noSaleReason
                watchers {
                  isWatched
                }
              }
            }
            ${pageInfo}
          }
          
          ${
            // Query everything needed for this section, not just the upcoming list content
            isFullQuery
              ? `
                auctionTimeSlotLane(auctionTimeSlotLaneId: "${options?.auctionTimeSlotLaneIds?.[0]}") {
                 ${auctionTimeSlotLaneSchema}
                }
              `
              : ''
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        auctionTimeSlotIds: options?.auctionTimeSlotIds,
        auctionTimeSlotLaneIds: options?.auctionTimeSlotLaneIds,
        first: options?.first,
        formats: options?.formats,
        keyword: options?.keyword,
        sort: options?.sort,
        status: options?.status,
      },
    },
  });

export const auctionItemPhysicalClearBids = ({ auctionItemId }: MutationauctionItemPhysicalClearBidsArgs) =>
  ApiRequest<{ auctionItemPhysicalClearBids: Pick<AuctionItem, 'id'> }>({
    data: {
      query: `
        mutation($auctionItemId: String!) {
          auctionItemPhysicalClearBids(auctionItemId: $auctionItemId) {
            id
          }
        }
      `,
      variables: {
        auctionItemId,
      },
    },
  });
