export const topOffer = `
  topOffer {
    id
    amount {
      amount
      currencyCode
      formattedAmount
      formattedAmountRounded
    }
    bidType
    company {
      id
      name
    }
    created
    createdBy {
      firstName
      lastName
    }
  }
`;

export const transactionDetails = `
  transactionDetails {
    transactionNumber
    purchasePrice {
      formattedAmount
    }
    buyBillOfSale
    buyTotal {
      formattedAmount
    }
    sellBillOfSale
    sellFeeCredit {
      formattedAmount
    }
    sellTotal {
      formattedAmount
    }
    created
  }
`;
