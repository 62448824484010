import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VerifyEmailForm from 'forms/account/accountVerification/verifyEmailForm';
import VerifyPhoneAndEmailForm from 'forms/account/accountVerification/verifyPhoneAndEmailForm';
import VerifyPhoneForm from 'forms/account/accountVerification/verifyPhoneForm';
import { AppDispatch, AppState } from 'store/configureStore';
import { setAccountVerificationFormActive } from 'store/system/systemActions';

const AccountVerificationFormContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFormOpen = useSelector((state: AppState) => state.app.system.isAccountVerificationFormOpen);
  const user = useSelector((state: AppState) => state.app.user);

  const isPhoneVerificationRequired = !user.phoneVerified;
  const isEmailVerificationRequired = !user.emailVerified;

  /**
   * Sets the system property `isAccountVerificationFormOpen` to false, which closes the global dialog
   */
  const onClose = useCallback(() => dispatch(setAccountVerificationFormActive(false)), [dispatch]);

  const form = useMemo(() => {
    if (isFormOpen) {
      if (isPhoneVerificationRequired && isEmailVerificationRequired) {
        return <VerifyPhoneAndEmailForm isOpen onClose={onClose} />;
      }

      if (isPhoneVerificationRequired) {
        return <VerifyPhoneForm isOpen onClose={onClose} />;
      }

      if (isEmailVerificationRequired) {
        return <VerifyEmailForm isOpen onClose={onClose} />;
      }
    }

    return null;
  }, [isEmailVerificationRequired, isFormOpen, isPhoneVerificationRequired, onClose]);

  return form;
};

export default AccountVerificationFormContainer;
