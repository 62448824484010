import ApiRequest from 'store/shared/api/apiRequest';
import {
  MutationuserUpdateInfoAsAdminArgs,
  User,
  UserCompanyRelationshipConnection,
} from 'store/shared/api/graph/interfaces/types';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { user } from 'store/shared/api/graph/schema/user';

export const updateAuctionAccessIdMutation = `
  mutation ($userId: String, $auctionAccessId: Int!) {
    userUpdateAuctionAccessId(userId: $userId, auctionAccessId: $auctionAccessId) {
      auctionAccessId
      auctionAccessLastVerified
    }
  }
`;

export const updateLocaleTimeZoneMutation = `,
mutation ($userId: String, $locale: String, $timeZone: String) {
  userUpdateLocaleTimeZone(userId: $userId, locale: $locale, timeZone: $timeZone) {
    locale,
    timeZone,
  }
}
`;

export const getUser = ({ userId = null }) =>
  ApiRequest<{ user: User }>({
    data: {
      operationName: 'getUserDetails',
      query: `
      query ($userId: String!) {
        user(userId: $userId) {
          ${user()}
          mainPhotoMain: mainPhotoUrl(width:800, height:600)
          mainPhotoThumb: mainPhotoUrl(width:100, height:100)
          companyRelationships {
            count
            list {
              company {
                id
                name
                primaryLocation {
                  address1
                  address2
                  city
                  name
                  regionCode
                  zipCode
                }
                source
              }
              status
            }
          }
        }
      }
      ${locationFragment}
    `,
      variables: {
        userId,
      },
    },
  });

export const saveUser = ({
  cellPhone = null,
  email = null,
  firstName = null,
  lastName = null,
  photo = null,
  secondaryPhone = null,
  status = null,
  title = null,
  userId,
}: MutationuserUpdateInfoAsAdminArgs) =>
  ApiRequest<{ userUpdateInfoAsAdmin: User }>({
    data: {
      operationName: 'saveUser',
      query: `
      mutation (
        $cellPhone: String,
        $email: String,
        $firstName: String,
        $lastName: String,
        $photo: String,
        $secondaryPhone: String,
        $status: UserStatus
        $title: String,
        $userId: String!,
      ) {
        userUpdateInfoAsAdmin(
          cellPhone: $cellPhone,
          email: $email,
          firstName: $firstName,
          lastName: $lastName,
          photo: $photo,
          secondaryPhone: $secondaryPhone,
          status: $status
          title: $title,
          userId: $userId,
        ) {
          id
          cellPhone
          email
          firstName
          lastName
          mainPhotoMain: mainPhotoUrl(width:800, height:600)
          mainPhotoThumb: mainPhotoUrl(width:100, height:100)
          secondaryPhone
          status
          title
        }
      }
    `,
      variables: {
        cellPhone,
        email,
        firstName,
        lastName,
        photo,
        secondaryPhone,
        status,
        title,
        userId,
      },
    },
  });

export const suspendUser = ({ id = null }) =>
  ApiRequest<{ userUpdateInfoAsAdmin: Pick<User, 'id' | 'status'> }>({
    data: {
      query: `
      mutation(
        $id: String!,
      ) {
        userUpdateInfoAsAdmin(
          userId:$id
          status:SUSPENDED
        ) {
          id
          status
        }
      }
    `,
      variables: {
        id,
      },
    },
  });

export const getUserCompanies = ({ userId = null }) =>
  ApiRequest<{ userCompanyConnection: UserCompanyRelationshipConnection }>({
    data: {
      operationName: 'getUserCompanies',
      query: `
      query ($userIds: [String]) {
        userCompanyConnection(first: 500, userIds: $userIds) {
          edges {
            node {
              user {
                id
                status
                firstName
                lastName
              }
              company {
                id
                name
                type
                subType
                primaryLocation {
                  distanceAway
                  city
                  regionCode
                }
              }
              status
              department
              roles {
                id
                name
              }
            }
          }
        }
      }
    `,
      variables: {
        userIds: [userId],
      },
    },
  });

export const updateOmvic = ({ userId = null, expiryDate = null, registrationNumber = null }) =>
  ApiRequest<{ userUpdateOmvic: Pick<User, 'omvicRegNumber' | 'omvicRegExpiryDate'> }>({
    data: {
      query: `
      mutation ($userId: String, $expiryDate: Date, $registrationNumber: String) {
        userUpdateOmvic(userId: $userId, expiryDate: $expiryDate, registrationNumber: $registrationNumber) {
          omvicRegNumber
          omvicRegExpiryDate
        }
      }
    `,
      variables: {
        userId,
        expiryDate,
        registrationNumber,
      },
    },
  });

export const updateLocaleTimeZone = ({ userId = null, locale = null, timeZone = null }) =>
  ApiRequest<{ userUpdateLocaleTimeZone: Pick<User, 'locale' | 'timeZone'> }>({
    data: {
      operationName: 'updateLocaleTimeZone',
      query: updateLocaleTimeZoneMutation,
      variables: {
        userId,
        locale,
        timeZone,
      },
    },
  });

export const updateAuctionAccessId = ({ userId = null, auctionAccessId = null }) =>
  ApiRequest<{ userUpdateAuctionAccessId: Pick<User, 'auctionAccessId' | 'auctionAccessLastVerified'> }>({
    data: {
      operationName: 'updateAuctionAccessId',
      query: updateAuctionAccessIdMutation,
      variables: {
        userId,
        auctionAccessId,
      },
    },
  });

export const updateLocation = ({
  userId = null,
  distanceUnit = null,
  address1 = null,
  address2 = null,
  city = null,
  region = null,
  country = null,
  zipCode = null,
  latitude = null,
  longitude = null,
}) =>
  ApiRequest<{ userUpdatePreferredLocationDistanceUnit: User }>({
    data: {
      query: `
      mutation (
        $userId: String,
        $distanceUnit: DistanceUnit,
        $address1: String,
        $address2: String,
        $city: String,
        $region: String,
        $country: String,
        $zipCode: String,
        $latitude: Float,
        $longitude: Float
      ) {
        userUpdatePreferredLocationDistanceUnit(
          userId: $userId,
          distanceUnit: $distanceUnit,
          address1: $address1,
          address2: $address2,
          city: $city,
          region: $region,
          country: $country,
          zipCode: $zipCode,
          latitude: $latitude,
          longitude: $longitude
        ) {
          id
          distanceUnit
          preferredLocation {
            ...location
          }
        }
      }
      ${locationFragment}
    `,
      variables: {
        userId,
        distanceUnit,
        address1,
        address2,
        city,
        region,
        country,
        zipCode,
        latitude,
        longitude,
      },
    },
  });
