import ApiRequest from 'store/shared/api/apiRequest';

export const getConversationAccessToken = () =>
  ApiRequest<{ conversationAccessToken: string }>({
    data: {
      operationName: 'getConversationAccessToken',
      query: `{ conversationAccessToken }`,
    },
  });

export const getConversationAuctioneerAccessToken = (auctionTimeSlotLaneId: string) =>
  ApiRequest<{ conversationAuctioneerAccessToken: string }>({
    data: {
      operationName: 'getConversationAuctioneerAccessToken',
      query: `
        query($auctionTimeSlotLaneId: String!) {
            conversationAuctioneerAccessToken(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) 
        }`,
      variables: {
        auctionTimeSlotLaneId,
      },
    },
  });
