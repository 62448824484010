import { createAction } from 'redux-actions';

import { Carrier, MutationupdateCarrierSuperDispatchArgs } from 'store/shared/api/graph/interfaces/types';
import { carriersListUpdateItem, processUpdateFacets } from '../list/carriersActions';
import { createCarrier, getCarrier, updateCarrier, updateCarrierSuperDispatch } from './carrierDetailsApi';
import { getUrlParams } from 'utils/urlUtils';
import { locationCreate, locationUpdate } from 'store/shared/api/graph/mutations/locationMutations';

export const isUpdating = createAction('ADMIN_CARRIER_DETAILS_SET_IS_UPDATING');
export const isNotUpdating = createAction('ADMIN_CARRIER_DETAILS_UNSET_IS_UPDATING');
export const isLoaded = createAction('ADMIN_CARRIER_DETAILS_IS_LOADED');
export const preloadCarrierItem = createAction('ADMIN_CARRIER_DETAILS_IS_PRELOADED');
export const updateCarrierItem = createAction('ADMIN_CARRIER_DETAILS_UPDATED');
export const setCarrierData = createAction('ADMIN_CARRIER_DETAILS_SETTING_DATA');

export const processGetCarrier = async (options, dispatch) => {
  dispatch(isUpdating());
  const carrierDetails = (await getCarrier(options))?.data?.data;
  dispatch(isLoaded(carrierDetails));
};

export const proccessSaveCarrier = (options, dispatch) => {
  dispatch(isUpdating());
  const saveFunc = options.id ? updateCarrier : createCarrier;
  return saveFunc(options)
    .then((response) => {
      const formattedResponse = response?.data.data?.[options.id ? 'carrierUpdate' : 'carrierCreate'] as Carrier;
      dispatch(updateCarrierItem(formattedResponse));
      dispatch(carriersListUpdateItem(formattedResponse));
      processUpdateFacets(getUrlParams(), dispatch);
      return formattedResponse;
    })
    .finally(() => dispatch(isNotUpdating()));
};

export const processSaveLocation = (options, isUpdatingLoc, dispatch) => {
  const saveFunc = isUpdatingLoc ? locationUpdate : locationCreate;
  dispatch(isUpdating());
  return saveFunc({ ...options, locationIdType: 'carrierId' })
    .then((response) => {
      const formattedResponse = {
        id: options.defaultId,
        primaryLocation: response.data.data[isUpdatingLoc ? 'locationUpdate' : 'locationCreate'],
      };

      dispatch(updateCarrierItem(formattedResponse));
      dispatch(carriersListUpdateItem(formattedResponse));
    })
    .finally(() => dispatch(isNotUpdating()));
};

export const processUpdateCarrierSuperDispatch = (options: MutationupdateCarrierSuperDispatchArgs, dispatch) => {
  return updateCarrierSuperDispatch(options)?.then((response) => {
    const formattedResponse = response?.data?.data?.updateCarrierSuperDispatch;
    dispatch(updateCarrierItem(formattedResponse));
  });
};
