import { handleActions } from 'redux-actions';

import { InitialState } from './auctioneerModels';
import { auctionItemLoaded, clearAuctioneer, isLoading, liveLaneLoaded } from './auctioneerActions';

export const auctioneerReducer = handleActions(
  {
    [clearAuctioneer().type]: () => new InitialState(),

    [isLoading().type]: (state) => state.setLoading(),

    [auctionItemLoaded().type]: (state, action) => {
      return state.setLoaded().set('auctionItem', action.payload);
    },

    [liveLaneLoaded().type]: (state, action) => {
      return state
        .setLoaded()
        .set('auctionTimeSlotLane', action?.payload?.auctionTimeSlotLane)
        .set('upcomingItems', action?.payload?.upcomingItems);
    },
  },
  new InitialState()
);
