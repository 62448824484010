import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import addNoteGlyph from 'glyphs/add-note.svg';
import printGlyph from 'glyphs/print.svg';

import Archive from 'components/sections/auctionItem/operations/archive/archive';
import AuctionItem from 'constants/auctionItem';
import Button from 'components/ui/shared/button';
import InventoryItem from 'constants/inventoryItem';
import SetHoldback from 'components/sections/auctionItem/operations/setHoldback/setHoldback';
import Share from 'components/sections/auctionItem/operations/share/share';
import Sprite from 'components/ui/shared/sprite';
import WatchButton from 'components/ui/lists/listItem/watchButton';
import { AppState } from 'store/configureStore';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { getEnabledCompanyIds } from 'utils/userUtils';
import { getGraphBaseURL } from 'utils/apiUtils';
import { joinStrings } from 'utils/stringUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

import style from './headerActionButtons.scss';

export interface Props {
  /** The auction item. */
  auctionItem?: AuctionItem;
  /** The inventory item. */
  inventoryItem?: InventoryItem;
  /** True when current user is a staff user. */
  isStaffUser?: boolean;
  /** Function invoked to refresh the list. */
  onRefreshList?: () => void;
  /** Function invoked when notes button clicked. */
  toggleNotes: () => void;
}

const HeaderActionButtons = ({ auctionItem, inventoryItem, isStaffUser, onRefreshList, toggleNotes }: Props) => {
  const user = useSelector((state: AppState) => state.app.user);

  /**
   * Tracks user action on button clicked.
   */
  const trackUserAction = useCallback(
    (action: UserAction) => {
      if (auctionItem) {
        trackUserActionWithAuctionItemAttributes(action, auctionItem);
      } else {
        trackUserActionWithInventoryItemAttributes(action, inventoryItem);
      }
    },
    [auctionItem, inventoryItem]
  );

  const renderHoldbackButton = useMemo(() => {
    if (!auctionItem) {
      return null;
    }
    return <SetHoldback auctionItem={auctionItem} className={classnames(style.button, style.holdbackButton)} />;
  }, [auctionItem]);

  const renderWatchButton = useMemo(() => {
    const isSellersVehicle = getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company?.id ?? '');

    if (!auctionItem || isSellersVehicle) {
      return null;
    }

    const isWatched = auctionItem.watchers?.isWatched;
    return (
      <WatchButton
        auctionItemId={auctionItem?.id}
        className={classnames(style.button, style.watchButton)}
        isWatched={!!isWatched}
      />
    );
  }, [auctionItem, user]);

  const renderNotesButton = useMemo(() => {
    const item = auctionItem?.inventoryItem || inventoryItem;
    const numOfNotes = Number(item?.notes?.length);
    const hasNotes = numOfNotes > 0;
    return (
      <Button
        className={classnames(style.button, style.notesButton)}
        dataTestId="notes-button"
        onClick={toggleNotes}
        theme="none"
      >
        <Sprite glyph={addNoteGlyph} />
        {hasNotes && <span className={style.hasNotes} data-testid="has-notes-badge" />}
      </Button>
    );
  }, [auctionItem?.inventoryItem, inventoryItem, toggleNotes]);

  const renderShareButton = useMemo(() => {
    const inventoryItemDetails = (auctionItem?.inventoryItem || inventoryItem) as InventoryItem;
    const { year, make, model, trim } = inventoryItemDetails;
    const title = joinStrings([year, make, model, trim], ' ');

    return (
      <Share
        auctionItemId={auctionItem?.id}
        className={classnames(style.button, style.shareButton)}
        inventoryItemId={inventoryItemDetails.id}
        title={title}
        trackUserAction={trackUserAction}
      />
    );
  }, [auctionItem?.id, auctionItem?.inventoryItem, inventoryItem, trackUserAction]);

  const onPrintClick = useCallback(() => {
    trackUserAction(UserAction.VDP_PRINT_CLICK);
    if (!auctionItem) {
      window.open(`${getGraphBaseURL()}${inventoryItem?.printableUrl}`);
    } else {
      window.open(`${getGraphBaseURL()}${auctionItem.inventoryItem.printableUrl}`);
    }
  }, [auctionItem, inventoryItem?.printableUrl, trackUserAction]);

  const renderPrintButton = useMemo(() => {
    return (
      <Button className={classnames(style.button, style.printButton)} onClick={onPrintClick} theme="none">
        <Sprite className={classnames(style.sprite, style.print)} glyph={printGlyph} />
      </Button>
    );
  }, [onPrintClick]);

  const renderArchiveButton = useMemo(() => {
    if (inventoryItem) {
      return <Archive inventoryItem={inventoryItem} onRefreshList={onRefreshList} />;
    }
    if (auctionItem) {
      const { isMyItem, status } = auctionItem;
      const isAuthorized = !!isStaffUser || isMyItem;
      const isMatchingStatus = [AuctionItemStatus.NO_SALE, AuctionItemStatus.SALE_CANCELLED].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return <Archive auctionItem={auctionItem} onRefreshList={onRefreshList} />;
      }
    }
    return null;
  }, [auctionItem, inventoryItem, isStaffUser, onRefreshList]);

  return (
    <div className={style.buttonContainer} data-testid="header-action-buttons">
      {renderNotesButton}
      {renderHoldbackButton}
      {renderPrintButton}
      {renderShareButton}
      {renderWatchButton}
      {renderArchiveButton}
    </div>
  );
};

export default HeaderActionButtons;
