import noConnectionGlyph from 'glyphs/no-connection.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './offline.scss';

const Offline = () => {
  return (
    <div className={style.offline} data-testid="error-offline">
      <Sprite className={style.noConnectionSprite} glyph={noConnectionGlyph} />
      <h2>{t('no_connection_error_message')}</h2>
      <p>{t('not_connected_message')}</p>
      <p>{t('reconnect_message')}</p>
      <Button className={style.refresh} theme="blue">
        {t('refresh')}
      </Button>
    </div>
  );
};

export default Offline;
