import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import { InitialState } from './auctionsModels';
import { auctionsListIsLoading, auctionsListLoaded, auctionsListCleared } from './auctionsActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const auctionsReducer = handleActions(
  {
    [auctionsListIsLoading().type]: (state) => state.set('isLoading', true),

    [auctionsListLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        resultList: List(parseQueryConnectionResponse(action?.payload) || []),
      });
    },

    [auctionsListCleared().type]: () => new InitialState(),
  },
  new InitialState()
);
