import events from 'glyphs/liveLanes/events.svg';

import LabelBadge from 'components/ui/badges/labelBadge';
import ReserveStats from 'components/sections/auctionItem/details/events/reserveStats';
import StatusInfo from 'components/sections/auctionItem/details/events/statusInfo';
import { BidType, MonetaryAmount, TopOffer } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './eventsStatsHeader.scss';

interface Props {
  /** True when the current user is the seller of the auction item. */
  isSeller: boolean;
  /** The reserve amount of the auction item */
  reserve: MonetaryAmount | undefined;
  /** True when the reserve has been met, otherwise false */
  reserveMet: boolean;
  /** The auction item's top offer. */
  topOffer?: TopOffer;
}

const EventsStatsHeader = ({ isSeller, reserve, reserveMet, topOffer }: Props) => {
  return (
    <div className={style.container}>
      <LabelBadge className={style.labelBadge} glyph={events} label={t('events')} position="topRight" theme="green" />
      {topOffer?.amount && (
        <StatusInfo
          className={style.currentBidStatus}
          infoText={topOffer?.amount.formattedAmount}
          subtitle={topOffer?.bidType === (BidType.FLOOR as string) ? t('floor_bid') : topOffer?.company?.name}
          title={t('current_bid')}
        />
      )}
      {isSeller && (
        <div className={style.reserveStats} data-testid="reserve-stats">
          <ReserveStats reserve={reserve} reserveMet={reserveMet} topOffer={topOffer} />
        </div>
      )}
    </div>
  );
};

export default EventsStatsHeader;
