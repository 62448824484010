import { Record as ImmutableRecord } from 'immutable';

import { Maybe } from 'store/shared/api/graph/interfaces/types';
import { RecordFactory } from './interfaces';

/**
 * TODO: [EB-9993] [WEB] Typings - Remove `NullableRecordProps`, ensure that we account for all potential null scenarios for our Models
 * Traverse through the props and make each prop Nullable by default.
 */
export type NullableRecordProps<T> = T extends object
  ? {
      [key in keyof T]: T[key] extends Array<infer V>
        ? Array<V extends object ? NullableRecordProps<V> : V> | null
        : T[key] extends object
          ? NullableRecordProps<T[key]> | null
          : T[key] extends Maybe<infer M>
            ? NullableRecordProps<M> | null
            : T[key] | null;
    }
  : T | null;

type Record = <TProps extends object>(props: NullableRecordProps<TProps>) => RecordFactory<TProps>;

/**
 * This factory will return the same Record that is from `immutable` library
 * except that it will be referencing to our much stronger typed RecordFactory
 */
const BaseRecord = ImmutableRecord as unknown as Record;

export default BaseRecord;
