import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** List of company names. */
  companyNames: string[];
  /** Whether the confirm dialog is open or not. */
  isOpen: boolean;
  /** Callback to handle when the notes confirm dialog is closed. */
  onClose: () => void;
  /** Callback to handle when the notes confirm dialog is confirmed. */
  onConfirm: (confirm: boolean) => void;
}

const NotesConfirmDialog = ({ companyNames, isOpen, onClose, onConfirm }: Props) => (
  <ConfirmDialog isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} theme="blue" title={t('add_note')}>
    <p>{t('add_note_confirm_message')}</p>
    <p>
      <strong>{joinStrings(companyNames)}</strong>
    </p>
  </ConfirmDialog>
);

export default NotesConfirmDialog;
