import ApiRequest from 'store/shared/api/apiRequest';
import { Pagination } from 'constants/enums/pagination';
import { CompanyConnection } from 'store/shared/api/graph/interfaces/types';

export const getCompanies = ({
  first = Pagination.LIST_LENGTH,
  after = null,
  last = null,
  before = null,
  filterBy = null,
  parentCompanyIds = null,
  status = null,
  type = null,
  subType = null,
  city = null,
  region = null,
  country = null,
  visibleRegion = null,
  keyword = null,
  salesRep = null,
  auction = null,
  salesRepId = null,
  auctionId = null,
  auctionRelationshipStatus = null,
  auctionRelationshipPermission = null,
}) => {
  return ApiRequest<{ companyConnection: CompanyConnection }>({
    data: {
      operationName: 'getCompanies',
      query: `
      query(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $filterBy: String,
        $parentCompanyIds: [String],
        $status: [CompanyStatus],
        $type: [CompanyType],
        $subType: [CompanySubType],
        $city: [String],
        $region: [String],
        $country: [String],
        $keyword: String,
        $visibleRegion: [String],
        $salesRep: [String],
        $auction: [String],
        $salesRepId: [String],
        $auctionId: [String],
        $auctionRelationshipStatus: [CompanyAuctionRelationshipStatus],
        $auctionRelationshipPermission: [String],
      ) {
        companyConnection(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          filterBy: $filterBy,
          parentCompanyIds: $parentCompanyIds,
          status: $status,
          type: $type,
          subType: $subType,
          city: $city,
          region: $region,
          country: $country,
          keyword: $keyword,
          visibleRegion: $visibleRegion,
          salesRep: $salesRep,
          auction: $auction,
          salesRepId: $salesRepId,
          auctionId: $auctionId,
          auctionRelationshipStatus: $auctionRelationshipStatus,
          auctionRelationshipPermission: $auctionRelationshipPermission,
        ) {
          edges {
            node {
              id
              name
              numericId
              primaryLocation {
                address1
                address2
                city
                name
                regionCode
                zipCode
              }
            }
          }
        }
      }
    `,
      variables: {
        first,
        after,
        last,
        before,
        filterBy,
        parentCompanyIds,
        status,
        type,
        subType,
        city,
        region,
        country,
        visibleRegion,
        keyword,
        salesRep,
        auction,
        salesRepId,
        auctionId,
        auctionRelationshipStatus,
        auctionRelationshipPermission,
      },
    },
  });
};
