import { ReactNode } from 'react';
import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import InteractiveText from 'components/ui/shared/interactiveText';
import { ErrorMessages } from 'constants/errors';

import style from './formDialogLayouts.scss';

export class FormDialogBody extends BaseClass<{
  className?: string;
  hasErrors?: boolean;
  children: ReactNode | ReactNode[];
}> {
  componentDidUpdate() {
    if (this.props?.hasErrors && this.formBody) {
      this.formBody.scrollTop = 0;
    }
  }

  formBody: HTMLDivElement | null = null;

  render() {
    const { className, children } = this.props;
    return (
      <div
        ref={(div) => {
          this.formBody = div;
        }}
        className={classnames(style.dialogBody, className)}
      >
        {children}
      </div>
    );
  }
}

export const FormDialogFooter = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode | ReactNode[];
}) => (
  <div className={classnames(style.dialogFooter, className)} data-testid="form-footer">
    {children}
  </div>
);

interface FormErrorsProps {
  /** Css to overwrite default styles */
  className?: string;
  /** Data test id */
  dataTestId?: string;
  /** Error messages to display */
  errorMessages: ErrorMessages | undefined;
  /** Whether to use interactive text */
  isInteractiveText?: boolean;
  /** Whether to use small dialog styles */
  isSmallDialog?: boolean;
}

export const FormErrors = ({
  errorMessages,
  className,
  dataTestId,
  isInteractiveText,
  isSmallDialog,
}: FormErrorsProps) => {
  return !errorMessages?.length ? null : (
    <ul
      className={classnames(style.formErrors, isSmallDialog && style.formErrorsSmallDialog, className)}
      data-testid={dataTestId || 'error-messages'}
    >
      {errorMessages.map((err, index) => (
        <li key={`error-${index}`}>
          {isInteractiveText ? (
            <InteractiveText linkClassName={style.formErrorsLink}>{err.message}</InteractiveText>
          ) : (
            err.message
          )}
        </li>
      ))}
    </ul>
  );
};

interface FormSectionType {
  children: ReactNode | ReactNode[];
  className?: string;
  contentClass?: string;
  dataTestId?: string;
  flexDirection?: 'column' | 'row';
  sectionNumber?: string;
  title?: string;
  titleClass?: string;
}

export const FormSection = ({
  children,
  className,
  contentClass,
  dataTestId,
  flexDirection = 'row',
  sectionNumber,
  title,
  titleClass,
}: FormSectionType) => {
  return (
    <section className={classnames(style.section, className)} data-testid={dataTestId}>
      {sectionNumber && <div className={style.sectionNumber}>{sectionNumber}</div>}
      {title && <h3 className={classnames(titleClass)}>{title}</h3>}
      <div
        className={classnames(
          style.sectionChildren,
          flexDirection === 'column' && style.sectionChildrenColumn,
          contentClass
        )}
      >
        {children}
      </div>
    </section>
  );
};

/**
 * Use this as a sibling of FormSection to stack sections without a separation border, or extra spacing between.
 */
export const FormSectionUnified = ({
  children,
  className,
  hasNegativeMargin = true,
  ...props
}: FormSectionType & { hasNegativeMargin?: boolean }) => (
  <FormSection
    {...props}
    className={classnames(
      style.sectionUnified,
      { [style.sectionUnifiedWithoutNegativeMargin]: !hasNegativeMargin },
      className
    )}
  >
    {children}
  </FormSection>
);

/**
 * FormSection wrapper to be used when InputGroups span more than 2 lines.
 */
export const FormSectionMultiLine = ({ children, className, ...props }: FormSectionType) => (
  <FormSection {...props} className={classnames(style.sectionMultiLine, className)}>
    {children}
  </FormSection>
);
