import { Location } from 'constants/reactRouter';
import { RecordOf } from 'store/shared/models/interfaces';
import Record from 'store/shared/models/record';

interface Props {
  routingTarget: RecordOf<Location> | null;
}

const InitialState = Record<Props>({
  routingTarget: null,
});

export { InitialState };
