import * as Sentry from '@sentry/browser';
import { Breadcrumb } from '@sentry/browser';

import User from 'constants/user';
import { LooseObject } from 'constants/objects';

class SentryLogger {
  /**
   * Initialize logger
   */
  static init() {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_ERRORS_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV_NAME,
        release: process.env.REACT_APP_BUILD_VERSION,
        integrations: [Sentry.breadcrumbsIntegration(), Sentry.rewriteFramesIntegration({ root: 'app:///' })],
        // Sometimes errors happen that are out of our control, or that we're not interested in.
        // Filter them out here, so they're not registered with Sentry.
        ignoreErrors: [
          // https://sentry.io/organizations/eblock-inc/issues/2034393922
          'timeout of 0ms exceeded',

          // https://sentry.io/organizations/eblock-inc/issues/2033827293
          'Request aborted',

          // https://eblock-inc.sentry.io/issues/3910539014
          // https://sentry.io/organizations/eblock-inc/issues/1113863322
          'Request failed with status code 400',
          'Request failed with status code 403',

          // The following is almost always triggered if a request is made with an invalid token
          // https://sentry.io/organizations/eblock-inc/issues/2040090742
          "Cannot read property 'data' of undefined",

          // https://sentry.io/organizations/eblock-inc/issues/1132581322
          'Out of memory',
          'out of memory',

          // https://sentry.io/organizations/eblock-inc/issues/1295213810
          '@sentry/browser',

          // https://sentry.io/organizations/eblock-inc/issues/2581163160
          'Illegal invocation',

          // https://sentry.io/organizations/eblock-inc/issues/2510406711
          'can\'t redefine non-configurable property "userAgent"',

          // https://eblock-inc.sentry.io/issues/4080657268
          'Loading CSS chunk',

          // https://eblock-inc.sentry.io/issues/4330470296
          // Common Crazy Egg errors
          'CE: multiple userscripts installed',
          'CE2.cleanPrototype',

          // https://eblock-inc.sentry.io/issues/4994932237
          // Redirect loops
          'ApiRequest - Too many concurrent requests',
        ],
        tracesSampleRate: 0,
      });
    }
  }

  /**
   * Capture Message
   *
   * @param {string} message
   * @param {object} info
   */
  static captureMessage(message: string, info: Record<string, any> | undefined = undefined) {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        if (info) {
          scope.setExtras(info);
        }
        Sentry.captureMessage(message, scope);
      });
    }
  }

  /**
   * Capture Exception
   *
   * @param {object} error
   * @param {object} info
   */
  static captureException(error, info: Record<string, any> | undefined = undefined) {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        if (info) {
          scope.setExtras(info);
        }

        Sentry.captureException(error);
      });
    }
  }

  /**
   * Set User
   *
   * @param {object} user
   */
  static setUser(user: User) {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      Sentry.getCurrentScope().setUser({ id: user?.id });
    }
  }

  /**
   * Clear User
   */
  static clearUser() {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      Sentry.getCurrentScope().setUser(null);
    }
  }

  /**
   * Track a user action
   *
   * @param actionName
   * @param attributes
   */
  static trackUserAction(actionName: string, attributes?: LooseObject) {
    if (process.env.REACT_APP_FEATURE_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
      const breadcrumb: Breadcrumb = { type: 'info', message: `User Action: ${actionName}`, data: attributes };
      Sentry.addBreadcrumb(breadcrumb);
    }
  }
}

export default SentryLogger;
