import { isEqual, omit, pick } from 'lodash-es';

import { Location } from 'constants/reactRouter';

/**
 * Checks if a location's query params have changed.
 *
 * @param {object} location
 * @param {object} locationNext
 * @param {[string]} ignorableQueryParams
 * @returns {boolean}
 */
export const haveParamsChanged = <T extends Location<any>>(
  location: T | undefined,
  locationNext: T | undefined,
  ignorableQueryParams: string[] = ['id']
): boolean => {
  const query = location?.query;
  const queryNext = locationNext?.query;

  return !isEqual(omit(query, ignorableQueryParams), omit(queryNext, ignorableQueryParams));
};

/**
 * Similar to `haveParamsChanged`, but limits check to the passed params, rather than checking all.
 *
 * @param location
 * @param locationNext
 * @param params
 */
export const haveSpecificParamsChange = <T extends Location<any>>(
  location: T,
  locationNext: T,
  params: string[]
): boolean => {
  const query = pick(location?.query, params);
  const queryNext = pick(locationNext?.query, params);

  return !isEqual(query, queryNext);
};

/**
 * Looks up a FacetGroup by name and returns a list of the selected facets.
 *
 * @param facetGroups
 * @param facetName
 */
export const findSelectedFacets = (facetGroups, facetName) => {
  return facetGroups
    ?.find(({ name }) => name === facetName)
    ?.facets?.filter(Boolean)
    ?.filter((f) => f.selected);
};
