import { Set } from 'immutable';
import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  LiveAuctionUpcomingItem,
  getAuctionVideoStreamConfigs,
  getLiveLanes,
  getNextAuctions,
  getUpcomingItems,
} from './liveLanesApi';
import { QueryauctionConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { getPermittedAuctionIds } from 'utils/privateLabelUtils';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const activeLanesChanged = createAction('LIVE_LANES_ACTIVE_LANES_CHANGED');
export const activeTimeSlotIdChanged = createAction('LIVE_LANES_ACTIVE_TIME_SLOT_ID_CHANGED');
export const clearUpcomingItemsSearch = createAction('LIVE_LANES_CLEAR_UPCOMING_ITEMS_SEARCH');
export const isLoading = createAction('LIVE_LANES_SET_IS_LOADING');
export const isLoadingUpcomingItems = createAction('LIVE_LANES_SET_IS_LOADING_UPCOMING_ITEMS');
export const isNotLoading = createAction('LIVE_LANES_UNSET_IS_LOADING');
export const liveLaneEnded = createAction('LIVE_LANES_LANE_ENDED');
export const liveLaneInit = createAction('LIVE_LANES_LANE_INIT');
export const liveLanesCleared = createAction('LIVE_LANES_CLEARED');
export const liveLanesLoaded = createAction('LIVE_LANES_LOADED');
export const pinPreviewItem = createAction('LIVE_LANES_PIN_PREVIEW');
export const searchUpcomingItems = createAction('LIVE_LANES_UPCOMING_ITEMS_SEARCH');
export const setNextAuctions = createAction('LIVE_LANES_SET_NEXT_AUCTIONS');
export const setAuctionVideoStreamConfigs = createAction('LIVE_LANES_SET_AUCTION_VIDEO_STREAM_CONFIGS');
export const updateLiveLane = createAction('LIVE_LANES_UPDATE_LANE');
export const updateLiveLaneUpcomingItems = createAction('LIVE_LANES_UPDATE_LANE_UPCOMING_ITEMS');

export interface AuctionLaneTimeSlot {
  /** The name of a time slot */
  auctionName: string;
  /** The time slot's start time */
  startTime: string;
  /** The id of a time slot */
  timeSlotId: string;
}

/**
 * Gets an array of unique time slots available from LiveLanes[].
 * Additionally, provides the following fields for each time slot:
 *  - `auctionName`
 *  - `startTime`
 *  - `timeSlotId`
 */
export const getAuctionTimeSlots = (lanes): AuctionLaneTimeSlot[] => {
  const timeSlotIds = Set(lanes.map((lane) => lane.timeSlotId)).toJS();
  return timeSlotIds
    ?.map((id) => {
      const { auctionName, startTime, timeSlotId } = lanes.find((lane) => lane.timeSlotId === id);
      return { auctionName, startTime, timeSlotId };
    })
    ?.sort((a, b) => a?.startTime?.localeCompare(b?.startTime));
};

const getLiveLanesOptions = () => {
  const options = {};
  if (process.env.PRIVATE_LABEL) {
    const auctionIds = getPermittedAuctionIds();
    return { ...options, auctionIds };
  }
  return options;
};

export const processGetAuctionVideoStreamConfigs = (dispatch: AppDispatch, options: QueryauctionConnectionArgs) => {
  return getAuctionVideoStreamConfigs(options).then((response) => {
    const formattedResponse = parseQueryConnectionResponse(response?.data?.data?.auctionConnection);

    return dispatch(setAuctionVideoStreamConfigs(formattedResponse));
  });
};

export const processGetLiveLanesData = (dispatch: AppDispatch) => {
  dispatch(isLoading());
  const options = getLiveLanesOptions();
  return getLiveLanes(options)?.then((response) => {
    const formattedResponse = response?.data?.data?.liveLanes;
    dispatch(liveLanesLoaded(formattedResponse));
    return formattedResponse;
  });
};

export const processUpdateLiveLanesData = (dispatch: AppDispatch) => {
  const options = getLiveLanesOptions();
  return getLiveLanes(options)
    .then((response) => response?.data?.data?.liveLanes)
    .then((response) => dispatch(liveLanesLoaded(response)));
};

export const processGetUpcomingItems = (dispatch, options: LiveAuctionUpcomingItem[], isFetchingMore?: boolean) => {
  if (isFetchingMore) {
    dispatch(isLoadingUpcomingItems(true));
  }

  // Fetch each lane asynchronously instead of using batch query
  Promise.all(
    options.map((option) =>
      getUpcomingItems(option)?.then((response) => {
        const formattedResponse = {
          auctionTimeSlotLaneId: option.auctionTimeSlotLaneId,
          lane: response?.data?.data?.auctionItemConnection,
          isFetchingMore,
        };
        dispatch(updateLiveLaneUpcomingItems(formattedResponse));
      })
    )
  )?.then(() => dispatch(isLoadingUpcomingItems(false)));
};

export const processSearchUpcomingItems = (dispatch, options: LiveAuctionUpcomingItem[]) => {
  dispatch(isLoadingUpcomingItems(true));

  // Fetch each lane asynchronously instead of using batch query
  Promise.all(
    options.map((option) =>
      getUpcomingItems(option)?.then((response) => {
        const formattedResponse = {
          auctionTimeSlotLaneId: option.auctionTimeSlotLaneId,
          lane: response?.data?.data?.auctionItemConnection,
        };
        dispatch(searchUpcomingItems(formattedResponse));
      })
    )
  )?.then(() => dispatch(isLoadingUpcomingItems(false)));
};

export const processActiveLanesChanged = (dispatch, laneIds) => {
  dispatch(activeLanesChanged(laneIds));
};

export const processActiveTimeSlotIdChanged = (dispatch, id) => {
  dispatch(activeTimeSlotIdChanged(id));
  dispatch(clearUpcomingItemsSearch());
};

export const processGetNextAuctions = (dispatch) => {
  return getNextAuctions(getLiveLanesOptions()).then((response) => {
    const nextAuctions = parseQueryConnectionResponse(response?.data?.data?.auctionTimeSlotConnection);
    if (nextAuctions?.length) {
      dispatch(setNextAuctions(nextAuctions));
    }
    return response;
  });
};
