import AuctionItem from 'constants/auctionItem';
import Record from 'store/shared/models/record';
import ServerRecord from 'store/shared/models/serverRecord';
import {
  AuctionTimeSlot,
  LiveLane,
  LiveLaneType as LaneType,
  VideoStreamConfig,
} from 'store/shared/api/graph/interfaces/types';
import { AuctionLaneTimeSlot } from 'store/liveLanes/liveLanesActions';
import { RecordOf } from 'store/shared/models/interfaces';

/**
 * Live lanes
 */
interface LiveLanesModel {
  /** The active lanes of an ongoing auction */
  activeLaneIds: (string | null)[];
  /** The id of active time slot */
  activeTimeSlotId: string | null;
  /** The video stream configs by auction id */
  auctionVideoStreamConfigs: Record<string, VideoStreamConfig>;
  /** Whether is loading upcoming auction items or not. */
  isLoadingUpcomingItems: boolean;
  /** A list of the next, upcoming auctions */
  nextAuctions: AuctionTimeSlot[];
  /** Whether the previous item is pinned or not. */
  previewItemPinned: boolean;
  /** All the current live lanes */
  resultList: LiveLaneResultModel[];
  /** The active time slots */
  timeSlots: AuctionLaneTimeSlot[];
}

export const LiveLanesInitialState = ServerRecord<LiveLanesModel>({
  activeLaneIds: [null, null, null, null],
  activeTimeSlotId: null,
  auctionVideoStreamConfigs: {},
  isLoadingUpcomingItems: false,
  nextAuctions: [],
  previewItemPinned: false,
  resultList: [],
  timeSlots: [],
});

/**
 * Live lane result
 */
export interface LiveLaneResultModel extends LiveLane {
  /** Whether the lane is empty of live/upcoming items */
  empty?: boolean;
  /** The remaining upcoming items in the lane */
  upcoming: AuctionItem[] | null;
  /** The total amount of upcoming items in a lane */
  upcomingSearchResults: AuctionItem[] | null;
  /** The total amount of upcoming items in a lane */
  upcomingTotal: number | null;
  /** The total of upcoming items currently loaded for a lane in state (via pagination) */
  upcomingTotalLoaded: number | null;
  /** Lane type (Being used by pub nub) */
  laneType?: LaneType;
  /** The id of the last loaded upcoming item in a lane */
  lastLoadedUpcomingItemId: string | null;
}

export type LiveLaneType = RecordOf<LiveLaneResultModel>;

export const LiveLaneResult = Record<LiveLaneResultModel>({
  id: null,
  auctionId: null,
  status: null,
  startTime: null,
  timerStart: null,
  timerEnd: null,
  timeSlotId: null,
  name: null,
  auctionName: null,
  auctionItemIdsWatching: [],
  auctionItemIdsWithAutoBids: [],
  controlType: null,
  itemsRemaining: null,
  nextItemId: null,
  type: null,
  laneType: null,
  liveItem: null,
  upcoming: null,
  upcomingSearchResults: null,
  upcomingTotal: null,
  upcomingTotalLoaded: null,
  lastLoadedUpcomingItemId: null,
  sequence: 0,
});

export const TimeSlot = Record<AuctionLaneTimeSlot>({
  auctionName: null,
  startTime: null,
  timeSlotId: null,
});
