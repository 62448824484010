import AddRemoveButton from 'components/ui/shared/buttons/addRemoveButton';
import BaseClass from 'components/ui/shared/base';
import CollapsiblePanel from 'components/ui/panels/collapsiblePanel';
import {
  ListItem,
  ListItemBody,
  ListItemFooter,
  ListItemHeader,
  PrimaryTitleLight,
  TertiaryTitle,
  TertiaryTitleLight,
} from 'layouts/list/listItemLayout';
import {
  AuctionFeeMode,
  AuctionServiceFeeTier,
  AuctionServiceMetadata,
  AuctionServiceMetadataParty,
  AuctionServiceTriState,
  AuctionServiceType,
  CompanySubType,
  CurrencyCode,
} from 'store/shared/api/graph/interfaces/types';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { formatCurrency, formatNumber, joinStrings } from 'utils/stringUtils';
import { formatDate } from 'utils/dateUtils';
import { t, tPlural } from 'utils/intlUtils';
import { BuyerShieldBadge, VerifiedBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';

import style from './feesSlideOutListItem.scss';

export interface ServiceFee {
  /** Fee amount, amount in string should be a formatted amount, it will display as is */
  amount: string | number | null;
  /** True display buyer shield badge */
  buyerShield?: AuctionServiceTriState;
  /** List of company sub types */
  companySubTypes?: CompanySubType[];
  /** The currency code to format to */
  currencyCode?: CurrencyCode;
  /** Fee mode (Amount or Percentage) */
  feeMode?: AuctionFeeMode;
  /** Fee tiers to be displayed */
  feeTiers?: AuctionServiceFeeTier[];
  /** Fee type */
  feeType?: AuctionServiceType;
  /** The ID of the service  */
  id: string;
  /** True when the fee is an inventory item fee  */
  isInventoryItemFee: boolean;
  /** A party designated to pay the fee */
  paidBy?: AuctionServiceMetadataParty;
  /** Service meta data */
  serviceMetadata: AuctionServiceMetadata;
  /** Last updated date */
  updated: string;
  /** True display verified badge */
  verified?: AuctionServiceTriState;
}

interface Props extends RouterProps {
  /** Callback to handle onRemove event */
  onRemove: () => void;
  /** Service fee to be displayed */
  serviceFee: ServiceFee;
}

class FeesSlideOutListItem extends BaseClass<Props> {
  getCompanySubTypesLabel = () => {
    const { companySubTypes } = this.props.serviceFee;

    if (companySubTypes?.length) {
      const formattedSubTypes = companySubTypes.map((companySubType) =>
        t(`company_sub_type_${companySubType.toLowerCase()}`)
      );
      return joinStrings(formattedSubTypes, ' • ');
    }

    return null;
  };

  getVerifiedLabel = () => {
    const { buyerShield, verified } = this.props.serviceFee;
    let labels: string[] = [];

    if (verified === AuctionServiceTriState.TRUE && buyerShield === AuctionServiceTriState.TRUE) {
      labels = [t('verified_vehicles'), t('buyer_shield')];
    } else if (verified === AuctionServiceTriState.TRUE && buyerShield !== AuctionServiceTriState.TRUE) {
      labels = [t('verified')];
    } else if (verified !== AuctionServiceTriState.TRUE && buyerShield === AuctionServiceTriState.TRUE) {
      labels = [t('buyer_shield')];
    } else if (verified === AuctionServiceTriState.FALSE) {
      labels = [t('non_verified_vehicles')];
    } else if (verified === AuctionServiceTriState.BOTH) {
      labels = [t('all_vehicles')];
    }

    return joinStrings(labels, ' • ');
  };

  getFeeValueOrRangeLabel = () => {
    const {
      currencyCode,
      amount,
      feeType = AuctionServiceType.FIXED,
      feeMode = AuctionFeeMode.AMOUNT,
    } = this.props.serviceFee;

    // Return fixed value: "$1,234" or "12%"
    if (feeType === AuctionServiceType.FIXED) {
      if (amount === null) {
        return t('tbd');
      }

      // Return formatted amount
      if (typeof amount === 'string') {
        return amount;
      }

      return feeMode === AuctionFeeMode.AMOUNT ? formatCurrency(amount, currencyCode) : `${formatNumber(amount)}%`;
    }

    return t('tiered');
  };

  render() {
    const {
      location,
      serviceFee: {
        buyerShield,
        currencyCode,
        feeTiers,
        isInventoryItemFee,
        paidBy,
        serviceMetadata,
        updated,
        verified,
      },
      onRemove,
    } = this.props;

    const companySubTypesLabel = this.getCompanySubTypesLabel();
    const feeValueOrRangeLabel = this.getFeeValueOrRangeLabel();
    const verifiedLabel = this.getVerifiedLabel();
    const isParked = location.pathname === Route.SELL_PARKED.toString();

    return (
      <ListItem className={style.listItem}>
        <ListItemHeader
          label={joinStrings([serviceMetadata.feeName, t('updated_x', [formatDate(updated, undefined, false)])], ' • ')}
        >
          {(!isInventoryItemFee || isParked) && (
            <AddRemoveButton className={style.removeButton} isAdding={false} onRemove={onRemove} />
          )}
        </ListItemHeader>
        <ListItemBody>
          <PrimaryTitleLight>{feeValueOrRangeLabel}</PrimaryTitleLight>
          <TertiaryTitleLight className={style.assuranceBadges} dataTestId="assurance-badges">
            {verified === AuctionServiceTriState.TRUE && <VerifiedBadge />}
            {buyerShield === AuctionServiceTriState.TRUE && <BuyerShieldBadge className={style.buyerShield} />}
            <span>{verifiedLabel}</span>
          </TertiaryTitleLight>
          {companySubTypesLabel && (
            <TertiaryTitleLight dataTestId="company-sub-types">{companySubTypesLabel}</TertiaryTitleLight>
          )}
          {serviceMetadata.party === AuctionServiceMetadataParty.SELLER &&
            paidBy === AuctionServiceMetadataParty.BUYER && (
              <TertiaryTitleLight dataTestId="paid-by">{t('paid_by_buyer')}</TertiaryTitleLight>
            )}
        </ListItemBody>
        {feeTiers && (
          <ListItemFooter>
            <CollapsiblePanel
              bodyClassName={style.tierRows}
              headingChildren={<TertiaryTitle>{tPlural('tier', feeTiers.length, [feeTiers.length])}</TertiaryTitle>}
            >
              {feeTiers.map((tier) => (
                <div key={tier?.id} className={style.tierRow} data-testid="tier-row">
                  <div>
                    {`${formatCurrency(tier?.startPrice, currencyCode)}
                    ${tier?.endPrice ? ` - ${formatCurrency(tier?.endPrice, currencyCode)}` : '+'}`}
                  </div>
                  <div>
                    {tier?.mode === AuctionFeeMode.AMOUNT
                      ? t('x_fee', [formatCurrency(tier?.value, currencyCode)])
                      : t('percentage_of_sell_price', [tier?.value])}
                  </div>
                </div>
              ))}
            </CollapsiblePanel>
          </ListItemFooter>
        )}
      </ListItem>
    );
  }
}

export default withRouter(FeesSlideOutListItem);
