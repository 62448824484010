import ApiRequest from 'store/shared/api/apiRequest';
import { Auction, AuctionCreateInput, AuctionUpdateInput } from 'store/shared/api/graph/interfaces/types';
import { auctionSettingsFragment } from 'store/shared/api/graph/fragments/auctionSettings';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

export const getAuctionDetails = ({ auctionId = null }) =>
  ApiRequest({
    data: {
      operationName: 'getAuctionDetails',
      query: `
        query ($auctionId: String!) {
          auction(auctionId: $auctionId) {
            id
            auctionMessage
            settings {
              ...auctionSettings
            }
            created
            currencyCode
            description
            lanes {
              defaultActivated
              id
              name
              type
            }
            location {
              ...location
            }
            managingCompanies {
              id
              name
              primaryLocation {
                address1
                address2
                city
                name
                regionCode
                zipCode
              }
            }
            regionCodes
            status
            timeZone
            title
            type
          }
        }
        ${auctionSettingsFragment}
        ${locationFragment}
      `,
      variables: {
        auctionId,
      },
    },
  });

export const createAuctionDetails = (input: AuctionCreateInput) =>
  ApiRequest<{ auctionCreate: Auction }>({
    data: {
      operationName: 'auctionCreate',
      query: `
        mutation ($input: AuctionCreateInput!) {
          auctionCreate(input: $input) {
            id
          }
        }
      `,
      variables: { input },
    },
  });

export const updateAuctionDetails = (input: AuctionUpdateInput) =>
  ApiRequest<{ auctionUpdate: Auction }>({
    data: {
      operationName: 'auctionUpdate',
      query: `
        mutation ($input: AuctionUpdateInput!) {
          auctionUpdate(input: $input) {
            id
            auctionMessage
            currencyCode
            description
            managingCompanies {
              id
              name
              primaryLocation {
                address1
                address2
                city
                name
                regionCode
                zipCode
              }
            }
            regionCodes
            status
            timeZone
            title
            type
          }
        }
      `,
      variables: { input },
    },
  });
