import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectLocations from 'forms/shared/selectLocations';
import { CompanyType, MutationauctionItemTransferRunningArgs } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Error messages. */
  errorMessages?: ErrorMessages;

  /** Whether the dialog is open or not. */
  isOpen: boolean;

  /** Callback function to handle on submit event. */
  onSubmit: (shouldSubmit: boolean, options: Partial<MutationauctionItemTransferRunningArgs>) => void;

  /** Whether is progressing or not. */
  progress: boolean;
}

interface State {
  /** Consigner ID. */
  consignerId?: string;

  /** Location ID. */
  locationId?: string;

  /** Pickup location ID. */
  pickupLocationId?: string;
}

class Dialog extends BaseClass<Props, State> {
  static defaultProps = {
    errorMessages: [],
  };

  state = {
    consignerId: undefined,
    locationId: undefined,
    pickupLocationId: undefined,
  };

  onSubmit = (shouldSubmit) => {
    const { onSubmit } = this.props;
    const { consignerId, locationId, pickupLocationId } = this.state;

    const options: Partial<MutationauctionItemTransferRunningArgs> = { locationId, pickupLocationId, consignerId };

    onSubmit(shouldSubmit, options);
  };

  render() {
    const { consignerId, locationId } = this.state;
    const { isOpen, errorMessages, progress } = this.props;

    const actionable = !!(consignerId && locationId);

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={t('confirm')}
        actionProgress={progress}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="green"
        title={t('transfer_vehicle')}
      >
        <p className={style.instructions}>{t('transfer_vehicle_message')}</p>
        <fieldset className={style.inputGroup}>
          <SelectCompany
            className={style.select}
            connectionVariables={{ type: CompanyType.CONSIGNER }}
            id="selectCompany"
            onChange={(company: SelectCompanyOption) =>
              this.setState({ consignerId: company?.value, locationId: undefined })
            }
            placeholder={t('dealership')}
          />
          <SelectLocations
            companyId={consignerId}
            onLocationChange={(location) => this.setState({ locationId: location?.id })}
            onPickupLocationChange={(pickupLocation) => this.setState({ pickupLocationId: pickupLocation?.id })}
          />
        </fieldset>
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage?.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
