import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Carrier as CarrierType, Facet } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface CarrierDetailsModel {
  /** Carrier details. */
  details: RecordOf<CarrierType>;

  /** Transport job facet list. */
  transportJobs: List<Facet>;
}

export type CarrierDetailsProps = ServerRecordOf<CarrierDetailsModel>;
export type CarrierDetailsPropsJs = ReturnType<CarrierDetailsProps['toJS']>;

export const InitialState = ServerRecord<CarrierDetailsModel>({
  details: null,
  transportJobs: List(),
});

export const Carrier = Record<CarrierType>({
  id: null,
  name: null,
  archived: null,
  primaryLocation: null,
  locations: [],
  deliveryIsLocalOnly: false,
  deliveryNotes: null,
  deliveryWhitelistedRegions: [],
  sdCarrierGuid: null,
  shipmentModes: [],
  notes: [],
  contacts: [],
  created: null,
  updated: null,
});
