import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { onApiError } from 'utils/apiUtils';
import { processSetAndPersistBiddingAsSelection } from 'store/user/userActions';
import { t } from 'utils/intlUtils';
import { userClearActingUser } from 'store/shared/api/graph/mutations/userActAsUser';

interface Props {
  /** Whether the dialog is open */
  isOpen: boolean;
  /** Callback to close the dialog */
  onClose: () => void;
  /** Dispatch function to set current bid selection. */
}

const ClearActingUserFormContainer = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Clear acting user and refresh the application
   */
  const onConfirm = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        setErrorMessages(undefined);
        setIsSubmitting(true);

        userClearActingUser()
          ?.then(() => {
            processSetAndPersistBiddingAsSelection(undefined, dispatch);
            window.location.reload();
          })
          ?.catch((error) => onApiError(error, setErrorMessages))
          ?.finally(() => setIsSubmitting(false));
      } else {
        onClose();
      }
    },
    [dispatch, onClose]
  );

  return (
    <ConfirmDialog
      actionProgress={isSubmitting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      theme="red"
      title={t('clear_acting_user')}
    >
      <FormErrors errorMessages={errorMessages} isSmallDialog />
      <p>{t('clear_acting_user_confirm_message')}</p>
    </ConfirmDialog>
  );
};

export default ClearActingUserFormContainer;
