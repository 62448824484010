import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

import style from './dialogContentLayouts.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;

  /** True when dialog has scrollbar. */
  hasScroll?: boolean;
}

const DialogContent = forwardRef<HTMLDivElement, Props>(({ className, children, hasScroll, ...props }: Props, ref) => {
  return (
    <div ref={ref} className={classnames(style.container, className, hasScroll && style.containerScroll)} {...props}>
      {children}
    </div>
  );
});

export default DialogContent;
