import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectLocations from 'forms/shared/selectLocations';
import { ErrorMessages } from 'constants/errors';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface SubmitOptions {
  /** The id of the selected company. */
  consignerId: string | undefined;
  /** The id of the company's location. */
  locationId: string | undefined;
  /** The id of the pickup location. */
  pickupLocationId: string | undefined;
}

interface Props {
  /** Validation errors. */
  errorMessages: ErrorMessages | undefined;
  /** True when item is an auction item. */
  isAuctionItem: boolean;
  /** True when dialog is open. */
  isOpen: boolean;
  /** True when form is submitting. */
  isSubmitting: boolean;
  /** Function invoked on confirm click. */
  onSubmit: (shouldSubmit: boolean, options: SubmitOptions) => void;
}

interface State {
  /** The id of the selected company. */
  consignerId: string | undefined;
  /** The id of the selected location. */
  locationId: string | undefined;
  /** The id of the selected pickup location. */
  pickupLocationId: string | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      consignerId: undefined,
      locationId: undefined,
      pickupLocationId: undefined,
    };
  }

  onConsignerChange = (consigner: SelectCompanyOption) => {
    this.setState({ consignerId: consigner?.value });
  };

  onSubmit = (shouldSubmit) => {
    const { consignerId, locationId, pickupLocationId } = this.state;
    const { onSubmit } = this.props;

    onSubmit(shouldSubmit, { consignerId, locationId, pickupLocationId });
  };

  render() {
    const { consignerId, locationId } = this.state;
    const { isAuctionItem, isOpen, errorMessages, isSubmitting } = this.props;

    const isActionable = !isAuctionItem ? !!consignerId : consignerId && locationId;

    return (
      <ConfirmDialog
        actionable={!!isActionable}
        actionLabel={t('change_seller')}
        actionProgress={isSubmitting}
        contentDataTestId="changeSeller-content"
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="green"
        title={t('change_seller')}
      >
        <p className={style.instructions}>{t('change_seller_message')}</p>
        <SelectCompany
          className={style.consigners}
          id="select-company"
          onChange={this.onConsignerChange}
          placeholder={t('select_company')}
        />
        {isAuctionItem && (
          <SelectLocations
            companyId={consignerId}
            onLocationChange={(location) => this.setState({ locationId: location?.id })}
            onPickupLocationChange={(pickupLocation) => this.setState({ pickupLocationId: pickupLocation?.id })}
          />
        )}
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage} data-testid="error-messages">
              {errorMessage?.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
