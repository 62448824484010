import { useCallback } from 'react';

import Button from 'components/ui/shared/button';
import { t } from 'utils/intlUtils';

import style from './marketGuideEmptyValuePlaceholder.scss';

interface Props {
  /** The url to redirect to when link icon clicked. */
  redirectUrl: string | undefined;
  /** A callback to trigger a custom event */
  redirectUrlEvent?: () => void;
}

const MarketGuideEmptyValuePlaceholder = ({ redirectUrl, redirectUrlEvent }: Props) => {
  /**
   * Opens redirect url in new tab.
   */
  const onRedirectClick = useCallback(() => {
    redirectUrlEvent?.();
    window.open(`${redirectUrl}`);
  }, [redirectUrl, redirectUrlEvent]);

  return (
    <div className={style.container} data-testid="empty-value-placeholder">
      <p className={style.message}>{t('my_market_guide_no_values_message')}</p>
      <Button className={style.linkButton} onClick={onRedirectClick} theme="none">
        {t('explore_my_market_guide')}
      </Button>
    </div>
  );
};

export default MarketGuideEmptyValuePlaceholder;
