import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { storageManager } from 'utils/storageUtils';
import { usePrevious } from 'hooks/usePrevious';

/**
 * A simple hook to track changes to an item in LocalStorage.
 * @example: const [state] = useLocalStorage('localStorageKeyName');
 */
export const useLocalStorage = <T = string>(name: string): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const localStorageManager = useRef(storageManager.createOrFetchStorage<T>(name));
  const [state, setState] = useState<T | null>(localStorageManager.current.get());
  const statePrev = usePrevious(state);

  useEffect(() => {
    const onStorageChange = () => {
      const stateNext = localStorageManager.current.get();
      if (stateNext !== statePrev) {
        setState(stateNext);
      }
    };

    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [state, statePrev]);

  return [state, setState];
};
