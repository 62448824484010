import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import {
  AuctionItemConnection,
  AuctionTimeSlotLane,
  QueryauctionItemConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionTimeSlotLaneSchema } from 'store/admin/liveLanes/shared/liveLaneSchemas';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';

export const getAuctionItem = ({ auctionItemId }: { auctionItemId: string }) => {
  return ApiRequest<{ auctionItem: AuctionItem }>({
    data: {
      query: `
        query ($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId) {
            asIs
            autoBids {
              count
            }
            auctionTimeSlotLane {
              name
            }
            auction {
              id
            }
            bidTimeline {
              count
              list {
                type
              }
            }
            id
            inventoryItem {
              notes {
                id
              }
              ... on Vehicle {
                autoGradeScore
                captureType
                company {
                  id
                  name
                  auctionRelationships {
                    list {
                      auction {
                        id
                      }
                      status
                      hideSellerNameEnabled
                    }
                  }
                }
                conditionReport {
                  carfaxCanadaClaimAmount {
                    amount
                    formattedAmountRounded
                  }
                  declarations(selected: true) {
                    id
                    name
                  }
                  overallConditionRating
                }
                extensiveVehicleConditionScore
                location {
                  countryCode
                }
                make
                mileage {
                  formattedAmount
                }
                model
                photos(first: 1) {
                  ...listItemPhotoFragment 
                }
                sellerNotes
                trim
                vehicleBuyerShield
                vin
                year
              }
            }
            isAssured
            displayRunNumber
            reserveMet
            saleLights
            startingBid {
              amount
              formattedAmountRounded
            }
            status
            topOffer {
              amount {
                amount
                formattedAmountRounded
              }
              bidType
            }
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const getLiveLane = ({ auctionTimeSlotLaneIds, first, sort, status }: QueryauctionItemConnectionArgs) =>
  ApiRequest<{
    auctionItemConnection: AuctionItemConnection;
    auctionTimeSlotLane: AuctionTimeSlotLane;
  }>({
    data: {
      operationName: 'getLiveLane',
      query: `
        query(
          $auctionTimeSlotLaneIds: [String],
          $auctionTimeSlotLane: String!,
          $first: Int,
          $sort: [Sort]
          $status: [AuctionItemStatus]
        ) {
          auctionItemConnection(
            auctionTimeSlotLaneIds: $auctionTimeSlotLaneIds,
            first: $first,
            sort: $sort,
            status: $status
          ) {
            edges {
              node {
                id
              }
            }
          }
          auctionTimeSlotLane(auctionTimeSlotLaneId: $auctionTimeSlotLane) {
           ${auctionTimeSlotLaneSchema}
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneIds,
        auctionTimeSlotLane: auctionTimeSlotLaneIds?.[0],
        first,
        sort,
        status,
      },
    },
  });
