import { memo, ReactNode } from 'react';

import Tooltip, { TooltipTitle, TooltipDescription } from 'components/ui/shared/tooltips/tooltip';

import style from './badgeTooltip.scss';

interface BadgeTooltipProps {
  /** Id reference from the element that the tooltip will be positioned around */
  anchorId: string;
  /** The description of the tooltip */
  description: ReactNode | string;
  /** The portal id prefix. */
  portalIdPrefix?: string;
  /** True to show tooltip */
  showTooltip?: boolean;
  /** The title of the tooltip */
  title?: string;
}

const BadgeTooltip = ({ description, showTooltip = true, title, ...props }: BadgeTooltipProps) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Tooltip {...props} className={style.container} delayShow={1000} showTooltip={showTooltip}>
      {!!title && <TooltipTitle className={style.title}>{title}</TooltipTitle>}
      {!!description && <TooltipDescription className={style.description}>{description}</TooltipDescription>}
    </Tooltip>
  );
};

export default memo(BadgeTooltip);
