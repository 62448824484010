import { Record } from 'immutable';

export const CompanyCreditLogEntry = Record({
  id: null,
  account: null,
  action: null,
  amount: null,
  auctionItem: null,
  balance: null,
  company: null,
  created: null,
  createdBy: null,
  notes: null,
});
