import BuyerShieldGlyph from 'glyphs/buyerShield.svg';

import AuctionItem from 'constants/auctionItem';
import ScoreBadge from 'components/ui/shared/scores/scoreBadge';
import User from 'constants/user';
import {
  AssuredBadge,
  AutoBidBadge,
  Badges,
  BuyerShieldBadge,
  HoldbackBadge,
  NoteBadge,
  ReranBadge,
  UnreservedBadge,
  VerifiedBadge,
  WatchedBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { getVehicleScoreByInventoryItem } from 'utils/inventoryItemUtils';
import { isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { isVerified } from 'utils/auctionItemUtils';

import style from './auctionItemBadges.scss';

interface Props {
  /** Auction item details. */
  auctionItem: AuctionItem;
  /** Current user. */
  user: User;
}

const AuctionItemBadges = ({ user, auctionItem }: Props) => {
  const { autoBids, history, holdbackActive, inventoryItem, isAssured, reserveMet } = auctionItem;
  const isStaffUser = isAuctionStaff(user, auctionItem?.auction?.id);
  const isGroupManager = isGroupManagerRole(user);
  const vehicleScore = getVehicleScoreByInventoryItem(inventoryItem);

  const badges = {
    archived: auctionItem.archived || inventoryItem.archived,
    buyerShield: !!inventoryItem.vehicleBuyerShield,
    hasAutobids: !!autoBids && (!!autoBids.myAutoBid || !!Number(autoBids.count ?? -1 > 0)),
    hasNotes: Number(inventoryItem.notes?.length) > 0,
    hasScore: !!vehicleScore,
    isAssured,
    isWatched: Number(auctionItem.watchers?.count) > 0,
    isHoldbackActive: !isGroupManager && holdbackActive,
    isReran: Number(history?.count) > 0,
    isReserveMet: reserveMet,
    isVerified: isVerified(inventoryItem.captureType),
  };

  return (
    <Badges className={style.badges}>
      {badges.isAssured && <AssuredBadge />}
      {badges.isVerified && <VerifiedBadge />}
      {badges.buyerShield && <BuyerShieldBadge glyph={BuyerShieldGlyph} />}
      {badges.isHoldbackActive && <HoldbackBadge />}
      {badges.hasAutobids && !isStaffUser && <AutoBidBadge />}
      {badges.hasNotes && <NoteBadge />}
      {badges.isReran && isStaffUser && <ReranBadge text={auctionItem?.history?.count?.toString()} />}
      {badges.hasAutobids && isStaffUser && <AutoBidBadge text={auctionItem?.autoBids?.count?.toString()} />}
      {badges.isWatched && isStaffUser && <WatchedBadge text={auctionItem?.watchers?.count?.toString()} />}
      {badges.hasScore && <ScoreBadge {...vehicleScore} />}
      {badges.isReserveMet && <UnreservedBadge />}
    </Badges>
  );
};

export default AuctionItemBadges;
