import 'react-dates/initialize';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import App from 'app';
import DatadogLogger from 'logging/DatadogLogger';
import SentryLogger from 'logging/SentryLogger';
import configureStore from 'store/configureStore';
import { Locale, bootSequence } from 'utils/intlUtils';

import 'normalize.css';
import './styles/typography.scss';
import './styles/base.scss';

// Initialize error logging
DatadogLogger.init();
SentryLogger.init();

const store = configureStore(browserHistory);
const syncedHistory = syncHistoryWithStore(browserHistory, store);

const renderApp = (locale: Locale = Locale.EN_CA) => {
  const container = document.getElementById('root');
  if (!container) {
    return;
  }

  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <App initialLocale={locale} syncedHistory={syncedHistory} />
    </Provider>
  );
};

// Let's go
bootSequence()?.then(renderApp);
