import AuctionItemType from 'constants/auctionItem';
import ReservedLaneSlot from 'constants/reservedLaneSlot';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlot, FacetedPageInfo, FacetGroup } from 'store/shared/api/graph/interfaces/types';

interface LaneManagerModel {
  /** The `auctionTimeSlot` of the loaded lanes */
  auctionTimeSlot: AuctionTimeSlot;
  /** The `auctionItems` set in the destination `auctionTimeSlotLane` */
  laneDestination: (AuctionItemType | ReservedLaneSlot)[] | undefined;
  /** The pageInfo available for `laneDestination` */
  laneDestinationPageInfo: FacetedPageInfo;
  /** The `auctionItems` set in the source `auctionTimeSlotLane` */
  laneSource: (AuctionItemType | ReservedLaneSlot)[] | undefined;
  /** The facet group filters available for `laneSource` */
  laneSourceFacetGroups: FacetGroup[];
  /** The pageInfo available for `laneSource` */
  laneSourcePageInfo: FacetedPageInfo;
}

export type LaneManagerProps = ServerRecordOf<LaneManagerModel>;

export const InitialState = ServerRecord<LaneManagerModel>({
  auctionTimeSlot: null,
  laneDestination: null,
  laneDestinationPageInfo: null,
  laneSource: null,
  laneSourceFacetGroups: null,
  laneSourcePageInfo: null,
});
