import classnames from 'classnames';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { PhotoSectionItem } from 'components/sections/inventoryItem/addModify/vehicleFormPanes/photoDetailsSection';

import style from './photoDetails.scss';

interface ItemProps {
  /** The item to be rendered. */
  item: PhotoSectionItem;
}

const DragHandle = SortableHandle(({ children }) => children);

const DisabledItem = ({ item: { disableClick, node } }: ItemProps) => (
  <li className={classnames(style.listItem, disableClick && style.cursorNotAllowed)}>
    <div className={classnames(style.listContent, style.cursorDefault, disableClick && style.listPointer)}>{node}</div>
  </li>
);

const SortableItem = SortableElement<ItemProps>(({ item: { deleteButton, dropdown, node } }) => (
  <li className={style.listItem} data-testid="photo-list-item">
    <DragHandle>{node}</DragHandle>
    {deleteButton}
    {dropdown}
  </li>
));

interface SortableListProps {
  /** List of items to be rendered. */
  items: PhotoSectionItem[];
}

const SortableList = SortableContainer<SortableListProps>(({ items }) => {
  return (
    <div className={style.listContainer}>
      {items.map((item, index) =>
        item.disabled ? (
          <DisabledItem key={`item-${index}`} item={item} />
        ) : (
          <SortableItem key={`item-${index}`} disabled={item.disabled} index={index} item={item} />
        )
      )}
    </div>
  );
});

export default SortableList;
