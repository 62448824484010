import removeGlyph from 'glyphs/remove.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import InputGroup from 'forms/shared/inputGroup';
import CurrencyToggle from 'forms/shared/currencyToggle';
import { AuctionServiceFeeTier } from 'store/shared/api/graph/interfaces/types';
import { FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { t } from 'utils/intlUtils';

import style from './addModifyFeeFormSectionTiered.scss';

const AuctionFeesSectionTiered = ({
  onChange = () => {},
  onRemoveFeeTier,
  tier = undefined,
}: {
  onChange?: (tier) => void; // eslint-disable-line no-shadow
  onRemoveFeeTier?: () => void;
  tier?: AuctionServiceFeeTier;
}) => {
  return (
    <FormSection className={onRemoveFeeTier && style.flexFlowNoWrap} dataTestId="tiered-fee-section">
      <InputGroup
        defaultValue={tier?.startPrice}
        label={t('price_from')}
        name="startPrice"
        onChange={(startPrice) => onChange({ ...tier, startPrice })}
        type="currency"
      />
      <InputGroup
        defaultValue={tier?.endPrice}
        label={t('price_to')}
        name="endPrice"
        onChange={(endPrice) => onChange({ ...tier, endPrice })}
        type="currency"
      />
      <CurrencyToggle
        label={t('fee_amount')}
        onChange={(value) => onChange({ ...tier, value })}
        onTypeChange={(type) => onChange({ ...tier, mode: type })}
        type={tier?.mode}
        value={tier?.value}
      />
      {onRemoveFeeTier && (
        <Button className={style.removeTier} onClick={() => onRemoveFeeTier()} theme="none">
          <Sprite className={style.sprite} glyph={removeGlyph} />
        </Button>
      )}
    </FormSection>
  );
};

export default AuctionFeesSectionTiered;
