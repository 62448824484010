export const getDisplayName = () => {
  return process.env.REACT_APP_PRIVATE_LABEL_DISPLAY_NAME || 'EBlock';
};

export const getPermittedAuctionIds = () => {
  const auctionIds = process.env.REACT_APP_PRIVATE_LABEL_PERMITTED_AUCTION_IDS;
  return auctionIds ? JSON.parse(auctionIds) : [];
};

export const isAmericasAuction = () => process.env.REACT_APP_PRIVATE_LABEL_AUCTION_ACCESS_WHITE_LABEL_ID === 'AAA365';
