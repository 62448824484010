import nochat from 'glyphs/nochat.svg';

import Sprite from 'components/ui/shared/sprite';
import { ChatErrorState, ErrorStatus } from 'store/chat/chatModels';
import { t } from 'utils/intlUtils';

import style from './chatError.scss';

interface Props {
  /** Error state of the chat window */
  errorState: ChatErrorState | undefined;
}

const ChatError = ({ errorState }: Props) => {
  return errorState?.status !== ErrorStatus.NONE ? (
    <>
      {errorState?.errors?.map((error) => {
        return (
          <div key={error?.message} className={style.container}>
            <Sprite className={style.glyph} glyph={nochat} />
            <div className={style.errorMessage}>{error?.message ?? t('chat_functionality_unavailable')}</div>
          </div>
        );
      })}
    </>
  ) : null;
};

export default ChatError;
