import classnames from 'classnames';
import moment, { Moment } from 'moment';

import BaseClass from 'components/ui/shared/base';
import { runEvery, stopEvery } from 'utils/runLoopUtils';

const UPDATE_FREQUENCY = 100;

const durations = {
  hour: 60 * 60,
  day: 24 * 60 * 60,
  week: 7 * 24 * 60 * 60,
  default: Number.MAX_SAFE_INTEGER,
};

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;

  /** The format(s) the time will be displayed.*/
  timeFormat: string | Record<string, any>;

  /** The end time of the countdown. */
  end: Moment;
}

interface State {
  /** The top position of the window. Used to determine if user is scrolling through list. */
  topPrev: number;
}

class Countdown extends BaseClass<Props, State> {
  private span: HTMLSpanElement | null = null;

  constructor(props) {
    super(props);

    this.state = {
      topPrev: 0,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    runEvery(UPDATE_FREQUENCY, this.update);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    stopEvery(UPDATE_FREQUENCY, this.update);
  }

  getTimeFormat(end: moment.Moment) {
    const { timeFormat } = this.props;

    if (typeof timeFormat === 'string') {
      return timeFormat;
    }
    if (typeof timeFormat === 'object') {
      const leftSeconds = Math.max(0, end.diff(moment(), 'seconds'));
      return Object.keys(timeFormat)
        .sort((k1, k2) => durations[k1] - durations[k2])
        .reduce((tf, k) => {
          if (!tf && leftSeconds < durations[k]) {
            return timeFormat[k];
          }
          return tf;
        }, null);
    }
    return null;
  }

  update = () => {
    if (this.span) {
      const boundingRect = this.span.getBoundingClientRect();

      // This is crude, but should suffice. If the boundingRect.top is the same
      // twice in a row (i.e. not scrolling) and it's more or less on screen, then
      // update it.
      if (boundingRect.top === this.state.topPrev && boundingRect.top > 0 && boundingRect.top < 2000) {
        this.forceUpdate();
      }
      this.setState({
        topPrev: boundingRect.top,
      });
    }
  };

  render() {
    const { className, end } = this.props;

    const leftSeconds = Math.max(0, end.diff?.(moment(), 'seconds'));

    // @ts-ignore
    // This is legacy code converted from js. Do not re-use.
    // Typescript does not allow .format() on moment.duration.
    const leftFormatted = moment.duration?.(leftSeconds, 'seconds')?.format?.(this.getTimeFormat(end), { trim: false });

    if (leftSeconds < 0) {
      stopEvery(UPDATE_FREQUENCY, this.update);
    }

    return (
      <span
        ref={(span) => {
          this.span = span;
        }}
        className={classnames('countdown', className)}
        data-testid="countdown"
      >
        {leftFormatted}
      </span>
    );
  }
}

export default Countdown;
