import classNames from 'classnames';
import { useMemo } from 'react';

import Button from 'components/ui/shared/button';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import {
  BlueDriverScanStatus,
  BlueDriverSmogScan,
  BlueDriverSmogScanStatus,
} from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './smogSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** Whether the list is loading or not. */
  isLoading?: boolean;
  /** Whether the slide out is open or not. */
  isOpen?: boolean;
  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
  /** Function invoked when refresh button clicked. */
  onRefresh: () => void;
  /** The smog data from the scan event. */
  smogScan: BlueDriverSmogScan | undefined;
  /** The status of the scan event. */
  smogScanStatus?: BlueDriverScanStatus | null;
}

const SmogSlideOut = ({ isLoading, isOpen, onClose, onRefresh, smogScan, smogScanStatus }: Props) => {
  const statusStyle = useMemo(
    () => `status-${smogScan?.status && BlueDriverSmogScanStatus[smogScan?.status]?.toLowerCase()}`,
    [smogScan?.status]
  );

  /**
   * Slide out content rendered when scan has pending status.
   */
  const renderScanPendingPlaceholder = useMemo(() => {
    return (
      <NoResults title={t('scan_pending')}>
        <div className={style.message}>{t('smog_scan_pending_message')}</div>
        <Button className={style.refreshButton} onClick={onRefresh} theme="blue">
          {t('refresh')}
        </Button>
      </NoResults>
    );
  }, [onRefresh]);

  const scanDetails = useMemo(() => {
    if (!smogScan || isLoading) {
      return null;
    }

    return (
      <div className={style.container}>
        <div className={style.header}>
          <div>{t('status')}</div>
          <div className={classNames(style.scanResult, style[statusStyle])} data-testid="scan-event-result">
            {smogScan.status}
          </div>
        </div>
        <ul className={style.list}>
          <li className={classNames(style.listItem, style.listItemHeader)}>
            <div>{t('system_monitors')}</div>
            <div className={style.status}>{t('status')}</div>
          </li>
          {smogScan.completeMonitors?.map((monitor) => (
            <li key={monitor} className={style.listItem} data-testid="complete-monitor">
              <div>{monitor}</div>
              <div>{t('complete')}</div>
            </li>
          ))}
          {smogScan.incompleteMonitors?.map((monitor) => (
            <li key={monitor} className={style.listItem} data-testid="incomplete-monitor">
              <div>{monitor}</div>
              <div>{t('incomplete')}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }, [isLoading, statusStyle, smogScan]);

  return (
    <SlideOut isOpen={isOpen} onClose={onClose} title={t('smog_readiness')}>
      {smogScanStatus === BlueDriverScanStatus.PENDING && renderScanPendingPlaceholder}
      {smogScanStatus !== BlueDriverScanStatus.PENDING && <div className={style.scanDetails}>{scanDetails}</div>}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default SmogSlideOut;
