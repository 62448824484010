import BlackBookValuesTooltip from 'components/sections/inventoryItem/details/feesAndValues/blackBookValues/blackBookValuesTooltip';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { blackBookHeaderTranslationMap, getBlackBookValues } from 'utils/formatting/blackBookFormatUtils';
import { t } from 'utils/intlUtils';

import style from './blackBookValues.scss';

interface Props {
  /** Inventory item values of the inventory item. */
  inventoryItemValues: InventoryItemValue[] | undefined;
}

const BlackBookValues = ({ inventoryItemValues }: Props) => {
  const blackBookValueFilteredAndSorted = getBlackBookValues(inventoryItemValues);

  if (!blackBookValueFilteredAndSorted?.length) {
    return null;
  }

  return (
    <div className={style.blackBookValues} data-testid="black-book-values">
      {blackBookValueFilteredAndSorted.map((value) => (
        <div key={value.type.id} className={style.row}>
          <div className={style.label}>{t(blackBookHeaderTranslationMap[value.type.id])} </div>
          <div className={style.value}>{value.value.formattedAmount}</div>
        </div>
      ))}
      <div className={style.tooltipRow}>
        <BlackBookValuesTooltip
          logoClassName={style.logo}
          showLogo
          tooltipDescriptionClassName={style.tooltipDescription}
        />
      </div>
    </div>
  );
};

export default BlackBookValues;
