import { handleActions } from 'redux-actions';

import {
  auctionItemAddNote,
  auctionItemDetailsLoaded,
  auctionItemDetailsUpdating,
  auctionItemSellerDetailsLoaded,
  auctionItemUpdateInventoryItem,
  clearAuctionItemDetails,
  holdbackSet,
  permissionDenied,
  preloadAuctionItem,
  updateArchived,
  updateAuctionItemOrder,
} from './auctionItemDetailsActions';
import { AuctionItemDetails, InitialState } from './auctionItemDetailsModels';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { getUrlParams } from 'utils/urlUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { isLatestRequestSequence } from 'utils/apiUtils';

export const auctionItemDetailsReducer = handleActions(
  {
    [auctionItemDetailsUpdating().type]: (state) => state.setUpdating(),

    [clearAuctionItemDetails().type]: () => new InitialState(),

    [auctionItemAddNote().type]: (state, action) => {
      return state.merge({
        details: new AuctionItemDetails({
          ...state.toJS().details,
          inventoryItem: {
            ...state.details.inventoryItem,
            notes: [action.payload.note, ...(state.details.inventoryItem.notes?.filter(Boolean) || [])],
          },
        }),
      });
    },

    [preloadAuctionItem().type]: (state, action) => {
      if (action.payload && !action.payload._ended) {
        const auctionItem = new AuctionItemDetails(action.payload);
        return state.setUpdating().set('details', auctionItem);
      }
      return state;
    },

    [permissionDenied().type]: (state) => {
      return state.setError('PERMISSION_DENIED');
    },

    [auctionItemDetailsLoaded().type]: (state, action) => {
      const auctionItem = state.toJS();
      const auctionItemNext = action.payload;
      const currentId = auctionItem.details ? auctionItem.details.id : null;
      const loadedId = auctionItemNext.id;
      const onlyIfDifferent = !!auctionItemNext.onlyIfDifferent;
      const isDifferent = onlyIfDifferent && currentId !== loadedId;
      const isValidSequence = isLatestRequestSequence(auctionItemNext.requestSequence);

      if (!isValidSequence || isDifferent) {
        return state;
      }

      if (auctionItem.details && currentId === loadedId) {
        // NOTE:
        // This relates to the temporary PubNub hack as seen in `auctionItemsReducer.js`.
        // When an item ends in the Timed Auction, we need to display it as ended,
        // but also include the highest bidder -- which needs to be fetched because it
        // isn't available in the PubNub bid event (for privacy reasons). This should
        // be removed, and reverted back to the previous implementation once sockets
        // replace PubNub.
        const { status, _error } = auctionItem.details;
        const { status: statusNext, _error: _errorNext, format: formatNext } = auctionItemNext;
        const { view } = getUrlParams();
        const isStatusNoLongerLive = statusNext !== status && status === AuctionItemStatus.LIVE;
        const isItemNoLongerAccessible = _errorNext !== _error && _errorNext === 'PERMISSION_DENIED';

        if (view !== 'true' && !isAuctionFormat(formatNext) && (isStatusNoLongerLive || isItemNoLongerAccessible)) {
          return state.set(
            'details',
            new AuctionItemDetails({
              ...auctionItemNext,
              _ended: true,
            })
          );
        }
      }

      const freshDetails = new AuctionItemDetails({
        ...action.payload,
        _ended: false,
      });

      if (currentId === loadedId) {
        return state.setLoaded().merge({ details: freshDetails });
      }

      return state.setLoaded().set('details', freshDetails);
    },

    [auctionItemSellerDetailsLoaded().type]: (state) => {
      const auctionItem = state.toJS();

      return state.merge({
        details: new AuctionItemDetails({
          ...auctionItem.details,
        }),
      });
    },

    [auctionItemUpdateInventoryItem().type]: (state, action) => {
      if (!state?.details?.id) {
        return state;
      }

      const auctionItem = state.toJS();
      return state.merge({
        details: new AuctionItemDetails({
          ...auctionItem.details,
          inventoryItem: {
            ...auctionItem.details.inventoryItem,
            ...action.payload,
          },
        }),
      });
    },

    [holdbackSet().type]: (state, action) => {
      const auctionItem = state.toJS();
      return state.merge({
        details: new AuctionItemDetails({
          ...auctionItem.details,
          holdback: action.payload.holdback,
          holdbackActive: action.payload.holdbackActive,
        }),
      });
    },

    [updateArchived().type]: (state, action) => {
      const auctionItem = state.toJS();
      return state.set(
        'details',
        new AuctionItemDetails({
          ...auctionItem.details,
          ...action.payload,
        })
      );
    },

    [updateAuctionItemOrder().type]: (state, action) => {
      return state?.setIn(['details', 'order'], action?.payload);
    },
  },
  new InitialState()
);
