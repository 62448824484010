import classnames from 'classnames';
import { Link } from 'react-router';
import { ReactElement, ReactNode } from 'react';

import arrowLeftGlyph from 'glyphs/arrow-left.svg';

import Button from 'components/ui/shared/button';
import Image from 'components/ui/shared/image';
import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';
import Sprite, { Glyph } from 'components/ui/shared/sprite';
import { Spinner } from 'components/ui/loading/loading';
import { t } from 'utils/intlUtils';

import style from './accountLayouts.scss';

interface AccountOverlayProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * AccountOverlay - container template
 */
const AccountOverlay = ({ children, className }: AccountOverlayProps) => {
  return <div className={classnames(style.container, className)}>{children}</div>;
};

interface AccountWrapperProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * AccountWrapper - container and wrapper template
 */
const AccountWrapper = ({ children, className }: AccountWrapperProps) => {
  return (
    <AccountOverlay className={className}>
      <div className={style.wrapper}>{children}</div>
    </AccountOverlay>
  );
};

interface AccountHeaderProps {
  /** The component's child elements to be rendered. */
  children?: ReactNode;
}

/**
 * AccountHeader - header template for account elements
 */
const AccountHeader = ({ children }: AccountHeaderProps) => {
  return (
    <div className={style.backButtonContainer}>
      <Link className={style.backButtonLink} to="/account">
        <Sprite className={style.sprite} glyph={arrowLeftGlyph} />
        <span className={style.header}>{t('my_account')}</span>
      </Link>
      {children}
    </div>
  );
};

interface AccountBodyProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * AccountBody - main body template for account elements
 */
const AccountBody = ({ children, className }: AccountBodyProps) => {
  return <div className={classnames(className)}>{children}</div>;
};

interface AccountBlockProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * AccountBlock - block template with a white background
 */
const AccountBlock = ({ children, className }: AccountBlockProps) => {
  return <div className={classnames(style.block, className)}>{children}</div>;
};

interface AccountSectionProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;
  /** Optional CSS styling for the section. */
  className?: string;
  /** Optional CSS styling for the section content. */
  contentClass?: string;
  /** The displayed title of the section. */
  title?: string;
  /** Optional CSS styling for the section title. */
  titleClass?: string;
  /** Tooltip for the title. */
  titleTooltip?: ReactElement | string;
}

/**
 * AccountSection - section template with a formatted title
 */
const AccountSection = ({
  children,
  className,
  contentClass,
  title,
  titleClass,
  titleTooltip,
}: AccountSectionProps) => {
  return (
    <section className={classnames(style.section, className)}>
      {title && (
        <div className={classnames(style.sectionTitle, titleClass)}>
          <p className={titleClass}>{title}</p>
          {titleTooltip && (
            <InfoTooltip
              className={style.labelTooltip}
              description={titleTooltip}
              glyphClassName={style.tooltipGlyph}
            />
          )}
        </div>
      )}
      <div className={classnames(style.sectionChildren, contentClass)}>{children}</div>
    </section>
  );
};

/**
 * AccountSection wrapper to be used when InputGroups span more than 2 lines.
 */
export const AccountSectionMultiLine = ({ children, className, ...props }: AccountSectionProps) => (
  <AccountSection {...props} className={classnames(style.sectionMultiLine, className)}>
    {children}
  </AccountSection>
);

interface AccountContentProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling for the section. */
  className?: string;

  /** The content details. */
  details?: ReactNode;

  /** Optional CSS styling for the section details. */
  detailsClass?: string;

  /** Optional CSS styling for the icon. */
  imgClass?: string;

  /** The icon of the account content. */
  imgGlyph?: Glyph;

  /** The url of the image source. */
  imgUrl?: string;

  /** The content sub-details. */
  subDetails?: string;

  /** Optional CSS styling for the section sub-details. */
  subDetailsClass?: string;

  /** The displayed title of the section. */
  title: string;

  /** Optional CSS styling for the section title. */
  titleClass?: string;
}

/**
 * AccountContent - displays formatted content on the left hand side
 */
const AccountContent = ({
  children,
  imgUrl,
  imgGlyph,
  imgClass,
  title,
  titleClass,
  details,
  detailsClass,
  subDetails,
  subDetailsClass,
  className,
}: AccountContentProps) => {
  return (
    <div className={classnames(style.contentWrapper, className)}>
      <div className={style.content}>
        {typeof imgUrl !== 'undefined' && (
          <span className={classnames(style.icon, imgClass)}>
            {imgUrl && <Image alt="" className={classnames(style.img, imgClass)} disableSpinner src={imgUrl} />}
            {imgGlyph && <Sprite className={style.spriteImg} glyph={imgGlyph} />}
          </span>
        )}
        <div className={style.contentText}>
          <p className={classnames(style.contentTitle, titleClass)}>{title}</p>
          {details && <p className={classnames(style.contentDetails, detailsClass)}>{details}</p>}
          {subDetails && <p className={classnames(style.contentDetails, subDetailsClass)}>{subDetails}</p>}
        </div>
      </div>
      {children}
    </div>
  );
};

interface AccountSubmitFooterProps {
  /** The footer details. */
  details?: string;

  /** True when submit button is disabled. */
  disabled?: boolean;

  /** The displayed text on the save button. */
  saveButtonText: string;

  /** The title of the footer. */
  title: string;
}

/**
 * AccountSubmitFooter - footer template and formatted display utilizing the AccountContent component
 */
const AccountSubmitFooter = ({ title, details, disabled, saveButtonText }: AccountSubmitFooterProps) => {
  return (
    <div className={style.submitFooter}>
      <AccountContent details={details} title={title}>
        <div className={style.buttonContainer}>
          <Button className={style.button} disabled={disabled} theme="blue" type="submit">
            {disabled ? (
              <Spinner className={style.spinner} spinnerStyleClassName={style.spinnerStyle} />
            ) : (
              saveButtonText
            )}
          </Button>
        </div>
      </AccountContent>
    </div>
  );
};

interface AccountDefaultLayoutProps {
  /** The component's child elements to be rendered. */
  children: ReactNode;

  /** Optional CSS styling. */
  className?: string;
}

/**
 * AccountDefaultLayout - default layout for common layout pages
 */
const AccountDefaultLayout = ({ children, className }: AccountDefaultLayoutProps) => {
  return (
    <AccountWrapper>
      <AccountHeader />
      <AccountBody>
        <AccountBlock className={className}>{children}</AccountBlock>
      </AccountBody>
    </AccountWrapper>
  );
};

export {
  AccountDefaultLayout,
  AccountOverlay,
  AccountWrapper,
  AccountBody,
  AccountHeader,
  AccountBlock,
  AccountSection,
  AccountContent,
  AccountSubmitFooter,
};
