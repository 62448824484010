// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","content":"content_cbe91cc","thumbnailsContainer":"thumbnailsContainer_c38702b","thumbnailList":"thumbnailList_b967df8","justifyThumbnails":"justifyThumbnails_c24774d","thumbnailListItem":"thumbnailListItem_fe259ba","thumbnail":"thumbnail_ff7061a","section":"section_d71ff5b","sectionName":"sectionName_ac42fbf","damagesContent":"damagesContent_ec28b56","damagesContainer":"damagesContainer_cdfe95f","description":"description_d501a65","textContent":"textContent_cdfdaf2"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"content\":\"content_cbe91cc\",\"thumbnailsContainer\":\"thumbnailsContainer_c38702b\",\"thumbnailList\":\"thumbnailList_b967df8\",\"justifyThumbnails\":\"justifyThumbnails_c24774d\",\"thumbnailListItem\":\"thumbnailListItem_fe259ba\",\"thumbnail\":\"thumbnail_ff7061a\",\"section\":\"section_d71ff5b\",\"sectionName\":\"sectionName_ac42fbf\",\"damagesContent\":\"damagesContent_ec28b56\",\"damagesContainer\":\"damagesContainer_cdfe95f\",\"description\":\"description_d501a65\",\"textContent\":\"textContent_cdfdaf2\"}";
        // 1734114921146
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  