import ApiRequest from 'store/shared/api/apiRequest';
import { Company, QueryinternalSaleCompaniesArgs } from 'store/shared/api/graph/interfaces/types';

export const getCompaniesForInternalSale = ({ auctionId, inventoryItemId }: QueryinternalSaleCompaniesArgs) =>
  ApiRequest<{ internalSaleCompanies: Company[] }>({
    data: {
      operationName: 'getCompaniesForInternalSale',
      query: `
        query ($auctionId: String!, $inventoryItemId: String!) {
          internalSaleCompanies(auctionId: $auctionId, inventoryItemId: $inventoryItemId) {
            id
            name
            numericId
          }
        }
      `,
      variables: {
        auctionId,
        inventoryItemId,
      },
    },
  });
