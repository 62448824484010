import { createAction } from 'redux-actions';

import AuctionItem from 'constants/auctionItem';
import {
  AuctionItemStatus,
  AuctionItemUpdateInput,
  AuctionTimeSlotLane,
  MutationauctionItemPhysicalClearBidsArgs,
  MutationauctionItemPhysicalEndArgs,
  QueryauctionItemConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import {
  auctionItemOverrideOnlineBid,
  auctionItemPhysicalClearBids,
  auctionItemPhysicalEnd,
  auctionItemPhysicalPause,
  auctionItemPhysicalRerun,
  auctionItemPhysicalResume,
  auctionItemPhysicalRetractBid,
  auctionItemPhysicalStart,
  auctionItemSetBidIncrement,
  auctionItemSetReserveMet,
  auctionItemSetStartPrice,
  auctionItemSubmitFloorBid,
  auctionPhysicalLaneEnd,
  auctionPhysicalLaneStart,
  getAuctionItem,
  getLiveLane,
} from 'store/admin/liveLanes/auctionClerk/auctionClerkApi';
import { auctionItemUpdate } from 'store/shared/api/graph/mutations/auctionItemUpdate';
import { parseQueryConnectionResponse, parseQueryParams } from 'utils/apiUtils';

export type MutationAuctionItemEndProps = MutationauctionItemPhysicalEndArgs & { auctionTimeSlotLaneId: string };

export const auctionItemLoaded = createAction('ADMIN_AUCTION_CLERK_AUCTION_ITEM_LOADED');
export const auctionItemPhysicalEnded = createAction('ADMIN_AUCTION_CLERK_AUCTION_ITEM_ENDED');
export const auctionItemPhysicalStarted = createAction('ADMIN_AUCTION_CLERK_AUCTION_ITEM_STARTED');
export const auctionItemUpdated = createAction('ADMIN_AUCTION_CLERK_AUCTION_ITEM_UPDATED');
export const auctionTimeSlotLaneUpdated = createAction('ADMIN_AUCTION_CLERK_AUCTION_TIME_SLOT_LANE_UPDATED');
export const clearAuctionClerk = createAction('ADMIN_AUCTION_CLERK_CLEAR_AUCTION_CLERK');
export const isLoading = createAction('ADMIN_AUCTION_CLERK_SET_IS_LOADING');
export const isNotUpdating = createAction('ADMIN_AUCTION_CLERK_SET_IS_NOT_UPDATING');
export const isUpdating = createAction('ADMIN_AUCTION_CLERK_SET_IS_UPDATING');
export const liveLaneLoaded = createAction('ADMIN_AUCTION_CLERK_LIVE_LANE_LOADED');
export const runItemsLoaded = createAction('ADMIN_AUCTION_CLERK_RUN_ITEMS_LOADED');

export const processAuctionItemEnd = (options: MutationAuctionItemEndProps, dispatch) => {
  return auctionItemPhysicalEnd(options)?.then((response) => {
    dispatch(auctionItemPhysicalEnded(response?.data?.data?.auctionItemPhysicalEnd));
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalEnd));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemPhysicalEnd?.auctionTimeSlotLane));
  });
};

export const processAuctionItemPause = (options, dispatch) => {
  return auctionItemPhysicalPause(options)?.then((response) => {
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalPause));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemPhysicalPause?.auctionTimeSlotLane));
  });
};

export const processAuctionItemResume = (options, dispatch) => {
  return auctionItemPhysicalResume(options)?.then((response) => {
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalResume));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemPhysicalResume?.auctionTimeSlotLane));
  });
};

export const processAuctionItemRerun = (options, dispatch) => {
  return auctionItemPhysicalRerun(options)?.then((response) => {
    dispatch(auctionItemPhysicalStarted(options));
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalRerun));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemPhysicalRerun?.auctionTimeSlotLane));
    return response?.data?.data?.auctionItemPhysicalRerun;
  });
};

export const processAuctionItemStart = (options, dispatch) => {
  return auctionItemPhysicalStart(options)?.then((response) => {
    dispatch(auctionItemPhysicalStarted(options));
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalStart));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemPhysicalStart?.auctionTimeSlotLane));
    return response?.data?.data?.auctionItemPhysicalStart;
  });
};

export const processGetAuctionItem = async (options: { auctionItemId: string }, dispatch) => {
  const auctionItemDetails = (await getAuctionItem(options))?.data?.data?.auctionItem;
  dispatch(auctionItemLoaded(auctionItemDetails));
};

export type LiveLaneResponse = Promise<{
  auctionTimeSlotLane?: AuctionTimeSlotLane;
  ranItems?: AuctionItem[];
  upcomingItems?: AuctionItem[];
}>;

const formatGetLiveLaneResponse = (options: QueryauctionItemConnectionArgs, response): Awaited<LiveLaneResponse> => {
  const auctionItems: AuctionItem[] = parseQueryConnectionResponse(response?.data?.data?.auctionItemConnection) ?? [];
  const hasUpcomingStatus: boolean = !!options.status?.includes(AuctionItemStatus.UPCOMING);
  return {
    auctionTimeSlotLane: response?.data?.data?.auctionTimeSlotLane,
    ranItems: options.keyword || !hasUpcomingStatus ? auctionItems : undefined,
    upcomingItems: options.keyword || hasUpcomingStatus ? auctionItems : undefined,
  };
};

export const processGetLiveLane = (options: QueryauctionItemConnectionArgs, dispatch): LiveLaneResponse => {
  dispatch(isUpdating());
  return getLiveLane(parseQueryParams(options))?.then((response) => {
    const formattedResponse = formatGetLiveLaneResponse(options, response);
    dispatch(liveLaneLoaded(formattedResponse));
    return formattedResponse;
  });
};

export const processGetRunListItems = async (options, dispatch, isFullQuery = false) => {
  dispatch(isUpdating());
  getLiveLane(parseQueryParams(options), isFullQuery)?.then((response) => {
    const formattedResponse = formatGetLiveLaneResponse(options, response);
    dispatch(runItemsLoaded(formattedResponse));
  });
};

export const processLaneEnd = (options, dispatch) => {
  return auctionPhysicalLaneEnd(options)?.then((response) =>
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionPhysicalLaneEnd))
  );
};

export const processLaneStart = (options, dispatch) => {
  return auctionPhysicalLaneStart(options)?.then((response) =>
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionPhysicalLaneStart))
  );
};

export const processSetBidIncrement = (options, dispatch) => {
  return auctionItemSetBidIncrement(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemSetBidIncrement))
  );
};

export const processSetReserveMet = (options, dispatch) => {
  return auctionItemSetReserveMet(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemReserveMet))
  );
};

export const processSetFloorBid = (options, dispatch) => {
  return auctionItemSubmitFloorBid(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemSubmitFloorBid))
  );
};

export const processSetStartPrice = (options, dispatch) => {
  return auctionItemSetStartPrice(options)?.then((response) => {
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemSetStartPrice));
    dispatch(auctionTimeSlotLaneUpdated(response?.data?.data?.auctionItemSetStartPrice?.auctionTimeSlotLane));
  });
};

export const processOverrideOnlineBid = (options, dispatch) => {
  return auctionItemOverrideOnlineBid(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemOverrideOnlineBid))
  );
};

export const processRetractPhysicalBid = (options, dispatch) => {
  return auctionItemPhysicalRetractBid(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalRetractBid))
  );
};

export const processAuctionItemUpdate = async (options: AuctionItemUpdateInput, dispatch) => {
  return auctionItemUpdate(options)?.then((response) =>
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemUpdate))
  );
};

export const processClearBids = (options: MutationauctionItemPhysicalClearBidsArgs, dispatch) => {
  return auctionItemPhysicalClearBids(options)?.then((response) => {
    dispatch(auctionItemUpdated(response?.data?.data?.auctionItemPhysicalClearBids));
  });
};
