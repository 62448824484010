import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store/configureStore';
import { LooseObject } from 'constants/objects';
import { Pagination } from 'constants/enums/pagination';
import { QueryauctionItemConnectionArgs, SavedFilter } from 'store/shared/api/graph/interfaces/types';
import { auctionItemClaimIfBids } from 'store/shared/api/graph/mutations/auctionItemClaimIfBids';
import {
  getAuctionItemsEndTimes,
  getAuctionItemsList,
  getSavedFilters,
  savedFilterCreate,
  savedFilterDelete,
  savedFilterUpdate,
} from './auctionItemsApi';
import { getEnabledCompanyIds } from 'utils/userUtils';
import { getOptionsWithRequestSequence, parseQueryParams } from 'utils/apiUtils';

export type QueryAuctionItemListProps = QueryauctionItemConnectionArgs & { resultsPerPage?: number };

export const auctionItemsListIsLoading = createAction('AUCTION_ITEMS_SET_IS_LOADING');
export const auctionItemsListIsUpdating = createAction('AUCTION_ITEMS_SET_IS_UPDATING');
export const auctionItemsListLoaded = createAction('AUCTION_ITEMS_LIST_LOADED');
export const auctionItemsListIsAdding = createAction('AUCTION_ITEMS_LIST_ADDING');
export const auctionItemsListAdded = createAction('AUCTION_ITEMS_LIST_ADDED');
export const auctionItemsListClear = createAction('AUCTION_ITEMS_LIST_CLEAR');
export const auctionItemsListUpdate = createAction('AUCTION_ITEMS_LIST_UPDATE');
export const auctionItemsEndTimesUpdate = createAction('AUCTION_ITEMS_END_TIMES_UPDATE');
export const auctionItemsListUpdateItem = createAction('AUCTION_ITEMS_LIST_UPDATE_ITEM');
export const auctionItemsListRemoveItem = createAction('AUCTION_ITEMS_LIST_REMOVE_ITEM');
export const auctionItemsListSetSubmittingItemIds = createAction('AUCTION_ITEMS_LIST_SET_SUBMITTING_IDS');
export const auctionItemsBidEvent = createAction('AUCTION_ITEMS_LIST_BID_EVENT');
export const auctionItemsEnded = createAction('AUCTION_ITEMS_LIST_AUCTION_ITEMS_ENDED');
export const incrementNewItemsCount = createAction('AUCTION_ITEMS_LIST_INCREMENT_NEW_ITEMS_COUNT');
export const resetNewItemsCount = createAction('AUCTION_ITEMS_LIST_RESET_NEW_ITEMS_COUNT');
export const updateFacetCount = createAction('AUCTION_ITEMS_LIST_FACETS_UPDATE_COUNT');
export const removeAuctionItem = createAction('AUCTION_ITEMS_LIST_REMOVE_AUCTION_ITEM');

export const setFilter = createAction('AUCTION_ITEMS_LIST_SET_FILTER');
export const unsetFilters = createAction('AUCTION_ITEMS_LIST_UNSET_FILTERS');
export const updateFacetGroups = createAction('AUCTION_ITEMS_LIST_UPDATE_FACET_GROUPS');
export const savedFiltersLoaded = createAction('AUCTION_ITEMS_SAVED_FILTERS_LOADED');
export const clearSavedFilters = createAction('AUCTION_ITEMS_CLEAR_SAVED_FILTERS');
export const bidEventItemUpdate = createAction('AUCTION_ITEMS_LIST_BID_EVENT_UPDATE_ITEM');
export const bidEventFacetsUpdate = createAction('AUCTION_ITEMS_LIST_BID_EVENT_UPDATE_FACETS_GROUPS');

export const processGetAuctionItemsList =
  (options: QueryAuctionItemListProps) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(auctionItemsListIsUpdating());

    const response = await getAuctionItemsList(
      parseQueryParams(options, options.resultsPerPage || Pagination.LIST_LENGTH)
    );
    const formattedResponse = getOptionsWithRequestSequence(response, response?.data?.data);
    const state = getState();
    const routingLocation = state.routing.locationBeforeTransitions;

    dispatch(resetNewItemsCount());
    dispatch(auctionItemsListLoaded({ ...formattedResponse, routingLocation }));
    return formattedResponse;
  };

/**
 * Used mainly for grid view, loads additional items to the list
 */
export const processLoadMoreAuctionItems = (options: QueryAuctionItemListProps, dispatch) => {
  dispatch(auctionItemsListIsAdding(true));
  return getAuctionItemsList(parseQueryParams(options, options.resultsPerPage || Pagination.LIST_LENGTH))
    .then((response) => dispatch(auctionItemsListAdded(response?.data?.data)))
    .finally(() => dispatch(auctionItemsListIsAdding(false)))
    .catch((e) => console.warn(e));
};

/**
 * Updates our live auction lists
 */
export const processUpdateAuctionItemsList =
  (options: QueryAuctionItemListProps) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const response = await getAuctionItemsList(parseQueryParams(options));
    const formattedResponse = response?.data?.data?.auctionItemConnection;
    const state = getState();
    const routingLocation = state.routing.locationBeforeTransitions;

    return dispatch(
      auctionItemsListUpdate({
        list: formattedResponse,
        context: options,
        auctionItemEndTimes: formattedResponse?.facetGroups?.find((facet) => facet.name === 'auctionItemEndTime')
          ?.facets,
        routingLocation,
      })
    );
  };

export const processClearAuctionItemsList = (dispatch) => {
  dispatch(resetNewItemsCount());
  dispatch(auctionItemsListClear());
};

export const processGetSavedFilters = async (dispatch) => {
  dispatch(clearSavedFilters());
  dispatch(savedFiltersLoaded((await getSavedFilters())?.data?.data?.user?.savedFilters));
};

export const processSaveFilter = async (
  id: string | null,
  name: string,
  isDefault: boolean,
  params: LooseObject,
  dispatch: AppDispatch
): Promise<{ id: string | undefined; savedFilters: SavedFilter[] }> => {
  if (id) {
    // Update filter
    await savedFilterUpdate(id, name, isDefault, params);
    const savedFilters = (await getSavedFilters())?.data?.data?.user?.savedFilters?.filter(Boolean) || [];
    dispatch(savedFiltersLoaded(savedFilters));
    return { id, savedFilters };
  }

  // Save new filter
  const savedFilter: SavedFilter | undefined = (await savedFilterCreate(name, isDefault, params))?.data?.data
    ?.savedFilterCreate;
  const savedFilters: SavedFilter[] = (await getSavedFilters())?.data?.data?.user?.savedFilters?.filter(Boolean) || [];
  dispatch(savedFiltersLoaded(savedFilters));
  return { id: savedFilter?.id, savedFilters };
};

export const processDeleteSavedFilter = async (id, dispatch) => {
  await savedFilterDelete(id);
  dispatch(savedFiltersLoaded((await getSavedFilters())?.data?.data?.user?.savedFilters));
};

export const processAuctionItemBidEvent = (options, dispatch) => {
  const { message, user, filterBy, isStaffUser, isInList } = options;
  const { auctionItemId, becameReserveMet, bidConsignerIds, lastAutoBid, winningConsignerId } = message;

  const companyIds = getEnabledCompanyIds(user);
  const hasBid = bidConsignerIds.some((id) => getEnabledCompanyIds(user)?.includes(id));
  const isWinning = isStaffUser ? false : !!getEnabledCompanyIds(user)?.includes(winningConsignerId);
  const isLosing = isStaffUser
    ? false
    : !isWinning && hasBid && getEnabledCompanyIds(user)?.some((id) => bidConsignerIds?.includes(id));
  const formattedOptions = { ...options, companyIds, hasBid, isWinning, isLosing };

  dispatch(bidEventItemUpdate(formattedOptions));
  dispatch(bidEventFacetsUpdate(formattedOptions));

  const updateList = (filterType) => {
    if (!isInList && filterType === filterBy) {
      const updateOptions = { ...options, auctionItemIds: [auctionItemId] };
      dispatch(processUpdateAuctionItemsList(updateOptions));
    }
  };

  if (isWinning && !lastAutoBid) {
    updateList('Winning');
  } else if (companyIds?.includes(bidConsignerIds[1])) {
    updateList('Outbid');
  }
  if (becameReserveMet) {
    updateList('Reserve Met');
  }
};

export const processUpdateAuctionItemEndTimes = (dispatch) => {
  dispatch(auctionItemsListIsUpdating());
  return getAuctionItemsEndTimes().then((response) => {
    const facetGroups = response?.data?.data?.auctionItemConnection.facetGroups;
    const auctionItemEndTimes = facetGroups?.find((facet) => facet.name === 'auctionItemEndTime')?.facets;
    dispatch(auctionItemsEndTimesUpdate(auctionItemEndTimes));
    return auctionItemEndTimes;
  });
};

export const processAuctionItemClaimIfBids = async (options, dispatch) => {
  try {
    dispatch(auctionItemsListSetSubmittingItemIds([options?.auctionItemId]));
    const response = await auctionItemClaimIfBids(options);

    if (response?.data?.data?.auctionItemClaimIfBids) {
      dispatch(auctionItemsListUpdateItem(response?.data?.data?.auctionItemClaimIfBids[0]));
    }
    dispatch(auctionItemsListSetSubmittingItemIds([]));

    return response;
  } catch (error) {
    // Reset submission ids; bubble error up to component
    dispatch(auctionItemsListSetSubmittingItemIds([]));
    throw error;
  }
};
