import AuctionItem from 'constants/auctionItem';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  AuctionLaneControlType,
  VehicleCaptureType,
} from 'store/shared/api/graph/interfaces/types';

export const ranAuctionItemStatus: AuctionItemStatus[] = [
  AuctionItemStatus.AWAITING_CHECKOUT,
  AuctionItemStatus.FAILED,
  AuctionItemStatus.IN_IF_BID,
  AuctionItemStatus.NO_SALE,
  AuctionItemStatus.SALE_CANCELLED,
  AuctionItemStatus.SOLD,
];

export const isLiveAuction = (auctionItem: Pick<AuctionItem, 'status' | 'format'>) => {
  return auctionItem?.status === AuctionItemStatus.LIVE && isAuctionFormat(auctionItem?.format);
};

export const isLiveBuyNow = (auctionItem: Pick<AuctionItem, 'status' | 'format'>) => {
  return auctionItem?.status === AuctionItemStatus.LIVE && auctionItem?.format === AuctionItemFormat.GROUNDED;
};

export const isUpcoming = (auctionItem: Pick<AuctionItem, 'status' | 'format'>) => {
  return auctionItem?.status === AuctionItemStatus.UPCOMING && isAuctionFormat(auctionItem?.format);
};

export const isLiveAppraisal = (auctionItem: Pick<AuctionItem, 'status' | 'format'>) => {
  return auctionItem?.status === AuctionItemStatus.LIVE && auctionItem?.format === AuctionItemFormat.APPRAISAL;
};

export const isUpcomingManual = (auctionItem: Pick<AuctionItem, 'auctionTimeSlotLane' | 'status'>) => {
  return (
    auctionItem?.status === AuctionItemStatus.UPCOMING &&
    auctionItem?.auctionTimeSlotLane?.controlType === AuctionLaneControlType.MANUAL
  );
};

export const isLiveTimedOffer = (auctionItem: Pick<AuctionItem, 'status' | 'format'>) => {
  return auctionItem?.status === AuctionItemStatus.LIVE && auctionItem?.format === AuctionItemFormat.TIMED_OFFER;
};

/**
 * Whether the auction has already ran or not
 * @param auctionItem
 */
export const isRanItem = (auctionItem: Pick<AuctionItem, 'status'>) => {
  return ranAuctionItemStatus.includes(auctionItem?.status);
};

/**
 * Whether the auction is running or not.
 * @param auctionItem
 */
export const isRunning = (auctionItem: Pick<AuctionItem, 'status'>) => {
  return [AuctionItemStatus.LIVE, AuctionItemStatus.PAUSED].includes(auctionItem?.status);
};

/**
 * Whether the format is auction/auction physical or not.
 * @param auctionItemFormat
 */
export const isAuctionFormat = (auctionItemFormat: AuctionItemFormat) => {
  return auctionItemFormat === AuctionItemFormat.AUCTION || auctionItemFormat === AuctionItemFormat.AUCTION_PHYSICAL;
};

/**
 * Whether the capture type is verified or not.
 * @param vehicleCaptureType
 */
export const isVerified = (vehicleCaptureType?: VehicleCaptureType) => {
  return !!vehicleCaptureType && vehicleCaptureType !== VehicleCaptureType.SELF_CAPTURE;
};

/**
 * Whether the capture type is verified extensive vehicle condition or not.
 * @param vehicleCaptureType
 */
export const isVerifiedExtensiveVehicleCondition = (vehicleCaptureType?: VehicleCaptureType) => {
  return vehicleCaptureType === VehicleCaptureType.VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE;
};

/**
 * Whether the capture type is verified auto grade or not.
 * @param vehicleCaptureType
 */
export const isVerifiedAutoGrade = (vehicleCaptureType?: VehicleCaptureType) => {
  return (
    vehicleCaptureType === VehicleCaptureType.VERIFIED_AUTOGRADE_CAPTURE ||
    vehicleCaptureType === VehicleCaptureType.VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE
  );
};

/**
 * Whether the currently running auction item's lane control type is manual or not.
 * @param auctionItem
 */
export const isManuallyControlled = (auctionItem?: Pick<AuctionItem, 'auctionTimeSlotLane' | 'status'>): boolean => {
  return (
    !!auctionItem &&
    [AuctionItemStatus.INIT, AuctionItemStatus.LIVE, AuctionItemStatus.PAUSED].includes(auctionItem?.status) &&
    auctionItem?.auctionTimeSlotLane?.controlType === AuctionLaneControlType.MANUAL
  );
};
