import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import InventoryItem from 'constants/inventoryItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { ErrorMessages } from 'constants/errors';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { auctionItemSellDirect } from 'store/inventoryItemDetails/inventoryItemDetailsApi';
import { getVehiclePath } from 'components/sections/admin/adminHelpers';
import { t } from 'utils/intlUtils';
import { trackUserActionWithInventoryItemAttributes } from 'utils/analyticsUtils';

interface Props extends RouterProps {
  /** Inventory item. */
  inventoryItem: InventoryItem;
}

interface State {
  /** Error Messages. */
  errorMessages: ErrorMessages | undefined;
  /** True when dialog is open. */
  isOpen: boolean;
  /** Whether the dialog is submitting or not. */
  isSubmitting?: boolean;
}

class SellDirect extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: undefined, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit, options) => {
    if (shouldSubmit) {
      const { router, inventoryItem } = this.props;

      const formattedOptions = {
        ...options,
        inventoryItemId: inventoryItem.id,
      };

      this.setState({ isSubmitting: true });
      trackUserActionWithInventoryItemAttributes(UserAction.VDP_SELL_DIRECT_CONFIRM_CLICK, inventoryItem);

      auctionItemSellDirect(formattedOptions)
        .then((response) => {
          const auctionItem = response?.data?.data?.auctionItemSellDirect;
          const vehiclePath = getVehiclePath(auctionItem);
          this.setState({ errorMessages: undefined, isOpen: false });
          router.push(vehiclePath);
        })
        .catch(this.onApiError)
        .finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: undefined, isOpen: false });
    }
  };

  render() {
    const { inventoryItem } = this.props;
    const { errorMessages, isOpen, isSubmitting } = this.state;

    return (
      <>
        <OperationButton
          dataTestId="sellDirect-button"
          glyph={submitGlyph}
          onClick={() => {
            this.setState({ isOpen: true });
            trackUserActionWithInventoryItemAttributes(UserAction.VDP_SELL_DIRECT_CLICK, inventoryItem);
          }}
          theme="green"
        >
          {t('sell_direct')}
        </OperationButton>
        <Dialog
          errorMessages={errorMessages}
          inventoryItem={inventoryItem}
          isOpen={isOpen}
          isSubmitting={!!isSubmitting}
          onSubmit={this.onSubmit}
        />
      </>
    );
  }
}

export default withRouter(SellDirect);
