import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import { MutationauctionItemClaimIfBidsArgs } from 'store/shared/api/graph/interfaces/types';

export const auctionItemClaimIfBids = ({ auctionItemIds, markClaimed }: MutationauctionItemClaimIfBidsArgs) =>
  ApiRequest<{ auctionItemClaimIfBids: Pick<AuctionItem, 'id' | 'ifBidClaimedByUserId' | 'ifBidClaimedByUserName'> }>({
    data: {
      query: `
        mutation ($auctionItemIds: [ID!]!, $markClaimed: Boolean!) {
          auctionItemClaimIfBids(auctionItemIds: $auctionItemIds, markClaimed: $markClaimed) {
            id
            ifBidClaimedByUserId
            ifBidClaimedByUserName
          }
        }
      `,
      variables: {
        auctionItemIds,
        markClaimed,
      },
    },
  });
