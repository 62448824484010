/**
 * @function validateField
 * @param {any} item
 * @param {any} defaultValue value to be returned if object is not found
 * @returns {any} boolean when its boolean, value when it's a valid value; otherwise return defaultValue
 */
const validateField = (item, defaultValue: string | null = null) => {
  if (item === false || item === 0 || item === '' || Number.isNaN(item)) {
    return item;
  }
  return item || defaultValue || null;
};

/**
 * @deprecated - Use optional chaining instead.
 *
 * @function getField (reduce and validate backend data to avoid app crashes)
 * @param {object} object
 * @param {string} path
 * @param {any} defaultValue value to be returned if object is not found
 * @returns {object} prop value or defaultValue
 */
export const getField = (object, path, defaultValue: any = null) =>
  path.split('.').reduce((value, key) => {
    if (!value && key) {
      return defaultValue;
    }
    return value && validateField(value[key], defaultValue);
  }, object || {});

/**
 * @deprecated - Use optional chaining instead.
 */
export const getAmount = (object) => getField(object, 'amount');

/**
 * @deprecated - Use optional chaining instead.
 */
export const getFormattedAmount = (object) => getField(object, 'formattedAmount');

export const deleteFalsyValues = (value) => {
  const object = { ...value };
  Object.keys(object).forEach((key) => {
    if (!object[key]) {
      delete object[key];
    }
  });
  return object;
};
