import { useCallback, useState } from 'react';
import classnames from 'classnames';

import cancelGlyph from 'glyphs/operationIcons/circle-cancel.svg';
import submitGlyph from 'glyphs/operationIcons/circle-check.svg';

import Button from 'components/ui/shared/button';
import InputText, { InputTextProps, InputTextValueType } from 'forms/shared/inputText';
import OutsideClick from 'components/ui/shared/directives/outsideClick';
import Sprite from 'components/ui/shared/sprite';
import { Flex } from 'layouts/shared/flexbox';
import { SpinnerCentered } from 'components/ui/loading/loading';

import style from './inputTextEditable.scss';

type Props = InputTextProps & {
  onCancel?: () => void;
  onSubmit?: (options) => void;
  isSubmitting?: boolean;
};

const InputTextEditable = ({ isSubmitting = false, onCancel = () => {}, onSubmit = () => {}, ...props }: Props) => {
  const [defaultValue, setDefaultValue] = useState<InputTextValueType | undefined>(props?.defaultValue);

  return (
    <OutsideClick onClick={onCancel}>
      <div className={style.container}>
        <InputText
          autoFocus
          theme="default"
          {...props}
          className={classnames(style.input, props?.className)}
          defaultValue={defaultValue}
          onChange={useCallback(
            (value) => {
              setDefaultValue(value);
              props?.onChange?.(value);
            },
            [props]
          )}
        />
        <Flex className={style.buttons}>
          <Button
            className={classnames(style.inputButton, style.submit)}
            onClick={useCallback(() => onSubmit(defaultValue), [defaultValue, onSubmit])}
            theme="none"
          >
            <Sprite glyph={submitGlyph} />
          </Button>
          <Button className={classnames(style.inputButton, style.cancel)} onClick={onCancel} theme="none">
            <Sprite glyph={cancelGlyph} />
          </Button>
        </Flex>
        {isSubmitting && (
          <div className={style.submittingOverlay}>
            <SpinnerCentered />
          </div>
        )}
      </div>
    </OutsideClick>
  );
};

export default InputTextEditable;
