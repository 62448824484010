import classnames from 'classnames';
import { ReactNode } from 'react';

import EditButton from 'components/ui/shared/buttons/editButton';
import { User } from 'store/shared/api/graph/interfaces/types';
import { formatDate } from 'utils/dateUtils';
import { formatUserName } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './feeValueItem.scss';

interface FeeValueRowProps {
  /** The child elements to be rendered. */
  children: ReactNode;
}

export const FeeValueRow = ({ children }: FeeValueRowProps) => <div className={style.row}>{children}</div>;

interface FeeValueItemType {
  /** The amount value of the item. */
  amount?: number;
  /** The formatted amount. */
  amountFormatted?: string;
  /** The timestamp the item was created. */
  created?: string;
  /** Details of the user that created the item. */
  createdBy?: User;
  /** The expiry date value of the item. */
  expiryDate?: string;
  /** The id of the item. */
  id: string;
  /** The note value of the item. */
  note?: string;
  /** The title of the item. */
  title: string;
  /** The last timestamp the item was updated. */
  updated?: string;
  /** The user that last updated the item. */
  updatedBy?: User;
}

interface Props {
  /** True when the edit button is displayed. */
  isUpdatable: boolean;
  /** The item details. */
  item: FeeValueItemType;
  /** Function invoked on edit button click. */
  onEdit: () => void;
}

const FeeValueItem = ({ item, isUpdatable = false, onEdit = () => {} }: Props) => {
  const testId = `${item?.id}-feeValueItem`;

  return (
    <div className={style.listItem}>
      <div className={style.listItemInner} data-testid={testId}>
        <div className={classnames(style.listItemCost, isUpdatable && style.isUpdatable)}>
          <div>{item?.title}</div>
          <div>{Number(item?.amount) < 0 ? `(${item?.amountFormatted?.replace('-', '')})` : item?.amountFormatted}</div>
          {isUpdatable && <EditButton className={style.editButton} onClick={onEdit} theme="blue-pencil" />}
        </div>
        {item?.created && item?.createdBy && (
          <div className={style.lighterText}>
            {t('created_x_by_x', [formatDate(item?.created), formatUserName(item?.createdBy)])}
          </div>
        )}
        {item?.updated && item?.updatedBy && (
          <div className={style.lighterText}>
            {t('updated_x_by_x', [formatDate(item?.updated), formatUserName(item?.updatedBy)])}
          </div>
        )}
        <div>{item?.note}</div>
        {item?.expiryDate && (
          <div className={style.lighterText}>{t('expires_x', [formatDate(item?.expiryDate, undefined, false)])}</div>
        )}
      </div>
    </div>
  );
};

export default FeeValueItem;
