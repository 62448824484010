import classnames from 'classnames';
import { Merge } from 'type-fest';
import { isEqual } from 'lodash-es';
import { memo, useCallback, useEffect, useState } from 'react';

import Location from 'components/sections/inventoryItem/addModify/selectLocation/location';
import { BuilderOptionLocation, Location as LocationType } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';
import { useIsFirstRender } from 'hooks/useIsFirstRender';
import { usePrevious } from 'hooks/usePrevious';

import style from './selectLocation.scss';

interface Props {
  /** Whether has error or not. */
  hasError: boolean;
  /** Whether is compound enabled or not. */
  isCompoundEnabled: boolean;
  /** Whether is dialog theme or not. */
  isDialogTheme?: boolean;
  /** Whether is small theme or not. */
  isSmallTheme?: boolean;
  /** Whether is vehicle builder active or not. */
  isVehicleBuilderActive?: boolean;
  /** Location id. */
  locationId?: string;
  /** Location list. */
  locations?: LocationType[];
  /** Callback function triggered when location information changed. */
  onChange: (locationId?: string | null, pickupLocationId?: string | null, isCompoundEnabled?: boolean) => void;
  /** Callback function triggered when compound information changed. */
  onCompoundChange?: (
    locationId?: string | null,
    pickupLocationId?: string | null,
    isCompoundEnabled?: boolean
  ) => void;
  /** Pickup location id. */
  pickupLocationId?: string;
  /** Pickup location list. */
  pickupLocations?: Merge<BuilderOptionLocation, { location: Omit<BuilderOptionLocation['location'], 'id'> }>[];
  /** Starting location list. */
  startingLocations?: Merge<BuilderOptionLocation, { location: Omit<BuilderOptionLocation['location'], 'id'> }>[];
}

const SelectLocation = ({
  hasError,
  isCompoundEnabled,
  isDialogTheme,
  isSmallTheme,
  isVehicleBuilderActive,
  locations = [],
  onChange,
  onCompoundChange = () => {},
  pickupLocations = [],
  startingLocations = [],
  ...props
}: Props) => {
  const [errorId, setErrorId] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<string | null>(null);
  const [pickupLocationId, setPickupLocationId] = useState<string | null>(null);
  const prevLocations = usePrevious(locations);
  const isFirstRender = useIsFirstRender();

  /**
   * Set location and pickup location on change
   */
  useEffect(() => {
    if (!isFirstRender && !isEqual(prevLocations, locations)) {
      setLocationId(null);
      onChange(props.locationId, props.pickupLocationId, isCompoundEnabled);
    }

    if (isVehicleBuilderActive || props.locationId || props.pickupLocationId) {
      let locationIdNext;
      let pickupLocationIdNext;

      if (isVehicleBuilderActive) {
        // Populate with Vehicle Builder selected values
        const selectedLocation = startingLocations.find(({ selected }) => selected);
        const selectedPickupLocation = pickupLocations.find(({ selected }) => selected);
        locationIdNext = (selectedLocation || {}).id;
        pickupLocationIdNext = (selectedPickupLocation || {}).id;
      } else {
        // ... or with `locationId` and `pickupLocationId` values
        locationIdNext = props.locationId;
        pickupLocationIdNext = props.pickupLocationId;
      }

      if (locationIdNext === pickupLocationIdNext) {
        pickupLocationIdNext = null;
      }

      setLocationId(locationIdNext);
      setPickupLocationId(pickupLocationIdNext);
    }
  }, [
    isCompoundEnabled,
    isFirstRender,
    isVehicleBuilderActive,
    locations,
    onChange,
    pickupLocations,
    prevLocations,
    props.locationId,
    props.pickupLocationId,
    startingLocations,
  ]);

  /**
   * Set location id on change
   */
  const onLocationChange = useCallback(
    (value) => {
      let isCompoundEnabledNext = isCompoundEnabled;
      let pickupLocationIdNext = pickupLocationId;

      if (!isCompoundEnabledNext) {
        pickupLocationIdNext = value;
      } else if (pickupLocationIdNext === value) {
        isCompoundEnabledNext = false;
        pickupLocationIdNext = null;
      }
      setLocationId(value);
      setPickupLocationId(pickupLocationIdNext);
      setErrorId(null);
      onChange(value, pickupLocationIdNext, isCompoundEnabledNext);
    },
    [isCompoundEnabled, onChange, pickupLocationId]
  );

  /**
   * Set compound location on change
   */
  const onCompoundLocationChange = useCallback(
    (value) => {
      let pickupLocationIdNext = value;
      let isCompoundEnabledNext = isCompoundEnabled;

      if (pickupLocationId && value === pickupLocationId) {
        pickupLocationIdNext = null;
        isCompoundEnabledNext = false;
      }
      setPickupLocationId(pickupLocationIdNext);
      setErrorId(null);
      onChange(locationId, pickupLocationIdNext, isCompoundEnabledNext);
    },
    [isCompoundEnabled, locationId, onChange, pickupLocationId]
  );

  /**
   * Set is compound on change
   */
  const onIsCompoundChange = useCallback(
    (value) => {
      let pickupLocationIdNext: string | null = null;

      if (isVehicleBuilderActive) {
        pickupLocationIdNext = !value ? locationId : pickupLocationId;
        onCompoundChange(locationId, pickupLocationIdNext, value);
      } else {
        onChange(locationId, pickupLocationIdNext, value);
      }
      setPickupLocationId(pickupLocationIdNext);
    },
    [isVehicleBuilderActive, locationId, onChange, onCompoundChange, pickupLocationId]
  );

  /**
   * Generate locations
   */
  const getLocations = () => {
    if (!isVehicleBuilderActive) {
      return locations;
    }

    // Merge locations, strip duplicates
    return [...startingLocations, ...pickupLocations]
      .map(({ id, selected, location }) => ({ id, selected, ...location }))
      .filter((location, index, self) => index === self.findIndex((_location) => _location.id === location.id));
  };

  return (
    <div className={classnames(style.selectLocation, isSmallTheme && style.isSmallTheme)}>
      <Location
        hasError={hasError}
        ignoredLocation={(isCompoundEnabled && pickupLocationId) || undefined}
        isCompoundEnabled={isCompoundEnabled}
        isDialogTheme={isDialogTheme}
        isPickupLocation
        isSmallTheme={!!isSmallTheme}
        label={t('choose_current_location')}
        locations={getLocations()}
        onChange={onLocationChange}
        onCompoundChange={onIsCompoundChange}
        selectedLocation={locationId || undefined}
      />
      {isCompoundEnabled && (
        <Location
          hasError={errorId === 'pickupLocationId'}
          ignoredLocation={locationId || undefined}
          isCompoundLocation
          isSmallTheme={!!isSmallTheme}
          label={t('choose_compound_location')}
          locations={getLocations()}
          onChange={onCompoundLocationChange}
          selectedLocation={pickupLocationId || undefined}
        />
      )}
    </div>
  );
};

export default memo(SelectLocation);
