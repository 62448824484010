import { useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SelectCompany, { SelectCompanyOption } from 'forms/shared/selectCompany';
import SelectUser from 'forms/shared/selectUser';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import {
  CompanyAuctionRelationshipStatus,
  CompanyStatus,
  MutationauctionItemChangeBuyerArgs,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Auction item to change buyer. */
  auctionItem: AuctionItem;
  /** Error messages. */
  errorMessages?: ErrorMessages;
  /** Whether the dialog is open or not. */
  isOpen?: boolean;
  /** Whether the dialog is submitting or not. */
  isSubmitting?: boolean;
  /** Callback function that handles on submit event. */
  onSubmit: (shouldSubmit: boolean, options?: MutationauctionItemChangeBuyerArgs) => void;
}

const Dialog = ({ isOpen, errorMessages, isSubmitting, auctionItem, onSubmit }: Props) => {
  const [companyId, setCompanyId] = useState<string>();
  const [userId, setUserId] = useState<string | null>(null);

  /**
   * Submit change buyer request
   */
  const onConfirm = useCallback(
    (shouldSubmit: boolean = false) => {
      const options: MutationauctionItemChangeBuyerArgs | undefined =
        companyId && userId ? { auctionItemId: auctionItem.id, companyId, userId } : undefined;

      onSubmit(shouldSubmit, options);
    },
    [auctionItem.id, companyId, onSubmit, userId]
  );

  return (
    <ConfirmDialog
      actionable={!!(companyId && userId)}
      actionLabel={t('confirm')}
      actionProgress={isSubmitting}
      contentDataTestId="changeBuyer-content"
      isOpen={!!isOpen}
      onConfirm={onConfirm}
      theme="green"
      title={t('change_buyer')}
    >
      <fieldset className={style.inputGroup}>
        <SelectCompany
          connectionVariables={{
            auctionId: auctionItem?.auction?.id,
            auctionRelationshipPermission: AuctionRelationshipPermission.CAN_BUY,
            auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
            status: CompanyStatus.ENABLED,
          }}
          excludedCompanyIds={[auctionItem?.buyer?.company?.name].filter(Boolean)}
          onChange={(company: SelectCompanyOption) => {
            setCompanyId(company?.value);
            setUserId(null);
          }}
          placeholder={t('select_company')}
        />
        <SelectUser
          companyId={companyId}
          id="selectUser"
          isDisabled={!companyId}
          onChange={(user: SelectOption) => setUserId(user?.value)}
          placeholder={t('select_user')}
        />
      </fieldset>
      {errorMessages &&
        errorMessages.map((errorMessage) => (
          <p key={errorMessage.name} className={style.errorMessage}>
            {errorMessage.message}
          </p>
        ))}
    </ConfirmDialog>
  );
};

export default Dialog;
