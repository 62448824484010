import topOfferGlyph from 'glyphs/top-offer.svg';

import AuctionItem from 'constants/auctionItem';
import Sprite from 'components/ui/shared/sprite';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { t, tPlural } from 'utils/intlUtils';

import style from './topOffer.scss';

interface RanBeforeRowProps {
  /** The auction item details. */
  details: AuctionItem;
  /** Function invoked on button click. */
  onClick?: (event) => void;
}

const RanBeforeRow = ({ details, onClick }: RanBeforeRowProps) => {
  if (details.history && details.history.count) {
    const topOffer = details.history.topOffers?.[0];
    return (
      <Row dataTestId="status-row-ran-before" name={t('ran_before')} onClick={onClick}>
        {tPlural('x_times', details?.history?.count, [details?.history?.count])}
        <Sprite className={style.topOfferIcon} glyph={topOfferGlyph} />
        <div>{!topOffer ? t('no_offers') : topOffer.amount.formattedAmountRounded}</div>
      </Row>
    );
  }
  return null;
};

export default RanBeforeRow;
