import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import CheckoutItemPreview from 'components/sections/checkout/forms/checkoutItemPreview';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InfoTooltip from 'components/ui/shared/tooltips/infoTooltip';
import Loading, { Spinner } from 'components/ui/loading/loading';
import MultiSelectCard from 'forms/shared/multiSelectCard';
import SelectLocations from 'forms/shared/selectLocations';
import {
  AuctionItem,
  AuctionItemOrder,
  AuctionItemOrderLineItem,
  PaymentSubType,
  PaymentType,
} from 'store/shared/api/graph/interfaces/types';
import {
  FormDialogBody,
  FormDialogFooter,
  FormErrors,
  FormSection,
  FormSectionUnified,
} from 'layouts/formLayouts/formDialogLayouts';
import {
  InvoiceContainer,
  InvoiceRow,
  InvoiceSection,
  InvoiceTotal,
} from 'components/sections/checkout/forms/checkoutInvoice';
import { SecondaryTitle } from 'layouts/list/listItemLayout';
import {
  auctionItemOrderServiceAdd,
  auctionItemOrderServiceRemove,
  auctionItemOrderUpdate,
} from 'store/shared/api/graph/mutations/checkout';
import { getPaymentTypes } from 'store/shared/api/graph/queries/paymentTypes';
import { getVehiclePath } from 'components/sections/admin/adminHelpers';
import { t } from 'utils/intlUtils';

import style from './checkoutForms.scss';

class CheckoutSingleForm extends BaseClass<
  {
    auctionItem: AuctionItem | undefined;
    isSubmitting: boolean;
    onGoBack?: (shouldRefresh?: boolean) => void;
    onSubmit?: () => void;
    onUpdateAuctionItemOrder?: (auctionItemOrder: AuctionItemOrder | undefined) => void;
  },
  {
    buyerPickupLocationId?: string;
    errorMessages?: any[];
    isConfirmLocationDialogOpen: boolean;
    isLoading: boolean;
    isSubmitting: boolean;
    paymentType?: PaymentType;
    paymentTypes?: PaymentType[];
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      buyerPickupLocationId: undefined,
      errorMessages: [],
      isConfirmLocationDialogOpen: false,
      isLoading: false,
      isSubmitting: false,
      paymentType: undefined,
      paymentTypes: undefined,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getRequiredFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.auctionItem?.id !== this.props?.auctionItem?.id) {
      this.getRequiredFields();
    }
  }

  /**
   * Get a range of required fields, in particular:
   * - the available payment types
   */
  getRequiredFields = () => {
    if (this.props.auctionItem?.auction?.id) {
      // Ensure `auction?.id` is available before fetching payment types
      this.setState({ errorMessages: [], isLoading: true });
      getPaymentTypes({
        auctionId: this.props.auctionItem?.auction?.id,
        auctionItemId: this.props.auctionItem?.id,
        companyId: this.props.auctionItem?.buyer?.company?.id,
        paymentSubType: PaymentSubType.PAYMENT,
      })
        ?.then((response) => this.setState({ paymentTypes: response?.data?.data?.paymentTypes }, this.setInputValues))
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ isLoading: false }));
    }
  };

  /**
   * Set input values in state
   */
  setInputValues = () => {
    const auctionItemOrder = this.props.auctionItem?.order;

    this.setState({
      buyerPickupLocationId: auctionItemOrder?.buyerPickupLocation?.id,
      paymentType: auctionItemOrder?.paymentType,
    });
  };

  /**
   * Trigger a mutation to notify the api that a service is either being added or removed
   */
  onAddRemoveService = (auctionItemOrderLineItem: AuctionItemOrderLineItem): Promise<any> => {
    const isSelected = auctionItemOrderLineItem?.selected;
    const addRemoveFunc = !isSelected ? auctionItemOrderServiceAdd : auctionItemOrderServiceRemove;
    const options = {
      auctionItemOrderId: this.props.auctionItem?.order?.id,
      auctionServiceId: auctionItemOrderLineItem?.auctionService?.id,
    };

    if (auctionItemOrderLineItem?.auctionService?.serviceMetadata?.transportService) {
      if (!isSelected && !this.state?.buyerPickupLocationId) {
        // Force user to select pickup location when enabling the Transport add-on for the first time
        this.setState({ isConfirmLocationDialogOpen: true });
        return Promise.resolve();
      }

      if (isSelected) {
        // Clear `buyerPickupLocationId` when disabling transport service
        this.setState({ buyerPickupLocationId: undefined });
      }
    }

    this.setState({ isLoading: true });
    return addRemoveFunc(options)
      ?.then(() => this.onSubmit(true))
      ?.catch(this.onApiError)
      ?.finally(() => this.setState({ isLoading: false }));
  };

  /**
   * Update payment type in state, then trigger a mutation to update the order to ensure fees are up to date
   */
  onPaymentTypeChange = (paymentType: PaymentType) => {
    this.setState({ paymentType }, () => this.onSubmit(true));
  };

  /**
   * Submit order updates
   */
  onSubmit = (skipPostUpdate = false) => {
    this.setState({ isSubmitting: true });
    auctionItemOrderUpdate({
      auctionItemOrderId: this.props.auctionItem?.order?.id,
      buyerPickupLocationId: this.state.buyerPickupLocationId,
      paymentTypeId: this.state.paymentType?.id,
    })
      ?.then((response) => {
        // Update order information with response, in the event the invoice has changes
        const order: AuctionItemOrder | undefined = response?.data?.data?.auctionItemOrderUpdate;
        this.props.onUpdateAuctionItemOrder?.(order);

        if (!skipPostUpdate) {
          if (this.props.onSubmit) {
            // If single version, refresh AuctionItemDetails, then close form dialog
            this.props.onSubmit();
            return;
          }

          if (this.props.onGoBack) {
            // If bulk checkout, return to orders view and refresh content
            this.props.onGoBack(true);
          }
        }
      })
      ?.catch(this.onApiError)
      ?.finally(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { auctionItem, onGoBack } = this.props;
    const { errorMessages, isLoading, isSubmitting } = this.state;

    const addOnsSummary = auctionItem?.order?.lineItems?.filter(({ optional, selected }) => !!optional && !!selected);

    return (
      <>
        <FormErrors className={style.formErrors} errorMessages={errorMessages} />
        <FormDialogBody>
          <div className={classnames(style.splitSection, errorMessages?.length && style.splitSectionWithErrors)}>
            <div className={style.splitSectionLeft}>
              <FormSection className={style.formSectionNoPadding}>
                <a
                  className={style.formSectionSinglePreview}
                  data-testid="checkout-item-preview"
                  href={getVehiclePath(auctionItem)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <CheckoutItemPreview auctionItem={auctionItem} isSingleForm />
                </a>
              </FormSection>
              <FormSection title={t('payment_method')}>
                <div className={style.paymentMethods} data-testid="payment-methods">
                  {this.state?.paymentTypes
                    ?.filter((paymentType) => paymentType?.active)
                    ?.map((paymentType) => (
                      <MultiSelectCard
                        key={paymentType?.id}
                        className={style.multiSelectRadio}
                        isSelected={paymentType?.id === this.state.paymentType?.id}
                        onSelect={() => this.onPaymentTypeChange(paymentType)}
                        title={paymentType?.name}
                      />
                    )) || <SecondaryTitle>No Available Payment Methods</SecondaryTitle>}
                </div>
              </FormSection>
              <FormSectionUnified dataTestId="add-ons-section" hasNegativeMargin={false} title={t('add_ons')}>
                {auctionItem?.order?.lineItems
                  ?.filter((auctionItemOrderLineItem) => !!auctionItemOrderLineItem?.optional)
                  ?.map((auctionItemOrderLineItem) => (
                    <MultiSelectCard
                      key={auctionItemOrderLineItem?.auctionService?.id}
                      isSelected={auctionItemOrderLineItem?.selected}
                      onSelect={() => this.onAddRemoveService(auctionItemOrderLineItem)}
                    >
                      <SecondaryTitle className={style.addOnTitle}>
                        {auctionItemOrderLineItem?.auctionService?.serviceMetadata?.serviceName}
                        {auctionItemOrderLineItem?.auctionService?.serviceMetadata?.description && (
                          <InfoTooltip
                            className={style.tooltip}
                            description={
                              <ul className={style.tooltipDescriptions}>
                                {auctionItemOrderLineItem?.auctionService?.serviceMetadata?.description
                                  ?.split('\n')
                                  ?.map((value) => <li key={value}>{value}</li>)}
                              </ul>
                            }
                            position="right"
                          />
                        )}
                      </SecondaryTitle>
                      <SecondaryTitle className={style.addOnPrice}>
                        {auctionItemOrderLineItem?.amount?.formattedAmount ? (
                          <>
                            <span>{auctionItemOrderLineItem?.amount?.formattedAmount}</span>{' '}
                            {auctionItemOrderLineItem?.taxable ? t('plus_tax') : ''}
                          </>
                        ) : (
                          <span>{t('fee_amount_to_be_provided')}</span>
                        )}
                      </SecondaryTitle>

                      {auctionItemOrderLineItem?.auctionService?.serviceMetadata?.transportService && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                          className={style.pickupLocation}
                          data-testid="choose-location"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {auctionItem?.order?.buyerPickupLocation && (
                            <SelectLocations
                              companyId={auctionItem?.buyer?.company?.id}
                              connectionVariables={{
                                auctionId: auctionItem?.auction?.id,
                                mode: 'OWN_AND_COMPOUNDS',
                                sort: [{ id: 'PUBLIC_LOCATION', ascending: true }],
                              }}
                              defaultValue={auctionItem?.order?.buyerPickupLocation}
                              isDisabled={!auctionItemOrderLineItem?.selected}
                              onLocationChange={(location) =>
                                this.setState({ buyerPickupLocationId: location?.id }, () => this.onSubmit(true))
                              }
                              showCompoundSelectionOption={false}
                            />
                          )}

                          <ConfirmDialog
                            bringToFront
                            className={style.confirmPickupLocation}
                            isOpen={this.state?.isConfirmLocationDialogOpen}
                            onClose={() => this.setState({ isConfirmLocationDialogOpen: false })}
                            onConfirm={(shouldSubmit) => {
                              if (shouldSubmit) {
                                this.onAddRemoveService(auctionItemOrderLineItem)?.then(() => {
                                  this.setState({ isConfirmLocationDialogOpen: false }, () => this.onSubmit(true));
                                });
                              }
                            }}
                            theme="blue"
                            title={t('transport_location')}
                          >
                            <p>{t('transport_location_message')}</p>
                            <SelectLocations
                              companyId={auctionItem?.buyer?.company?.id}
                              connectionVariables={{
                                auctionId: auctionItem?.auction?.id,
                                mode: 'OWN_AND_COMPOUNDS',
                                sort: [{ id: 'PUBLIC_LOCATION', ascending: true }],
                              }}
                              defaultValue={auctionItem?.order?.buyerPickupLocation}
                              onLocationChange={(location) => this.setState({ buyerPickupLocationId: location?.id })}
                              showCompoundSelectionOption={false}
                            />
                          </ConfirmDialog>
                        </div>
                      )}
                    </MultiSelectCard>
                  ))}
              </FormSectionUnified>
            </div>
            <div className={style.splitSectionRight} data-testid="fees-summary-section">
              <FormSection>
                <InvoiceContainer>
                  <InvoiceSection title={t('fees')}>
                    <InvoiceRow
                      name={t('purchase_price')}
                      value={auctionItem?.topOffer?.amount.formattedAmountRounded}
                    />
                    {auctionItem?.order?.lineItems
                      ?.filter(({ optional, selected }) => !optional && !!selected)
                      ?.map((auctionItemOrderLineItem) => (
                        <InvoiceRow
                          key={auctionItemOrderLineItem?.id}
                          name={
                            auctionItemOrderLineItem?.auctionService?.serviceMetadata?.feeName ??
                            auctionItemOrderLineItem?.inventoryService?.serviceMetadata?.feeName
                          }
                          value={auctionItemOrderLineItem?.amount?.formattedAmount || t('fee_amount_to_be_provided')}
                        />
                      ))}
                  </InvoiceSection>
                  {!!addOnsSummary?.length && (
                    <InvoiceSection title={t('add_ons')}>
                      {addOnsSummary?.map((auctionItemOrderLineItem) => (
                        <InvoiceRow
                          key={auctionItemOrderLineItem?.id}
                          name={
                            auctionItemOrderLineItem?.auctionService?.serviceMetadata?.serviceName ??
                            auctionItemOrderLineItem?.inventoryService?.serviceMetadata?.serviceName
                          }
                          value={auctionItemOrderLineItem?.amount?.formattedAmount}
                        />
                      ))}
                    </InvoiceSection>
                  )}
                  <InvoiceSection title={t('summary')}>
                    <InvoiceRow name={t('subtotal')} value={auctionItem?.order?.subtotal?.formattedAmount} />
                    <InvoiceRow name={t('credit')} value={auctionItem?.order?.totalBuyCredit?.formattedAmount} />
                    <InvoiceRow name={t('taxes')} value={auctionItem?.order?.taxes?.formattedAmount} />
                  </InvoiceSection>
                  <InvoiceTotal value={auctionItem?.order?.total?.formattedAmount} />
                </InvoiceContainer>
              </FormSection>
            </div>
          </div>
          <FormDialogFooter>
            {!!onGoBack && (
              <Button onClick={() => onGoBack()} style={{ marginRight: 'auto' }} theme="gray-outline">
                {t('back')}
              </Button>
            )}
            <Button
              dataTestId="submit-button"
              disabled={isLoading || isSubmitting || this.props.isSubmitting}
              onClick={() => this.onSubmit()}
              theme="blue"
            >
              {isLoading || isSubmitting || this.props.isSubmitting ? (
                <Spinner />
              ) : (
                <>{t(onGoBack ? 'save' : 'checkout')}</>
              )}
            </Button>
          </FormDialogFooter>
        </FormDialogBody>
        <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
      </>
    );
  }
}

export default CheckoutSingleForm;
