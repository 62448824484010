import ImageLightBox from 'react-image-lightbox';
import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import AutoGradeLogo from 'components/sections/inventoryItem/details/conditionReport/autoGradeLogo';
import Button from 'components/ui/shared/button';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { AutoGradeDamageCodeRecord } from 'store/shared/api/graph/interfaces/types';
import { AutoGradePhoto, AutoGradeVehicleDamageProps } from 'constants/autoGrade';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './autoGradeDamagesSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** Array of damages to be displayed in the slide out */
  damages?: AutoGradeVehicleDamageProps[];

  /** Whether the slide out is open or not. */
  isOpen?: boolean;

  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
}

interface DamagesSectionProps {
  /** Array of damages to be displayed in the slide out */
  damages?: AutoGradeVehicleDamageProps[];

  /** Callback function to handle click on a photo. */
  openPhoto?: (photoId: string) => void;

  /** Section name */
  sectionName: string;

  /** Section number */
  sectionNumber: number;
}

interface DamagesItemProps {
  /** Damage record for a particular item on the vehicle */
  damageCodeRecord: AutoGradeDamageCodeRecord;
}

const DamagesItem = ({ damageCodeRecord }: DamagesItemProps) => (
  <div className={classnames(style.damagesContent)}>
    <div className={style.description} data-testid={`item-${damageCodeRecord.id}`}>
      {damageCodeRecord.itemName}
    </div>
    <div className={style.textContent} data-testid={`damage-${damageCodeRecord.id}`}>
      {damageCodeRecord.damageName}
    </div>
    <div className={style.textContent} data-testid={`severity-${damageCodeRecord.id}`}>
      {t('autograde_severity_x', [damageCodeRecord.severityName ?? damageCodeRecord.severityCode])}
    </div>
  </div>
);

const AutoGradeDamagesSection = ({ damages, openPhoto, sectionNumber, sectionName }: DamagesSectionProps) => {
  return (
    <>
      <div className={style.section}>
        <div className={style.sectionName} data-testid={`section-${sectionNumber}`}>
          {sectionName}
        </div>
      </div>
      <div>
        {damages?.map((damage) => (
          <div key={damage.damageCodeRecord?.id} className={style.damagesContainer}>
            <DamagesItem damageCodeRecord={damage.damageCodeRecord} />

            {Number(damage?.photos?.length) > 0 && (
              <div
                className={classnames(style.thumbnailsContainer)}
                data-testid={`photos-${damage.damageCodeRecord.id}`}
              >
                <ul className={classnames(style.thumbnailList)}>
                  {damage.photos?.map((photo, index) => (
                    <li key={`${photo.id}-${index}`} className={style.thumbnailListItem}>
                      <Button
                        className={classnames(style.thumbnail)}
                        dataTestId={`${photo.id}-${index}`}
                        onClick={() => openPhoto?.(photo.id)}
                        style={{ backgroundImage: `url('${photo.thumb}')` }}
                        theme="none"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const AutoGradeDamagesSlideOut = ({ damages, isOpen, onClose }: Props) => {
  const [activePhotoIndex, setActivePhotoIndex] = useState<number>(-1);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState<boolean>();

  /** We need to ensure that the damages are sorted by section so that we can group them
   *  together.  Each section will subsequently be rendered in a AutoGradeDamagesSection component.
   */
  const damageSectionsArray: Array<Array<AutoGradeVehicleDamageProps>> = useMemo(() => {
    const sectionsMap: Record<string, AutoGradeVehicleDamageProps[]> = {};

    /** Organize the damages into sections for display */
    damages?.forEach((_damage) => {
      const sectionNumber: number = _damage?.damageCodeRecord?.sectionNumber;
      let section: AutoGradeVehicleDamageProps[] = sectionsMap[sectionNumber];
      if (!section) {
        section = [];
        sectionsMap[sectionNumber] = section;
      }

      section.push(_damage);
    });

    return Object.values(sectionsMap);
  }, [damages]);

  /**
   * Memoized list of all damage photos
   */
  const damagePhotos: (AutoGradePhoto & { caption: string })[] = useMemo(() => {
    const UNCATEGORIZED_DAMAGE_CODE = 0;

    return damageSectionsArray.flatMap((section) =>
      section.flatMap((damage) => {
        const damageCodeRecord = damage.damageCodeRecord;
        return (
          damage.photos?.map((photo) => ({
            ...photo,
            caption:
              damageCodeRecord?.id !== UNCATEGORIZED_DAMAGE_CODE
                ? `${damageCodeRecord?.itemName} - ${damageCodeRecord?.damageName} - ${damageCodeRecord?.severityName}`
                : photo.location,
          })) ?? []
        );
      })
    );
  }, [damageSectionsArray]);

  /**
   * Callback when a particular photo is clicked to get a detailed view of all auto grade damage photos
   */
  const openPhoto = useCallback(
    (photoId: string) => {
      setActivePhotoIndex(damagePhotos.findIndex((photo) => photoId === photo.id));
      setIsLightBoxOpen(true);
    },
    [damagePhotos]
  );

  return (
    <>
      <SlideOut contentInnerClassName={style.content} isOpen={isOpen} onClose={onClose} title={t('damage_details')}>
        {damageSectionsArray?.map((damagesSection, index) => {
          const sectionName: string = damagesSection[0].damageCodeRecord?.sectionName;
          return (
            <AutoGradeDamagesSection
              key={`${sectionName}-${index}`}
              damages={damagesSection}
              openPhoto={openPhoto}
              sectionName={damagesSection[0].damageCodeRecord?.sectionName}
              sectionNumber={damagesSection[0].damageCodeRecord?.sectionNumber}
            />
          );
        })}

        <AutoGradeLogo />
      </SlideOut>

      {isLightBoxOpen && damagePhotos?.length && activePhotoIndex > -1 ? (
        <ImageLightBox
          imageCaption={damagePhotos?.[activePhotoIndex]?.caption}
          mainSrc={damagePhotos?.[activePhotoIndex]?.expanded}
          nextSrc={
            (damagePhotos?.length > 1 && damagePhotos?.[(activePhotoIndex + 1) % damagePhotos?.length]?.expanded) ||
            undefined
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMoveNextRequest={() => setActivePhotoIndex((activePhotoIndex + 1) % damagePhotos?.length)}
          onMovePrevRequest={() =>
            setActivePhotoIndex((activePhotoIndex + (damagePhotos?.length - 1)) % damagePhotos?.length)
          }
          prevSrc={
            (damagePhotos?.length > 1 &&
              damagePhotos?.[(activePhotoIndex + (damagePhotos?.length - 1)) % damagePhotos?.length]?.expanded) ||
            undefined
          }
        />
      ) : null}
    </>
  );
};

export default AutoGradeDamagesSlideOut;
