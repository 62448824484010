import { memo } from 'react';

import infoGlyph from 'glyphs/info.svg';

import NotificationText from 'components/ui/shared/notifications/notificationText';
import Sprite from 'components/ui/shared/sprite';
import Tooltip from 'components/ui/shared/tooltips/tooltip';
import { t } from 'utils/intlUtils';

import style from './obdiiCodesTooltip.scss';

const ObdiiCodesTooltip = () => (
  <div className={style.container}>
    <Tooltip
      className={style.tooltip}
      position="bottom"
      trigger={<Sprite className={style.tooltipGlyph} glyph={infoGlyph} />}
    >
      <NotificationText glyphClassName={style.notificationTextGlyph}>
        <strong>{t('pending_codes')}</strong>
        <br />
        {t('obdii_pending_codes_info_text')}
        <br />
        <br />
        <strong>{t('confirmed_codes')}</strong>
        <br />
        {t('obdii_confirmed_codes_info_text')}
        <br />
        <br />
        <strong>{t('permanent_codes')}</strong>
        <br />
        {t('obdii_permanent_codes_info_text')}
        <br />
        <br />
        <strong>{t('enhanced_codes')}</strong>
        <br />
        {t('obdii_enhanced_codes_info_text')}
      </NotificationText>
    </Tooltip>
  </div>
);

export default memo(ObdiiCodesTooltip);
