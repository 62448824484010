import Photo from 'constants/photo';
import { AutoGradeDamageCodeRecord } from 'store/shared/api/graph/interfaces/types';
import { AutoGradeScoreDataProps, AutoGradeVehicleDamageProps } from 'constants/autoGrade';

/**
 * Returns a list photo records that can be used for thumbnails
 *
 * @param autoGradeScoreData
 * @returns {[]}
 */
export const getAutoGradePhotos = (autoGradeScoreData?: AutoGradeScoreDataProps) => {
  const UNCATEGORIZED_DAMAGE_CODE = 0;

  const thumbnailPhotos: Photo[] | undefined = autoGradeScoreData?.autoGradeVehicleDamage?.flatMap(
    (damage: AutoGradeVehicleDamageProps) => {
      const damageRecord: AutoGradeDamageCodeRecord = damage.damageCodeRecord;

      return damage.photos
        ? damage.photos.map((photo) => ({
            expanded: photo.expanded,
            isDamagePhoto: true,
            location:
              damageRecord.id !== UNCATEGORIZED_DAMAGE_CODE
                ? `${damageRecord.itemName} - ${damageRecord.damageName} - ${damageRecord.severityName}`
                : photo.location,
            main: photo.main,
            thumb: photo.thumb,
            tile: photo.tile,
          }))
        : [];
    }
  );

  return thumbnailPhotos ?? [];
};
