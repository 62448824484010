import { handleActions } from 'redux-actions';

import { InitialState } from 'store/auth/authModels';
import {
  authError,
  authErrorCleared,
  isAuctionAccessMode,
  isLoading,
  isNotLoading,
  isNotUpdating,
  isUpdating,
  login,
  logout,
  setVerificationCodeRequired,
} from 'store/auth/authActions';

export const authReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [isAuctionAccessMode().type]: (state) => state.set('isAuctionAccessMode', true),

    [login().type]: (state) => state.setLoaded().merge({ isLoggedIn: true }),

    [logout().type]: () => {
      return new InitialState();
    },

    [authError().type]: (state, action) => state.setError(action.payload),

    [authErrorCleared().type]: (state) => state.clearError(),

    [setVerificationCodeRequired().type]: (state, action) => {
      const { isVerificationCodeRequired, tempAuthToken } = action.payload;
      return state.set('isVerificationCodeRequired', isVerificationCodeRequired).set('tempAuthToken', tempAuthToken);
    },
  },
  new InitialState()
);
