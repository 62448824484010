import moment, { Moment } from 'moment';

import Button from 'components/ui/shared/button';
import { DateFormat } from 'constants/enums/dateAndTime';
import { MileageType } from 'forms/shared/mileageInput';
import { TertiaryTitle } from 'layouts/list/listItemLayout';
import { formatMomentDate } from 'utils/dateUtils';
import { formatNumber } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './lastKnownMileageRowButton.scss';

interface Props {
  /** The last known mileage amount. */
  lastKnownMileageAmount: number | null;
  /** The last known mileage date. */
  lastKnownMileageDate: Moment | null;
  /** The last known mileage unit type. */
  lastKnownMileageUnit: MileageType;
  /** Function invoked on row click.*/
  onClick: () => void;
}

const LastKnownMileageRowButton = ({
  lastKnownMileageAmount,
  lastKnownMileageDate,
  lastKnownMileageUnit,
  onClick,
}: Props) => {
  return (
    <Button
      className={style.lastKnownMileageRowButton}
      dataTestId="last-known-mileage-row-button"
      onClick={onClick}
      theme="none"
    >
      <TertiaryTitle>
        {`${t('last_known_mileage')}: `}
        {lastKnownMileageAmount ? (
          <span className={style.mileageValue}>
            {formatNumber(lastKnownMileageAmount)} {lastKnownMileageUnit}
          </span>
        ) : (
          '-'
        )}
      </TertiaryTitle>
      <TertiaryTitle>
        {`${t('last_known_mileage_date')}: `}
        {moment(lastKnownMileageDate).isValid()
          ? formatMomentDate(moment(lastKnownMileageDate), DateFormat.MONTH_DAY_YEAR_LONG_FORMAT)
          : '-'}
      </TertiaryTitle>
    </Button>
  );
};

export default LastKnownMileageRowButton;
