import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import notificationGlyph from 'glyphs/notifications.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import UserNotificationsSlideOut from 'components/sections/notifications/userNotificationsSlideOut';
import { UserAction } from 'logging/analytics/events/userActions';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

import style from './userNotificationButton.scss';

interface Props {
  /** Additional class names to apply */
  className?: string;
  /** Whether the slideOut should be open by default */
  isDefaultOpen?: boolean;
}

const UserNotificationsButton = ({ className, isDefaultOpen }: Props) => {
  const [isPreferencesOpen, setIsPreferencesOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsPreferencesOpen(isDefaultOpen || false);
  }, [isDefaultOpen]);
  /**
   * Open or close the slideOut, and track the event
   */
  const onOpenCloseSlideOut = useCallback((isOpen: boolean) => {
    setIsPreferencesOpen(isOpen);
    trackUserActionWithUserAttributes(isOpen ? UserAction.NOTIFICATIONS_OPEN : UserAction.NOTIFICATIONS_CLOSE);
  }, []);

  return (
    <>
      <Button
        className={classnames(style.button, className)}
        dataTestId="user-preferences-button"
        onClick={() => onOpenCloseSlideOut(true)}
        theme="none"
      >
        <Sprite className={style.sprite} glyph={notificationGlyph} />
      </Button>
      <UserNotificationsSlideOut isOpen={isPreferencesOpen} onClose={() => onOpenCloseSlideOut(false)} />
    </>
  );
};

export default UserNotificationsButton;
