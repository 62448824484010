import ApiRequest from 'store/shared/api/apiRequest';
import { auctionSettingsFragment } from 'store/shared/api/graph/fragments/auctionSettings';
import { createGraphQLArgs } from 'store/shared/api/graph/createGraphQLArgs';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

const _queryArgs = {
  first: 'Int',
  after: 'String',
  last: 'Int',
  before: 'String',
  consignerId: 'String',
  regionCode: 'String',
  keyword: 'String',
};

export const getAuctionsList = (options) => {
  const { queryArgs, fieldsArgs, variables } = createGraphQLArgs(_queryArgs, options);

  return ApiRequest({
    data: {
      operationName: 'getAuctionsList',
      query: `
        query(${queryArgs}) {
          auctionConnection(${fieldsArgs}) {
            edges {
              node {
                settings {
                  ...auctionSettings
                }
                created
                description
                id
                location {
                  ...location
                }
                status
                title
                type
              }
            }
          }
        }
        ${auctionSettingsFragment}
        ${locationFragment}
      `,
      variables,
    },
  });
};
