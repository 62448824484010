import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { setRoutingTarget, clearRoutingTarget } from './routingActions';
import { InitialState } from './routingModels';

export const routingReducer = handleActions(
  {
    [setRoutingTarget().type]: (state, action) => {
      return state.set('routingTarget', fromJS(action.payload));
    },

    [clearRoutingTarget().type]: (state) => {
      return state.delete('routingTarget');
    },
  },
  new InitialState()
);
