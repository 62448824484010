import classnames from 'classnames';

import closeGlyph from 'glyphs/close.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { t } from 'utils/intlUtils';

import style from './dialogLayouts.scss';

interface Props {
  /** The className to overwrite default styles */
  className?: string;
  /** Title to be rendered. */
  title: string;
  /** Callback function to close the dialog.*/
  onClose?: () => void;
}

export const DialogHeader = ({ className, title, onClose }: Props) => (
  <div className={classnames(style.dialogHeader, className)} data-testid="dialog-header">
    <h4 className={style.headerTitle}>{title}</h4>
    <Button className={style.closeButton} dataTestId="close-dialog" onClick={onClose} theme="none" title={t('close')}>
      <Sprite className={style.closeIcon} glyph={closeGlyph} />
    </Button>
  </div>
);
