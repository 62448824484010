import { createAction } from 'redux-actions';

import {
  getUser,
  saveUser,
  suspendUser,
  getUserCompanies,
  updateOmvic,
  updateLocaleTimeZone,
  updateAuctionAccessId,
  updateLocation,
} from './userDetailsApi';
import { usersListUpdateItem, processUpdateFacets } from '../list/usersActions';
import { userUpdated, userDistanceUnit } from 'store/user/userActions';
import { getUrlParams } from 'utils/urlUtils';
import { Locale, setLocale } from 'utils/intlUtils';

export const isUpdating = createAction('USER_DETAILS_SET_IS_UPDATING');
export const isNotUpdating = createAction('USER_DETAILS_UNSET_IS_UPDATING');
export const isLoaded = createAction('USER_DETAILS_IS_LOADED');
export const clearUser = createAction('USER_DETAILS_IS_CLEARED');
export const preloadUser = createAction('USER_DETAILS_IS_PRELOADED');
export const updateUser = createAction('USER_DETAILS_UPDATED');
export const setUserData = createAction('USER_DETAILS_SETTING_DATA');
export const userRelationshipsAreLoading = createAction('USER_DETAILS_LINK_LOADING');
export const userRelationshipsLoaded = createAction('USER_DETAILS_LINK_LOADED');

export const processGetUser = async (options, dispatch) => {
  dispatch(isUpdating());
  const userDetails = (await getUser(options))?.data.data;
  dispatch(isLoaded(userDetails));
};

export const processUpdateUser = (user, dispatch, isLoggedInUser = false) => {
  if (isLoggedInUser) {
    dispatch(userUpdated(user)); // Logged in user within app
    if (user?.distanceUnit) {
      userDistanceUnit.set(user?.distanceUnit);
    }
    return;
  }
  dispatch(updateUser(user)); // Loaded user within admin/users section
};

export const processUpdateResponse = (options, dispatch) => {
  dispatch(usersListUpdateItem(options));
  processUpdateFacets(getUrlParams(), dispatch);
};

export const processUpdateUserAsAdmin = (options, dispatch) => {
  dispatch(isUpdating());
  return saveUser(options)
    .then((response) => {
      const formattedResponse = response?.data?.data?.userUpdateInfoAsAdmin;
      dispatch(updateUser(formattedResponse));
      processUpdateResponse(formattedResponse, dispatch);
      return formattedResponse;
    })
    .finally(() => dispatch(isNotUpdating()));
};

export const processSuspendUser = (options, dispatch) => {
  dispatch(isUpdating());
  return suspendUser(options)
    .then((response) => {
      const formattedResponse = response?.data?.data?.userUpdateInfoAsAdmin;
      dispatch(updateUser(formattedResponse));
      processUpdateResponse(formattedResponse, dispatch);
      return formattedResponse;
    })
    .finally(() => dispatch(isNotUpdating()));
};

export const processGetUserCompanies = (options, dispatch) => {
  dispatch(userRelationshipsAreLoading());
  return getUserCompanies(options).then((response) => {
    dispatch(userRelationshipsLoaded(response?.data?.data?.userCompanyConnection));
  });
};

export const processUpdateOmvic = (options, dispatch) => {
  return updateOmvic(options).then((response) => {
    const user = response?.data?.data?.userUpdateOmvic;
    processUpdateUser(user, dispatch, options.isModifyingLoggedInUser);
  });
};

export const processUpdateLocaleTimeZone = (options, dispatch) => {
  return updateLocaleTimeZone(options).then((response) => {
    const user = response?.data?.data?.userUpdateLocaleTimeZone;
    processUpdateUser(user, dispatch, options.isModifyingLoggedInUser);

    if (options?.isModifyingLoggedInUser) {
      setLocale(options?.locale as Locale);
      window.location.reload();
    }
  });
};

export const processUpdateAuctionAccessId = (options, dispatch) => {
  return updateAuctionAccessId(options).then((response) => {
    const user = response?.data?.data?.userUpdateAuctionAccessId;
    processUpdateUser(user, dispatch, options.isModifyingLoggedInUser);
  });
};

export const processUpdateLocation = (options, dispatch) => {
  return updateLocation(options).then((response) => {
    const user = response?.data?.data?.userUpdatePreferredLocationDistanceUnit;
    processUpdateUser(user, dispatch, options.isModifyingLoggedInUser);
  });
};
