import moment from 'moment';
import 'moment-duration-format';

import { DateFormat } from 'constants/enums/dateAndTime';
import { t, tPlural } from 'utils/intlUtils';

/**
 * Basic timestamp validation
 *
 * @param {string} timestamp
 * @returns {boolean}
 */
export const isValidTimestamp = (timestamp) => {
  return moment(timestamp).parsingFlags().iso;
};

/**
 * Converts UTC timestamp to local time
 *
 * @param {string} timestamp
 * @returns {string}
 */
export const getLocalTime = (timestamp) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  return moment.utc(timestamp).local();
};

/**
 * Returns a date string in the following format: 'May 4, 9:21 AM'
 *
 * @param {string} timestamp
 * @param {string} format
 * @param {boolean} convertToUTC
 * @returns {string}
 */
export const formatDate = (timestamp, format = 'MMMM DD, YYYY', convertToUTC = true) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  return moment(convertToUTC ? getLocalTime(timestamp) : timestamp).format(format);
};

/**
 * Is the date in the past, based on measurement in days
 * @param timestamp - the date you wish to compare vs today
 * @returns {boolean} - is the provided date in the past
 */
export const isPastDate = (timestamp) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }
  return moment().diff(getLocalTime(timestamp), 'd') > 0;
};

/**
 * Is the provided date today
 * @param timestamp - the date you wish to compare vs today
 * @param {boolean} convertToUTC
 * @returns {boolean} - is the provided date today
 */
export const isToday = (timestamp, convertToUTC = true) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }
  return moment().isSame(convertToUTC ? getLocalTime(timestamp) : timestamp, 'd');
};

/**
 * Returns a calendar date string in the following formats:
 *
 *  - Yesterday:          'Yesterday at 9:21 PM'
 *  - Today:              'Today at 9:21 PM'
 *  - Tomorrow:           'Tomm at 9:21 PM'
 *  - Friday:             'Friday at 9:21 PM'
 *  - Beyond next week:   'May 24, 9:21 AM'
 *
 * @param {string} timestamp
 * @param {string} format
 * @returns {string}
 */
export const formatCalendarTime = (timestamp, format = 'MMM DD, LT') => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  return moment(getLocalTime(timestamp)).calendar(null, {
    nextWeek: format,
    lastWeek: format,
    sameElse: format,
  });
};

/**
 * Returns a calendar date string in the following formats:
 *
 *  - Today:      'Today'
 *  - Tomorrow:   'Tomorrow'
 *  - sameElse:   'May 24, 2017'
 *
 * @param {string} timestamp
 * @param {boolean} convertToUTC
 * @param {string} format
 * @returns {string}
 */
export const formatCalendarDate = (timestamp, convertToUTC = true, format = 'MMM DD, YYYY') => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  return moment(convertToUTC ? getLocalTime(timestamp) : timestamp).calendar(null, {
    sameDay: `[${t('today')}]`,
    nextDay: `[${t('tomorrow')}]`,
    lastDay: `[${t('yesterday')}]`,
    nextWeek: format,
    lastWeek: format,
    sameElse: format,
  });
};

/**
 * Returns a calendar date string from a time in the past
 *
 * @param {string} timestamp
 * @returns {string}
 */
export const formatPastTime = (timestamp) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }
  const secondsAgo = Math.abs(getLocalTime(timestamp)?.diff(moment(), 'seconds') || 0);

  if (secondsAgo < 1) {
    return tPlural('x_seconds_ago', 1, [1]);
  }
  if (secondsAgo < 60) {
    return tPlural('x_seconds_ago', secondsAgo, [secondsAgo]);
  }
  if (secondsAgo < 120) {
    return tPlural('x_minutes_ago', 1, [1]);
  }
  if (secondsAgo < 3600) {
    const formattedMinutes = Math.floor((secondsAgo / 3600) * 60);
    return tPlural('x_minutes_ago', formattedMinutes, [formattedMinutes]);
  }
  if (secondsAgo < 7200) {
    return tPlural('x_hours_ago', 1, [1]);
  }
  if (secondsAgo < 86400) {
    const formattedHours = Math.floor((secondsAgo / 86400) * 24);
    return tPlural('x_hours_ago', formattedHours, [formattedHours]);
  }
  if (secondsAgo < 172800) {
    return t('yesterday');
  }
  return formatCalendarDate(timestamp);
};

/**
 * Returns an ISO date string less the days from current date:
 *
 * @param {string} days
 * @returns {string} timestamp in ISO8601 format
 */
export const getPastDate = (days) => {
  return moment().subtract(days, 'days').toISOString();
};

/**
 * Returns an ISO date string greater the days from current date:
 *
 * @param {string} days
 * @returns {string} timestamp in ISO8601 format
 */
export const getFutureDate = (days) => {
  return moment().add(days, 'days').toISOString();
};

/**
 * Takes a moment() object and returns a date strings object in { id, name } format:
 *
 * Example:  moment() -> {id: '2017-11-26', name: 'Today'}
 *
 * @param {string} timestamp
 * @returns {object} which contains id:{string}, name:{string}
 */
export const createDate = (momentObj) => {
  return {
    id: momentObj.format('YYYY-MM-DD'),
    name: formatCalendarDate(momentObj.toISOString()),
  };
};

/**
 * Recursively creates an array of date string objects between start and end moment() dates:
 *
 * Ex:
 *
 *   Input:
 *     start:     moment().startOf('days')
 *     end:       moment().add(30, 'days')
 *     keys:      'days'
 *
 *   Output: [
 *             {id: '2017-11-26', name: 'Today'},
 *             {id: '2017-11-27', name: 'Tomorrow'},
 *             ...
 *             {id: "2017-12-25", name: "Dec 25 2017"}
 *             {id: "2017-12-26", name: "Dec 26 2017"}
 *           ]
 *
 *
 * @param {string} timestamp
 * @returns {string} timestamp in MM/DD/YYYY
 */
export const getRangeOfDates = (start, end, key, arr = [createDate(start.startOf(key))]) => {
  if (start.isAfter(end)) {
    throw new Error('start must precede end');
  }

  const next = moment(start).add(1, key).startOf(key);
  const nextObj = createDate(next);

  if (next.isAfter(end, key)) {
    return arr;
  }

  return getRangeOfDates(next, end, key, arr.concat(nextObj));
};

/**
 * Getting a formatted date based on the created date value provided
 *
 * @param {number} value is the time in milliseconds from 1970
 */
export const getFormattedCreatedDate = (value) => {
  const difference = new Date().getTime() - new Date(value).getTime();
  const hourInMilli = 3600000;
  const minInMilli = 60000;
  const secInMilli = 1000;

  if (difference >= hourInMilli) {
    // An hour or greater
    return `${formatCalendarDate(value)}`;
  }
  if (difference >= minInMilli) {
    // A minute or greater
    const minutesAgo = Math.round(difference / minInMilli);
    return tPlural('x_minutes_ago', minutesAgo, [minutesAgo]);
  }

  // Seconds
  const secondsAgo = Math.round(difference / secInMilli);
  return tPlural('x_seconds_ago', secondsAgo, [secondsAgo]);
};

/**
 * Returns number of days from the current date
 *
 * @param {string} date the date to compare to today
 */
export const getDaysDifference = (date: string): number => {
  const currDate = moment();
  const createdDate = moment(date.substring(0, 10)).format();
  return Math.abs(currDate.startOf('day').diff(createdDate, 'days'));
};

/**
 * Returns a formatted past date in days from the current date
 *
 * @param {string} date the date to compare to today
 */
export const getDaysPast = (date: string) => {
  const daysDiff = getDaysDifference(date);
  switch (daysDiff) {
    case 0:
      return t('today');
    case 1:
      return t('yesterday');
  }

  return tPlural('x_days_ago', daysDiff, [daysDiff]);
};

/**
 * Returns a moment date to a formatted string. Defaults as 'YYYY-MM-DDTHH:mm:ss'
 *
 * @param date JS Date, ISO dateTime or custom format date/dateTime
 * @param format The outputted date format
 * @returns {string|null}
 */
export const formatMomentDate = (date: moment.Moment | null, format = 'YYYY-MM-DDTHH:mm:ss.SSS'): string | null => {
  if (!(date instanceof moment)) {
    return null;
  }

  return date?.format?.(format);
};

/**
 * Converts seconds to a string like '21 h 16 m 10 s'
 *
 * @param seconds The seconds to format
 * @param format The outputted date format
 * @returns {string}
 */
export const formatSeconds = (seconds, format = 'D [d] h [h] m [m] s [s]') => {
  const duration = moment.duration(seconds, 'seconds') as any;
  return duration.format(format);
};

/**
 * Converts time in hours/minutes/seconds to an accumulated second count.
 *
 * Example: convertTimeToSeconds(12, 12, 12) -> 43932
 *
 * @param hours {number}
 * @param minutes {number}
 * @param seconds {number}
 * @returns {number}
 */
export const convertTimeToSeconds = (hours = 0, minutes = 0, seconds = 0) => {
  return [seconds, minutes, hours].reduce((prev, curr, i) => prev + curr * 60 ** i, 0);
};

/**
 * Breaks timestamp into object containing strings that represent
 * day in form of DDD, date as number, time as H:MM AM/PM (TZ)
 *
 * @param {*} timestamp
 */
export const dateToObj = (timestamp) => {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  const date = moment(timestamp);
  const timezone = date.toDate().toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();

  return {
    month: date.format('MMM'),
    date: date.format('D'),
    formattedTime: `${formatDate(timestamp, DateFormat.TIME_FORMAT)} (${timezone})`,
  };
};

/**
 * Determines if a date needs to be formatted from a moment object.
 * Useful in forms where a default date value is a string, but afterwards
 * is changed to a moment object.
 *
 * @param {string|moment.Moment} date
 * @param {string} format
 * @returns {string}
 */
export const getFormattedDate = (date, format = 'YYYY-MM-DD') => {
  if (typeof date === 'string') {
    return date;
  }
  return formatMomentDate(date, format);
};

/**
 * Get how days are configured in react-dates DatePicker
 *
 * @param {moment} date The date input
 */
export const getCalendarDate = (date: moment.Moment): moment.Moment => {
  return date.startOf('day').hours(12);
};
