import classnames from 'classnames';
import { ChangeEvent, FocusEvent, Ref } from 'react';

import style from './textArea.scss';

interface TextAreaProps {
  /** The child elements to be rendered. */
  children?: string;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Set the html `data-testid` attribute. */
  dataTestId?: string;
  /** The default value of the textarea. */
  defaultValue?: string;
  /** Set the html 'disabled' attribute */
  disabled?: boolean;
  /** Name of the textarea. */
  name?: string;
  /** Callback function triggers on blur. */
  onBlur?: (event?: FocusEvent<HTMLTextAreaElement>) => unknown;
  /** Callback function triggers on value change. */
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => unknown;
  /** Placeholder for the textarea. */
  placeholder?: string;
  /** References `<textarea>` in DOM */
  reference?: Ref<HTMLTextAreaElement>;
  /** The CSS theme for the textarea. */
  theme?: 'default' | 'small' | 'error';
}

const TextArea = ({ children, className, dataTestId, name, reference, theme, ...props }: TextAreaProps) => {
  return (
    <textarea
      ref={reference}
      className={classnames(style.textArea, style[`theme-${theme}`], className)}
      data-testid={dataTestId ? `${dataTestId}-textarea` : 'textarea'}
      id={name}
      name={name}
      {...props}
    >
      {children}
    </textarea>
  );
};

export default TextArea;
