import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { ArbitrationType } from 'store/admin/arbitrations/details/arbitrationDetailsModels';
import { FacetedPageInfo, FacetGroup } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface ArbitrationsModel {
  /** Arbitrations facet groups. */
  facetGroups: List<FacetGroup>;

  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;

  /** Arbitrations list. */
  resultList: List<RecordOf<ArbitrationType>>;
}

export type ArbitrationsProps = ServerRecordOf<ArbitrationsModel>;

export const InitialState = ServerRecord<ArbitrationsModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});

export const Arbitrations = Record<ArbitrationType>({
  id: null,
  buyer: null,
  status: null,
  format: null,
  arbitrationRequest: null,
  inventoryItem: null,
});
