import { useMemo } from 'react';

import BlackBookCopyright from 'components/sections/inventoryItem/details/feesAndValues/blackBookValues/blackBookCopyright';
import GroupedValuesItem from 'components/sections/inventoryItem/details/feesAndValues/groupedValuesItem';
import { BlackBookValuesTooltipText } from 'components/sections/inventoryItem/details/feesAndValues/blackBookValues/blackBookValuesTooltip';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { blackBookHeaderTranslationMap, getBlackBookValues } from 'utils/formatting/blackBookFormatUtils';
import { t } from 'utils/intlUtils';

import style from './blackBookValuesItem.scss';

interface BlackBookValueItemProps {
  /** The black book values of the inventory item. */
  values: InventoryItemValue[] | undefined;
}

const copyright = (
  <BlackBookCopyright className={style.blackBookCopyright} logoClassName={style.blackBookCopyrightLogo} />
);

const tooltipText = <BlackBookValuesTooltipText />;

const BlackBookValuesItem = ({ values }: BlackBookValueItemProps) => {
  /** Memoized formatted and sorted black book values. */
  const formattedValues = useMemo(() => {
    const blackBookValues = getBlackBookValues(values) || [];
    return blackBookValues.map((value) => ({
      label: t(blackBookHeaderTranslationMap[value.type.id]),
      value: value.value.formattedAmount,
    }));
  }, [values]);

  return (
    <GroupedValuesItem
      footerComponent={copyright}
      title={t('black_book')}
      tooltipText={tooltipText}
      values={formattedValues}
    />
  );
};

export default BlackBookValuesItem;
