import ApiRequest from 'store/shared/api/apiRequest';
import { UserConnection } from 'store/shared/api/graph/interfaces/types';
import { createGraphQLArgs } from 'store/shared/api/graph/createGraphQLArgs';

const _queryArgs = {
  first: 'Int',
  after: 'String',
  last: 'Int',
  before: 'String',
  status: '[String]',
  email: '[String]',
  title: '[String]',
  firstName: '[String]',
  lastName: '[String]',
  consignerId: '[String]',
  consignerName: '[String]',
  createdGTE: 'DateTime',
  createdLTE: 'DateTime',
  updatedGTE: 'DateTime',
  updatedLTE: 'DateTime',
  city: '[String]',
  region: '[String]',
  country: '[String]',
  timeZone: '[String]',
  locale: '[String]',
  photoStatus: '[String]',
  auctionAccessStatus: '[String]',
  omvicStatus: '[String]',
  keyword: 'String',
};

export const getUsers = (options) => {
  const { queryArgs, fieldsArgs, variables } = createGraphQLArgs(_queryArgs, options);

  return ApiRequest<{ userConnection: UserConnection }>({
    data: {
      operationName: 'getUsers',
      query: `
        query(${queryArgs}) {
          userConnection(${fieldsArgs}) {
            edges {
              node {
                id
                firstName
                lastName
              }
            }
          }
        }
    `,
      variables,
    },
  });
};
