import ApiRequest from 'store/shared/api/apiRequest';
import { CompanyConnection } from 'store/shared/api/graph/interfaces/types';
import { createGraphQLArgs } from 'store/shared/api/graph/createGraphQLArgs';
import { getFacetGroupsByUrl, pageInfo } from 'store/shared/api/graph/schema/connection';
import { repsFragment } from 'store/shared/api/graph/fragments/reps';

const _queryArgs = {
  first: 'Int',
  after: 'String',
  last: 'Int',
  before: 'String',
  filterBy: 'String',
  parentCompanyIds: '[String]',
  status: '[CompanyStatus]',
  type: '[CompanyType]',
  subType: '[CompanySubType]',
  city: '[String]',
  region: '[String]',
  country: '[String]',
  visibleRegion: '[String]',
  keyword: 'String',
  salesRep: '[String]',
  salesRepId: '[String]',
  auction: '[String]',
  auctionId: '[String]',
  auctionRelationshipStatus: '[CompanyAuctionRelationshipStatus]',
};

export const getCompaniesList = (options) => {
  const { queryArgs, fieldsArgs, variables } = createGraphQLArgs(_queryArgs, options);

  return ApiRequest<{ companyConnection: CompanyConnection }>({
    data: {
      operationName: 'getCompaniesList',
      query: `
        query(${queryArgs}) {
          companyConnection(${fieldsArgs}) {
            edges {
              node {
                id
                name
                legalName
                status
                description
                type
                subType
                created
                updated
                parentCompany {
                  name
                }
                primaryLocation {
                  address1
                  address2
                  city
                  country
                  countryCode
                  distanceAway
                  region
                  regionCode
                }
                visibleRegions
                auctionRelationships {
                  list {
                    id
                    auction {
                      title
                    }
                    permissions {
                      id
                      name
                    }
                    reps {
                      ...reps
                    }
                  }
                }
              }
            }
            ${getFacetGroupsByUrl()}
            ${pageInfo}
          }
        }
        ${repsFragment}
      `,
      variables,
    },
  });
};

export const getCompaniesForBulkAssignment = (options) => {
  const { queryArgs, fieldsArgs, variables } = createGraphQLArgs(_queryArgs, options);

  return ApiRequest<{ companyConnection: CompanyConnection }>({
    data: {
      operationName: 'getCompaniesForBulkAssignment',
      query: `
        query(${queryArgs}) {
          companyConnection(${fieldsArgs}) {
            edges {
              node {
                id
                name
                auctionRelationships {
                  list {
                    id
                    auction {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables,
    },
  });
};
