import { MutableRefObject, useMemo } from 'react';

import {
  AccountVerificationFormStep,
  AccountVerificationStepHandleRef,
  AccountVerificationStepProps,
} from 'forms/account/accountVerification/steps/accountVerificationHelpers';

interface Props {
  /** The current form step */
  currentStep: number;
  /** The default value to pre-fill the single input available (overwrites user defaults in extended component) */
  defaultValue?: string;
  /** Any persisted data stored from previous form steps */
  formData: AccountVerificationStepProps['formData'];
  /** The form ref */
  formRef: MutableRefObject<AccountVerificationStepHandleRef | undefined>;
  /** Callback function to notify the parent from controller if the submit button should be enabled */
  onFormInputChange: AccountVerificationStepProps['onChange'];
  /** Callback function to notify the parent from controller if the submit button should be enabled */
  onNavigateToSection: AccountVerificationStepProps['onNavigate'];
  /** A form's step config */
  steps: AccountVerificationFormStep[];
}

/**
 * Memoized form content, depending on which step is being rendered
 */
export const useFormContent = ({
  currentStep,
  defaultValue,
  formData,
  formRef,
  onFormInputChange,
  onNavigateToSection,
  steps,
}: Props) =>
  useMemo(() => {
    const Component = steps[currentStep].component;
    const hasValidationRequirements = steps[currentStep].isFormWithValidationRequirements;

    const props: AccountVerificationStepProps = {
      defaultValue,
      onChange: onFormInputChange,
      onNavigate: onNavigateToSection,
      isNavigationHidden: steps[currentStep].isNavigationHidden,
      ...(hasValidationRequirements && {
        formData,
        ref: formRef,
      }),
    };

    return <Component {...props} />;
  }, [currentStep, defaultValue, formData, formRef, onFormInputChange, onNavigateToSection, steps]);
