import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import { MutationauctionItemSellInternalArgs } from 'store/shared/api/graph/interfaces/types';

export const auctionItemSellInternal = ({
  auctionId,
  companyId,
  inventoryItemId,
  price,
}: MutationauctionItemSellInternalArgs) =>
  ApiRequest<{ auctionItemSellInternal: Pick<AuctionItem, 'id' | 'status' | 'format'> }>({
    data: {
      query: `
      mutation(
        $auctionId: String! 
        $companyId: String! 
        $inventoryItemId: String! 
        $price: BigDecimal!
        ) {
          auctionItemSellInternal(
            auctionId: $auctionId, 
            companyId: $companyId, 
            inventoryItemId: $inventoryItemId, 
            price: $price
            ) {
              id
              status
              format
          }
      }
    `,
      variables: {
        auctionId,
        companyId,
        inventoryItemId,
        price,
      },
    },
  });
