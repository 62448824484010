import { connect, ConnectedProps } from 'react-redux';
import { ComponentProps } from 'react';
import { SetOptional, SetRequired } from 'type-fest';

import AddFeeFormContainer from 'containers/admin/auctions/forms/addFeeFormContainer';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import FeesSlideOutList from 'components/sections/admin/auctions/details/fees/feesSlideOutList';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { AppDispatch, AppState } from 'store/configureStore';
import { AuctionFeeProps, AuctionItemFeeProps, FeeType, InventoryItemFeeProps } from 'forms/admin/auctions/addFeeForm';
import { AuctionServiceMetadataParty, CurrencyCode, QueryfeesArgs } from 'store/shared/api/graph/interfaces/types';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { Pane, Tabs } from 'components/ui/shared/tabs/tabs';
import { ServiceFee } from 'components/sections/admin/auctions/details/fees/feesSlideOutListItem';
import { getFees } from 'store/shared/api/graph/queries/fees';
import { getInventoryFees } from 'store/shared/api/graph/queries/inventoryServices';
import { getUrlParams } from 'utils/urlUtils';
import { processRefreshAuctionItemDetails } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { removeAuctionFee } from 'store/shared/api/graph/mutations/auctionServices';
import { removeInventoryFee } from 'store/shared/api/graph/mutations/inventoryServices';
import { t } from 'utils/intlUtils';

import style from './feesSlideOut.scss';

const stateConnect = (state: AppState) => ({
  /** The logged-in user */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Function to refresh auction item details */
  refreshAuctionItemDetails: (options) => processRefreshAuctionItemDetails(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface BaseProps extends ConnectedProps<typeof connector> {
  /** True disables outside click */
  isClickOutsideDisabled: boolean;
  /** True opens slide out */
  isOpen: boolean;
  /** Callback to handle onClose event */
  onClose?: () => void;
  /** Callback to handle onCloseDialog event */
  onCloseDialog?: () => void;
  /** Callback to handle onOpenDialog event */
  onOpenDialog?: () => void;
  /** Current selected tab index */
  selectedTabIndex?: number;
  /** True, refresh auction item on update */
  shouldRefreshAuctionItemOnUpdate?: boolean;
  /** Title for the slide out */
  title: string | undefined;
}

type CurrencyProps = {
  /** The currency code to format to */
  currencyCode?: CurrencyCode;
};

type IntersectedAuctionItemFeeProps = SetOptional<AuctionItemFeeProps, 'format'> & BaseProps & CurrencyProps;
type IntersectedAuctionFeeProps = SetOptional<AuctionFeeProps, 'format'> & BaseProps & CurrencyProps;
type IntersectedInventoryItemFeeProps = InventoryItemFeeProps & BaseProps;

type Props = IntersectedAuctionItemFeeProps | IntersectedAuctionFeeProps | IntersectedInventoryItemFeeProps;

interface State {
  errorMessages: any[];
  serviceFees: ServiceFee[];
  isDialogOpen: boolean;
  isLoadingFees: boolean;
  isRemoveFeeDialogOpen: boolean;
  isRemovingFee: boolean;
  selectedFeeIdToRemove?: string;
  selectedParty: AuctionServiceMetadataParty;
  selectedTabIndex: number;
}

class FeesSlideOut extends BaseClass<Props, State> {
  static defaultProps = {
    onClose: () => {},
  };

  state: State = {
    errorMessages: [],
    isDialogOpen: false,
    isLoadingFees: false,
    isRemoveFeeDialogOpen: false,
    isRemovingFee: false,
    selectedFeeIdToRemove: undefined,
    selectedParty: AuctionServiceMetadataParty.BUYER,
    selectedTabIndex: 0,
    serviceFees: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps?.isOpen && this.props?.isOpen) {
      this.onTabChange(this.props.selectedTabIndex || 0);
    } else if (prevProps?.isOpen && !this.props?.isOpen) {
      this.onTabChange(0);
    }
  }

  getAuctionFees = ({
    currencyCode,
    format,
    ...props
  }: IntersectedAuctionItemFeeProps | IntersectedAuctionFeeProps) => {
    const { selectedParty } = this.state;

    const options: QueryfeesArgs = {
      format,
      party: selectedParty,
      ...((props.feeType === FeeType.AUCTION || props.feeType === FeeType.AUCTION_COMPANY) && {
        auctionId: props.auctionId,
        companyId: props.companyId,
      }),
      ...(props.feeType === FeeType.AUCTION_ITEM && {
        auctionId: props.auctionId,
        companyId: props.companyId,
        auctionItemId: props.auctionItemId,
      }),
    };

    this.setState({ isLoadingFees: true });
    return getFees(options)
      .then((response) =>
        this.setState({
          serviceFees:
            response?.data?.data?.fees?.map<ServiceFee>((fee) => ({
              id: fee.id,
              amount: fee.inventoryItem ? fee.amount : (fee.feeFixedValue ?? null),
              buyerShield: fee.buyerShield || undefined,
              companySubTypes: fee.companySubTypes?.filter(Boolean),
              currencyCode,
              feeMode: fee.feeFixedMode || undefined,
              feeTiers: fee.feeTiers?.filter(Boolean),
              feeType: fee.feeType,
              isInventoryItemFee: !!fee.inventoryItem,
              paidBy: fee.paidBy || undefined,
              serviceMetadata: fee.serviceMetadata,
              updated: fee.updated,
              verified: fee.verified || undefined,
            })) || [],
        })
      )
      .catch(this.onApiError)
      .finally(() => this.setState({ isLoadingFees: false }));
  };

  getInventoryFees = ({ inventoryItemId }: IntersectedInventoryItemFeeProps) => {
    const { selectedParty } = this.state;

    this.setState({ isLoadingFees: true });
    return getInventoryFees({ inventoryItemId })
      .then((response) =>
        this.setState({
          serviceFees:
            response?.data?.data?.inventoryItem.inventoryServices
              ?.filter(({ serviceMetadata }) => serviceMetadata.party === selectedParty)
              ?.map<ServiceFee>((inventoryFee) => ({
                id: inventoryFee.id,
                amount: inventoryFee.formattedAmount,
                isInventoryItemFee: true,
                serviceMetadata: inventoryFee.serviceMetadata,
                updated: inventoryFee.updated,
              })) || [],
        })
      )
      .catch(this.onApiError)
      .finally(() => this.setState({ isLoadingFees: false }));
  };

  getFees = () => {
    return this.props.feeType === FeeType.INVENTORY
      ? this.getInventoryFees(this.props)
      : this.getAuctionFees(this.props);
  };

  onTabChange = (index) => {
    this.setState(
      {
        selectedTabIndex: index,
        selectedParty: index === 0 ? AuctionServiceMetadataParty.BUYER : AuctionServiceMetadataParty.SELLER,
      },
      this.getFees
    );
  };

  onUpdateFeesComplete = () => {
    if (this.props?.shouldRefreshAuctionItemOnUpdate) {
      const params = getUrlParams();
      const options = { ...params, auctionItemId: params?.id };
      this.props?.refreshAuctionItemDetails(options);
    }

    return this.getFees();
  };

  getFeeFormContainerProps(): ComponentProps<typeof AddFeeFormContainer> {
    const { isDialogOpen, selectedParty } = this.state;
    const { onCloseDialog, ...props } = this.props as
      | SetRequired<IntersectedAuctionItemFeeProps, 'format'>
      | SetRequired<IntersectedAuctionFeeProps, 'format'>
      | IntersectedInventoryItemFeeProps;

    const baseProps = {
      isOpen: isDialogOpen,
      onClose: () => this.setState({ isDialogOpen: false }, onCloseDialog),
      onUpdateList: this.onUpdateFeesComplete,
      party: selectedParty,
    };

    const feeType = props.feeType;

    switch (feeType) {
      case FeeType.INVENTORY:
        return {
          ...baseProps,
          feeType,
          inventoryItemId: props.inventoryItemId,
        };

      case FeeType.AUCTION_ITEM:
        return {
          ...baseProps,
          auctionId: props.auctionId,
          auctionItemId: props.auctionItemId,
          companyId: props.companyId,
          feeType,
          format: props.format,
        };

      case FeeType.AUCTION_COMPANY:
      case FeeType.AUCTION:
      default:
        return {
          ...baseProps,
          auctionId: props.auctionId,
          companyId: props.companyId,
          feeType,
          format: props.format,
        };
    }
  }

  render() {
    const { isClickOutsideDisabled, isOpen, onClose, onCloseDialog, onOpenDialog, title, user, ...props } = this.props;

    const {
      errorMessages,
      isLoadingFees,
      isRemoveFeeDialogOpen,
      isRemovingFee,
      selectedFeeIdToRemove,
      selectedParty,
      selectedTabIndex,
      serviceFees,
    } = this.state;

    const feeType = props.feeType;
    const auctionId = props.feeType === FeeType.INVENTORY ? undefined : props.auctionId;

    return (
      <>
        <SlideOut
          contentClassName={style.slideOut}
          contentInnerClassName={style.slideOutInner}
          headerClassName={style.slideOutHeader}
          isClickOutsideDisabled={isClickOutsideDisabled}
          isOpen={isOpen}
          onClose={onClose}
          title={title}
        >
          <Tabs
            activeButtonClass={style.activeTab}
            buttonClass={style.tabButton}
            className={style.tabs}
            onChange={this.onTabChange}
            selected={selectedTabIndex}
            tabClass={style.tab}
          >
            <Pane label={t('buyer_fees')}>
              <FeesSlideOutList
                addButtonLabel={t('add_buyer_fee')}
                auctionId={auctionId}
                isLoadingFees={isLoadingFees}
                onAdd={() => this.setState({ isDialogOpen: true }, onOpenDialog)}
                onRemove={(serviceId) => {
                  this.setState({ isRemoveFeeDialogOpen: true, selectedFeeIdToRemove: serviceId }, onOpenDialog);
                }}
                serviceFees={serviceFees}
                user={user}
              />
            </Pane>
            <Pane label={t('seller_fees')}>
              <FeesSlideOutList
                addButtonLabel={t('add_seller_fee')}
                auctionId={auctionId}
                isLoadingFees={isLoadingFees}
                onAdd={() => this.setState({ isDialogOpen: true }, onOpenDialog)}
                onRemove={(serviceId) => {
                  this.setState({ isRemoveFeeDialogOpen: true, selectedFeeIdToRemove: serviceId }, onOpenDialog);
                }}
                serviceFees={serviceFees}
                user={user}
              />
            </Pane>
          </Tabs>
        </SlideOut>

        <AddFeeFormContainer {...this.getFeeFormContainerProps()} />

        <ConfirmDialog
          actionLabel={t('confirm')}
          actionProgress={isRemovingFee}
          containerClassName={style.removeFeeDialog}
          isOpen={isRemoveFeeDialogOpen}
          onConfirm={(shouldSubmit) => {
            if (shouldSubmit && selectedFeeIdToRemove) {
              this.setState({ errorMessages: [], isRemovingFee: true });

              const removeFee =
                feeType === FeeType.INVENTORY
                  ? removeInventoryFee({ inventoryServiceId: selectedFeeIdToRemove })
                  : removeAuctionFee({ auctionServiceId: selectedFeeIdToRemove });

              removeFee
                .then(() =>
                  this.setState({ isRemoveFeeDialogOpen: false, selectedFeeIdToRemove: undefined }, onCloseDialog)
                )
                ?.then(this.onUpdateFeesComplete)
                ?.catch(this.onApiError)
                ?.finally(() => this.setState({ isRemovingFee: false }));
            } else {
              this.setState({ isRemoveFeeDialogOpen: false, selectedFeeIdToRemove: undefined }, onCloseDialog);
            }
          }}
          theme="red"
          title={t('remove_x_fee', [selectedParty])}
        >
          <FormErrors errorMessages={errorMessages} isSmallDialog />
          {t('remove_fee_question')}
        </ConfirmDialog>
      </>
    );
  }
}

export default connector(FeesSlideOut);
