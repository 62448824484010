import moment from 'moment';
import classnames from 'classnames';

import { BidTimeline, BidType } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './bids.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    bidTimeline: BidTimeline;
    status: string;
  };
}

const InventoryItemDetailsBids = ({ inventoryDetailsData }: Props) => {
  const bidTimeline = inventoryDetailsData?.bidTimeline;
  const isSold = inventoryDetailsData?.status === 'SOLD';

  return (
    <div className={style.summarySection}>
      <ul>
        {bidTimeline.list?.filter(Boolean).map((bid, index) => (
          <li key={bid.id} className={style.listItem}>
            <div>
              <span className={style.accentuate}>
                {bid.type === BidType.FLOOR ? t('floor_bid') : bid?.company?.name}
                {bid.retracted && ` (${t('retracted')})`}
              </span>
              {` - ${moment(bid.created).format('h:mm A')}`}
            </div>
            <div className={classnames(style.price, isSold && index === 0 && style.green)}>
              {isSold && index === 0 && `${t('sold')} - `}
              {bid.amount.formattedAmountRounded}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryItemDetailsBids;
