import { connect, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AuctionItemUpdateInput,
  QueryauctionItemSuggestedSaleLightsArgs,
  SaleLight,
} from 'store/shared/api/graph/interfaces/types';
import Button from 'components/ui/shared/button';
import Loading from 'components/ui/loading/loading';
import SaleLightFormSection from 'components/sections/auctionItem/operations/submitToAuction/pages/saleLightForm/saleLightFormSection';
import SlideOut from 'components/ui/slideOuts/slideOut';
import { AppDispatch } from 'store/configureStore';
import { getErrors } from 'utils/apiUtils';
import { getSuggestedSaleLights } from 'store/auctionSubmission/auctionSubmissionApi';
import { processAuctionItemUpdate } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';
import { usePrevious } from 'hooks/usePrevious';

import style from './saleLightsSlideOut.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to set the sale lights. */
  auctionItemUpdate: (options: AuctionItemUpdateInput) => processAuctionItemUpdate(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The active sale lights of the auction lane. */
  activeSaleLights: SaleLight[];
  /** True when the auction item is being sold as is. */
  asIs: boolean;
  /** The id of the auction item. */
  auctionItemId: string;
  /** The id of the inventory item. */
  inventoryItemId: string;
  /** True when the slide out is open. */
  isOpen: boolean;
  /** Function invoked on slide out close. */
  onClose: () => void;
  /** Determines if sale lights should be automated or manually overridden */
  saleLightsOverride?: SaleLight[];
}

const SaleLightsSlideOut = ({
  activeSaleLights,
  asIs,
  auctionItemId,
  auctionItemUpdate,
  inventoryItemId,
  isOpen,
  onClose,
  saleLightsOverride,
}: Props) => {
  const { setConfig } = useGlobalDialog();
  const auctionItemIdPrev = usePrevious(auctionItemId);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentSaleLights, setCurrentSaleLights] = useState<SaleLight[]>(activeSaleLights || []);
  const [suggestedSaleLights, setSuggestedSaleLights] = useState<SaleLight[]>();

  /**
   * Gets the existing or suggested sale lights for the physical auction item.
   *  - If the auction item has sale lights, then use those.
   *  - Otherwise, get the suggested sale lights.
   */
  useEffect(() => {
    if (!isOpen || !!currentSaleLights.length || !!saleLightsOverride?.length || suggestedSaleLights !== undefined) {
      return;
    }

    if (activeSaleLights.length) {
      setCurrentSaleLights(activeSaleLights);
      return;
    }

    setLoading(true);

    const options: QueryauctionItemSuggestedSaleLightsArgs = {
      asIs,
      inventoryItemId,
    };

    getSuggestedSaleLights(options)
      .then((response) => {
        const suggestedSaleLightsResponse = response?.data?.data?.auctionItemSuggestedSaleLights ?? [];
        setSuggestedSaleLights(suggestedSaleLightsResponse);
        setCurrentSaleLights(suggestedSaleLightsResponse);
      })
      .catch((error) => setConfig({ errorsOverride: getErrors(error) }))
      .finally(() => setLoading(false));
  }, [
    activeSaleLights,
    asIs,
    currentSaleLights,
    inventoryItemId,
    isOpen,
    setConfig,
    saleLightsOverride,
    suggestedSaleLights,
  ]);

  const onSubmit = useCallback(() => {
    setLoading(true);

    auctionItemUpdate({
      auctionItemId,
      saleLightsInput: {
        clearAutoBids: false,
        saleLights: currentSaleLights,
      },
    })
      .then(onClose)
      .catch((error) => setConfig({ errorsOverride: getErrors(error) }))
      .finally(() => {
        setLoading(false);
      });
  }, [auctionItemId, auctionItemUpdate, currentSaleLights, onClose, setConfig]);

  /**
   * Determines if the user has changed the sale lights.
   */
  const isLightsChanged = useMemo(() => {
    const initialSaleLights = suggestedSaleLights || activeSaleLights;
    return !isEqual(currentSaleLights.sort(), initialSaleLights.sort());
  }, [activeSaleLights, currentSaleLights, suggestedSaleLights]);

  /**
   * Determines if the save button should be disabled.
   */
  const isSaveDisabled = useMemo(() => {
    if (suggestedSaleLights?.length) {
      return false;
    }

    return !isLightsChanged;
  }, [isLightsChanged, suggestedSaleLights]);

  /**
   * Function called when the user reverts the sale light changes.
   */
  const onRevertLight = useCallback(() => {
    setCurrentSaleLights(suggestedSaleLights || activeSaleLights);
  }, [suggestedSaleLights, activeSaleLights]);

  /**
   * Function called when the user changes the sale lights.
   */
  const onChangeLights = useCallback((currentLights) => {
    setCurrentSaleLights(currentLights);
  }, []);

  /**
   * Function called when slide out is closed.
   */
  const onSlideOutClosed = useCallback(() => {
    setCurrentSaleLights([]);
    setSuggestedSaleLights(undefined);
    onClose();
  }, [onClose]);

  /**
   * Close slide out when the auction item changes.
   */
  useEffect(() => {
    if (auctionItemIdPrev && auctionItemIdPrev !== auctionItemId) {
      onSlideOutClosed();
    }
  }, [auctionItemId, auctionItemIdPrev, onSlideOutClosed]);

  return (
    <SlideOut
      className={style.slideOut}
      contentInnerClassName={style.content}
      isOpen={isOpen}
      onClose={onSlideOutClosed}
      title={t('sale_lights')}
    >
      {!isLoading && (
        <div className={style.form}>
          <SaleLightFormSection
            activeSaleLights={currentSaleLights}
            infoMessageClassName={style.infoMessage}
            isSuggestedOverridden={isLightsChanged}
            onChange={onChangeLights}
            onRevert={onRevertLight}
            saleLightsOverride={saleLightsOverride}
          />
          <Button className={style.saveButton} disabled={isSaveDisabled} onClick={onSubmit} theme="blue">
            {t('save')}
          </Button>
        </div>
      )}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default connector(SaleLightsSlideOut);
