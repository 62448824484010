import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import archiveGlyph from 'glyphs/operationIcons/archive-item.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InventoryItem from 'constants/inventoryItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import {
  MutationauctionItemArchiveArgs,
  MutationinventoryItemArchiveArgs,
} from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { processArchiveAuctionItem } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { processArchiveInventoryItem } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

import style from './archive.scss';

type ArchiveOptions = MutationauctionItemArchiveArgs | MutationinventoryItemArchiveArgs;

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to archive the inventory item. */
  archiveInventoryItem: (options: ArchiveOptions) => processArchiveInventoryItem(options, dispatch),
  /** Dispatch function to archive the auction item. */
  archiveAuctionItem: (options: ArchiveOptions) => processArchiveAuctionItem(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem?: AuctionItem;
  /** The inventory item details. */
  inventoryItem?: InventoryItem;
  /** Function invoked to refresh the list. */
  onRefreshList?: () => void;
}

interface State {
  /** Validation Messages. */
  errorMessages: ErrorMessages;
}

class Archive extends BaseClass<Props, State> {
  static defaultProps = {
    onRefreshList: () => {},
  };

  state = { errorMessages: [] };

  archive = () => {
    const { archiveAuctionItem, archiveInventoryItem, auctionItem, inventoryItem } = this.props;

    if (inventoryItem) {
      trackUserActionWithInventoryItemAttributes(UserAction.VDP_ARCHIVE_CLICK, inventoryItem);
      return archiveInventoryItem({ inventoryItemId: inventoryItem.id, archive: !inventoryItem.archived });
    }

    if (auctionItem) {
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_ARCHIVE_CLICK, auctionItem);
      return archiveAuctionItem({ auctionItemId: auctionItem.id, archive: !auctionItem.archived });
    }

    return null;
  };

  onArchive = () => {
    const { onRefreshList } = this.props;

    this.archive()
      ?.then(onRefreshList)
      .catch((error) => {
        const errorMessages = getErrors(error).map((err) => err.message);
        this.setState({ errorMessages });
      });
  };

  render() {
    const { auctionItem, inventoryItem } = this.props;
    const { errorMessages } = this.state;
    const currentEntity = inventoryItem || auctionItem;
    const isArchived = !!currentEntity?.archived;
    const text = t(isArchived ? 'unarchive' : 'archive');

    return (
      <>
        <OperationButton
          className={classnames(style.archiveButton, { [style.archived]: isArchived })}
          glyph={archiveGlyph}
          onClick={this.onArchive}
          theme="none"
        >
          {text}
        </OperationButton>
        <ConfirmDialog
          isOpen={!!errorMessages.length}
          onConfirm={() => this.setState({ errorMessages: [] })}
          theme="red"
          title={t('error')}
          titleClassName={style.errorTitle}
        >
          {errorMessages.map((err) => err)}
        </ConfirmDialog>
      </>
    );
  }
}

export default connector(Archive);
