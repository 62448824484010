import classnames from 'classnames';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import AddModifyInventoryItemContainer from 'containers/inventoryItem/addModify/addModifyContainer';
import AnchorTabs, { SCROLLABLE_CONTENT_ID } from 'components/ui/shared/tabs/anchorTabs';
import Ended from 'components/sections/auctionItem/details/ended';
import Header, { HeaderType } from 'components/sections/auctionItem/details/header';
import Hero from 'components/sections/auctionItem/details/hero';
import InventoryItemDetails from 'components/sections/inventoryItem/details/inventoryItemDetails';
import NotAvailable from 'components/sections/auctionItem/details/notAvailable';
import Operations from 'components/sections/inventoryItem/operations/operations';
import Status from 'components/sections/auctionItem/details/status/status';
import User from 'constants/user';
import VinDetails from 'components/sections/inventoryItem/details/vinDetails';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { AuctionItemStatus, LiveLane, TopOffer } from 'store/shared/api/graph/interfaces/types';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { Location } from 'constants/reactRouter';
import { LooseObject } from 'constants/objects';
import { PaneIndex } from 'components/sections/inventoryItem/addModify/vehicleForm';
import { SectionNavigation } from 'components/sections/inventoryItem/addModify/interfaces/vehicleForm';
import { Spinner } from 'components/ui/loading/loading';
import { getAnchorTabsConfig } from 'components/sections/inventoryItem/details/anchorTabs';

import style from './auctionItemDetailsDigital.scss';

interface Props {
  /** Auction item details. */
  auctionItemDetails: AuctionItemDetailsProps;

  /** Rendered child elements. */
  children: ReactNode;

  /** Function invoked to clear auction item. */
  clearAuctionItem: () => void;

  /** Function invoked when inventory option clicked. */
  handleFeatureClicked: (featureType: string, inventoryDetailsData?: LooseObject) => void;

  /** True when the auction details are updating. */
  isUpdating: boolean;

  /** True when user does not have permission to view the auction item.*/
  isPermissionDenied: boolean;

  /** The live lane for the auction item. */
  liveLane: LiveLane | undefined;

  /** The current url information for the component. */
  location: Location;

  /** Function Invoked when dialog closed. */
  onEndedClose: () => void;

  /** Callback function to refresh the list. */
  refreshList: (() => void) | undefined;

  /** Whether to show auction is ended or not. */
  showEnded: boolean;

  /** System time offset. */
  timeOffset: number;

  /** The top offer */
  topOffer?: TopOffer | null;

  /** The total bids applied to the current unit */
  totalBids: number;

  /** The unique bidders for to the current unit */
  uniqueBidders: string[];

  /** Current user. */
  user: User;
}

const AuctionItemDetailsDigital = ({
  auctionItemDetails,
  children,
  clearAuctionItem,
  handleFeatureClicked,
  isPermissionDenied,
  isUpdating,
  liveLane,
  location,
  onEndedClose,
  refreshList,
  showEnded,
  timeOffset,
  topOffer,
  totalBids,
  uniqueBidders,
  user,
}: Props) => {
  const [isAddModifyOpen, setAddModifyOpen] = useState<boolean>(false);
  const [isNotesOpen, setNotesOpen] = useState<boolean>(false);
  const [navigateToSection, setNavigateToSection] = useState<SectionNavigation>({
    paneIndex: null,
    section: '',
  });
  const { archived, asIs, format, inventoryItem, isAssured, isMyItem } = auctionItemDetails;
  const isUpdatable = inventoryItem.isUpdatable;
  const headerType =
    auctionItemDetails.displayRunNumber ||
    !!auctionItemDetails.saleLights?.length ||
    !!auctionItemDetails.auction?.settings?.saleLightsOverride?.length
      ? HeaderType.FULL
      : HeaderType.COMPACT;

  /**
   * Memoized anchor tabs configuration
   */
  const anchorTabsConfig = useMemo(
    () => getAnchorTabsConfig(inventoryItem, auctionItemDetails, user, true),
    [auctionItemDetails, inventoryItem, user]
  );
  const anchorTabs = Object.values(anchorTabsConfig);

  /**
   * onOpenAddModifyModal
   */
  const openAddModifyModal = useCallback(
    (section: SectionNavigation['section'], paneIndex: SectionNavigation['paneIndex']) => {
      setAddModifyOpen(true);
      setNavigateToSection({ section, paneIndex });
    },
    []
  );

  /**
   * Handles vin details click event. Opens up the modify modal
   * pointing to vin details
   */
  const handleVinDetailsOnClick = useCallback(() => {
    return openAddModifyModal('vinDetails', PaneIndex.VEHICLE_DETAILS);
  }, [openAddModifyModal]);

  /**
   * Handles notes click event. Toggles notes open state
   */
  const handleNotesClick = useCallback(() => {
    setNotesOpen(!isNotesOpen);
  }, [isNotesOpen]);

  /**
   * Refreshes list when auction item is cancelled or no sale
   */
  const onRefreshList = useMemo(() => {
    return [AuctionItemStatus.SALE_CANCELLED, AuctionItemStatus.NO_SALE].includes(auctionItemDetails.status)
      ? refreshList
      : undefined;
  }, [auctionItemDetails.status, refreshList]);

  return (
    <>
      <div className={style.details}>
        <div className={style.contentWrapper} id={SCROLLABLE_CONTENT_ID}>
          <div
            className={classnames(style.header, {
              [style.compactHeader]: headerType === HeaderType.COMPACT,
              [style.fullHeader]: headerType === HeaderType.FULL,
            })}
            data-testid="auction-item-details-header"
          >
            <Header
              details={auctionItemDetails}
              onNotesClick={handleNotesClick}
              onRefreshList={onRefreshList}
              user={user}
            />
          </div>
          <section className={classnames(style.content, style.heroContainer, archived && style.archived)}>
            <Hero
              auctionItemDetails={auctionItemDetails}
              displayNotes={isNotesOpen}
              handleFeatureClicked={handleFeatureClicked}
              isUpdating={isUpdating}
              liveLane={liveLane}
              openAddModifyModal={openAddModifyModal}
              timeOffset={timeOffset}
              user={user}
            />
          </section>
          <AnchorTabs defaultSelected={0} headerType={headerType} isSticky tabs={anchorTabs} />
          {isUpdating ? (
            <section className={style.content}>
              <Spinner className={style.detailsLoader} />
            </section>
          ) : (
            <Switch>
              <Case if={!isPermissionDenied}>
                <Operations headerType={headerType} onRefreshList={onRefreshList} />
                {inventoryItem.vin && (
                  <section>
                    <VinDetails
                      auctionItem={auctionItemDetails}
                      isUpdatable={isUpdatable}
                      onUpdateButtonClick={handleVinDetailsOnClick}
                      vin={inventoryItem.vin}
                    />
                  </section>
                )}
                <section id={anchorTabsConfig.AUCTION_DETAILS.id}>
                  <Status auctionItemDetails={auctionItemDetails} handleFeatureClicked={handleFeatureClicked} />
                </section>
                <section data-testid="inventory-item-details-section">
                  <InventoryItemDetails
                    anchorTabsConfig={anchorTabsConfig}
                    auctionItem={auctionItemDetails}
                    handleFeatureClicked={handleFeatureClicked}
                    inventoryItemDetails={inventoryItem}
                    isAsIs={asIs}
                    isAssured={isAssured}
                    isAuctionItemView
                    isMyItem={isMyItem}
                    isUpdatable={isUpdatable}
                    location={location}
                    openAddModifyModal={openAddModifyModal}
                    user={user}
                  />
                </section>
              </Case>
              <Case>
                <section className={style.content}>
                  <NotAvailable onClose={clearAuctionItem} />
                </section>
              </Case>
            </Switch>
          )}
        </div>
        {children}
      </div>
      {showEnded && (
        <Ended
          currencyCode={auctionItemDetails?.furtherBidIncrement?.currencyCode}
          format={format}
          isInAuctionItemsList
          isMyItem={isMyItem}
          onClose={() => onEndedClose()}
          topOffer={topOffer || undefined}
          totalBids={totalBids}
          uniqueBidders={uniqueBidders}
          user={user}
        />
      )}
      <AddModifyInventoryItemContainer
        auctionItemId={auctionItemDetails.id}
        closeModal={() => setAddModifyOpen(false)}
        inventoryItemId={auctionItemDetails.inventoryItem.id}
        isOpen={isAddModifyOpen}
        navigateToSection={navigateToSection}
      />
    </>
  );
};

export default AuctionItemDetailsDigital;
