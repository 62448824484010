import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlot } from 'store/shared/api/graph/interfaces/types';

interface EventDetailsModel {
  details: AuctionTimeSlot | null;
}

export type EventDetailsProps = ServerRecordOf<EventDetailsModel>;

export const InitialState = ServerRecord<EventDetailsModel>({
  details: null,
});
