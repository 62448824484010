import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';

import BaseClass from 'components/ui/shared/base';
import routes from 'store/routing/routesConfig';
import { AppDispatch, AppState } from 'store/configureStore';
import { Locale } from 'utils/intlUtils';
import { Router } from 'constants/reactRouter';
import { checkApplicationVersion } from 'utils/versionUtils';
import { configure, stop } from 'io/pubsub/pubsub';
import { networkDown, networkUp, updateTimeOffset } from 'store/system/systemActions';
import { runEvery, runEverySecond, stopEverySecond } from 'utils/runLoopUtils';

const stateConnect = (state: AppState) => ({
  /** Notification channels to listen on. */
  channels: state.app.user.channels,
  /** Whether PubSub is active or not. */
  isPubSubActive: state.app.system.isPubSubActive,
  /** Whether application has timed out or not. */
  isTimedOut: state.app.system.isTimedOut,
  /** The logged-in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Notify that network is down. */
  dispatchNetworkDown: () => dispatch(networkDown()),
  /** Notify that network is up. */
  dispatchNetworkUp: () => dispatch(networkUp()),
  /** Set the time-offset. */
  setTimeOffset: () => updateTimeOffset(dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The browser history */
  history: History;
  /** The initial locale value */
  initialLocale: Locale;
}

class Root extends BaseClass<Props> {
  private timeOffsetInterval: number;

  constructor(props: Props) {
    super(props);
    this.timeOffsetInterval = 0;
  }

  componentDidMount() {
    super.componentDidMount();

    const { dispatchNetworkUp, dispatchNetworkDown } = this.props;
    window.addEventListener('online', dispatchNetworkUp);
    window.addEventListener('offline', dispatchNetworkDown);

    if (process.env.NODE_ENV !== 'development') {
      runEvery(1000 * 60 * 5, checkApplicationVersion);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { channels, user, isPubSubActive } = prevProps;
    const { channels: channelsNext, user: userNext, isTimedOut, isPubSubActive: isPubSubActiveNext } = this.props;

    const channelsChanged = JSON.stringify(channels) !== JSON.stringify(channelsNext);
    const userIdChanged = user.id !== userNext.id;
    const pubSubActiveChanged = isPubSubActive !== isPubSubActiveNext;

    if ((pubSubActiveChanged && !isPubSubActiveNext) || isTimedOut) {
      // Request to disable subscriptions, or user inactivity timeout; unsubscribe from PubSub
      stop();
    } else if (pubSubActiveChanged || channelsChanged || userIdChanged) {
      if (isPubSubActiveNext) {
        configure(channelsNext?.filter(Boolean) || []);
      }
    }

    if (process.env.APP_ENV === 'prod') {
      if (!user.loadedDate && userNext.loadedDate) {
        runEverySecond(this.handleTimeOffsetUpdate);
      } else if (!userNext.loadedDate && user.loadedDate) {
        stopEverySecond(this.handleTimeOffsetUpdate);
        this.timeOffsetInterval = 0;
      }
    }
  }

  handleTimeOffsetUpdate = () => {
    // First 10 every second, then every minute after that.
    if (this.timeOffsetInterval < 10 || this.timeOffsetInterval % 60 === 0) {
      this.props.setTimeOffset();
    }

    this.timeOffsetInterval += 1;
  };

  render() {
    const { history } = this.props;

    return (
      <div>
        <Router history={history}>{routes}</Router>
      </div>
    );
  }
}

export default connector(Root);
