export const inventoryItemFees = `
  inventoryItemFee {
    transportFee {
      amount
      formattedAmount
    }
    transportFeeText
    mechanicalFee {
      amount
      formattedAmount
    }
    mechanicalFeeText
    reconditioningFee {
      amount
      formattedAmount
    }
    reconditioningFeeText
    storageFee {
      amount
      formattedAmount
    }
    storageFeeText
    redemptionFee {
      amount
      formattedAmount
    }
    redemptionFeeText
    marshallingFee {
      amount
      formattedAmount
    }
    marshallingFeeText
    miscFee {
      amount
      formattedAmount
    }
    miscFeeText
    registrationFee {
      amount
      formattedAmount
    }
    registrationFeeText
    fuelFee {
      amount
      formattedAmount
    }
    fuelFeeText
  }
`;

export const inventoryItemValues = `
  values {
    id
    type {
      id
      name
      canAdd
      canUpdate
      canDelete
    }
    value {
      currencyCode
      amount
      formattedAmount
      formattedAmountRounded
    }
    notes
    createdBy {
      firstName
      lastName
    }
    created
    updated
    updatedBy {
      firstName
      lastName
    }
    expiryDate
  }
`;
