import { ComponentProps, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import AuctionItemEventsIOUpdater from 'components/sections/auctionItem/details/events/auctionItemEventsIOUpdater';
import EventMessage from 'components/sections/auctionItem/details/events/eventMessage';
import Section from 'components/sections/admin/liveLanes/shared/section/section';
import SectionContent from 'components/sections/admin/liveLanes/shared/section/sectionContent';
import SectionHeader from 'components/sections/admin/liveLanes/shared/section/sectionHeader';
import User from 'constants/user';
import { AuctionEventTimelineEntry, AuctionEventTimelineEntryAudience } from 'store/shared/api/graph/interfaces/types';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './events.scss';

export const QUERY = gql`
  query EVENTS_QUERY($auctionTimeSlotLaneId: String!) {
    auctionEventTimeline(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
      auctionItemId
      auctionTimeSlotLaneId
      created
      id
      message
      style
    }
  }
`;

interface Props {
  /** The id of the auction item. */
  auctionItemId: string;
  /** Current auction time slot lane information. */
  auctionTimeSlotLaneId: string | undefined;
  /** The designated audience to retrieve messages */
  audience?: AuctionEventTimelineEntryAudience[];
  /** The variant to use */
  containerVariant?: ComponentProps<typeof Section>['variant'];
  /** True displays all events */
  shouldDisplayAllEvents?: boolean;
  /** True display header */
  shouldDisplayHeader?: boolean;
  /** The logged-in user */
  user: User;
}

interface Query {
  auctionEventTimeline?: AuctionEventTimelineEntry[];
}

const Events = ({
  auctionItemId,
  auctionTimeSlotLaneId,
  audience = [AuctionEventTimelineEntryAudience.ALL, AuctionEventTimelineEntryAudience.CUSTOMER],
  containerVariant = 'transparent',
  shouldDisplayAllEvents = false,
  shouldDisplayHeader = false,
  user,
}: Props) => {
  const { data, loading } = useQuery<Query>(QUERY, { variables: { auctionTimeSlotLaneId } });

  /**
   * Display filtered event messages for last 3 auction items in lane for buyers or sellers.
   * Display event messages for all auction items in lane for auction staff.
   * Otherwise, filter event messages by auction item for auction clerk and auctioneer.
   */
  const eventMessages = useMemo(() => {
    const auctionEventTimeline = data?.auctionEventTimeline ?? [];
    if (shouldDisplayAllEvents) {
      if (isAuctionStaff(user)) {
        return auctionEventTimeline;
      }

      const uniqueAuctionItemIds = new Set<string>();
      const filteredEntries: AuctionEventTimelineEntry[] = [];
      auctionEventTimeline.forEach((eventEntry) => {
        if (eventEntry.auctionItemId) {
          if (uniqueAuctionItemIds.has(eventEntry.auctionItemId)) {
            filteredEntries.push(eventEntry);
          } else if (uniqueAuctionItemIds.size < 3) {
            uniqueAuctionItemIds.add(eventEntry.auctionItemId);
            filteredEntries.push(eventEntry);
          }
        } else {
          filteredEntries.push(eventEntry);
        }
      });
      return filteredEntries;
    }
    return auctionEventTimeline.filter((event) => event.auctionItemId === auctionItemId);
  }, [auctionItemId, data?.auctionEventTimeline, shouldDisplayAllEvents, user]);

  const renderEventTimeline = useMemo(() => {
    if (loading) {
      return <SpinnerCentered />;
    }

    return (
      <div className={style.messages}>
        {eventMessages.map((event) => (
          <EventMessage key={event.id} auctionEvent={event} />
        ))}
      </div>
    );
  }, [eventMessages, loading]);

  return (
    <>
      <Section className={style.section} data-testid="auction-item-events" variant={containerVariant}>
        {shouldDisplayHeader && <SectionHeader>{t('events')}</SectionHeader>}
        <SectionContent isScrollable scrollableClassName={style.scroll}>
          {renderEventTimeline}
        </SectionContent>
      </Section>

      {/* Listen for events stream; append to cache-store as they flow in */}
      <AuctionItemEventsIOUpdater auctionTimeSlotLaneId={auctionTimeSlotLaneId} audience={audience} user={user} />
    </>
  );
};

export default Events;
