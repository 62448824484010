import { Fragment } from 'react';

interface Props {
  /** The content to be displayed. */
  content: string;
}

const NoteContent = ({ content }: Props) => {
  return (
    <>
      {content.split('\n').map((line, i) => (
        <Fragment key={i}>
          {line}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export default NoteContent;
