import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import { companiesListIsLoading, companiesListLoaded, companiesListCleared } from './companiesActions';
import { Company, FacetGroup, InitialState, PageInfo } from './companiesModels';

export const companiesReducer = handleActions(
  {
    [companiesListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [companiesListLoaded().type]: (state, action) => {
      const {
        companyConnection: { edges, pageInfo, facetGroups },
      } = action.payload;

      return state.setLoaded().merge({
        resultList: List(edges.map((companyEdge) => new Company(companyEdge.node))),
        facetGroups: List(facetGroups.map((facetGroupData) => new FacetGroup(facetGroupData))),
        pageInfo: new PageInfo(pageInfo),
      });
    },

    [companiesListCleared().type]: () => new InitialState(),
  },
  new InitialState()
);
