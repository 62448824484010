import Lottie, { AnimationItem } from 'lottie-web';

import BaseClass from 'components/ui/shared/base';
import { LooseObject } from 'constants/objects';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;

  /** Optional Lottie animations options. */
  options?: LooseObject;
}

/*
  A simple React wrapper for the lottie-web lib. See more at: https://github.com/airbnb/lottie-web

  const animationOptions = {
    loop: false,
    autoplay: true,
    prerender: true,
    animationData: animationJsonObject
  };

  return (
    <div>
      <SvgAnimation options={animationOptions} />
    </div>
  );
*/
class SvgAnimation extends BaseClass<Props> {
  private animation: AnimationItem;
  private container: HTMLDivElement | null;

  componentDidMount() {
    if (!this._isMounted && this.container) {
      const options = {
        ...this.props.options,
        container: this.container,
      };

      this.animation = Lottie.loadAnimation<'svg'>(options);
    }

    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    if (this.animation) {
      this.animation.destroy();
    }
  }

  render() {
    return (
      <div
        ref={(div) => {
          this.container = div;
        }}
        className={this.props.className}
      />
    );
  }
}

export default SvgAnimation;
