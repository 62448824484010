import classnames from 'classnames';
import { useMemo } from 'react';

import Button from 'components/ui/shared/button';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { SingleSaleLight } from 'components/ui/lists/listItem/saleLights';

import style from './saleLightsButton.scss';

/**
 * The order in which sale lights should appear. Handled via all clients.
 */
export const SaleLightsOrder: SaleLight[] = [SaleLight.GREEN, SaleLight.YELLOW, SaleLight.RED];

/**
 * Reorders sale lights to match `SaleLightsOrder`
 *
 * @param saleLights
 */
export const getSaleLightsInOrder = (saleLights: SaleLight[]) =>
  (saleLights ?? []).sort((a, b) => SaleLightsOrder.indexOf(a) - SaleLightsOrder.indexOf(b));

interface Props {
  /** The active sale light of the auction lane. */
  activeSaleLights: SaleLight[];
  /** CSS styling to override default container style */
  className?: string;
  /** Function invoked on button click. */
  onClick?: () => void;
  /** Determines if sale lights should be automated or manually overridden */
  saleLightsOverride?: SaleLight[];
}

const SaleLightsButton = ({ activeSaleLights, className, onClick, saleLightsOverride }: Props) => {
  const saleLights = useMemo(() => {
    // Default to active sale lights
    let lights = activeSaleLights;
    if (saleLightsOverride?.length) {
      // When sale lights are overridden, limit which ones are displayed
      lights = saleLightsOverride;
    } else if (onClick) {
      // otherwise, only display active ones
      lights = [SaleLight.GREEN, SaleLight.YELLOW, SaleLight.RED];
    }

    const lightsOrdered = getSaleLightsInOrder(lights);
    return lightsOrdered.map((saleLight: SaleLight) => (
      <SingleSaleLight
        key={`sale-light-${saleLight}`}
        isDisabled={!!onClick}
        isSelected={activeSaleLights.includes(saleLight)}
        variant={saleLight}
      />
    ));
  }, [activeSaleLights, onClick, saleLightsOverride]);

  if (!onClick) {
    return (
      <div className={classnames(style.noEditContainer, className)} data-testid="sale-lights-no-edit">
        {saleLights}
      </div>
    );
  }

  return (
    <Button
      className={classnames(style.buttonContainer, className)}
      data-testid="sale-lights-edit-button"
      onClick={() => onClick()}
      theme="none"
    >
      {saleLights}
    </Button>
  );
};

export default SaleLightsButton;
