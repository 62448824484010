import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  Carrier as CarrierType,
  FacetedPageInfo,
  FacetGroup as FacetGroupType,
} from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface CarriersModel {
  /** Facet groups used to filter search results. */
  facetGroups: List<FacetGroupType>;

  /** Information about pagination. */
  pageInfo: FacetedPageInfo;

  /** Result list of carriers. */
  resultList: List<RecordOf<CarrierType>>;
}

export type CarriersProps = ServerRecordOf<CarriersModel>;

export const InitialState = ServerRecord<CarriersModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});

export const Carrier = Record<CarrierType>({
  archived: null,
  contacts: [],
  created: null,
  deliveryIsLocalOnly: false,
  deliveryNotes: null,
  deliveryWhitelistedRegions: [],
  id: null,
  locations: null,
  name: null,
  notes: null,
  primaryLocation: null,
  shipmentModes: [],
  updated: null,
});

export const FacetGroup = Record<FacetGroupType>({
  name: null,
  allowMultiple: false,
  facets: [],
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});
