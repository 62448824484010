import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionServiceMetadata,
  InventoryItem,
  QueryinventoryItemArgs,
  QueryinventoryServiceMetadataArgs,
} from 'store/shared/api/graph/interfaces/types';

export const getInventoryServiceMetadata = ({ party }: QueryinventoryServiceMetadataArgs) =>
  ApiRequest<{ serviceMetadata: AuctionServiceMetadata[] }>({
    data: {
      operationName: 'getInventoryServiceMetadata',
      query: `
        query ($party: AuctionServiceMetadataParty) {
          serviceMetadata: inventoryServiceMetadata(party: $party) {
            id
            feeName
          }
        }
      `,
      variables: {
        party,
      },
    },
  });

export const getInventoryFees = ({ inventoryItemId }: QueryinventoryItemArgs) =>
  ApiRequest<{ inventoryItem: Pick<InventoryItem, 'inventoryServices'> }>({
    data: {
      operationName: 'getInventoryFees',
      query: `
        query ($inventoryItemId: String!) {
          inventoryItem(inventoryItemId: $inventoryItemId) {
            inventoryServices {
              formattedAmount
              id
              serviceMetadata {
                feeName
                party
              }
              updated
            }
          }
        }
      `,
      variables: {
        inventoryItemId,
      },
    },
  });
