import classnames from 'classnames';
import { CSSProperties } from 'react';

import style from './swatch.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The swatch color. */
  color: string;
}

const Swatch = ({ className, color }: Props) => {
  const backgroundStyle: CSSProperties = { background: undefined };

  if (style[color] === undefined) {
    backgroundStyle.background = color;
  }

  return (
    <span
      className={classnames(style.swatchContainer, style[color], className)}
      data-testid="swatch"
      style={backgroundStyle}
    />
  );
};

export default Swatch;
