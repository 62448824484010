import { useRef, useState } from 'react';
import ImageLightBox from 'react-image-lightbox';
import classnames from 'classnames';
import 'react-image-lightbox/style.css';

import defaultVehicleGlyph from 'glyphs/default-vehicle.svg';

import Button from 'components/ui/shared/button';
import Image from 'components/ui/shared/image';
import Photo from 'constants/photo';
import Sprite from 'components/ui/shared/sprite';
import raf from 'utils/raf';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { useMountEffect } from 'hooks/useMountEffect';

import style from './photos.scss';

interface Props extends SlideOutComponentProps {
  /** CSS Styling to overwrite default container style. */
  className?: string;
  /** The inventory details data. */
  inventoryDetailsData: {
    photos: Photo[];
    scrollIndex?: number;
  };
  /** Function to enable/disable click outside. */
  setIsClickOutsideDisabled?: (isEnabled: boolean) => void;
}

const InventoryItemDetailsPhotos = ({ className, inventoryDetailsData, setIsClickOutsideDisabled }: Props) => {
  const { photos, scrollIndex } = inventoryDetailsData;

  // State
  const [activePhoto, setActivePhoto] = useState<number>(-1);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState<boolean>();

  // Refs
  const container = useRef<HTMLUListElement | null>(null);

  useMountEffect(() => {
    // Scroll selected photo into view once slideout 'open' animation completes
    scrollIndex !== undefined &&
      raf(() =>
        container.current?.getElementsByClassName(style.listItem)?.[scrollIndex]?.scrollIntoView({ behavior: 'smooth' })
      );
  });

  return (
    <div className={className} data-testid="photos">
      <ul ref={container} className={style.list}>
        {photos.map((photo, index) => {
          const isDamagePhoto = !!photo.isDamagePhoto || !!photo.location;

          return (
            <li key={photo.expanded} className={classnames(style.listItem)}>
              <div className={classnames(style.photoContainer, isDamagePhoto && style.isDamagePhoto)}>
                <Button
                  className={style.photoButton}
                  dataTestId={`photo-${index}`}
                  onClick={() => {
                    setActivePhoto(index);
                    setIsLightBoxOpen(true);
                    setIsClickOutsideDisabled?.(true);
                  }}
                  theme="none"
                >
                  <Image alt={`Damaged item: ${index + 1}`} className={style.photo} src={photo.expanded || ''}>
                    <Sprite className={style.defaultVehicleIcon} glyph={defaultVehicleGlyph} />
                  </Image>
                </Button>
              </div>
              {isDamagePhoto && (
                <div className={style.info}>
                  <div className={style.type}>{photo.type}</div>
                  <div className={style.location}>{photo.location}</div>
                </div>
              )}
            </li>
          );
        })}
      </ul>

      {isLightBoxOpen && photos?.length && activePhoto > -1 && (
        <ImageLightBox
          imageCaption={photos?.[activePhoto]?.location || undefined}
          mainSrc={photos?.[activePhoto]?.expanded || ''}
          nextSrc={(photos?.length > 1 && photos?.[(activePhoto + 1) % photos?.length]?.expanded) || undefined}
          onCloseRequest={() => {
            setIsLightBoxOpen(false);
            setIsClickOutsideDisabled?.(false);
          }}
          onMoveNextRequest={() => setActivePhoto((activePhoto + 1) % photos?.length)}
          onMovePrevRequest={() => setActivePhoto((activePhoto + (photos?.length - 1)) % photos?.length)}
          prevSrc={
            (photos?.length > 1 && photos?.[(activePhoto + (photos?.length - 1)) % photos?.length]?.expanded) ||
            undefined
          }
        />
      )}
    </div>
  );
};

export default InventoryItemDetailsPhotos;
