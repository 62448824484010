import { ActionPayload, handleActions } from 'redux-actions';
import { List } from 'immutable';

import { AuctionTimeSlotConnection } from 'store/shared/api/graph/interfaces/types';
import { FacetGroup, PageInfo } from 'store/shared/models/graph/connections';
import { InitialState } from 'store/admin/events/list/eventsModels';
import { eventsListCleared, eventsListIsLoading, eventsListLoaded } from 'store/admin/events/list/eventsActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const eventsReducer = handleActions(
  {
    [eventsListIsLoading().type]: (state) => state.set('isLoading', true),

    [eventsListLoaded().type]: (state, action: ActionPayload<AuctionTimeSlotConnection | undefined>) => {
      return state.setLoaded().merge({
        facetGroups: List(action?.payload?.facetGroups?.map((facetGroup) => new FacetGroup(facetGroup)) || []),
        pageInfo: new PageInfo(action?.payload?.pageInfo),
        resultList: List(parseQueryConnectionResponse(action?.payload) || []),
      });
    },

    [eventsListCleared().type]: () => new InitialState(),
  },
  new InitialState()
);
