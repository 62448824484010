import { MouseEventHandler, useCallback, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import User from 'constants/user';
import ViewedBySlideOut from 'components/sections/auctionItem/details/status/statusRows/viewedByRow/viewedBySlideOut';
import { Row } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserAction } from 'logging/analytics/events/userActions';
import { getAuctionItemViewers } from 'store/auctionItemDetails/auctionItemDetailsApi';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './viewedByRow.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** The current user. */
  user: User;
}

const ViewedByRow = ({ auctionItem, user }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState<boolean>(false);
  const [viewers, setViewers] = useState<User[]>([]);

  /**
   * Get auction item watchers.
   */
  const getViewers = useCallback(() => {
    setIsLoading(true);
    getAuctionItemViewers({ auctionItemId: auctionItem.id })
      ?.then((response) => setViewers(response?.data?.data?.auctionItem?.viewers?.filter(Boolean) || []))
      ?.finally(() => setIsLoading(false));
    // TODO: Add catch for api error when EB-2929 is implemented
  }, [auctionItem.id]);

  /**
   * Close slideOut
   */
  const onCloseSlideOut = useCallback(() => {
    setIsSlideOutOpen(false);
  }, []);

  /**
   * Open slide out on row click
   */
  const onOpenSlideOut = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setIsSlideOutOpen(true);
    getViewers();
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_RECENTLY_VIEWED_BY_CLICK, auctionItem);
  }, [auctionItem, getViewers]);

  return (
    <>
      <Row
        className={style.viewedByRow}
        dataTestId="status-row-viewed-by"
        name={t('viewed_by')}
        onClick={onOpenSlideOut}
      />
      <ViewedBySlideOut
        isLoading={isLoading}
        isOpen={isSlideOutOpen}
        onClose={onCloseSlideOut}
        user={user}
        viewers={viewers}
      />
    </>
  );
};

export default ViewedByRow;
