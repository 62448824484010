import classnames from 'classnames';
import { memo } from 'react';

import infoGlyph from 'glyphs/info.svg';

import NotificationText from 'components/ui/shared/notifications/notificationText';
import Sprite from 'components/ui/shared/sprite';
import Tooltip from 'components/ui/shared/tooltips/tooltip';
import { t } from 'utils/intlUtils';

import style from './marketGuideValuesTooltip.scss';

interface Props {
  /** CSS styling to overwrite default tooltip icon style.*/
  tooltipDescriptionClassName?: string;
  /** CSS styling to overwrite default tooltip icon style.*/
  tooltipIconClassName?: string;
}

export const MarketGuideValuesTooltipText = () => (
  <>
    <strong>{t('low')}</strong>
    <br />
    {t('market_guide_low_info')}
    <br />
    <br />

    <strong>{t('average')}</strong>
    <br />
    {t('market_guide_average_info')}
    <br />
    <br />

    <strong>{t('high')}</strong>
    <br />
    {t('market_guide_high_info')}
  </>
);

const MarketGuideValuesTooltip = ({ tooltipDescriptionClassName, tooltipIconClassName }: Props) => (
  <div className={style.container}>
    <div className={tooltipDescriptionClassName}>{t('my_market_guide')}</div>
    <Tooltip
      className={style.tooltip}
      trigger={<Sprite className={classnames(style.tooltipGlyph, tooltipIconClassName)} glyph={infoGlyph} />}
    >
      <NotificationText glyphClassName={style.notificationTextGlyph}>
        <MarketGuideValuesTooltipText />
      </NotificationText>
    </Tooltip>
  </div>
);

export default memo(MarketGuideValuesTooltip);
