import AuctionItem from 'constants/auctionItem';
import StatusColumn from 'components/sections/myBlock/list/status/statusColumn';
import StatusRow from 'components/sections/myBlock/list/status/statusRow';
import SubmitToAuction from 'components/sections/myBlock/list/status/operations/submitToAuction';
import { formatDate } from 'utils/dateUtils';
import { t } from 'utils/intlUtils';

import style from './statusInfo.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
}

const StatusInfoNoSale = ({ auctionItem }: Props) => {
  return (
    <div className={style.container} data-testid="status-info-no-sale">
      <StatusColumn>
        <StatusRow
          dataTestId="highest-bid-row"
          subTitle={auctionItem?.topOffer?.amount?.formattedAmount}
          title={t('highest_bid')}
        />
        <StatusRow
          dataTestId="date-ran-row"
          subTitle={formatDate(auctionItem?.ranOn)}
          subTitleClassName={style.date}
          title={t('date_ran')}
        />
      </StatusColumn>
      <div className={style.operations}>
        <SubmitToAuction auctionItem={auctionItem} />
      </div>
    </div>
  );
};

export default StatusInfoNoSale;
