import AuctionItemType from 'constants/auctionItem';
import ServerRecord from 'store/shared/models/serverRecord';
import { AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';

export interface MarqueeProps {
  /** The auction item currently being auctioned */
  auctionItem: AuctionItemType | null;
  /** Current auction time slot lane information. */
  auctionTimeSlotLane: AuctionTimeSlotLane | null;
  /** List of upcoming auction items. */
  upcomingItems: AuctionItemType[];
}

export const InitialState = ServerRecord<MarqueeProps>({
  auctionItem: null,
  auctionTimeSlotLane: null,
  upcomingItems: [],
});
