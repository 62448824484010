import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

/**
 * Get the translated label value of an AuctionItemStatus
 *
 * @example getTranslatedAuctionItemStatus(IN_IF_BID) // "In If Bid" (see `~locales/en.json)
 * @param status
 */
export const getTranslatedAuctionItemStatus = (status: AuctionItemStatus) =>
  t(`auction_item_status_${status?.toLowerCase()}`);
