import { createAction } from 'redux-actions';

import { AuctionItem, AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';
import { getAuctionItem, getLiveLane } from 'store/admin/liveLanes/auctioneer/auctioneerApi';
import { parseQueryConnectionResponse, parseQueryParams } from 'utils/apiUtils';

export const auctionItemLoaded = createAction('ADMIN_AUCTIONEER_AUCTION_ITEM_LOADED');
export const auctionItemUpdated = createAction('ADMIN_AUCTIONEER_AUCTION_ITEM_UPDATED');
export const clearAuctioneer = createAction('ADMIN_AUCTIONEER_CLEAR_AUCTIONEER');
export const isLoading = createAction('ADMIN_AUCTIONEER_SET_IS_LOADING');
export const isNotUpdating = createAction('ADMIN_AUCTIONEER_SET_IS_NOT_UPDATING');
export const isUpdating = createAction('ADMIN_AUCTIONEER_SET_IS_UPDATING');
export const liveLaneLoaded = createAction('ADMIN_AUCTIONEER_LIVE_LANE_LOADED');

export const processGetAuctionItem = async (options: { auctionItemId: string }, dispatch) => {
  const auctionItemDetails = (await getAuctionItem(options))?.data?.data?.auctionItem;
  dispatch(auctionItemLoaded(auctionItemDetails));
};

export type LiveLaneResponse = Promise<{
  auctionTimeSlotLane: AuctionTimeSlotLane | undefined;
  upcomingItems: AuctionItem[] | undefined;
}>;

export const processGetLiveLane = (options, dispatch): LiveLaneResponse => {
  dispatch(isLoading());
  return getLiveLane(parseQueryParams(options))?.then((response) => {
    const formattedResponse = {
      auctionTimeSlotLane: response?.data?.data?.auctionTimeSlotLane,
      upcomingItems: parseQueryConnectionResponse(response?.data?.data?.auctionItemConnection),
    };

    dispatch(liveLaneLoaded(formattedResponse));
    return formattedResponse;
  });
};
