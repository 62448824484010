import ApiRequest from 'store/shared/api/apiRequest';
import { InventoryItemMetaData } from 'store/shared/api/graph/interfaces/types';

export const getTransportDetailsMetadata = () =>
  ApiRequest<{ inventoryItemMetaData: InventoryItemMetaData }>({
    data: {
      query: `
        {
          inventoryItemMetaData {
            vehicleMetaData {
              transportDetails {
                id
                value
              }
            }
          }
        }
      `,
    },
  });
