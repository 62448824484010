import { ComponentProps } from 'react';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import FeesSlideOut from 'components/sections/admin/auctions/details/fees/feesSlideOut';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { DetailsSection } from 'layouts/list/listLayout';
import { FeeType } from 'forms/admin/auctions/addFeeForm';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './fees.scss';

interface BaseProps {
  /** The current user. */
  user: User;
}

interface AuctionItemProps extends BaseProps {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** Type of fees */
  feeType: FeeType.AUCTION_ITEM;
}

interface InventoryItemProps extends BaseProps {
  /** Type of fees */
  feeType: FeeType.INVENTORY;
  /** The inventory item details. */
  inventoryItem: InventoryItem;
}

type Props = AuctionItemProps | InventoryItemProps;

interface State {
  /** True when the dialog is open. */
  isFormDialogOpen: boolean;
  /** True when click outside dialog is disabled. */
  isSlideOutAutoCloseDisabled: boolean;
  /** The selected tab index when dialog is opened. */
  selectedTabIndex: number;
}

class Fees extends BaseClass<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isFormDialogOpen: false,
      isSlideOutAutoCloseDisabled: false,
      selectedTabIndex: 0,
    };
  }

  isVisible() {
    const { user, ...props } = this.props;

    switch (props.feeType) {
      case FeeType.AUCTION_ITEM:
        return (
          ![props.auctionItem?.status]?.includes(AuctionItemStatus.SOLD) &&
          (isAuctionStaff(user) || getEnabledCompanyIds(user)?.includes(props.auctionItem?.inventoryItem?.company.id))
        );

      case FeeType.INVENTORY:
        return isAuctionStaff(user) || getEnabledCompanyIds(user)?.includes(props.inventoryItem.company?.id);

      default:
        return false;
    }
  }

  getFeesSlideOutProps(): ComponentProps<typeof FeesSlideOut> {
    const { isFormDialogOpen, isSlideOutAutoCloseDisabled, selectedTabIndex } = this.state;

    const baseProps = {
      isClickOutsideDisabled: isSlideOutAutoCloseDisabled,
      isOpen: isFormDialogOpen,
      onClose: () => this.setState({ isFormDialogOpen: false }),
      onCloseDialog: () => this.setState({ isSlideOutAutoCloseDisabled: false }),
      onOpenDialog: () => this.setState({ isSlideOutAutoCloseDisabled: true }),
      selectedTabIndex,
      shouldRefreshAuctionItemOnUpdate: true,
      title: t('fees'),
    };

    switch (this.props.feeType) {
      case FeeType.AUCTION_ITEM:
        return {
          ...baseProps,
          auctionId: this.props.auctionItem.auction.id,
          auctionItemId: this.props.auctionItem.id,
          companyId: this.props.auctionItem.inventoryItem.company.id,
          currencyCode: this.props.auctionItem.auction.currencyCode || undefined,
          feeType: FeeType.AUCTION_ITEM,
          format: this.props.auctionItem.format,
        };

      case FeeType.INVENTORY:
      default:
        return {
          ...baseProps,
          inventoryItemId: this.props.inventoryItem.id,
          feeType: FeeType.INVENTORY,
        };
    }
  }

  render() {
    if (!this.isVisible()) {
      return null;
    }

    return (
      <>
        <DetailsSection subButtonDataTestId="addModifyFeesButton" subButtonType="add" title={t('fees')}>
          <div className={style.feeButtons}>
            <Button
              className={style.feeButton}
              onClick={() => this.setState({ isFormDialogOpen: true, selectedTabIndex: 0 })}
              theme="none"
            >
              {t('buyer_fees')}
              <Chevron />
            </Button>
            <Button
              className={style.feeButton}
              onClick={() => this.setState({ isFormDialogOpen: true, selectedTabIndex: 1 })}
              theme="none"
            >
              {t('seller_fees')}
              <Chevron />
            </Button>
          </div>
        </DetailsSection>

        <FeesSlideOut {...this.getFeesSlideOutProps()} />
      </>
    );
  }
}

export default Fees;
