import classnames from 'classnames';
import { ReactNode } from 'react';

import style from './authBanner.scss';

interface Props {
  /** The content to render */
  children: ReactNode;
  /** Css to overwrite default styles */
  className?: string;
}

const AuthBanner = ({ children, className }: Props) => {
  return (
    <div className={classnames(style.authBanner, className)} data-testid="auth-messages">
      {children}
    </div>
  );
};

export default AuthBanner;
