import { createAction } from 'redux-actions';

import { getErrors, parseQueryParams } from 'utils/apiUtils';
import { getTransportJobsList, getFacets } from './transportJobsApi';

export const transportJobsListIsLoading = createAction('ADMIN_TRANSPORT_JOBS_LIST_IS_LOADING');
export const transportJobsListLoaded = createAction('ADMIN_TRANSPORT_JOBS_LIST_LOADED');
export const transportJobsListCleared = createAction('ADMIN_TRANSPORT_JOBS_LIST_CLEARED');
export const transportJobsListError = createAction('ADMIN_TRANSPORT_JOBS_LIST_ERROR');
export const transportJobsListUpdateItem = createAction('ADMIN_TRANSPORT_JOBS_LIST_UPDATE_ITEM');
export const facetGroupsLoaded = createAction('ADMIN_TRANSPORT_JOBS_LIST_FACET_GROUPS_LOADED');
export const pageInfoLoaded = createAction('ADMIN_TRANSPORT_JOBS_LIST_PAGE_INFO_LOADED');

export const processGetTransportJobsList = async (options, dispatch) => {
  try {
    dispatch(transportJobsListIsLoading(true));
    const transportJobs = (await getTransportJobsList(parseQueryParams(options)))?.data?.data?.transportConnection;
    dispatch(transportJobsListLoaded(transportJobs));
  } catch (err) {
    dispatch(transportJobsListIsLoading(false));
    if (err.response) {
      dispatch(transportJobsListError(getErrors(err)));
    } else {
      dispatch(transportJobsListError(err.message));
    }
  }
};

export const processUpdateFacets = async (options, dispatch) => {
  return getFacets(parseQueryParams(options)).then((response) =>
    dispatch(facetGroupsLoaded(response?.data?.data?.transportConnection?.facetGroups))
  );
};
