import { useCallback, useState, useMemo, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'forms/shared/checkbox';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import SelectUser, { SelectUserProps } from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { MonetaryAmount, Company } from 'store/shared/api/graph/interfaces/types';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { UserProps } from 'store/user/userModels';
import { isAuctionStaff } from 'utils/userUtils';
import { onApiError } from 'utils/apiUtils';
import { processCounterOffer } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Company that is counter offering */
  counterOfferCompany: Pick<Company, 'id' | 'name'>;
  /** True if it's a seller */
  isSeller: boolean;
  /** Offered amount */
  offeredAmount: Pick<MonetaryAmount, 'amount' | 'formattedAmountRounded'>;
  /** Offer Id */
  offerId: string;
  /** Function used to close dialog */
  onClose: () => void;
}

const Dialog = ({ counterOfferCompany, isSeller, offeredAmount, offerId, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const auctionId = useSelector<AppState, string>((state) => state.app.auctionItemDetails.details.auction.id);
  const auctionItemId = useSelector<AppState, string>((state) => state.app.auctionItemDetails.details.id);
  /**
   * Use topOffer as the minimum offer for buyer's counter, this would account for other buyer's
   * offer.
   */
  const topOffer = useSelector<AppState, Pick<MonetaryAmount, 'amount' | 'formattedAmountRounded'> | null>(
    (state) => state.app.auctionItemDetails.details.topOffer?.amount || null
  );

  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionId), [auctionId, user]);

  const [counterOfferAmount, setCounterOfferAmount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [shouldApplyBuyNow, setShouldApplyBuyNow] = useState<boolean>(false);
  const [counterOfferUser, setCounterOfferingUser] = useState<SelectOption | undefined>();
  const [message, setMessage] = useState<string>('');

  const counterOfferInputPlaceholder = useMemo(
    () =>
      isSeller
        ? t('more_than_x', [offeredAmount.formattedAmountRounded])
        : t('x_or_more', [topOffer?.formattedAmountRounded]),
    [isSeller, offeredAmount.formattedAmountRounded, topOffer?.formattedAmountRounded]
  );

  const isActionable = useMemo(() => {
    if (isStaffUser && !counterOfferUser) {
      return false;
    }

    return true;
  }, [counterOfferUser, isStaffUser]);

  /**
   * Handles accept offer button
   */
  const handleOnConfirm = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        setIsSubmitting(true);

        processCounterOffer(
          {
            input: {
              amount: counterOfferAmount,
              appliedToBuyNow: shouldApplyBuyNow,
              auctionItemId,
              comment: message,
              companyId: counterOfferCompany.id,
              offerId,
              userId: counterOfferUser?.value,
            },
          },
          dispatch
        )
          .then(() => {
            setErrorMessages([]);
            onClose();
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages([]);
        onClose();
      }
    },
    [
      auctionItemId,
      counterOfferAmount,
      counterOfferCompany.id,
      counterOfferUser?.value,
      dispatch,
      message,
      offerId,
      onClose,
      shouldApplyBuyNow,
    ]
  );

  /**
   * Handles user change events
   */
  const handleUserOnChange = useCallback<NonNullable<SelectUserProps['onChange']>>(
    (option) => setCounterOfferingUser(option || undefined),
    []
  );

  /**
   * Handles counter offer amount change
   */
  const handleCounterOfferAmountOnChange = useCallback((value: string) => setCounterOfferAmount(Number(value)), []);

  /**
   * Handles text area message change
   */
  const handleMessageOnChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => setMessage(e.currentTarget.value),
    []
  );

  /**
   * Handles apply buy now on change event
   */
  const handleBuyNowOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setShouldApplyBuyNow(e.target.checked),
    []
  );

  return (
    <ConfirmDialog
      actionable={isActionable}
      actionLabel={t('counter').toUpperCase()}
      actionProgress={isSubmitting}
      isOpen
      onClose={onClose}
      onConfirm={handleOnConfirm}
      theme="green"
      title={t('counter_offer')}
    >
      <FormErrors errorMessages={errorMessages} isSmallDialog />
      <div className={style.counterAmount}>{offeredAmount.formattedAmountRounded}</div>
      <p className={style.instructions}>{t('counter_if_bid_message')}</p>
      <InputText
        className={style.amount}
        dataTestId="amount"
        onChange={handleCounterOfferAmountOnChange}
        placeholder={counterOfferInputPlaceholder}
        type="currency"
      />
      {/**
       * Apply to buy now checkbox only applies to seller
       */}
      {isSeller && (
        <Checkbox
          checked={shouldApplyBuyNow}
          className={style.applyBuyNow}
          dataTestId="apply-buy-now"
          id="apply-buy-now"
          onChange={handleBuyNowOnChange}
          text={t('apply_to_buy_now_price')}
        />
      )}
      {isStaffUser && <SelectUser companyId={counterOfferCompany.id} onChange={handleUserOnChange} />}
      <TextArea className={style.message} onChange={handleMessageOnChange} placeholder={t('attach_optional_message')} />
    </ConfirmDialog>
  );
};

export default Dialog;
