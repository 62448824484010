import ApiRequest from 'store/shared/api/apiRequest';
import { Broadcast, BroadcastCreateInput, BroadcastUpdateInput } from 'store/shared/api/graph/interfaces/types';
import { broadcastFragment } from 'store/shared/api/graph/fragments/broadcast';

export const broadcastCreateQuery = `
    mutation ($input: BroadcastCreateInput!) {
      broadcastCreate(broadcastCreateInput: $input) {
        ...broadcast
      }
    }
    ${broadcastFragment}
  `;

export const broadcastUpdateQuery = `
    mutation ($input: BroadcastUpdateInput!) {
      broadcastUpdate(broadcastUpdateInput: $input) {
        ...broadcast
      }
    }
    ${broadcastFragment}
    `;

export const getBroadcastDetails = ({ broadcastId = null }) =>
  ApiRequest({
    data: {
      operationName: 'getBroadcastDetails',
      query: `
        query($broadcastId: ID!) {
          broadcast(broadcastId: $broadcastId) {
            ...broadcast
          }
        }
        ${broadcastFragment}
      `,
      variables: {
        broadcastId,
      },
    },
  });

export const broadcastCreate = (options: BroadcastCreateInput) =>
  ApiRequest<{ broadcastCreate?: Broadcast }>({
    data: {
      operationName: 'broadcastCreate',
      query: broadcastCreateQuery,
      variables: {
        input: options,
      },
    },
  });

export const broadcastUpdate = (options: BroadcastUpdateInput) =>
  ApiRequest<{ broadcastCreate?: Broadcast }>({
    data: {
      operationName: 'broadcastUpdate',
      query: broadcastUpdateQuery,
      variables: {
        input: options,
      },
    },
  });
