import classnames from 'classnames';
import { HTMLAttributes } from 'react';

import style from './divider.scss';

interface Props extends HTMLAttributes<HTMLHRElement> {
  /** The color of the divider */
  color?: string;
}

const Divider = ({ className, color, ...props }: Props) => (
  <hr {...props} className={classnames(style.divider, className)} style={{ backgroundColor: color }} />
);

export default Divider;
