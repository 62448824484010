import { Auction } from 'store/shared/api/graph/interfaces/types';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';

interface AuctionDetailsModel {
  details: Auction | null;
}

export type AuctionDetailsProp = ServerRecordOf<AuctionDetailsModel>;

export const InitialState = ServerRecord<AuctionDetailsModel>({
  details: null,
});
