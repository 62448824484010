import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';
import { isEqual, merge } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getDefaultSelectStyles,
  getErrorSelectStyles,
  greenSelectTheme,
  blueSelectTheme,
  minimalSelectTheme,
  transparentSelectTheme,
  getTransparentSelectStyles,
  darkSelectStyle,
  customOptionFiltering,
} from 'utils/selectUtils';
import { t } from 'utils/intlUtils';

export const themes = {
  blue: blueSelectTheme,
  green: greenSelectTheme,
  minimal: minimalSelectTheme,
  transparent: transparentSelectTheme,
};

export const styles = {
  dark: darkSelectStyle,
};

export interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<Props<Option, IsMulti, Group>, 'theme'> {
  hasError?: boolean;
  styleName?: 'dark';
  theme?: 'green' | 'blue' | 'minimal' | 'transparent';
  themeOverrides?: object;
}

const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  theme = 'blue',
  hasError = false,
  themeOverrides = {},
  styleName,
  options,
  ...props
}: SelectProps<Option, IsMulti, Group>) => {
  const [selectOptions, setSelectOptions] = useState<SelectProps<Option, IsMulti, Group>['options']>(options);

  /**
   * Update options only if they changed
   */
  useEffect(() => {
    if (!isEqual(options, selectOptions)) {
      setSelectOptions(options);
    }
  }, [options, selectOptions]);

  /**
   * Get styles based on theme and error
   */
  const overrideStyles = useMemo<StylesConfig<Option, IsMulti, Group>>(() => {
    if (hasError) {
      return getErrorSelectStyles<Option, IsMulti, Group>();
    }
    if (theme === 'transparent') {
      return getTransparentSelectStyles<Option, IsMulti, Group>();
    }
    return getDefaultSelectStyles<Option, IsMulti, Group>(false, styleName && styles[styleName]);
  }, [hasError, styleName, theme]);

  /**
   * Get theme based on theme overrides
   */
  const themeSettings = useCallback(
    (settings) => merge(themes[theme](settings), themeOverrides),
    [theme, themeOverrides]
  );

  return (
    <ReactSelect
      classNamePrefix="select"
      filterOption={customOptionFiltering}
      options={selectOptions}
      placeholder={t('select')}
      styles={overrideStyles}
      theme={themeSettings}
      {...props}
    />
  );
};

export default Select;
