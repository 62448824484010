import classnames from 'classnames';

import streamGlyph from 'glyphs/liveLanes/stream.svg';

import Sprite from 'components/ui/shared/sprite';

import style from './liveStreamBadge.scss';

interface Props {
  /** CSS style to override default style. */
  className?: string;
}

const LiveStreamBadge = ({ className }: Props) => {
  return (
    <div className={classnames(style.liveStreamBadge, className)} data-testid="live-stream-badge">
      <Sprite className={style.sprite} glyph={streamGlyph} />
    </div>
  );
};

export default LiveStreamBadge;
