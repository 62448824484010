import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import AutoGradeDamagesSlideOut from 'components/sections/inventoryItem/details/conditionReport/autoGradeDamagesSlideOut';
import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InventoryItem from 'constants/inventoryItem';
import autoGradeImage from 'static/images/AutoGrade.png';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { Chevron } from 'components/ui/shared/chevrons';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { UserAction } from 'logging/analytics/events/userActions';
import { isVerifiedAutoGrade } from 'utils/auctionItemUtils';
import { t, tPlural } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './autoGradeScoreButton.scss';

/** Export so that the props can be referenced by storybook */
export interface AutoGradeScoreButtonProps {
  /** The inventory item details. */
  inventoryItem: InventoryItem;
}

const AutoGradeScoreButton = ({
  inventoryItem,
  inventoryItem: { autoGradeScore, autoGradeScoreData, captureType },
}: AutoGradeScoreButtonProps) => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSlideOutOpen, setSlideOutOpen] = useState<boolean>(false);
  const autoGradeUrl = 'https://www.naaa.com/standards/vehicle_gradingscale.pdf';
  const autoGradeVehicleDamageCount = useMemo(
    () => autoGradeScoreData?.autoGradeVehicleDamageCount ?? 0,
    [autoGradeScoreData?.autoGradeVehicleDamageCount]
  );

  /**
   * Open slideOut
   */
  const onOpenSlideOut = useCallback(() => {
    if (!isSlideOutOpen) {
      setSlideOutOpen(true);
    }
  }, [isSlideOutOpen]);

  /**
   * Close slideOut
   */
  const onCloseSlideOut = useCallback(() => {
    setSlideOutOpen(false);
  }, []);

  /**
   * Handle on confirm error dialog
   */
  const onConfirmErrorDialog = useCallback(() => {
    setErrorMessages([]);
    setSlideOutOpen(false);
  }, []);

  const isAutoGradeCapture = useMemo(() => isVerifiedAutoGrade(captureType), [captureType]);

  const isAutoGradeScoreSet = useMemo(() => autoGradeScore !== null && autoGradeScore !== undefined, [autoGradeScore]);

  const onClick = useCallback(() => {
    if (autoGradeVehicleDamageCount && isAutoGradeScoreSet && isAutoGradeCapture) {
      trackUserActionWithAuctionItemAttributes(UserAction.AUTOGRADE_SCORE_CLICK, { inventoryItem });
      onOpenSlideOut();
    } else if (!isAutoGradeCapture) {
      trackUserActionWithAuctionItemAttributes(UserAction.AUTOGRADE_SCORE_CLICK, { inventoryItem });
      window.open(autoGradeUrl, '_blank');
    }
  }, [autoGradeVehicleDamageCount, inventoryItem, isAutoGradeCapture, isAutoGradeScoreSet, onOpenSlideOut]);

  return (
    <>
      <Button
        className={classnames(style.crButton, {
          [style.crButtonStatic]: (!autoGradeVehicleDamageCount && isAutoGradeCapture) || !isAutoGradeScoreSet,
          [style.noFlyout]: !isAutoGradeCapture || !isAutoGradeScoreSet,
        })}
        dataTestId="autograde-damages-button"
        onClick={onClick}
        theme="none"
      >
        <div>
          <span className={classnames(style.crButtonText, style.black)} data-testid="autograde-score-text">
            {isAutoGradeScoreSet && autoGradeScore !== -1 ? autoGradeScore : '-'}
          </span>
          <img alt={t('autograde_scale')} className={style.autoGradeImage} src={autoGradeImage} />
          {isAutoGradeCapture && (isAutoGradeScoreSet || autoGradeScore === -1) && (
            <div className={style.damageStatus} data-testid="autograde-damage-details">
              <Switch>
                <Case if={autoGradeScore === -1}>
                  <div className={style.hasDamage}>{t('autograde_unable_to_retrieve')}</div>
                </Case>
                <Case if={!autoGradeVehicleDamageCount}>
                  <div className={style.noDamage}>{t('no_damage_details')}</div>
                </Case>
                <Case>
                  <div className={style.hasDamage}>
                    {tPlural('x_damage_details', autoGradeVehicleDamageCount, [autoGradeVehicleDamageCount])}
                  </div>
                </Case>
              </Switch>
            </div>
          )}
        </div>
        <Chevron className={style.chevron} />
      </Button>
      <AutoGradeDamagesSlideOut
        damages={autoGradeScoreData?.autoGradeVehicleDamage}
        isOpen={isSlideOutOpen}
        onClose={onCloseSlideOut}
      />
      <ConfirmDialog
        isOpen={!!errorMessages.length}
        onConfirm={onConfirmErrorDialog}
        theme="red"
        title={t('error')}
        titleClassName={style.errorTitle}
      >
        <FormErrors errorMessages={errorMessages} isSmallDialog />
      </ConfirmDialog>
    </>
  );
};

export default AutoGradeScoreButton;
