import classnames from 'classnames';
import { ReactNode } from 'react';
import { SortEnd } from 'react-sortable-hoc';

import SortableList from 'components/sections/inventoryItem/addModify/vehicleFormPanes/sortableList';
import { AccountSection } from 'layouts/accountLayouts/accountLayouts';
import { PhotoType } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './photoDetails.scss';

export interface PhotoSectionItem {
  /** Delete button to be rendered. */
  deleteButton?: ReactNode;
  /** Whether click is disabled or not. */
  disableClick?: boolean;
  /** Whether is disabled or not. */
  disabled?: boolean;
  /** Dropdown to be rendered. */
  dropdown?: ReactNode;
  /** Node to be rendered. */
  node: ReactNode;
}

interface Props {
  /** Callback function invoked after sorting photos */
  handleOnSortEnd: ({ newIndex, oldIndex }: SortEnd, photoSection: PhotoSectionItem[], photoType: PhotoType) => void;
  /** Whether the section is highlighted or not */
  highlighted?: boolean;
  /** The id of the section */
  id: string;
  /** The section photo type */
  photoType: PhotoType;
  /** A collection of photo items */
  photoSection: PhotoSectionItem[];
  /** Callback function to set the section reference */
  sectionRef?: (ref: HTMLDivElement) => void;
  /** The section index of the listed sections */
  sectionIndex: number;
  /** The total number of sections */
  totalSections: number;
}

const PhotoDetailsSection = ({
  handleOnSortEnd,
  highlighted,
  id,
  photoSection,
  photoType,
  sectionIndex,
  sectionRef,
  totalSections,
}: Props) => {
  const sectionTitle = t(`${photoType.toLowerCase()}_photos`);
  return (
    <>
      <div
        ref={sectionRef}
        className={classnames(style.segment, { [style.highlighted]: highlighted })}
        data-testid={`${photoType.toLowerCase()}-section`}
      >
        <AccountSection
          className={style.section}
          contentClass={style.sectionContent}
          title={sectionTitle}
          titleClass={style.title}
        >
          <SortableList
            axis="xy"
            helperClass={style.sortableHelper}
            items={photoSection}
            onSortEnd={(obj) => handleOnSortEnd(obj, photoSection, photoType)}
            useDragHandle
          />
        </AccountSection>
      </div>
      <div id={id} />
    </>
  );
};

export default PhotoDetailsSection;
