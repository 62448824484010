import MyBlockStat from 'components/sections/myBlock/stats/myBlockStat';
import { MyBlockBuyerResponse } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './myBlockBuyerStats.scss';

interface Props {
  /** Buyer stats amounts. */
  stats: MyBlockBuyerResponse;
}

const MyBlockBuyerStats = ({ stats }: Props) => (
  <>
    <div className={style.statGroup}>
      {
        /**
         * TODO: Enable for myBlock phase 2
         */
        process.env.NODE_ENV !== 'production' && (
          <MyBlockStat color="gray-darker" label={t('bid')} value={stats?.hasBid} />
        )
      }
      <MyBlockStat color="green-dark" label={t('purchased')} value={stats?.purchased} />
      <MyBlockStat color="yellow-dark" label={t('auction_item_status_in_if_bid')} value={stats?.inIfBid} />
    </div>
    <div className={style.statGroup}>
      <MyBlockStat
        className={style.statTotal}
        color="gray-lighter"
        label={t('total_purchased_amount')}
        value={stats?.totalSpentAmount?.formattedAmountRounded}
      />
    </div>
  </>
);

export default MyBlockBuyerStats;
