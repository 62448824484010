import ApiRequest from 'store/shared/api/apiRequest';
import {
  CompanyIntegration,
  ExternalId,
  MutationcompanyCreateIntegrationArgs,
  MutationcompanyUpdateIntegrationArgs,
  MutationexternalIdSetArgs,
} from 'store/shared/api/graph/interfaces/types';
import { companyIntegrationFragment } from 'store/shared/api/graph/fragments/companyIntegrations';

export const companyCreateIntegration = ({
  companyId,
  type,
  enabled = undefined,
  values = undefined,
}: MutationcompanyCreateIntegrationArgs) =>
  ApiRequest<{ companyCreateIntegration: CompanyIntegration }>({
    data: {
      operationName: 'companyCreateIntegration',
      query: `
        mutation ($companyId: String!, $type: String!, $enabled: Boolean, $values: [IntegrationInputValue]) {
          companyCreateIntegration(companyId: $companyId, type: $type, enabled: $enabled, values: $values) {
            ...companyIntegration
          }
        }
        ${companyIntegrationFragment}
    `,
      variables: {
        companyId,
        type,
        enabled,
        values,
      },
    },
  });

export const companyUpdateIntegration = ({
  id,
  enabled = undefined,
  values = undefined,
}: MutationcompanyUpdateIntegrationArgs) =>
  ApiRequest<{ companyUpdateIntegration: CompanyIntegration }>({
    data: {
      operationName: 'companyUpdateIntegration',
      query: `
        mutation ($id: String!, $enabled: Boolean, $values: [IntegrationInputValue]) {
          companyUpdateIntegration(id: $id, enabled: $enabled, values: $values) {
            ...companyIntegration
          }
        }
        ${companyIntegrationFragment}
    `,
      variables: {
        id,
        enabled,
        values,
      },
    },
  });

export const externalIdSet = ({ externalIdSetInput }: MutationexternalIdSetArgs) =>
  ApiRequest<{ externalIdSet: ExternalId }>({
    data: {
      query: `
        mutation ($externalIdSetInput: ExternalIdSetInput!) {
          externalIdSet(externalIdSetInput: $externalIdSetInput) {
            created
            entityId
            entityType
            externalId
            integrationName
            lastReceived
            lastSent
          }
        }
    `,
      variables: {
        externalIdSetInput,
      },
    },
  });
