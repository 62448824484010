import { LayerInfo as LayerInfoType, MediaTrackInfo, View, ViewConnectOptions } from '@millicast/sdk';
import { isEqual, last } from 'lodash-es';

import { handleConnectToStream, handleInitViewConnection } from 'io/millicast/utils/viewConnection';

interface LayerInfo extends LayerInfoType {
  /**
   * - Current bitrate in bits per second.
   */
  bitrate: number;
}

export enum MillicastViewerBroadcastEvent {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  LAYERS = 'layers',
  VAD = 'vad',
  VIEWER_COUNT = 'viewercount',
}
export interface ActiveTrackInfo {
  /** Whether the audio track is activated or not. */
  isAudioTrackActivated: boolean;
  /** Whether the video track is activated or not. */
  isVideoTrackActivated: boolean;
}

export interface MillicastViewerManagerProps {
  /** Millicast Account id. */
  accountId: string;
  /** Callback function triggers on active qualities change. */
  onActiveQualitiesChange?: (qualities?: LayerInfo[]) => void;
  /** Callback function triggers on video track active change. */
  onActiveTracksChange?: (activeTrack: ActiveTrackInfo) => void;
  /** Callback function triggers on live state change. */
  onLiveStateChange?: (isLive: boolean) => void;
  /** Callback function triggers on media stream change. */
  onStreamChange?: (stream?: MediaStream) => void;
  /** Callback function triggers on viewer count change. */
  onViewerCountChange?: (count: number) => void;
  /** The name of the stream. */
  streamName: string;
  /** Millicast subscriber token. */
  subscriberToken?: string;
}

export enum QualityLevel {
  HIGHEST = 'highest',
  LOWEST = 'lowest',
  AUTO = 'auto',
}

/**
 * @example
 * const millicastManager = new MillicastViewerManager();
 * millicastManager.init();
 * millicastManager.connectStream();
 * ...
 * millicastManager.stopStream();
 */
class MillicastViewerManager {
  /** Callback function triggers on active qualities change. */
  private readonly onActiveQualitiesChange?: MillicastViewerManagerProps['onActiveQualitiesChange'];
  /** Callback function triggers on active track change. */
  private readonly onActiveTracksChange?: MillicastViewerManagerProps['onActiveTracksChange'];
  /** Callback function triggers on live state change. */
  private readonly onLiveStateChange?: MillicastViewerManagerProps['onLiveStateChange'];
  /** Callback function triggers on media stream change. */
  private readonly onStreamChange?: MillicastViewerManagerProps['onStreamChange'];
  /** Callback function to set viewer count. */
  private readonly onViewerCountChange?: MillicastViewerManagerProps['onViewerCountChange'];
  /** Millicast Account id. */
  private readonly streamAccountId: string;
  /** The name of the stream. */
  private readonly streamName: string;
  /** Millicast subscriber token. */
  private readonly subscriberToken?: string;
  /** Available video qualities in descending ordered. */
  private activeQualities: LayerInfo[];
  /** Whether the stream is live or not. */
  private isLive: boolean;
  /** Selected quality. */
  private selectedQuality: LayerInfo | undefined;
  /** Current media stream. */
  private stream: MediaStream;
  /** Millicast viewer. */
  private viewer: View | null;

  constructor(options: MillicastViewerManagerProps) {
    this.onActiveQualitiesChange = options.onActiveQualitiesChange;
    this.onActiveTracksChange = options.onActiveTracksChange;
    this.onLiveStateChange = options.onLiveStateChange;
    this.onStreamChange = options.onStreamChange;
    this.onViewerCountChange = options.onViewerCountChange;
    this.streamAccountId = options.accountId;
    this.streamName = options.streamName;
    this.subscriberToken = options.subscriberToken;
  }

  /**
   * Initialize the millicast view and set listened to events
   */
  init(): void {
    this.viewer = handleInitViewConnection(this.streamAccountId, this.streamName, this.subscriberToken);
    this.setViewerEvents();
  }

  /**
   * Connect to the stream
   */
  async connectStream(options?: ViewConnectOptions): Promise<void> {
    await handleConnectToStream(this.viewer, options);
  }

  /**
   * Stop stream connection and clean up the viewer
   */
  async stopStream(): Promise<void> {
    await this.viewer?.stop?.();
    this.viewer = null;
    this.onStreamChange?.(undefined);
  }

  /**
   * Set viewer events
   * @private
   */
  private setViewerEvents(): void {
    this.setTrackEvent();
    this.setBroadcastEvent();
  }

  /**
   * Set on track event handler
   * @private
   */
  private setTrackEvent(): void {
    this.viewer?.on('track', async (event) => {
      const stream = event.streams[0];
      this.stream = stream;
      this.onStreamChange?.(stream);
    });
  }

  /**
   * Set on broad cast event handler
   * @private
   */
  private setBroadcastEvent(): void {
    this.viewer?.on('broadcastEvent', (event) => {
      const { name, data } = event;
      switch (name) {
        case MillicastViewerBroadcastEvent.ACTIVE:
          this.isLive = true;
          this.onLiveStateChange?.(true);
          this.setActiveMediaTrack(data.tracks);
          break;
        case MillicastViewerBroadcastEvent.INACTIVE:
          this.isLive = false;
          this.onLiveStateChange?.(false);
          break;
        case MillicastViewerBroadcastEvent.LAYERS:
          this.updateLayersState(data);
          this.onActiveQualitiesChange?.(this.activeQualities);
          break;
        case MillicastViewerBroadcastEvent.VIEWER_COUNT:
          this.onViewerCountChange?.(data.viewercount);
          break;
      }
    });
  }

  private setActiveMediaTrack(tracks: MediaTrackInfo[]) {
    let isAudioTrackActivated = false;
    let isVideoTrackActivated = false;
    tracks.forEach(({ media }) => {
      if (media === 'audio') {
        isAudioTrackActivated = true;
      } else if (media === 'video') {
        isVideoTrackActivated = true;
      }
    });
    this.onActiveTracksChange?.({ isAudioTrackActivated, isVideoTrackActivated });
  }

  /**
   * On layers state change, update active qualities, only works with simulcast
   * @private
   */
  private updateLayersState(data) {
    const activeQualities: LayerInfo[] = [];
    const encodings: Array<{ layers: LayerInfo[] }> = Object.values(data.medias);
    encodings.forEach((encoding) => {
      encoding.layers?.forEach((layer) => {
        activeQualities.push(layer);
      });
    });
    // Qualities sorted in descending order
    activeQualities.sort((a, b) => {
      return b.bitrate - a.bitrate;
    });
    this.activeQualities = activeQualities;
  }

  /**
   * Set stream quality, only works with simulcast publisher
   */
  setQuality(quality?: LayerInfo) {
    if (!isEqual(quality, this.selectedQuality)) {
      this.viewer
        ?.select(quality)
        ?.then(() => {
          this.selectedQuality = quality;
        })
        ?.catch(() => {
          // Quality selection failed, do nothing.
        });
    }
  }

  /**
   * Set stream quality level
   */
  setQualityLevel(qualityLevel: QualityLevel | undefined) {
    if (this.activeQualities?.length) {
      switch (qualityLevel) {
        case QualityLevel.HIGHEST:
          this.setQuality(this.activeQualities[0]);
          break;
        case QualityLevel.LOWEST:
          this.setQuality(last(this.activeQualities));
          break;
        default:
          this.setQuality();
      }
    }
  }
}

export default MillicastViewerManager;
