import { Spinner } from 'components/ui/loading/loading';
import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import CheckoutItemOrderOverview from 'components/sections/checkout/forms/checkoutItemOrderOverview';
import {
  InvoiceContainer,
  InvoiceRow,
  InvoiceSection,
  InvoiceTotal,
} from 'components/sections/checkout/forms/checkoutInvoice';
import { FormDialogBody, FormDialogFooter, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { AuctionItemOrdersResponse } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './checkoutForms.scss';

class CheckoutAllForm extends BaseClass<{
  auctionItemOrders?: AuctionItemOrdersResponse;
  isSubmitting: boolean;
  onEdit: (auctionItemId: string) => void;
  onSubmit: () => void;
}> {
  render() {
    const { auctionItemOrders, isSubmitting, onEdit, onSubmit } = this.props;

    return (
      <>
        <FormDialogBody>
          <div className={style.splitSection}>
            <div className={style.splitSectionLeft}>
              <FormSection className={style.formSectionNoPadding} dataTestId="all-orders-section">
                {auctionItemOrders?.auctionItems
                  ?.filter(Boolean)
                  .map((auctionItem) => (
                    <CheckoutItemOrderOverview
                      key={auctionItem?.id}
                      auctionItem={auctionItem}
                      onEdit={() => onEdit(auctionItem?.id)}
                    />
                  ))}
              </FormSection>
            </div>
            <div className={style.splitSectionRight} data-testid="fees-summary-section">
              <FormSection>
                <InvoiceContainer>
                  {/*
                  <InvoiceSection title="Fee Summary">
                    <InvoiceRow name="Total Fees" value={auctionItemOrders?.totalFees?.formattedAmount} />
                    <InvoiceRow name="Total Fee Tax" value={auctionItemOrders?.totalFeeTax?.formattedAmount} />
                  </InvoiceSection>
                  */}
                  <InvoiceSection title={t('summary')}>
                    <InvoiceRow name={t('subtotal')} value={auctionItemOrders?.subtotal?.formattedAmount} />
                    <InvoiceRow name={t('credit')} value={auctionItemOrders?.credit?.formattedAmount} />
                    <InvoiceRow name={t('taxes')} value={auctionItemOrders?.taxes?.formattedAmount} />
                  </InvoiceSection>
                  <InvoiceTotal value={auctionItemOrders?.total?.formattedAmount} />
                </InvoiceContainer>
              </FormSection>
            </div>
          </div>
        </FormDialogBody>
        <FormDialogFooter>
          <Button dataTestId="submit-button" disabled={isSubmitting} onClick={() => onSubmit()} theme="blue">
            {isSubmitting ? <Spinner /> : t('checkout_all')}
          </Button>
        </FormDialogFooter>
      </>
    );
  }
}

export default CheckoutAllForm;
