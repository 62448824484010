// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_aee18d5","type-body-2":"type-body-2_b7ca94d","type-body-3":"type-body-3_c363d61","type-body-4":"type-body-4_bae65ed","type-section-title":"type-section-title_c0bd1bc","type-notes":"type-notes_b53ca01","type-item-title":"type-item-title_fc99129","type-item-subtitle":"type-item-subtitle_e7cb1a4","type-subtitle":"type-subtitle_a0fc78a","type-title":"type-title_b756de6","headerBar":"headerBar_a13ec7d","subtitle":"subtitle_f507e9b","title":"title_b9e648b","labelComp":"labelComp_a9043e4","header":"header_f317196","top":"top_baf13a3","runNumberBadge":"runNumberBadge_b07130e","saleLights":"saleLights_ac68f80","badgesContainer":"badgesContainer_c65de5f","mileage":"mileage_c0e988e","badges":"badges_ecbbad6"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_aee18d5\",\"type-body-2\":\"type-body-2_b7ca94d\",\"type-body-3\":\"type-body-3_c363d61\",\"type-body-4\":\"type-body-4_bae65ed\",\"type-section-title\":\"type-section-title_c0bd1bc\",\"type-notes\":\"type-notes_b53ca01\",\"type-item-title\":\"type-item-title_fc99129\",\"type-item-subtitle\":\"type-item-subtitle_e7cb1a4\",\"type-subtitle\":\"type-subtitle_a0fc78a\",\"type-title\":\"type-title_b756de6\",\"headerBar\":\"headerBar_a13ec7d\",\"subtitle\":\"subtitle_f507e9b\",\"title\":\"title_b9e648b\",\"labelComp\":\"labelComp_a9043e4\",\"header\":\"header_f317196\",\"top\":\"top_baf13a3\",\"runNumberBadge\":\"runNumberBadge_b07130e\",\"saleLights\":\"saleLights_ac68f80\",\"badgesContainer\":\"badgesContainer_c65de5f\",\"mileage\":\"mileage_c0e988e\",\"badges\":\"badges_ecbbad6\"}";
        // 1734114905916
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  