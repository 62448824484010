import moment from 'moment/moment';
import { memo, useCallback, useMemo } from 'react';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import { AppDispatch } from 'store/configureStore';
import { Route } from 'store/routing/routes';
import {
  USER_SESSION_EXPIRY_STORAGE_NAME,
  UserSessionExpiryInfo,
  setUserSessionExpiryTime,
} from 'containers/notifications/userSessionExpiry/userSessionExpiryHelpers';
import { logoutAndDeleteToken } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'constants/reactRouter';

import style from './userSessionExpiry.scss';

const UserSessionExpiry = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [state] = useLocalStorage<UserSessionExpiryInfo>(USER_SESSION_EXPIRY_STORAGE_NAME);
  const { pathname } = useLocation();

  const isExpiryDialogVisible = useMemo(() => {
    if (!state || state?.isDismissed || pathname.includes('/auth') || pathname.includes('/register')) {
      return false;
    }

    // Check if expiry time is within the next 3 hours
    const expiryTime = moment.unix(Number(state?.expiryTime));
    const expiryTimeInHours = expiryTime.diff(moment(), 'hours');
    return expiryTimeInHours <= 3;
  }, [pathname, state]);

  /**
   * onSubmit
   */
  const onSubmit = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        // Logout
        logoutAndDeleteToken(dispatch, () => dispatch(push(Route.AUTH_LOGIN)));
        return;
      }

      // Mark user session expiry storage entry as dismissed so the user
      // isn't bother by this notification until the next expiry event.
      setUserSessionExpiryTime(state?.expiryTime || '', true);
    },
    [dispatch, state]
  );

  if (!isExpiryDialogVisible) {
    return null;
  }

  return (
    <ConfirmDialog
      actionLabel={t('reauthenticate')}
      dismissLabel={t('not_now')}
      isOpen
      onConfirm={onSubmit}
      theme="blue"
      title={t('session_expiring')}
    >
      <p className={style.strong}>{t('session_expiring_confirm_title')}</p>
      <p>{t('session_expiring_confirm_message')}</p>
    </ConfirmDialog>
  );
};

export default memo(UserSessionExpiry);
