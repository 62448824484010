import Record from 'store/shared/models/record';
import { FacetedPageInfo, FacetGroup as FacetGroupType } from 'store/shared/api/graph/interfaces/types';

export const FacetGroup = Record<FacetGroupType>({
  name: null,
  allowMultiple: false,
  facets: [],
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: null,
  totalEdges: 0,
});
