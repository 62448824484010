import ApiRequest from '../../apiRequest';
import {
  Location,
  MutationlocationCreateArgs,
  MutationlocationUpdateArgs,
} from 'store/shared/api/graph/interfaces/types';
import { locationFragment } from '../fragments/common';

export const locationUpdate = ({
  id,
  name = null,
  address1 = null,
  address2 = null,
  phone = null,
  fax = null,
  latitude = null,
  longitude = null,
  city = null,
  zipCode = null,
  regionCode = null,
  region = null,
  country = null,
  countryCode = null,
  email = null,
  pickupInstructions = null,
  dropOffInstructions = null,
  isPublic = null,
}: MutationlocationUpdateArgs & { isPublic?: MutationlocationCreateArgs['public'] }) =>
  ApiRequest<{ locationUpdate: Location }>({
    data: {
      operationName: 'locationUpdate',
      query: `
        mutation(
          $id: String!,
          $name: String,
          $address1: String,
          $address2: String,
          $phone: String,
          $fax: String,
          $latitude: Float,
          $longitude: Float,
          $city: String,
          $zipCode: String,
          $regionCode: String,
          $region: String,
          $country: String,
          $countryCode: String,
          $email: String,
          $pickupInstructions: String,
          $dropOffInstructions: String,
          $public: Boolean
        ) {
          locationUpdate(
            id: $id,
            name: $name,
            address1: $address1,
            address2: $address2,
            phone: $phone,
            fax: $fax,
            latitude: $latitude,
            longitude: $longitude,
            city: $city,
            zipCode: $zipCode,
            regionCode: $regionCode,
            region: $region,
            country: $country,
            countryCode: $countryCode,
            email: $email,
            pickupInstructions: $pickupInstructions,
            dropOffInstructions: $dropOffInstructions,
            public: $public
          ) {
            ...location
          }
      }
      ${locationFragment}
      `,
      variables: {
        id,
        name,
        address1,
        address2,
        phone,
        fax,
        latitude,
        longitude,
        city,
        zipCode,
        regionCode,
        region,
        country,
        countryCode,
        email,
        pickupInstructions,
        dropOffInstructions,
        public: isPublic,
      },
    },
  });

export const locationCreate = ({
  id = null,
  name = null,
  address1 = null,
  address2 = null,
  phone = null,
  fax = null,
  latitude = null,
  longitude = null,
  city = null,
  zipCode = null,
  regionCode = null,
  region = null,
  country = null,
  countryCode = null,
  email = null,
  locationIdType,
  pickupInstructions = null,
  dropOffInstructions = null,
  isPublic = null,
}: MutationlocationCreateArgs & {
  id?: string | null;
  isPublic?: MutationlocationCreateArgs['public'];
  locationIdType: string;
}) =>
  ApiRequest<{ locationCreate: Location }>({
    data: {
      operationName: 'locationCreate',
      query: `
        mutation(
          $id: String,
          $name: String,
          $address1: String,
          $address2: String,
          $phone: String,
          $fax: String,
          $latitude: Float,
          $longitude: Float,
          $city: String,
          $zipCode: String,
          $regionCode: String,
          $region: String,
          $country: String,
          $countryCode: String,
          $email: String,
          $pickupInstructions: String,
          $dropOffInstructions: String,
          $public: Boolean
        ) {
          locationCreate(
            ${locationIdType}: $id,
            name: $name,
            address1: $address1,
            address2: $address2,
            phone: $phone,
            fax: $fax,
            latitude: $latitude,
            longitude: $longitude,
            city: $city,
            zipCode: $zipCode,
            regionCode: $regionCode,
            region: $region,
            country: $country,
            countryCode: $countryCode,
            email: $email,
            pickupInstructions: $pickupInstructions,
            dropOffInstructions: $dropOffInstructions,
            public: $public
          ) {
            ...location
          }
      }
      ${locationFragment}
      `,
      variables: {
        id,
        name,
        address1,
        address2,
        phone,
        fax,
        latitude,
        longitude,
        city,
        zipCode,
        regionCode,
        region,
        country,
        countryCode,
        email,
        pickupInstructions,
        dropOffInstructions,
        public: isPublic,
      },
    },
  });
