import ApiRequest from 'store/shared/api/apiRequest';
import { InventoryItemValue, QueryinventoryItemValuesArgs } from 'store/shared/api/graph/interfaces/types';

export const getInventoryItemValues = ({ auctionId, inventoryItemId }: QueryinventoryItemValuesArgs) =>
  ApiRequest<{ inventoryItemValues: InventoryItemValue[] }>({
    data: {
      operationName: 'inventoryItemValues',
      query: `
        query ($auctionId: String!, $inventoryItemId: String!) {
          inventoryItemValues(auctionId: $auctionId, inventoryItemId: $inventoryItemId) {
            id
            notes
            type {
              id
              name
            }
            value {
              amount
              currencyCode
              formattedAmount
              formattedAmountRounded
            }
          }
        }
        
    `,
      variables: {
        auctionId,
        inventoryItemId,
      },
    },
  });
