import { useMemo } from 'react';

import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import User from 'constants/user';
import UserImage from 'components/ui/shared/images/userImage';
import { ListItem, ListItemBody, SecondaryTitle, TertiaryTitle } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { formatUserName } from 'utils/stringUtils';
import { hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './watchedBySlideOut.scss';

interface Props {
  /** True when the slide out is loading. */
  isLoading?: boolean;
  /** True when the slide out is open. */
  isOpen?: boolean;
  /** Function invoked on slide out close. */
  onClose?: () => void;
  /** The current user details. */
  user: User;
  /** The users watching the auction item. */
  watchers: User[];
}

const WatchedBySlideOut = ({ isLoading, isOpen, onClose, user, watchers }: Props) => {
  /**
   * Check if the current user has permission to view users.
   */
  const hasPermission = useMemo(() => hasSystemPermission(user, ['SYSTEM_VIEW_USERS']), [user]);

  /**
   * Render list of watchers.
   */
  const renderList = useMemo(() => {
    if (!watchers?.length) {
      return <NoResults title={t('no_items_to_show')} />;
    }

    return watchers?.map((watcher) => (
      <ListItem
        key={`watched-by-${watcher?.id}`}
        className={style.listItem}
        isLink={hasPermission}
        to={`${Route.ADMIN_USERS}?id=${watcher?.id}`}
      >
        <ListItemBody className={style.body}>
          <UserImage containerClassName={style.img} imageUrl={watcher?.photoUrl || undefined} />
          <div className={style.details}>
            <SecondaryTitle>{formatUserName(watcher)}</SecondaryTitle>
            {watcher?.companyRelationships?.list[0]?.company?.name && (
              <TertiaryTitle>{watcher?.companyRelationships?.list[0]?.company?.name}</TertiaryTitle>
            )}
          </div>
        </ListItemBody>
      </ListItem>
    ));
  }, [hasPermission, watchers]);

  return (
    <SlideOut contentInnerClassName={style.content} isOpen={isOpen} onClose={onClose} title={t('watched_by')}>
      <div>{renderList}</div>
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default WatchedBySlideOut;
