import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import SimulcastChatManager from 'io/twilio/simulcastChatManager';
import { ConnectionState } from 'io/twilio/simulcastChatManagerTypes';
import { processClearConversations, processSetUserOnlineStatus } from 'store/chat/chatActions';

let chatManager;

interface Props {
  /** The id of the current auctionTimeSlotLane */
  auctionTimeSlotLaneId?: string;
  /** Is this user viewing conversations in the auctioneer context */
  isAuctioneer?: boolean;
  /** Update connection state based on the chat manager connection */
  setConnectionState: (state: ConnectionState) => void;
}

/**
 * This effect will initialize the connection to the Twillio chat manager and callback when
 * the chat manager is available to connect.
 */
export const useChatManager = ({ auctionTimeSlotLaneId, isAuctioneer, setConnectionState }: Props) => {
  const dispatch = useDispatch();

  /**
   * Initialize chat manager on mount
   */
  useEffect(() => {
    // Initialize chat manager
    processClearConversations({}, dispatch);
    chatManager = new SimulcastChatManager();
    chatManager.init(
      (state: ConnectionState) => {
        setConnectionState(state);
      },
      isAuctioneer,
      auctionTimeSlotLaneId,
      (userId, onlineStatus) => processSetUserOnlineStatus({ userId, onlineStatus }, dispatch)
    );

    return () => {
      chatManager.terminate();
      chatManager = null;
    };
  }, [auctionTimeSlotLaneId, dispatch, isAuctioneer, setConnectionState]);

  return chatManager;
};
