import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash-es';

import InputText from 'forms/shared/inputText';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getFilteredUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';

import style from './reserveRange.scss';

interface LocationQuery {
  reservePriceGTE: string;
  reservePriceLTE: string;
}

interface Props extends RouterProps<LocationQuery> {
  /** CSS styling to overwrite default title style. */
  titleClassName?: string;
}

const ReserveRange = ({ location, router, titleClassName }: Props) => {
  // A memoized debounced onChange handler for the price inputs below
  const onChange = useMemo(
    () =>
      debounce((paramName, value) => {
        const filteredParams = getFilteredUrlParams([paramName]);
        const paramsNext = paramsToQueryString({
          ...filteredParams,
          ...(value !== '' && Number(value) > -1 && { [paramName]: value }),
        });

        // Update URL params
        router?.push(`${location?.pathname}?${paramsNext}`);
      }, 250),
    [location?.pathname, router]
  );

  return (
    <>
      <div className={style.container} data-testid="reserve-range">
        <div className={classnames(style.title, titleClassName)}>{t('reserve_price')}</div>
        <div className={style.inputs}>
          <InputText
            className={style.input}
            defaultValue={location?.query?.reservePriceGTE || undefined}
            onChange={useCallback((value) => onChange('reservePriceGTE', value), [onChange])}
            placeholder={t('min_price')}
            textClassName={style.inputText}
            type="number"
          />
          <span>{t('to')}</span>
          <InputText
            className={style.input}
            defaultValue={location?.query?.reservePriceLTE || undefined}
            onChange={useCallback((value) => onChange('reservePriceLTE', value), [onChange])}
            placeholder={t('max_price')}
            textClassName={style.inputText}
            type="number"
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(ReserveRange);
