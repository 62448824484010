import classnames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

import Button from 'components/ui/shared/button';
import { Chevron } from 'components/ui/shared/chevrons';

import style from './statusRows.scss';

interface NameProps {
  /** The rendered child elements.*/
  children: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;
}

export const Name = ({ children, className }: NameProps) => {
  return <dt className={classnames(style.name, className)}>{children}</dt>;
};

interface RowContainerProps {
  /** The rendered child elements.*/
  children: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;
}

export const RowsContainer = ({ children, className }: RowContainerProps) => (
  <div className={classnames(style.rowsContainer, className)}>{children}</div>
);

interface RowsProps extends HTMLAttributes<HTMLDListElement> {
  /** The rendered child elements.*/
  children: ReactNode;

  /** CSS styling to overwrite default style. */
  className?: string;
}

export const Rows = ({ children, className, ...props }: RowsProps) => {
  if (!children) {
    return null;
  }

  return (
    <dl className={className} {...props}>
      {children}
    </dl>
  );
};

interface RowProps {
  /** The rendered child elements.*/
  children?: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Id used for testing */
  dataTestId?: string;
  /** The row description. */
  description?: string;
  /** True when the mergeNext style is applied. */
  mergeNext?: boolean;
  /** The row name. */
  name?: string;
  /** Function invoked on button click. */
  onClick?: (event) => void;
}

export const Row = ({ children, className, dataTestId, description, mergeNext, name, onClick }: RowProps) => {
  let kids = children;
  let nameComp = (
    <dt
      key="name"
      className={classnames(style.name, onClick && style.clickableRow, mergeNext && style.mergeNext)}
      data-testid={`${dataTestId}-name`}
    >
      {name}
    </dt>
  );
  if (kids instanceof Array) {
    nameComp = kids.filter((c) => !!c).find((c) => c.type === Name || c.type === (<Name>test</Name>).type) || nameComp;
    kids = kids.filter((c) => !!c).filter((c) => !(c.type === Name || c.type === (<Name>test</Name>).type));
  }

  if (onClick) {
    kids = [
      <Button
        key="clickable"
        className={style.clickable}
        dataTestId={`${dataTestId}-button`}
        onClick={onClick}
        theme="none"
      >
        <span>{kids}</span>
        <Chevron className={style.chevron} />
      </Button>,
    ];
  }

  return (
    <>
      {nameComp}
      <dd
        key="value"
        className={classnames(style.value, className, mergeNext && style.mergeNext)}
        data-testid={`${dataTestId}-value`}
      >
        {kids}
        {description && <p>{description}</p>}
      </dd>
    </>
  );
};
