import { MultiValue } from 'react-select';

import ApiRequest from 'store/shared/api/apiRequest';
import { MutationnoteCreateArgs, Note, NoteEntityType } from 'store/shared/api/graph/interfaces/types';
import { NoteEntity, noteEntities } from 'constants/notes';
import { SelectOption } from 'utils/interfaces/SelectOption';

interface MutationPostNote extends Omit<MutationnoteCreateArgs, 'entityId' | 'companyIds'> {
  /** Entity Id */
  id: string;
  /** List of company options */
  companies: MultiValue<SelectOption<string>>;
}

interface QueryGetNote {
  /** Entity type */
  entityType: NoteEntityType;
  /** Entity id */
  id: string;
}

export const postNote = ({ entityType, id, content, companies }: MutationPostNote) =>
  ApiRequest<{ noteCreate: Pick<Note, 'id'> }>({
    data: {
      operationName: 'postNote',
      query: `
      mutation($entityType: NoteEntityType!, $id: String!, $content: String!, $companyIds: [String]) {
        noteCreate(entityType: $entityType, entityId: $id, content: $content, companyIds: $companyIds) {
          id
        }
      }
    `,
      variables: {
        entityType,
        id,
        content,
        companyIds: companies?.map((company) => company?.value),
      },
    },
  });

export const getNotes = ({ entityType, id }: QueryGetNote) => {
  const noteEntity = noteEntities[entityType];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return ApiRequest<{ [key in NoteEntity]?: { notes: Note[] } }>({
    data: {
      operationName: 'getNotes',
      query: `
        query ($id: String!) {
          ${noteEntity}(${noteEntity}Id: $id) {
            notes {
              id
              content
              created
              createdBy {
                firstName
                lastName
                photoUrl(width:150, height:150)
                lastLoggedIn
                id
              }
            }
          }
        }
    `,
      variables: {
        id,
      },
    },
  });
};
