import { ReactNode } from 'react';
import classnames from 'classnames';

import style from './flexbox.scss';

type FlexLayout = {
  className?: string;
  children: ReactNode;
};

export const Flex = ({ className, children, ...props }: FlexLayout) => (
  <div {...props} className={classnames(style.flex, className)}>
    {children}
  </div>
);

export const FlexRow = ({ className, children, ...props }: FlexLayout) => (
  <Flex {...props} className={classnames(style.flexRow, className)}>
    {children}
  </Flex>
);

export const FlexRowSpaceBetween = ({ className, children, ...props }: FlexLayout) => (
  <FlexRow {...props} className={classnames(style.flexRowSpaceBetween, className)}>
    {children}
  </FlexRow>
);

export const FlexColumn = ({ className, children, ...props }: FlexLayout) => (
  <Flex {...props} className={classnames(style.flexColumn, className)}>
    {children}
  </Flex>
);
