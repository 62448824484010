import { lazy } from 'react';
import { IndexRedirect, IndexRoute, Redirect } from 'react-router';

import restrictedRoute from './restrictedRoute';
import RouterRoot from 'store/routing/routerRoot';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  AuctionTimeSlotStatus,
  TransactionType,
} from 'store/shared/api/graph/interfaces/types';
import { Route } from 'store/routing/routes';
import { Route as RouterRoute } from 'constants/reactRouter';

// Load the following components asynchronously
const AccountCompaniesContainer = lazy(() => import('containers/account/accountCompaniesContainer'));
const AccountContainer = lazy(() => import('containers/account/accountContainer'));
const AccountDeleteContainer = lazy(() => import('containers/account/deleteAccountContainer'));
const AcknowledgementsContainer = lazy(() => import('containers/acknowledgements/acknowledgementsContainer'));
const AuctionAccessContainer = lazy(() => import('containers/auth/register/auctionAccessContainer'));
const AuctionItemsComparablesContainer = lazy(
  () => import('containers/lists/auctionItemsList/auctionItemComparablesContainer')
);
const AuctionItemsListContainer = lazy(() => import('containers/lists/auctionItemsList/auctionItemsListContainer'));
const AuthLayout = lazy(() => import('layouts/authLayouts/authLayouts'));
const Error404 = lazy(() => import('components/core/errors/404/404'));
const ForgotPassword = lazy(() => import('components/sections/auth/forgotPassword'));
const HomeDashboardContainer = lazy(() => import('containers/homeDashboard/homeDashboardContainer'));
const InventoryItemsListContainer = lazy(() => import('containers/lists/inventoryItemsList/inventoryItemsContainer'));
const Invite = lazy(() => import('components/sections/auth/invite'));
const LiveLaneContainer = lazy(() => import('containers/liveLanes/liveLanesContainer'));
const Login = lazy(() => import('components/sections/auth/login'));
const LogoutMessage = lazy(() => import('components/sections/auth/logoutMessage'));
const LoginPipeline = lazy(() => import('components/sections/auth/loginPipeline'));
const MyBlockBuyerContainer = lazy(() => import('containers/myBlock/myBlockBuyerContainer'));
const MyBlockSellerContainer = lazy(() => import('containers/myBlock/myBlockSellerContainer'));
const MyMarketGuideContainer = lazy(() => import('containers/myMarketGuide/myMarketGuideContainer'));
const ReportsContainer = lazy(() => import('containers/reports/reportsContainer'));
const ResetPassword = lazy(() => import('components/sections/auth/resetPassword'));

// Admin
const EventsContainer = lazy(() => import('containers/admin/events/eventsContainer'));
const LaneManagerContainer = lazy(() => import('containers/admin/liveLanes/laneManagerContainer'));
const LazyArbitrationsContainer = lazy(() => import('containers/admin/arbitrations/arbitrationsContainer'));
const LazyAuctionsContainer = lazy(() => import('containers/admin/auctions/auctionsContainer'));
const LazyBroadcastsContainer = lazy(() => import('containers/admin/broadcasts/broadcastsContainer'));
const LazyCarriersContainer = lazy(() => import('containers/admin/carriers/carriersContainer'));
const LazyCompaniesContainer = lazy(() => import('containers/admin/companies/companiesContainer'));
const LazyTransportJobsContainer = lazy(() => import('containers/admin/transportJobs/transportJobsContainer'));
const LazyUsersContainer = lazy(() => import('containers/admin/users/usersContainer'));
const LiveLanesAuctionClerkContainer = lazy(() => import('containers/admin/liveLanes/auctionClerkContainer'));
const LiveLanesAuctioneerContainer = lazy(() => import('containers/admin/liveLanes/auctioneerContainer'));
const LiveLanesMarqueeContainer = lazy(() => import('containers/admin/liveLanes/marqueeContainer'));

export default (
  <RouterRoute component={RouterRoot} path={Route.HOME}>
    <IndexRoute component={HomeDashboardContainer} />

    {/* Authentication and sign-up flows */}
    <RouterRoute component={AuthLayout} path={Route.AUTH}>
      <IndexRedirect to={Route.AUTH_LOGIN} />
      <RouterRoute component={Login} path={Route.AUTH_LOGIN} />
      <RouterRoute component={LogoutMessage} path={Route.AUTH_LOGOUT} />
      <RouterRoute component={ForgotPassword} path={Route.AUTH_FORGOT_PASSWORD} />
      <RouterRoute component={ResetPassword} path={Route.AUTH_RESET_PASSWORD} />
      <RouterRoute component={Invite} path={Route.AUTH_REQUEST_INVITE} />
    </RouterRoute>

    {/* Authentication short-hand redirects */}
    <Redirect from="login" to={Route.AUTH_LOGIN} />
    <Redirect from="forgot-password" to={Route.AUTH_FORGOT_PASSWORD} />
    <Redirect from="request-invite" to={Route.AUTH_REQUEST_INVITE} />

    {/* Authentication pages without `AuthLayout` wrapper */}
    <RouterRoute component={LoginPipeline} path={Route.AUTH_CARFAX_USA} />
    <RouterRoute component={LoginPipeline} path={Route.AUTH_EMPLOYEE} />
    <RouterRoute component={LoginPipeline} path={Route.AUTH_PIPELINE} />
    <RouterRoute component={AuctionAccessContainer} path={Route.AUTH_REGISTER} />

    <RouterRoute path={Route.BUY}>
      <IndexRedirect to={Route.BUY_TIMED_AUCTION} />
      {/* "Timed Auctions" used to be known as "Hourly Auctions". Allow for old bookmarks to be redirected */}
      <Redirect from={Route.BUY_HOURLY_AUCTION} to={Route.BUY_TIMED_AUCTION} />
      <RouterRoute
        component={AuctionItemsListContainer}
        formats={[AuctionItemFormat.APPRAISAL, AuctionItemFormat.GROUNDED]}
        path={Route.BUY_TIMED_AUCTION}
        status={AuctionItemStatus.LIVE}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        formats={[AuctionItemFormat.AUCTION, AuctionItemFormat.AUCTION_PHYSICAL]}
        path={Route.BUY_RUN_LIST}
        sortId="CREATED"
        status={AuctionItemStatus.UPCOMING}
        timeSlotStatus={AuctionTimeSlotStatus.WAITING}
      />
      <RouterRoute component={LiveLaneContainer} path={Route.BUY_LIVE_AUCTION} />
      <RouterRoute
        component={AuctionItemsListContainer}
        formats={[AuctionItemFormat.TIMED_OFFER]}
        path={Route.BUY_BUY_NOW}
        status={AuctionItemStatus.LIVE}
      />
      <RouterRoute
        component={restrictedRoute({
          component: AuctionItemsComparablesContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_COMPARABLES'],
        })}
        path={Route.BUY_COMPARABLES}
      />
    </RouterRoute>

    <RouterRoute path={Route.SELL}>
      <IndexRedirect to={Route.SELL_PARKED} />
      <RouterRoute component={InventoryItemsListContainer} path={Route.SELL_PARKED} />
    </RouterRoute>

    <RouterRoute path={Route.PENDING}>
      <IndexRedirect to={Route.PENDING_IN_IF_BID} />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.PENDING_IN_IF_BID}
        sortAscending={false}
        sortId="UPDATED"
        status={AuctionItemStatus.IN_IF_BID}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.PENDING_DELIVERY}
        sortId="UPDATED"
        status={AuctionItemStatus.PENDING_DELIVERY}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.PENDING_AWAITING_CHECKOUT}
        sortAscending={false}
        sortId="CHECKOUT_EXPIRY"
        status={AuctionItemStatus.AWAITING_CHECKOUT}
      />
    </RouterRoute>

    <RouterRoute path={Route.FINISHED}>
      <IndexRedirect to={Route.FINISHED_SOLD} />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.FINISHED_PURCHASED}
        sortId="DATE_SOLD"
        status={AuctionItemStatus.SOLD}
        transactionType={TransactionType.BUYING}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.FINISHED_SOLD}
        sortId="DATE_SOLD"
        status={AuctionItemStatus.SOLD}
        transactionType={TransactionType.SELLING}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.FINISHED_NO_SALE}
        sortId="UPDATED"
        status={AuctionItemStatus.NO_SALE}
      />
      <RouterRoute
        component={AuctionItemsListContainer}
        path={Route.FINISHED_CANCELLED}
        sortId="UPDATED"
        status={AuctionItemStatus.SALE_CANCELLED}
      />
    </RouterRoute>

    <RouterRoute path={Route.TRANSPORT}>
      <IndexRedirect to={Route.TRANSPORT_JOBS} />
      <RouterRoute
        component={restrictedRoute({
          component: LazyTransportJobsContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_TRANSPORT'],
        })}
        path={Route.TRANSPORT_JOBS}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LazyCarriersContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_TRANSPORT'],
        })}
        path={Route.TRANSPORT_CARRIERS}
      />
    </RouterRoute>

    <RouterRoute
      component={restrictedRoute({
        component: LazyArbitrationsContainer,
        authorizedSystemPermissions: ['SYSTEM_VIEW_ARBITRATIONS'],
      })}
      path={Route.ADMIN_ARBITRATIONS}
    />

    <RouterRoute
      component={restrictedRoute({ component: ReportsContainer, isStaffUser: true })}
      path={Route.ADMIN_REPORTS}
    />
    <RouterRoute
      component={restrictedRoute({ component: ReportsContainer, isStaffUser: true })}
      path={Route.ADMIN_REPORT}
    />

    <RouterRoute path={Route.ADMIN}>
      <IndexRedirect to="companies" />
      <RouterRoute
        component={restrictedRoute({
          component: LazyCompaniesContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_COMPANIES'],
        })}
        path={Route.ADMIN_COMPANIES}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LazyUsersContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_USERS'],
        })}
        path={Route.ADMIN_USERS}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LazyAuctionsContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_AUCTIONS'],
        })}
        path={Route.ADMIN_AUCTIONS}
      />
      <RouterRoute
        component={restrictedRoute({
          component: EventsContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_EVENTS'],
        })}
        path={Route.ADMIN_EVENTS}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LaneManagerContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_EVENTS'],
        })}
        path={Route.ADMIN_EVENTS_LANE_MANAGEMENT}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LazyBroadcastsContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_BROADCASTS'],
        })}
        path={Route.ADMIN_BROADCASTS}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LiveLanesAuctionClerkContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_EVENTS'],
        })}
        isNavigationDisabled
        path={Route.ADMIN_LIVE_LANES_AUCTION_CLERK}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LiveLanesAuctioneerContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_EVENTS'],
        })}
        isNavigationDisabled
        path={Route.ADMIN_LIVE_LANES_AUCTIONEER}
      />
      <RouterRoute
        component={restrictedRoute({
          component: LiveLanesMarqueeContainer,
          authorizedSystemPermissions: ['SYSTEM_VIEW_EVENTS'],
        })}
        isNavigationDisabled
        path={Route.ADMIN_LIVE_LANES_MARQUEE}
      />
    </RouterRoute>

    <RouterRoute path={Route.MY_BLOCK}>
      <IndexRedirect to={Route.MY_BLOCK_SELLER} />
      <RouterRoute component={MyBlockBuyerContainer} path={Route.MY_BLOCK_BUYER} />
      <RouterRoute component={MyBlockSellerContainer} path={Route.MY_BLOCK_SELLER} />
    </RouterRoute>

    <RouterRoute component={MyMarketGuideContainer} path={Route.MY_MARKET_GUIDE} />

    <RouterRoute component={HomeDashboardContainer} path={Route.NOTIFICATION_SETTINGS} />

    <RouterRoute path={Route.ACCOUNT}>
      <IndexRoute component={AccountContainer} />
      <RouterRoute component={AccountCompaniesContainer} path={Route.ACCOUNT_COMPANIES} />
      <RouterRoute component={AccountDeleteContainer} path={Route.ACCOUNT_DELETE_ACCOUNT} />
    </RouterRoute>

    {!process.env.PRIVATE_LABEL && <RouterRoute component={AcknowledgementsContainer} path={Route.ACKNOWLEDGEMENTS} />}

    <Redirect from="*/index.html" to="/" />

    <RouterRoute component={Error404} path="*" />
    <RouterRoute path="*">
      <IndexRedirect to="/*" />
    </RouterRoute>
  </RouterRoute>
);
