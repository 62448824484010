import ApiRequest from 'store/shared/api/apiRequest';
import { UserConnection } from 'store/shared/api/graph/interfaces/types';
import { getFacetGroupsByUrl } from 'store/shared/api/graph/schema/connection';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { user } from 'store/shared/api/graph/schema/user';

export const getUsersList = ({
  first = null,
  last = null,
  after = null,
  before = null,
  status = null,
  email = null,
  title = null,
  firstName = null,
  lastName = null,
  consignerId = null,
  consignerName = null,
  createdGTE = null,
  createdLTE = null,
  updatedGTE = null,
  updatedLTE = null,
  city = null,
  region = null,
  country = null,
  timeZone = null,
  locale = null,
  photoStatus = null,
  auctionAccessStatus = null,
  omvicStatus = null,
  keyword = null,
}) =>
  ApiRequest<{ userConnection: UserConnection }>({
    data: {
      operationName: 'getUsersList',
      query: `
      query(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $status: [String]
        $email: [String]
        $title: [String]
        $firstName: [String]
        $lastName: [String]
        $consignerId: [String]
        $consignerName: [String]
        $createdGTE: DateTime
        $createdLTE: DateTime
        $updatedGTE: DateTime
        $updatedLTE: DateTime
        $city: [String]
        $region: [String]
        $country: [String]
        $timeZone: [String]
        $locale: [String]
        $photoStatus: [String]
        $auctionAccessStatus: [String]
        $omvicStatus: [String]
        $keyword: String
      ) {
        userConnection(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          status: $status,
          email: $email,
          title: $title,
          firstName: $firstName,
          lastName: $lastName,
          consignerId: $consignerId,
          consignerName: $consignerName,
          createdGTE: $createdGTE,
          createdLTE: $createdLTE,
          updatedGTE: $updatedGTE,
          updatedLTE: $updatedLTE,
          city: $city,
          region: $region,
          country: $country,
          timeZone: $timeZone,
          locale: $locale,
          photoStatus: $photoStatus,
          auctionAccessStatus: $auctionAccessStatus,
          omvicStatus: $omvicStatus,
          keyword: $keyword
        ) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
            totalEdges
          }
          edges {
            node {
              ${user()}
              companyRelationships {
                count
                list {
                  company {
                    id
                    name
                  }
                }
              }
            }
          }
          ${getFacetGroupsByUrl()}
        }
      }
      ${locationFragment}
    `,
      variables: {
        first,
        last,
        after,
        before,
        status,
        email,
        title,
        firstName,
        lastName,
        consignerId,
        consignerName,
        createdGTE,
        createdLTE,
        updatedGTE,
        updatedLTE,
        city,
        region,
        country,
        timeZone,
        locale,
        photoStatus,
        auctionAccessStatus,
        omvicStatus,
        keyword,
      },
    },
  });

export const getFacets = ({
  first = null,
  last = null,
  after = null,
  before = null,
  status = null,
  consignerId = null,
  consignerName = null,
  keyword = null,
}) =>
  ApiRequest<{ userConnection: UserConnection }>({
    data: {
      query: `
      query(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $status: [String]
        $consignerId: [String],
        $consignerName: [String],
        $keyword: String,
      ) {
        userConnection(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          status: $status,
          consignerId: $consignerId,
          consignerName: $consignerName,
          keyword: $keyword,
        ) {
          ${getFacetGroupsByUrl()}
        }
      }
    `,
      variables: {
        first,
        last,
        after,
        before,
        status,
        consignerId,
        consignerName,
        keyword,
      },
    },
  });
