import classnames from 'classnames';
import moment from 'moment';
import { debounce, groupBy, isEqual } from 'lodash-es';

import arrowLeftGlyph from 'glyphs/arrow-left.svg';

import AuctionLaneInput from 'components/sections/auctionItem/operations/submitToAuction/pages/auctionLaneInput';
import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import InputText from 'forms/shared/inputText';
import RadioButtons, { RadioButtonLabel } from 'forms/shared/radioButtons';
import ReservePrice from 'components/sections/auctionItem/operations/submitToAuction/pages/reservePrice';
import SaleLightFormSection from 'components/sections/auctionItem/operations/submitToAuction/pages/saleLightForm/saleLightFormSection';
import Select from 'forms/shared/select';
import SelectLocations from 'forms/shared/selectLocations';
import Sprite from 'components/ui/shared/sprite';
import TimedOfferForm from 'components/sections/auctionItem/operations/submitToAuction/pages/timedOfferForm';
import ValuesInfo from 'components/sections/inventoryItem/details/feesAndValues/valuesInfo';
import {
  AuctionDates,
  AuctionDateType,
  AuctionFormatType,
  LanesByType,
  TimedAuctionDates,
} from 'components/sections/auctionItem/operations/submitToAuction/constants/submitToAuction';
import { AuctionDetails } from 'store/auctionSubmission/auctionSubmissionModels';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import {
  AuctionItemFormat,
  AuctionLaneType,
  InventoryItemValue,
  InventoryItemValueTypeEnum,
  Location as LocationType,
  MutationauctionItemCreateArgs,
  QueryasIsQualifiedArgs,
  ReserveType,
  SaleLight,
} from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { FormDialogBody, FormDialogFooter, FormErrors, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { FormStepType } from 'components/sections/auctionItem/operations/submitToAuction/dialog';
import { InventoryItemDetailsProps } from 'store/inventoryItemDetails/inventoryItemDetailsModels';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { Spinner } from 'components/ui/loading/loading';
import { asIsQualified } from 'store/shared/api/graph/queries/asIs';
import { getAuctionTimeSlot, getSuggestedSaleLights } from 'store/auctionSubmission/auctionSubmissionApi';
import { getErrorMessages } from 'utils/apiUtils';
import { getRangeOfDates } from 'utils/dateUtils';
import { t } from 'utils/intlUtils';

import style from './submitForm.scss';

export interface Props {
  /** The auction details. */
  auction: AuctionDetails;
  /** Date details for the selected auction type. */
  auctionDates: AuctionDates;
  /** The auction type. */
  auctionFormatType: AuctionFormatType;
  /** The auction Item details. */
  auctionItem: AuctionItemDetailsProps | undefined;
  /** Function invoked to create auction item marketplace. */
  createAuctionItem: (options: MutationauctionItemCreateArgs) => Promise<void>;
  /** The auction delivery date options. */
  deliveryDates?: SelectOption[];
  /** The inventory item details. */
  inventoryItem: InventoryItemDetailsProps;
  /** The mmr values of the inventory item. */
  inventoryItemValues?: InventoryItemValue[];
  /** Function invoked when user proceeds to next form step. */
  onPageChange: (page: FormStepType) => void;
}

interface State {
  /** True when current inventory item is `as-is` qualified. */
  asIs: boolean;
  /** The selected auction time slot id value. */
  auctionTimeSlotId: string;
  /** Date details for the selected auction type. */
  auctionDates: AuctionDateType[] | null;
  /** The auction item format type. */
  auctionItemFormat: AuctionItemFormat | null;
  /** The type of auction lane. */
  auctionLaneType: AuctionLaneType | null;
  /** The auction location of the inventory item. */
  auctionLocation: LocationType | undefined;
  /** The id of the auction time slot lane. */
  auctionTimeSlotLaneId: string | null;
  /** The auction time slot lanes grouped by type. */
  auctionTimeSlotLanesByType: LanesByType | null;
  /** The buy now amount of the auction item */
  buyNowAmount?: number;
  /** The delivery date of the auction item. */
  deliveryDate: string | null;
  /** Validation Errors. */
  errorMessages: ErrorMessages;
  /** True when fetching auction time slot. */
  isFetchingAuctionTimeSlot: boolean;
  /** True when fetching auction time slot. */
  isFetchingSaleLights: boolean;
  /** True when `as-is` is eligibility enabled. */
  isAsIsEnabled: boolean;
  /** True when the asIs form is rendered. */
  isAsIsForm: boolean;
  /** True when the asIs form is rendered. */
  isSaleLightForm: boolean;
  /** True when form is saving. */
  isSaving: boolean;
  /** The reserve amount of the auction item. */
  reserveAmount: number | undefined;
  /** The reserve type of the auction item. */
  reserveType: ReserveType;
  /** The run number of the auction lane. */
  runNumber: number | null;
  /** The sale lights of the physical auction item. */
  saleLights: SaleLight[];
  /** The pre-populated sale lights of the physical auction item. */
  saleLightsInitial: SaleLight[];
  /** The starting bid of the auction item. */
  startingAmount: number | undefined;
}

class SubmitForm extends BaseClass<Props, State> {
  private deliveryDates: SelectOption[];

  constructor(props: Props) {
    super(props);
    const { auctionItem, auctionFormatType, inventoryItem } = props;
    const auctionItemFormat = this.getDefaultAuctionItemFormat(auctionFormatType);
    this.deliveryDates = getRangeOfDates(moment().startOf('days'), moment().add(21, 'days'), 'days').map(
      ({ id, name }) => ({ label: name, value: id })
    );

    const intendedAuctionTimeSlotId = inventoryItem.auctionTimeSlotLane?.auctionTimeSlotId || null;
    const auctionDates =
      auctionItemFormat &&
      [AuctionItemFormat.AUCTION, AuctionItemFormat.TIMED_OFFER].includes(auctionItemFormat) &&
      Array.isArray(props.auctionDates)
        ? props.auctionDates
        : null;
    const auctionDate = this.getAuctionDate(auctionDates, intendedAuctionTimeSlotId);
    const defaultAuctionTimeSlotId = auctionDate?.value;
    const suggestedReservePrice = this.getSuggestedReservePrice();

    this.state = {
      asIs: false,
      auctionDates,
      auctionItemFormat,
      auctionLaneType: null,
      auctionLocation: inventoryItem?.auctionLocation || undefined,
      auctionTimeSlotId: defaultAuctionTimeSlotId || '',
      auctionTimeSlotLaneId: null,
      auctionTimeSlotLanesByType: null,
      buyNowAmount: suggestedReservePrice || auctionItem?.buyNowPrice?.amount,
      deliveryDate: auctionItem?.deliveryDate || null,
      errorMessages: [],
      isAsIsEnabled: false,
      isAsIsForm: false,
      isSaleLightForm: false,
      isFetchingAuctionTimeSlot: false,
      isFetchingSaleLights: false,
      isSaving: false,
      reserveAmount: suggestedReservePrice || auctionItem?.buyNowPrice?.amount || auctionItem?.reserve?.amount,
      reserveType: auctionItem?.reserveType || ReserveType.FLEXIBLE,
      runNumber: null,
      saleLights: [],
      saleLightsInitial: [],
      startingAmount: auctionItem?.startingBid?.amount,
    };

    if (auctionItemFormat === AuctionItemFormat.AUCTION) {
      // Fetch auction time slot lanes for the default auction time slot
      defaultAuctionTimeSlotId && this.getAuctionTimeSlotLanes(defaultAuctionTimeSlotId, auctionItemFormat);
      // For Live Auction items, check the AS-IS eligibility if the unit is being re-run
      this.requestInitialPriceChange();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      auctionDates: auctionDatesNext,
      auctionItemFormat: auctionItemFormatNext,
      auctionLaneType: auctionLaneTypeNext,
      auctionTimeSlotId: auctionTimeSlotIdNext,
    } = this.state;
    const { auctionDates, auctionItemFormat, auctionLaneType, auctionTimeSlotId } = prevState;
    const newAuctionDates = !!auctionDatesNext && auctionDates !== auctionDatesNext;

    if (auctionItemFormat !== auctionItemFormatNext) {
      // Clear time slot selection if format changes.
      this.setState({
        auctionTimeSlotId: null,
      });
    }

    if (auctionTimeSlotIdNext !== auctionTimeSlotId) {
      // Fetch auction time slot lanes for the selected auction time slot
      this.getAuctionTimeSlotLanes(auctionTimeSlotIdNext, auctionItemFormatNext);
    }

    if (newAuctionDates && Array.isArray(auctionDatesNext) && auctionDatesNext.length === 1) {
      this.setState({
        auctionTimeSlotId: auctionDatesNext[0].value,
        auctionTimeSlotLaneId: null,
        runNumber: null,
      });
    }

    if (
      auctionItemFormat === null &&
      auctionItemFormatNext !== null &&
      [AuctionItemFormat.APPRAISAL, AuctionItemFormat.GROUNDED].includes(auctionItemFormatNext)
    ) {
      // Once a Timed Auction option is selected, check the AS-IS eligibility if the unit is being re-run
      this.requestInitialPriceChange();
    }

    if (!!auctionLaneTypeNext && auctionLaneTypeNext !== auctionLaneType) {
      const priceMap = {
        [AuctionItemFormat.APPRAISAL]: this.state.startingAmount,
        [AuctionItemFormat.TIMED_OFFER]: this.state.buyNowAmount,
        [AuctionItemFormat.AUCTION]: this.state.reserveAmount ?? 0,
      };
      const price = this.state.auctionItemFormat ? priceMap[this.state.auctionItemFormat] : undefined;
      this.checkAsIsEligibility(price);
    }
  }

  getAuctionTimeSlotLanes = (auctionTimeSlotId: string, auctionItemFormat: AuctionItemFormat | null) => {
    const { inventoryItem } = this.props;

    if (auctionItemFormat !== AuctionItemFormat.AUCTION) {
      return;
    }

    this.setState({ isFetchingAuctionTimeSlot: true });
    getAuctionTimeSlot({ auctionTimeSlotId, companyId: inventoryItem.company?.id })
      .then((response) => {
        const auctionTimeSlotLanes = response?.data?.data?.auctionTimeSlot?.auctionTimeSlotLanes ?? [];
        const lanesByType = groupBy(auctionTimeSlotLanes, 'type');
        this.setState({
          auctionLaneType: this.getDefaultAuctionLaneType(auctionItemFormat, lanesByType),
          auctionTimeSlotLanesByType: lanesByType,
        });
      })
      .catch((error) => {
        const errorMessages = getErrorMessages(error);
        this.setState({ errorMessages });
      })
      .finally(() => {
        this.setState({ isFetchingAuctionTimeSlot: false });
      });
  };

  /**
   * Gets the prepopulated sale lights for the physical auction item.
   *  - If the auction item has sale lights from re-sale, then use those as the suggested lights.
   *  - Otherwise, get the suggested sale lights.
   */
  getInitialSaleLights = () => {
    const { auctionItem, inventoryItem } = this.props;
    const { asIs } = this.state;
    const existingSaleLights = auctionItem?.saleLights ?? [];

    if (existingSaleLights.length) {
      this.setState({
        saleLightsInitial: existingSaleLights,
        saleLights: existingSaleLights,
      });
      return;
    }

    const options = {
      asIs,
      inventoryItemId: inventoryItem.id,
    };

    getSuggestedSaleLights(options)
      .then((response) => {
        const suggestedSaleLights = response?.data?.data?.auctionItemSuggestedSaleLights ?? [];
        this.setState({
          saleLightsInitial: suggestedSaleLights,
          saleLights: suggestedSaleLights,
        });
      })
      .catch((error) => {
        const errorMessages = getErrorMessages(error);
        this.setState({ errorMessages });
      });
  };

  getDefaultAuctionItemFormat = (auctionFormatType: AuctionFormatType) => {
    if (auctionFormatType === AuctionFormatType.LIVE) {
      return AuctionItemFormat.AUCTION;
    }

    if (auctionFormatType === AuctionFormatType.TIMED_OFFER) {
      return AuctionItemFormat.TIMED_OFFER;
    }

    return null;
  };

  getAuctionDate(auctionDates: AuctionDateType[] | null, auctionTimeSlotId: string | null): AuctionDateType | null {
    const selectedAuctionDate = auctionDates?.find(({ value }) => value === auctionTimeSlotId);
    if (selectedAuctionDate) {
      return selectedAuctionDate;
    }

    return auctionDates?.length === 1 ? auctionDates[0] : null;
  }

  getDefaultAuctionLaneType(
    auctionItemFormat: AuctionItemFormat | null,
    auctionTimeSlotLanesByType: LanesByType | null
  ): AuctionLaneType | null {
    const { auctionTimeSlotId } = this.state;
    const { inventoryItem } = this.props;
    if (auctionItemFormat !== AuctionItemFormat.AUCTION) {
      return null;
    }

    // Return intended lane type if intended lane is available and matches the selected auction time slot.
    if (inventoryItem.auctionTimeSlotLane?.auctionTimeSlotId === auctionTimeSlotId) {
      return inventoryItem.auctionTimeSlotLane?.type;
    }

    const laneTypes = Object.keys(auctionTimeSlotLanesByType ?? {}) as AuctionLaneType[];

    // Return lane type if there is only one lane type available.
    return laneTypes.length === 1 ? laneTypes[0] : null;
  }

  getTimedAuctionDateByType(auctionItemFormat: AuctionItemFormat) {
    const { auctionDates } = this.props;
    const { appraisal, grounded } = auctionDates as TimedAuctionDates;

    return auctionItemFormat === AuctionItemFormat.APPRAISAL ? appraisal : grounded;
  }

  validate = (): boolean => {
    const {
      auctionItemFormat,
      auctionLaneType,
      auctionTimeSlotId,
      auctionTimeSlotLaneId,
      buyNowAmount,
      deliveryDate,
      reserveAmount,
      reserveType,
      runNumber,
      startingAmount,
    } = this.state;
    const errorMessages: ErrorMessages = [];

    switch (auctionItemFormat) {
      /** Live Auction */
      case AuctionItemFormat.AUCTION:
        // Physical Auction
        if (auctionLaneType === AuctionLaneType.PHYSICAL) {
          if (!auctionTimeSlotLaneId || !runNumber) {
            errorMessages.push({ message: t('error_lane_run_number_required') });
          }
          if (!this.state.auctionTimeSlotId) {
            errorMessages.push({ message: t('error_time_slot_required') });
          }

          // Digital Auction
        } else {
          if (!reserveAmount && reserveType !== ReserveType.UNRESERVED) {
            errorMessages.push({ message: t('error_reserve_required') });
          }
          if (this.state.auctionTimeSlotId && !this.state.auctionLaneType) {
            errorMessages.push({ message: t('error_lane_type_required') });
          }
          if (!this.state.auctionTimeSlotId) {
            errorMessages.push({ message: t('error_time_slot_required') });
          }
        }
        break;

      /** Timed Auction - Appraisal */
      case AuctionItemFormat.APPRAISAL:
        if (!startingAmount) {
          errorMessages.push({ message: t('error_starting_bid_required') });
        }
        if (!deliveryDate) {
          errorMessages.push({ message: t('error_delivery_date_required') });
        }
        if (!auctionTimeSlotId) {
          errorMessages.push({ message: t('error_appraisal_end_time_required') });
        }
        break;
      /** Timed Auction - Grounded */
      case AuctionItemFormat.GROUNDED:
        if (!reserveAmount && reserveType !== ReserveType.UNRESERVED) {
          errorMessages.push({ message: t('error_reserve_required') });
        }
        if (!auctionTimeSlotId) {
          errorMessages.push({ message: t('error_time_slot_required') });
        }
        break;
      /** Buy Now */
      case AuctionItemFormat.TIMED_OFFER:
        if (!buyNowAmount) {
          errorMessages.push({ message: t('error_buy_now_required') });
        }
        if (!auctionTimeSlotId) {
          errorMessages.push({ message: t('error_time_slot_required') });
        }
        break;
      default:
        errorMessages.push({ message: t('error_auction_type') });
        break;
    }

    this.setState({ errorMessages });
    return !errorMessages?.length;
  };

  onNextPageRequest = () => {
    if (!this.validate()) {
      // Invalid form state; show errors and prevent page change/form submission
      return;
    }

    const { auction } = this.props;
    const { isAsIsEnabled, isAsIsForm, isSaleLightForm } = this.state;
    const saleLightsOverride = auction?.settings?.saleLightsOverride;
    const isSaleLightsDisabled = saleLightsOverride !== null && !saleLightsOverride?.length;
    const isSaleLightsAutomated = saleLightsOverride === null || !saleLightsOverride?.length;
    const asIsFormNext = isAsIsEnabled && !isAsIsForm && !isSaleLightForm;
    const isSaleLightsFormNext = !isSaleLightsDisabled && !isSaleLightForm;

    if (asIsFormNext) {
      this.setState({ asIs: true, isAsIsForm: true });
    } else if (isSaleLightsFormNext) {
      if (isSaleLightsAutomated) {
        this.getInitialSaleLights();
      }
      this.setState({ isAsIsForm: false, isSaleLightForm: true });
    } else {
      this.handleSubmit();
    }
  };

  /**
   * HandleSubmit - debounced to limit concurrent submissions
   */
  handleSubmit = debounce(() => {
    const { auctionLaneType, auctionItemFormat } = this.state;

    switch (auctionItemFormat) {
      /** Live Auction */
      case AuctionItemFormat.AUCTION:
        if (auctionLaneType === AuctionLaneType.PHYSICAL) {
          this.handleAuctionPhysicalSubmit();
        } else {
          this.handleAuctionSubmit();
        }
        break;
      /** Timed Auction - Appraisal */
      case AuctionItemFormat.APPRAISAL:
        this.handleAppraisalSubmit();
        break;
      /** Timed Auction - Grounded */
      case AuctionItemFormat.GROUNDED:
        this.handleGroundedSubmit();
        break;
      /** Buy Now */
      case AuctionItemFormat.TIMED_OFFER:
        this.handleTimedOfferSubmit();
        break;
      default:
        this.setState({
          errorMessages: [{ message: t('error_auction_type') }],
        });
        break;
    }
  }, 250);

  handleAuctionSubmit = () => {
    this.submitAuction(
      this.props.createAuctionItem({
        auctionItemCreateInput: {
          auctionInput: {
            asIs: this.state?.asIs,
            auctionLocationId: this.state?.auctionLocation?.id,
            auctionTimeSlotId: this.state?.auctionTimeSlotId,
            reserveAmount: this.state?.reserveAmount,
            reserveType: this.state?.reserveType,
            saleLights: this.state?.saleLights,
          },
        },
        inventoryItemId: this.props.inventoryItem.id,
        format: AuctionItemFormat.AUCTION,
      })
    );
  };

  handleAuctionPhysicalSubmit = () => {
    if (!this.state.auctionTimeSlotLaneId || !this.state.runNumber) {
      this.setState({ errorMessages: [{ message: t('error_lane_run_number_required') }] });
    } else if (!this.state.auctionTimeSlotId) {
      this.setState({ errorMessages: [{ message: t('error_time_slot_required') }] });
    } else {
      this.submitAuction(
        this.props.createAuctionItem({
          auctionItemCreateInput: {
            physicalInput: {
              asIs: this.state?.asIs,
              auctionLocationId: this.state?.auctionLocation?.id,
              auctionTimeSlotLaneId: this.state?.auctionTimeSlotLaneId,
              reserveAmount: this.state?.reserveAmount,
              runNumber: this.state?.runNumber,
              saleLights: this.state?.saleLights,
            },
          },
          inventoryItemId: this.props.inventoryItem.id,
          format: AuctionItemFormat.AUCTION_PHYSICAL,
        })
      );
    }
  };

  handleAppraisalSubmit = () => {
    this.submitAuction(
      this.props.createAuctionItem({
        auctionItemCreateInput: {
          appraisalInput: {
            asIs: this.state.asIs,
            auctionId: this.props.auction?.id,
            auctionLocationId: this.state.auctionLocation?.id,
            auctionTimeSlotId: this.state.auctionTimeSlotId,
            deliveryDate: this.state.deliveryDate!,
            saleLights: this.state?.saleLights,
            startingAmount: this.state.startingAmount!,
          },
        },
        inventoryItemId: this.props.inventoryItem.id,
        format: AuctionItemFormat.APPRAISAL,
      })
    );
  };

  handleGroundedSubmit = () => {
    this.submitAuction(
      this.props.createAuctionItem({
        auctionItemCreateInput: {
          groundedInput: {
            asIs: this.state?.asIs,
            auctionId: this.props?.auction?.id,
            auctionLocationId: this.state?.auctionLocation?.id,
            auctionTimeSlotId: this.state?.auctionTimeSlotId,
            buyNowAmount: this.state?.buyNowAmount,
            reserveAmount: this.state?.reserveAmount,
            reserveType: this.state?.reserveType,
            saleLights: this.state?.saleLights,
          },
        },
        inventoryItemId: this.props.inventoryItem.id,
        format: AuctionItemFormat.GROUNDED,
      })
    );
  };

  handleTimedOfferSubmit = () => {
    this.submitAuction(
      this.props.createAuctionItem({
        auctionItemCreateInput: {
          timedOfferInput: {
            asIs: this.state.asIs,
            auctionLocationId: this.state.auctionLocation?.id,
            auctionTimeSlotId: this.state.auctionTimeSlotId,
            buyNowAmount: this.state.buyNowAmount!,
            saleLights: this.state?.saleLights,
          },
        },
        inventoryItemId: this.props.inventoryItem.id,
        format: AuctionItemFormat.TIMED_OFFER,
      })
    );
  };

  submitAuction = (auctionSubmission) => {
    const { onPageChange } = this.props;

    this.setState({ isSaving: true });
    auctionSubmission
      .then(() => {
        this.setState({ isSaving: false });
        onPageChange(FormStepType.SUCCESSFUL_SUBMISSION);
      })
      .catch((error) => {
        const errorMessages = getErrorMessages(error);
        this.setState({ errorMessages, isSaving: false });
      });
  };

  clearErrorMessages = () => {
    if (this.state.errorMessages.length > 0) {
      this.setState({
        errorMessages: [],
      });
    }
  };

  /**
   * Verify if we need to check the AS-IS eligibility on units being re-run
   */
  requestInitialPriceChange = () => {
    const { auctionItemFormat, reserveAmount, startingAmount } = this.state;
    const price = auctionItemFormat === AuctionItemFormat.APPRAISAL ? startingAmount : reserveAmount;

    if (price) {
      this.onPriceChange(price);
    }
  };

  onPriceChange = debounce((price: number) => {
    if (!this.state.auctionItemFormat) {
      return;
    }

    this.setState((prevState) => {
      switch (this.state.auctionItemFormat) {
        case AuctionItemFormat.APPRAISAL:
          return {
            ...prevState,
            startingAmount: price,
          };

        case AuctionItemFormat.TIMED_OFFER:
          return {
            ...prevState,
            buyNowAmount: price,
          };

        default:
          return {
            ...prevState,
            reserveAmount: price,
          };
      }
    });

    this.checkAsIsEligibility(price);
  }, 250);

  /**
   *  Check AS-IS eligibility - Physical vs Digital can have different eligibility
   */
  checkAsIsEligibility = (price: number) => {
    if (price === undefined || !this.state.auctionItemFormat) {
      return;
    }

    const auctionFormat =
      this.state.auctionLaneType === AuctionLaneType.PHYSICAL
        ? AuctionItemFormat.AUCTION_PHYSICAL
        : this.state.auctionItemFormat;

    const options: QueryasIsQualifiedArgs = {
      inventoryItemId: this.props.inventoryItem.id,
      auctionId: this.props.auction?.id,
      auctionFormat,
      price,
    };
    asIsQualified(options)
      .then((response) => this.setState({ isAsIsEnabled: !!response?.data?.data?.asIsQualified }))
      .catch(() => {});
  };

  getSuggestedReservePrice = () => {
    const { auctionItem, inventoryItemValues } = this.props;

    // Only show suggested reserve price for parked items
    const suggestedReserve = !auctionItem
      ? inventoryItemValues?.find((value) => value.type.id === InventoryItemValueTypeEnum.SUGGESTED_RESERVE)
      : undefined;

    return suggestedReserve?.value.amount;
  };

  renderTimedAuctionFormatOptions() {
    const { auction } = this.props;
    const labels: RadioButtonLabel<AuctionItemFormat>[] = [
      {
        value: AuctionItemFormat.APPRAISAL,
        title: t('auction_item_format_appraisal'),
        subtitle: auction.appraisalMessage || undefined,
        subtitleTheme: 'orange',
      },
      {
        value: AuctionItemFormat.GROUNDED,
        title: t('auction_item_format_grounded'),
        subtitle: auction.buyNowMessage || undefined,
        subtitleTheme: 'green',
      },
    ];

    return (
      <div className={style.radioButtonsContainer}>
        <p className={style.headerText}>{t('choose_auction_format')}</p>
        <RadioButtons
          className={classnames(style.radioButtons, style.radioButtonsAuction)}
          labels={labels}
          onChange={(e: { target: { value: AuctionItemFormat } }) =>
            this.setState({
              auctionDates: this.getTimedAuctionDateByType(e.target.value),
              auctionItemFormat: e.target.value,
            })
          }
          stackedText
        />
      </div>
    );
  }

  renderInput() {
    const { auctionItemFormat, auctionLaneType, deliveryDate, startingAmount, reserveAmount, reserveType } = this.state;
    const { deliveryDates } = this.props;

    const formattedDates = deliveryDates ?? this.deliveryDates;
    const isAppraisal = auctionItemFormat === AuctionItemFormat.APPRAISAL;

    return (
      <div className={classnames(isAppraisal && style.startingPriceContainer)}>
        {isAppraisal ? (
          <>
            <div className={style.inputContainer}>
              <InputText
                dataTestId="currency-input"
                defaultValue={startingAmount || ''}
                onChange={this.onPriceChange}
                onFocus={() => this.clearErrorMessages()}
                placeholder={t('starting_bid')}
                theme="minimal"
                type="currency"
              />
            </div>
            <Select
              className={style.dropdown}
              onChange={(option) => {
                this.clearErrorMessages();
                this.setState({ deliveryDate: option?.value });
              }}
              options={formattedDates}
              placeholder={t('delivery_date')}
              themeOverrides={{ height: '46px' }}
              value={formattedDates.find(({ value }) => value === deliveryDate)}
            />
          </>
        ) : (
          <ReservePrice
            auctionItemFormat={auctionItemFormat}
            auctionLaneType={auctionLaneType}
            clearErrorMessages={this.clearErrorMessages}
            onPriceChange={this.onPriceChange}
            onReserveTypeChange={(type) => this.setState({ reserveType: type })}
            reserveAmount={reserveAmount}
            reserveType={reserveType}
          />
        )}
      </div>
    );
  }

  renderBuyNowPriceInput() {
    return (
      <div className={style.inputContainer}>
        <InputText
          defaultValue=""
          onChange={(value: number) => {
            this.clearErrorMessages();
            this.setState({ buyNowAmount: value });
          }}
          onFocus={this.clearErrorMessages}
          placeholder={t('buy_now_price_optional')}
          theme="minimal"
          type="currency"
        />
      </div>
    );
  }

  renderAuctionForm() {
    const { auction, inventoryItem, inventoryItemValues } = this.props;
    const {
      auctionDates,
      auctionItemFormat,
      auctionLaneType,
      auctionTimeSlotId,
      auctionTimeSlotLaneId,
      auctionTimeSlotLanesByType,
      isFetchingAuctionTimeSlot,
      runNumber,
    } = this.state;

    const isAppraisal = auctionItemFormat === AuctionItemFormat.APPRAISAL;
    const isAuction = auctionItemFormat === AuctionItemFormat.AUCTION;
    const isGrounded = auctionItemFormat === AuctionItemFormat.GROUNDED;

    return (
      <div className={style.radioButtonsContainer}>
        {!!auction?.settings?.requireAuctionLocation && (
          <>
            <p className={style.headerText}>{t('choose_auction_location')}</p>
            <SelectLocations
              className={style.selectLocation}
              companyId={this.props?.inventoryItem?.company?.id}
              connectionVariables={{ auctionId: auction?.id, consignerId: undefined }}
              defaultValue={this.state?.auctionLocation}
              onLocationChange={(location) => this.setState({ auctionLocation: location })}
              showCompoundSelectionOption={false}
              themeOverrides={{ height: '46px' }}
            />
          </>
        )}
        <p className={style.headerText}>{t(isAppraisal ? 'starting_bid_and_delivery_date' : 'reserve_price')}</p>
        {this.renderInput()}

        <ValuesInfo
          countryCode={inventoryItem.location?.countryCode}
          inventoryItemValues={inventoryItemValues}
          make={inventoryItem.make}
          model={inventoryItem.model}
          subModel={inventoryItem.subModel}
          year={inventoryItem.year}
        />

        {isGrounded && (
          <>
            <p className={classnames(style.subTitle, style.headerText)}>{t('buy_now_price')}</p>
            {this.renderBuyNowPriceInput()}
          </>
        )}

        {auctionDates && (
          <>
            <p className={classnames(style.subTitle, style.headerText)}>{t(isAuction ? 'start_time' : 'end_time')}</p>
            <div>
              <RadioButtons
                className={style.radioButtons}
                labels={auctionDates}
                onChange={(e) => {
                  this.clearErrorMessages();

                  this.setState((previousState) => ({
                    ...previousState,
                    auctionTimeSlotId: e.target.value,
                    auctionTimeSlotLaneId: null,
                    runNumber: null,
                  }));
                }}
                selectedOption={auctionTimeSlotId}
              />
            </div>
          </>
        )}
        {isAuction && (
          <AuctionLaneInput
            auctionLaneType={auctionLaneType}
            auctionTimeSlotId={auctionTimeSlotId}
            auctionTimeSlotLaneId={auctionTimeSlotLaneId}
            auctionTimeSlotLanesByType={auctionTimeSlotLanesByType}
            intendedAuctionTimeSlotLane={inventoryItem.auctionTimeSlotLane || null}
            intendedRunNumber={inventoryItem.runNumber || null}
            isLoading={isFetchingAuctionTimeSlot}
            runNumber={runNumber}
            setAuctionLaneType={(value) => this.setState({ auctionLaneType: value })}
            setAuctionTimeSlotLaneId={(value) => this.setState({ auctionTimeSlotLaneId: value })}
            setRunNumber={(value) => this.setState({ runNumber: value })}
          />
        )}
      </div>
    );
  }

  renderAsIsForm = () => {
    const { inventoryItem } = this.props;
    const { asIs } = this.state;

    return (
      <div className={style.asIsContainer}>
        <p className={style.asIsCause}>{inventoryItem.asIsCause}</p>
        <RadioButtons
          labels={[{ value: 'AS_IS', title: t('vehicle_will_be_sold_as_is') }]}
          onChange={(event) => this.setState({ asIs: event?.target?.value !== null })}
          selectedOption={asIs ? 'AS_IS' : undefined}
          theme="red"
          toggleEnabled
        />
      </div>
    );
  };

  renderSaleLightForm = () => {
    const { auction } = this.props;
    const { auctionLaneType, saleLightsInitial, saleLights } = this.state;

    const saleLightsOverride = auction?.settings?.saleLightsOverride;

    return (
      <SaleLightFormSection
        activeSaleLights={saleLights}
        isSelectionDisabled={
          auctionLaneType !== AuctionLaneType.PHYSICAL &&
          (!saleLightsOverride || (!!saleLightsOverride && saleLightsOverride?.length <= 0))
        }
        isSuggestedOverridden={!isEqual(saleLightsInitial.sort(), saleLights.sort())}
        onChange={(currentLights) => {
          this.setState({ saleLights: currentLights });
        }}
        onRevert={() => {
          this.setState({ saleLights: saleLightsInitial });
        }}
        saleLightsOverride={saleLightsOverride ?? undefined}
        title={t('sale_lights')}
      />
    );
  };

  renderTimedOfferForm = () => {
    const { inventoryItem, inventoryItemValues } = this.props;
    const { auctionDates, auctionTimeSlotId, buyNowAmount } = this.state;
    return (
      <TimedOfferForm
        auctionDates={auctionDates}
        auctionTimeSlotId={auctionTimeSlotId}
        clearErrorMessages={this.clearErrorMessages}
        defaultBuyNowAmount={buyNowAmount}
        inventoryItem={inventoryItem}
        inventoryItemValues={inventoryItemValues}
        setBuyNowAmount={this.onPriceChange}
        setEndTime={(value) => this.setState({ auctionTimeSlotId: value })}
      />
    );
  };

  renderFooter() {
    const { auction, onPageChange } = this.props;
    const { isAsIsEnabled, isAsIsForm, isSaleLightForm } = this.state;
    const saleLightsOverride = auction?.settings?.saleLightsOverride;
    const isSaleLightsDisabled = saleLightsOverride !== null && !saleLightsOverride?.length;
    const asIsFormNext = isAsIsEnabled && !isAsIsForm && !isSaleLightForm;
    const isSaleLightsFormNext = !isSaleLightsDisabled && !isSaleLightForm;
    const submitButtonText = asIsFormNext || isSaleLightsFormNext ? t('next') : t('submit_to_auction');

    return (
      <FormDialogFooter className={style.footer}>
        <Button
          className={classnames(style.button)}
          onClick={() => {
            if (isAsIsForm) {
              this.setState({ asIs: false, isAsIsForm: false, errorMessages: [] });
            } else if (isSaleLightForm) {
              this.setState({
                isAsIsForm: isAsIsEnabled,
                isSaleLightForm: false,
              });
            } else {
              onPageChange(FormStepType.AUCTION_LIST);
            }
          }}
          theme="gray-outline"
        >
          <Sprite className={style.arrowSprite} glyph={arrowLeftGlyph} />
          {t('back')}
        </Button>
        <Button
          className={style.button}
          dataTestId="submitButton"
          disabled={this.state.isSaving}
          onClick={this.onNextPageRequest}
          theme="blue"
        >
          {this.state.isSaving ? <Spinner spinnerStyleClassName={style.spinnerStyle} /> : submitButtonText}
        </Button>
      </FormDialogFooter>
    );
  }

  render() {
    const { auctionFormatType } = this.props;
    const { auctionItemFormat, errorMessages, isAsIsForm, isSaleLightForm } = this.state;
    const isAppraisal = auctionItemFormat === AuctionItemFormat.APPRAISAL;
    const isGrounded = auctionItemFormat === AuctionItemFormat.GROUNDED;
    const isAuction = auctionItemFormat === AuctionItemFormat.AUCTION;
    const isTimedAuction = auctionFormatType === AuctionFormatType.TIMED;
    const isTimedOffer = auctionItemFormat === AuctionItemFormat.TIMED_OFFER;
    const hasAuctionType = isAuction || isAppraisal || isGrounded;

    const shouldRenderAuctionForm = hasAuctionType && !isAsIsForm && !isSaleLightForm;
    const shouldRenderAsIsForm = (hasAuctionType || isTimedOffer) && isAsIsForm && !isSaleLightForm;
    const shouldRenderTimedAuctionOptions = isTimedAuction && !isAsIsForm && !isSaleLightForm;
    const shouldRenderTimedOfferForm = isTimedOffer && !isAsIsForm && !isSaleLightForm;

    return (
      <>
        <FormDialogBody>
          <FormErrors errorMessages={errorMessages} />
          <FormSection flexDirection="column">
            {/**
             * TODO: [EB-10200] [WEB] Submit to Auction - Extracted Timed Auction form into it's own component
             * TODO: [EB-10201] [WEB] Submit to Auction - Extracted Live Auction form into it's own component
             **/}
            {shouldRenderTimedAuctionOptions && this.renderTimedAuctionFormatOptions()}
            {shouldRenderAuctionForm && this.renderAuctionForm()}
            {shouldRenderAsIsForm && this.renderAsIsForm()}
            {isSaleLightForm && this.renderSaleLightForm()}
            {shouldRenderTimedOfferForm && this.renderTimedOfferForm()}
          </FormSection>
        </FormDialogBody>
        {this.renderFooter()}
      </>
    );
  }
}

export default SubmitForm;
