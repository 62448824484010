import classnames from 'classnames';
import { memoize } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Loading from 'components/ui/loading/loading';
import RadioButtons, { RadioButtonLabel } from 'forms/shared/radioButtons';
import {
  AuctionTimeSlot,
  AuctionTimeSlotLane,
  BlackBookValueUpdateInput,
  InventoryItemValue,
  QueryauctionArgs,
  QueryinventoryItemValuesArgs,
} from 'store/shared/api/graph/interfaces/types';
import { AuctionData, FormStepType } from 'components/sections/auctionItem/operations/submitToAuction/dialog';
import {
  AuctionDates,
  AuctionDateType,
  AuctionFormatType,
} from 'components/sections/auctionItem/operations/submitToAuction/constants/submitToAuction';
import {
  AuctionDetails,
  AuctionSubmissionItem,
  AuctionSubmissionList,
} from 'store/auctionSubmission/auctionSubmissionModels';
import { Chevron } from 'components/ui/shared/chevrons';
import { DEFAULT_SOLD_WITHIN_DAYS, getMarketGuideMetadata } from 'store/marketGuide/marketGuideApi';
import { DateFormat } from 'constants/enums/dateAndTime';
import { FormDialogBody, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { blackBookValueTypes } from 'utils/formatting/blackBookFormatUtils';
import { formatDate } from 'utils/dateUtils';
import { getInventoryItemValues } from 'store/shared/api/graph/queries/inventoryItemValues';
import { inventoryItemValuesFetchBlackBookValues } from 'store/shared/api/graph/mutations/inventoryItemValuesFetchBlackBookValues';
import { isMarketGuideEnabled } from 'utils/featureFlagUtils';
import { joinStrings } from 'utils/stringUtils';
import {
  mapMarketGuideMetadataToInventoryItemValues,
  marketGuideValueTypes,
} from 'utils/formatting/marketGuideFormatUtils';
import { t, tPlural } from 'utils/intlUtils';

import style from './auctionList.scss';

export interface Props {
  /** The default auction format type. */
  defaultAuctionFormatType: AuctionFormatType | null;
  /** Function to get the upcoming timeslots of the selected auction */
  getUpcomingTimeSlots: (options: QueryauctionArgs) => Promise<void>;
  /** Intended auction time slot lane */
  intendedAuctionTimeSlotLane: Pick<AuctionTimeSlotLane, 'auctionTimeSlotId' | 'id' | 'name' | 'type'> | null;
  /** Inventory item id. */
  inventoryItemId: string;
  /** A collection of available auctions. */
  list: AuctionSubmissionList;
  /** Inventory make  */
  make: string | undefined;
  /** Inventory model  */
  model: string | undefined;
  /** Function invoked when user proceeds to next form step. */
  onPageChange: (page: FormStepType, data: AuctionData) => void;
  /** Inventory sub model  */
  subModel: string | undefined;
  /** Inventory year */
  year: number | undefined;
}

interface State {
  /** The auction details. */
  auction: AuctionDetails | null;
  /** Date details for the selected auction type. */
  auctionDates: AuctionDates;
  /** The auction format type. */
  auctionFormatType: AuctionFormatType | null;
  /** The values of the inventory item. */
  inventoryItemValues?: InventoryItemValue[];
  /** True when loading the inventory item values. */
  isFetchingInventoryItemValues: boolean;
}

/**
 * Returns vehicle count information
 */
const formatVehicleCountMessage = memoize((count: number) =>
  count > 0 ? tPlural('x_vehicles', count, [count]) : t('no_vehicles')
);

/**
 * Map and normalize auction time slots into auction date format
 */
export const mapTimeSlots = (timeSlots: AuctionTimeSlot[], auctionFormatType?: AuctionFormatType) =>
  timeSlots.map<AuctionDateType>((timeSlot) => {
    const isTimedOfferFormat = auctionFormatType === AuctionFormatType.TIMED_OFFER;
    const isTimedFormat = auctionFormatType === AuctionFormatType.TIMED;
    const timeSlotTime = isTimedOfferFormat || isTimedFormat ? timeSlot.finishTime : timeSlot.startTime;

    return {
      value: timeSlot.id,
      title: joinStrings(
        [
          formatDate(timeSlotTime, DateFormat.DATE_TIME_FORMAT),
          !isTimedOfferFormat && formatVehicleCountMessage(timeSlot.itemCount || 0),
        ].filter(Boolean),
        ' - '
      ),
    };
  });

class AuctionList extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      auction: null,
      auctionDates: [],
      auctionFormatType: props?.defaultAuctionFormatType ?? null,
      inventoryItemValues: undefined,
      isFetchingInventoryItemValues: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.auction === null || prevState.auction.id !== this.state.auction?.id) {
      const intendedAuction = this.getIntendedAuction();

      /**
       * Automatically set intended auction as default if no auction type is selected
       */
      if (intendedAuction && !this.state.auctionFormatType) {
        this.setState(
          {
            auctionFormatType: AuctionFormatType.LIVE,
          },
          () => this.onSelectAuction(intendedAuction)
        );
      }
    }
  }

  /**
   * Upon selecting an Auction to list to, check if the unit has any associated
   * Black Book values to it, so we can display them on the next screen.
   */
  getBlackBookValues = (options: BlackBookValueUpdateInput) =>
    inventoryItemValuesFetchBlackBookValues(options)?.then(
      (response) => response?.data?.data?.inventoryItemValuesFetchBlackBookValues ?? []
    );

  /**
   * Upon selecting an Auction to list to, check if the unit has any associated
   * values to it, so we can display them on the next screen.
   * Omit market guide and black book values, as they are handled separately.
   */
  getInventoryItemValues = (options: QueryinventoryItemValuesArgs) =>
    getInventoryItemValues(options)?.then((response) => {
      return (
        response?.data?.data?.inventoryItemValues?.filter(
          (value) => !marketGuideValueTypes.includes(value.type.id) && !blackBookValueTypes.includes(value.type.id)
        ) ?? []
      );
    });

  /**
   * Upon selecting an Auction to list to, check if the unit has any associated
   * marketguide values to it, so we can display them on the next screen.
   */
  getMarketGuideValues = async () => {
    const { make, model, subModel, year } = this.props;

    if (!isMarketGuideEnabled()) {
      return [];
    }

    const response = await getMarketGuideMetadata({
      make,
      model,
      soldWithinDays: DEFAULT_SOLD_WITHIN_DAYS,
      subModels: subModel ? [subModel] : undefined,
      yearGTE: year,
      yearLTE: year,
    });

    return mapMarketGuideMetadataToInventoryItemValues(response?.data.data?.marketGuideConnection.metadata);
  };

  getExpiryTimes = (auction: AuctionSubmissionItem) => {
    const { auctionFormatType } = this.state;

    switch (auctionFormatType) {
      case AuctionFormatType.LIVE:
        return mapTimeSlots(auction.node.upcomingAuctionTimeSlots?.filter(Boolean) || [], auctionFormatType);
      case AuctionFormatType.TIMED_OFFER:
        return mapTimeSlots(auction.node.upcomingTimedOfferTimeSlots?.filter(Boolean) || [], auctionFormatType);
      case AuctionFormatType.TIMED:
        return {
          appraisal: mapTimeSlots(auction.node.upcomingAppraisalTimeSlots?.filter(Boolean) || [], auctionFormatType),
          grounded: mapTimeSlots(auction.node.upcomingGroundedTimeSlots?.filter(Boolean) || [], auctionFormatType),
        };
      default:
        return {};
    }
  };

  onSelectAuction = async (item: AuctionSubmissionItem) => {
    const { getUpcomingTimeSlots, inventoryItemId, onPageChange } = this.props;
    const { auctionFormatType: auctionType } = this.state;

    // Fetch the time slots for the selected auction
    await getUpcomingTimeSlots({ auctionId: item.node.id });
    const auctionEdge = this.props.list?.find((edge) => edge.node.id === item.node.id);

    if (auctionType) {
      this.setState({ auctionDates: this.getExpiryTimes(auctionEdge!), auction: item.node }, () => {
        const options: QueryinventoryItemValuesArgs = {
          auctionId: item?.node?.id,
          inventoryItemId,
        };

        if (!this.state?.isFetchingInventoryItemValues) {
          this.setState({ isFetchingInventoryItemValues: true });
          Promise.allSettled([
            this.getBlackBookValues(options),
            this.getInventoryItemValues(options),
            this.getMarketGuideValues(),
          ])
            .then(([blackBookValues, inventoryItemValues, marketGuideValues]) => {
              this.setState(
                {
                  inventoryItemValues: [
                    ...(blackBookValues.status === 'fulfilled' ? blackBookValues.value : []),
                    ...(inventoryItemValues.status === 'fulfilled' ? inventoryItemValues.value : []),
                    ...(marketGuideValues.status === 'fulfilled' ? marketGuideValues.value : []),
                  ],
                },
                () => onPageChange(FormStepType.SUBMIT_FORM, this.state)
              );
            })
            .catch((error) => console.error(error))
            .finally(() => {
              this.setState({ isFetchingInventoryItemValues: false });
            });
        }
      });
    }
  };

  /**
   * Traverse through a list of auction connection node to look for auction that has a matching intended time slot
   */
  getIntendedAuction = () => {
    // Fetch the intended auction
    return this.props.list?.find(
      (auction) =>
        !!auction.node.upcomingAuctionTimeSlots?.find(
          (upcomingTimeSlot) => upcomingTimeSlot?.id === this.props.intendedAuctionTimeSlotLane?.auctionTimeSlotId
        )
    );
  };

  getAuctionLabels = (): RadioButtonLabel<AuctionFormatType>[] =>
    [
      { value: AuctionFormatType.TIMED, title: t('timed_auction') },
      { value: AuctionFormatType.LIVE, title: t('live_auction') },
      { value: AuctionFormatType.TIMED_OFFER, title: t('buy_now') },
    ].filter(Boolean);

  render() {
    const { auctionFormatType: auctionType, isFetchingInventoryItemValues } = this.state;
    const { list, defaultAuctionFormatType: defaultAuctionType } = this.props;

    return (
      <FormDialogBody className={style.formBody}>
        <FormSection className={style.formSection} dataTestId="auction-section" flexDirection="column">
          <p className={style.headerText}>{t('choose_auction_format')}</p>
          <RadioButtons
            className={style.radioButtons}
            id="formats"
            labels={this.getAuctionLabels()}
            onChange={(e) => this.setState({ auctionFormatType: e.target.value })}
            selectedOption={defaultAuctionType || undefined}
          />
          {(!list || isFetchingInventoryItemValues) && <Loading hasFullWidth isLoading isModalTransparent />}
          <div className={classnames(style.radioButtons, !auctionType && style.disabled)} data-testid="auction-type">
            <p className={style.headerText}>{t('choose_auction')}</p>
            <div>
              {list?.map((item, index) => (
                <Button
                  key={index}
                  className={style.auctionItem}
                  disabled={!auctionType}
                  onClick={() => this.onSelectAuction(item)}
                  theme="none"
                >
                  <div>{item.node.title}</div>
                  <Chevron />
                </Button>
              ))}
            </div>
          </div>
        </FormSection>
      </FormDialogBody>
    );
  }
}

export default AuctionList;
