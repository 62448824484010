import { chunk } from 'lodash-es';

import AddModifyFeeValue, {
  FeeField,
  FieldTypes,
} from 'components/sections/inventoryItem/details/feesAndValues/addModifyFeeValue';
import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import FeeValueItem, { FeeValueRow } from 'components/sections/inventoryItem/details/feesAndValues/feeValueItem';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { DetailsSection } from 'layouts/list/listLayout';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** The auction item details. */
  auctionItem?: AuctionItem;
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** True when item is an inventory item. */
  isInventoryItem?: boolean;
  /** The current user. */
  user: User;
}

interface State {
  /** True when dialog is open. */
  isFormDialogOpen: boolean;
  /** The selected fee. */
  selectedFee: FeeField | undefined;
}

class FeesLegacy extends BaseClass<Props, State> {
  static defaultProps = {
    isInventoryItem: true,
  };

  fees: FeeField[] = [
    { id: 'transportFee', name: 'transport_fee' },
    { id: 'mechanicalFee', name: 'mechanical_fee' },
    { id: 'reconditioningFee', name: 'reconditioning_fee' },
    { id: 'storageFee', name: 'storage_fee' },
    { id: 'redemptionFee', name: 'redemption_fee' },
    { id: 'marshallingFee', name: 'marshalling_fee' },
    { id: 'miscFee', name: 'misc_fee' },
    { id: 'registrationFee', name: 'registration_fee' },
    { id: 'fuelFee', name: 'fuel_fee' },
  ];

  state = {
    isFormDialogOpen: false,
    selectedFee: undefined,
  };

  render() {
    const { auctionItem, inventoryItem, isInventoryItem, user } = this.props;
    const { isFormDialogOpen, selectedFee } = this.state;

    const feeItems = this.fees.filter((fee) => inventoryItem?.inventoryItemFee?.[fee?.id]?.amount > 0);
    const feeItemsNotSet = this.fees.filter((fee) => !feeItems?.find(({ name }) => fee?.name === name));
    const hasNoFees = !feeItems?.length;
    const hasAllFees = feeItems?.length === this.fees?.length;
    const isStaffUser = isAuctionStaff(user);
    const isVisible =
      ![auctionItem?.status]?.includes(AuctionItemStatus.SOLD) &&
      (isStaffUser ||
        getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company.id || inventoryItem?.company?.id));

    if (!isVisible) {
      return null;
    }

    return (
      <>
        <DetailsSection
          isEmpty={hasNoFees}
          onClickSubButton={!hasAllFees ? () => this.setState({ isFormDialogOpen: true }) : null}
          subButtonDataTestId="addModifyFeesButton"
          subButtonType="add"
          title={hasNoFees ? t('no_x', [t('legacy_fees')]) : t('legacy_fees')}
        >
          {chunk(feeItems, 2)?.map((row) => (
            <FeeValueRow key={row?.[0]?.id}>
              {row?.map((fee) => (
                <FeeValueItem
                  key={fee?.id}
                  isUpdatable={isStaffUser}
                  item={{
                    id: fee?.id,
                    title: t(fee?.name),
                    amount: inventoryItem?.inventoryItemFee?.[fee?.id]?.amount,
                    amountFormatted: inventoryItem?.inventoryItemFee?.[fee?.id]?.formattedAmount,
                    note: inventoryItem?.inventoryItemFee?.[`${fee?.id}Text`],
                  }}
                  onEdit={() => this.setState({ isFormDialogOpen: true, selectedFee: fee })}
                />
              ))}
            </FeeValueRow>
          ))}
        </DetailsSection>

        {/* Form dialog */}
        <AddModifyFeeValue
          availableFields={feeItemsNotSet}
          fieldType={FieldTypes.FEE}
          inventoryItem={inventoryItem}
          isInventoryItem={isInventoryItem}
          isOpen={isFormDialogOpen}
          onClose={() => this.setState({ isFormDialogOpen: false, selectedFee: undefined })}
          selectedField={selectedFee}
        />
      </>
    );
  }
}

export default FeesLegacy;
