import classnames from 'classnames';
import { Link } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

import raf from 'utils/raf';
import {
  FacetLabel,
  FacetLabelGroup,
  SingleFacetGroupConfigItem,
} from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { Location } from 'constants/reactRouter';
import { facetLink } from 'components/ui/lists/filters/facetGroups/facetGroups';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';

import style from './radioFacetGroup.scss';

interface Props {
  /** The configuration information associated to the facet group. */
  configItem: SingleFacetGroupConfigItem;

  /** The facet group. */
  facetGroup: FacetLabelGroup | undefined;

  /** The label of the facet group. */
  label: string | undefined;

  /** The current url information. */
  location: Location;
}

const RadioFacetGroup = ({
  configItem: { canSave = true, values, countThemes = {}, isSummaryEnabled = true },
  location,
  facetGroup,
  label,
}: Props) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [changedFacets, setChangedFacets] = useState<string[]>([]);
  const previousFacets: FacetLabel[] | undefined = usePrevious(facetGroup?.facets);
  const includeSummary = useMemo(
    () => isSummaryEnabled && !values && !facetGroup?.facets?.find((facet) => facet.name.toLowerCase() === 'all'),
    [isSummaryEnabled, facetGroup, values]
  );

  /** Invoked when component mounts. */
  useMountEffect(() => setMounted(true));

  /** Update facet counts and show animation when counts update. */
  useEffect(() => {
    if (!previousFacets || !facetGroup) {
      return;
    }

    const { facets: facetsNext } = facetGroup;
    const facetCounts = previousFacets.reduce((counts, { name, count }) => ({ ...counts, [name]: count }), {});
    const updatedFacets = facetsNext
      ?.filter(({ name, count }) => facetCounts[name] !== count)
      .map((facet) => facet.name);

    // Set animation timeout when counts have changed.
    if (updatedFacets && updatedFacets.length > 0) {
      raf(() => {
        if (isMounted) {
          setChangedFacets([]);
        }
        raf(() => {
          if (isMounted) {
            setChangedFacets(updatedFacets);
          }
        });
      }, 1000);
    }
  }, [facetGroup, isMounted, previousFacets]);

  if (!facetGroup) {
    return null;
  }

  return (
    <div className={style.facetGroup} data-testid={`${facetGroup.name}-radio-facet-group`}>
      <div className={style.heading}>{label || facetGroup.name}</div>
      <ul>
        {includeSummary && (
          <li className={style.facet}>
            <Link className={style.name} to={facetLink(facetGroup.name, location, null, false, canSave)}>
              <div
                className={classnames(
                  style.radio,
                  facetGroup.facets?.reduce((selected, f) => selected && !f.selected, true) && style.selected
                )}
              />
              {t('all')}
            </Link>
            <span className={style.count}>{facetGroup.facets?.reduce((sum, f) => sum + f.count, 0)}</span>
          </li>
        )}
        {facetGroup.facets?.map(({ name, facetLabel, count, selected, trackUserAction }) => (
          <li key={name} className={classnames(style.facet, { [style.changed]: changedFacets.includes(name) })}>
            <Link
              className={style.name}
              data-testid={`${facetGroup.name}-${name}`}
              onClick={trackUserAction}
              to={facetLink(facetGroup.name, location, name, false, canSave)}
            >
              <div className={classnames(style.radio, selected && style.selected)} />
              <div>{facetLabel}</div>
            </Link>
            {count !== -1 && (
              <span
                className={classnames(
                  style.count,
                  style[`count${count}`],
                  countThemes[name] && style[`theme${countThemes[name]}`]
                )}
              >
                {count}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RadioFacetGroup;
