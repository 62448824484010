import { createAction } from 'redux-actions';

import { Broadcast } from 'store/shared/api/graph/interfaces/types';
import { broadcastsListUpdateItem } from 'store/admin/broadcasts/list/broadcastsActions';
import {
  broadcastCreate,
  broadcastUpdate,
  getBroadcastDetails,
} from 'store/admin/broadcasts/details/broadcastDetailsApi';

export const broadcastDetailsCleared = createAction('ADMIN_BROADCAST_DETAILS_CLEARED');
export const broadcastDetailsIsLoading = createAction('ADMIN_BROADCAST_DETAILS_LOADING');
export const broadcastDetailsLoaded = createAction('ADMIN_BROADCAST_DETAILS_LOADED');
export const broadcastDetailsUpdated = createAction('ADMIN_BROADCAST_DETAILS_UPDATED');
export const broadcastDetailsUpdating = createAction('ADMIN_BROADCAST_DETAILS_UPDATING');
export const preloadBroadcastDetails = createAction('ADMIN_BROADCAST_DETAILS_PRELOADED');
export const setBroadcastData = createAction('ADMIN_BROADCAST_DETAILS_SETTING_DATA');

export const processGetBroadcastDetails = (options, dispatch) => {
  dispatch(broadcastDetailsIsLoading());
  return getBroadcastDetails(options).then((response) => {
    dispatch(broadcastDetailsLoaded(response?.data?.data?.broadcast));
  });
};

export const processAddModifyBroadcast = (options, dispatch) => {
  const isAdding = !options?.broadcastId;
  const saveFunc = isAdding ? broadcastCreate : broadcastUpdate;
  const mutationName = isAdding ? 'broadcastCreate' : 'broadcastUpdate';

  return saveFunc(options).then((response) => {
    const formattedResponse: Broadcast = response?.data?.data?.[mutationName];
    if (!isAdding) {
      dispatch(broadcastDetailsLoaded(formattedResponse));
    }
    dispatch(broadcastsListUpdateItem(formattedResponse));
    return formattedResponse;
  });
};
