import classnames from 'classnames';
import { ReactNode } from 'react';

import chevronGlyph from 'glyphs/chevron.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';

import style from './collapsiblePanel.scss';

class CollapsiblePanel extends BaseClass<
  {
    bodyClassName?: string;
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    headingChildren: ReactNode;
    headingClassName?: string;
  },
  { isOpen: boolean }
> {
  state = { isOpen: false };

  render() {
    const { bodyClassName, children, className, disabled, headingChildren, headingClassName } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classnames(style.container, className, { [style.isOpen]: isOpen })}>
        <Button
          className={classnames(style.toggle, headingClassName)}
          disabled={disabled}
          onClick={() => this.setState({ isOpen: !isOpen })}
          theme="none"
        >
          {headingChildren}
          {!disabled && <Sprite className={style.chevron} glyph={chevronGlyph} />}
        </Button>
        <div className={classnames(style.content, bodyClassName)}>{children}</div>
      </div>
    );
  }
}

export default CollapsiblePanel;
