import BaseClass from 'components/ui/shared/base';
import SlideOut from 'components/ui/slideOuts/slideOut';
import User from 'constants/user';
import {
  ListItem,
  ListItemBody,
  ListItemWithUserImage,
  PrimaryTitle,
  SecondaryTitleLight,
  TertiaryTitleLight,
} from 'layouts/list/listItemLayout';
import { Chevron } from 'components/ui/shared/chevrons';
import { AuctionRepresentative } from 'store/shared/api/graph/interfaces/types';
import { formatUserName } from 'utils/stringUtils';
import { hasSystemPermission } from 'utils/userUtils';

import style from './repSlideOut.scss';

interface RepProps {
  /** An auction representative. */
  rep: AuctionRepresentative;

  /** The current user. */
  user: User;
}

const Rep = ({ rep, user }: RepProps) => {
  const isLink = hasSystemPermission(user, ['SYSTEM_VIEW_ALL_USERS']);

  return (
    <ListItem className={style.rep} isLink={isLink} to={`/admin/users?id=${rep?.user?.id}`}>
      <ListItemBody className={style.repInner}>
        <ListItemWithUserImage imageUrl={rep?.user?.photoUrl || undefined} tagName="div">
          <PrimaryTitle className={style.primaryTitle}>{formatUserName(rep?.user)}</PrimaryTitle>
          <SecondaryTitleLight className={style.secondaryTitle}>
            {rep?.representativeType?.name || rep?.user?.title}
            <span> • {rep?.user?.cellPhone || rep?.user?.secondaryPhone}</span>
          </SecondaryTitleLight>
          <TertiaryTitleLight>{rep?.user?.email}</TertiaryTitleLight>
          {isLink && <Chevron className={style.chevron} />}
        </ListItemWithUserImage>
      </ListItemBody>
    </ListItem>
  );
};

interface Props {
  /** True when the slide out is open. */
  isOpen: boolean;

  /** Function invoked when slide out is closed. */
  onClose?: () => void;

  /** A collection of auction representatives.  */
  reps?: AuctionRepresentative[];

  /** The title of the slide out/ */
  title: string;

  /** The current user. */
  user: User;
}

class RepsSlideOut extends BaseClass<Props> {
  static defaultProps = {
    onClose: () => {},
  };

  render() {
    const { isOpen, onClose, reps, title, user } = this.props;

    return (
      <SlideOut headerClassName={style.slideOutHeader} isOpen={isOpen} onClose={onClose} title={title}>
        <ul>{reps?.map((rep, index) => <Rep key={`${rep?.user?.id}-${index}`} rep={rep} user={user} />)}</ul>
      </SlideOut>
    );
  }
}

export default RepsSlideOut;
