import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import closeGlyph from 'glyphs/close.svg';
import fileGlyph from 'glyphs/file.svg';
import rightArrowGlyph from 'glyphs/right-arrow.svg';

import Button from 'components/ui/shared/button';
import NoteContent from 'components/ui/shared/notes/noteContent';
import NotesConfirmDialog from 'components/ui/shared/notes/notesConfirmDialog';
import Select from 'forms/shared/select';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch, AppState } from 'store/configureStore';
import { DateFormat } from 'constants/enums/dateAndTime';
import { InventoryItem } from 'store/shared/api/graph/interfaces/types';
import { MutationAddInventoryItemNote } from 'store/inventoryItemDetails/inventoryItemDetailsApi';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { formatDate } from 'utils/dateUtils';
import { formatUserName } from 'utils/stringUtils';
import { getEnabledCompanyRelationships } from 'utils/userUtils';
import { getMultiSelectProps } from 'utils/selectUtils';
import { processAddInventoryItemNote } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './inventoryItemNotes.scss';

const stateConnect = (state: AppState) => ({
  /** Currently selected companies to send notes to. */
  currentNotesCompanies: state.app.notes?.currentNotesCompanies,
  /** Current logged in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback to add a note to the inventory item. */
  addNote: (options: MutationAddInventoryItemNote) => processAddInventoryItemNote(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface InventoryItemNotesProps extends ConnectedProps<typeof connector> {
  /** The inventory item id that the notes belongs to. */
  inventoryItemId: InventoryItem['id'];
  /**
   * Whether the item is an auction item or not.
   * @default false
   */
  isAuctionItem?: boolean;
  /** The inventory item notes. */
  notes: InventoryItem['notes'];
  /** Callback to handle when the notes overlay is closed. */
  onClose?: () => void;
}

const InventoryItemNotes = ({
  addNote,
  currentNotesCompanies,
  inventoryItemId,
  isAuctionItem = false,
  notes,
  onClose,
  user,
}: InventoryItemNotesProps) => {
  const [selectedCompanies, setSelectedCompanies] = useState<SelectOption[]>(currentNotesCompanies);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [selectCompanyErrorMessage, setSelectCompanyErrorMessage] = useState<string | undefined>(undefined);

  const noteInputRef = useRef<HTMLInputElement>(null);
  const noteListRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    const noteList = noteListRef.current;
    if (noteList) {
      noteList.scrollTop = noteList.scrollHeight;
    }
  }, [noteListRef]);

  useEffect(() => {
    scrollToBottom();
  }, [notes, scrollToBottom]);

  const reversedNotes = useMemo(() => [...(notes || [])].reverse(), [notes]);

  const companyOptions = useMemo(() => {
    return getEnabledCompanyRelationships(user)?.map((userCompanyRelationship) => ({
      label: userCompanyRelationship.company.name,
      value: userCompanyRelationship.company.id,
    }));
  }, [user]);

  const onSubmit = useCallback(
    (shouldSubmit: boolean = false, shouldValidate: boolean = true) => {
      const noteInputValue = noteInputRef.current?.value?.trim();

      if (!selectedCompanies?.length) {
        setSelectCompanyErrorMessage(t('please_select_company'));
        return;
      }

      if (noteInputValue) {
        if (shouldValidate && selectedCompanies?.length > 1) {
          setConfirmDialogOpen(true);
          return;
        }

        if (shouldSubmit && inventoryItemId) {
          setSubmitting(true);
          const options: MutationAddInventoryItemNote = {
            isAuctionItem,
            inventoryItemId,
            content: noteInputValue,
            companies: selectedCompanies,
          };
          addNote(options)
            .then(() => {
              if (noteInputRef.current?.value) {
                noteInputRef.current.value = '';
              }
            })
            .finally(() => setSubmitting(false));
        }
      }
    },
    [selectedCompanies, isAuctionItem, inventoryItemId, addNote]
  );

  const noteConfirmDialog = useMemo(
    () => (
      <NotesConfirmDialog
        companyNames={selectedCompanies?.map((company) => company?.label)}
        isOpen={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={(confirm: boolean) => {
          setConfirmDialogOpen(false);
          onSubmit(confirm, false);
        }}
      />
    ),
    [onSubmit, isConfirmDialogOpen, selectedCompanies]
  );

  return (
    <div className={style.notes} data-testid="notes-container">
      <div className={style.header} data-testid="notes-header">
        {t('notes')}
        <Button className={style.closeButton} dataTestId="close-notes-button" onClick={onClose} theme="none">
          <Sprite className={style.sprite} glyph={closeGlyph} />
        </Button>
      </div>
      <div ref={noteListRef} className={style.body}>
        {reversedNotes?.length > 0 ? (
          <ul className={style.noteList} data-testid="notes-list">
            {reversedNotes.map((note, index) => (
              <li key={`note-list-item-${index}`} className={style.noteListItem}>
                <div>
                  <div className={style.noteListItemContent}>
                    <NoteContent content={note?.content || ''} />
                  </div>
                  <p className={style.noteListItemAuthor}>
                    {formatUserName(note?.createdBy)} •{' '}
                    {formatDate(
                      note?.created,
                      `${DateFormat.MONTH_DAY_YEAR_FORMAT} [${t('at')}] ${DateFormat.TIME_FORMAT}`
                    )}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className={style.emptyNoteList} data-testid="notes-list-empty">
            <div className={style.emptyNoteListInner}>
              <Sprite glyph={fileGlyph} />
              <h4>{t('no_inventory_notes_message')}</h4>
            </div>
          </div>
        )}
      </div>
      <div className={style.footer}>
        <form autoComplete="off" className={style.form}>
          <div className={style.selectCompanyContainer}>
            <Select
              hasError={!!selectCompanyErrorMessage}
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  return;
                }

                setSelectedCompanies(value);
                if (value?.length) {
                  setSelectCompanyErrorMessage(undefined);
                }
              }}
              options={companyOptions}
              placeholder={t('choose_companies')}
              {...getMultiSelectProps()}
              id="notes-select-company"
              value={selectedCompanies}
            />
          </div>
          <div className={style.formRow}>
            <input
              ref={noteInputRef}
              autoComplete="off"
              className={style.formInput}
              data-testid="notes-input"
              name="note"
              placeholder={t('enter_notes')}
              type="text"
            />
            <Button
              className={style.formSubmit}
              dataTestId="notes-submit-button"
              disabled={isSubmitting}
              onClick={() => onSubmit(true)}
              theme="none"
            >
              {isSubmitting ? <SpinnerCentered theme="white" /> : <Sprite glyph={rightArrowGlyph} />}
            </Button>
          </div>
        </form>
      </div>
      {noteConfirmDialog}
    </div>
  );
};

export default connector(InventoryItemNotes);
