import AuctionItemType from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';

interface AuctioneerModel {
  /** The auction item currently being auctioned */
  auctionItem: AuctionItemType;
  /** Current auction time slot lane information. */
  auctionTimeSlotLane: AuctionTimeSlotLane;
  /** List of upcoming auction items. */
  upcomingItems: AuctionItemType[];
}

export type AuctioneerProps = ServerRecordOf<AuctioneerModel>;

export const InitialState = ServerRecord<AuctioneerModel>({
  auctionItem: null,
  auctionTimeSlotLane: null,
  upcomingItems: [],
});
