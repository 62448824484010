import { useState } from 'react';
import classnames from 'classnames';

import Button from 'components/ui/shared/button';
import InputGroup from 'forms/shared/inputGroup';

import style from './currencyToggle.scss';

type CurrencyInputType = 'AMOUNT' | 'PERCENTAGE';

interface Props {
  /** Label for the input */
  label?: string;
  /** Callback when input value changes */
  onChange?: (amount: number) => void;
  /** Callback when type changes */
  onTypeChange?: (type: CurrencyInputType) => void;
  /** Placeholder text for the input */
  placeholder?: string;
  /** Type of currency */
  type?: CurrencyInputType;
  /** Value of the input */
  value?: number;
}

const CurrencyToggle = ({
  label,
  onChange = () => {},
  onTypeChange = () => {},
  placeholder = 'Enter Amount',
  type = 'AMOUNT',
  value,
}: Props) => {
  const [activeType, setActiveType] = useState<CurrencyInputType>(type);

  return (
    <div className={classnames(style.inputContainer)}>
      <InputGroup
        dataTestId="currency-input"
        defaultValue={value}
        hasMargin={false}
        label={label}
        name="amount"
        onChange={onChange}
        placeholder={`${activeType === 'AMOUNT' ? '$' : '%'}   ${placeholder}`}
        type="number"
      />
      <Button
        className={classnames(style.dollar, !!label && style.hasLabel)}
        onClick={() => {
          setActiveType('AMOUNT');
          onTypeChange('AMOUNT');
        }}
        theme={activeType === 'AMOUNT' ? 'blue' : 'gray-outline'}
      >
        $
      </Button>
      <Button
        className={classnames(style.percent, !!label && style.hasLabel)}
        onClick={() => {
          setActiveType('PERCENTAGE');
          onTypeChange('PERCENTAGE');
        }}
        theme={activeType === 'PERCENTAGE' ? 'blue' : 'gray-outline'}
      >
        %
      </Button>
    </div>
  );
};

export default CurrencyToggle;
