import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { MutationauctionItemIfBidCounterArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { isAuctionStaff } from 'utils/userUtils';
import { processAuctionItemIfBidCounter } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const stateConnect = (state: AppState) => ({
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to set if bid counter. */
  ifBidCounter: (options: MutationauctionItemIfBidCounterArgs) => processAuctionItemIfBidCounter(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item information. */
  auctionItem: AuctionItem;
  /** Callback function to trigger on done. */
  onDone?: (options: boolean) => void;
}

interface State {
  /** Error messages. */
  errorMessages: ErrorMessages | null;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Whether is submitting or not. */
  isSubmitting: boolean;
}

class CounterOffer extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { errorMessages: null, isOpen: false, isSubmitting: false };
  }

  onSubmit = (shouldSubmit: boolean = false, options: Partial<MutationauctionItemIfBidCounterArgs> = {}) => {
    const {
      auctionItem,
      auctionItem: { id },
      ifBidCounter,
      onDone,
    } = this.props;

    if (shouldSubmit) {
      this.setState({ isSubmitting: true });

      trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_COUNTER_CONFIRM_CLICK, auctionItem);

      ifBidCounter?.({ auctionItemId: id, ...options })
        ?.then(() => {
          this.setState({ errorMessages: null, isOpen: false, isSubmitting: false });
          onDone?.(true);
        })
        ?.catch(this.onApiError)
        ?.finally(() => this.setState({ isSubmitting: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
      onDone?.(false);
    }
  };

  render() {
    const { auctionItem, isStaffUser } = this.props;

    return [
      <OperationButton
        key="button"
        dataTestId="counterOfferButton"
        onClick={() => {
          this.setState({ isOpen: true });
          trackUserActionWithAuctionItemAttributes(UserAction.VDP_IF_BID_COUNTER_CLICK, auctionItem);
        }}
        theme={undefined}
      >
        {t('counter_offer')}
      </OperationButton>,
      <Dialog
        key="dialog"
        auctionItem={auctionItem}
        isStaffUser={isStaffUser}
        onSubmit={this.onSubmit}
        {...this.state}
      />,
    ];
  }
}

export default connector(CounterOffer);
