import classnames from 'classnames';
import { useMemo } from 'react';

import InteractiveText from 'components/ui/shared/interactiveText';
import { t } from 'utils/intlUtils';

import style from './footerSupportText.scss';

interface Props {
  /** True if the top margin should be larger than the default */
  hasTopMargin?: boolean;
  /** True when the short-formed text label variant should be displayed */
  isShortForm?: boolean;
}

const FooterSupportText = ({ hasTopMargin, isShortForm }: Props) => {
  const text = useMemo(() => {
    return t(isShortForm ? 'contact_us_message_web' : 'registration_info_message');
  }, [isShortForm]);

  return (
    <div className={classnames(style.registrationInfo, hasTopMargin && style.increasedTopMargin)}>
      <InteractiveText linkClassName={style.link}>{text}</InteractiveText>
    </div>
  );
};

export default FooterSupportText;
