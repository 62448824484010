import classnames from 'classnames';
import { useCallback, useMemo } from 'react';

import UserImage from 'components/ui/shared/images/userImage';
import { AutoBid, AutoBids, User } from 'store/shared/api/graph/interfaces/types';
import { ListItem, ListItemBody, SecondaryTitle, TertiaryTitle } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { formatCalendarTime } from 'utils/dateUtils';
import { formatUserName } from 'utils/stringUtils';
import { hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './autobids.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: {
    /** The auto-bids on this vehicle. */
    autoBids: AutoBids;
    /** The current user details. */
    user: User;
  };
}

const InventoryItemDetailsAutobids = ({ inventoryDetailsData }: Props) => {
  const { autoBids, user } = inventoryDetailsData;

  /**
   * Check if the current user has permission to view users.
   */
  const hasPermission = useMemo(() => hasSystemPermission(user, ['SYSTEM_VIEW_USERS']), [user]);

  /**
   * Render list item body.
   */
  const renderListItemBody = useCallback((autobid: AutoBid) => {
    return (
      <ListItemBody className={style.body}>
        <UserImage imageUrl={autobid?.createdBy?.photoUrl || undefined} />
        <div className={style.details}>
          <SecondaryTitle>{autobid?.company?.name}</SecondaryTitle>
          <TertiaryTitle dataTestId="auto-bid-user">
            {autobid?.actionBy
              ? t('x_on_behalf_of_x', [formatUserName(autobid?.actionBy), formatUserName(autobid?.createdBy)])
              : formatUserName(autobid?.createdBy)}
          </TertiaryTitle>
          <TertiaryTitle className={style.createdDate} dataTestId="auto-bid-created">
            {`${formatCalendarTime(autobid?.created)} - `}
            <span
              className={classnames({
                [style.active]: autobid.active,
                [style.inactive]: !autobid.active,
              })}
            >
              {autobid.active ? t('active') : t('inactive')}
            </span>
          </TertiaryTitle>
        </div>
        <div className={classnames(style.price)}>{autobid?.maxBid?.formattedAmountRounded}</div>
      </ListItemBody>
    );
  }, []);

  return (
    <div className={style.summarySection}>
      <ul>
        {autoBids?.list?.filter(Boolean)?.map((autobid) => (
          <ListItem
            key={`autobid-item-${autobid?.id}`}
            className={style.listItem}
            isLink={hasPermission}
            to={`${Route.ADMIN_USERS}?id=${autobid?.createdBy?.id}`}
          >
            {renderListItemBody(autobid)}
          </ListItem>
        ))}
      </ul>
    </div>
  );
};

export default InventoryItemDetailsAutobids;
