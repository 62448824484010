import classnames from 'classnames';

import MiniButton from 'components/ui/shared/buttons/miniButton';

import style from './systemBanner.scss';

interface Props {
  /** The button text */
  buttonText: string;
  /** The styles to optionally override the defaults */
  className?: string;
  /** Function invoked when button is clicked */
  onButtonClick?: () => void;
  /** The banner text */
  text: string;
  /** The banner colour theme */
  theme?: 'red';
}

const SystemBanner = ({ buttonText, className, onButtonClick, text, theme = 'red' }: Props) => {
  return (
    <div className={classnames(style.container, style[`theme-${theme}`], className)}>
      <div>{text}</div>
      <MiniButton className={style.button} onClick={onButtonClick} theme="red">
        {buttonText}
      </MiniButton>
    </div>
  );
};

export default SystemBanner;
