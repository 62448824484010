import classnames from 'classnames';
import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { isEqual } from 'lodash-es';

import BaseClass from 'components/ui/shared/base';
import { Location } from 'store/shared/api/graph/interfaces/types';
import { onMapsApiLoaded } from 'utils/mapUtils';

import styleVariables from 'styles/variables.scss';
import style from './map.scss';

// Inline SVG for custom Google Map marker icon
const markerIcon =
  '<svg width="22" height="26" viewBox="-1 0 22 25" xmlns="http://www.w3.org/2000/svg">' +
  '  <path d="M10,0 C4.5,0 0,4.33333333 0,9.66666667 C0,11.7777778 0.722222222,13.7777778 2,15.4444444 L9.55555556,25.3333333 C9.72222222,25.5555556 10.1111111,25.6111111 10.3333333,25.4444444 C10.3888889,25.3888889 10.3888889,25.3888889 10.4444444,25.3333333 L18,15.4444444 C19.2777778,13.7777778 20,11.7777778 20,9.66666667 C20,4.33333333 15.5,0 10,0 Z M9.22222222,13.4444444 C7.11111111,13 5.72222222,11 6.16666667,8.88888889 C6.61111111,6.77777778 8.61111111,5.38888889 10.7222222,5.83333333 C12.2777778,6.16666667 13.5,7.33333333 13.7777778,8.88888889 C14.1666667,11 12.7777778,13.0555556 10.7222222,13.4444444 C10.2222222,13.5555556 9.72222222,13.5555556 9.22222222,13.4444444 Z"/>' +
  '</svg>';

/**
 * @param {object} location - Assumes `latitude` and `longitude` are attached.
 */
export const openMapLink = (location) => {
  if (location) {
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
    window.open(mapUrl, '_blank');
  }
};

export type MapLocation = Pick<Location, 'latitude' | 'longitude'> & {
  /** The color of the map marker for this map coordinate. */
  color?: string;

  /** Details that will be displayed when marker is clicked. */
  info?: ReactElement;
};

interface Props {
  /** The className to overwrite default styles. */
  className?: string;

  /** Test ID for the map */
  dataTestId?: string;

  /** True when the Map is draggable. */
  draggable?: boolean;

  /** True when the map can be clicked to open the map in a new browser tab. */
  isClickEnabled?: boolean;

  /** The map coordinates to display on the map. */
  locations: MapLocation[];

  /** The maximum zoom level which will be displayed on the map. */
  maxZoom?: number;

  /** The minimum zoom level which will be displayed on the map. */
  minZoom?: number;

  /** The initial Map zoom level. */
  zoom?: number;

  /** The enabled/disabled state of the Zoom control. */
  zoomControl?: boolean;
}

class Map extends BaseClass<Props> {
  private infoWindow: google.maps.InfoWindow | null;
  private map: google.maps.Map | null;
  private mapBounds: google.maps.LatLngBounds | null;
  private mapMarkers: google.maps.marker.AdvancedMarkerElement[];
  private mapNode: HTMLElement | null;
  private mapsApi: typeof google.maps | null;
  private timeoutId: number | null;

  static defaultProps = {
    isClickEnabled: true,
    draggable: false,
    locations: [],
    zoom: 13,
    zoomControl: false,
  };

  constructor(props) {
    super(props);

    this.map = null;
    this.mapNode = null;
    this.mapsApi = null;
    this.mapBounds = null;
    this.infoWindow = null;
    this.mapMarkers = [];
  }

  componentDidUpdate(prevProps) {
    const { locations } = prevProps;
    const { locations: locationsNext } = this.props;

    const hasChanged = !isEqual(locations, locationsNext);
    const isValid = locations.every(({ latitude, longitude }) => latitude && longitude);
    const coordinates = { lat: locationsNext[0]?.latitude, lng: locationsNext[0]?.longitude };

    if (hasChanged && isValid && this.map) {
      this.clearMarkers();
      this.setMarkers();

      if (locations.length === 1) {
        this.map.setCenter(coordinates);
      }
    }
  }

  onOpenMap = () => {
    const { locations, isClickEnabled } = this.props;

    if (isClickEnabled && locations.length === 1) {
      openMapLink(locations[0]);
    }
  };

  setMarkers() {
    const { locations } = this.props;
    const mapsApi = this.mapsApi;

    if (mapsApi) {
      this.mapBounds = new mapsApi.LatLngBounds();
      locations.forEach((location) => {
        // Create a marker icon
        const parser = new DOMParser();
        const markerElement = parser.parseFromString(markerIcon, 'image/svg+xml').documentElement;
        // Apply color to the marker icon
        markerElement.setAttribute('fill', location.color || styleVariables.colorRed);
        markerElement.setAttribute('stroke', location.color || styleVariables.colorRed);

        const marker = new mapsApi.marker.AdvancedMarkerElement({
          position: new mapsApi.LatLng(location.latitude, location.longitude),
          map: this.map,
          content: markerElement,
        });

        if (location.info) {
          const contentElement = document.createElement(`div`);
          const root = createRoot(contentElement);
          root.render(location.info);
          mapsApi.event.addListener(marker, 'click', () => {
            this.infoWindow?.setContent(contentElement);
            this.infoWindow?.setOptions({ headerDisabled: true });
            this.infoWindow?.open(this.map, marker);
          });
        }

        const markerPosition = marker.position;
        if (markerPosition) {
          this.mapBounds?.extend(markerPosition);
        }

        this.mapMarkers.push(marker);
      });

      if (locations.length > 1) {
        this.map?.fitBounds(this.mapBounds);
      }
    }
  }

  clearMarkers() {
    this.mapMarkers.forEach((marker) => {
      const markerElement = marker;
      markerElement.map = null;
    });
    this.mapMarkers = [];
  }

  initMap = (mapNode) => {
    const { draggable, locations, isClickEnabled, zoom, zoomControl, minZoom, maxZoom } = this.props;
    const coordinates = { lat: locations[0]?.latitude, lng: locations[0]?.longitude };

    if (!mapNode || !coordinates.lat || !coordinates.lng) {
      this.map = null;
      this.mapsApi = null;
      this.clearMarkers();
      this.refresh(mapNode || this.mapNode);
      return;
    }

    this.mapNode = mapNode;
    onMapsApiLoaded((mapsApi) => {
      this.mapsApi = mapsApi;
      this.mapBounds = new mapsApi.LatLngBounds();
      this.infoWindow = new mapsApi.InfoWindow();

      this.map = new mapsApi.Map(mapNode, {
        center: coordinates,
        clickableIcons: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        draggable,
        draggableCursor: isClickEnabled ? 'pointer' : 'default',
        draggingCursor: 'pointer',
        fullscreenControl: false,
        keyboardShortcuts: false,
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
        mapTypeControl: false,
        mapTypeId: 'roadmap',
        maxZoom,
        minZoom,
        overviewMapControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoom,
        zoomControl,
      });

      if (this.map) {
        this.mapsApi?.event.addListener(this.map, 'click', () => {
          this.infoWindow?.close();
        });
      }
      this.setMarkers();
    });
  };

  refresh(mapNode) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      this.timeoutId = null;
      this.initMap(mapNode);
    }, 250);
  }

  render() {
    const { className, dataTestId } = this.props;

    return (
      <div
        ref={this.initMap}
        className={classnames(style.map, className)}
        data-chromatic="ignore"
        data-testid={dataTestId}
        onClick={this.onOpenMap}
        onKeyDown={() => {}}
        role="presentation"
      />
    );
  }
}

export default Map;
