import classnames from 'classnames';

import Check from 'glyphs/check.svg';

import Sprite from 'components/ui/shared/sprite';

import style from './check.scss';

interface CheckMdProps {
  className?: string;
}

/**
 * Check Medium
 */
export const CheckMd = ({ className }: CheckMdProps) => {
  return <Sprite className={classnames(style.checkMd, className)} glyph={Check} />;
};

interface CheckSmProps {
  className?: string;
}

/**
 * Check Small
 */
export const CheckSm = ({ className }: CheckSmProps) => {
  return <Sprite className={classnames(style.checkSm, className)} glyph={Check} />;
};

interface CheckXsProps {
  className?: string;
}

/**
 * Check Extra-Small
 */
export const CheckXs = ({ className }: CheckXsProps) => {
  return <Sprite className={classnames(style.checkXs, className)} glyph={Check} />;
};

/**
 * Check Rounded
 */
export const CheckRounded = ({ className }: CheckXsProps) => {
  return <Sprite className={classnames(style.checkRounded, className)} glyph={Check} />;
};
