import { MouseEventHandler, useState } from 'react';

import AuctionItemsListItemFooter from 'components/sections/auctionItem/list/auctionItemsListItemFooter';
import BiddingAsSelector from 'components/sections/admin/selectConsigner/biddingAsSelector';
import Button, { ButtonTheme } from 'components/ui/shared/button';
import IncrementButtons from 'components/ui/shared/buttons/incrementButtons';
import InventoryItem from 'constants/inventoryItem';
import ListItemHeader from 'components/ui/lists/listItem/listItemHeader';
import { AuctionItem, User } from 'store/shared/api/graph/interfaces/types';
import { AuctionItemPropsJs } from 'store/auctionItemsList/auctionItemsModels';
import { SpinnerCentered } from 'components/ui/loading/loading';
import { isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './listItemOverlay.scss';

interface Props {
  /** The auction item. */
  auctionItem: AuctionItemPropsJs;
  /** The bid amount. */
  bidAmount: number | undefined;
  /** The `data-testid` attribute, used for testing */
  dataTestId?: string;
  /** The inventory item. */
  inventoryItem: InventoryItem;
  /** True when the confirm process is active */
  isAnimating?: boolean;
  /** True when the bidding as selector should be enabled */
  isBiddingEnabled?: boolean;
  /** True when custom bid increments should be disabled */
  isCustomBiddingDisabled?: boolean;
  /** The text label to optionally replace the Bidding As component; use one or the other */
  label?: string;
  /** Function invoked for auxiliary label. */
  onAuxLabelClick?: () => void;
  /** Function invoked on cancel click. */
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  /** Function invoked on confirm click. */
  onConfirm: (bidAmount?: number) => void;
  /** The text to override the default button text */
  onConfirmText?: string;
  /** The button theme of the confirm button */
  onConfirmTheme?: ButtonTheme;
  /** The logged-in user */
  user: User;
}

const ListItemOverlay = ({
  auctionItem,
  bidAmount,
  dataTestId = 'auction-item-list-confirm-overlay',
  inventoryItem,
  isAnimating,
  isBiddingEnabled,
  isCustomBiddingDisabled,
  label,
  onAuxLabelClick,
  onCancel,
  onConfirm,
  onConfirmText = t('confirm'),
  onConfirmTheme = 'green',
  user,
}: Props) => {
  const isGroupManager = isGroupManagerRole(user);
  const isStaffUser = isAuctionStaff(user, auctionItem?.auction?.id);
  const canSelectConsigner = isGroupManager || isStaffUser;

  // Custom bid amount
  const [nextBidAmount, setNextBidAmount] = useState<number>(bidAmount ?? 0);

  return (
    <div
      className={style.container}
      data-testid={dataTestId}
      onClick={(e) => e.preventDefault?.()}
      onKeyDown={undefined}
      role="presentation"
    >
      <div className={style.containerInner}>
        <ListItemHeader
          auctionItem={auctionItem}
          inventoryItem={inventoryItem}
          onAuxLabelClick={onAuxLabelClick}
          user={user}
        />
        <div className={style.body}>
          {label && <label className={style.label}>{label}</label>}
          {isBiddingEnabled && (
            <BiddingAsSelector
              auctionId={auctionItem?.auction?.id}
              className={style.biddingAs}
              isSelectEnabled={canSelectConsigner}
              onCancel={onCancel}
            />
          )}
          <IncrementButtons
            disabled={isCustomBiddingDisabled}
            furtherBidIncrement={auctionItem?.furtherBidIncrement?.amount ?? 100}
            onChange={(nextValue: number) => setNextBidAmount(nextValue)}
            value={nextBidAmount}
          />
          <div className={style.buttons}>
            <Button className={style.button} disabled={isAnimating} onClick={onCancel} theme="gray-outline">
              {t('cancel')}
            </Button>
            <Button
              className={style.button}
              disabled={isAnimating}
              onClick={() => onConfirm(nextBidAmount)}
              theme={onConfirmTheme}
            >
              {isAnimating ? <SpinnerCentered /> : onConfirmText}
            </Button>
          </div>
        </div>
        {!!auctionItem && <AuctionItemsListItemFooter auctionItem={auctionItem as AuctionItem} user={user} />}
      </div>
    </div>
  );
};

export default ListItemOverlay;
