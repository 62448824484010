import classnames from 'classnames';
import { ComponentProps } from 'react';

import OperationButton from 'components/sections/inventoryItem/operations/operationButton';

import style from './inactiveOperationButton.scss';

interface Props extends Omit<ComponentProps<typeof OperationButton>, 'disabled' | 'theme'> {
  /** The child elements to be rendered. */
  theme?: 'gray-outline' | 'red-outline';
}

const InactiveOperationButton = ({ className, children, theme = 'gray-outline', ...props }: Props) => (
  <div className={style.buttonContainer}>
    <OperationButton {...props} className={classnames(style.button, style[theme], className)} theme={theme}>
      {children}
    </OperationButton>
  </div>
);

export default InactiveOperationButton;
