import { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import SelectUser, { SelectUserProps } from 'forms/shared/selectUser';
import { AppDispatch, AppState } from 'store/configureStore';
import { Company, MonetaryAmount } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { UserProps } from 'store/user/userModels';
import { isAuctionStaff } from 'utils/userUtils';
import { onApiError } from 'utils/apiUtils';
import { processDeclineOffer } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** Company that is declining an offer */
  companyDeclining: Pick<Company, 'id' | 'name'>;
  /** Company that is offering */
  companyOffering: Pick<Company, 'id' | 'name'>;
  /** True if it's a seller */
  isSeller: boolean;
  /** Offered amount */
  offeredAmount: Pick<MonetaryAmount, 'formattedAmountRounded'>;
  /** Offer Id */
  offerId: string;
  /** Function used to close dialog */
  onClose: () => void;
}

const Dialog = ({ companyDeclining, companyOffering, isSeller, offeredAmount, offerId, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const auctionId = useSelector<AppState, string>((state) => state.app.auctionItemDetails.details.auction.id);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionId), [auctionId, user]);
  const [actionableUser, setActionableUser] = useState<SelectOption | undefined>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isActionable = useMemo(() => {
    if (isStaffUser && !actionableUser) {
      return false;
    }

    return true;
  }, [actionableUser, isStaffUser]);

  const instructions = useMemo(() => {
    if (isStaffUser) {
      const sellerBuyerLabel = isSeller ? t('seller') : t('buyer');

      return (
        <InterpolatedTranslation
          args={[
            actionableUser ? actionableUser.label : sellerBuyerLabel,
            offeredAmount.formattedAmountRounded,
            companyOffering.name,
          ]}
          namespace="decline_offer_message_admin_bolded"
        />
      );
    }

    return (
      <InterpolatedTranslation
        args={[offeredAmount.formattedAmountRounded, companyOffering.name]}
        namespace="decline_offer_message_all_bolded"
      />
    );
  }, [actionableUser, isSeller, isStaffUser, offeredAmount.formattedAmountRounded, companyOffering.name]);

  /**
   * Handles decline offer button
   */
  const handleOnConfirm = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit) {
        setIsSubmitting(true);

        processDeclineOffer(
          {
            companyId: companyDeclining.id,
            offerId,
            userId: actionableUser?.value,
          },
          dispatch
        )
          .then(() => {
            setErrorMessages([]);
            onClose();
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages([]);
        onClose();
      }
    },
    [companyDeclining.id, dispatch, offerId, onClose, actionableUser?.value]
  );

  /**
   * Handles user change events
   */
  const handleUserOnChange = useCallback<NonNullable<SelectUserProps['onChange']>>((option) => {
    if (option) {
      setActionableUser(option);
    }
  }, []);

  return (
    <ConfirmDialog
      actionable={isActionable}
      actionLabel={t('decline_offer').toUpperCase()}
      actionProgress={isSubmitting}
      isOpen
      isPreventingDefaultOnClick
      onClose={onClose}
      onConfirm={handleOnConfirm}
      theme="red"
      title={t('decline_offer')}
    >
      <FormErrors errorMessages={errorMessages} isSmallDialog />
      <div className={style.instructions}>
        <p className={style.confirmMessage}>{t('decline_offer_confirm')}</p>
        <p>{instructions}</p>
      </div>
      {isStaffUser && <SelectUser companyId={companyDeclining.id} onChange={handleUserOnChange} />}
    </ConfirmDialog>
  );
};

export default Dialog;
