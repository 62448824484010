import ApiRequest from 'store/shared/api/apiRequest';
import { User } from 'store/shared/api/graph/interfaces/types';

export const userNotificationSettingsSchema = `
notificationSettings {
  appraisal
  auction
  distanceFromPreferredLocation
  email
  grounded
  makeModelFilters {
    make
    makeId
    maxMileage
    minMileage
    model
    modelId
    yearFrom
    yearTo
  }
  notifications {
    category
    description
    email
    id
    name
    push
    sms
  }
  push
  sms
  timedOffer
}`;

export const getUserNotifications = () =>
  ApiRequest<{ user: Pick<User, 'notificationSettings'> }>({
    data: {
      operationName: 'getUserNotifications',
      query: `
      {
        user {
          ${userNotificationSettingsSchema}
        }
      }
      `,
    },
  });
