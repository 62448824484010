import { MouseEvent, useCallback, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import BlackBookValues from 'components/sections/myBlock/list/listItem/blackBookValues';
import InventoryItemFooterProgressBar from 'components/ui/lists/listItem/inventoryItemFooterProgressBar';
import PostSaleStatuses from 'components/sections/myBlock/list/listItem/postSaleStatuses';
import StatusInIfBid from 'components/sections/myBlock/list/status/statusInIfBid';
import StatusInfoNoSale from 'components/sections/myBlock/list/status/statusInfoNoSale';
import StatusInfoRunList from 'components/sections/myBlock/list/status/statusInfoRunList';
import StatusInfoSold from 'components/sections/myBlock/list/status/statusInfoSold';
import User from 'constants/user';
import VehicleDetails from 'components/sections/myBlock/list/listItem/vehicleDetails';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { ListItem } from 'layouts/list/listItemLayout';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';

import style from './myBlockListItem.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** Function invoked when list item is clicked. */
  onClick?: (event) => void;
  /** The current user. */
  user: User;
  /** The variation of my block view. */
  variant: MyBlockVariant;
}

const MyBlockListItem = ({ auctionItem, onClick, user, variant }: Props) => {
  /**
   * Determine if workflow processes should be displayed instead of black book values.
   */
  const displayPostSaleStatuses = useMemo(
    () => auctionItem.status === AuctionItemStatus.SOLD && !!auctionItem.workflowProcesses?.length,
    [auctionItem.status, auctionItem.workflowProcesses]
  );

  /**
   * onItemClick - Prevent click event propagation on list item click.
   */
  const onItemClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <ListItem className={style.listItem} onClick={onClick}>
      <div className={style.container}>
        <div className={style.left}>
          <VehicleDetails auctionItem={auctionItem} variant={variant} />
          {displayPostSaleStatuses ? (
            <PostSaleStatuses workflowProcesses={auctionItem?.workflowProcesses?.filter(Boolean)} />
          ) : (
            <BlackBookValues inventoryItemValues={auctionItem?.inventoryItem?.values?.filter(Boolean)} />
          )}
        </div>
        <div className={style.right} onClick={onItemClick} role="presentation">
          {(auctionItem?.status === AuctionItemStatus.SOLD ||
            auctionItem?.status === AuctionItemStatus.AWAITING_CHECKOUT) && (
            <StatusInfoSold auctionItem={auctionItem} variant={variant} />
          )}
          {auctionItem?.status === AuctionItemStatus.UPCOMING && variant === MyBlockVariant.SELLER && (
            <StatusInfoRunList auctionItem={auctionItem} />
          )}
          {auctionItem?.status === AuctionItemStatus.NO_SALE && variant === MyBlockVariant.SELLER && (
            <StatusInfoNoSale auctionItem={auctionItem} />
          )}
          {auctionItem?.status === AuctionItemStatus.IN_IF_BID && (
            <StatusInIfBid auctionItem={auctionItem} variant={variant} />
          )}
        </div>
      </div>
      {auctionItem?.status !== AuctionItemStatus.LIVE && (
        <InventoryItemFooterProgressBar auctionItem={auctionItem} user={user} />
      )}
    </ListItem>
  );
};

export default MyBlockListItem;
