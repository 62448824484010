import ApiRequest from 'store/shared/api/apiRequest';
import { MutationuserActAsUserArgs, User } from 'store/shared/api/graph/interfaces/types';

export const userActAsUser = ({ otherUserId }: MutationuserActAsUserArgs) =>
  ApiRequest<{ userActAsUser: Pick<User, 'id'> }>({
    data: {
      query: `
        mutation ($otherUserId: String!) {
          userActAsUser(otherUserId: $otherUserId) {
            id
          }
        }
      `,
      variables: {
        otherUserId,
      },
    },
  });

export const userClearActingUser = () =>
  ApiRequest<{ userActAsUser: Pick<User, 'id'> }>({
    data: {
      operationName: 'clearActingUser',
      query: `
        mutation {
          userClearActingUser {
            id
          }
        }
      `,
    },
  });
