import classnames from 'classnames';

import fullScreenGlyph from 'glyphs/liveLanes/full-screen.svg';
import volumeGlyph from 'glyphs/liveLanes/volume.svg';
import volumeMuteGlyph from 'glyphs/liveLanes/volume-mute.svg';

import IconButton from 'components/ui/shared/buttons/iconButton';

import style from './videoControl.scss';

interface Props {
  /** CSS style to override default style. */
  className?: string;
  /** Whether the video is muted or not. */
  isMuted?: boolean;
  /**
   * Whether to show full screen button or not.
   * @default true
   */
  showFullScreenButton?: boolean;
  /**
   * Whether to show mute button or not.
   * @default true
   */
  showMuteButton?: boolean;
  /** Callback function to make player full screen or not. */
  toggleFullScreen?: () => void;
  /** Callback function to make video muted or not. */
  toggleMute?: () => void;
}

const VideoControl = ({
  className,
  isMuted,
  showFullScreenButton = true,
  showMuteButton = true,
  toggleFullScreen,
  toggleMute,
}: Props) => {
  return (
    <div className={classnames(style.container, className)}>
      {showMuteButton && (
        <IconButton
          className={classnames(style.controlButton, style.volumeControl)}
          dataTestId={isMuted ? 'unmute-button' : 'mute-button'}
          glyph={isMuted ? volumeMuteGlyph : volumeGlyph}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            toggleMute?.();
          }}
          spriteClassName={classnames(style.sprite, { [style.isMuted]: isMuted })}
        />
      )}
      {showFullScreenButton && (
        <IconButton
          className={classnames(style.controlButton, style.fullScreenControl)}
          dataTestId="fullscreen-button"
          glyph={fullScreenGlyph}
          onClick={(event) => {
            event.preventDefault();
            toggleFullScreen?.();
          }}
          spriteClassName={style.sprite}
        />
      )}
    </div>
  );
};

export default VideoControl;
