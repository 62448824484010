import classNames from 'classnames';
import { useMemo } from 'react';

import { MonetaryAmount, TopOffer } from 'store/shared/api/graph/interfaces/types';
import { UnreservedBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { formatCurrency } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './reserveStats.scss';

interface Props {
  /** The reserve amount of the auction item */
  reserve: MonetaryAmount | undefined;
  /** True when the reserve has been met, otherwise false */
  reserveMet: boolean;
  /** The auction item's top offer. */
  topOffer: TopOffer | undefined;
}

const ReserveStats = ({ reserve, reserveMet, topOffer }: Props) => {
  const gapAmount = useMemo(() => {
    return !reserve ? '-' : formatCurrency(reserve.amount - (topOffer?.amount?.amount ?? 0), reserve.currencyCode);
  }, [reserve, topOffer]);

  return (
    <div className={style.reserveAmounts}>
      <div className={style.valueContainer} data-testid="reserve">
        <div className={classNames(style.value, style.reserve)}>{reserve ? reserve.formattedAmountRounded : '-'}</div>
        <div>{t('reserve_price')}</div>
      </div>
      <div className={style.valueContainer} data-testid="gap-reserve">
        {reserveMet || Number(topOffer?.amount?.amount) >= Number(reserve?.amount) ? (
          <div className={style.value}>
            <UnreservedBadge />
          </div>
        ) : (
          <div className={classNames(style.value, style.gap)}>{gapAmount}</div>
        )}
        <div>{t('gap_to_reserve')}</div>
      </div>
    </div>
  );
};

export default ReserveStats;
