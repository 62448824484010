import classnames from 'classnames';
import { useRef } from 'react';

import InputText from 'forms/shared/inputText';
import InventoryItem from 'constants/inventoryItem';
import RadioButtons from 'forms/shared/radioButtons';
import ValuesInfo from 'components/sections/inventoryItem/details/feesAndValues/valuesInfo';
import { AuctionDateType } from 'components/sections/auctionItem/operations/submitToAuction/constants/submitToAuction';
import { InventoryItemValue } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './timedOfferForm.scss';

interface Props {
  /** The id of the auction time slot. */
  auctionTimeSlotId: string | null;
  /** Date details for the selected auction type. */
  auctionDates: AuctionDateType[] | null;
  /** The default buy now amount */
  defaultBuyNowAmount?: number;
  /** Callback to clear error messages */
  clearErrorMessages: () => void;
  /** The inventory Item. */
  inventoryItem: InventoryItem;
  /** The mmr values of the inventory item. */
  inventoryItemValues?: InventoryItemValue[];
  /** Callback to set buy now amount */
  setBuyNowAmount: (buyNowAmount: number) => void;
  /** Callback to set end time */
  setEndTime: (auctionTimeSlotId: string) => void;
}

const TimedOfferForm = ({
  auctionDates,
  auctionTimeSlotId,
  clearErrorMessages,
  defaultBuyNowAmount,
  inventoryItem,
  inventoryItemValues,
  setBuyNowAmount,
  setEndTime,
}: Props) => {
  const refClearErrorMessages = useRef(clearErrorMessages);
  const refSetBuyNowAmount = useRef(setBuyNowAmount);
  const refSetEndTime = useRef(setEndTime);

  return (
    <div className={style.container}>
      <ValuesInfo
        countryCode={inventoryItem.location?.countryCode}
        inventoryItemValues={inventoryItemValues}
        make={inventoryItem.make}
        model={inventoryItem.model}
        subModel={inventoryItem.subModel}
        year={inventoryItem.year}
      />
      <p className={style.headerText}>{t('buy_now_price')}</p>
      <div className={style.inputContainer}>
        <InputText
          dataTestId="buy-now-price-input"
          defaultValue={defaultBuyNowAmount || ''}
          onChange={refSetBuyNowAmount.current}
          onFocus={refClearErrorMessages.current}
          placeholder={t('buy_now_price')}
          theme="minimal"
          type="currency"
        />
      </div>
      {Boolean(auctionDates?.length) && (
        <>
          <p className={classnames(style.subTitle, style.headerText)}>{t('end_time')}</p>
          <RadioButtons
            className={style.radioButtons}
            labels={auctionDates || []}
            onChange={({ target }) => {
              refClearErrorMessages.current();
              refSetEndTime.current(target.value);
            }}
            selectedOption={auctionTimeSlotId || undefined}
          />
        </>
      )}
    </div>
  );
};

export default TimedOfferForm;
