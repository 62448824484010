import classnames from 'classnames';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';

import calendarGlyph from 'glyphs/calendar.svg';
import circleXGlyph from 'glyphs/operationIcons/circle-cancel.svg';

import Sprite from 'components/ui/shared/sprite';

import style from './datePicker.scss';

type DatePickerProps = SingleDatePickerShape & {
  className?: string;
  inputIconClassName?: string;
};

const DatePicker = ({ className, inputIconClassName, ...props }: DatePickerProps) => (
  <div className={classnames(style.datePicker, className)}>
    <SingleDatePicker
      customCloseIcon={<Sprite className={style.clearFilterSprite} glyph={circleXGlyph} />}
      customInputIcon={
        props.date ? null : (
          <Sprite className={classnames(style.calendarIcon, inputIconClassName)} glyph={calendarGlyph} />
        )
      }
      showClearDate={!!props.date}
      {...props}
    />
  </div>
);

export default DatePicker;
