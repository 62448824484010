import { List } from 'immutable';

import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import UserType from 'constants/user';
import { FacetGroup as FacetGroupType, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface UserModel {
  /** Facet groups used to filter search results. */
  facetGroups: List<RecordOf<FacetGroupType>>;
  /** Information about pagination. */
  pageInfo: RecordOf<FacetedPageInfo>;
  /** List of users. */
  resultList: List<RecordOf<UserType>>;
}

export type UsersProps = ServerRecordOf<UserModel>;

export const InitialState = ServerRecord<UserModel>({
  facetGroups: List([]),
  pageInfo: null,
  resultList: List([]),
});

export const User = Record<UserType>({
  companyRelationships: {
    count: 0,
    list: [],
  },
  created: null,
  email: null,
  emailVerified: false,
  employee: null,
  firstName: null,
  id: null,
  internal: false,
  lastLoggedIn: null,
  lastName: null,
  mainPhotoMain: null,
  mainPhotoThumb: null,
  omvicRegNumber: null,
  phoneVerified: false,
  photoUrl: null,
  preferredLocation: null,
  savedFilters: [],
  sessionActingAsOtherUser: null,
  source: null,
  status: null,
  title: null,
  updated: null,
});

export const FacetGroup = Record<FacetGroupType>({
  name: null,
  allowMultiple: false,
  facets: [],
});

export const PageInfo = Record<FacetedPageInfo>({
  endCursor: null,
  hasNextPage: null,
  hasPreviousPage: null,
  startCursor: null,
  totalEdges: null,
});
