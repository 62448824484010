import classnames from 'classnames';

import vehicleGlyph from 'glyphs/default-vehicle.svg';

import ImageOrSprite from './imageOrSprite';

import style from './vehicleImage.scss';

interface Props {
  /** CSS styling to overwrite default image style. */
  className?: string;

  /** CSS styling to overwrite default container style. */
  containerClassName?: string;

  /** Source url of the image to be rendered. */
  imageUrl;
}
const VehicleImage = ({ className, containerClassName, imageUrl }: Props) => (
  <ImageOrSprite
    alt="Vehicle image"
    className={className}
    containerClassName={classnames(containerClassName, style.container)}
    dataTestId="vehicle-image"
    glyph={vehicleGlyph}
    imageUrl={imageUrl}
  />
);

export default VehicleImage;
