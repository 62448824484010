import { handleActions } from 'redux-actions';

import Logger from 'logging/Logger';
import { UserProfile } from './userModels';
import {
  isLoading,
  isNotLoading,
  isUpdating,
  setCurrentBiddingAsSelection,
  userCleared,
  userDistanceUnit,
  userError,
  userFeatureFlagsUpdated,
  userLoaded,
  userUpdated,
} from './userActions';

export const userReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [userCleared().type]: () => {
      userDistanceUnit.remove();
      Logger.clearUser();
      return new UserProfile();
    },

    [userLoaded().type]: (state, action) => {
      const sortedFeatureFlags = action?.payload?.featureFlags?.sort((a, b) =>
        a?.featureKey?.localeCompare(b?.featureKey)
      );
      const userNext = new UserProfile({
        ...action.payload?.user,
        featureFlags: sortedFeatureFlags,
        operatingRegions: action?.payload?.operatingRegions,
        supportedLocales: action?.payload?.supportedLocales,
      });

      Logger.setUser(action?.payload?.user);
      return userNext.setLoaded();
    },

    [userUpdated().type]: (state, action) => {
      const user = state?.toJS?.();
      const userNext = {
        ...user,
        ...action.payload,
      };

      Logger.setUser(userNext);
      return new UserProfile(userNext).setLoaded();
    },

    [userError().type]: (state, action) => state.setError(action.payload),

    [setCurrentBiddingAsSelection().type]: (state, action) =>
      state.set('currentBiddingAsSelections', {
        ...(action.payload ? state.currentBiddingAsSelections : null),
        ...action.payload,
      }),

    [userFeatureFlagsUpdated().type]: (state, action) => {
      const sortedFeatureFlags = action?.payload?.sort((a, b) => a?.featureKey?.localeCompare(b?.featureKey));
      return state?.set('featureFlags', sortedFeatureFlags);
    },
  },
  new UserProfile()
);
