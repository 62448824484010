import classnames from 'classnames';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import FeesSlideOutListItem, { ServiceFee } from './feesSlideOutListItem';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import User from 'constants/user';
import { FormDialogFooter } from 'layouts/formLayouts/formDialogLayouts';
import { hasAuctionPermission, hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './feesSlideOutList.scss';

interface Props {
  /** Label for add button */
  addButtonLabel: string;
  /** The id of the auction. */
  auctionId?: string;
  /** CSS styling to overwrite default container style */
  containerClassName?: string;
  /** True if fee is loading */
  isLoadingFees: boolean;
  /** Callback to handle onAdd event */
  onAdd?: () => void;
  /** Callback to handle onRemove event */
  onRemove?: (serviceId: string) => void;
  /** Service fees to be displayed  */
  serviceFees: ServiceFee[];
  /** The logged-in user */
  user: User;
}

class FeesSlideOutList extends BaseClass<Props> {
  render() {
    const { auctionId, addButtonLabel, isLoadingFees, containerClassName, onAdd, onRemove, serviceFees, user } =
      this.props;

    return (
      <>
        <div className={classnames(style.listContainer, containerClassName)}>
          <ul className={style.list}>
            {serviceFees && serviceFees.length > 0
              ? serviceFees.map((serviceFee) => (
                  <FeesSlideOutListItem
                    key={serviceFee.id}
                    onRemove={() => onRemove?.(serviceFee.id)}
                    serviceFee={serviceFee}
                  />
                ))
              : null}
            {!serviceFees?.length && !isLoadingFees && <NoResults title={t('no_items_to_show')} />}
            <Loading hasFullWidth isLoading={isLoadingFees} isModalTransparent />
          </ul>
          {(hasSystemPermission(user, ['SYSTEM_MANAGE_ANY_AUCTION_FEE']) ||
            (auctionId &&
              hasAuctionPermission(user, auctionId, [
                'AUCTION_MANAGE_AUCTION_FEES',
                'AUCTION_MANAGE_AUCTION_ITEM_FEES',
                'AUCTION_MANAGE_COMPANY_FEES',
              ]))) && (
            <FormDialogFooter className={style.footer}>
              <Button className={style.addButton} onClick={onAdd} theme="blue">
                {addButtonLabel}
              </Button>
            </FormDialogFooter>
          )}
        </div>
      </>
    );
  }
}

export default FeesSlideOutList;
