import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  AuctionItemEdge as AuctionItemEdgeType,
  AuctionTimeSlot,
  Broadcast,
  FacetedPageInfo,
  FacetGroup,
  InspectionEdge,
  LiveLane,
} from 'store/shared/api/graph/interfaces/types';
import AuctionItem from 'constants/auctionItem';
import { RecordOf } from 'store/shared/models/interfaces';

interface AuctionItemEdge extends Omit<AuctionItemEdgeType, 'node'> {
  node: AuctionItem;
}

interface HomeDashboardResultModel {
  /** Live lanes. */
  liveLanes: LiveLane[];
  /** Next auction. */
  nextAuction: AuctionTimeSlot;
  /** Upcoming auction item. */
  upcoming: {
    facetGroups: FacetGroup[];
  };
  /** Recommended auction items. */
  recommended: {
    edges: AuctionItemEdge[];
  };
  /** Appraisals auction items. */
  appraisals: {
    facetGroups: FacetGroup[];
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** Buy now auction items. */
  buyNow: {
    facetGroups: FacetGroup[];
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** Timed Offer */
  timedOffer: {
    facetGroups: FacetGroup[];
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** Parked items. */
  parked: {
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** If bid auction items. */
  ifBid: {
    facetGroups: FacetGroup[];
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** Sold auction items. */
  boughtSold: {
    facetGroups: FacetGroup[];
  };
  /** Awaiting checkout auction items. */
  awaitingCheckout: {
    facetGroups: FacetGroup[];
  };
  /** Recent activity auction items. */
  recentActivity: {
    edges: AuctionItemEdge[];
    facetGroups: FacetGroup[];
    pageInfo: Pick<FacetedPageInfo, 'totalEdges'>;
  };
  /** Inspection connection. */
  inspectionConnection: {
    edges: InspectionEdge[];
  };
  /** Broadcast list. */
  broadcasts: Broadcast[];
}

export type HomeDashboardResultProps = RecordOf<HomeDashboardResultModel>;

interface HomeDashboardModel {
  /** Home dashboard result */
  results: HomeDashboardResultProps;
}

export type HomeDashboardProps = ServerRecordOf<HomeDashboardModel>;

export const HomeDashboardInitialState = ServerRecord<HomeDashboardModel>({
  results: null,
});

export const HomeDashboardResult = Record<HomeDashboardResultModel>({
  appraisals: {
    facetGroups: null,
    pageInfo: {
      totalEdges: null,
    },
  },
  awaitingCheckout: {
    facetGroups: [],
  },
  boughtSold: {
    facetGroups: [],
  },
  broadcasts: null,
  buyNow: {
    facetGroups: null,
    pageInfo: {
      totalEdges: null,
    },
  },
  ifBid: {
    facetGroups: null,
    pageInfo: {
      totalEdges: null,
    },
  },
  inspectionConnection: {
    edges: [],
  },
  liveLanes: [],
  nextAuction: null,
  parked: {
    pageInfo: {
      totalEdges: null,
    },
  },
  recentActivity: {
    edges: [],
    facetGroups: null,
    pageInfo: {
      totalEdges: null,
    },
  },
  recommended: {
    edges: [],
  },
  timedOffer: {
    facetGroups: null,
    pageInfo: {
      totalEdges: null,
    },
  },
  upcoming: {
    facetGroups: [],
  },
});
