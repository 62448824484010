import classnames from 'classnames';
import he from 'he';
import { connect, ConnectedProps } from 'react-redux';

import BaseClass from 'components/ui/shared/base';
import { AppDispatch, AppState } from 'store/configureStore';
import { Spinner } from 'components/ui/loading/loading';
import { formatDate } from 'utils/dateUtils';
import { processGetAuditLog } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './auditLog.scss';

const stateConnect = (state: AppState) => ({
  /** The audit log entries of the inventory item. */
  logEntries: state.app.inventoryItemDetails.logEntries,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** The dispatch function to retrieve the inventory item's audit logs. */
  getAuditLog: (options: { inventoryItemId: string }) => processGetAuditLog(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The id of the inventory item. */
  inventoryItemId: string;
}

class AuditLog extends BaseClass<Props> {
  constructor(props: Props) {
    super(props);

    this.props.getAuditLog?.({
      inventoryItemId: this.props.inventoryItemId,
    });
  }

  render() {
    const { logEntries } = this.props;

    if (!logEntries.loadedDate || logEntries.isUpdating) {
      return <Spinner className={style.logEntriesLoading} />;
    }

    if (!logEntries.results || !logEntries.results.size) {
      return <div className={style.noLogsPlaceholder}>{t('audit_log_none_available')}</div>;
    }

    return (
      <>
        {logEntries.results.map((logsByDate) => (
          <div key={logsByDate?.title} className={style.logGroup}>
            <div className={style.title}>{logsByDate?.title}</div>
            <div className={style.logEntries}>
              {logsByDate?.logs.map((entry) => (
                <div key={entry.created + entry.message} className={style.logEntry}>
                  <div className={style.type}>
                    {entry.type}
                    <span>{formatDate(entry.created, 'MMM DD, h:mm:ss a')}</span>
                  </div>
                  <div className={style.message}>{he.decode(entry.message)}</div>
                  {entry.note && <div className={style.note}>{`- "${entry.note}"`}</div>}
                  <div className={classnames(style.colorBadge, style[entry.color.toLowerCase()])} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default connector(AuditLog);
