import { createAction } from 'redux-actions';

import { getCarriersList } from 'store/shared/api/graph/queries/carriers';
import { getErrors, parseQueryParams } from 'utils/apiUtils';
import { getFacets } from 'store/admin/carriers/list/carriersApi';

export const carriersListIsLoading = createAction('ADMIN_CARRIERS_LIST_IS_LOADING');
export const carriersListLoaded = createAction('ADMIN_CARRIERS_LIST_LOADED');
export const carriersListCleared = createAction('ADMIN_CARRIERS_LIST_CLEARED');
export const carriersListError = createAction('ADMIN_CARRIERS_LIST_ERROR');
export const carriersListUpdateItem = createAction('ADMIN_CARRIERS_LIST_UPDATE_ITEM');
export const facetGroupsLoaded = createAction('ADMIN_CARRIERS_LIST_FACET_GROUPS_LOADED');
export const pageInfoLoaded = createAction('ADMIN_CARRIERS_LIST_PAGE_INFO_LOADED');

export const processGetCarriersList = async (options, dispatch) => {
  try {
    dispatch(carriersListIsLoading(true));
    const carriers = (await getCarriersList(parseQueryParams(options)))?.data?.data;
    dispatch(carriersListLoaded(carriers));
  } catch (err) {
    dispatch(carriersListIsLoading(false));
    if (err.response) {
      dispatch(carriersListError(getErrors(err)));
    } else {
      dispatch(carriersListError(err.message));
    }
  }
};

export const processUpdateFacets = async (options, dispatch) => {
  return getFacets(parseQueryParams(options)).then((response) =>
    dispatch(facetGroupsLoaded(response?.data?.data?.carrierConnection?.facetGroups))
  );
};
