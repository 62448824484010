import { List } from 'immutable';

import ServerRecord from 'store/shared/models/serverRecord';
import { Auction, FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

interface AuctionsModel {
  /** Auctions facet groups. */
  facetGroups: List<FacetGroup>;
  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;
  /** Transport job/request list. */
  resultList: List<RecordOf<Auction>>;
}

export const InitialState = ServerRecord<AuctionsModel>({
  resultList: List([]),
  facetGroups: List([]),
  pageInfo: null,
});
