import classnames from 'classnames';
import { MouseEvent, MouseEventHandler, useCallback, useState } from 'react';

import Button from 'components/ui/shared/button';
import CarfaxSlideOut from 'components/sections/inventoryItem/details/conditionReport/carfaxSlideOut';
import CarfaxUSButton from 'components/sections/inventoryItem/details/conditionReport/carfaxUSButton';
import VehicleConditionReport from 'constants/vehicleConditionReport';
import carfaxImageCA from 'static/images/carfax-ca.png';
import carfaxImageUS from 'static/images/carfax-us.png';
import { CarfaxAnnouncements } from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { UserAction } from 'logging/analytics/events/userActions';
import { VehicleFeature } from 'components/sections/inventoryItem/details/vehicleDetails';
import { getErrors } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';
import { vehicleCarfaxAnnouncements } from 'store/shared/api/graph/mutations/carfax';
import { vehicleFeatures } from 'components/sections/inventoryItem/details/inventoryItemDetails';

import style from './carfaxButton.scss';

export enum CarfaxButtonVariant {
  DEFAULT = 'DEFAULT',
  MY_MARKET_GUIDE = 'MY_MARKET_GUIDE',
}

interface Props {
  /** Country code. */
  countryCode?: string;
  /** Callback function to open add/modify modal. */
  handleOpenAddModifyModal?: (feature: VehicleFeature) => void;
  /** True when inventory item details are editable. */
  isUpdatable?: boolean;
  /** The id of the inventory item. */
  inventoryItemId?: string | null;
  /** Function invoked on user action. */
  trackUserAction?: (userAction: UserAction, event?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  /** The inventory condition report */
  vehicleConditionReport?: VehicleConditionReport;
  /** The inventory vin number */
  vin?: string;
  /** Different variants of the Carfax button */
  variant?: CarfaxButtonVariant;
}

const CarfaxButton = ({
  countryCode,
  handleOpenAddModifyModal,
  isUpdatable = false,
  inventoryItemId,
  trackUserAction,
  variant = CarfaxButtonVariant.DEFAULT,
  vehicleConditionReport,
  vin,
}: Props) => {
  const { setConfig } = useGlobalDialog();
  const { carfax } = vehicleFeatures;

  const isCA = countryCode === 'CA';
  const isUS = countryCode === 'US';
  const carfaxImage = isCA && variant !== CarfaxButtonVariant.MY_MARKET_GUIDE ? carfaxImageCA : carfaxImageUS;
  const claimsExist = vehicleConditionReport?.carfaxCanadaDisclosuresExist ?? false;
  const isClickable = isUpdatable || !!vehicleConditionReport?.carfaxCanadaReportUrl;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState<boolean>(false);
  const [carfaxAnnouncements, setCarfaxAnnouncements] = useState<CarfaxAnnouncements>();

  /**
   * Get carfax summary and announcements.
   */
  const getCarfaxAnnouncements = useCallback(() => {
    if (!inventoryItemId) {
      return;
    }

    setIsLoading(true);
    vehicleCarfaxAnnouncements({ inventoryItemId })
      .then((response) => setCarfaxAnnouncements(response?.data?.data?.vehicleCarfaxAnnouncements))
      .catch((error) => setConfig({ errorsOverride: getErrors(error) }))
      .finally(() => setIsLoading(false));
  }, [inventoryItemId, setConfig]);

  /**
   * Close slideOut
   */
  const onCloseSlideOut = useCallback(() => {
    setIsSlideOutOpen(false);
  }, []);

  /**
   * Open slide out on row click
   */
  const onOpenSlideOut = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setIsSlideOutOpen(true);
    getCarfaxAnnouncements();
  }, [getCarfaxAnnouncements]);

  /**
   * Open vehicle form dialog to edit carfax section.
   */
  const onOpenModifyDialog = useCallback(() => {
    handleOpenAddModifyModal?.(carfax);
  }, [carfax, handleOpenAddModifyModal]);

  /**
   * On carfax button clicked
   * - if there are existing claims or report then open slide out.
   * - otherwise, if the inventory item isUpdatable, then open carfax section of edit dialog.
   */
  const onCarfaxButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      trackUserAction?.(UserAction.CARFAX_CLICK, event);
      if (vehicleConditionReport?.carfaxCanadaReportUrl) {
        onOpenSlideOut(event);
        return;
      }

      if (isUpdatable) {
        onOpenModifyDialog();
      }
    },
    [isUpdatable, onOpenModifyDialog, onOpenSlideOut, trackUserAction, vehicleConditionReport?.carfaxCanadaReportUrl]
  );

  if (variant === CarfaxButtonVariant.MY_MARKET_GUIDE) {
    return (
      <div
        className={classnames(style.mmgCarfax, {
          [style.red]: claimsExist,
        })}
        data-testid="carfax-marketguide"
      >
        <img alt={t('carfax')} className={style.mmgCarfaxImage} src={carfaxImage} />
      </div>
    );
  }

  if (isUS) {
    return (
      <CarfaxUSButton
        className={style.carfaxUSButton}
        onClick={(event) => trackUserAction?.(UserAction.CARFAX_CLICK, event)}
        vin={vin || undefined}
      />
    );
  }

  return (
    <>
      <Button
        className={classnames(style.crButton, {
          [style.crButtonRed]: claimsExist,
        })}
        data-testid="carfax-ca-button"
        onClick={onCarfaxButtonClick}
        theme="none"
      >
        <div>
          <img alt={t('carfax')} className={style.carfax} src={carfaxImage} />
          {claimsExist && <div className={style.claimsFound}>{t('claims_found')}</div>}
        </div>
        {isClickable && <Chevron className={style.chevron} />}
      </Button>

      <CarfaxSlideOut
        carfaxAnnouncements={carfaxAnnouncements}
        claimsExist={claimsExist}
        isLoading={isLoading}
        isOpen={isSlideOutOpen}
        onClose={onCloseSlideOut}
        onEdit={isUpdatable ? onOpenModifyDialog : undefined}
        onRefresh={getCarfaxAnnouncements}
        onReportLinkClick={(event) => trackUserAction?.(UserAction.CARFAX_REPORT_CLICK, event)}
      />
    </>
  );
};

export default CarfaxButton;
